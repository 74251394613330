import React, { useState, useCallback, version } from 'react'
import Select, { components } from 'react-select'
import findIndex from 'lodash/findIndex'
import cn from 'classnames'
import Checkbox from '../Checkbox/Checkbox'
import FieldLabel from '../FieldLabel/FieldLabel'
import MultiValueContainers from './MultiValueContainers'
import Menus from './Menus'
import MultiValues from './MultiValue'
import ContentEditor from './ContentEditor'
import Icon from '../Icon2/Icon'
import './Select.css'

const SingleValue = (props: any) => {
  const options = props.getValue()
  const { updateOption, ...rest } = props
  return (
    <components.SingleValue {...rest}>
      {options.map((option: any, index: number) => {
        return <ContentEditor key={index} value={option.label} option={option} updateOption={updateOption} />
      })}
    </components.SingleValue>
  )
}

const MultiValueLabel = (props: any) => {
  const { updateOption, ...rest } = props
  return (
    <components.MultiValueLabel {...rest}>
      <ContentEditor value={rest.data.label} option={rest.data} updateOption={updateOption} />
    </components.MultiValueLabel>
  )
}

const MultiValueContainer = ({ children, ...props }) => {
  return <MultiValueContainers children={children} {...props} />
}

const getValueContainer = ({
  showAllSelectedItems: showAll,
  setShowAllSelectedItems: setShowAll,
  multiDisplayLimit,
  isValueContainer
}: {
  showAllSelectedItems: boolean
  setShowAllSelectedItems: React.Dispatch<React.SetStateAction<boolean>>
  multiDisplayLimit: number
  isValueContainer: boolean
}) => {
  return ({ children, hasValue, ...props }) => {
    if (isValueContainer) {
      if (!hasValue) {
        return <components.ValueContainer {...props}>{children}</components.ValueContainer>
      }
      const [selectedItems, otherChildren] = children
      const itemsToDisplay = showAll ? selectedItems : selectedItems.slice(0, multiDisplayLimit)
      const overflowCounter = selectedItems.length - multiDisplayLimit

      return (
        <components.ValueContainer {...props}>
          <div className="Select__value-container--custom">
            {itemsToDisplay}
            {!showAll && overflowCounter > 0 && (
              <button
                className="Select__see-more-button"
                id="see-more-button"
                data-test-id="see-more-button"
                onClick={() => {
                  setShowAll(true)
                }}
                onMouseDown={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}>
                {`+${overflowCounter} More`}
              </button>
            )}
            {showAll && overflowCounter > 0 && (
              <button
                className="Select__see-less-button"
                id="see-less-button"
                data-test-id="see-less-button"
                onClick={() => {
                  setShowAll(false)
                }}
                onMouseDown={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}>
                {`See Less`}
              </button>
            )}
            {otherChildren}
          </div>
        </components.ValueContainer>
      )
    }
    return <components.MultiValueContainer {...props}>{children}</components.MultiValueContainer>
  }
}

/** Returns a custom option component to be sent to the react-select component */
const Option = ({ classname }: { classname?: string }) => {
  return (props: any) => {
    if (props.isMulti && props.selectProps.noCheckBox && props.selectProps.isNested) {
      return (
        <components.Option {...props} className={classname}>
          <div
            data-test-id={props.data.testId || 'cs-select-element'}
            style={{ paddingLeft: `${props.data.depth * 10}px` }}>
            {props.children}
          </div>
        </components.Option>
      )
    }

    if (props.isMulti) {
      let flag = false
      let optionIdx = findIndex(props.options, { value: props.value })
      if (optionIdx > -1 && optionIdx !== 0 && props.data.depth === 0) {
        flag = true
      }
      return (
        <components.Option {...props}>
          {props.selectProps.isNested ? (
            <div
              data-test-id={props.data.testId || 'cs-select-element'}
              style={{ paddingLeft: `${props.data.depth * 10}px` }}>
              <Checkbox
                checked={props.isSelected}
                text={props.children}
                fullWidth={true}
                tooltipContent={props.data.label}
              />
            </div>
          ) : (
            <Checkbox
              checked={props.isSelected}
              text={props.children}
              fullWidth={true}
              testId={props.data.testId || 'cs-select-element'}
              tooltipContent={props.data.label}
            />
          )}
        </components.Option>
      )
    }
    if (props.selectProps.isNested && !props.isMulti) {
      let flag = false
      let optionIdx = findIndex(props.options, { value: props.value })
      if (optionIdx > -1 && optionIdx !== 0 && props.data.depth === 0) {
        flag = true
      }

      return (
        <components.Option {...props}>
          <div
            data-test-id={props.data.testId || 'cs-select-element'}
            style={{ paddingLeft: `${props.data.depth * 10}px` }}>
            {props.children}
          </div>
        </components.Option>
      )
    }
    // return <components.Option {...props} className={classname} />
    return (
      <div data-test-id={props.data.testId || 'cs-select-element'} title={props.label}>
        <components.Option {...props} className={classname} />
      </div>
    )
  }
}

const customComponents = ({
  updateOption,
  canEditOption,
  showCount,
  addOption,
  showMore,
  optionClassname,
  decorators = {},
  isMulti,
  showAllSelectedItems,
  setShowAllSelectedItems,
  multiDisplayLimit,
  menuStyle,
  menuClassName,
  version
}: {
  isMulti: boolean
  showAllSelectedItems: boolean
  setShowAllSelectedItems: React.Dispatch<React.SetStateAction<boolean>>
  multiDisplayLimit: number
  menuStyle?: Object
  menuClassName?: string
  [key: string]: any
}) => {
  let selectObj = {};
  if (version === 'v2') {
    selectObj = {
      ...selectObj, DropdownIndicator: (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <Icon
              icon="CaretDownNew"
              version='v2'
              size="medium"
              className='select-caret-down'
            />
          </components.DropdownIndicator>
        )
      }
    }
  }
  if (canEditOption) {
    selectObj = {
      ...selectObj,
      SingleValue: (props: any) => <SingleValue {...props} updateOption={updateOption} />,
      MultiValueLabel: (props: any) => <MultiValueLabel {...props} updateOption={updateOption} />,
      Option: Option({ classname: optionClassname })
    }
    return selectObj
  } else if (showCount && !addOption) {
    selectObj = {
      ...selectObj,
      MultiValueContainer: (props) => <MultiValueContainers {...props} />,
      Option: Option({ classname: optionClassname })
    }
    return selectObj
  } else if (addOption) {
    if (MultiValueContainer) {
      if (isMulti && multiDisplayLimit) {
        selectObj = {
          ...selectObj,
          ValueContainer: getValueContainer({
            showAllSelectedItems,
            setShowAllSelectedItems,
            multiDisplayLimit,
            isValueContainer: true
          }),
          Option: Option({ classname: optionClassname }),
          Menu: (props) => <Menus {...props} menuStyle={menuStyle} menuClassName={menuClassName} />
        }
        return selectObj
      }
      selectObj = {
        ...selectObj,
        MultiValue: (props) => <MultiValues {...props} />,
        Option: Option({ classname: optionClassname }),
        Menu: (props) => <Menus {...props} menuStyle={menuStyle} menuClassName={menuClassName} />
      }
      return selectObj
    } else {
      selectObj = {
        ...selectObj,
        Option: Option({ classname: optionClassname }),
        Menu: (props) => <Menus {...props} menuStyle={menuStyle} menuClassName={menuClassName} />
      }
      return selectObj
    }
  } else if (Object.keys(decorators).length) {
    const { CustomDropdownIndicator } = decorators
    return {
      Option: Option({ classname: optionClassname }),
      DropdownIndicator: (props) => {
        return (
          <components.DropdownIndicator {...props}>
            {CustomDropdownIndicator ? <CustomDropdownIndicator /> : props.children}
          </components.DropdownIndicator>
        )
      }
    }
  } else {
    if (isMulti && multiDisplayLimit) {
      selectObj = {
        ...selectObj,
        Option: Option({ classname: optionClassname }),
        ValueContainer: getValueContainer({
          showAllSelectedItems,
          setShowAllSelectedItems,
          multiDisplayLimit,
          isValueContainer: true
        })
      }
      return selectObj
    }
    selectObj = {
      ...selectObj,
      Option: Option({ classname: optionClassname })
    }
    return selectObj
  }
}

export type ISelectProps = {
  width?: string
  maxWidth?: string
  minWidth?: string
  name?: string
  isDisabled?: boolean
  isClearable?: boolean
  isMulti?: boolean
  isSearchable?: boolean
  placeholder?: string
  hideSelectedOptions?: boolean
  menuShouldScrollIntoView?: boolean
  menuPlacement?: 'auto' | 'bottom' | 'top'
  menuIsOpen?: boolean
  maxMenuHeight?: number
  minMenuHeight?: number
  onChange: Function
  onBlur?: any
  options: any[]
  value: any
  canEditOption?: boolean
  updateOption?: (IUpdateOption: { label: any; id: any }) => void
  selectLabel?: string
  isNested?: boolean
  showCount?: boolean
  selectedLabel?: string
  addOption?: Function
  hasAddOption?: boolean
  addOptionText?: React.ReactNode | Element
  getOptionLabel?: any
  getOptionValue?: any
  noCheckBox?: boolean
  showMore?: boolean
  className?: string
  decorators?: object
  noOptionsMessage?: any
  error?: Boolean
  optionClassname?: string,
  id?: string,
  testId?: string,
  filterOption?: Function,
  isOptionSelected?: Function,
  isOptionDisabled?: Function,
  multiDisplayLimit?: number,
  menuPortalTarget?: any,
  styles?: any,
  menuStyle?: object,
  menuClassName?: string,
  type?: 'primary' | 'secondary',
  onMenuOpen?: Function,
  version?: "v2",
  tabSelectsValue?: boolean,
  onKeyDown?: any
  ref?: React.Ref<any>
}

const SelectField: React.FunctionComponent<ISelectProps> = React.forwardRef((props, ref) => {
  const [showAllSelectedItems, setShowAllSelectedItems] = useState(false);

  const allComponents = customComponents({
    updateOption: props.updateOption,
    canEditOption: props.canEditOption,
    showCount: props.showCount,
    addOption: props.hasAddOption,
    showMore: props.showMore,
    decorators: props.decorators,
    optionClassname: props.optionClassname,
    isMulti: props.isMulti,
    showAllSelectedItems: showAllSelectedItems,
    setShowAllSelectedItems: setShowAllSelectedItems,
    multiDisplayLimit: props.multiDisplayLimit,
    menuStyle: props.menuStyle,
    menuClassName: props.menuClassName,
    version: props.version
  })

  let widthStyleObj: any = {}
  if (props.width) {
    widthStyleObj = { width: props.width }
  } else {
    widthStyleObj = { maxWidth: props.maxWidth || '500px', minWidth: props.minWidth || '200px' }
  }

  const handleChange = useCallback(
    (newValue: any) => {
      props.onChange(props.isMulti ? newValue || [] : newValue)
    },
    [props.onChange, props.isMulti]
  )
  const versionClass = `${props?.version === 'v2' && props?.type !== 'secondary' ? 'Select__v2' : ''}`
  const classNames = cn(
    'Select',
    versionClass,
    {
      'Select--inline': props.showMore,
      'Select--add-option': props.hasAddOption,
      'Select--error': props.error,
      'Select--secondary': props.type === 'secondary',
      'Select--selected': props.value
    },
    props.className
  )

  return (
    <div className={classNames} style={{ ...widthStyleObj }} data-test-id={props.testId}>
      {props.selectLabel && <FieldLabel htmlFor="selectLabel">{props.selectLabel}</FieldLabel>}

      <Select
        id={props.id}
        styles={props.styles}
        menuPortalTarget={props.menuPortalTarget}
        name={props.name}
        closeMenuOnSelect={!props.isMulti}
        isDisabled={props.isDisabled}
        isClearable={props.isClearable}
        isMulti={props.isMulti}
        isSearchable={props.isSearchable}
        placeholder={props.placeholder}
        hideSelectedOptions={props.hideSelectedOptions}
        closeMenuOnScroll={true}
        menuShouldBlockScroll={false}
        menuShouldScrollIntoView={props.menuShouldScrollIntoView}
        menuPlacement={props.menuPlacement}
        menuIsOpen={props.menuIsOpen}
        maxMenuHeight={props.maxMenuHeight}
        minMenuHeight={props.minMenuHeight}
        onChange={handleChange}
        onBlur={props.onBlur}
        options={props.options}
        menuClassName={props.menuClassName}
        menuStyle={props.menuStyle}
        value={props.value}
        // captureMenuScroll={false}
        components={allComponents}
        backspaceRemovesValue={false}
        classNamePrefix={props.menuPortalTarget ? 'Portal' : 'Select'}
        isNested={props.isNested}
        addOption={props.addOption}
        hasAddOption={props.hasAddOption}
        addOptionText={props.addOptionText}
        showCount={props.showCount}
        selectedLabel={props.selectedLabel}
        getOptionLabel={props.getOptionLabel}
        getOptionValue={props.getOptionValue}
        noCheckBox={props.noCheckBox}
        showMore={props.showMore}
        className={props.className}
        noOptionsMessage={props.noOptionsMessage}
        isOptionSelected={props.isOptionSelected}
        aria-label={'cs-select-aria'}
        filterOption={props.filterOption}
        isOptionDisabled={props.isOptionDisabled}
        onMenuOpen={props.onMenuOpen}
        tabSelectsValue={props.tabSelectsValue}
        onKeyDown={props.onKeyDown}
        ref={ref}

      />
    </div>
  )
})

SelectField.defaultProps = {
  testId: "cs-select",
  maxMenuHeight: 283,
  isDisabled: false,
  isClearable: false,
  isMulti: false,
  isSearchable: false,
  hideSelectedOptions: false,
  tabSelectsValue: true
}
export default React.memo(SelectField)

// const Menu = (menuStyle?: object, menuClassName?: string) => {
//   return (props) => {
//     const { isDisabled, children, selectProps, ...rest } = props
//     return (
//       <components.Menu {...props}>
//         <div style={menuStyle}>
//           {children}
//           <div onClick={selectProps.addOption} className={`Select__add-option flex-v-center ${menuClassName}`}>{selectProps.addOptionText}</div>
//         </div>
//       </components.Menu>
//     )
//   }
// }

// const Menu = (props) => {
//   const { isDisabled, children, selectProps, ...rest } = props;
//   let menuClassName = "Select__add-option flex-v-center";
//   if (selectProps && selectProps.menuClassName) {
//     menuClassName = `${menuClassName} ${selectProps.menuClassName}`
//   }
//   return (
//     <components.Menu {...props}>
//       <div style={selectProps.menuStyle}>
//         {children}
//         <div onClick={selectProps.addOption} className={menuClassName}>{selectProps.addOptionText}</div>
//       </div>
//     </components.Menu>
//   );
// };

import React from 'react'
import { defaultValue as defaultValueUtil, defaultProps as defaultPropsUtil, viewByName } from './util'

//Note: add any default value in defaultValueUtil object
export const defaultValue = {
  ...defaultValueUtil,
  withExportCta: {
    component: <></>,
    showExportCta: false
  }
}

const defaultProps = {
  ...defaultPropsUtil,
  withExportCta: {
    component: <></>,
    showExportCta: false
  }
}

export type InitialSortByProp = {
  id: string
  desc: boolean
}

export type RowSelectCheckboxProp = {
  key: string
  value: boolean
}

export type ActionCbArgProp = {
  data: any[]
  type: string
}

export type bulkActionList = {
  label: string | React.ReactNode
  cb: (actionCbArg: ActionCbArgProp) => void
  type?: string
  icon?: string
  showSelected?: boolean
}

export type NameProp = {
  singular: string
  plural: string
}
export type CustomDropDownListProp = {
  label: string | React.ReactNode
  action: (actionCbArg: ActionCbArgProp) => void
  default?: boolean
}
export type CustomDropdownOptionProp = {
  label: string
  value: string
  icon?: string
  className: string
  list: CustomDropDownListProp[]
  activeIcon: string
}

export type ColumnsProp = {
  Header: string
  accessor: string | Function
  default?: boolean
  disableSortBy?: boolean
  Cell?: (props: any) => React.ReactNode
  addToColumnSelector?: boolean | false
  id?: string
  cssClass?: string
  columnWidthMultiplier?: number
}

export type SortByProp = {
  id: string
  sortingDirection: string
}

export type FetchDataArgProp = {
  skip: number
  limit: number
  sortBy?: SortByProp | undefined
  startIndex: number
  stopIndex: number
  searchText?: string
  refresh?: boolean
}

export type ItemStatusMapProp = {
  [index: number]: 'loading' | 'loaded'
}

export type ResizedColumnProp = {
  [id: string]: number
}

export type TooltipListProp = {
  label: string | React.ReactNode
  title: string
  action: Function
  displayType: string
}

export type ExportCtaProp = {
  component: React.ReactNode
  showExportCta: boolean
}

export type RowDisableProp = {
  key: string
  value: boolean
}

export type v2FeaturesProp = {
  tableRowAction?: boolean
  pagination?: boolean
  tableBulkAction?: boolean
  isNewEmptyState?: boolean
  canFreezeCheckbox?: boolean
}

type DefaultProps = Readonly<typeof defaultProps>

export type TableInstanceRef = {
  setTablePage: Function
  setTablePageSize: Function
  pageSize: number
  activePage: number
}

export type TableProps = {
  /**
   * The core columns configuration object for the entire table
   */
  columns: ColumnsProp[]
  /**
   * Show and hide, column selectors filters
   */
  columnSelector?: boolean
  /**
   * The Data array that you want to display on table
   */
  data: any[]
  /**
   * Used in rowselect header and table panel items counts
   */
  name?: NameProp
  /**
   * If a column's ID is contained in this array, it will be hidden
   */
  hiddenColumns?: string[]
  /**
   * used for sort data in table initially
   */
  initialSortBy?: InitialSortByProp[]
  /**
   * Calls when searchText, sortBy changes with `FetchDataArgProp`
   */
  fetchTableData: (fetchTableDataArg: FetchDataArgProp) => void
  /**
   * Click handler to attach onrowclick, calls with row data
   */
  onRowClick?: (e: any) => void
  /**
   * Used in other parts of table to track status of data
   */
  loading?: boolean
  /**
   * Pass true to add checkbox in row
   */
  isRowSelect?: boolean
  /**
   * Used for bulk operation on selected row
   */
  bulkActionList?: bulkActionList[]
  /**
   * Used for bulk operation on selected row, prop deprecated use bulkActionList
   */
  onRowSelectProp?: bulkActionList[]
  /**
   * Used internally for table operation, rendering unique row data
   */
  uniqueKey: string
  /**
   * Used for conditinally add select box in a row
   */
  rowSelectCheckboxProp?: RowSelectCheckboxProp
  /**
   * pass the rowIds which will be selected at table initial load
   */
  initialSelectedRowIds?: object
  /**
   * calls getSelectedRow method  with all selected row ids
   */
  getSelectedRow?: Function
  /**
   * Min batch size or data size(limit) to fetch on scroll
   */
  minBatchSizeToFetch?: number
  /**
   * Table uses this to track based of value of index if
   * loading shows skeleton loader, if loaded shows data
   */
  itemStatusMap?: ItemStatusMapProp
  /**
   * Table calls this to fetch data with `FetchDataArgProp`
   */
  loadMoreItems?: (loadMoreItemsArg: FetchDataArgProp) => void
  /**
   * Total counts of all data
   */
  totalCounts: number
  /**
   * Size of a item basically row height
   */
  itemSize?: number
  /**
   * Pass it if you need to control scroll or smth with ref
   */
  fixedlistRef?: any
  /**
   * Show and hide table view selector
   */
  viewSelector?: boolean
  /**
   * Header text when there is no data available
   */
  emptyHeading?: any
  /**
   * Description for emptystate
   */
  emptyDescription?: any
  /**
   * we can also use this prop for empty state of table
   */
  emptyObj?: any
  /**
   *Get table view when changes, Comfort or Compact
   */
  getViewByValue?: (selectedViewBy: keyof typeof viewByName) => void
  /**
   * get position  value of scrollOffset
   */
  getScrollPostion?: (scrollOffset: number) => void
  /**
   * Pass it for Actions tooltip on hover of row
   */
  tableRowActionList?: Array<TooltipListProp>
  /**
   * use this props to make the entire row of table selectable
   */
  fullRowSelect?: boolean
  /**
   * Pass true to add search bar on top of table
   */
  canSearch?: boolean
  /**
   * Pass true to add refresh icon on top of table
   */
  canRefresh?: boolean
  /**
   * Callback function to be called when refresh icon is clicked
   */
  onRefresh?: Function
  /**
   * Placeholder for search
   */
  searchPlaceholder?: string
  /**
   * Input value for search
   */
  searchValue?: string
  /**
   * Pass it for single row select, use onRowClick to set it
   */
  singleSelectedRowId?: string
  /**
   * Height for table
   */
  tableHeight?: number
  /**
   * Pass it for different set of Actions tooltip on hover of row based on some condition
   */
  onHoverListCondition?: any
  /**
   * determines if single row of table is selectable or not based key and value passed in obj to this prop
   */
  conditionalSingleSelect?: any
  /**
   * With every action that is dispatched to the table's internal `React.useReducer` instance,
   * this reducer is called and is allowed to modify the final state object for updating.
   */
  tableStateReducer?: any
  /**
   * decides maximum selected of the data of table
   */
  maxSelect?: number
  /**
   * table will use this to load with initial selected data
   */
  initialRowSelectedData?: any[]
  /**
   * Pass the number of static row you add in the table
   */
  staticRowCount?: number
  // selectKeyList?: string[]
  /**
   * will make all columns of table equal in width
   */
  equalWidthColumns?: boolean
  /**
   * calls with updated columnSelector data  when column selector is changed
   */
  onToggleColumnSelector?: Function
  /**
   * use this when we want open in new tab support for table row that has link
   */
  linkToRow?: string | Function
  /**
   * load custom component beside viewSeletor and columnSelector
   */
  withExportCta?: ExportCtaProp
  testId?: string
  customRowAdd?: boolean
  onSaveChange?: Function
  resetSearch?: boolean
  hideTablePanel?: boolean
  LinkComponent?: Function
  customRowComponent?: React.ReactNode
  /**
   * Used for conditinally disable a row
   */
  rowDisableProp?: RowDisableProp
  /**
   * calls onHoverText method  with current row data
   */
  onRowHoverText?: Function
  /**
   * Used for conditinally disable a table resize ß
   */
  isResizable?: boolean
  /**
   * calls for setting the resize widths in localstorage
   */
  onResizeColumn?: Function
  /**
   * Used to get the resized widths from localstorage
   */
  resizedColumnWidths?: ResizedColumnProp
  /**
   * Pass true to enable ordering of table column from column selector
   */
  canOrderColumn?: boolean
  /**
   * Table uses order of column's ID array to set its column order
   */
  columnsOrder?: string[]
  /**
   * calls with updated column orders when column's orders is changed
   */
  onChangeColumnOrder?: Function
  /**
   * Used to support freeze column behaviour
   */
  canFreezeColumn?: boolean
  /**
   * Used to provide custom option in settings dropdown
   */
  dropdownCustomOptions?: CustomDropdownOptionProp[]
  /**
   * calls for setting the freezed column in local storage
   */
  onColumnFreeze?: Function
  /**
   * Used to get the freezed column from local storage
   */
  freezedColumns?: string[]
  /**
   * Used to set initialRowPerPage in Pagination Table
   */
  initialPageSize?: number
  // updatePageSize?: (num: number) => void
  /**
   * Used to set initialPage in Pagination Table
   */
  initialPage?: number
  // updatePage?: (pageNum: number) => void
  /**
   * Used to set rowPerPageOptions in Pagination Table
   */
  rowPerPageOptions?: number[]
  /**
   * Enable table v2 specific features
   */
  v2Features?: v2FeaturesProp

  tableInstanceRef?: any

  onChangePagination?: any

  showSelected?: boolean

  countExceedPompt?: string
} & Partial<DefaultProps>

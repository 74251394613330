import React, { useRef, useState, useEffect, createContext } from 'react'
import ReactDOM from 'react-dom'
import Icon from '../Icon2/Icon'
import FieldProperties from '../FieldProperties/FieldProperties'
import infoModal from '../InfoModal/InfoModal'
import Tooltip from '../Tooltip/Tooltip'
import { addFieldName, wrapperStyle, fieldForm } from './helper'
import { getTestIdFormatter } from '../../utils/helpers'
import { isEnterPressed } from 'utils/keyboardUtils'
import './ContentTypeField.css'

const ContentTypeFieldContext: any = createContext({})

const useContentTypeFieldContext = () => {
  const context = React.useContext(ContentTypeFieldContext)
  return context
}

export const ContentTypeFieldProvider = (props: any) => {
  const state = useState()
  return <ContentTypeFieldContext.Provider value={state}>{props.children}</ContentTypeFieldContext.Provider>
}

const ContentTypeField = (props) => {
  const {
    field,
    options,
    setOptions,
    parentInfo,
    onFieldDelete,
    index,
    disable,
    globalFieldOptions,
    extensionFieldOptions,
    referenceContentType,
    jsonRteContentType,
    rteContentType,
    disableDelete,
    updatedState,
    selectAsNumberText,
    selectAsKeyValue,
    selectOptionsLimit,
    disableSetting,
    currentFieldPath,
    enableEmbeddedObj,
    canDrag,
    connectDragSource,
    t,
    isNonLocalizable,
    hasNonLocalizablePlan,
    isCTBuilder,
    isSingle
  } = props
  const fieldPropRef = useRef(null)
  const fieldOptionRef = useRef(null)

  const [initialField, setField] = useState(field)

  let [isActive, setIsActive] = useState(false)

  const [contextState, setContextState]: any = useContentTypeFieldContext()
  const handleDoubleClick = () => {
    onSettingsClick()
  }
  const handleDeleteClick = () => {
    onFieldDelete && onFieldDelete()
  }
  let handle

  const byDefaultTitleAndUrl = (field) => {
    if ((field.uid === 'title' || field.uid === 'url') && field.field_metadata?._default) {
      return true
    }
  }

  const handleSettingsClick = () => {
    if (fieldPropRef.current.childNodes.length === 0) {
      if (contextState && Object.keys(contextState).length) {
        contextState.closeModal()
        setContextState({})
      }
      onSettingsClick()
    } else {
      setIsActive(false)
      destroy(fieldPropRef.current.childNodes[0])
    }
  }


  const handleSettingKeyPress = (e: any): void => {
    if (isEnterPressed(e.key)) {
      e.preventDefault()
      e.stopPropagation()
      handleSettingsClick()
    }
  }

  const handleDeleteKeyPress = (e: React.KeyboardEvent<HTMLDivElement>): void => { if (isEnterPressed(e.key)) { handleDeleteClick() } }

  const renderComponent = () => {
    return (
      <div
        className={[
          'ContentTypeField__details',
          canDrag ? 'draggableHandler' : '',
          field.error ? 'ContentTypeField__error' : ''
        ].join(' ')}
        onDoubleClick={handleDoubleClick}>
        <div className="ContentTypeField__field-name">
          <Icon icon={field.iconName} />
        </div>
        <h4 className="ContentTypeField__display-name flex-v-center">
          {field.display_name}
          {byDefaultTitleAndUrl(field) ? <span className="ContentTypeField__property">Default field</span> : null}
          {((field.multiple && field.data_type !== 'blocks') ||
            (field.data_type === 'reference' && field.field_metadata.ref_multiple)) && (
              <span className="ContentTypeField__property">Multiple</span>
            )}
          {field.non_localizable && <span className="ContentTypeField__property">Non localizable</span>}
          {field.field_metadata && field.field_metadata.isTitle && (
            <span className="ContentTypeField__property">Group Title</span>
          )}
        </h4>
        {!disable && (
          <div className="ContentTypeField__options">
            {!disableSetting ? (
              <div
                className="ContentTypeField__option"
                data-test-id={`cs-ct-${getTestIdFormatter(field?.display_name)}-option-properties`}
                ref={fieldOptionRef}
                onClick={handleSettingsClick}
                tabIndex={0}
                onKeyUp={handleSettingKeyPress}
                aria-label="open-field-settings"
              >
                <Tooltip content="Properties" position="top">
                  <Icon icon="Settings" />
                </Tooltip>
              </div>
            ) : null}
            {!disableDelete ? (
              <div role='button' tabIndex={0} onClick={handleDeleteClick} onKeyUp={handleDeleteKeyPress} className="ContentTypeField__option" data-test-id={`cs-ct-${getTestIdFormatter(field?.display_name)}-option-delete`}>
                <Tooltip content="Delete" position="top">
                  <Icon icon="Delete" />
                </Tooltip>
              </div>
            ) : null}
            {canDrag ? (
              <div className="ContentTypeField__option draggableHandler" data-test-id={`cs-ct-${getTestIdFormatter(field?.display_name)}-option-move`}>
                <Tooltip content="Move" position="top">
                  <Icon icon="MoveIcon" />
                </Tooltip>
              </div>
            ) : null}
          </div>
        )
        }
      </div >
    )
  }


  if (canDrag) {
    handle = connectDragSource(renderComponent(), {
      dropEffect: 'copy'
    })
  }

  const onClose = () => {
    setIsActive(false)
  }

  const handleChange = () => {
    setField({ ...field })
    updatedState()
  }

  const isURlField = (field: any) => {
    if (field && field.fieldUid === 'url' && field.field_metadata && field.field_metadata._default) {
      return true
    }
    return false
  }

  const onSettingsClick = () => {
    let uid: any = field && field.fieldIdentifier
    const isUrl: any = isURlField(field)
    setIsActive(!isActive)
    delete field.openSettingFlag
    let key = `${uid}.${index}`
    let basicForm = fieldForm(
      field,
      options,
      parentInfo,
      key,
      globalFieldOptions,
      extensionFieldOptions,
      referenceContentType,
      jsonRteContentType,
      rteContentType,
      handleChange,
      'basic',
      selectAsNumberText,
      selectAsKeyValue,
      selectOptionsLimit,
      currentFieldPath,
      enableEmbeddedObj,
      hasNonLocalizablePlan,
      isCTBuilder,
      t,
      setOptions
    )
    let advancedForm = fieldForm(
      field,
      options,
      parentInfo,
      key,
      globalFieldOptions,
      extensionFieldOptions,
      referenceContentType,
      jsonRteContentType,
      rteContentType,
      handleChange,
      'advanced',
      selectAsNumberText,
      selectAsKeyValue,
      selectOptionsLimit,
      currentFieldPath,
      enableEmbeddedObj,
      hasNonLocalizablePlan,
      isCTBuilder,
      t,
      setOptions,
      isNonLocalizable
    )
    let returnObj = infoModal({
      component: (props) => (
        <>
          <FieldProperties
            {...props}
            basicForm={basicForm}
            advancedForm={advancedForm}
            title={field.fieldName || field.field_name}
            icon={field.iconName}
            isSingle={isSingle}
            isCTBuilder={isCTBuilder}
            isUrl={isUrl}
            updatedState={updatedState}
          />
        </>
      ),
      modalProps: {
        onClose,
        targetNodeOrId: fieldPropRef.current,
        closeOnOverlayClick: true,
        closeOnEscape: true,
        wrapperStyle,
        ignoreContainers: ['ReactModalPortal', 'ReactModal__Content', 'Table__body__row', 'Infomodal--field-setting-box']
      },
      alignment: 'field-setting-box'
    })

    const findAncestor: any = (el: any, cls: any) => {
      if (el && el.classList && el.classList.length && el.classList.contains(cls)) {
        return el
      }
      if (el && el.parentElement) {
        if (el.classList && el.classList.length && el.classList.contains(cls)) {
          return el
        } else {
          el = el.parentElement
          return findAncestor(el, cls)
        }
      } else {
        return false
      }
    }

    setContextState(returnObj)

    setTimeout(() => {
      let PageLayout__body: any = document.querySelector('.PageLayout__body')
      let FieldProperties: any = document.querySelector('.FieldProperties')
      let CurrElem = findAncestor(fieldOptionRef.current, 'ContentTypeField')
      let CurrElemOffset = CurrElem.getBoundingClientRect().top - 56

      let oldTimeStamp = null
      let scrollY = PageLayout__body?.scrollTop
      let scrollDist
      scrollDist =
        PageLayout__body?.scrollTop +
        (CurrElemOffset + FieldProperties?.offsetHeight - PageLayout__body?.offsetHeight) +
        20

      function step(newTimestamp) {
        if (oldTimeStamp !== null) {
          // if duration is 0 scrollY will be -Infinity
          scrollY += (scrollDist * (newTimestamp - oldTimeStamp)) / 500

          PageLayout__body.scrollTop = scrollY
          if (scrollY > scrollDist) {
            scrollY = scrollDist
            return (PageLayout__body.scrollTop = scrollDist)
          }
        }
        oldTimeStamp = newTimestamp
        window.requestAnimationFrame(step)
      }
      if (CurrElemOffset + FieldProperties?.offsetHeight > PageLayout__body?.offsetHeight) {
        window.requestAnimationFrame(step)
      }
    })
  }

  function destroy(node: any) {
    if (node) {
      const unmountResult = ReactDOM.unmountComponentAtNode(node)
      if (unmountResult) {
        node.remove()
      }
    }
  }

  useEffect(() => {
    if (fieldPropRef.current) {
      if (fieldPropRef.current.childNodes.length === 0) {
        //if current fieldProp modal is removed by any other newly opening modal, then we need to set the isActive to 'false'
        setIsActive(false)
      }
    }
  }, [contextState])

  useEffect(() => {
    field && addFieldName(field, parentInfo)
    if (fieldOptionRef && fieldOptionRef.current && field && field.openSettingFlag) {
      fieldOptionRef.current.click()
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [])

  return (
    <>
      <div
        className={`ContentTypeField ${isActive ? ' ContentTypeField--active' : ''} ${field.data_type === 'group' && field.schema.length === 0 ? 'ContentTypeField--group' : ''
          }`}>
        <div ref={fieldPropRef}></div>
        {canDrag ? (
          handle
        ) : (
          renderComponent()
        )}
      </div>
    </>
  )
}

export default React.memo(ContentTypeField)

import React from 'react'
import { useSlate } from 'slate-react'
import { cx } from '@emotion/css'

import Icon from './TableAddRowIcon'
import { Tooltip } from '../../../../../../../index'

import { insertRowAtEnd } from '../utils/insertRowAtEnd'
//@ts-ignore
import styles from '../style.module.css'

export default (props) => {
  const { tablePath, isSelected, isNodeRTLDirected } = props
  const editor = useSlate()
  return (
    <div
      data-testid='table-footer'
      data-type="ignore-deserializer"
      contentEditable={false}
      className={cx(styles['table-row-add'], "scrte-table-row-add")}
      onClick={(e) => {
        e.preventDefault()
        insertRowAtEnd(editor, tablePath)
      }}
    >
      <span className={cx(styles['scrte-table-footer-dnd-extra-cell'], isNodeRTLDirected && styles['scrte-table-footer-dnd-extra-cell-rtl'])}></span>
      <Tooltip content="Add Row" position="bottom">
        <div contentEditable={false} className={styles['table-footer-icon-text-wrapper']}>
          <Icon></Icon>
          <span className={styles['table-footer-text']}>New</span>
        </div>
      </Tooltip>
      <span className={styles['scrte-table-footer-dnd-extra-cell-end']}></span>
    </div>
  )
}

import React, { useEffect, useRef } from 'react'

/**
 * Used when we need run an effect only when component is updated
 * @param effect - side effect (function) to be run after updated
 * @param deps - dependancy array as passed to useEffect
 */
export function useDidUpdate(effect = () => {}, deps: Array<string | number | boolean> = []) {
  const hasComponentMounted = useRef(false)
  useEffect(() => {
    if (hasComponentMounted.current) {
      return effect()
    }
    hasComponentMounted.current = true
  }, deps)
}


import React, { forwardRef, RefObject, useEffect, useRef, useState } from 'react'
import { middleTruncate, truncate } from '../AdvanceSearch/util/helpers'
import Tooltip from '../Tooltip/Tooltip'
import './Truncate.css'
import { useResizeObserver } from './use-resize-observer'

export type TruncateProps = {
  children: string
  maxChar?: number
  tooltip?: TruncateTooltipProps
  isResponsive?: boolean
  testId?: string
  truncateFrom?: 'end' | 'middle'
  endCharCount?: number
  multilineCount?: number
}

export type TruncateTooltipProps = {
  position:
  | 'bottom'
  | 'top'
  | 'left'
  | 'right'
  | 'bottom-start'
  | 'bottom-end'
  | 'top-start'
  | 'top-end'
  | 'left-start'
  | 'left-end'
  | 'right-start'
  | 'right-end'
  showArrow?: boolean
  disabled?: boolean
  variantType?: 'dark' | 'light' | 'menuIcon' | 'basic'
  type?: 'primary' | 'secondary' | 'dynamic'
  maxWidth?: any
  [propsKey: string]: any
}

export const Truncate = forwardRef(({ children, maxChar, tooltip, isResponsive, testId, truncateFrom, endCharCount, multilineCount }: TruncateProps, nodeRef) => {
  const refDefault = useRef(null)
  const truncateRef = useRef(null)
  const wrapperRef = (nodeRef as RefObject<HTMLDivElement>) || refDefault

  const dimensions = useResizeObserver(wrapperRef)
  const [isToolTipDisabled, setIsToolTipDisabled] = useState(null)
  let { truncatedText } = truncate(children, maxChar)
  let endText = ""
  const isEndResponsive = truncateFrom === 'end' && isResponsive
  const isMultilineEndResponsive = truncateFrom === 'end' && isResponsive && multilineCount > 1
  if (truncateFrom === "middle") {
    const middleData = middleTruncate(children, maxChar, endCharCount, isResponsive)
    truncatedText = middleData.truncatedText;
    endText = middleData.endText;
  }

  const isEllipsisActive = () => {
    if (truncateFrom === "middle" || isEndResponsive) return wrapperRef?.current?.offsetWidth < wrapperRef?.current?.scrollWidth
    return wrapperRef?.current?.offsetWidth < truncateRef?.current?.scrollWidth
  }

  const setEllipses = () => {
    const isTruncated = isEllipsisActive()
    if (!isTruncated) {
      setIsToolTipDisabled(true)
    } else {
      setIsToolTipDisabled(false)
    }
  }

  const isMaxChar = () => {
    if (isResponsive) {
      setEllipses()
    }

    if (maxChar && !isResponsive) {
      children?.length <= maxChar ? setIsToolTipDisabled(true) : setIsToolTipDisabled(false)
    }
  }

  useEffect(() => {
    isMaxChar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions])

  if (truncateFrom === "middle" || isEndResponsive) {

    const textStyle = isMultilineEndResponsive ? { WebkitLineClamp: multilineCount } : undefined;

    return (
      <div className={`middle-truncate ${isMultilineEndResponsive ? 'multiline' : ''}`}  >
        <Tooltip content={children} position={tooltip.position as string} disabled={isToolTipDisabled} {...tooltip}>
          <>
            <div data-test-id={testId} ref={wrapperRef} className='middle-truncate-start' style={textStyle} >
              {truncateFrom === "middle" && !isResponsive ? truncatedText : children}
            </div>
            {truncateFrom === "middle" && <div className='middle-truncate-end'>{isToolTipDisabled ? '' : endText}</div>}
          </>
        </Tooltip>
      </div>
    )
  }

  return (
    <div ref={wrapperRef}>
      <Tooltip content={children} position={tooltip.position as string} disabled={isToolTipDisabled} {...tooltip}>
        <div
          ref={truncateRef}
          className="truncate-text"
          style={isResponsive ? { width: `${dimensions}px` } : null}
          data-test-id={testId}>
          {isResponsive ? children : truncatedText}
        </div>
      </Tooltip>
    </div>
  )
})

Truncate.defaultProps = {
  testId: 'cs-truncate',
  maxChar: 24,
  tooltip: { position: 'bottom' },
  isResponsive: false
}

import { Editor, Path } from 'slate';
import { ElementWithType } from 'utils/types';

export * from './createTable';
export * from './deleteTable';
export * from './insertHeader'
export * from './deleteHead';
export * from './insertRowBefore'
export * from './insertRowAfter';
export * from './deleteRow';

export * from './insertColBefore';
export * from './insertColAfter'
export * from './deleteCol';
export * from './createRowWithColSize';
export * from './calculateRowSize';
export * from './calculateColSize';
export * from './createCell'
export * from './createHeadCell'
export * from './createHeader'
export * from './mergeCells';

export * from './MergeUnMergeBtn'

export * from './headerToggleSwitch'
export * from './isColumnComplete'

export const ROW_EXCEED_COUNT = 400;

export const isRowCountExceeded = (editor:Editor, path:Path):boolean => {
  try{
    //@ts-ignore
    const tbody = Editor.above(editor, { at: path, match: (node) => node.type === 'tbody' })
    // @ts-ignore
    return tbody?.[0]?.children?.length > ROW_EXCEED_COUNT;
  } catch (err) {
    console.log('FAILED TO COUNT ROWS PRESENT IN TABLE');
    return false;
  }
}


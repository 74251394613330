import React from 'react'
import { useSlate } from 'slate-react'
import { Transforms } from 'slate'

import { HoveringToolbarProvider } from '../../../components/ContextProvider/HoveringToolbarProvider'
import { shouldAllowNewCommentThreadAtSelection } from './helpers'

export function RangeCommentIngressComponent(props) {
  const editor = useSlate()
  const discussionMetadata = React.useContext(HoveringToolbarProvider)
  if (!discussionMetadata) return null
  const { discussions, setActiveDiscussion, commentsEnabled } = discussionMetadata
  const handleMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (shouldDisableIcon({ editor })) return
    Transforms.setSelection(editor, editor.selection)
    setActiveDiscussion('new-range-comment')
    // @ts-ignore
    editor.createRangeCommentSelection = editor.selection
  }

  return (
    <React.Fragment>
      {commentsEnabled && props.children({
        handleMouseDown,
        isDisable: {
          func: shouldDisableIcon,
          params: { editor }
        },
        additionalParams: { editor }
      })}
    </React.Fragment>
  )
}

const shouldDisableIcon = ({ editor }) => {
  const shouldDisable = !shouldAllowNewCommentThreadAtSelection(editor, editor.selection)
  return shouldDisable
}

import React, { useState } from 'react'
import './AutomationCloseAvatar.css'
import '../DropDown/Dropdown'
import Dropdown from '../DropDown/Dropdown'
import Tooltip from '../Tooltip/Tooltip';
import cbModal, { ModalFooter, ModalHeader, ModalBody } from '../Modal/Modal'
import Button from '../Button/Button'
import Search from '../Search/Search'

const defaultProps = {
  avatars: [],
  showPlusSignAfter: 5,
  type: 'text',
  showExtraType: 'button',
  showExtraColor: 'light',
  size: 'small',
  showStatus: false
}

type DefaultProps = Readonly<typeof defaultProps>

export type AvatarProps = {
  avatars?: Array<AvatarData>
  showPlusSignAfter?: number
  type?: 'text' | 'image'
  showExtraType?: string
  showExtraColor?: string
  size?: 'small' | 'large' | 'extra-large'
  showStatus?: boolean
  isToHideTooltip?: boolean
} & Partial<DefaultProps>

export type AvatarData = {
  id: number
  name: string
  image: string
  email: string
  avatarColor: string
}

function displayAvatarContent(type, avatar) {
  let nameInitials = avatar && avatar.name && avatar.name.split(' ')
  let firstInitial = nameInitials[0]
  let lastInitial = nameInitials[nameInitials.length - 1]
  let nameInitialsShort = firstInitial.substring(0, 1) + lastInitial.substring(0, 1)
  if (type === 'image') {
    return <img src={avatar.image} alt={avatar.name} className="AutomationCloseAvatar__image" />
  }
  return nameInitialsShort
};

const searchUser = (users, searchText = '') => {
  if (searchText) {
    const usersData = users.filter((user) => {
      return user.name.toLowerCase().includes(searchText.toLowerCase())
    })
    return usersData;
  }
  return users;
}
const PopUpComponent = (props) => {
  const { avatars, closeModal } = props;
  const [searchText, setSearchText] = useState('')
  const updateSearchText = (value) => {
    setSearchText(value)
  }
  const filterUsers = searchUser(avatars, searchText)

  return (
    <>
      <ModalHeader
        title={<div className="popUp-body__header">Members <div>{avatars.length}</div></div>}
        closeModal={closeModal}
      />
      <ModalBody className='popUp-body__container'>
        <div >
          <div className='popUp-body__search'>
            <Search
              debounceSearch={true}
              value={searchText}
              placeholder={"Search members"}
              onChange={updateSearchText}
            />
          </div>
          <ul>
            {filterUsers.length ?
              filterUsers.map((user, i) => {
                return (
                  <li key={i}>{user.name}</li>
                )
              }) :
              <li>No matching members found</li>}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button buttonType="light" onClick={closeModal}>Cancel</Button>
      </ModalFooter>
    </>
  )
}

function renderExtraAvatarsButton(showExtraAvatars, showExtraType, extraCount, showExtraColor, avatars) {

  const eventStop = (event: any) => {
    event.preventDefault();
  }

  const popUpMembersList = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    cbModal({
      component: props => <PopUpComponent {...props} avatars={avatars} />,
      modalProps: {
        size: 'tiny',
      },
    })
  }

  if (showExtraAvatars) {
    if (showExtraType === 'button') {
      return (
        <button
          className={`AutomationCloseAvatarList__expand-button${extraCount > 9 ? ' AutomationCloseAvatarList__expand-button--tiny-text' : ''
            }`}
          onClick={eventStop}
        >
          +{extraCount}
        </button>
      )
    } else if (showExtraType === 'link') {
      return (
        <a
          className={`AutomationCloseAvatarList__expand-link${showExtraColor === 'dark' ? ' AutomationCloseAvatarList__expand-link--dark' : ''
            }`}
          onClick={popUpMembersList}
        >
          +{extraCount} others
        </a>
      )
    }
  }
}

function createExtraAvatarList(avatars, type) {
  return avatars.map(avatar => {
    return {
      label: (
        <div className="AutomationCloseAvatar__profile">
          <span className="AutomationCloseAvatar__image">
            {displayAvatarContent(type, avatar)}
          </span>
          <span className="AutomationCloseAvatar__name">{avatar.name}</span>
        </div>
      ),
      action: () => {
        console.log('clicked on: ', avatar.name)
      },
    }
  })
}

function AutomationCloseAvatar({ avatars, showPlusSignAfter, type, showExtraType, showExtraColor, size, showStatus, isToHideTooltip }: AvatarProps) {
  if (avatars.length === 1) {
    let avatar = avatars[0]
    return (
      <div>
        {isToHideTooltip ? <div style={{ backgroundColor: avatar.avatarColor }} className="AutomationCloseAvatar AutomationCloseAvatar--single">
          <span className="AutomationCloseAvatar__link" style={{ color: !avatar.avatarColor ? '#718096' : '#fffff' }}>
            {displayAvatarContent(type, avatar)}
          </span>
          {showStatus && avatar.status === 'active' && <span className="AutomationCloseAvatar__active"></span>}
        </div> : <Tooltip content={avatar.name || avatar.email} position="bottom" showArrow={false}>
          <div style={{ backgroundColor: avatar.avatarColor }} className="AutomationCloseAvatar AutomationCloseAvatar--single">
            <span className="AutomationCloseAvatar__link" style={{ color: !avatar.avatarColor ? '#718096' : '#fffff' }}>
              {displayAvatarContent(type, avatar)}
            </span>
            {showStatus && avatar.status === 'active' && <span className="AutomationCloseAvatar__active"></span>}
          </div>
        </Tooltip>}
      </div>
    )
  } else {
    let showExtraAvatars = avatars.length > showPlusSignAfter ? true : false
    let visibleAvatars = []
    let hiddenAvatars = []
    if (showExtraAvatars) {
      visibleAvatars = avatars.slice(0, showPlusSignAfter)
      hiddenAvatars = avatars.slice(showPlusSignAfter)
    } else {
      visibleAvatars = avatars
    }

    // className="Avatar"
    return (
      <div className="AutomationCloseAvatarList">
        {visibleAvatars.map(avatar => {
          return (
            <div key={avatar.id}>
              {isToHideTooltip ? <div style={{ backgroundColor: avatar.avatarColor }} className={`AutomationCloseAvatar AutomationCloseAvatar--${size}`}>
                <span className="AutomationCloseAvatar__link" style={{ color: !avatar.avatarColor ? '#718096' : '#fffff' }}>
                  {displayAvatarContent(type, avatar)}
                </span>
                {showStatus && avatar.status === 'active' && <span className="AutomationCloseAvatar__active"></span>}
              </div> : <Tooltip content={avatar.name || avatar.email} position="bottom" showArrow={false}>
                <div style={{ backgroundColor: avatar.avatarColor }} className={`AutomationCloseAvatar AutomationCloseAvatar--${size}`}>
                  <span className="AutomationCloseAvatar__link" style={{ color: !avatar.avatarColor ? '#718096' : '#fffff' }}>
                    {displayAvatarContent(type, avatar)}
                  </span>
                  {showStatus && avatar.status === 'active' && <span className="AutomationCloseAvatar__active"></span>}
                </div>
              </Tooltip>}
            </div>
          )
        })}
        {renderExtraAvatarsButton(
          showExtraAvatars,
          showExtraType,
          hiddenAvatars.length,
          showExtraColor,
          avatars
        )}
        {/* <Dropdown
          list={createExtraAvatarList(hiddenAvatars, type)}
          type="click"
          className="AutomationCloseAvatar__dropdown"
        >
          {renderExtraAvatarsButton(
            showExtraAvatars,
            showExtraType,
            hiddenAvatars.length,
            showExtraColor
          )}
        </Dropdown> */}
      </div>
    )
  }
}

const AutomationCloseAvatarInfo = ({ name, email }) => {
  return (
    <div className="AutomationCloseAvatar__info">
      <h6 className="AutomationCloseAvatar__name">{name}</h6>
      <span className="AutomationCloseAvatar__email">{email}</span>
    </div>
  )
}

AutomationCloseAvatar.defaultProps = defaultProps

export default AutomationCloseAvatar
export { AutomationCloseAvatarInfo }

//@ts-nocheck
import React, { useState, useRef } from 'react'
import { Registry } from '@react-registry'
import { Transforms } from 'slate'
import { useSlateStatic, useFocused, useSelected, ReactEditor } from 'slate-react'
import { Resizable } from 're-resizable'
import isUrl from 'is-url'
import {
  cbModal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  ButtonGroup,
  TextInput,
  Icon,
  Field,
  ActionTooltip
} from '../../../../../../index'

import EmbededOverlay from './EmbededOverlay'
import { EmbedIcon } from '../../utils/Icon'
import getEmbedUrl from './getEmbedUrl'
import { handleDelete } from '../../utils/actions'
//@ts-ignore
import styles from './style.module.css'
import { cx } from '@emotion/css'
import { elementCategories } from '../elementCategories'
import { isIframe, getUrlFromIframe } from './EmbededOverlay'
import { srcDoc } from './srdDoc'

export const EmbededElement = ({ attributes, children, element, attrs }) => {
  let { url, width, height, 'class-name': iframeClassName = '' } = element.attrs
  const editor = useSlateStatic()
  const selected = useSelected()
  const focused = useFocused()
  const embedRef = useRef()
  const extraProps = {}
  if (!url) {
    extraProps['srcDoc'] = srcDoc`Iframe URL is undefined`
  }
  if (!isUrl(url) && !extraProps['srcDoc']) {
    extraProps['srcDoc'] = srcDoc`Iframe URL is invalid`
  }

  const onResizeStop = (event, dir, el, size) => {
    let newWidth = el.style.width
    if (newWidth.slice(-2) === 'px') {
      newWidth = parseInt(newWidth)
    }
    let height = el.style.height
    let heightLen = height.length
    height = Number(height.slice(0, heightLen - 2))
    let path = ReactEditor.findPath(editor, element)
    let { attrs } = element
    Transforms.setNodes(
      editor,
      { attrs: { ...attrs, width: newWidth, height } },
      {
        at: path
      }
    )
  }

  const marginAlignment = {
    center: { margin: 'auto' },
    left: { marginRight: 'auto' },
    right: { marginLeft: 'auto' }
  }
  const margin = attrs?.style?.["text-align"] ? marginAlignment[attrs?.style?.["text-align"]] : marginAlignment['left']
  const style = {
    ...(attrs.style ? attrs.style : {}),
    ...margin
  }

  const ModalComponent = (props) => {
    const [formData, setFormData] = useState({
      embeded_url: props.initialUrl || ''
    })

    const updateFormData = (e) => {
      e.persist()
      setFormData((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value
        }
      })
    }

    const handleOnOk = () => {
      props.closeModal()
      let path = ReactEditor.findPath(editor, element)
      let attrs = {}
      if (isIframe(formData['embeded_url'])) {
        attrs = getUrlFromIframe(formData['embeded_url'], true)
      }
      else {
        attrs = { ...element.attrs, url: getEmbedUrl(formData['embeded_url']) }
      }
      Transforms.setNodes(editor, { attrs }, { at: path })
    }
    return (
      <div>
        <ModalHeader title="Edit Embed Url" closeModal={props.closeModal} />

        <ModalBody>
          <Field labelText="URL">
            <TextInput
              name="embeded_url"
              onChange={updateFormData}
              value={formData.embeded_url}
              type="url"
              placeholder="Enter Embeded URL"
            />
          </Field>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button key="cancel" buttonType="light" onClick={() => props.closeModal()}>
              Cancel
            </Button>
            <Button key="add" icon="CheckedWhite" onClick={handleOnOk}>
              Update
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </div>
    )
  }

  const toolList = [
    {
      label: <Icon icon="Edit" size="small" />,
      title: 'Edit',
      action: () => {
        cbModal({
          component: (props) => <ModalComponent initialUrl={url} {...props} />
        })
      }
    },
    {
      label: <Icon icon="Trash" size="small" />,
      title: 'Delete',
      action: () => {
        handleDelete(editor, element)
      }
    }
  ]
  return (
    <div {...attributes} >
      {children}
      <div contentEditable={false} className={styles['embed-wrapper']}>
        <Resizable
          onResizeStop={onResizeStop}
          minHeight={150}
          minWidth={150}
          defaultSize={{
            width: width || "100%",
            height: `${height || 300}px`
          }}
          contentEditable={false}
          style={style}>
          <div className={styles['scrte--iframe-wrapper']}>
            <ActionTooltip list={toolList} className={styles['scrte--action-tooltip']}>
              <iframe
                ref={embedRef}
                data-testid="embed-iframe"
                src={`${url}?title=0&byline=0&portrait=0`}
                frameBorder="0"
                className={cx(styles['scrte--iframe'], {
                  [styles['scrte--embed-selected']]: selected && focused
                }, iframeClassName)}
                style={{ ...element.attrs.style }}
                sandbox="allow-scripts allow-popups allow-top-navigation-by-user-activation allow-forms allow-same-origin allow-presentation"
                allowFullScreen={true}
                loading="lazy"
                {...extraProps}
              />
            </ActionTooltip>
          </div>
        </Resizable>
      </div>
      {children}
    </div>
  )
}

Registry.register(
  {
    Component: React.memo(EmbededElement),
    iconName: <EmbedIcon />,
    title: 'Video',
    subtitle: 'Embed Youtube, Vimeo or Sites',
    IngressComponent: EmbededOverlay,
    isContentStackElement: true,
    category: elementCategories.VIDEO
  },
  { id: 'embed', registry: 'element' }
)

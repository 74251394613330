import * as React from 'react'
import cn from 'classnames'
import { TimelineContext } from './Timeline'

export type TimelineItemProps = {
  className?: string
  showOnHover?: boolean
  style?: React.CSSProperties
  timelineName?: string
  onClick?: React.MouseEventHandler
}

const TimelineItem: React.FC<TimelineItemProps> = (props) => {
  const { children, showOnHover, timelineName, className, style, onClick } = props
  const { searchText } = React.useContext(TimelineContext)
  const [timelineitem, setTimelineitem] = React.useState({ right: false, left: false })
  const Ref = React.useRef(null)
  const classNames = cn(
    searchText && !timelineName?.toLowerCase().includes(searchText.toLowerCase()) && 'TimelineItem--Hide',
    searchText && timelineName?.toLowerCase().includes(searchText.toLowerCase()) && 'HideTimelineSeparator',
    'TimelineItem',
    showOnHover && 'TimelineItem--NotVisible',
    timelineitem.left ? 'MissingTimelineItemLeft' : 'TimelineItem',
    timelineitem.right ? 'MissingTimelineItemRight' : 'TimelineItem',
    className
  )

  React.useEffect(() => {
    const rightDiv = Ref.current?.querySelector('.TimelineRight')
    const leftDiv = Ref.current?.querySelector('.TimelineLeft')
    setTimelineitem({ right: !rightDiv, left: !leftDiv })
  }, [Ref.current])

  if (searchText && !timelineName?.toLowerCase().includes(searchText.toLowerCase())) {
    return null
  }

  return (
    <li style={{ ...style }} ref={Ref} onClick={onClick} className={classNames}>
      {children}
    </li>
  )
}
export default TimelineItem

import React from 'react'
import { Registry } from '@react-registry'
import { Element, Editor, Range } from 'slate'
import { useSlate, useSlateStatic, ReactEditor, useSelected } from 'slate-react'
import { cx } from "@emotion/css"

import Paragraph from './paragraph'
import { DndBlock, DndElement } from '../../components/Dnd'
import { attrsProvider, lineHeightProvider } from '../utils/attributesProvider'
//@ts-ignore
import styles from './style.module.css'
import { handleInitialRTLDirection } from './textDirection/utils'
import { DiscussionHOC } from '../../components/Discussion'

export default ({ discussion, ...props }) => {
  const { element } = props
  let extraAttrs = {}
  let registryComponent = Registry.get({
    id: element.type,
    registry: 'v2.element',
    conditions: {
      overide: true
    }
  });

  //@ts-ignore
  if (registryComponent?.shouldOverride && !registryComponent.shouldOverride(element)) {
    const defaultComponent = Registry.get({
      id: element.type,
      registry: 'v2.element',
      conditions: {
        overide: undefined
      }
    })
    if (defaultComponent) {
      registryComponent = defaultComponent
    }
  }
  const editor = useSlateStatic();
  const { selection } = editor;
  const path = ReactEditor.findPath(editor as ReactEditor, element);
  const isNodeRTLDirected = (element?.attrs?.style?.direction || props?.attributes?.dir) === 'rtl';

  handleInitialRTLDirection(editor, props)

  if (!registryComponent) {
    return (
      <DndBlock singleLineRef={props.singleLineRef} path={path} editor={editor} disablegriddnd={true} setTriggerRerenderingAfterDnd={props.setTriggerRerenderingAfterDnd}>
        <DndElement path={path} elementtype='p' setTriggerRerenderingAfterDnd={props.setTriggerRerenderingAfterDnd} isNodeRTLDirected={isNodeRTLDirected} editor={editor}>
          <Paragraph
            {...props}
            attrs={attrsProvider(element)}
            extraAttrs
            slatePath={path}
            elementtype={element.type}
          />
        </DndElement>
      </DndBlock>
    )
  }
  const { dndOptions, beforeElementRender }: any = registryComponent;
  let { Component: RegistryElement }: any = registryComponent;

  const Component = DiscussionHOC(RegistryElement, discussion, editor, element, path)
  let { DisableDND, DisableSelectionHalo, getDroppableContainer, PreviewStyleClass, DisableGridDnd } = dndOptions || {}


  let selectableHalo = false;

  if (selection) {
    const range = Editor.range(editor, path)
    const intersection = Range.intersection(range, selection)
    if (range && intersection) {
      if (Range.equals(range, intersection) && Range.isExpanded(selection)) {
        selectableHalo = true
      }
    }
  }
  //Find parent type and import modify renderer
  const parentElement: any = Editor.parent(editor, path)
  if (parentElement?.[0]?.type) {
    const { beforeChildrenRender }: any = Registry.get({
      id: String(parentElement[0].type),
      registry: 'v2.element',
      conditions: {
        overide: true
      }
    }) || {}
    if (beforeChildrenRender) {
      let basicProps = {
        DisableDND,
        DisableSelectionHalo,
        element,
        path,
        editor,
        extraAttrs,
        parentElement,
      }
      beforeChildrenRender(basicProps)
      DisableDND = basicProps.DisableDND
      DisableSelectionHalo = basicProps.DisableSelectionHalo
      extraAttrs = basicProps.extraAttrs
    }
  }

  if (beforeElementRender) {
    const isSelected = useSelected()
    let basicProps = {
      DisableDND,
      DisableSelectionHalo,
      element,
      path,
      editor,
      isSelected,
      extraAttrs
    };
    beforeElementRender(basicProps)
    DisableDND = basicProps.DisableDND
    DisableSelectionHalo = basicProps.DisableSelectionHalo
    extraAttrs = basicProps.extraAttrs
  }
  const isChildrenEmpty = !!(
    Element.isElement(element) &&
    element.children.length === 1 &&
    //@ts-ignore
    element.children[0].text === ''
  )

  const getIsParentTableCell= (editor, path) => {
    const [parent] = Editor.parent(editor, path)
    // @ts-ignore
    return parent?.type === 'td' || parent?.type === 'th'
  }
  
  if (Component) {
    const isParentTableCell = getIsParentTableCell(editor,path);
    DisableDND = isParentTableCell ? isParentTableCell : DisableDND;
    if (DisableDND) {
      if (DisableSelectionHalo) {
        return (
          <Component
            {...props}
            attrs={attrsProvider(element)}
            extraAttrs
            isChildrenEmpty={isChildrenEmpty}
            slatePath={path}
          />
        )
      }
      return (
        <div className={cx(selectableHalo ? styles['selection-halo'] : '', 'scrte-selection-halo')}>
          <Component
            {...props}
            attrs={attrsProvider(element)}
            extraAttrs
            isChildrenEmpty={isChildrenEmpty}
            slatePath={path}
          />
        </div>
      )
    }
    let container = getDroppableContainer?.(element.type, path) || 'doc'

    if (DisableSelectionHalo) {
      return (
        <React.Fragment>
          <DndBlock singleLineRef={props.singleLineRef} path={path} editor={editor} collection={container} disablegriddnd={DisableGridDnd} setTriggerRerenderingAfterDnd={props.setTriggerRerenderingAfterDnd}>
            <DndElement path={path} {...lineHeightProvider(element)} elementtype={element.type} collection={container} previewstyleclass={PreviewStyleClass} setTriggerRerenderingAfterDnd={props.setTriggerRerenderingAfterDnd} isNodeRTLDirected={isNodeRTLDirected} editor={editor}>
              <Component
                {...props}
                attrs={attrsProvider(element)}
                extraAttrs
                isChildrenEmpty={isChildrenEmpty}
                slatePath={path}
              />
            </DndElement>
          </DndBlock>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <DndBlock
          singleLineRef={props.singleLineRef}
          path={path}
          element={element}
          editor={editor}
          collection={container}
          disablegriddnd={DisableGridDnd}
          setTriggerRerenderingAfterDnd={props.setTriggerRerenderingAfterDnd}>
          <DndElement
            path={path}
            element={element}
            {...lineHeightProvider(element)}
            elementtype={element.type}
            collection={container}
            previewstyleclass={PreviewStyleClass}
            setTriggerRerenderingAfterDnd={props.setTriggerRerenderingAfterDnd}            
            isNodeRTLDirected={isNodeRTLDirected}
            editor={editor}>
            <div className={cx(selectableHalo ? styles['selection-halo'] : '', 'scrte-selection-halo')}>
              <Component
                {...props}
                attrs={attrsProvider(element)}
                extraAttrs
                isChildrenEmpty={isChildrenEmpty}
                slatePath={path}
              />
            </div>
          </DndElement>
        </DndBlock>
      </React.Fragment>
    )
  }
}



export const PlainElement = (props) => {
  const { element } = props
  const editor = useSlate()
  const path = ReactEditor.findPath(editor as ReactEditor, element)
  const registeredComponent = Registry.get({
    id: element.type,
    registry: 'v2.element',
    conditions: {
      overide: true
    }
  })
  if (registeredComponent) {
    const { Component, ReadOnlyComponent }: any = registeredComponent
    if (ReadOnlyComponent)
      return (
        <ReadOnlyComponent
          {...props}
          attrs={attrsProvider(element)}
          slatePath={path}
        />
      )
    return (
      <Component {...props} attrs={attrsProvider(element)} slatePath={path} />
    )
  }

  return <Paragraph {...props} attrs={attrsProvider(element)} />
}

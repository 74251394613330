import React from 'react'
import { Transforms } from 'slate'
import { Registry } from '@react-registry'

import linkReferenceButton, { ShortcutOpenEntryAsLinkOverlay } from './linkReferenceButton'
import { elementCategories } from '../../../elementCategories'
import { cx } from '@emotion/css'
//@ts-ignore
import styles from './style.module.css';
import { EmbedBtnGrp } from '../inlineReference/components';
import Tooltip from '../../../../../../../Tooltip/Tooltip';
import { ImageOverlay, shortcutOpenChooseFromImageModal } from '../../image/handleMouseDown';


export const EmbedLinkReference = ({ entry, editor, element, ...props }) => {
  const url = entry['url'] || element?.attrs?.['href']
  const isError = entry['error']
  const { history, apiKey } = editor?.requestProps || {}
  const contentTypeUid = element?.attrs?.['content-type-uid'],
    entryUid = element?.attrs?.['entry-uid'],
    assetUid = element?.attrs?.['asset-uid'],
    locale = element?.attrs?.['locale']
  const isAssetLink = contentTypeUid === 'sys_assets'
  const isDisabled = entry['disable']

  const handleEdit = ({ apiKey, contentTypeUid, entryUid }) => {
    if (contentTypeUid !== 'sys_assets')
      history?.push(`/stack/${apiKey}/content-type/${contentTypeUid}/${locale}/entry/${entryUid}/edit`)
    else history.push(`/stack/${apiKey}/assets/${assetUid}`)
  }

  const handleUnlink = () => {
    const selection = editor.selection || editor.savedSelection
    if (!selection) return
    Transforms.unwrapNodes(editor, {
      at: selection,
      //@ts-ignore
      match: (node) => node.type === 'reference' && node?.attrs?.['display-type'] === 'link'
    })

    if (editor.requestProps?.['userAnalytics']) {
      const userAnalytics = editor.requestProps['userAnalytics'];
      const metaData = {
        stack_api_key: editor.requestProps['apiKey'],
        content_type_uid: element['attrs']['content-type-uid'],
        content_type_name: editor.requestProps['content_type_name'],
        json_rte_field_uid: editor.requestProps['field_uid'],
        json_rte_field_type: editor.requestProps['field_type'],
        embedded_entry_type: element['attrs']['display-type'],
        embedded_entry_uid: element['attrs']['entry-uid'],
        embedded_entry_url: url,
        mixpanel: true
      }
      userAnalytics.trackEvent(`Unlink Embedded Entry Link within JSON RTE`, metaData);
    }
  }

  return (
    <span className="tippy-inline">
      <Tooltip
        appendTo={document.body}
        zIndex={909}
        className="p-0"
        position="bottom"
        trigger="click"
        variantType="light"
        content={
          <div contentEditable={false} className={styles['embed--btn-group']}>
            <a href={url} target="_blank" className={styles['embed--url']}>
              {url}
            </a>
            <EmbedBtnGrp
              isDisabled={isDisabled}
              isError={isError}
              handleCaption={undefined}
              handleEdit={() => handleEdit({ apiKey, contentTypeUid, entryUid })}
              handleDelete={undefined}
              handleUnlink={handleUnlink}
              editor={editor}
              type={isAssetLink ? 'assetLink' : 'link'}
              attributes={{ ...props.attributes, ...props.attrs, element }}
            />
          </div>
        }>
        <Tooltip
          appendTo={document.body}
          disabled={!Boolean(entry['error'])}
          zIndex={909}
          className={styles['embed--link-error']}
          position="top"
          variantType="light"
          content={entry['error']}>
          <a
            href={url}
            {...props.attributes}
            {...props.attrs}
            data-type="link"
            data-testid={'embed-hyperlink'}
            className={cx(styles['linkRef'], entry['error'] && styles['linkRefError'])}>
            {props.children}
          </a>
        </Tooltip>
      </Tooltip>
    </span>
  )
}

const shortcutOpenAssetAsLinkOverlay = (event, editor, format, registryObj) => {
  shortcutOpenChooseFromImageModal(event, editor, format, registryObj, 'link')
}

const AssetEmbed = (props) => <ImageOverlay {...props} type='assetLink' />

Registry.register(
  {
    title: 'Embeded Entry as Link',
    category: elementCategories.LINK_DROPDOWN,
    IngressComponent: linkReferenceButton,
    shortcut: { key: 'mod+alt+shift+k', callback: ShortcutOpenEntryAsLinkOverlay },
    isContentStackElement: true,
    inBasicToolbar: false,
    toolbar: {
      inMainToolbar: true,
      inHoveringToolbar: true,
      inSlashToolbar: false
    },
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true
    }
  },
  { id: 'linkReference', registry: 'v2.element' }
)

Registry.register({
  title: 'Embeded Asset as Link',
  category: elementCategories.LINK_DROPDOWN,
  IngressComponent: AssetEmbed,
  shortcut: { key: 'mod+alt+k', callback: shortcutOpenAssetAsLinkOverlay },
  isContentStackElement: true,
  inBasicToolbar: false,
  toolbar: {
    inMainToolbar: true,
    inHoveringToolbar: true
  },
  dndOptions: {
    DisableDND: true,
    DisableSelectionHalo: true,
  }
},
  { id: 'assetLinkReference', registry: 'v2.element' }
)

export * from './deserializer'

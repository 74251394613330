import React, { useEffect, useRef, useState } from 'react'
import { Registry } from '@react-registry'
import classNames from 'classnames'

const Figure = ({ attributes, children, attrs, element }) => {
  return (
    <div {...attributes} {...attrs}>{children}</div>
  )
}

export default Figure

Registry.register(
  {
    Component: Figure,
    isContentStackElement: true,
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true
    }
  },
  { id: 'figure', registry: 'element' }
)
import isHotkey from 'is-hotkey'
import { Editor, Range, Transforms, Node } from 'slate'
// @ts-ignore
import { Registry } from '@react-registry'

export const handleTableArrows = ({ editor, event }) => {
  const arrows = {
    left: {
      isCursorAtCorrectPosition: (selection, cell) => Editor.isStart(editor, Range.start(selection), cell[1]),
      getCell: Editor.previous
    },
    right: {
      isCursorAtCorrectPosition: (selection, cell) => Editor.isEnd(editor, Range.end(selection), cell[1]),
      getCell: Editor.next
    },
  }
  let arrow: keyof typeof arrows | undefined

  if (isHotkey('left', event)) {
    arrow = 'left'
  }
  if (isHotkey('right', event)) {
    arrow = 'right'
  }
  if (arrow) {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        // @ts-ignore
        match: (n) => ['td', 'th'].includes(n.type),
        mode: 'lowest'
      })
      if (cell) {
        if (!arrows[arrow].isCursorAtCorrectPosition(selection, cell)) return

        // @ts-ignore
        const cellToSelect = arrows[arrow].getCell(editor, {
          match: (n) => ['td', 'th'].includes(n.type) && !editor.isVoid(n),
          mode: 'lowest',
          at: cell[1]
        })

        if (cellToSelect) {
          event.preventDefault()
          Transforms.select(editor, Editor.end(editor, cellToSelect[1]))
        }
      }
    }
  }
}

Registry.register(handleTableArrows, {
  id: 'handle_table_arrows',
  registry: 'v2.keydown'
})

//@ts-nocheck
import React from 'react'
import { useSelected, useFocused } from 'slate-react'
import { Registry } from '@react-registry'

import { AddDividerButton, ShortcutAddDivider, insertDivider } from './DividerButton'
//@ts-ignore
import styles from './style.module.css'


import { DividerIcon } from '../../utils/Icon'

import { cx } from '@emotion/css'
import { elementCategories } from '../elementCategories'

const Divider = (props) => {
  const selected = useSelected()
  const focused = useFocused()

  let dividerStyles = props?.element?.attrs?.["redactor-attributes"]?.style?.split(';')

  let attrsStyles = {}
  if (dividerStyles) {
    Array.from(dividerStyles).forEach((styleElem) => {
      let [key, value] = styleElem.split(":")
      attrsStyles[key] = value
      if (key === 'width') attrsStyles['minWidth'] = value
    })

  }
  return (
    <div
      {...props.attributes}
      className={cx(styles['divider'], { [styles['divider--focused']]: selected && focused })}
      style={{
        padding: '12px 0',
        cursor: 'pointer',
        caretColor: 'transparent'
      }}>
      {props.children}
      <div contentEditable={false}>
        <div
          className={styles['divider-line']}
          style={attrsStyles}
        ></div>
      </div>
      {props.children}
    </div>
  )
}
export default Divider

Registry.register(
  {
    Component: Divider,

    handleMouseDown: insertDivider,
    category: elementCategories.MULTIMEDIA_SEE_MORE,
    iconName: <DividerIcon />,
    title: 'Divider',
    shortcut: { key: 'mod+h', callback: ShortcutAddDivider },
    subtitle: 'Visual division for site',
    isContentStackElement: true,
    inBasicToolbar: false,
    toolbar: {
      inHoveringToolbar: false,
    },
    category: elementCategories.MULTIMEDIA_SEE_MORE
  },
  { id: 'hr', registry: 'element' }
)

import { Editor, Path, Transforms, Node } from "slate";

export const customBlockQuoteKeydown = (event, editor) => {
  if (event.key === 'Enter') {
    event.preventDefault()
    const { selection } = editor;
    if (!selection) return
    const [blockquote] = Editor.nodes(editor, {
      match: (n: any) => n.type === 'blockquote'
    })
    if (!blockquote) {
      editor.insertBreak()
      return
    }
    //@ts-ignore
    let textLength = Node.string(blockquote[0]).length
    if (!textLength) {
      Transforms.setNodes(editor, { type: 'p', children: [{ text: '' }] }, { at: editor.selection })
      return
    }
    //@ts-ignore
    Transforms.insertNodes(editor, { type: 'p', children: [{ text: '' }] }, { at: Path.next(blockquote[1]), select: true })
  }
}

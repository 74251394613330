import React from 'react';
import EmptyState from '../EmptyState/EmptyState';
import PageLayout from '../PageLayout/PageLayout';
import './FullPageLoader.css';
import CSLogoPreloader from './CSLogoPreloader.gif';

const FullPageLoader = ({ resourceName, testId }: { resourceName: string, testId?: string }) => {

  return (
    <div className='FullPageLoader' data-test-id={testId}>
      <PageLayout
        type='empty'
        content={{
          component:
            <>
              <div className='CSLogoPreloaderContainer'>
                <img className='CSLogoPreloaderGif' src={CSLogoPreloader} alt='full page loader' />
              </div>
              {
                <EmptyState
                  forPage='empty'
                  heading={'Loading...'}
                  description={`Please wait while we load the ${resourceName} for you.`}
                />
              }

            </>,
          alignContent: 'center'
        }}
      />
    </div>
  )
}

export default FullPageLoader;

FullPageLoader.defaultProps = {
  testId: 'cs-full-page-loader',
}
import React, { Component } from 'react'
import './Paragraph.css'

export type ParagraphProps = {
  /**
   * Pass the class names to be appended to this prop
   */
  className?: string
  /**
   * Provide the name of the <html> tag
   */
  tagName?: 'p'
  /**
   * Lets you add data that you want to display in tag
   */
  text?: string
  /**
   * Pass an ID that you can use for testing purposes. It is applied as a data attribute (data-test-id)
   */
  testId?: string
  /**
   * Provide variable font size of the text depending on the type of information
   */
  variant?: 'p1' | 'p2' | 'p3'
  /**
   * Add the weight of the tag
   */
  variantStyle?: 'regular' | 'bold'
  forwardRef?: any
} & typeof defaultProps

const defaultProps = {
  text: 'Body text',
  testId: 'cs-paragraph-tag',
  tagName: 'p',
  variant: 'p1',
  variantStyle: 'regular'
}

export class Paragraph extends Component<ParagraphProps> {
  static defaultProps = defaultProps

  render() {
    const { tagName, text, className, testId, variant, variantStyle, forwardRef } = this.props
    const Element: any = tagName
    return (
      <Element ref={forwardRef} className={`${className} ${variant} ${variantStyle}`} data-test-id={testId}>
        {text}
      </Element>
    )
  }
}

export default Paragraph

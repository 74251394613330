import { keyDownHooks } from '../hooks'
import { allElements } from '../../components/Toolbar/utils/toolbarElements'
import isHotkey from 'is-hotkey'
import { toggleBlock, toggleMark } from '../../components/Toolbar/Button/buttonUtils'
import { isString } from 'lodash'
import { isMac } from '../isMac'
import { IScrteShortcut } from '../types'
import { getRegistry } from '../getRegistry'
import { Editor, Node } from 'slate'
import { ElementWithType } from '../../../../../utils/types'
// from element, leaf registry
// from shortcut registry

// leaf -> toggleMark
// element -> toggleBlock

// global shortcuts

export const getElements = () => {
  return Object.entries(getRegistry('v2.element')).filter(
    ([key, value]) => {
      if (value['components'][0]['component'].customKeydown) {
        return [key, value['components'][0]['component']]
      }
    }
  )
}

const registries = [
  {
    registry: 'v2.element',
    callback: (event, editor, format, registryObj) => {
      toggleBlock(editor, format)
    }
  },
  {
    registry: 'v2.leaf',
    callback: (event, editor, format, registryObj) => {
      toggleMark(editor, format)
    }
  }
]

declare interface ItoolbarConfig {
  customToolbarComponents: any
  contentStack: boolean
  toolbarMode: string
}
export const handleShortcut = (event, editor, toolbarConfig: ItoolbarConfig) => {
  const elements = getElements();
  if (elements) {
    const keys = elements.filter((element) => {
      return (element[1]['components'][0]['component']['customKeydown'].find((key) => {
        return isHotkey(key.key, event)
      }))
    })
    if (keys.length) {
      keys.forEach((key) => {
        const [match] = Editor.nodes(editor, {
          match: (n: ElementWithType) => n.type === key[0]
        })
        if (!!match) {
          const customKeydown = key[1]['components'][0]['component']['customKeydown']
          Array.from(customKeydown).find((keydown: any) => {
            if (isHotkey(keydown.key, event)) {
              if (keydown['defaultKeydown']) {
                keydown['event'](event, editor)
                keyDownHooks({ event, editor })
              }
              else {
                keydown['event'](event, editor)
              }
            }
          })
        }
        else {
          keyDownHooks({ event, editor })
        }
      })
    }
    else {
      keyDownHooks({ event, editor })
    }
  }
  else {
    keyDownHooks({ event, editor })
  }
  // global shortcuts from "keyDownHooks" registry

  registries.forEach((registry) => {
    const elements = allElements(registry.registry)

    elements.forEach(([format, registryObj]) => {
      // filters
      if (!registryObj.shortcut) return
      if (
        toolbarConfig.contentStack &&
        registryObj.isContentStackElement !== undefined &&
        !registryObj.isContentStackElement
      )
        return
      if (toolbarConfig.toolbarMode) {
        switch (toolbarConfig.toolbarMode) {
          case 'basic':
            if (registryObj.inBasicToolbar !== undefined && !registryObj.inBasicToolbar) return
            break
          case 'custom':
            if (
              toolbarConfig.customToolbarComponents !== undefined &&
              //@ts-ignore
              !toolbarConfig.customToolbarComponents?.[registry.registry.split('.')[1]]?.includes(format) // ? NOTE: the split here is required due to temporary change in registry name
            )
              return
        }
      }

      const shortcut: IScrteShortcut = registryObj.shortcut

      if (shortcut.isDisable && shortcut.isDisable(event, editor, format, registryObj)) return
      if (!shortcut.key) return

      let shortcutText: string = null
      if (isString(shortcut.key)) {
        shortcutText = shortcut.key
      } else if (isMac() && shortcut.key.mac) {
        shortcutText = shortcut.key.mac
      } else if (shortcut.key.win) {
        shortcutText = shortcut.key.win
      } else return

      if (isHotkey(shortcutText, { byKey: Boolean(shortcut.byKey) }, event)) {
        event.preventDefault();
        if (shortcut.callback) {
          shortcut.callback(event, editor, format, registryObj)
        } else {
          registry.callback(event, editor, format, registryObj)
        }
      }
    })
  })
}

//@ts-nocheck
import React, { useState, useEffect, Fragment } from 'react'
import { getRegistry } from '../../../utils'
import { ToolbarButton } from '../utils/generatElements'
import { MarkButton, BlockButton, Button as DropDownButton, DropdownMenuItem } from '../Button'
import Dropdown from '../../../../../DropDown/Dropdown'
import { Icon } from '../../../../../..'
import { TriggerDropdownButton, TriggerButton } from '../Button/TriggerButton'
import { useSlateStatic, useSlate } from 'slate-react'
import { isBlockActive, toggleBlock, toggleMark, isMarkActive } from '../Button/buttonUtils'
import { cx } from '@emotion/css'

import styles from '../style.module.css'
import { organize, getCleanRegistry, filterIsContentStack } from './utils'
import { useToolbarProps } from '../Toolbar'
import { Divider } from '../utils/Divider'
import { useRteFullScreen } from '../../../../../../utils'

const DropdownInnerButton = ({ DefaultMenuComponent, element, id }) => {
  const { IngressComponent, toolbar, handleMouseDown } = element
  const editor = useSlateStatic()
  if (handleMouseDown) {
    return (
      <span key={id} style={{ padding: '0' }}>
        <span
          onMouseDown={(event) => {
            event.preventDefault()
            handleMouseDown({ event, editor })
          }}
          className={cx(
            { [styles['dropdown__menu__default__list--active']]: isBlockActive(editor, id) },
            { [styles['dropdown__menu__default__list--hoverable']]: !isBlockActive(editor, id) }
          )}>
          <span className={cx(styles['dropdown__ment__list--default'])}>
            <DropdownMenuItem title={element.title} shortcut={element.shortcut} icon={element.iconName} format={id} />
          </span>
        </span>
      </span>
    )
  }

  if (toolbar?.RenderComponent) {
    const RenderComponent = toolbar.RenderComponent
    return (
      <span key={id} style={{ padding: '0' }}>
        <RenderComponent key={id} />
      </span>
    )
  }
  if (IngressComponent) {
    return (
      <span key={id} style={{ padding: '0' }}>
        <TriggerDropdownButton key={id} format={id} value={element} />
      </span>
    )
  }
  return (
    <span key={id} style={{ padding: '0' }}>
      <DefaultMenuComponent key={id} format={id}>
        <span
          className={cx(styles['dropdown__ment__list--default'])}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '.625rem 1.25rem'
          }}>
          <DropdownMenuItem title={element.title} shortcut={element.shortcut} icon={element.iconName} format={id} />
        </span>
      </DefaultMenuComponent>
    </span>
  )
}

const ToolbarInnerButton = ({ DefaultComponent, element, id }) => {
  const { IngressComponent, iconName, title, shortcut } = element
  if (IngressComponent) {
    return <TriggerButton title={title} shortcut={shortcut} format={id} value={element} />
  }
  return <DefaultComponent title={title} shortcut={shortcut} icon={iconName} format={id} />
}

const DefaultBlockComponent = (props) => {
  const { format, children } = props
  const editor = useSlate()
  return (
    <span
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
      className={cx(
        { [styles['dropdown__menu__default__list--active']]: isBlockActive(editor, format) },
        { [styles['dropdown__menu__default__list--hoverable']]: !isBlockActive(editor, format) }
      )}>
      {children}
    </span>
  )
}

const DefaultLeafComponent = (props) => {
  const { format, children } = props
  const editor = useSlate()
  return (
    <span
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
      className={cx(
        { [styles['dropdown__menu__default__list--active']]: isMarkActive(editor, format) },
        { [styles['dropdown__menu__default__list--hoverable']]: !isMarkActive(editor, format) }
      )}>
      {children}
    </span>
  )
}

function RenderDropdown({ list, details, type, showArrow }) {
  const isButton = details?.['isButton']
  if (isButton) {
    if (!list.length) return
    const DefaultComponent = type === 'v2.leaf' ? MarkButton : BlockButton
    return <ToolbarInnerButton DefaultComponent={DefaultComponent} element={list[0]['value']} id={list[0]['id']} />
  }
  const DefaultComponent = type === 'v2.leaf' ? DefaultLeafComponent : DefaultBlockComponent
  const reducedList = list.reduce((acc, { id, value }) => {
    if (id !== 'rangeComment') {
      const newVal = { label: <DropdownInnerButton DefaultMenuComponent={DefaultComponent} element={value} id={id} /> }
      acc.push(newVal)
    }
    return acc
  }, [])

  const { title, icon } = details
  return (
    <Dropdown list={reducedList} type="click" className="scrte-dropdown" closeAfterSelect={true}>
      <DropDownButton dropdown={showArrow} format={title} onMouseDown={(e) => e.preventDefault()}>
        {icon || 'icon-here'} {showArrow && <Icon icon={'DropdownDark'} style={{ marginLeft: '0.2rem' }} />}
      </DropDownButton>
    </Dropdown>
  )
}

function RenderSubCategories({ category, type, showArrow }) {
  const { editorRef } = useToolbarProps()
  const isFullScreen = useRteFullScreen(editorRef)
  return category.map((element, idx) => {
    if (Array.isArray(element.childrens)) {
      if (element.details['openOnFullScreen'] && isFullScreen) {
        return element.childrens.map((el) => <Button key={el.id} button={el} type={type} />)
      }
      return (
        <RenderDropdown
          showArrow={showArrow}
          type={type}
          list={element.childrens}
          details={element.details}
          key={element.details.id}
        />
      )
    } else {
      return <Button key={element.id} button={element} type={type} />
    }
  })
}

function RenderCategories({ categories, type, showArrow }) {
  return categories.map((category, idx) => (
    <RenderSubCategories showArrow={showArrow} category={category} key={idx} type={type} />
  ))
}

export const Button = ({ button, type, closeHoveringToolbar }) => {
  const { id, value } = button

  if (type === 'v2.leaf') {
    const DefaultComponent = (props) => {
      const { element, keyData, toolbarType } = props
      const { title, shortcut, iconName } = element
      return (
        <MarkButton key={keyData} format={keyData} title={title} shortcut={shortcut}>
          {iconName || 'icon-here'}
        </MarkButton>
      )
    }
    return (
      <ToolbarButton
        keyData={id}
        element={value}
        toolbarType="toolbar"
        DefaultComponent={DefaultComponent}
        closeHoveringToolbar={closeHoveringToolbar}
      />
    )
  }
  if (type === 'v2.element') {
    const DefaultComponent = (props) => {
      const { element, keyData, toolbarType } = props
      const { title, iconName } = element
      return (
        <BlockButton key={keyData} format={keyData} title={title}>
          {iconName || 'icon-here'}
        </BlockButton>
      )
    }
    return <ToolbarButton keyData={id} element={value} toolbarType="toolbar" DefaultComponent={DefaultComponent} />
  }
  return <ToolbarButton keyData={id} element={value} toolbarType="toolbar" DefaultComponent={null} />
}

export function Buttons({ type, pluginslen, closeHoveringToolbar }) {
  const [buttons, setButtons] = useState({
    rootCategories: [],
    remainingElements: [],
    seemore: []
  })

  const { toolbarType, toolbarMode, customToolbarComponents } = useToolbarProps()

  useEffect(() => {
    let cleanRegistry = filterIsContentStack(getCleanRegistry(getRegistry(type)))
    cleanRegistry = organize(cleanRegistry, toolbarType, toolbarMode, customToolbarComponents, type)

    if (toolbarType === 'hoveringToolbar' && type === 'v2.leaf') {
      const propertyValue = Object.entries(getRegistry('v2.element'))
        .find(([key]) => key === 'property')?.[1]?.components?.[0]?.component;

      if (propertyValue) {
        let property = {
          id: 'property',
          value: propertyValue
        }

        if (toolbarMode === 'custom' && !customToolbarComponents['v2.element'].includes('property')) {
          property = undefined;
        }

        if (property) {
          const indexToInsert = cleanRegistry.remainingElements.length - 1;
          cleanRegistry.remainingElements.splice(indexToInsert, 0, property)
        }
      }
    }
    setButtons(cleanRegistry)
  }, [pluginslen, customToolbarComponents?.['v2.leaf']?.length])

  const isDividerVisible = Boolean(
    buttons.rootCategories.length || buttons.remainingElements.length || buttons.seemore.length
  )
  return isDividerVisible ? (
    <Fragment>
      <RenderCategories categories={buttons.rootCategories} type={type} showArrow={true} />
      {buttons.remainingElements.map((button) => (
        <Button button={button} key={button.id} type={type} closeHoveringToolbar={closeHoveringToolbar} />
      ))}
      <RenderCategories categories={buttons.seemore} type={type} showArrow={false} />
      <Divider />
    </Fragment>
  ) : null
}

import React from 'react'
import './AssetCardHorizontal.css'
import Icon from '../Icon2'
import ProgressBar from '../ProgressBar/ProgressBar'
import ValidationMessage from '../ValidationMessage/ValidationMessage'
import Button from '../Button/Button'
import { formatBytes, getAssetIcon } from '../../utils'
import Paragraph from '../Typography/Paragraph/Paragraph'
import Dropdown from '../DropDown/Dropdown'
import Tooltip from '../Tooltip/Tooltip'
import { Truncate } from '../Truncate/Truncate'

export type AssetCardHorizontalProps = {
  url?: string
  name?: string
  size?: any
  description?: string
  onDelete?: (data?: any) => void
  onEdit?: (data?: any) => void
  data?: any
  type?: any
  imageWidth?: any
  imageHeight?: any
  progressBar?: number
  onAssetIconClick?: Function
  onReload?: Function
  withDragAction?: boolean
  version: string
  leftcomponent?: React.ReactNode
}

export const DeleteDom = ({ iconTestId, labelTestId, labelText }): JSX.Element => {
  return (
    <div className='flex-v-center'>
      <Icon testId={`cs-${iconTestId}`} className='mr-8' icon="DontSave" size="medium" version='v2' />
      <Paragraph testId={`cs-${labelTestId}`} tagName='p' text={labelText} variant='p1' />
    </div>
  )
}

const ContextualDropdown = ({ onDelete, data }): JSX.Element => {

  const contextualDropdownActionArray = [
    {
      label: (
        <DeleteDom iconTestId={'date-instance-delete-icon'} labelTestId={'asset-instance-delete-label'} labelText={'Remove'} />
      ),
      id: 'delete',
      className: 'action-delete-instance',
      action: (e): void => onDelete(data),
      testId: 'cs-asset-multi-field-delete'
    }
  ]

  return (
    <div className='field-contextual-dropdown ml-4'>
      <Dropdown className='action-dropdown' version='v2' testId="cs-entry-group-action-dropdown"
        list={
          contextualDropdownActionArray
        } type='click' withIcon={true} closeAfterSelect={true}>
        <Tooltip content={`More Actions`} position="top" showArrow={false}>
          <Button testId='cs-group-more-actions-icon' tabIndex={0} onlyIcon={true} onlyIconHoverColor="primary" version="v2" icon="v2-DotsThreeLargeVertical" />
        </Tooltip>
      </Dropdown>
    </div>
  )
}

const AssetCardHorizontal = (props: AssetCardHorizontalProps) => {
  let {
    url,
    name,
    size,
    description,
    onDelete,
    onEdit,
    onAssetIconClick,
    data,
    type,
    imageHeight,
    imageWidth,
    progressBar,
    onReload,
    withDragAction,
    version,
    leftcomponent
  } = props

  if (type === 'image' && !url) {
    type = 'imageBroken'
  }

  if (progressBar > 100 || (progressBar < 0 && progressBar !== -1)) {
    progressBar = undefined
  }

  url = `${url}`
  // url = `${url}?width=${imageWidth}&height=${imageHeight}&resize-filter=nearest`;

  const classCreator = () => {
    if (imageHeight && imageWidth) {
      if (imageHeight === imageWidth && imageHeight >= 60) {
        return `ImageCard__image_full`
      }
      if (imageHeight > 60 && imageWidth > 60) {
        if (imageHeight < imageWidth) {
          return `ImageCard__image-height-full`
        }
        if (imageHeight > imageWidth) {
          return `ImageCard__image-width-full`
        }
      }
      if (imageHeight >= 60 && imageWidth < 60) {
        return `ImageCard__image-height-auto`
      }
      if (imageWidth >= 60 && imageHeight < 60) {
        return `ImageCard__image-width-auto`
      }
    }
    return ''
  }

  const handleOnClick = () => {
    if (props?.onAssetIconClick) {
      props.onAssetIconClick(props?.data)
    }
  }
  return (
    <div className={`ImageCard ${version === 'v2' ? 'ImageCard--v2' : ''} flex`}>
      {version === 'v2' && leftcomponent ? <div className='mr-4'>
        {leftcomponent}
      </div> : null}
      {type === 'image' ? (
        <div className="ImageCard__image-wrapper flex-center">
          <img
            src={url}
            alt={name}
            className={`${classCreator()} ${onAssetIconClick ? 'action--click' : ''}`}
            onClick={handleOnClick}
          />
        </div>
      ) : (
        <div className="ImageCard__image-wrapper ImageCard__image-iconbox flex-center">
          <Icon
            className={`placeholder ${onAssetIconClick ? 'action--click' : ''}`}
            icon={getAssetIcon(type)}
            onClick={handleOnClick}
          />
        </div>
      )}
      <div className="ImageCard__details">
        <div className="ImageCard__name">
          {version === 'v1' ?
            name :
            <Truncate isResponsive={true} truncateFrom="end">
              {name}
            </Truncate>
          }
        </div>
        {size &&
          (progressBar === undefined ||
            progressBar === null ||
            (progressBar !== undefined && progressBar !== null && progressBar !== -1)) ? (
          <div className="ImageCard__size">{formatBytes(size)}</div>
        ) : null}
        {progressBar !== undefined && progressBar !== null && progressBar !== 100 ? (
          <>
            {progressBar !== -1 ? (
              <div className="ImageCard__progress">
                <ProgressBar percentage={progressBar} color="#6C5CE7" />
              </div>
            ) : (
              <div className="flex-v-center">
                <ValidationMessage version={version === 'v2' ? 'v2' : 'v1'}>Failed</ValidationMessage>
                {onReload ? (
                  <>
                    <div
                      onClick={(event: any) => {
                        onReload(event, data)
                      }}>
                      {version === 'v1' ?
                        <Icon icon={'Reload'} className="ImageCard__reload" /> :
                        <Icon icon={'Reload'} size="small" version="v2" className="ImageCard__reload" />
                      }
                    </div>
                  </>
                ) : null}
              </div>
            )}
          </>
        ) : null}
        {description ? (
          <>
            {progressBar === undefined ||
              progressBar === null ||
              (progressBar !== undefined && progressBar !== null && (progressBar === 100 || progressBar === -1)) ? (
              <div className="ImageCard__description">
                {version === 'v1' ?
                  description :
                  <Truncate isResponsive={true} truncateFrom="end">
                    {description}
                  </Truncate>
                }
              </div>
            ) : null}
          </>
        ) : null}
      </div>
      <div className="ImageCard__actions flex">
        {
          onDelete && version === 'v1' ?
            <div
              className="ImageCard__action--delete flex"
              onClick={(event: any) => {
                onDelete && onDelete(data)
              }}>
              <Icon icon="TrashMini" />
            </div> : null
        }
        {onEdit ?
          version === 'v2' ?
            <Button className="ImageCard__action--edit-v2 mr-4" version="v2"
              onClick={(event: any) => {
                onEdit && onEdit(data)
              }}
              onlyIconHoverColor="primary" onlyIcon={true} icon="v2-Edit" />
            :
            <>
              {progressBar === undefined || (progressBar !== undefined && progressBar !== null && progressBar !== -1) ? (
                <div
                  className="ImageCard__action--edit flex"
                  onClick={(event: any) => {
                    onEdit && onEdit(data)
                  }}>
                  <Icon icon="EditSmall" />
                </div>
              ) : null}
            </>
          : null}
        {
          onDelete && version === 'v2' ?
            <ContextualDropdown onDelete={onDelete} data={data} /> : null
        }
        {withDragAction && version !== 'v2' && (
          <div className="ImageCard__action-bar-icon">
            <Icon icon="ActionBar" />
          </div>
        )}
      </div>
    </div>
  )
}

AssetCardHorizontal.defaultProps = {
  type: 'imageBroken',
  imageHeight: 100,
  imageWidth: 200,
  withDragAction: true,
  version: 'v1'
} as Partial<AssetCardHorizontalProps>

export default AssetCardHorizontal

import React, { useEffect, useState } from 'react'

import { IMessageDTO, IUserState } from '../../utils/types'
import { getMessageWithDisplayName, getUserName, sanitizeData } from './utils'
import styles from './style.module.css'


interface ICommentResolvedText {
  comment: IMessageDTO,
  userState: IUserState
}
const CommentResolvedText: React.FC<ICommentResolvedText> = ({ comment, userState }) => {
  const [text, setText] = useState(comment.message)

  useEffect(() => {
    setText(getMessageWithDisplayName(comment, userState, "html"))
  }, [comment.message, userState.roleMap, userState.userMap, comment.to_roles, comment.to_users])
  return (
    <div data-testid={'scrte-discussion-comment--message'} className={styles['scrte-discussion-comment--message']} dangerouslySetInnerHTML={{ __html: sanitizeData(text.replace(/\n/g, '<br/>')) }}></div>
  )
}

export default CommentResolvedText
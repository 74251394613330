import { Transforms, Editor, Element } from 'slate'
import { ElementWithType } from '../../../../../../utils/types'
import { JsonNode, INodeAttrs } from '../../../utils/types'
import { getLangDir } from 'rtl-detect';
import { stringToStyleObject } from '../../utils/attributesProvider/elementAttrs'
import { isPlainObject } from 'lodash';

export const textDirection = (editor, direction) => {
  try {
    const { selection } = editor;
    if (selection === null) {
      return
    }

    for (const [n, p] of Editor.nodes(editor, { at: selection })) {
      const node: JsonNode = n
      const path = p
      const textDirectionStyle = {
        "direction": direction,
        "text-align": direction === 'ltr' ? 'left' : 'right'
      }
      setTextDirectionStyle(editor, node, path, textDirectionStyle)
      Transforms.select(editor, selection)
    }
  } catch (error) {
    console.error(error);
  }
}

export const isNodeTextDirected = (editor, style) => {
  try {
    const selection = editor.selection;
    const entryLocale = editor?.requestProps?.locale || 'en-us';
    //@ts-ignore
    const localeDirection = getLangDir(entryLocale);
    const entryLocaleDirection = localeDirection;

    if (selection === null) {
      return false
    }
    // TODO : Remove this and convert the RTL fucntions to normalization methods
    Editor.normalize(editor, { force: true })
    syncWithPreviousNode(editor);

    const [node] = Editor.nodes(editor, { match: (n: any) => (n?.attrs?.style?.["direction"] || n?.attrs?.dir) });

    //@ts-ignore
    if (node) {
      // @ts-ignore
      if (node[0]?.dir) {
        textDirection(editor, node[0]?.['dir']);
        return style === node[0]?.['dir']
      } else {
        //@ts-ignore
        return node[0]?.attrs?.dir === style
      }
    }
    else {
      textDirection(editor, entryLocaleDirection);
      return style === entryLocaleDirection;
    }
  } catch (error) {
    console.error(error);
  }
}

export const syncWithPreviousNode = (editor) => {
  try {
    handleListElements(editor);

    const nodeList = ['table', 'embed', 'social-embeds']
    const [nodes] = Editor.nodes(editor, { match: (n: any) => nodeList.includes(n?.type) });
    if (nodes) {
      const previousNodeObject = Editor.previous(editor, { at: nodes[1] });
      if (!previousNodeObject) return;
      const [previousNode] = previousNodeObject;
      //@ts-ignore
      if (previousNode && previousNode?.attrs?.style) {
        const currentNode = Editor.nodes(editor, { at: nodes[1] });
        if (!currentNode) return;
        for (const [n, p] of currentNode) {
          const node: JsonNode = n
          const path = p
          if (!node?.attrs?.style) {
            //@ts-ignore
            const textDirectionStyle = previousNode?.attrs?.style || {}
            setTextDirectionStyle(editor, node, path, textDirectionStyle)
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export const setTextDirectionStyle = (editor, node: JsonNode, path, textDirectionStyle) => {
  try {
    if (Element.isElement(node) && node.type !== 'doc') {
      const oldAttributes: any = node.attrs

      let redactorAttributes: Partial<INodeAttrs> = {}

      if (oldAttributes) {
        redactorAttributes = {
          ...redactorAttributes,
          ...oldAttributes["redactor-attributes"]
        }
      }
      if (node.type === 'img' || node.type === 'reference') {
        redactorAttributes.position = textDirectionStyle.direction === 'ltr' ? 'left' : 'right'
      }

      let newStyles = {}
      if (typeof oldAttributes?.style === 'string') {
        newStyles = { ...stringToStyleObject(oldAttributes?.style) }
      } else if (isPlainObject(oldAttributes?.style)) {
        newStyles = { ...oldAttributes?.style }
      }

      const newAttributes = {
        attrs: {
          ...oldAttributes,
          style: {
            ...newStyles,
          },
          "redactor-attributes": redactorAttributes
        }
      };

      if (textDirectionStyle.direction === 'rtl') {
        newAttributes.attrs["style"] = {
          ...newStyles,
          ...textDirectionStyle
        }
        newAttributes.attrs.dir = 'rtl'
      } else {
        newAttributes.attrs.dir = 'ltr';
      }

      Transforms.setNodes(editor, newAttributes as ElementWithType, { at: path });
    }
  } catch (error) {
    console.error(error);
  }
}

export const handleListElements = (editor) => {
  try {
    const [listContainerNodes] = Editor.nodes(editor, { match: (n: any) => n?.type === 'li' });
    if (listContainerNodes) {
      const parentNode = Editor.parent(editor, listContainerNodes[1]);
      if (parentNode) {
        //@ts-ignore
        if (listContainerNodes[0]?.attrs.style && !parentNode[0]?.attrs['style']) {
          const elementAttribute = {
            attrs: {
              //@ts-ignore
              style: listContainerNodes[0]?.attrs.style
            }
          }
          Transforms.setNodes(editor, elementAttribute as ElementWithType, { at: parentNode[1] });
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export const handleInitialRTLDirection = (editor, props) => {
  try {
    const { element } = props;
    if (Element.isElement(element) && element['type'] === 'doc') return;

    if (props?.attributes?.dir && props?.attributes?.dir === 'rtl') {
      const oldAttributes = element['attrs']
      if (oldAttributes.dir === 'rtl') return;
      const elementAttribute = {
        attrs: {
          ...oldAttributes,
          style: {
            ...oldAttributes.style,
            "direction": 'rtl',
            "text-align": "right"
          },
          dir: 'rtl'
        }
      }
      Transforms.setNodes(editor, elementAttribute as any);
    }
  } catch (error) {
    console.error(error);
  }
}
import React from 'react'
import { useSlateStatic } from 'slate-react'
import { Registry } from '@react-registry'
import { textDirection, isNodeTextDirected, handleListElements } from '../utils'
import { elementCategories } from '../../elementCategories'
import { RTLTextDirectionIcon } from '../../../utils/Icon'

export const ShortcutTextDirectionRTL = (editor) => {
  textDirection(editor, 'rtl')
}

export const RightToLeft = (props) => {
  const editor = useSlateStatic()

  const handleMouseDown = (event) => {
    event.preventDefault()
    textDirection(editor, 'rtl')
  }

  return (
    <React.Fragment>
      {props.children({
        handleMouseDown,
        active: isNodeTextDirected(editor, 'rtl')
      })}
    </React.Fragment>
  )
}

Registry.register(
  {
    iconName: <RTLTextDirectionIcon />,
    slashIconName: <RTLTextDirectionIcon />,
    style: 'rtl',
    shortcut: { key: { win: 'mod+alt+r', mac: 'cmd+ctrl+r' }, callback: ShortcutTextDirectionRTL },
    title: 'Right-to-left(RTL)',
    slashTitle: 'Right-to-left(RTL)',
    template: '',
    IngressComponent: RightToLeft,
    category: elementCategories.MULTIMEDIA_SEE_MORE,
    isContentStackElement: true,
    inBasicToolbar: true,
    canCustomize: false,
    keywords: ['rtl']
  },
  { id: 'text-direction-rtl', registry: 'v2.element' }
)

//@ts-nocheck
import React from 'react'

import HoveringToolbarWrapper from './HoveringToolbarWrapper'
import BaseToolbar from '../Toolbar'

export declare interface HoveringToolbarProps extends React.PropsWithChildren<object> {
  customToolbarComponents: any
  contentStack: boolean
  toolbarMode: any
  scrollRef: any
  pluginslen: any
}

export const HoveringToolbar = React.memo(
  (props: HoveringToolbarProps) => {
    const { contentStack, customToolbarComponents, toolbarMode = 'advance', pluginslen } = props

    return (
      <HoveringToolbarWrapper {...props}>
        <BaseToolbar
          toolbarType={'hoveringToolbar'}
          contentStack={contentStack}
          toolbarMode={toolbarMode}
          customToolbarComponents={customToolbarComponents}
          pluginslen={pluginslen}
        />
      </HoveringToolbarWrapper>
    )
  },
  (prev, next) => {
    return (prev.pluginslen === next.pluginslen) && (prev?.customToolbarComponents?.['v2.leaf']?.length === next?.customToolbarComponents?.["v2.leaf"]?.length)
  }
)

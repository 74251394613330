import { JsonNode } from 'components/JsonRTE/SuperChargedRte/utils/types'
import { Transforms, Node, Editor, Element, Path, Range, BaseEditor, NodeEntry } from 'slate'
import { ElementWithType } from '../../../../../../../utils/types'

import { normalTextElement } from '../../paragraph/utils'
import { LIST_TYPES } from '../utils'
import { cloneDeep, isEqual } from 'lodash'
import { jsx } from 'slate-hyperscript'

export const outdentListItem = (editor, liEntry) => {
  const liPath = liEntry[1]
  const liIndex = liPath.at(-1)

  const parentListEntry = Editor.parent(editor, liPath)

  const parentLiEntry = getParentLi(editor, liPath)
  const nextListEntry = getNextList(editor, liPath)

  if (!parentLiEntry || !parentListEntry) return

  const [parentList, parentListPath] = parentListEntry
  const parentLiPath = parentLiEntry[1]

  let location = [
    [...parentListPath, liIndex + 1],
    [...parentListPath, parentList.children.length - 1]
  ]

  const hasOnlyOneNextSibling = isEqual(location[0], location[1])
  const hasNoNextSibling = liIndex === parentList.children.length - 1

  if (hasOnlyOneNextSibling) {
    location = location[0]
  }
  if (!hasNoNextSibling) {
    Transforms.wrapNodes(editor, jsx('element', { type: parentList['type'] }), {
      // @ts-ignore
      at: location
    })
    const wrappedListRef = Editor.pathRef(editor, Path.next(liPath))

    let entry = liEntry

    if (nextListEntry) {
      entry = nextListEntry
    }

    Transforms.moveNodes(editor, { at: wrappedListRef.current, to: [...entry[1], entry[0].children.length] })

    if (nextListEntry) {
      Transforms.unwrapNodes(editor, { at: wrappedListRef.current })
    }
    wrappedListRef.unref()
  }

  if (parentLiPath) Transforms.moveNodes(editor, { at: liPath, to: Path.next(parentLiPath) })
}

export const indentListItem = (editor, li) => {
  const liPath = li[1]

  const parentListEntry = Editor.parent(editor, liPath)
  const parentLiEntry = Editor.previous<JsonNode>(editor, { at: liPath })

  if (liPath.at(-1) == 0 || !parentListEntry || !parentLiEntry) {
    return null
  }
  const [parentList] = parentListEntry

  const [previousLi, previousLiPath] = parentLiEntry

  const previousListEntry = getPreviousList(editor, liPath)
  const nextListEntry = getNextList(editor, liPath)
  const nextListRef = nextListEntry ? Editor.pathRef(editor, nextListEntry[1]) : null

  let moveTo = [...previousLiPath, previousLi.children.length]

  if (previousListEntry) {
    const [node, path] = previousListEntry
    moveTo = [...path, node.children.length]
  }
  if (!previousListEntry) {
    Transforms.wrapNodes(editor, jsx('element', { type: parentList['type'] }), { at: liPath })
  }

  Transforms.moveNodes(editor, { at: liPath, to: moveTo })
  if (nextListRef) {
    const moveListTo = previousListEntry ? Path.next(moveTo) : [...moveTo, 1]
    Transforms.moveNodes(editor, { at: nextListRef.current, to: moveListTo })
    Transforms.unwrapNodes(editor, { at: moveListTo })
    nextListRef.unref()
  }
}
export const exitList = (editor) => {
  if (editor.selection) {
    const [listItemEntry] = Editor.nodes(editor, {
      match: (n: ElementWithType) => {
        return n.type === 'li'
      },
      mode: 'lowest'
    })
    if (listItemEntry) {
      const [listItem, listItemPath] = listItemEntry
      // check if text exist
      if (Node.string(listItem) === '') {
        const [listEntry] = Editor.nodes(editor, {
          match: (n: any) => {
            return LIST_TYPES.includes(n.type)
          }
        })

        if (listEntry) {
          const listParentPath = listEntry[1]

          const nextPath = Path.next(listParentPath)

          Transforms.insertNodes(editor, normalTextElement, { at: nextPath })
          Transforms.removeNodes(editor, { at: listItemPath })

          try {
            Transforms.select(editor, nextPath)
          } catch (e) {
            Transforms.select(editor, listParentPath)
          }
          return true
        }
      }

      if (Range.isExpanded(editor.selection)) {
        Transforms.collapse(editor)
      }
      let addNewListItemToPath = Path.next(listItemPath)

      const isAtEnd = Editor.isEnd(editor, editor.selection.focus, [...listItemPath, 0])
      const isAtStart = Editor.isStart(editor, editor.selection.focus, [...listItemPath, 0])
      if (isAtStart && !isAtEnd) {
        addNewListItemToPath = listItemPath
      }

      if (isAtEnd || isAtStart) {
        Transforms.insertNodes(editor, jsx('element', { type: 'li' }), { at: addNewListItemToPath })
        Transforms.select(editor, addNewListItemToPath)
        Transforms.collapse(editor)
      }

      if (!isAtStart || !isAtEnd) {
        Transforms.splitNodes(editor, { match: (n) => n['type'] === 'li' })
      }
      return true
    }
  }
}

export function getParentLi(editor, liPath) {
  const [parentLi] = Editor.nodes(editor, {
    at: liPath,
    mode: 'lowest',
    match: (node, path) => Element.isElement(node) && node['type'] === 'li' && Path.isAncestor(path, liPath)
  })
  return parentLi
}

export function getPreviousList(editor, liPath) {
  return getNextList(editor, Path.previous(liPath))
}

export function getNextList(editor, liPath) {
  const [currentListEntry] = Editor.nodes<Element>(editor, {
    at: liPath,
    match: (node: JsonNode, path) =>
      Element.isElement(node) && LIST_TYPES.includes(node.type) && Path.isAncestor(liPath, path)
  })
  return currentListEntry
}



//@ts-nocheck
import React, { useState, useEffect, useRef } from 'react'
import { Registry } from '@react-registry'
import { Transforms, Node } from 'slate'
import { useSlateStatic, ReactEditor } from 'slate-react'
import isUrl from 'is-url'
import { Resizable } from 're-resizable'
import { Embeds } from './Embeds'
import {
  cbModal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  ButtonGroup,
  TextInput,
  Icon,
  Field,
  ActionTooltip
} from '../../../../../../index'

import EmbeddedOverlay from './EmbeddedOverlay'
import { SocialEmbedIcon } from '../../utils/Icon'
import { handleDelete } from '../../utils/actions'
//@ts-ignore
import styles from './style.module.css'
import { elementCategories } from '../elementCategories'
import { iFrameUrl, blockQuoteUrl, changeIframeWidth, iframeHeightOnResizeStop, convertWidthInOrginalUnits } from './utils'
import ErrorBoundary from './ErrorBoundary'
import { ElementWithType } from '../../../../../../utils/types'

export const SocialEmbededElement = ({ attributes, children, element, attrs }) => {
  let { src, width, height } = element.attrs
  let lockRatio;
  if (src?.indexOf('vimeo.com') > -1) {
    lockRatio = true
  }
  const editor = useSlateStatic() as ReactEditor;
  const ref = useRef(null)
  const [embedWidth, setWidth] = useState(null);
  const [embedHeight, setHeight] = useState(null);
  const [maxWidth, setMaxWidth] = useState(null);
  const [minWidth, setMinWidth] = useState(null);
  const [dataWidth, setDataWidth] = useState(null);
  const [lockAspectRatio, setLockAspectRatio] = useState(lockRatio ? true : false)

  useEffect(() => {
    if (width) {
      if (src?.indexOf('tiktok.com') > -1 || src?.indexOf('facebook.com') > -1) {
        changeIframeWidth(src, width, ref)
      }
      setWidth(width)
    }
    if (element?.attrs['redactor-attributes']?.height) {
      setHeight(element.attrs['redactor-attributes'].height)
    }
    if (src?.indexOf('facebook.com') > -1) {
      setDataWidth(ref?.current?.getElementsByClassName('fb-post')[0]?.getAttribute('data-width'))
      setMaxWidth(750)
      setMinWidth(350)
    }
    else if (src?.indexOf('instagram.com') > -1) {
      setMaxWidth(540)
      setMinWidth(326)
    }
    else if (src?.indexOf('youtube.com' || 'youtu.be') > -1) {
      if (ref.current) {
        setMaxWidth(ref.current.getElementsByTagName('iframe')[0].getAttribute('width'))
        setMinWidth(175)
      }
    }
    else if (src?.indexOf('vimeo.com') > -1) {
      setMinWidth(175)
      setMaxWidth(ref.current.getElementsByTagName('iframe')[0].getAttribute('width'))
      if (!element?.attrs['redactor-attributes']?.height) {
        setHeight(ref.current.getElementsByTagName('iframe')[0].getAttribute('height'))
      }
    }

  }, [])

  const isIframe = (data) => {
    return (data?.match(/<iframe|<video/gi) !== null);
  }

  const isBlockQuote = (data) => {
    return (data?.match(/<blockquote/gi) !== null);
  }
  if (isIframe(src)) {
    src = iFrameUrl(src)
  }

  if (isBlockQuote(src)) {
    src = blockQuoteUrl(src)
  }

  if (!src || !isUrl(src)) {
    return null
  }

  const onResizeStart = (event, dir, el) => {
    if (src?.indexOf('twitter.com') > -1) {
      const iframe = ref?.current?.getElementsByTagName('iframe')[0].getAttribute('src')
      if (iframe.indexOf('&width=') > -1) {
        setMaxWidth(iframe.substr(iframe.indexOf('&width=') + 7).slice(0, iframe.substr(iframe.indexOf('&width=') + 7).length - 2))
        setMinWidth(250)
      }
    }
    else if (src?.indexOf('tiktok.com') > -1) {
      if (ref.current.getElementsByTagName('blockquote')[0]) {
        setMinWidth(ref.current.getElementsByTagName('blockquote')[0].style['min-width'])
        setMaxWidth(ref.current.getElementsByTagName('blockquote')[0].style['max-width'])
      }
    }
    else if (src?.indexOf('instagram.com') > -1) {
      el.style.height = `${ref.current.getElementsByTagName('iframe')[0].getAttribute('height')}px`
    }
  }

  const onResizeStop = (event, dir, el, size) => {
    iframeHeightOnResizeStop(src, ref, el)

    let width = el.style.width
    let height = el.style.height
    let heightLen = height.length
    if (height.slice(heightLen - 2) === 'px') {
      height = Number(height.slice(0, heightLen - 2))
    }

    setWidth(width);
    setHeight(height);
    let path = ReactEditor.findPath(editor, element)
    let { attrs } = element
    Transforms.setNodes(
      editor,
      { attrs: { ...attrs, width: width, height } } as Partial<ElementWithType>,
      {
        at: path
      }
    )
  }

  const onResize = (event, dir, el, size) => {
    if (src?.indexOf('twitter.com') > -1) {
      if (ref.current.getElementsByTagName('iframe')[0]) {
        ref.current.getElementsByTagName('iframe')[0].style.width = el.style.width;
      }
    }
    else if (src?.indexOf('tiktok.com') > -1) {
      if (ref.current.getElementsByTagName('blockquote')[0]) {
        ref.current.getElementsByTagName('blockquote')[0].style.width = el.style.width;
      }
    }
    else if (src?.indexOf('instagram.com') > -1) {
      el.style.height = `${ref.current.getElementsByTagName('iframe')[0].getAttribute('height')}px`
    }
    setWidth(el.style.width)
    setHeight(el.style.height.slice(0, el.style.height.length - 2))
  }

  const ModalComponent = (props) => {
    const [formData, setFormData] = useState({
      embeded_url: props.initialUrl || ''
    })

    const updateFormData = (e) => {
      e.persist()
      setFormData((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value
        }
      })
    }

    const handleOnOk = () => {
      props.closeModal()
      let path = ReactEditor.findPath(editor, element);
      let parsedUrl;
      if (isIframe(formData['embeded_url'])) {
        parsedUrl = iFrameUrl(formData['embeded_url'])
      }
      if (isBlockQuote(formData['embeded_url'])) {
        parsedUrl = blockQuoteUrl(formData['embeded_url'])
      }
      const src = parsedUrl ? parsedUrl : formData['embeded_url']
      const attrs = { ...element.attrs, src }
      Transforms.setNodes(editor, { attrs } as Partial<Node>, { at: path })
    }

    return (
      <div>
        <ModalHeader title="Edit Embed Url" closeModal={props.closeModal} />

        <ModalBody>
          <Field labelText="URL">
            <TextInput
              name="embeded_url"
              onChange={updateFormData}
              value={formData.embeded_url}
              type="url"
              placeholder="Enter Embeded URL"
            />
          </Field>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button key="cancel" buttonType="light" onClick={() => props.closeModal()}>
              Cancel
            </Button>
            <Button key="add" icon="CheckedWhite" onClick={handleOnOk}>
              Update
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </div>
    )
  }



  const toolList = [
    {
      label: <Icon icon="Edit" size="small" />,
      title: 'Edit',
      action: () => {
        cbModal({
          component: (props) => <ModalComponent initialUrl={src} {...props} />
        })
      }
    },
    {
      label: <Icon icon="Trash" size="small" />,
      title: 'Delete',
      action: () => {
        handleDelete(editor, element)
      }
    }
  ]
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <Resizable
          onResize={onResize}
          onResizeStop={onResizeStop}
          onResizeStart={onResizeStart}
          lockAspectRatio={lockAspectRatio}
          size={{
            width: `${element.attrs.width ? embedWidth : dataWidth ? dataWidth : 'auto'}`,
            height: `${element.attrs.height ? element.attrs.height + "px" : 'auto'}`,
          }}
          minWidth={minWidth}
          maxWidth={maxWidth}

        >
          <div className={styles['scrte--iframe-wrapper']}>
            <ActionTooltip list={toolList} className={styles['scrte--action-tooltip']}>
              <ErrorBoundary key={src}>
                <div ref={ref} data-type='SocialEmbeds' className={styles['social-embed-wrapper']}>
                  <div data-type='ignore-deserializer'>
                    <Embeds url={src} width={"100%"} height={embedHeight}></Embeds>
                  </div>
                </div>
              </ErrorBoundary>
            </ActionTooltip>
          </div>
        </Resizable>
      </div>
      {children}
    </div>
  )
}

Registry.register(
  {
    Component: React.memo(SocialEmbededElement),
    iconName: <SocialEmbedIcon />,
    title: 'Social Embeds',
    subtitle: 'Embed Instagram, Twitter, Facebook, Youtube and TikTok',
    IngressComponent: EmbeddedOverlay,
    isContentStackElement: true,
    category: elementCategories.SOCIAL_EMBEDS
  },
  { id: 'social-embeds', registry: 'element' }
)

import React, { useRef, useState, useLayoutEffect } from 'react'
import './EntryReferenceDetails.css'
import Icon from '../Icon2/Icon'
import Tooltip from '../Tooltip/Tooltip'
import Heading from '../Typography/Heading/Heading'
import Paragraph from '../Typography/Paragraph/Paragraph'
import Button from '../Button/Button'
const defaultProps = {
  testId: 'cs-entry-reference-details',
  version: 'v1'
}

type DefaultProps = Readonly<typeof defaultProps>
const containerMaxWidth = 500;
const responsiveWidth = 768;
export type EntryReferenceDetailsProps = {
  title?: string
  contentType?: string
  onEdit?: () => void
  onDelete?: () => void
  onViewNestedEntries?: () => void,
  testId?: string
  LinkComponent?: any
  linkToRow?: string
  leftcomponent?: React.ReactNode
} & Partial<DefaultProps>

export type EntryTitleAndCTProps = {
  title?: string
  contentType?: string,
  isentryReferenceMaxWidth?: any
  version: string
  leftcomponent?: React.ReactNode
}

export type EditIconDomProps = {
  onEdit?: () => void
  LinkComponent?: any
  linkToRow?: string
  version: string
}

export const EntryTitleAndCT = ({ title, contentType, version, leftcomponent }: EntryTitleAndCTProps) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const contentTypeRef = useRef<HTMLDivElement>(null);
  const [isentryReferenceTitleWidth, setEntryReferenceTitleWidth] = useState(false);
  const [isentryReferenceContentWidth, setEntryReferenceContentWidth] = useState(false);

  const isEllipsisActive = (ref) => {
    return ref?.current?.offsetWidth < ref?.current?.scrollWidth;
  }
  useLayoutEffect(() => {
    if (contentTypeRef.current) {
      setEntryReferenceContentWidth(isEllipsisActive(contentTypeRef))
    }
    if (titleRef.current) {
      setEntryReferenceTitleWidth(isEllipsisActive(titleRef));
    }
  }, [title, contentType]);
  return (
    <div className="EntryReferenceDetails flex-v-center">
      {version === 'v2' ? <div>
        {leftcomponent ? leftcomponent : null}
      </div> : null}
      <div className='EntryReferenceDetails__tooltip'>
        <Tooltip content={title} position='top' disabled={version === "v2" ? false : !isentryReferenceTitleWidth}>
          {version === 'v2' ? <Heading tagName="h3" className={`EntryReferenceDetails__title-v2`} forwardRef={titleRef} text={title} /> : <h4 className='EntryReferenceDetails__title' ref={titleRef}> {title}</h4>}
        </Tooltip>

        <Tooltip content={contentType} position='top' disabled={version === "v2" ? false : !isentryReferenceContentWidth}>
          {version === 'v2' ? <Paragraph className={`EntryReferenceDetails__content-type-v2`} tagName="p" variant="p2" forwardRef={contentTypeRef} text={contentType} /> : <span className='EntryReferenceDetails__content-type' ref={contentTypeRef}>{contentType}</span>}
        </Tooltip>
      </div>
    </div>
  )
}

export const EditIconDom = ({ onEdit, LinkComponent, linkToRow, version }: EditIconDomProps) => {

  if (LinkComponent && linkToRow) {
    return (<Tooltip position="top" content="Edit">
      <>
        <LinkComponent
          className={`${version !== 'v2' ? "EntryReferenceDetails__action EntryReferenceDetails__action--edit flex" : ''}`}
          to={linkToRow}
        >
          {version === 'v2' ?
            <Button version="v2" className="mr-4" onlyIconHoverColor="primary" onlyIcon={true} size="small" icon="v2-Edit" /> :
            <Icon icon="Edit" />
          }
        </LinkComponent>
      </>
    </Tooltip>)
  }
  if (onEdit) {
    return (
      <Tooltip position="top" content="Edit">
        {version === "v1" ?
          <div
            onClick={onEdit}
            className="EntryReferenceDetails__action EntryReferenceDetails__action--edit flex"
          >
            <Icon icon="Edit" />
          </div> : <Button className="EntryReferenceDetails__action--edit-v2 mr-4" version="v2" onClick={onEdit} onlyIconHoverColor="primary" onlyIcon={true} icon="v2-Edit" />

        }
      </Tooltip>
    )
  }
  return null;
}

const EntryReferenceDetails = (props: EntryReferenceDetailsProps) => {
  let { title, contentType, onEdit, onDelete, onViewNestedEntries, testId, LinkComponent, linkToRow, version, leftcomponent } = props

  return (
    <div className={`EntryReferenceDetailsWrapper ${version === 'v2' ? 'EntryReferenceDetailsWrapper--v2' : ''}`} data-test-id={testId}>
      {LinkComponent && linkToRow ?
        <LinkComponent
          to={linkToRow}
        >
          <EntryTitleAndCT leftcomponent={leftcomponent} title={title} contentType={contentType} version={version} />
        </LinkComponent>
        :
        <EntryTitleAndCT leftcomponent={leftcomponent} title={title} contentType={contentType} version={version} />
      }
      <div className="Wrapper__actions EntryReferenceDetails__actions flex">
        {onViewNestedEntries ? <Tooltip position="top" content="View Nested Entries">
          {version === 'v1' ?
            <div onClick={onViewNestedEntries} className="EntryReferenceDetails__action EntryReferenceDetails__action--view flex">
              <Icon icon="ViewNestedEntries" size="small" />
            </div> :
            <Button className="EntryReferenceDetails__action--view-v2 mr-4" onClick={onViewNestedEntries} version="v2" onlyIconHoverColor="primary" onlyIcon={true} icon="v2-ListDashes" />
          }
        </Tooltip> : null}
        <EditIconDom onEdit={onEdit} LinkComponent={LinkComponent} linkToRow={linkToRow} version={version} />
        <Tooltip position="top" content="Remove">
          {version === 'v1' ?
            <div
              onClick={onDelete}
              className="EntryReferenceDetails__action EntryReferenceDetails__action--remove flex"
            >
              <Icon icon="RemoveFilled" />
            </div> :
            <Button className="EntryReferenceDetails__action--remove-v2" version="v2" onClick={onDelete} onlyIconHoverColor="primary" onlyIcon={true} icon="v2-Delete" />
          }
        </Tooltip>
      </div>
    </div>
  )
}

EntryReferenceDetails.defaultProps = defaultProps

export default EntryReferenceDetails
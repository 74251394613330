import React, { ReactNode, useRef } from 'react'
import PillIcon from './PillIcon'
import PillInput from './PillInput'
import { IPill, PillStatus, PillVariant } from './type'
import cn from 'classnames'
import { Draggable } from 'react-beautiful-dnd'
import Icon from '../Icon2'
import { Truncate } from '../Truncate/Truncate'

interface PillItemProps {
  itemId: string | number
  index: number
  text: string
  variant: PillVariant
  status?: PillStatus
  background?: string
  isDisabled?: boolean
  leadingIcon?: ReactNode
  trailingIcon?: ReactNode
  isEditable?: boolean
  onChangeItem?: (data: Omit<IPill, 'id'>, index: number) => void
  onNewItemAdd?: () => void
  onRemoveItem?: (index: number) => void
  isSortable?: boolean
  stroke?: string
  onPillClickHandler?: Function
  customKeyDown?: Function
  isLastTag?: Boolean
  isSingleton?: Boolean
  pillsCount?: Number
  canTruncate?: boolean
}

const draggableStyleFn = (isDragging) => (isDragging ? { outline: '1px solid #6C5CE7' } : {})

const PillItem: React.FC<PillItemProps> = (props) => {
  const {
    text,
    background,
    status,
    isDisabled,
    variant,
    leadingIcon,
    trailingIcon,
    isEditable,
    onChangeItem,
    onNewItemAdd,
    onRemoveItem,
    itemId,
    index,
    isSortable,
    stroke,
    onPillClickHandler,
    isSingleton,
    canTruncate
  } = props

  const handelSelfRemove = () => {
    onRemoveItem(index)
  }

  const handelOnChange = (value) => {
    if (!value) handelSelfRemove()
    if (onChangeItem && value && value !== text) onChangeItem({ text: value }, index)
  }

  const handleClick = (e) => {
    e.stopPropagation()
  }

  const handleOnClick = () => {
    onPillClickHandler && onPillClickHandler()
  }

  const pillKeydownHandler = (event: any) => {
    if (event?.key === "Enter" || event?.key === " ") {
      handleClick && handleClick(event)
      handleOnClick && handleOnClick()
    }
  }

  let itemStyle = undefined
  if (!(isSortable || isEditable) && (stroke || background)) {
    itemStyle = { color: '#475161' }
    if (!stroke && background) itemStyle.backgroundColor = background
    if (stroke) itemStyle.outline = `1px solid ${stroke}`
  }

  let itemClassName = cn(
    'PillItem flex-center',
    { PillItem__isDisabled: isDisabled },
    `PillItem__${variant}`,
    { [`PillItem__${status}`]: !(stroke || background || isSortable || isEditable) && status !== 'default' },
    { PillItem__interact: isSortable || isEditable },
    {
      PillItem__default:
        isSortable ||
        isEditable ||
        (status === 'default' && !stroke && !background) ||
        (!status && !stroke && !background)
    },
    { ['PillItem__link']: onPillClickHandler }
  )
  return (
    <Draggable draggableId={String(itemId)} key={itemId} index={index} isDragDisabled={!isSortable || isDisabled}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          style={{ ...provided.draggableProps.style, ...itemStyle, ...draggableStyleFn(snapshot.isDragging) }}
          className={itemClassName}
          data-test-id={`cs-pill-item-${index}`}
          onClick={handleClick}
          tabIndex={isSingleton ? 0 : null}
          onKeyDown={isSingleton ? pillKeydownHandler : null}
        >
          {isSortable ? (
            <PillIcon
              {...provided.dragHandleProps}
              role={undefined}
              aria-describedby={undefined}
              tabIndex={undefined}
              icon={<Icon icon="MoveIcon" size="extraSmall" />}
              variant={variant}
              pillIconPosition="start"
              className={`PillIcon__move ${snapshot.isDragging ? 'PillIcon__move--dragging' : ''} `}
            />
          ) : null}

          {leadingIcon ? <PillIcon icon={leadingIcon} variant={variant} pillIconPosition="start" /> : null}
          {isEditable ? (
            <PillInput
              canTruncate={canTruncate}
              className={`PillItem__inputElement ${snapshot.isDragging ? 'PillItem__inputElement--dragging' : ''}`}
              defaultValue={text}
              onChange={handelOnChange}
              onEnterPressed={onNewItemAdd}
              variant={variant}
              isDisabled={isDisabled}
              onRemoveItem={handelSelfRemove}
              isDragging={snapshot.isDragging}
              pillIndex={index}
              customKeyDown={props.customKeyDown}
              isLastTag={props.isLastTag}
              pillsCount={props.pillsCount}
            />
          ) : (
            <span
              className={`PillItem__text-content ${snapshot.isDragging ? 'PillItem__text-content--dragging' : ''}`}
              onClick={handleOnClick}>
              {canTruncate ?
                <Truncate isResponsive truncateFrom='end' >{text}</Truncate>
                : text}
            </span>
          )}
          {trailingIcon ? <PillIcon icon={trailingIcon} variant={variant} pillIconPosition="end" /> : null}
        </div>
      )}
    </Draggable>
  )
}

PillItem.defaultProps = {
  status: 'danger',
  variant: 'label',
  isDisabled: false,
  isSortable: false
}

export default PillItem

//@ts-nocheck
import React, { Fragment } from 'react'
import { Editor, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

import { ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup } from '../../../../../../../../index'
import { Editor as ModifiedEditor } from '../../../../../utils/types'
import Dropable from './Upload/Dropable'
import { Upload } from './Upload'
import { uploadImage } from '../request'
import './style.css'
import { insertImage } from '../../../image'
import { checkProps } from '../../utils/checkProps'
import { InvalidPropsMsg } from '../../utils/InvalidPropsMessage/InvalidPropsMsg'
import { insertEmbedAsset, SelectEmbedAssetTypeUpload } from './ModalComponent'
import { getAssetType } from './util'


declare interface ModifiedEditorSecond extends ModifiedEditor, ReactEditor { }

export declare type ModalComponentProps = {
  editor: ModifiedEditorSecond
  closeModal: Function,
  type: string;
}

declare type image = {}

export default class extends React.Component<ModalComponentProps> {
  state = {
    folderUid: 'cs_root',
    loading: false,
    selected: false,
    isInline: false,
    files: []
  }

  setFolderUid = (uid) => {
    this.setState({
      folderUid: uid,
    })
  }

  handleSubmit = (res) => {
    const files = this.state.files.length ? this.state.files : res;
    files.forEach((instance) => {
      const asset = instance.asset
      const url = asset?.url
      const filename = asset?.filename
      const editor = this.props.editor
      const selection = editor.selection || editor.savedSelection

      const isEditorBlur = !selection;
      if (isEditorBlur) {
        ReactEditor.focus(editor);
        Transforms.select(editor, Editor.end(editor, []));
      } else {
        Transforms.select(editor, selection);
      }
      if (this.props.type === 'asset') {
        insertEmbedAsset({ editor, asset, isInline: this.state.isInline });
      }
      else if (url) {
        insertImage(editor, url, { caption: filename, alt: filename })
      }
    })
    this.handleReset()
  }

  handleReset = () => {
    //setting selection on editor.savedSelection throws an error if the selection was pointing to some inline selection which is now not available after the insertion of asset
    //removing this to make the working similar to Choose Asset functionality
    this.props.closeModal()
  }

  setLoading = isLoading => this.setState({ loading: isLoading });

  handleUpload = (files, uid = '') => {
    const requestProps: Object = this.props.editor.requestProps || {};
    let folderUid = uid.length !== 0 ? uid : this.state.folderUid;
    folderUid = folderUid === 'cs_root' ? '' : folderUid;
    this.setState({ loading: true });
    uploadImage({ ...requestProps, files: files, folderUid })
      .then(res => {
        const isImage = Array.from(res).some(instance => getAssetType(instance?.['asset']?.['content_type']) === null);
        if (isImage) {
          this.setState({ selected: true, loading: false, files: res })
        } else {
          this.handleSubmit(res);
        }
      })
      .catch(err => this.setLoading(false));
  };


  setIsInline = (value) => {
    this.setState({
      ...this.state,
      isInline: value
    })
  }

  render() {
    const editor = this.props.editor
    const isPropsValid = checkProps({ editor });
    const isEmbed = this.props?.type === 'asset';
    const { selected } = this.state;
    return (
      <div id="scrte-image-modal">
        <ModalHeader title="Select a folder to upload asset(s)" closeModal={this.handleReset} />

        <ModalBody className="modalBodyCustomClass">
          {isPropsValid ? (
            <Fragment>
              <Upload loading={this.state.loading} editor={this.props.editor} handleUpload={this.handleUpload} setFolderUid={this.setFolderUid} selected={selected} />
              {isEmbed && selected && <SelectEmbedAssetTypeUpload imageSelected={{ content_type: 'image' }} isInline={this.state.isInline} setIsInline={this.setIsInline} />}
            </Fragment>
          ) : (
            <InvalidPropsMsg asset="Images" />
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={this.handleReset} buttonType="light" testId={"asset-modal-reset"}>
              Cancel
            </Button>
            {selected ? <Button onClick={this.handleSubmit} icon='CheckedWhite' loading={this.state.loading} testId={"asset-modal-submit"}>Insert Uploaded Images</Button> : <Dropable config={{ noDrag: true, multiple: true, onDrop: this.handleUpload }}>
              <Button icon='Upload' loading={this.state.loading}>Upload Here</Button>
            </Dropable>}

          </ButtonGroup>
        </ModalFooter>
      </div>
    )
  }
}

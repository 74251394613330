import React from 'react'
import { Registry } from '@react-registry'

//@ts-ignore
import styles from './style.module.css'
import { elementCategories } from '../elementCategories'
import { ParagraphIcon } from '../../utils/Icon'
const Paragraph = (props) => {
  return (
    <div {...props.attributes} {...props.attrs} className={styles['paragraph']}>
      <p data-testid='p'>{props.children}</p>
    </div>
  )
}

export default Paragraph

export * from './deserializer'
export * from './withParagrph'

Registry.register(
  {
    Component: Paragraph,
    title: 'Normal',
    subtitle: 'Just a simple text',
    slashTitle: 'Paragraph',
    // iconName: <ParagraphIcon />,
    slashIconName: <ParagraphIcon />,
    shortcut: { key: 'mod+alt+0' },
    category: elementCategories.TEXT_FORMAT,
    isContentStackElement: true,
    inBasicToolbar: true,
    keywords: ['paragraph']
  },
  { id: 'p', registry: 'v2.element' }
)

import axios from 'axios'
import {
  ICommentRequestBody,
  IDiscussionCreateOrUpdateResponse,
  IDiscussionDTO,
  IDiscussionFieldDTO,
  IEditorRequestProps,
  IEntryMetadata,
  IFieldMetadata,
  IMessageCreateOrUpdateResponse,
  IMessageDTO
} from '../../../utils/types'
import extend from 'lodash/extend'
import Notification from '../../../../../Notification/Notification'
import Q from '../../../../lib/requestModule'
import { parseDiscussionUID } from './index'

type IfetchActiveDiscussionResponse = {
  discussions: Array<IDiscussionDTO>
  isCachedValue ?: boolean
}
export type IfetchBatchMessagesForDiscussion = {
  count: number
  conversations: Array<IMessageDTO>
}
const getHeaders = (editorMetadata: IEditorRequestProps): { api_key: string; branch?: string } => {
  const headers = {
    api_key: editorMetadata.apiKey
  }
  if (editorMetadata.branch) {
    headers['branch'] = editorMetadata.branch
  }
  return headers
}
export const fetchActiveDiscussions = async (
  editorMetadata: IEditorRequestProps,
  doc_uid: string,
  content_type_uid: string,
  entry_uid: string,
  locale: string
): Promise<IfetchActiveDiscussionResponse> => {
  try {
    const { assetUrl, isPlugin = false } = editorMetadata
    const url = `${assetUrl}/content_types/${content_type_uid}/entries/${entry_uid}/discussion`
    const query = {
      'field.type': 'rteDiscussion',
      'field.doc_uid': doc_uid
    }
    const params = {
      locale,
      status: 'active',
      query
    }

    let promise = Promise.resolve()
    if (isPlugin) {
      //@ts-ignore
      if (!window.postRobot) return { discussions: [], isCachedValue: true}
      const res = await Q({
        action: 'getDiscussions',
        content_type_uid,
        entry_uid,
        params: {
          locale,
          status: 'active',
          query: JSON.stringify(query)
        }
      })
      const data = res.data
      if (data?.error_message) throw res
      return data
    } else {
      const res = await axios({
        method: 'GET',
        url,
        params,
        headers: getHeaders(editorMetadata)
      })
      return res.data
    }
  } catch (error) {
    const sanitizedError = handleApiError(error)
    throw sanitizedError.response || sanitizedError
  }
}

export const fetchBatchMessagesForDiscussion = async (
  editorMetadata: IEditorRequestProps,
  discussion_uid: string,
  content_type_uid: string,
  entry_uid: string,
  locale: string,
  skip: number = 0,
  limit: number = 10
): Promise<IfetchBatchMessagesForDiscussion> => {
  try {
    discussion_uid = parseDiscussionUID(discussion_uid)
    const { assetUrl, isPlugin = false } = editorMetadata
    const url = `${assetUrl}/content_types/${content_type_uid}/entries/${entry_uid}/discussion/${discussion_uid}/message`
    const params = {
      locale,
      status: 1,
      include_count: true,
      skip: skip,
      limit: limit
    }

    if (isPlugin) {
      const res = await Q({
        action: 'getConversations',
        content_type_uid,
        entry_uid,
        discussion_uid,
        params: params
      })
      const data = res.data
      if (data?.error_message) throw res
      return data
    } else {
      const res = await axios({
        method: 'GET',
        url,
        params,
        headers: getHeaders(editorMetadata)
      })
      return res.data
    }
  } catch (error) {
    const sanitizedError = handleApiError(error)
    throw sanitizedError.response || sanitizedError
  }
}
export const resolveDiscussions = async (
  editorMetadata: IEditorRequestProps,
  discussion_uid: string,
  content_type_uid: string,
  entry_uid: string,
  locale: string
): Promise<IDiscussionCreateOrUpdateResponse> => {
  try {
    discussion_uid = parseDiscussionUID(discussion_uid)
    const { assetUrl, isPlugin = false } = editorMetadata
    const url = `${assetUrl}/content_types/${content_type_uid}/entries/${entry_uid}/discussion/${discussion_uid}/resolve`
    const params = {
      locale
    }

    if (isPlugin) {
      const res = await Q({
        action: 'resolveDiscussion',
        content_type_uid,
        entry_uid,
        discussion_uid,
        params: params
      })
      const data = res.data
      if (data?.error_message) throw res
      return data
    } else {
      const res = await axios({
        method: 'GET',
        url,
        params,
        headers: getHeaders(editorMetadata)
      })
      return res.data
    }
  } catch (error) {
    const sanitizedError = handleApiError(error)
    throw sanitizedError.response || sanitizedError
  }
}
export const createNewDiscussion = async (
  editorMetadata: IEditorRequestProps,
  entryMetadata: IEntryMetadata,
  data: { field: IDiscussionFieldDTO; title: string }
): Promise<IDiscussionCreateOrUpdateResponse> => {
  try {
    const { assetUrl, isPlugin = false } = editorMetadata
    const { contentTypeUid, entryUid, locale } = entryMetadata

    const url = `${assetUrl}/content_types/${contentTypeUid}/entries/${entryUid}/discussion`
    const params = {
      locale
    }
    const requestBody = {
      discussion: data
    }
    if (isPlugin) {
      const res = await Q({
        action: 'createDiscussion',
        content_type_uid: contentTypeUid,
        entry_uid: entryUid,
        params: params,
        payload: requestBody
      })
      const data = res.data
      if (data?.error_message) throw res
      return data
    } else {
      const res = await axios({
        method: 'POST',
        url,
        params,
        data: requestBody,
        headers: getHeaders(editorMetadata)
      })
      return res.data
    }
  } catch (error) {
    const sanitizedError = handleApiError(error)
    throw sanitizedError.response || sanitizedError
  }
}

export const updateJsonRteDoc = async (
  editorMetadata: IEditorRequestProps,
  entryMetadata: IEntryMetadata,
  data: { field: IDiscussionFieldDTO; title?: string },
  rteDoc: object
) => {
  try {
    const { assetUrl, isPlugin = false } = editorMetadata
    const { contentTypeUid, entryUid, locale } = entryMetadata

    const url = `${assetUrl}/content_types/${contentTypeUid}/entries/${entryUid}/rte_blocks/${data.field['doc_uid']}`
    const params = {
      locale
    }
    const requestBody = {
      rte_block: rteDoc
    }
    if (isPlugin) {
      const res = await Q({
        action: 'updateJsonDoc',
        content_type_uid: contentTypeUid,
        entry_uid: entryUid,
        params: params,
        payload: requestBody
      })
      const data = res.data
      if (data?.error_message) throw res
      return data
    } else {
      const res = await axios({
        method: 'PUT',
        url,
        params,
        data: requestBody,
        headers: getHeaders(editorMetadata)
      })
      return res.data
    }
  } catch (error) {
    const sanitizedError = handleApiError(error)
    throw sanitizedError.response || sanitizedError
  }
}

export const createNewComments = async (
  editorMetadata: IEditorRequestProps,
  entryMetadata: IEntryMetadata,
  data: ICommentRequestBody
): Promise<IMessageCreateOrUpdateResponse> => {
  try {
    data.discussion_uid = parseDiscussionUID(data.discussion_uid)
    const { assetUrl, isPlugin = false } = editorMetadata
    const { contentTypeUid, entryUid, locale } = entryMetadata
    const url = `${assetUrl}/content_types/${contentTypeUid}/entries/${entryUid}/discussion/${data.discussion_uid}/message`
    const params = {
      locale
    }
    const requestBody = {
      conversation: data,
      locale: locale
    }
    if (isPlugin) {
      const res = await Q({
        action: 'createConversation',
        content_type_uid: contentTypeUid,
        entry_uid: entryUid,
        discussion_uid: data.discussion_uid,
        params: params,
        payload: requestBody
      })
      const resData = res.data
      if (resData?.error_message) throw res
      return resData
    } else {
      const res = await axios({
        method: 'POST',
        url,
        params,
        data: requestBody,
        headers: getHeaders(editorMetadata)
      })
      return res.data
    }
  } catch (error) {
    const sanitizedError = handleApiError(error)
    throw sanitizedError.response || sanitizedError
  }
}
export const deleteSingleComment = async (
  editorMetadata: IEditorRequestProps,
  entryMetadata: IEntryMetadata,
  discussion_uid: string,
  comment_uid: string
): Promise<{ notice: string }> => {
  try {
    const { assetUrl, isPlugin = false } = editorMetadata
    const { contentTypeUid, entryUid, locale } = entryMetadata
    discussion_uid = parseDiscussionUID(discussion_uid)

    const url = `${assetUrl}/content_types/${contentTypeUid}/entries/${entryUid}/discussion/${discussion_uid}/message/${comment_uid}`
    const params = {
      locale
    }
    if (isPlugin) {
      const res = await Q({
        action: 'deleteConversation',
        content_type_uid: contentTypeUid,
        entry_uid: entryUid,
        discussion_uid: discussion_uid,
        conversation_uid: comment_uid,
        params: params
      })
      const resData = res.data
      if (resData?.error_message) throw res
      return resData
    } else {
      const res = await axios({
        method: 'DELETE',
        url,
        params,
        headers: getHeaders(editorMetadata)
      })
      return res.data
    }
  } catch (error) {
    const sanitizedError = handleApiError(error)
    throw sanitizedError.response || sanitizedError
  }
}
export const updateSingleComment = async (
  editorMetadata: IEditorRequestProps,
  entryMetadata: IEntryMetadata,
  data: ICommentRequestBody,
  comment_uid: string
): Promise<IMessageCreateOrUpdateResponse> => {
  try {
    data.discussion_uid = parseDiscussionUID(data.discussion_uid)
    const { assetUrl, isPlugin = false } = editorMetadata
    const { contentTypeUid, entryUid, locale } = entryMetadata
    const { discussion_uid, ...body } = data
    const url = `${assetUrl}/content_types/${contentTypeUid}/entries/${entryUid}/discussion/${discussion_uid}/message/${comment_uid}`
    const params = {
      locale
    }
    const requestBody = {
      conversation: body
    }
    if (isPlugin) {
      const res = await Q({
        action: 'updateConversation',
        content_type_uid: contentTypeUid,
        entry_uid: entryUid,
        discussion_uid: discussion_uid,
        conversation_uid: comment_uid,
        params: params,
        payload: requestBody
      })
      const resData = res.data
      if (resData?.error_message) throw res
      return resData
    } else {
      const res = await axios({
        method: 'PUT',
        url,
        params,
        data: requestBody,
        headers: getHeaders(editorMetadata)
      })
      return res.data
    }
  } catch (error) {
    const sanitizedError = handleApiError(error)
    throw sanitizedError.response || sanitizedError
  }
}
const handleApiError = (err: any) => {
  const errorResponse = err.response || err || {}

  if (!(errorResponse.status >= 400 && errorResponse.status < 500)) {
    return err
  }

  if (errorResponse.status >= 500) {
    let errBody: any = new Error(err.message || '')
    errBody.status = err.status
    errBody = extend(errBody, err.data)
    return errBody
  }
  return err
}
export const failureNotification = (errorMessage: any, errors: any) => {
  Notification({
    displayContent: { text: errorMessage, error: errors },
    notifyProps: { hideProgressBar: true },
    type: 'error'
  })
}

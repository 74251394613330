import React, { useState, useRef, useMemo, MouseEventHandler, forwardRef, ReactElement, Ref } from 'react'
import cn from 'classnames'

import Icon from '../Icon2/Icon'
import Button from '../Button/Button'

import './PageLayout.css'
import { isEnterOrSpacePressed, isArrowRightPressed, isArrowLeftPressed, isArrowUpPressed, isArrowDownPressed } from 'utils/keyboardUtils'

export type headerProps = {
  backNavigation?: Function
  Breadcrumb?: React.ReactNode
  component?: React.ReactNode
  title?: string
  headerLogoOnClick?: MouseEventHandler
}

export type mainContentProps = {
  component?: React.ReactNode
  livePreview?: React.ReactNode
}

export type sidebarProps = {
  component?: React.ReactNode
}

export type topNavBarProps = {
  component?: React.ReactNode
}

export type stickyFooterProps = {
  component?: React.ReactNode
}

export type layoutProps = {
  footer?: stickyFooterProps
  content?: mainContentProps | emptyPageContentProps
  header?: headerProps
  headerType?: string
  leftSidebar?: sidebarProps
  rightSidebar?: sidebarProps
  layoutBackgroundColor?: boolean
  leftFooter?: React.ReactNode
  rightFooter?: React.ReactNode
  type: string
  className?: string
  mode?: 'compact' | 'fullscreen'
  topNavBar?: topNavBarProps
  topNavMode?: boolean
  version?: 'v2'
  hasBackground?: boolean
  testId?: string
}

export type contentProps = {
  content: mainContentProps
  type: string
  toggle?: Function
  toggleStat?: boolean
  headerType?: string
  mode?: 'compact' | 'fullscreen'
  layoutBackgroundColor?: boolean
  onKeyDown?: any
  version?: string
}

export type layoutHeaderProps = {
  header: headerProps
  toggle?: Function
  toggleStat?: boolean
  type: string
  headerType?: string
  mode?: 'compact' | 'fullscreen'
  layoutBackgroundColor?: boolean
  onKeyDown?: any
  testId?: string
  version?: string
}

export type leftSidebarProps = {
  leftSidebar: sidebarProps
}

export type rightSidebarProps = {
  rightSidebar: sidebarProps
}

export type footerProps = {
  footer?: stickyFooterProps
  onKeyDown?: any
}

export type rightFooterProps = {
  rightFooter?: React.ReactNode
  onKeyDown?: any
}

export type leftFooterProps = {
  leftFooter?: React.ReactNode
  onKeyDown?: any
}

export type secondaryLayoutProps = {
  content: mainContentProps
  footer?: stickyFooterProps
  header: headerProps
  leftSidebar?: sidebarProps
  rightSidebar?: sidebarProps
  className?: string
  mode?: 'compact' | 'fullscreen'
  layoutBackgroundColor?: boolean
  leftFooter?: React.ReactNode
  rightFooter?: React.ReactNode
  topNavBar?: topNavBarProps
  version?: 'v2'
  testId?: string
}

export type primaryLayoutProps = {
  content: mainContentProps
  header: headerProps
  leftSidebar?: sidebarProps
  type?: string
  headerType?: string
  className?: string
  hasBackground?: boolean
  testId?: string
  version?: string
}

export type emptyPageContentProps = {
  component?: React.ReactNode
  alignContent?: 'center'
  backgroundColor?: 'primary' | 'secondary'
}

export type emptyPageProps = {
  content: emptyPageContentProps
  header?: headerProps
  testId?: string
}

const getKeyboardFocusableElements = (element = document) => {
  const elements = element.querySelectorAll(
    'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
  )
  return Array.from(elements).filter(
    el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
  )
}


const setRequiredFocus = (currentRef: React.MutableRefObject<any>) => {
  const items = getKeyboardFocusableElements(currentRef.current)
  if (items.length) {
    (items[0] as HTMLElement).focus()
  }
  else {
    currentRef?.current?.focus()
  }
}


const TopNavbar = ({ topNavBar }: { topNavBar: topNavBarProps }) => {
  return <>{topNavBar.component}</>
}

const LayoutHeader = forwardRef((props: layoutHeaderProps, layoutHeaderRef: Ref<any>) => {
  let headClassname = ''
  if (props.type === 'card') {
    headClassname = props.headerType === 'custom' ? 'PageLayout__head--card--custom-header' : 'PageLayout__head--card'
  }
  return useMemo(() => {
    if (props.header !== undefined) {
      return (
        <div onKeyDown={props.onKeyDown} ref={layoutHeaderRef} tabIndex={-1} className={`PageLayout__head ${headClassname}`}>
          {props.toggleStat !== undefined ? (
            <div

              className="PageLayout__head__hamburger"
              data-test-id={`${props.testId}-hamburger-menu`}
              id="PageLayout-hamburger"
              onClick={() => props.toggle()}
              tabIndex={0}
              onKeyDown={(e: any) => {
                if (isEnterOrSpacePressed(e.key)) {
                  e.preventDefault()
                  e.stopPropagation()
                  props.toggle(e)
                }
              }}>
              {props.version === "v2" ? <Icon icon="CaretLeft" size="tiny" version='v2' /> : <Icon icon="Hamburger" size="original" />}
            </div>
          ) : (
            // <div
            //   data-test-id="cs-page-layout-go-back"
            //   ref={layoutHeaderRef}
            //   className="PageLayout__head__backArrow"
            //   tabIndex={0}
            //   onKeyDown={(e: any) => {
            //     if (isEnterOrSpacePressed(e.key)) {
            //       e.preventDefault()
            //       e.stopPropagation()
            //       props.header.backNavigation(e)
            //     }
            //   }}
            //     onClick={ ( event: any ) => props.header.backNavigation( event ) }>
            //    <Icon icon="LeftArrow" size="medium" version="v2"/>
            // </div>
            <Button
              className="PageLayout__head__backArrow"
              buttonType="tertiary"
              onlyIcon={true}
              ref={layoutHeaderRef}
              icon={'v2-LeftArrow'}
              onlyIconHoverColor='secondary'
              aria-label="Go back"
              testId="cs-page-layout-go-back"
              version="v2"
              onKeyDown={(e: any) => {
                if (isEnterOrSpacePressed(e.key)) {
                  e.preventDefault()
                  e.stopPropagation()
                  props.header.backNavigation(e)
                }
              }}
              onClick={(event: any) => props.header.backNavigation(event)} />
          )}
          {props.header.component !== undefined ? props.header.component : ''}
        </div>
      )
    } else {
      return null
    }
  }, [props.header && props.header.title, props.toggleStat, props.header && props.header.component])
})


const LeftSidebar = (props: leftSidebarProps) => {
  return <>{props.leftSidebar.component}</>
}

const RightSidebar = (props: rightSidebarProps) => {
  return <>{props.rightSidebar.component}</>
}
const Footer = (props: footerProps) => {
  return <>{props.footer.component}</>
}

const Content = forwardRef((props: contentProps, contentRef: Ref<any>) => {
  let cardClassname = ''
  if (props.type === 'card') {
    cardClassname = props.headerType === 'custom' ? 'PageLayout__body--card--custom-header' : 'PageLayout__body--card'
  }
  return useMemo(() => {
    if (props.content !== undefined) {
      return (
        <>
          {props.type === 'edit' ? (

            <div ref={contentRef} onKeyDown={props.onKeyDown} className={`PageLayout__body`} id="PageLayout__body">
              {props.content.livePreview}
              <div className="PageLayout__body__container" >{props.content.component}</div>
            </div>
          ) : (
            <div ref={contentRef} onKeyDown={props.onKeyDown} className={`PageLayout__body ${cardClassname}`}>
              {props.content.component}
              {
                props.version === 'v2' ? <div className="PageLayout__body--left-drawer"
                  onMouseOver={() => {
                    const hamburgerIcon = document.getElementById('PageLayout-hamburger')
                    hamburgerIcon?.classList?.add('PageLayout__head__hamburger__v2')
                  }}
                  onMouseOut={() => {
                    const hamburgerIcon = document.getElementById('PageLayout-hamburger')
                    hamburgerIcon?.classList?.remove('PageLayout__head__hamburger__v2')
                  }}

                  onClick={() => props.toggle()}></div> : <></>
              }
            </div>
          )}
        </>
      )
    } else {
      return null
    }
  }, [props.content, props.content.component, props.toggleStat])
})

const ListLayout = (props: primaryLayoutProps) => {
  const [toggleLeftNav, setLeftNav] = useState(true)
  const sidebar = useRef(null)
  const [toggleLayout, setToggleLayout] = useState(false)

  const handleToggle = () => {
    setLeftNav(!toggleLeftNav)
    let leftSidebarElem: any = sidebar.current
    if (sidebar.current.classList.contains('PageLayout__leftSidebar--show')) {
      setTimeout(function () {
        leftSidebarElem.style.zIndex = '8'
        leftSidebarElem.style.zIndex = props.version === 'v2' ? 'auto' : '8';
      }, 300)
    } else {
      leftSidebarElem.style.zIndex = '2'
    }
    setToggleLayout(!toggleLayout)
  }
  let contentCardClassname = ''
  if (props.type === 'card') {
    contentCardClassname =
      props.headerType === 'custom' ? 'PageLayout__content--card--custom-header' : 'PageLayout__content--card'
  }
  const leftSidebarClassName = !!toggleLeftNav ? 'PageLayout__leftSidebar--show' : 'PageLayout__leftSidebar--hide'
  const leftSidebarBackground = props.hasBackground ? 'PageLayout__leftSidebar--pure__white' : ''
  const primaryLayoutClassnames = cn("PageLayout PageLayout--primary", props.className, {
    "PageLayout--primary--v2": props.version,
  })
  return (
    <div
      data-test-id={props.testId}
      className={primaryLayoutClassnames}>
      {props.leftSidebar !== undefined ? (
        <div
          ref={sidebar}
          className={`PageLayout__leftSidebar ${leftSidebarBackground} ${leftSidebarClassName}`}
          data-test-id={`${props.testId}-leftSidebar`}>
          <LeftSidebar leftSidebar={props.leftSidebar} />
        </div>
      ) : null}
      <div
        className={`PageLayout__content ${props.leftSidebar === undefined ? 'PageLayout__content--full' : ''
          } ${contentCardClassname} ${toggleLayout ? 'PageLayout__content--full' : ''}`}>
        {props.leftSidebar !== undefined ? (
          <LayoutHeader
            header={props.header}
            type={props.type}
            headerType={props.headerType}
            toggleStat={toggleLeftNav}
            toggle={handleToggle}
            testId={props.testId}
            version={props.version}
          />
        ) : (
          <LayoutHeader
            header={props.header}
            headerType={props.headerType}
            toggle={handleToggle}
            type={props.type}
            testId={props.testId}
          />
        )}
        <Content
          content={props.content}
          type={props.type}
          headerType={props.headerType}
          toggle={handleToggle}
          toggleStat={toggleLeftNav}
          version={props.version}
        />
      </div>
    </div>
  )
}
ListLayout.defaultProps = {
  testId: 'cs-list-layout'
}

const RightFooter = forwardRef((props: rightFooterProps, rightFooterRef: any) => {
  const { rightFooter } = props
  return <div className="PageLayout__right-footer" ref={rightFooterRef}>{rightFooter}</div>
})

const LeftFooter = forwardRef((props: leftFooterProps, leftFooterRef: any) => {
  const { leftFooter } = props
  return <div className="PageLayout__left-footer" ref={leftFooterRef}>{leftFooter}</div>
})


const FullLayout = (props: secondaryLayoutProps) => {
  const {
    className,
    header,
    mode,
    leftSidebar,
    rightSidebar,
    content,
    footer,
    layoutBackgroundColor,
    rightFooter,
    leftFooter,
    topNavBar,
    version,
    testId
  } = props

  const contentRef = useRef(null);
  const leftSidebarRef = useRef(null);
  const rightSidebarRef = useRef(null);
  const footerRef = useRef(null);
  const rightFooterRef = useRef(null);
  const leftFooterRef = useRef(null);
  const topNavBarRef = useRef(null);
  const layoutHeaderRef = useRef(null);


  const fullPageClassName = !leftFooter ? `${footer ? '' : 'PageLayout--content-fullpage'}` : ''

  const secondaryLayoutClassnames = cn('PageLayout PageLayout--secondary', className, {
    'PageLayout--full-screen': mode === 'fullscreen',
    'PageLayout--content-fullpage': fullPageClassName,
    'PageLayout--secondary-edit': layoutBackgroundColor === true,
    'PageLayout--secondary-edit--v2': version
  })

  const handleContentKeyUp = (e) => {
    if (e.altKey && e.shiftKey) {
      if (isArrowRightPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(rightSidebarRef)
      }
      if (isArrowLeftPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(leftSidebarRef)
      }
      if (isArrowUpPressed(e.key)) {
        e.preventDefault();
        if (topNavBarRef?.current) {
          setRequiredFocus(topNavBarRef)
        } else if (layoutHeaderRef?.current) {
          setRequiredFocus(layoutHeaderRef)
        }
      }
      if (isArrowDownPressed(e.key)) {
        e.preventDefault();
        if (footerRef?.current) {
          setRequiredFocus(footerRef)
        }
        else if (leftFooterRef?.current) {
          setRequiredFocus(leftFooterRef)
        }
      }
    }
  }

  const handleLeftFooterKeyUp = (e) => {
    if (e.altKey && e.shiftKey) {
      if (isArrowRightPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(rightFooterRef);
      }
      if (isArrowLeftPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(leftSidebarRef);
      }
      if (isArrowUpPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(contentRef);
      }
    }
  }

  const handleRightFooterKeyUp = (e) => {
    if (e.altKey && e.shiftKey) {
      if (isArrowRightPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(rightSidebarRef);
      }
      if (isArrowLeftPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(leftFooterRef);
      }
      if (isArrowUpPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(contentRef);
      }
    }
  }

  const handleFooterKeyUp = (e) => {
    if (e.altKey && e.shiftKey) {
      if (isArrowRightPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(rightSidebarRef);
      }
      if (isArrowLeftPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(leftSidebarRef);
      }
      if (isArrowUpPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(contentRef);
      }
    }
  }

  const handleTopNavBarKeyUp = (e) => {
    if (e.altKey && e.shiftKey) {
      if (isArrowRightPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(rightSidebarRef);
      }
      if (isArrowLeftPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(leftSidebarRef);
      }
      if (isArrowDownPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(contentRef);
      }
      if (isArrowUpPressed(e.key)) {
        e.preventDefault();
        if (layoutHeaderRef?.current) {
          setRequiredFocus(layoutHeaderRef);
        }
      }
    }
  }

  const handleLayoutHeaderKeyUp = (e) => {
    if (e.altKey && e.shiftKey) {
      if (isArrowRightPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(rightSidebarRef);
      }
      if (isArrowLeftPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(leftSidebarRef);
      }
      if (isArrowDownPressed(e.key)) {
        e.preventDefault();
        if (topNavBarRef?.current) {
          setRequiredFocus(topNavBarRef);
        } else if (contentRef?.current) {
          setRequiredFocus(contentRef);
        }
      }
    }
  }

  const handleLeftSideBarKeyUp = (e) => {
    if (e.altKey && e.shiftKey) {
      if (isArrowRightPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(contentRef)
      }
      if (isArrowDownPressed(e.key)) {
        e.preventDefault();
        if (footerRef?.current) {
          setRequiredFocus(footerRef)
        }
        else if (leftFooterRef?.current) {
          setRequiredFocus(leftFooterRef)
        }
        else if (rightSidebarRef?.current) {
          setRequiredFocus(rightSidebarRef)
        }
      }
      if (isArrowUpPressed(e.key)) {
        e.preventDefault();
        if (topNavBarRef?.current) {
          setRequiredFocus(topNavBarRef)

        } else if (layoutHeaderRef?.current) {
          setRequiredFocus(layoutHeaderRef)
        }
      }
    }
  }

  const handleRightSideBarKeyUp = (e) => {
    if (e.altKey && e.shiftKey) {
      if (isArrowLeftPressed(e.key)) {
        e.preventDefault();
        setRequiredFocus(contentRef);
      }
      if (isArrowDownPressed(e.key)) {
        e.preventDefault();
        if (footerRef?.current) {
          setRequiredFocus(footerRef);
        }
        else if (rightSidebarRef?.current) {
          setRequiredFocus(rightSidebarRef);
        }
        else if (leftFooterRef?.current) {
          setRequiredFocus(leftFooterRef);
        }
      }
      if (isArrowUpPressed(e.key)) {
        e.preventDefault();
        if (topNavBarRef?.current) {
          setRequiredFocus(topNavBarRef);
        } else if (layoutHeaderRef?.current) {
          setRequiredFocus(layoutHeaderRef);
        }
      }
    }
  }

  return (
    <div data-test-id={testId} className={secondaryLayoutClassnames}>
      <LayoutHeader onKeyDown={handleLayoutHeaderKeyUp} ref={layoutHeaderRef} header={header} type={'edit'} mode={mode} />
      <div className={`PageLayout__content`} >
        {leftSidebar && (
          <div className={`PageLayout__leftSidebar`} tabIndex={-1} ref={leftSidebarRef} onKeyDown={handleLeftSideBarKeyUp}>
            <LeftSidebar leftSidebar={leftSidebar} />
          </div>
        )}
        {rightSidebar && (
          <div className={`PageLayout__rightSidebar`} tabIndex={-1} ref={rightSidebarRef} onKeyDown={handleRightSideBarKeyUp}>
            <RightSidebar rightSidebar={rightSidebar} />
          </div>
        )}
        {topNavBar && (
          <div className={`PageLayout__topNavbar`} tabIndex={-1} ref={topNavBarRef} onKeyDown={handleTopNavBarKeyUp}>
            <TopNavbar topNavBar={topNavBar} />
          </div>
        )}
        <Content onKeyDown={handleContentKeyUp} ref={contentRef} content={content} type={'edit'} mode={mode} />
        {footer && (
          <div className={`PageLayout__footer`} tabIndex={-1} ref={footerRef} onKeyDown={handleFooterKeyUp}>
            <Footer footer={footer} />
          </div>
        )}
        {rightFooter && <RightFooter onKeyDown={handleRightFooterKeyUp} ref={rightFooterRef} rightFooter={rightFooter} />}
        {leftFooter && <LeftFooter onKeyDown={handleLeftFooterKeyUp} ref={leftFooterRef} leftFooter={leftFooter} />}
      </div>
    </div>
  )
}
FullLayout.defaultProps = {
  testId: 'cs-full-layout'
}

const EmptyPage = (props: emptyPageProps) => {
  const PageHeaderWithCSLogo = () => (
    <div className="PageLayout__head--emptyLayout" data-test-id={props.testId}>
      <div
        className={`PageLayout__head--CSLogo ${props.header?.headerLogoOnClick ? 'clickable' : ''}`}
        onClick={props.header?.headerLogoOnClick}>
        <Icon icon="ContentstackLogoGrey" />
      </div>
    </div>
  )

  return (
    <div
      data-test-id={props.testId}
      className={`PageLayout--emptyLayout
        ${props.content.backgroundColor === 'secondary' ? 'secondaryBackground' : 'primaryBackground'}`}>
      <PageHeaderWithCSLogo />
      <div
        className={`${props.content.alignContent === 'center' ? 'PageLayout__content--center' : 'PageLayout__content'
          }`}>
        {props.content.component}
      </div>
    </div>
  )
}
EmptyPage.defaultProps = {
  testId: 'cs-empty-page'
}

const PageLayout = (props: layoutProps) => {
  const {
    header,
    leftSidebar,
    content,
    type,
    rightSidebar,
    footer,
    headerType,
    className,
    mode,
    layoutBackgroundColor,
    rightFooter,
    leftFooter,
    topNavBar,
    version,
    testId,
    hasBackground
  } = props

  switch (type) {
    case 'list':
      return (
        <ListLayout header={header} leftSidebar={leftSidebar} content={content} className={className} testId={testId} hasBackground={hasBackground} version={version} />
      )
    case 'card':
      return (
        <ListLayout
          header={header}
          headerType={headerType}
          leftSidebar={leftSidebar}
          content={content}
          type={type}
          className={className}
          testId={testId}
        />
      )
    case 'edit':
      return (
        <FullLayout
          header={header}
          leftSidebar={leftSidebar}
          content={content}
          rightSidebar={rightSidebar}
          footer={footer}
          className={className}
          mode={mode}
          layoutBackgroundColor={layoutBackgroundColor}
          rightFooter={rightFooter}
          leftFooter={leftFooter}
          topNavBar={topNavBar}
          version={version}
          testId={testId}
        />
      )
    case 'empty':
      return <EmptyPage content={content} header={header} testId={testId} />
    default:
      return null
  }
}

export default PageLayout

PageLayout.defaultProps = {
  testId: 'cs-page-layout',
  hasBackground: false
}


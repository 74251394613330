import React, { useState, useRef, useEffect } from 'react'

import Field from '../../Field/Field'
import TextInput from '../../TextInput/TextInput'
import Textarea from '../../Textarea/Textarea'
import Tooltip from '../../Tooltip/Tooltip'
import FieldLabel from '../../FieldLabel/FieldLabel'
import Checkbox from '../../Checkbox/Checkbox'
import Radio from '../../Radio'
import Help from '../../Help/Help'
import Datepicker from '../../Datepicker/Datepicker'
import InfoModal from '../../InfoModal/InfoModal'
import TimePicker2 from '../../Timepicker/TimePicker2'

import DateTimepicker2 from '../../DateTimepicker/DateTimepicker2'
import { defaultTimezone } from '../../DateTime/helper'
import DSTTimeZoneList from '../../DateTime/TimeZoneList'

import { fieldUidRegex, getContentTypeFieldDataTestId, CONTENT_TYPE_FIELD_ACTION_IDS } from '../helper'

export const DateField = (props: any) => {
  const { onChange, parentInfo, uniqueKey, schema, type, isNonLocalizable, hasNonLocalizablePlan } = props
  const [, setSchema] = useState(schema)
  const { display_name, uid, field_metadata } = schema
  const [error, setError] = useState(fieldUidRegex(schema.uid, schema))

  let todaydate = new Date()
  let foundZone = DSTTimeZoneList().find((singleZoneObj) => defaultTimezone('') === singleZoneObj.value)
  let foundZoneVal = foundZone ? foundZone.offsetString : '+0000'
  console.log('foundZone ', foundZone, foundZoneVal)
  let newDate: any = `${todaydate.getFullYear()}-${todaydate.getMonth() + 1}-${todaydate.getDate()}`
  let newTime: any = '00:00:00' + foundZoneVal

  if (schema.field_metadata && schema.field_metadata.default_value && schema.field_metadata.default_value.date) {
    newDate = new Date(schema.field_metadata.default_value.date)
  }
  if (schema.field_metadata && schema.field_metadata.default_value && schema.field_metadata.default_value.time) {
    newTime = schema.field_metadata.default_value.time
  }

  //custom date
  const [customDateVal, setCustomDate] = useState('')
  const [customTimeVal, setCustomTime] = useState('')

  const [selectedStartDate, setSelectedStartDate]: any = useState({})
  const [selectedEndDate, setSelectedEndDate]: any = useState({})

  const displayNameRef: any = useRef(null)

  useEffect(() => {
    displayNameRef?.current?.focus()
  }, [displayNameRef])

  const onchange = () => {
    onChange && onChange(schema)
    setSchema({ ...schema })
  }
  // const { schema, onChange, uniqueKey } = props
  // const { display_name, uid, field_metadata, error_messages = {} } = schema
  // const onchange = () => {
  //   onChange && onChange(schema)
  // }
  const onDisplayNameChange = (event: any) => {
    schema.display_name = event.target.value

    if (schema.hasOwnProperty('display_name') && schema.newfield) {
      schema.uid = schema.display_name
        .trim()
        .replace(/[^A-Z0-9]+/gi, '_')
        .toLowerCase()
      if (schema.display_name) {
        schema.error_details = {
          ...schema.error_details,
          uid: ''
        }
      }
    }

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.display_name.length) {
      schema.error_details = {
        ...schema.error_details,
        display_name: 'Display Name cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        display_name: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onUidChange = (event: any) => {
    setError(fieldUidRegex(event.target?.value?.trim(), schema))

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.uid.length) {
      schema.error_details = {
        ...schema.error_details,
        uid: 'uid cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        uid: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onInstructionChange = (event: any) => {
    field_metadata.instruction = event.target.value
    onchange()
  }
  const onHelpTextChange = (event: any) => {
    field_metadata.description = event.target.value
    onchange()
  }
  const onOptionsChange = (event: any) => {
    if (event.target.name === `${uniqueKey}.mandatory`) {
      schema.mandatory = event.target.checked
    }
    if (event.target.name === `${uniqueKey}.hideTime`) {
      if (event.target.checked) {
        field_metadata.hide_time = true
        if (schema.startDate) {
          schema.startDate = schema.startDate.split('T')[0]
        }
        if (schema.endDate) {
          schema.endDate = schema.endDate.split('T')[0]
        }
      } else {
        field_metadata.hide_time = false
        if (schema.startDate) {
          schema.startDate = schema.startDate + 'T' + newTime
        }
        if (schema.endDate) {
          schema.endDate = schema.endDate + 'T' + newTime
        }
      }
    }
    if (event.target.name === `${uniqueKey}.setDateRange`) {
      field_metadata.date_range = event.target.checked
    }
    if (event.target.name === `${uniqueKey}.multiple`) {
      schema.multiple = event.target.checked
      schema.error_details = {
        ...schema.error_details,
        max_instance: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
      console.log('Error Date ', schema)
      if (!schema.multiple) {
        delete schema.max_instance
      }
    }
    if (event.target.name === `${uniqueKey}.nonLocalization`) {
      schema.non_localizable = event.target.checked
    }
    onchange()
  }
  const onDefaultValueChange = (event: any) => {
    if (event.target.value === 'createdDate') {
      schema.field_metadata.default_value = { custom: false, date: '', time: '' }
    }
    if (event.target.value === 'customDate') {
      schema.field_metadata.default_value = { custom: true, date: customDateVal, time: customTimeVal }
    }
    onchange()
  }
  const onStartDateChangeHandler = (date: any) => {
    schema.startDate = date
    if (schema.startDate && schema.endDate) {
      let date1 = schema.startDate
      let date2 = schema.endDate
      if (date2 < date1) {
        schema.error_details = {
          ...schema.error_details,
          start_date: 'Start Date cannot be greater than End Date'
        }
        schema.error = true
      } else {
        schema.error_details = {
          ...schema.error_details,
          start_date: ''
        }
        schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
          ? true
          : false
      }
    }

    onchange()
  }
  const onEndDateChangeHandler = (date: any) => {
    schema.endDate = date

    if (schema.startDate && schema.endDate) {
      let date1 = schema.startDate
      let date2 = schema.endDate
      if (date2 < date1) {
        schema.error_details = {
          ...schema.error_details,
          end_date: 'End Date cannot be less than Start Date'
        }
        schema.error = true
      } else {
        schema.error_details = {
          ...schema.error_details,
          end_date: ''
        }
        schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
          ? true
          : false
      }
    }
    onchange()
  }


  const onMaxInstance = (event: any) => {
    schema.max_instance = event.target.value ? parseInt(event.target.value) : null

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (event.target.value.length && schema.max_instance < 0) {
      schema.error_details = {
        ...schema.error_details,
        max_instance: 'max instance cannot be less then zero'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        max_instance: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }

    onchange()
  }

  //DatePicker
  const targetRefDate = useRef(null)
  const targetRefTime = useRef(null)
  const targetRefDateTimeStart = useRef(null)
  const targetRefDateTimeEnd = useRef(null)

  //date range
  // const todayDate = new Date();
  // todayDate.setHours(0, 0, 0, 0);
  // const [today, setToday] = useState(todayDate.toISOString());

  const todayDate = new Date()
  let convertedDate = todayDate.toISOString()
  let setDateTime = convertedDate.split('.')[0].split('T')
  let result = setDateTime[1].replace(setDateTime[1], '00:00:00')
  let finalResult = setDateTime[0] + 'T' + result
  const [today] = useState(finalResult)

  if (type === 'basic') {
    return (
      <div className="FormFields">
        <div className="FormFields__column">
          <Field>
            <FieldLabel required htmlFor="displayName">
              Display Name
            </FieldLabel>
            <TextInput
              required
              name={`${uniqueKey}.displayName`}
              value={display_name}
              inputRef={displayNameRef}
              onChange={onDisplayNameChange}
              error={schema.error && schema.error_details.display_name ? true : false}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.DISPLAY_NAME_INPUT)}

            />
          </Field>
          <Field>
            <FieldLabel htmlFor="helpText">Help Text</FieldLabel>
            <Help text="Provide additional info about the field. The help text will appear when the user hovers on the ‘?’ sign beside the field name." />
            <Textarea
              name={`${uniqueKey}.helpText`}
              value={field_metadata.description || ''}
              onChange={onHelpTextChange}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              // multiline={true}
              testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.HELP_TEXT_INPUT)}
            />
          </Field>
        </div>
        <div className="FormFields__column">
          <Field>
            <FieldLabel required htmlFor="uid">
              Unique ID
            </FieldLabel>
            <Help text="A unique ID for this field. This should not be same as the ID of any other field in this content type." />
            <TextInput
              required
              name={`${uniqueKey}.uid`}
              value={uid}
              onChange={onUidChange}
              error={(schema.error && schema.error_details.uid) || error ? true : false}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.UID_INPUT)}
            />
          </Field>
          <Field>
            <FieldLabel htmlFor="instruction">Instruction Value</FieldLabel>
            <Help text="Helps you add instructions for this field. The instructional text will appear below the field." />
            <Textarea
              name={`${uniqueKey}.instruction`}
              value={field_metadata.instruction || ''}
              onChange={onInstructionChange}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              // multiline={true}
              testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.INSTRUCTION_INPUT)}
            />
          </Field>
        </div>
      </div>
    )
  } else if (type === 'advanced') {
    console.log('schema change called====', schema, selectedStartDate, selectedEndDate)
    return (
      <div className="FormFields">
        <div className="FormFields__column">
          <Field>
            <FieldLabel htmlFor="defaultValue">Default Value</FieldLabel>
            <Help text="Set a default field value for this field. The value will appear by default while creating an entry for this content type." />
            <div className="FormFields__selection-wrapper">
              {field_metadata?.default_value && Object.keys(field_metadata.default_value).includes('custom') ? (
                <>
                  <Radio
                    testId="cs-ct-field-date-created-date"
                    radioDisplayType="block"
                    name={`${uniqueKey}.defaultValue`}
                    text="Created Date"
                    checked={!field_metadata?.default_value?.custom}
                    value="createdDate"
                    onChange={onDefaultValueChange}
                  />
                  <Radio
                    testId="cs-ct-field-date-custom-date"
                    radioDisplayType="block"
                    name={`${uniqueKey}.defaultValue`}
                    text="Custom Date"
                    checked={field_metadata?.default_value?.custom}
                    value="customDate"
                    onChange={onDefaultValueChange}
                  />
                </>
              ) : (
                  <>
                    <Radio
                      testId="cs-ct-field-date-created-date"
                      radioDisplayType="block"
                      name={`${uniqueKey}.defaultValue`}
                      text="Created Date"
                      checked={false}
                      value="createdDate"
                      onChange={onDefaultValueChange}
                    />
                    <Radio
                      testId="cs-ct-field-date-custom-date"
                      radioDisplayType="block"
                      name={`${uniqueKey}.defaultValue`}
                      text="Custom Date"
                      checked={false}
                      value="customDate"
                      onChange={onDefaultValueChange}
                    />
                  </>
                )}
            </div>
          </Field>
          {field_metadata?.default_value?.custom ? (
            <>
              <div>
                <TextInput
                  type="text"
                  name={`${uniqueKey}.date`}
                  value={field_metadata.default_value.date || customDateVal}
                  placeholder="Date [yyyy-mm-dd]"
                  onClick={() => {
                    InfoModal({
                      component: (props) => (
                        <>
                          <Datepicker
                            initialDate={customDateVal}
                            onChange={(date) => {
                              console.log('you change date', date)
                              field_metadata.default_value.date = date
                              onchange()
                              setCustomDate(date)
                            }}
                            viewType="date"
                            {...props}
                          />
                        </>
                      ),
                      modalProps: {
                        onClose: () => {
                          console.log('you close mee')
                        },
                        targetNodeOrId: targetRefDate.current,
                        closeOnOverlayClick: true,
                        wrapperStyle: {
                          left: 'auto !important'
                        }
                      }
                    })
                  }}
                  testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.DATE_TEXT_INPUT)}
                />

                <div ref={targetRefDate}></div>
              </div>
              {!field_metadata.hide_time && (
                <div>
                  <TextInput
                    type="text"
                    name={`${uniqueKey}.time`}
                    value={field_metadata.default_value.time || customTimeVal}
                    placeholder="Time [hh:mm:ssZ]"
                    onClick={() => {
                      InfoModal({
                        component: (props) => (
                          <>
                            <TimePicker2
                              initialDate={customTimeVal}
                              onChange={(time) => {
                                console.log('you changed time', time)
                                field_metadata.default_value.time = time
                                onchange()
                              }}
                              onDone={(time) => {
                                console.log('on date component done time', time)
                                setCustomTime(time)
                              }}
                              inputDisabled={false}
                              placeholder="Select time"
                              viewType="time"
                              {...props}
                            />
                          </>
                        ),
                        modalProps: {
                          onClose: () => {
                            console.log('you close mee')
                          },
                          targetNodeOrId: targetRefTime.current,
                          closeOnOverlayClick: true,
                          wrapperStyle: {
                            left: 'auto !important'
                          }
                        }
                      })
                    }}
                    testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.TIME_TEXT_INPUT)}

                  />
                  <div ref={targetRefTime}></div>
                </div>
              )}
            </>
          ) : null}
        </div>
        <div className="FormFields__column">
          <Field>
            <FieldLabel htmlFor="options">Options</FieldLabel>
            <div className="FormFields__selection-wrapper">
              <Checkbox
                testId="cs-ct-field-date-mandatory"
                name={`${uniqueKey}.mandatory`}
                text="Mandatory"
                checked={schema.mandatory}
                onChange={onOptionsChange}
              />
              <Checkbox
                testId="cs-ct-field-date-hide-time"
                name={`${uniqueKey}.hideTime`}
                text="Hide Time"
                checked={field_metadata.hide_time || false}
                onChange={onOptionsChange}
              />
              <div className="FormFields__with-help">
                <Checkbox
                  testId="cs-ct-field-date-set-date-range"
                  name={`${uniqueKey}.setDateRange`}
                  text="Set Date Range"
                  checked={field_metadata.date_range || false}
                  onChange={onOptionsChange}
                />
                <Help text="Set range of date (start and end) that the user will be allowed to select from." />
              </div>
              {field_metadata.date_range
                ? (console.log('schema setrange===', schema, today, newDate),
                  (
                    <>
                      <Field>
                        <FieldLabel className="ml-0" htmlFor="startDate">
                          Start Date
                        </FieldLabel>
                        <>
                          <div>
                            <TextInput
                              type="text"
                              name={`${uniqueKey}.daterangestart`}
                              value={schema.startDate || ''}
                              placeholder="Date [yyyy-mm-dd]"
                              onClick={() => {
                                InfoModal({
                                  component: (props) => (
                                    <>
                                      <DateTimepicker2
                                        initialDate={schema.startDate || today}
                                        onCancel={() => { }}
                                        onDone={(startDate) => {
                                          console.log('on Date Start Select Done', startDate)
                                          let selectedDate = startDate
                                          selectedDate.date = selectedDate && selectedDate.date.split('T')[0]
                                          setSelectedStartDate(selectedDate)
                                          if (field_metadata.hide_time) {
                                            onStartDateChangeHandler(`${selectedDate.date}`)
                                          } else {
                                            onStartDateChangeHandler(`${selectedDate.date}T${selectedDate.time}`)
                                          }
                                          //onchange();
                                        }}
                                        hideTime={field_metadata.hide_time ? true : false}
                                        {...props}
                                      />
                                    </>
                                  ),
                                  modalProps: {
                                    onClose: () => { },
                                    targetNodeOrId: targetRefDateTimeStart.current,
                                    closeOnOverlayClick: true
                                  },
                                  alignment: 'right'
                                })
                              }}
                              error={schema.error && schema.error_details.start_date ? true : false}
                              testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.START_DATE_TEXT_INPUT)}

                            />
                            <div ref={targetRefDateTimeStart}></div>
                          </div>
                        </>
                      </Field>
                      <Field>
                        <FieldLabel className="ml-0" htmlFor="endDate">
                          End Date
                        </FieldLabel>
                        <>
                          <div>
                            <TextInput
                              type="text"
                              name={`${uniqueKey}.daterangeend`}
                              value={schema.endDate || ''}
                              placeholder="Date [yyyy-mm-dd]"
                              onClick={() => {
                                InfoModal({
                                  component: (props) => (
                                    <>
                                      <DateTimepicker2
                                        initialDate={schema.endDate || today}
                                        onCancel={() => { }}
                                        onDone={(endDate) => {
                                          let selectedDate = endDate
                                          selectedDate.date = selectedDate && selectedDate.date.split('T')[0]
                                          setSelectedEndDate(selectedDate)

                                          // schema.endDate = `${endDate.date}T${endDate.time}`;
                                          // setSelectedEndDate();
                                          if (field_metadata.hide_time) {
                                            onEndDateChangeHandler(`${endDate.date}`)
                                          } else {
                                            onEndDateChangeHandler(`${endDate.date}T${endDate.time}`)
                                          }
                                          // onchange();
                                        }}
                                        hideTime={field_metadata.hide_time ? true : false}
                                        {...props}
                                      />
                                    </>
                                  ),
                                  modalProps: {
                                    onClose: () => { },
                                    targetNodeOrId: targetRefDateTimeEnd.current,
                                    closeOnOverlayClick: true
                                  },
                                  alignment: 'right'
                                })
                              }}
                              error={schema.error && schema.error_details.end_date ? true : false}
                              testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.END_DATE_TEXT_INPUT)}
                            />
                            <div ref={targetRefDateTimeEnd}></div>
                          </div>
                        </>
                      </Field>
                    </>
                  ))
                : null}
              <Checkbox
                testId="cs-ct-field-date-multiple"
                name={`${uniqueKey}.multiple`}
                text="Multiple"
                checked={schema.multiple}
                onChange={onOptionsChange}
              />
              {schema.multiple ? (
                <div>
                  <Field>
                    <FieldLabel className="ml-0" htmlFor="validation">
                      Set Maximum Limit
                    </FieldLabel>
                    <TextInput
                      type="number"
                      name={`${uniqueKey}.maxinstance`}
                      value={schema.max_instance || null}
                      onChange={onMaxInstance}
                      // placeholder="Maximum"
                      placeholder={props.t(
                        'content_type.save_content_type.body.fields.common_properties.placeholders.maximum'
                      )}
                      error={schema.error && schema.max_instance ? true : false}
                      testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.SET_MAX_LIMIT_INPUT)}
                    />
                  </Field>
                </div>
              ) : null}
              {(parentInfo.hasParent ? !parentInfo.isMulti : true) ? (
                hasNonLocalizablePlan ? (
                  <>
                    {isNonLocalizable ? (
                      <Tooltip position="top" content="Available only if there are multiple languages in your stack.">
                        <Checkbox
                          testId="cs-ct-field-date-non-localizable"
                          name={`${uniqueKey}.nonLocalization`}
                          text="Non-localizable"
                          checked={schema.non_localizable}
                          disabled={isNonLocalizable}
                          onChange={onOptionsChange}
                        />
                      </Tooltip>
                    ) : (
                        <Checkbox
                          testId="cs-ct-field-date-non-localizable"
                          name={`${uniqueKey}.nonLocalization`}
                          text="Non-localizable"
                          checked={schema.non_localizable}
                          disabled={isNonLocalizable}
                          onChange={onOptionsChange}
                        />
                      )}
                    <div className="nl-note">
                      If enabled, editing this field is restricted in localized entries. The field will use the value of
                      the master-language entry in all localized entries.
                    </div>{' '}
                  </>
                ) : null
              ) : null}
            </div>
          </Field>
          {(
            parentInfo.hasParent
              ? parentInfo.isMulti && (parentInfo.data_type == 'group' || parentInfo.data_type == 'global_field')
              : false
          ) ? (
              <>
                <div className="mark-group-title">
                  <Checkbox
                    testId="cs-ct-field-date-group-title"
                    name={`${uniqueKey}.isTitle`}
                    text="Mark as Group title"
                    checked={field_metadata.isTitle || false}
                    onChange={(event) => {
                      let eventData = event.target.checked
                      field_metadata.isTitle = eventData
                      onchange()
                      // groupAsTitle(parentInfo.id, schema, () => {
                      // 	console.log('callback triggered groupAsTitle');
                      // });
                    }}
                  />
                </div>
              </>
            ) : null}
        </div>
      </div>
    )
  }
}

export default React.memo(DateField)

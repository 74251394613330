import React from 'react'
import { Registry } from '@react-registry'
import { elementCategories } from '../../elementCategories'
import { OrderedListIcon } from '../../../utils/Icon'
//@ts-ignore
import styles from '../style.module.css'
import { useSlate } from 'slate-react'
import { toggleList } from '../utils'
import { isBlockActive } from '../../../../components/Toolbar/Button/buttonUtils'
import { cx } from '@emotion/css'

const format = 'ol'

export const OrderedList = (props) => {
  const isNodeRTLDirected = props?.attrs?.style?.direction === "rtl";

  return (
    <div {...props.attributes} {...props.attrs} className={isNodeRTLDirected
      ? cx(styles["list"], styles["list-rtl"])
      : styles["list"]}>
      <ol>{props.children}</ol>
    </div>
  )
}

export const OrderedListToggleButton = (props) => {
  const editor = useSlate()

  const handleMouseDown = (event) => {
    toggleList(event, editor, format)
  }

  return <React.Fragment>{props.children({ handleMouseDown, active: isBlockActive(editor, format) })}</React.Fragment>
}

export const shortcutOrderedListToggle = (event, editor, format, registryObj) => {
  toggleList(event, editor, format)
}

export * from './deserializer'

Registry.register(
  {
    Component: OrderedList,
    IngressComponent: OrderedListToggleButton,
    iconName: <OrderedListIcon />,
    slashIconName: <OrderedListIcon />,
    shortcut: { key: 'mod+shift+7', callback: shortcutOrderedListToggle },
    title: 'Ordered list',
    slashTitle: 'Ordered list',
    subtitle: 'Ordered list of Items',
    category: elementCategories.LIST_ITEMS,
    isContentStackElement: true,
    inBasicToolbar: false,
    keywords: ['ordered list'],
    dndOptions: {
      DisableDND: true
    }
  },
  { id: format, registry: 'v2.element' }
)

import React, { useMemo, useCallback, useState, useRef, useEffect, useContext } from 'react'
import { createEditor, Transforms, Editor, Node, Text } from 'slate'
import { Slate, Editable, withReact, ReactEditor } from 'slate-react'
import { withHistory } from 'slate-history'
import { cx } from '@emotion/css'
import { cloneDeep, isEqual } from 'lodash'
import { v4 } from 'uuid'
import siteViewStyles from '../../styles.module.css'
import { savedSelection } from '../../utils/savedSelection'
//@ts-ignore
import styles from './style.module.css'
import { Icon } from '../../../../../index'
import { Element, Leaf } from '../../elements'
import { MainToolbar, HoveringToolbar } from '../Toolbar'
import { applyPlugins, useRtePlugins, dndResetProps, useCustomToolbar } from '../../utils'
import { EditorProvider, ScrollRefProvider, ReduxProvider } from '../ContextProvider'
import { handleShortcut } from '../../utils/shortcuts/handleShortcut'
import { handleRteOverlayClick, useEditorResize, useRteFullScreen } from '../../../../../utils/hooks'
import dndStyles from '../Dnd/style.module.css'

import { useExtensionFullScreen } from '../../utils/useExtensionFullScreen'
import { SlashElement, SlashComponent } from '../SlashCommand'
import { useDecorate } from '../../utils/hooks/useDecorate'
import { EditorElementProps } from './types'
import DiscussionPopup from '../Discussion/DiscussionPopup'
import { useDiscussion } from '../Discussion/utils/useDiscussion'
import { failureNotification, fetchActiveDiscussions } from '../Discussion/utils/request'
import { HoveringToolbarProvider } from '../ContextProvider/HoveringToolbarProvider'
import { IHoveringToolbarContext } from '../../utils/types'
import { getDiscussionMarksOnTextNode, isDiscussionThreadActive } from '../../elements/Leaf/rangeComment/helpers'
import isHotkey from 'is-hotkey'
import { useDidUpdate } from '../../../../../utils/hooks/componentLifecycle'
import useEntrySave from '../../utils/hooks/useEntrySave'
import useDiscussionListeners from '../Discussion/utils/hooks/useDiscussionListeners'

const EditorElement = ({
  customToolbarComponents,
  value,
  onChange,
  toolbar = true,
  hoveringToolbar = true,
  contentStack,
  toolbarMode,
  allowExtraTags,
  contentTypeNameMap,
  disabled = false,
  fieldName = 'Supercharged RTE',
  customPasteOptions,
  copyPasteConfig,
  csOnlyBreakline,
  csBreakLineOnEnter,
  comments,
  fieldMetadata,
  entryMetadata,
  stackMetadata,
  ...props
}: EditorElementProps) => {
  const scrollRef = useRef(null)
  const editorRef = useRef(null)
  const singleLineRef = useRef(null)
  const editorResizeRef = useRef(null)
  const [slateValue, setSlateValue] = useState(value)
  const [plugins, setPlugins] = useState([])
  const [triggerRerenderingAfterDnd, setTriggerRerenderingAfterDnd] = useState(Date.now())

  const editor = useMemo(() => applyPlugins(withHistory(withReact(createEditor() as ReactEditor))), [])
  const customToolbarComponentsCopy = useCustomToolbar(customToolbarComponents, editor)

  const { activeDiscussion, setActiveDiscussion, discussions, setDiscussions, removeDiscussion } = useDiscussion()

  const isFullScreen = useRteFullScreen(editorRef)

  const [isFetchingDiscussions, setIsFetchingDiscussion] = useState(true)

  useEntrySave(editor, props?.requestProps?.isPlugin)
  useDiscussionListeners({ path: fieldMetadata?._metadata?.path, setActiveDiscussion, discussions, isFetchingDiscussions, isPlugin : props?.requestProps?.isPlugin })

  useDidUpdate(() => {
    if (!isEqual(slateValue, value)) {
      Transforms.removeNodes(editor, { at: [0] })
      Transforms.insertNodes(editor, value, { at: [0] })
      if (editor.isEntrySaving) {
        delete editor['mockOperations']
        editor.isEntrySaving = false
      }
    }
  }, [props.updateValueFromAppSdk])

  useEffect(() => {
    if (!isEqual(slateValue, value)) {
      editor.selection = null
      setSlateValue(value)
      editor.children = value
    }
  }, [value])

  // Removing resolved stale discussion marks, for discussion

  useEffect(() => {
    if (isFetchingDiscussions) return

    const staleDiscussionThreads = new Set<string>()
    const textNodes = Array.from(Editor.nodes(editor, { at: [0], mode: 'lowest', match: Text.isText }))
    textNodes.forEach(([node]) => {
      const threads = getDiscussionMarksOnTextNode(node)
      threads.forEach((thread) => {
        if (!isDiscussionThreadActive(thread, discussions)) {
          staleDiscussionThreads.add(thread)
        }
      })
    })
    try {
      Transforms.select(editor, [0])
      staleDiscussionThreads.forEach((thread) => {
        Editor.removeMark(editor, thread)
      })
      Transforms.deselect(editor)
      editor.history?.undos?.pop()
    } catch (e) {
      console.error(e)
    }
  }, [isFetchingDiscussions])

  useEffect(() => {
    editor.requestProps = props.requestProps
  }, [props.requestProps])

  const handleKeyDown = (event) => {
    handleShortcut(event, editor, {
      customToolbarComponents,
      contentStack,
      toolbarMode
    })
    handleKeyDownForSlash(event)
  }

  const elementRenderer = useCallback(
    (props) => (
      <Element {...props} setTriggerRerenderingAfterDnd={setTriggerRerenderingAfterDnd} singleLineRef={singleLineRef} discussion={{ comments, setActiveDiscussion, discussions }} />
    ),
    [plugins.length, discussions, triggerRerenderingAfterDnd, comments?.enabled]
  )
  const leafRenderer = useCallback(
    (props) => (
      <Leaf
        {...props}
        discussionUtils={{ enabled: comments?.enabled, setActiveDiscussion, discussions, activeDiscussion }}
      />
    ),
    [plugins.length, discussions, activeDiscussion, comments?.enabled, isFetchingDiscussions]
  )

  let newProps = {
    editorRef: editorRef
  }
  const discussionProps = {
    entryMetadata,
    editorMetadata: {
      assetUrl: props?.requestProps?.assetUrl,
      isPlugin: props?.requestProps?.isPlugin,
      apiKey: props?.requestProps?.apiKey,
      branch: props?.requestProps?.branch,
      isFullScreen
    },
    stackMetadata,
    fieldMetadata,
    docUID: slateValue[0].uid,
    setDiscussions,
    discussions
  }
  const handleFocus = (e) => {
    props.onFocus(e)
  }
  const handleEmptyClick = (event) => {
    if (event.clientX > event.target.clientWidth || event.clientY > event.target.clientHeight) return
    const endPoint = Editor.end(editor, [])
    const { path } = endPoint
    const node = Node.get(editor, path.slice(0, 2))
    ReactEditor.focus(editor)
    if (Editor.isVoid(editor, node)) {
      Transforms.select(editor, path)
    } else {
      Transforms.select(editor, endPoint)
    }
  }

  const handleFocusForAccessibility = (e) => {
    const toolbarParent = e?.target?.getElementsByClassName('toolbar-parent')
    if (toolbarParent?.length && toolbarParent[0]?.childNodes?.length) {
      Array.from(toolbarParent[0]?.childNodes).forEach((child: any, index: any) => {
        if (index === 0) return
        let i = 0
        while (i < child.childNodes?.length) {
          if (!child.childNodes[i].className.includes('divider')) {
            child.childNodes[i]?.setAttribute("tabIndex", "-1")
          }
          i++
        }
      })
    }
    const editableElement = e?.target?.querySelector('#scrte-editable')
    if (editableElement) editableElement.setAttribute('tabIndex', "-1")
  }

  const handleKeyDownForAccessibility = (e) => {
    if (e.key === "Enter") {
      e?.target?.querySelector('span[data-icon="bold"]')?.focus()
      const toolbarParent = e?.target?.getElementsByClassName('toolbar-parent')
      if (toolbarParent?.length && toolbarParent[0]?.childNodes?.length) {
        Array.from(toolbarParent[0]?.childNodes).forEach((child: any, index: any) => {
          if (index === 0) return
          let i = 0
          while (i < child.childNodes?.length) {
            if (!child.childNodes[i].className.includes('divider')) {
              child.childNodes[i]?.setAttribute("tabIndex", "0")
            }
            i++
          }
        })
      }
      const editableElement = e?.target?.querySelector('#scrte-editable')
      if (editableElement) editableElement.setAttribute('tabIndex', "0")
    }
    if (isHotkey('shift+meta+enter', e)) {
      e.preventDefault()
      handleEmptyClick(e)
    }
    if (e.key === 'Escape') {
      e.target?.closest('#scrte-editor')?.focus()
    }
  }

  useEffect(() => {
    if (!editor.savedSelection) {
      savedSelection(editor)
    }
  }, [])

  const handleBlur = (e) => {
    props.onBlur(e)
    savedSelection(editor)
  }

  useEffect(() => {
    editor.uniqueId = v4().split('-').join('')
    editor.allowExtraTags = allowExtraTags
    editor.contentTypeNameMap = contentTypeNameMap
    editor.customPasteOptions = customPasteOptions || {}
    editor.copyPasteConfig = copyPasteConfig
    editor.csOnlyBreakline = csOnlyBreakline
    editor.csBreakLineOnEnter = csBreakLineOnEnter;
  }, [copyPasteConfig])
  useEffect(() => {
    const docUID = slateValue?.[0]?.uid
    // If comments is enabled then fetch all discussion for this entry
    if (comments.enabled) {
      setIsFetchingDiscussion(true)
      fetchActiveDiscussions(
        props.requestProps,
        docUID,
        entryMetadata.contentTypeUid,
        entryMetadata.entryUid,
        entryMetadata.locale
      )
        .then((res) => {
          const discussionMap = {}
          Array.from(res.discussions).forEach((discussion) => {
            if (discussion.field.block_uid) {
              discussionMap[discussion.field.block_uid] = discussion
            } else {
              discussionMap[`range-${discussion.uid}`] = discussion
            }
          })
          setDiscussions(discussionMap)
          if (!res.isCachedValue)
            setIsFetchingDiscussion(false)
        })
        .catch((error) => {
          setIsFetchingDiscussion(false)
          failureNotification(
            error?.data?.error_message || 'Error while fetching active discussions.',
            error?.data?.errors
          )
        })
    }
  }, [plugins.length, props.discussionRandomUid])

  useExtensionFullScreen(editor?.requestProps?.isPlugin, editorRef)
  useEditorResize(editorResizeRef, false)
  useRtePlugins({ plugins: props.plugins, setPlugins, editor, editorRef })

  const decorate = useDecorate(editor, [plugins.length])
  const {
    onChangeSlash,
    onKeyDownSlash,
    target,
    search,
    index,
    choice,
    setChoice,
    setTarget,
    searchElements,
    handleSet
  } = SlashElement(editor)
  const handleKeyDownForSlash = useCallback(
    (event) => {
      onKeyDownSlash({ event, editor })
    },
    [target, search, index]
  )

  const hoveringContext: IHoveringToolbarContext = useMemo(() => {
    return {
      discussions: discussions,
      setActiveDiscussion: setActiveDiscussion,
      commentsEnabled: comments?.enabled
    }
  }, [activeDiscussion, discussions, comments?.enabled])

  return (
    <ReduxProvider>
      <EditorProvider.Provider value={newProps}>
        <ScrollRefProvider.Provider value={{ scrollRef }}>
          <Slate
            editor={editor}
            value={slateValue}
            onChange={(newValue) => {
              setSlateValue(newValue)
              onChange(newValue)
              onChangeSlash(scrollRef)
            }}>
            <div ref={singleLineRef} contentEditable={false} className={cx(dndStyles['scrte-dragline'])} />
            <div
              ref={editorRef}
              tabIndex={0}
              onFocus={handleFocusForAccessibility}
              onKeyDown={handleKeyDownForAccessibility}
              id="scrte-editor"
              className={cx(styles['super-charged-rte'], 'super-charged-rte active', disabled && styles['disabled'])}>
              {toolbar && (
                <MainToolbar
                  customToolbarComponents={customToolbarComponentsCopy}
                  className={cx('scrte-toolbar', styles['scrte-main-toolbar'])}
                  contentStack={contentStack}
                  toolbarMode={toolbarMode}
                  fieldName={fieldName}
                  editorRef={editorRef}
                  pluginslen={plugins.length}
                />
              )}
              {hoveringToolbar && (
                <HoveringToolbarProvider.Provider value={hoveringContext}>
                  <HoveringToolbar
                    customToolbarComponents={customToolbarComponentsCopy}
                    scrollRef={scrollRef}
                    contentStack={contentStack}
                    toolbarMode={toolbarMode}
                    pluginslen={plugins.length}
                  />
                </HoveringToolbarProvider.Provider>
              )}
              <div id="scroller" className={cx('scrte-scroller', `scrte-editor-${editor.uniqueId}`)} ref={scrollRef}>
                <>
                  <Editable
                    {...dndResetProps}
                    onMouseDown={handleEmptyClick}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    renderElement={elementRenderer}
                    renderLeaf={leafRenderer}
                    onKeyDown={handleKeyDown}
                    autoCorrect="true"
                    spellCheck="false"
                    id="scrte-editable"
                    readOnly={disabled}
                    tabIndex={-1}
                    //@ts-ignore
                    className={cx(
                      siteViewStyles['editable'],
                      props.className,
                      styles['scrte-editable'],
                      'scrte-editable'
                    )}
                    required={props.required}
                    decorate={decorate}
                  />

                  {!editor?.requestProps?.isPlugin && <div className="editor-resize" ref={editorResizeRef}></div>}
                  <Icon icon="Resize" className={styles['resize-icon']} />
                </>
              </div>
            </div>
            <div
              className="rte-fullscreen-overlay"
              onClick={() => handleRteOverlayClick(editorRef, editor.requestProps.isPlugin)}></div>
            <SlashComponent
              index={index}
              target={target}
              searchElements={searchElements}
              editor={editor}
              choice={choice}
              setChoice={setChoice}
              handleSet={handleSet}
              setTarget={setTarget}
              scrollRef={scrollRef}
            />
            <DiscussionPopup
              activeDiscussion={activeDiscussion}
              setActiveDiscussion={setActiveDiscussion}
              docUID={slateValue[0].uid}
              scrollRef={scrollRef}
              removeDiscussion={removeDiscussion}
              {...discussionProps}
            />
          </Slate>
        </ScrollRefProvider.Provider>
      </EditorProvider.Provider>
    </ReduxProvider>
  )
}

EditorElement.defaultProps = {
  onChange: () => {
    // It is intentionally left blank
  },
  value: [
    {
      type: 'doc',
      children: [
        {
          type: 'h1',
          attrs: {},
          children: [{ text: 'Hello, world!' }]
        }
      ]
    }
  ],
  requestProps: {},
  onFocus: () => {
    // It is intentionally left blank
  },
  onBlur: () => {
    // It is intentionally left blank
  },
  onMouseEnter: () => {
    // It is intentionally left blank
  },
  onMouseLeave: () => {
    // It is intentionally left blank
  },
  placeholder: `Type '/' for options`,
  uid: null,
  required: false,
  allowExtraTags: {},
  contentTypeNameMap: {},
  plugins: [],
  customPasteOptions: {},
  comments: {
    enabled: false
  }
}

export default EditorElement

import React, { ReactNode, Component, createRef } from 'react'
import cn from 'classnames'

import Checkbox from '../Checkbox/Checkbox'
import Icon from '../Icon2/Icon'
import Radio from '../Radio/Radio'
import ProgressBar from '../ProgressBar/ProgressBar'
import ValidationMessage from '../ValidationMessage/ValidationMessage'
import Tooltip from '../Tooltip/Tooltip'

import { formatBytes, getAssetIcon, classCreator } from '../../utils'
import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc'

import './AssetCardVertical.css'
import { AssetWrapper } from './AssetUtilComp'
import { Truncate } from '../Truncate/Truncate'

const defaultProps = {
  assetType: 'imageBroken',
  // imageHeight: 100,
  // imageWidth: 200,
  canHover: true,
  checked: false,
  isRadio: false,
  isCardDisabled: false,
  testId: 'cs-asset-card-vertical',
  version: 'v1'
}

export interface AssetCardVerticalState {
  hoverState: boolean
}

export type Actions = {
  actionTitle: string
  actionIcon: ReactNode
  actionOnClick: Function
}

export type AssetCardVerticalProps = {
  // className?: string
  assetType?:
  | 'image'
  | 'folder'
  | 'code'
  | 'pdf'
  | 'excel'
  | 'presentation'
  | 'document'
  | 'json'
  | 'text/plain'
  | 'zip'
  | 'video'
  | 'audio'
  | 'imageBroken'
  | 'image/tiff'
  assetUrl?: string
  title?: string
  size?: number
  height?: any
  width?: any
  onCardClick?: Function
  onEditClick?: Function
  onCopyClick?: Function
  onDeleteClick?: Function
  onFullScreenClick?: Function
  onChecked?: Function
  fullScreenComponent?: React.ReactNode
  cardData?: any
  // imageHeight?: any
  // imageWidth?: any
  checked?: boolean
  isRadio?: boolean
  progressBar?: number
  canHover?: boolean
  onReloadClick?: Function
  actions?: Actions[]
  isCardDisabled?: boolean
  hoverText?: string
  testId?: string,
  version?: 'v1' | 'v2'
} & typeof defaultProps

class AssetCardVertical extends Component<AssetCardVerticalProps, AssetCardVerticalState> {
  constructor(props: any) {
    super( props )
    this.state = {
      hoverState: false
    }
  }
  
  static defaultProps = defaultProps

  assetCardInput: any = React.createRef();


  handleOnClick = ( ) => {
    if ( this?.props?.assetType !== 'folder' && !this.props?.isRadio && this.props?.onChecked)
    {
      this.assetCardInput?.click()
    }
    if ( this.props.onCardClick )
    {
      this.props.onCardClick( this.props.cardData )
    }
  }

  handleMouseEnter = () => {
    if (this.props.progressBar === -1) {
      this.setState({ hoverState: true })
    }
  }

  handleMouseLeave = () => {
    if (this.props.progressBar === -1) {
      this.setState({ hoverState: false })
    }
  }

  handleOnCheck = (event: object) => {
    this.props.onChecked(event, this.props.cardData)
  }

  handleOnEditClick = () => {
    this.props.onEditClick(this.props.cardData)
  }

  handleOnDeleteClick = () => {
    this.props.onDeleteClick(this.props.cardData)
  }
  handleOnCopyClick = () => {
    this.props.onCopyClick(this.props.cardData)
  }
  handleOnFullScreenClick = () => {
    this.props.onFullScreenClick(this.props.cardData)
  }

  handleOnReloadClick = (event: object) => {
    this.props.onReloadClick(event, this.props.cardData)
  }

  updateActions = (actions) => {
    let updatedActions
    let length = 0

    if (this.props.onEditClick) length++
    if (this.props.onDeleteClick) length++
    if (this.props.onCopyClick) length++

    updatedActions = actions ? actions.slice(0, 4 - length) : null

    return updatedActions
  }

  render() {
    let {
      // className,
      assetType,
      assetUrl,
      title,
      size,
      height,
      width,
      onEditClick,
      onCopyClick,
      onDeleteClick,
      onFullScreenClick,
      onChecked,
      // imageHeight,
      // imageWidth,
      cardData,
      checked,
      isRadio,
      fullScreenComponent,
      progressBar,
      canHover,
      onReloadClick,
      actions,
      isCardDisabled,
      hoverText,
      testId,
      version
    } = this.props

    const dimension = height && width ? true : false

    if (assetType === 'image' && !assetUrl) {
      assetType = 'imageBroken'
    }

    //url = `${url}?width=${imageWidth}&height=${imageHeight}&resize-filter=nearest`
    // For quick fix for demo we are removing height and making width as 200 static
    assetUrl = `${assetUrl}`
    const isV2 = version === 'v2';
    const assetTypeClassnames = cn('AssetCard', `AssetCard__${assetType}`, {
      'wo-dimensions': !dimension,
      'checkbox-checked': checked,
      'Radio-checked': isRadio && checked,
      'onhover-disabled': !canHover
    })
    const AssetCardV2 = cn('AssetCard-v2',
      { 'checkbox-checked': checked })
    const AssetCardCN = `AssetCard${isV2 ? '-v2' : ''}`

    const showProgress = progressBar !== undefined && progressBar !== null && progressBar === -1
    const showAssetFormat = progressBar !== -1 && size;
    const showAssetDimension = assetType === 'image' &&
      height &&
      width &&
      progressBar !== -1 &&
      (progressBar === undefined ||
        progressBar === null ||
        (progressBar !== undefined && progressBar !== null && progressBar === 100))

    const hasFullScreenButton = (onFullScreenClick && !fullScreenComponent && progressBar !== -1) || (fullScreenComponent && !onFullScreenClick && progressBar !== -1)

    return (
      <div data-test-id={testId} data-testid={testId} title={hoverText}>
        <div className={isCardDisabled ? `${AssetCardCN}__disabled` : undefined}>
          <div
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            className={isV2 ? AssetCardV2 : assetTypeClassnames}>
            {onChecked && progressBar !== -1 ? (
              !isRadio ? (
                <Checkbox checked={ checked } onClick={ this.handleOnCheck } testId={ `${ testId }-card-radio` } inputRef={ input => this.assetCardInput = input } />
              ) : (
                <Radio
                  aria-label={'cs-asset-card-vertical-radio'}
                  checked={checked}
                  className={`${checked ? 'Radiobox-checked' : ''}`}
                  onClick={this.handleOnCheck}
                  testId={`${testId}-card-checkbox`}
                />
              )
            ) : null}
            {assetType === 'image' ? (
              <div
                // className={`AssetCard__asset ${assetType === 'image'
                //   ? 'AssetCard__asset--image'
                //   : 'AssetCard__icon flex-v-center flex-h-center'
                //   } ${classCreator(height, width, assetType)}`}
                className={`${AssetCardCN}__asset ${AssetCardCN}__asset--image ${classCreator(height, width, assetType)}  ${!this.props.onCardClick ? 'not--clickable' : ''
                  }` }
                onClick={this.handleOnClick}
                data-test-id={testId + '--image'}
                data-testid={testId + '--image'}
                style={{ backgroundImage: `url('${assetUrl}')` }}
              // style={assetType === 'image' ? { backgroundImage: `url('${assetUrl}')` } : {}}
              ></div>
            ) : (
              <div
                // className={`AssetCard__asset ${assetType === 'image'
                //   ? 'AssetCard__asset--image'
                //   : 'AssetCard__icon flex-v-center flex-h-center'
                //   }`}
                className={`${AssetCardCN}__asset ${AssetCardCN}__icon flex-v-center flex-h-center ${!this.props.onCardClick ? 'not--clickable' : ''
                  }`}
                data-test-id={`${testId}-asset-card-icon`}
                onClick={this.handleOnClick}>
                <Icon icon={getAssetIcon(assetType, version)} version={version} />
              </div>
            )}
            <AssetWrapper version={version} className={`${AssetCardCN}__bottom--wrapper`} >
              <AssetWrapper version={version} className={`${AssetCardCN}__action--wrapper`} >
                {onEditClick || onDeleteClick || onCopyClick || actions ? (
                  <div className={`${AssetCardCN}__action flex-v-center ${hasFullScreenButton ? 'hasFullScreenButton' : ''}`}>
                    {onEditClick && progressBar !== -1 ? (
                      <div
                        onClick={this.handleOnEditClick}
                        className={`${AssetCardCN}__action__edit`}
                        data-test-id={`${testId}-action-edit`}>
                        <Tooltip content="Edit" position="top" showArrow={false}>
                          {
                            isV2 ? <Icon version='v2' icon='Edit' size='medium' />
                              : <Icon icon="Edit" width="12px" height="12px" viewBox="0 0 16 16" />
                          }
                        </Tooltip>
                      </div>
                    ) : null}
                    {onCopyClick && progressBar !== -1 ? (
                      <div
                        onClick={this.handleOnCopyClick}
                        className={`${AssetCardCN}__action__copy`}
                        data-test-id={`${testId}-action-copy`}>
                        <Tooltip content="Copy" position="top" showArrow={false}>
                          {
                            isV2 ? <Icon version='v2' icon='Copy' size='medium' />
                              : <Icon icon="Copy" width="12px" height="12px" viewBox="0 0 16 16" />
                          }
                        </Tooltip>
                      </div>
                    ) : null}
                    {onDeleteClick ? (
                      <div
                        onClick={this.handleOnDeleteClick}
                        className={`${AssetCardCN}__action__delete`}
                        data-test-id={`${testId}-action-delete`}>
                        <Tooltip content="Delete" position="top" showArrow={false}>
                          {isV2 ? <Icon version='v2' icon='Delete' size='medium' />
                            : <Icon icon="DeleteSmall" width="12px" height="12px" viewBox="0 0 14 14" />
                          }
                        </Tooltip>
                      </div>
                    ) : null}
                    {this.updateActions(actions)?.map((action, index) => (
                      <div
                        onClick={() => action.actionOnClick && action.actionOnClick(cardData)}
                        className={`${AssetCardCN}__action__custom`}
                        data-test-id={`${testId}-action-custom`}
                        key={index}>
                        <Tooltip content={action.actionTitle} position="top" showArrow={false}>
                          <div>{action.actionIcon ? action.actionIcon : null} </div>
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                ) : null}
                {onFullScreenClick && !fullScreenComponent && progressBar !== -1 ? (
                  <div
                    onClick={this.handleOnFullScreenClick}
                    className={`${AssetCardCN}__fullScreen`}
                    data-test-id={`${testId}-fullscreen`}>
                    <Tooltip content="Expand" position="top" showArrow={false}>
                      <div className="AssetFullScreen__icon-wrapper">
                        {isV2 ? <Icon version='v2' icon='Expand' size='medium' />
                          : <Icon icon="AssetFullScreen" width="12px" height="12px" />
                        }
                      </div>
                    </Tooltip>
                  </div>
                ) : null}
                {fullScreenComponent && !onFullScreenClick && progressBar !== -1 ? (
                  <div className={`${AssetCardCN}__fullScreen flex-v-center flex-h-center`}>{fullScreenComponent}</div>
                ) : null}
              </AssetWrapper>
              <div className={`${AssetCardCN}__shortinfo ${this.state.hoverState ? `${AssetCardCN}__failed-status` : ''}`}>
                <div className={`${AssetCardCN}__shortinfo__name`}>
                  <Truncate isResponsive truncateFrom='end' >{title}</Truncate>
                </div>
                {showAssetFormat || showAssetDimension || showProgress ? <div className={`${AssetCardCN}__shortinfo__smalldetails`}>
                  {showAssetFormat ? (
                    <div className={`${AssetCardCN}__shortinfo__smalldetails__size`}>File Size: {formatBytes(size)} </div>
                  ) : null}
                  {showAssetDimension ?
                    <div className={`${AssetCardCN}__shortinfo__smalldetails__dimensions`}>
                      {`Dimensions: ${height} x ${width}`}
                    </div>
                    : null}
                  {progressBar !== undefined && progressBar !== null && progressBar === -1 ? (
                    <div className="flex-v-center">
                      <ValidationMessage>Failed</ValidationMessage>
                      {onReloadClick ? (
                        <div onClick={this.handleOnReloadClick} data-test-id="cs-reload-click">
                          {isV2 ?
                            <Icon version='v2' size={'tiny'} icon={'Reload'} className="AssetCard__reload" />
                            : <Icon icon={'Reload'} className="AssetCard__reload" />
                          }
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div> : null}
                {progressBar !== undefined && progressBar !== null && progressBar !== 100 ? (
                  <span className={`${this.state.hoverState && progressBar === -1 ? 'progress-bar-failed' : ''}`}>
                    <ProgressBar
                      percentage={progressBar === -1 ? 100 : progressBar}
                      color={progressBar === -1 ? 'red' : ''}
                    />
                  </span>
                ) : null}
              </div>
            </AssetWrapper>
          </div>
        </div>
      </div >
    )
  }
}

export default withDeprecatedProp(AssetCardVertical, {
  type: 'assetType',
  url: 'assetUrl',
  onClick: 'onCardClick',
  data: 'cardData',
  isSingle: 'isRadio',
  onHover: 'canHover',
  onReload: 'onReloadClick',
  cardDisabled: 'isCardDisabled',
  actions: [
    {
      title: 'actionTitle',
      icon: 'actionIcon',
      method: 'actionOnClick'
    }
  ]
})

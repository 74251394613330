import React, { useState, useRef, useEffect } from 'react'

import Field from '../../Field/Field'
import TextInput from '../../TextInput/TextInput'
import Textarea from '../../Textarea/Textarea'
import Tooltip from '../../Tooltip/Tooltip'
import FieldLabel from '../../FieldLabel/FieldLabel'
import Checkbox from '../../Checkbox/Checkbox'
import Help from '../../Help/Help'
import { fieldUidRegex } from '../helper'

export const Taxonomy = (props: any) => {
  const { onChange, options, parentInfo, uniqueKey, schema, type, isNonLocalizable, hasNonLocalizablePlan } = props
  const [initialSchema, setSchema] = useState(schema)
  const { display_name, uid, field_metadata } = schema
  const [error, setError] = useState(fieldUidRegex(schema.uid, schema))

  const displayNameRef: any = useRef(null)

  useEffect(() => {
    displayNameRef.current.focus()
  }, [displayNameRef])

  const onchange = () => {
    onChange && onChange(schema)
    setSchema({ ...schema })
  }
  const onDisplayNameChange = (event: any) => {
    schema.display_name = event.target.value

    // if (schema.hasOwnProperty('display_name') && schema.newfield) {
    //   schema.uid = schema.display_name
    //     .trim()
    //     .replace(/[^A-Z0-9]+/gi, '_')
    //     .toLowerCase()
    //   if (schema.display_name) {
    //     schema.error_details = {
    //       ...schema.error_details,
    //       uid: ''
    //     }
    //   }
    // }

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.display_name.length) {
      schema.error_details = {
        ...schema.error_details,
        display_name: 'Display Name cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        display_name: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onUidChange = (event: any) => {
    setError(fieldUidRegex(event.target?.value?.trim(), schema))

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.uid.length) {
      schema.error_details = {
        ...schema.error_details,
        uid: 'uid cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        uid: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onHelpTextChange = (event: any) => {
    field_metadata.description = event.target.value
    onchange()
  }
  const onOptionChange = (event: any) => {
    if (event.target.name === `${uniqueKey}.nonLocalization`) {
      schema.non_localizable = event.target.checked
    }
    onchange()
  }

  const onInstructionChange = (event: any) => {
    field_metadata.instruction = event.target.value
    onchange()
  }

  if (type === 'basic') {
    return (
      <div className="FormFields">
        <div className="FormFields__column">
          <Field>
            <FieldLabel required htmlFor="displayName">
              Display Name
            </FieldLabel>
            <TextInput
              required
              name={`${uniqueKey}.displayName`}
              value={display_name}
              onChange={onDisplayNameChange}
              inputRef={displayNameRef}
              error={schema.error && schema.error_details.display_name ? true : false}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
            />
          </Field>
          <Field>
            <FieldLabel htmlFor="helpText">Help Text</FieldLabel>
            <Help text="Provide additional info about the field. The help text will appear when the user hovers on the ‘?’ sign beside the field name." />
            <Textarea
              name={`${uniqueKey}.helpText`}
              value={field_metadata.description || ''}
              onChange={onHelpTextChange}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              // multiline={true}
            />
          </Field>
        </div>
        <div className="FormFields__column">
          <Field>
            <FieldLabel required htmlFor="uid">
              Unique ID
            </FieldLabel>
            <Help text="A unique ID for this field. This should not be same as the ID of any other field in this content type." />
            <TextInput
              required
              name={`${uniqueKey}.uid`}
              value={uid}
              onChange={onUidChange}
              error={(schema.error && schema.error_details.uid) || error ? true : false}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              disabled={true}
            />
          </Field>
          <Field>
            <FieldLabel htmlFor="instruction">Instruction Value</FieldLabel>
            <Help text="Helps you add instructions for this field. The instructional text will appear below the field." />
            <Textarea
              name={`${uniqueKey}.instruction`}
              value={field_metadata.instruction || ''}
              onChange={onInstructionChange}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              // multiline={true}
            />
          </Field>
        </div>
      </div>
    )
  } 
  // else if (type === 'advanced') {
  //   return (
  //     <div className="FormFields">
  //       {(parentInfo.hasParent ? !parentInfo.isMulti : true) ? (
  //         hasNonLocalizablePlan ? (
  //           <Field>
  //             <FieldLabel htmlFor="options">Options</FieldLabel>
  //             <div className="FormFields__selection-wrapper">
  //               {isNonLocalizable ? (
  //                 <Tooltip position="top" content="Available only if there are multiple languages in your stack.">
  //                   <Checkbox
  //                     name={`${uniqueKey}.nonLocalization`}
  //                     text="Non-localizable"
  //                     checked={schema.non_localizable}
  //                     disabled={isNonLocalizable}
  //                     onChange={onOptionChange}
  //                   />
  //                 </Tooltip>
  //               ) : (
  //                 <Checkbox
  //                   name={`${uniqueKey}.nonLocalization`}
  //                   text="Non-localizable"
  //                   checked={schema.non_localizable}
  //                   disabled={isNonLocalizable}
  //                   onChange={onOptionChange}
  //                 />
  //               )}
  //               <div className="nl-note">
  //                 If enabled, editing this field is restricted in localized entries. The field will use the value of the
  //                 master-language entry in all localized entries.
  //               </div>
  //             </div>
  //           </Field>
  //         ) : null
  //       ) : null}
  //     </div>
  //   )
  // }
}

export default React.memo(Taxonomy)

import { options, stringOperators } from './constants'
import uniq from 'lodash/uniq'

import {
  suggestForEntries,
  suggestedSyntaxes as suggestedEntriesSyntaxes,
  inEntries,
  textLabel,
  dateLabel,
  suggestedSyntaxByUid as suggestedEntriesSyntaxByUid
} from './entries'

import { queryBuilderEntries, queryParserEntries, fetchDataForQuery, setCounterForAdvanceQuery, getQueryType } from './helpers'

import {
  suggestForAssets,
  suggestedSyntaxes as suggestedAssetSyntaxes,
  queryBuilderAssets,
  queryParserAssets,
  fileSizeLabel,
  inAssets,
  advanceQueryAssetParser,
  suggestedSyntaxByUid as suggestedAssetSyntaxByUid
} from './assets'

import { buildQueryObjectForAdvCase, numberLabel, advanceQueryParserForEntries, updateSelectedContentTypeStatus } from './advanceQueryEntries'
import Notification from '../../Notification/Notification'

export const getSuggestedSyntaxes = (option = options[0].value) => {
  return option === options[1].value ? suggestedAssetSyntaxes : suggestedEntriesSyntaxes
}

export const getSuggestedSyntaxByUid = (option = options[0].value) => {
  return option === options[1].value ? suggestedAssetSyntaxByUid : suggestedEntriesSyntaxByUid
}


export const getRecentUsedFieldsOrder = ({ queryArray, suggestedSyntaxes, selectedModule ,storedRecentUsedFields = [], action = '' }) => {
  try {
    let updatedOrder = [];
    let updatedSuggestedSyntax = []

    let copySuggestedSyntax = [...suggestedSyntaxes]

    if (action === 'init') {
      updatedOrder = [...storedRecentUsedFields]
    } else {
      updatedOrder = queryArray.map(query => {
        let { key = [] } = query
        if (Array.isArray(key) && key.length && key[0].value) {
          return key[0].value
        }
      })
    }

    if (updatedOrder.length) {
      const currentSuggestedSyntaxByUid = getSuggestedSyntaxByUid(selectedModule)
      //multi query Array can have same query like multi query for tag, taking uniq only
      updatedOrder = uniq(updatedOrder)
      updatedOrder.forEach(syntaxUid => {
        if (currentSuggestedSyntaxByUid[syntaxUid]) {
          //populate obj in recent used order
          updatedSuggestedSyntax.push(currentSuggestedSyntaxByUid[syntaxUid])
          //remove that syntax from copySuggestedSyntax, merging both create recentUsedSyntax followed by less used
          copySuggestedSyntax = copySuggestedSyntax.filter(syntex => syntex.uid !== syntaxUid)
        }
      })

      return updatedSuggestedSyntax = [...updatedSuggestedSyntax, ...copySuggestedSyntax]
    }
    return suggestedSyntaxes
  } catch (error) {
    console.log("getRecentUsedFieldsOrder -> error", error)
  }
}

export const autoSuggest = async (data) => {
  //if selectedDrop value is ENtries then handle suggest
  if (data.selectedModule === options[0].value) {
    const object: any = await suggestForEntries(data)
    return object
  }

  // if selected value is Assets
  if (data.selectedModule === options[1].value) {
    const object: any = await suggestForAssets(data)
    return object
  }

  return {
    withOperator: false,
    queryData: [],
    isQueryCompleted: false,
    count: 0
  }
}

export const updateCurrentSearch = (data) => {
  // console.log('updateCurrentSearch -> data', data)
  let {
    queryType,
    nextQuery,
    selectedOpt,
    type,
    currentSearch,
    withOperator,
    action,
    isQueryCompleted,
    operators,
    defaultOperator
  } = data

  let { key, operator, value } = currentSearch

  if (action === 'edit') {
    currentSearch.value = [selectedOpt]
    return currentSearch
  }
  currentSearch.isQueryCompleted = isQueryCompleted
  if (queryType === 'inEntries' || queryType === 'inAssets') {
    let keyValue = '_text_entries'

    if (queryType === 'inAssets') {
      keyValue = '_text_assets'
    }

    key.push({ label: 'Text', value: keyValue })
    value.push(selectedOpt)
    operator.push(stringOperators[1])
    currentSearch.queryType = queryType
    currentSearch.nextQuery = queryType
    return currentSearch
  }

  if (defaultOperator) {
    operator.push(defaultOperator)
  } else if (withOperator) {
    // console.log('in with op case')
    operator.push(stringOperators[0])
  }

  if (type === 'key') {
    key.push(selectedOpt)
    currentSearch.queryType = queryType
    currentSearch.nextQuery = nextQuery
    return currentSearch
  }

  if (type === 'value') {
    value.push(selectedOpt)
    return currentSearch
  }
}

export const getSuggestionsLength = (suggestions) => {
  let length = 0
  suggestions.forEach((query) => {
    length = length + query.options.length
  })
  return length
}

export const getSuggestionsDataByIndex = (suggestions, selectedIndex) => {
  // console.log('getSuggestionsDataByIndex -> suggestions, selectedIndex', suggestions, selectedIndex)
  try {
    let counter = 0
    for (let query of suggestions) {
      for (let option of query.options) {
        if (counter === selectedIndex) {
          // console.log('========> query', query, option)
          return {
            queryType: query.queryType,
            nextQuery: query.nextQuery,
            selectedOpt: option,
            type: query.type
          }
        }
        counter = counter + 1
      }
    }
  } catch (error) {
    console.log('error', error)
  }
}

const cbNameByCurrentQuery = {
  inContentType: 'fetchContentTypes',
  inUser: 'fetchUsers',
  inEntries: 'fetchEntries',
  inEnvironment: 'fetchEnvironments',
  inLanguage: 'fetchLanguages',
  inAssetTag: 'fetchAssetTags',
  inEntriesTag: 'fetchEntriesTags',
  inType: 'fetchAssetTypes',
  inFile: 'fetchAssets',
  inReference: 'fetchEntries',
  inWorkflowStage: 'fetchWorkflowStage',
  inTerms: 'fetchTerms'
}

//fetch data genric method
export const handleDataFetch = async (data) => {
  console.log('handleDataFetch args', data)
  const { skip, limit, inputValue, currentQuery, selectedOption, fetchData, query, suggestionsLength } = data
  try {
    if (selectedOption === options[0].value || selectedOption === options[1].value) {
      const cbName = cbNameByCurrentQuery[currentQuery]
      const dataFetchHandler = fetchData[cbName]
      if (dataFetchHandler) {
        let payload: any = {
          skip,
          limit,
          inputValue,
          suggestionsLength
        }
        if (query) {
          payload.query = query
        }

        const response: any = await dataFetchHandler(payload)
        return response
      }
    }

    return { data: [], count: 0 }
  } catch (error) {
    console.log('handleDataFetch -> error', error)
  }
}

export const queryBuilder = (data) => {
  const { queryCase, queryArray, selectedOption, advanceQueryArray, action='' } = data

  let queryObject: any = { $and: [] }
  let search = ''

  if (queryCase === 'advanceQuery') {
    const queryResponse = buildQueryObjectForAdvCase(advanceQueryArray, selectedOption)
    queryObject = queryResponse
  }

  if (queryCase === 'basicQuery' && selectedOption === options[0].value) {
    const queryResponse = queryBuilderEntries({ queryArray, action, queryCase: 'basicQuery' })
    queryObject = queryResponse.queryObject
    search = queryResponse.search
  }

  if (queryCase === 'basicQuery' && selectedOption === options[1].value) {
    const queryResponse = queryBuilderAssets({ queryArray })
    queryObject = queryResponse.queryObject
    search = queryResponse.search
  }

  return { queryObject, search }
}

export const basicQueryParser = async (data) => {
  try {
    const { queryObject, selectedOption, search_text, fetchData, cacheResponse } = data

    let queryArray = []

    if (selectedOption === options[0].value) {
      const parsedQuery = await queryParserEntries(queryObject, search_text, fetchData, cacheResponse)
      queryArray = parsedQuery
      return queryArray
    }

    if (selectedOption === options[1].value) {
      const parsedQuery = await queryParserAssets(queryObject, search_text, fetchData, cacheResponse)
      queryArray = parsedQuery
      return queryArray
    }
    return []
  } catch (error) {
    throw error
  }
}

export const advanceQueryParser = async ({ query, selectedModule, fetchData, cache = null }) => {
  try {
    let advanceQueryArray = []

    let cacheResponse
    if (cache) {
      cacheResponse = cache
    } else {
      cacheResponse = await fetchDataForQuery(Object.values(query)[0], fetchData)
    }

    if (selectedModule === options[0].value) {
      advanceQueryArray = await advanceQueryParserForEntries(query, cacheResponse, fetchData, null, true)
      updateSelectedContentTypeStatus(advanceQueryArray, 'default')
    }

    if (selectedModule === options[1].value) {
      advanceQueryArray = await advanceQueryAssetParser(query, selectedModule, fetchData, cacheResponse, true)
    }

    setCounterForAdvanceQuery([advanceQueryArray], -1)
    return [advanceQueryArray]
  } catch (error) {
    console.log("error", error)
    throw error
  }
}

export const getQueryTypeAndParseQuery = async (data) => {
  try {
    const { query, search_text, selectedModule, fetchData, cache = null } = data

    const queryString = JSON.stringify(query)
    const queryType = getQueryType(queryString, selectedModule)

    if (queryType === 'basicQuery') {
      const response = await basicQueryParser({ queryObject: query, selectedOption: selectedModule, search_text, fetchData })
      return { queryType, parsedQueryArray: response }
    }

    if (queryType === 'advanceQuery') {
      const response = await advanceQueryParser({ query, selectedModule, fetchData })
      return { queryType, parsedQueryArray: response }
    }


  } catch (error) {
    throw error
  }
}

export const numericTypes = [
  fileSizeLabel.queryType,
  fileSizeLabel.nextQuery,
  numberLabel.queryType,
  numberLabel.nextQuery,
  'fileSize'
]

export const textTypes = [inEntries.queryType, textLabel.queryType, inAssets.queryType]

export const dateTypes = [dateLabel.queryType, dateLabel.nextQuery]

export const notify = (message, type: 'warning' | 'error' | 'success' = 'warning') => {
  Notification({
    displayContent: { text: message },
    notifyProps: { hideProgressBar: true, position: 'bottom-center' },
    type
  })
}

export const validateSelectedOption = ({ queryType, nextQuery, selectedOpt, type, queryArray, errorContent, action }: any) => {
  const selectedValue = typeof selectedOpt.value === 'string' ? selectedOpt.value.trim() : selectedOpt.value
  if (selectedValue === `" "` || !selectedValue) {
    return { isValid: false, errorMessage: errorContent.no_valid_text }
  }

  const queryArrayHasText = queryArray.find((query) => textTypes.includes(query.queryType))
  const currentQueryIsText = textTypes.includes(queryType)

  if (currentQueryIsText && queryArrayHasText) {
    //handling edit case of inEntires since its "value" type, quertype and next query is same  
    if (!(action === 'edit' && queryType === inEntries.queryType)) {
      return { isValid: false, errorMessage: errorContent.only_single_text }
    }
  }

  return { isValid: true, errorMessage: '' }
}

export const maxSuggetsedItemsCount = {
  entries: 5,
  assets: 5
}

export const maxSavedSearchesCount = 5

export const getOperatorTextContent = ({ operatorKey, textContent, queryType }) => {
  try {
    let tooltipContent = textContent.tooltips.operators[operatorKey.name]

    const isNumeric = numericTypes.includes(queryType)
    const isDate = dateTypes.includes(queryType)
  
    const changeOperator = isNumeric || isDate
    if (changeOperator && ['NotEquals', 'Equals'].includes(operatorKey.name)) {
      tooltipContent = textContent.tooltips.operators[operatorKey.name + 'Numeric']
    }
    return tooltipContent
  } catch (error) {
    console.log("getOperatorTextContent -> error", error)
  }
}


    // let filteredSyntex = 
    
    // let copySuggestedSyntax = JSON.parse(JSON.stringify(suggestedSyntax))
    // let allFoundIndexes = []

    // if (updatedOrder.length) {
    //   for (let i = updatedOrder.length - 1; i >= 0; i--) {
    //     let syntax = updatedOrder[i]
    //     const foundIndex = copySuggestedSyntax.findIndex(data => data.uid === syntax)
    //     if (foundIndex !== -1) {
    //       updatedSuggestedSyntax.push(copySuggestedSyntax[foundIndex])
    //       allFoundIndexes.push(foundIndex)
    //     }
    //   }
    //   allFoundIndexes.forEach(index => {
    //     copySuggestedSyntax.splice(index, 1)
    //   })
    //   updatedSuggestedSyntax = [...updatedSuggestedSyntax, ...copySuggestedSyntax]
    //   console.log("getRecentUsedFieldsOrder -> updatedSuggestedSyntax", updatedSuggestedSyntax)
    //   return updatedSuggestedSyntax
    // }

    // return suggestedSyntax
import React from 'react'
import Icon from '../Icon2/Icon'
import Tooltip from '../Tooltip/Tooltip'
import OutlineTag from '../Tag/OutlineTag'
import DateLabel from '../DateLabel/DateLabel'
import './PublishDetails.css'
import Pill from '../Pills/Pill'
type PublishStatusProps = {
  label?: string
  style?: React.CSSProperties
  className?: string
  details?: React.ReactNode | string | undefined
  color?: string
  environment?: string
  version?: string
  locale?: string
  userName?: string
  scheduledAt?: string
  dateTime?: any
  publishAction?: string
  versionOnClick?: Function
  url?: React.ReactNode | string
  testId?: string
  versionTestId?: string
}
const handleVersionClick = (versionOnClick) => {
  if (versionOnClick) {
    versionOnClick()
  }
}
const GetScheduleInfo = ({
  environment,
  version,
  locale,
  url,
  userName,
  scheduledAt,
  publishAction,
  versionOnClick,
  versionTestId
}: PublishStatusProps) => {
  const handleVersionClickOnKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleVersionClick(versionOnClick)
    }
  }
  return (
    <div>
      <div className="flex-v-center mb-10">
        <div className="PublishDetails__env">{environment}</div>
      </div>
      {version ? (
        <div className="flex-v-center mb-15">
          <div
            data-test-id={versionTestId}
            onClick={versionOnClick ? () => handleVersionClick(versionOnClick) : null}
            tabIndex={versionOnClick ? 0 : -1}
            onKeyDown={versionOnClick ? handleVersionClickOnKeyDown : null}
            className={`PublishDetails__version ${versionOnClick ? 'PublishDetails__version__link' : ''}`}>
            <span> {version}</span>
          </div>
          <div>
            {publishAction == 'publish' ? <Pill shouldHaveBorder={false} isSortable={false} variant="label" status="default" items={[{ id: 1, text: "Publish Scheduled" }]} /> : null}
            {publishAction == 'unpublish' ? <Pill shouldHaveBorder={false} isSortable={false} variant="label" status="default" items={[{ id: 2, text: "Unpublish Scheduled" }]} /> : null}
            {publishAction == 'scheduled' ? <Pill shouldHaveBorder={false} isSortable={false} variant="label" status="default" items={[{ id: 3, text: "Deploy Scheduled" }]} /> : null}
          </div>
        </div>
      ) : null}
      <div className="flex-v-center mb-10">
        {publishAction === 'publish' || publishAction === 'unpublish' || publishAction === 'scheduled' ? (
          <Tooltip content="Scheduled At" position="left" showArrow={false}>
            <Icon icon="Clock" version="v2" size="small" />
          </Tooltip>
        ) : (
          <Tooltip content="Published At" position="left" showArrow={false}>
            <Icon icon="PublishIcon" version="v2" size="small" />
          </Tooltip>
        )}
        <DateLabel date={scheduledAt} type="medium" />
      </div>
      {locale ? (
        <div className="flex-v-center mb-10">
          <Tooltip content="Locale" position="left" showArrow={false}>
            <Icon icon="Language" size="small" version="v2" />
          </Tooltip>
          <span className="PublishDetails__locale">{locale}</span>
        </div>
      ) : null}
      <div className="flex-v-center mb-10">
        <Tooltip content="User" position="left" showArrow={false}>
          <Icon icon="Profile" version="v2" size="small" />
        </Tooltip>
        <span className="PublishDetails__user">{userName}</span>
      </div>
      {url && (
        <div className="flex-v-center">
          <Tooltip content="Url" position="left" showArrow={false}>
            <Icon icon="Url" version='v2' size='small' />
          </Tooltip>
          <span className="PublishDetails__url">{url || '-'}</span>
        </div>
      )}
    </div>
  )
}
const GetVersionDetails = ({ version, versionOnClick }) => {
  const handleVersionClickOnKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleVersionClick(versionOnClick)
    }
  }
  if (version) {
    return (
      <div
        onClick={versionOnClick ? () => handleVersionClick(versionOnClick) : null}
        className={`PublishDetails__version ${versionOnClick ? 'PublishDetails__version__link' : ''} mb-15`}
        tabIndex={versionOnClick ? 0 : -1}
        onKeyDown={versionOnClick ? handleVersionClickOnKeyDown : null}>
        <span> {version}</span>
      </div>
    )
  }
  return null
}
const GeUnlocalizePublishDetails = (locale) => {
  if (locale) {
    return <div className="PublishDetails__unlocalize">The entry was published before localizing</div>
  }
  return null
}
const PublishDetails: React.FunctionComponent<PublishStatusProps> = (props) => {
  const {
    color,
    environment,
    version,
    locale,
    userName,
    scheduledAt,
    dateTime,
    publishAction,
    versionOnClick,
    url,
    testId
  } = props
  return (
    <div className="PublishDetails" data-test-id={testId}>
      <div>
        <Icon icon="EnvironmentColor" fill={color}></Icon>
        {scheduledAt ? (
          <GetScheduleInfo {...props} />
        ) : (
          <div>
            <div>
              <div className="PublishDetails__env mb-10">{environment}</div>
            </div>
            <GetVersionDetails version={version} versionOnClick={versionOnClick} />
            <div className="flex-v-center mb-5">
              <Tooltip content={"Published At"} position="left" showArrow={false}>
                <Icon icon={"PublishIcon"} version="v2" size="small" />
              </Tooltip>
              <DateLabel date={dateTime} type="medium" dateVal={dateTime}></DateLabel>
            </div>
            <div className="flex-v-center mb-5">
              <Tooltip content="User" position="left" showArrow={false}>
                <Icon icon="Profile" version="v2" size="small" />
              </Tooltip>
              <span className="PublishDetails__user">{userName}</span>
            </div>
            {url && (
              <div className="flex-v-center">
                <Tooltip content="Url" position="left" showArrow={false}>
                  <Icon icon="Url" version='v2' size='small' />
                </Tooltip>
                <span className="PublishDetails__url">{url || '-'}</span>
              </div>
            )}
            <GeUnlocalizePublishDetails locale={locale} />
          </div>
        )}
      </div>
    </div>
  )
}
PublishDetails.defaultProps = {
  label: '',
  version: '',
  locale: '',
  testId: 'cs-publish-details',
  versionTestId: 'cs-publish-details-version-test-id'
} as Partial<PublishStatusProps>

export default PublishDetails
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames'

import ClipBoard from '../ClipBoard/ClipBoard'
import { constants } from './util'
const TableRowAction = (props) => {
  const { list = [], data, closeDropdown, rowActionData = {}, viewBy, ...rest }: any = props

  const [isCursorInside, setIsCursorInside] = useState(false)
  const [openAtTop, setOpenAtTop] = useState(false)

  let [listCursor, setListCursor] = useState(-1)

  let tableActionRef: any = useRef({})
  tableActionRef.current.isCursorInside = isCursorInside
  tableActionRef.current.listCursor = listCursor

  let actionDropdownRef: any = useRef({})

  const handleClickOutside = () => {
    if (!tableActionRef.current.isCursorInside) {
      console.log('click outside detected')
      closeDropdown()
    }
  }

  const registerKeybindings = (e) => {
    const { key } = e
    let listCursor = tableActionRef.current.listCursor

    if (key === 'ArrowDown' && listCursor < list.length - 1) {
      listCursor = listCursor + 1
      setListCursor(listCursor)
    } else if (key === 'ArrowUp' && listCursor > 0) {
      listCursor = listCursor - 1
      setListCursor(listCursor)
    } else if (key === 'Escape') {
      setListCursor(-1)
      closeDropdown()
    }

    if (key === 'Enter' && list >= 0 && listCursor < listCursor.length - 1) {
      if (list[listCursor] && list[listCursor].action) {
        list[listCursor].action(e, list[listCursor], rest)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    document.addEventListener('keyup', registerKeybindings, false)
    actionDropdownRef.current.focus()
    return () => {
      closeDropdown()
      document.removeEventListener('click', handleClickOutside)
      document.removeEventListener('keyup', registerKeybindings, false)
    }
  }, [])

  useEffect(() => {
    if (actionDropdownRef.current && typeof rowActionData.clientY === 'number') {
      let openDropdownAtTop =
        window.innerHeight - rowActionData.clientY > actionDropdownRef.current.offsetHeight + 40 ? false : true
      setOpenAtTop(openDropdownAtTop)
    }
  }, [actionDropdownRef, rowActionData.rowIndex])

  let topOffset = viewBy === constants.comfort_view ? 58 : 52

  let styleObj = {
    left: props.rowActionData.clientX - 236 + 70,
    top: openAtTop ? rowActionData.clientY - actionDropdownRef.current.offsetHeight : rowActionData.clientY + topOffset
  }

  return (
    <div
      className="VerticalActionTooltip"
      onMouseEnter={() => {
        setIsCursorInside(true)
      }}
      onMouseLeave={() => {
        setIsCursorInside(false)
      }}
      ref={actionDropdownRef}
      style={styleObj}
      tabIndex={0}>
      <ul className="VerticalActionTooltip__actions">
        {list.map((item: any, i: any) => {
          const canDisplay = typeof item.canDisplay === 'function' ? item.canDisplay(data) : true
          const controlledClipboardCopyText = (data && data[item.copyText]) || data?.uid

          if (canDisplay && item.displayType === 'clipboard') {
            return (
              <div
                className={classnames(
                  'VerticalActionTooltip__clipboard',
                  listCursor === i && 'VerticalActionTooltip__clipboard--active'
                )}
                key={i}>
                <ClipBoard
                  copyLabel={item.title}
                  copyText={controlledClipboardCopyText}
                  type={'default'}
                  position="top">
                  <li className="flex-v-center" data-test-id={item.testId}>
                    {typeof item.label === 'function' ? <item.label data={data} /> : item.label}
                    <div className="ml-8">{typeof item.title === 'function' ? item.title(data) : item.title}</div>
                  </li>
                </ClipBoard>
              </div>
            )
          }

          return (
            canDisplay && (
              <li
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    closeDropdown()
                    item.action(e, data, rest)
                  }
                }}
                // onBlur={(e) => {
                //   if (i === list.length - 1) {
                //     // closeDropdown()
                //     setTimeout(() => {
                //       //getting focus on table row
                //       const currentTableRow: any = document.querySelectorAll('.Table__body__row')[rowActionData.rowIndex]
                //       currentTableRow?.focus()
                //     })
                //   }
                // }}
                onClick={(e) => {
                  closeDropdown()
                  item.action(e, data, rest)
                }}
                className={classnames(
                  'flex-v-center',
                  listCursor === i && 'VerticalActionTooltip__actions__selectedList'
                )}
                key={i}
                data-test-id={item.testId}>
                {typeof item.label === 'function' ? <item.label data={data} /> : item.label}
                <div className="ml-8">{typeof item.title === 'function' ? item.title(data) : item.title}</div>
              </li>
            )
          )
        })}
      </ul>
    </div>
  )
}

const TableRowActionWrapper = ({ list, data, rowActionData, closeDropdown, viewBy }) => {
  let foundDiv = document.getElementById('tableRowActionNode')

  let div: any

  if (foundDiv) {
    div = foundDiv
  } else {
    div = document.createElement('div')
    div.setAttribute('id', 'tableRowActionNode')
    document.body.appendChild(div)
  }

  function closeTableRowAction() {
    // const unmountResult = ReactDOM.unmountComponentAtNode(div)
    if (div.parentNode) {
      div.parentNode.removeChild(div)
      closeDropdown()
    }
  }

  return ReactDOM.createPortal(
    <TableRowAction
      list={list}
      data={data}
      rowActionData={rowActionData}
      closeDropdown={closeTableRowAction}
      viewBy={viewBy}
    />,
    div
  )
}

export default TableRowActionWrapper

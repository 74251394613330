import React, { useState } from 'react'
import className from 'classnames'

import TimeZoneStandard from './TimeZoneStandard'
import { getInitialTime, defaultTimezone } from '../DateTime/helper'
import { ITimeSlider, ITimePicker } from '../DateTime/interfaces'
import TextInput from '../TextInput/TextInput'
import Button from '../Button/Button'
import './Timepicker.css'

const TimeSlider: React.FunctionComponent<ITimeSlider> = (props) => {
  const { name, min, max, value, onClick }: any = props
  return (
    <div className={`TimePicker__time TimePicker__time--${name}`}>
      <div className="TimePicker__time__title">{name}</div>
      <div className="TimePicker__time__box">
        <TextInput
          type="number"
          placeholder={name}
          value={value}
          className="TimePicker__time__value"
          min={min}
          max={max}
          onFocus={(event) => {
            event.preventDefault()
            event.stopPropagation()
          }}
          onChange={(event) => {
            event.preventDefault()
            onClick(event.target.value)
          }}
        />
      </div>
    </div>
  )
}

const TimePicker: React.FunctionComponent<ITimePicker> = (props) => {
  const { initialDate, onChange, viewType, onDone, onCancel, closeModal, testId } = props
  const initDate = initialDate
  const currDate = new Date(initialDate).getTime() ? new Date(initialDate) : new Date(new Date().setHours(0, 0, 0, 0))

  const gmt = new Date().toTimeString().split('GMT')[1].substring(0, 5)
  const [zone, setZone] = useState(gmt)
  const [dstZone, setDstZone] = useState(defaultTimezone(''))
  const [timeObj, setTimeObj] = useState({ label: '(GMT +5:30) India Standard Time - Kolkata', value: '+0530' })
  const [time, setTime] = useState(
    initialDate && initialDate.toString().includes('T') ? getInitialTime(currDate) + zone : `00:00:00${zone}`
  )
  const [hour, setHour] = useState(initialDate ? String(currDate.getHours()).padStart(2, '0') : '00')
  const [minute, setMinute] = useState(initialDate ? String(currDate.getMinutes()).padStart(2, '0') : '00')
  const [second, setSecond] = useState(initialDate ? String(currDate.getSeconds()).padStart(2, '0') : '00')

  const hourChangeHandler = (value: any) => {
    const hour = parseInt(value)
    let t = ''
    if (hour >= 0 && hour < 24) {
      const formattedNumber = ('0' + hour).slice(-2)
      setHour(formattedNumber)
      t = `${formattedNumber}:${minute}:${second}${zone}`
      setTime(t)
      // } else if (hour > 23) {
      //   t = time
    } else {
      setHour('00')
      t = `00:${minute}:${second}${zone}`
      setTime(t)
    }
    if (onChange) {
      onChange(t, timeObj)
    }
  }
  const minuteChangeHandler = (value: any) => {
    const minute = parseInt(value)
    let t = ''
    if (minute >= 0 && minute < 60) {
      const formattedNumber = ('0' + minute).slice(-2)
      t = `${hour}:${formattedNumber}:${second}${zone}`
      setMinute(formattedNumber)
      setTime(t)
    } else if (minute > 59) {
      t = time
    } else {
      setMinute('00')
      t = `${hour}:00:${second}${zone}`
      setTime(t)
    }
    if (onChange) {
      onChange(t, timeObj)
    }
  }
  const secondChangeHandler = (value: any) => {
    const second = parseInt(value)
    let t = ''
    if (second >= 0 && second < 60) {
      const formattedNumber = ('0' + second).slice(-2)
      setSecond(formattedNumber)
      t = `${hour}:${minute}:${formattedNumber}${zone}`
      setTime(t)
      // } else if (second > 59) {
      //   t = time
    } else {
      t = `${hour}:${minute}:00${zone}`
      setSecond('00')
      setTime(t)
    }
    if (onChange) {
      onChange(t, timeObj)
    }
  }
  const zoneHandler = (zone: any, obj: any) => {
    const timeValue = time.includes('+') ? time.split('+')[0] : time.split('-')[0]
    const t = timeValue + obj.offsetString
    setTime(t)

    setZone(obj.offsetString)
    setDstZone(zone)
    setTimeObj(obj)

    if (onChange) {
      onChange(t, obj)
    }
  }

  const doneHandler = () => {
    onDone(time, timeObj)
    if (closeModal) {
      closeModal()
    }
  }

  const cancelHandler = () => {
    if (onCancel) {
      onCancel(initDate)
    }
    if (closeModal) {
      closeModal()
    }
  }

  const handlePickerClick = (event: any) => {
    event.stopPropagation()
    if (
      !event.target.parentElement?.classList.contains('TimePicker__time__value') &&
      !event.target?.classList.contains('TimePicker__action') &&
      !event.target.parentElement?.classList.contains('TimePicker__action') &&
      !event.target.parentElement.parentElement?.classList.contains('TimePicker__action')
    ) {
      let elems = document.querySelectorAll('.TimePicker__time--focus')
        ;[].forEach.call(elems, function (el) {
          el.classList.remove('TimePicker__time--focus')
        })
    }
  }

  return (
    <>
      <div
        data-test-id={testId}
        className={className('TimePicker', {
          'TimePicker__date-time': viewType === 'datetime'
        })}
        onClick={handlePickerClick}>
        <div className="TimePicker--time">
          <div className="TimePicker__title">Choose Time</div>
          <div className="flex">
            <TimeSlider name="Hour" value={hour} onClick={hourChangeHandler} min={0} max={23} />
            <TimeSlider name="Minute" value={minute} onClick={minuteChangeHandler} min={0} max={59} />
            <TimeSlider name="Second" value={second} onClick={secondChangeHandler} min={0} max={59} />
          </div>
        </div>

        <div className="TimePicker--timezone">
          <div className="TimePicker__title">Choose Time Zone</div>
          <TimeZoneStandard zone={zone} dstZone={dstZone} onChange={zoneHandler} />
        </div>
        {viewType !== 'datetime' && (
          <div className="TimePicker__actions flex-right">
            <Button buttonType="primary" onClick={doneHandler}>
              Done
            </Button>
            <Button buttonType="tertiary" onClick={cancelHandler}>
              Cancel
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

TimePicker.defaultProps = {
  testId: 'cs-timepicker'
}
export default TimePicker

import { Range, Transforms } from 'slate'
import isUrl from 'is-url'
import imageExtensions from './imageExtensions'
import { uploadImage } from '../contentstack/image/request'
import { getAssetType } from '../contentstack/image/Modal/util'

export const insertImage = async (editor, url, extraAttrs = {}) => {
  const selection = editor.selection || editor.savedSelection;

  let image;
  if (url.startsWith('data')) { // upload to assets

    let fileArray = url.split(','),
      mime = fileArray[0].match(/:(.*?);/)[1],
      bstr = atob(fileArray[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const fileToUpload = new File([u8arr], 'image.png', { type: mime });
    const requestProps: Object = editor?.requestProps || {};
    const folderUid = ''
    const imgUploadRes: any = await uploadImage({ ...requestProps, files: [fileToUpload], folderUid })
    if (imgUploadRes) {
      const isImage = Array.from(imgUploadRes).some(instance => getAssetType(instance?.['asset']?.['content_type']) === null);

      if (isImage) {
        const alt = extraAttrs["alt"] ?? ''
        delete extraAttrs["alt"]
        image = {
          type: 'img',
          attrs: {
            url: imgUploadRes[0].asset.url,
            width: null,
            alt,
            height: 'auto',
            "redactor-attributes": { ...extraAttrs }
          },
          children: [{ text: '' }]
        }
      }
    }
  } else {
    const alt = extraAttrs["alt"] ?? ''
    delete extraAttrs["alt"]
    image = {
      type: 'img',
      attrs: {
        url,
        width: null,
        alt,
        height: 'auto',
        "redactor-attributes": { ...extraAttrs }
      },
      children: [{ text: '' }]
    }
  }
  if (selection)
    Transforms.insertNodes(editor, image, { at: selection });
  else Transforms.insertNodes(editor, image)
}

export const isImageUrl = (url) => {
  if (!url) return false
  if (!isUrl(url)) return false
  const ext = new URL(url).pathname.split('.').pop()
  return imageExtensions.includes(ext)
}

export const isSelectionCollapsed = (selection) => {
  return Range.isCollapsed(selection)
}

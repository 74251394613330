export const limitToFetch = 30

export const notPublishedEnvObj = { label: 'Not Published', value: '_notPublished_env' }

//for dropdown
export const options = [
  { label: 'Entries', value: 'entries', placeholder: `Enter keywords, or hit spacebar to search by fields` },
  { label: 'Assets', value: 'assets', placeholder: `Enter keywords, or hit spacebar to search by fields` }
]

// dom ids 
export const tooltipWrapperId = 'AdvancedSearch__tooltip-wrapper';
export const portalId = 'AdvancedSearch__portal';

export const usersKey = ['created_by', 'updated_by', '_published_by']
export const dateKey = ['created_at', 'updated_at', '_published_at']
export const environmentKey = '_publish_environment'
export const contentTypeKey = '_content_type_uid'
export const termKey = 'taxonomies'
export const languageKey = 'locale'
export const tagKey = 'tags'
export const fileNameKey = 'filename'
export const fileSizeKey = 'file_size'
export const urlKey = 'url'
export const titleKey = 'title'
export const assetTypeKey = '_content_type_uid'
export const uidKey = 'uid'
export const workflowStageKey = '_workflow'

export const iconName = {
  Equals: 'Equals',
  Contains: 'Contains',
  NotEquals: 'NotEquals',
  Search: 'Search',
  DropdownDark: 'DropdownDark',
  Clock: 'Clock',
  DeleteQueryBuilder: 'DeleteQueryBuilder',
  AddQueryBuilder: 'AddQueryBuilder',
  LessThanEquals: 'LessThanEquals',
  GreaterThanEquals: 'GreaterThanEquals',
  Disk: 'Disk',
  LessThan: 'LessThan',
  GreaterThan: 'GreaterThan',
  SettingsOutline: 'SettingsOutline',
  NewTab: 'NewTab',
  Filter: 'Filter'
}

export const inputTextShadow = `-10px 0 24px #6c83a9, 2px 0 24px #7991b8`

export const viewAllCtaId = 'AdvancedSearch-optionButton-viewAllCta'

export let stringOperators = [
  {
    name: 'Equals',
    icon: iconName.Equals,
    value: '$eq'
  },
  {
    name: 'Contains',
    icon: iconName.Contains,
    value: 'contains'
  },
  {
    name: 'NotEquals',
    icon: iconName.NotEquals,
    value: '$ne'
  }
]

export let basicOperators = [
  {
    name: 'Equals',
    icon: iconName.Equals,
    value: '$eq'
  },
  {
    name: 'NotEquals',
    icon: iconName.NotEquals,
    value: '$ne'
  }
]

export let comparisonOperator = [
  {
    name: 'Equals',
    icon: iconName.Equals,
    value: '$eq'
  },
  {
    name: 'LessThanEquals',
    icon: iconName.LessThanEquals,
    value: '$lte'
  },
  {
    name: 'GreaterThanEquals',
    icon: iconName.GreaterThanEquals,
    value: '$gte'
  }
]

export let allOperators = [
  {
    name: 'LessThanEquals',
    icon: iconName.LessThanEquals,
    value: '$lte'
  },
  {
    name: 'GreaterThanEquals',
    icon: iconName.GreaterThanEquals,
    value: '$gte'
  },
  {
    name: 'LessThan',
    icon: iconName.LessThan,
    value: '$lt'
  },
  {
    name: 'GreaterThan',
    icon: iconName.GreaterThan,
    value: '$gt'
  },
  {
    name: 'Equals',
    icon: iconName.Equals,
    value: '$eq'
  },
  {
    name: 'NotEquals',
    icon: iconName.NotEquals,
    value: '$ne'
  }
]

export const switchMatchAllAny = {
  matchAll: 'matchAny',
  matchAny: 'matchAll'
}

export const advanceQueryOpts = [
  { label: 'Match All', name: 'Match All', value: 'matchAll' },
  { label: 'Match Any', name: 'Match Any', value: 'matchAny' }
]


export const operatorTextByName = {
  Contains: '~',
  Equals: '=',
  NotEquals: '≠',
  LessThan: '<',
  GreaterThan: '>',
  LessThanEquals: '≤',
  GreaterThanEquals: '≥',
}

export const operatorByValue = {
  $in: {
    name: 'Equals',
    icon: iconName.Equals,
    value: '$eq',
  },
  $nin: {
    name: 'NotEquals',
    icon: iconName.NotEquals,
    value: '$ne',
  },
  $eq: {
    name: 'Equals',
    icon: iconName.Equals,
    value: '$eq',
  },
  $ne: {
    name: 'NotEquals',
    icon: iconName.NotEquals,
    value: '$ne',
  },
  $lte: {
    name: 'LessThanEquals',
    icon: iconName.LessThanEquals,
    value: '$lte',
  },
  $gte: {
    name: 'GreaterThanEquals',
    icon: iconName.GreaterThanEquals,
    value: '$gte',
  },
  contains: {
    name: 'Contains',
    icon: iconName.Contains,
    value: 'contains',
  },
  $regex: {
    name: 'Contains',
    icon: iconName.Contains,
    value: 'contains',
  },
  $lt: {
    name: "LessThan",
    icon: iconName.LessThan,
    value: "$lt"
  },
  $gt: {
    name: "GreaterThan",
    icon: iconName.GreaterThan,
    value: "$gt"
  }
}

const commonQueryFormat = {
  created_by: {
    key: [{ label: 'Created by', value: 'created_by' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    nextQuery: 'inUser',
    queryType: 'inSyntax'
  },
  updated_by: {
    key: [{ label: 'Updated by', value: 'updated_by' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    nextQuery: 'inUser',
    queryType: 'inSyntax'
  },
  _published_by: {
    key: [{ label: 'Published by', value: '_published_by' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    nextQuery: 'inUser',
    queryType: 'inSyntax'
  },
  _publish_environment: {
    key: [{ label: 'Published Environment', value: '_publish_environment' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    queryType: 'environmentLabel',
    nextQuery: 'inEnvironment'
  },
  // tags: {
  //   key: [{ label: 'Tags', value: 'tags' }],
  //   operator: [],
  //   value: [],
  //   isQueryCompleted: true,
  //   queryType: 'tagLabel',
  //   nextQuery: 'inTag'
  // },
  created_at: {
    key: [{ label: 'Created At', value: 'created_at' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    queryType: 'date',
    nextQuery: 'inDate'
  },
  updated_at: {
    key: [{ label: 'Updated At', value: 'updated_at' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    queryType: 'date',
    nextQuery: 'inDate'
  },
  _published_at: {
    key: [{ label: 'Published At', value: '_published_at' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    queryType: 'date',
    nextQuery: 'inDate'
  },
  uid: {
    key: [{ label: 'UID', value: 'uid' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    queryType: '"uidLabel"',
    nextQuery: '"inText"'
  }
}

export const assetQueryFormat = {
  filename: {
    key: [{ label: 'File Name', value: 'filename' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    nextQuery: 'inFileName',
    queryType: 'fileNameLabel'
  },
  url: {
    key: [{ label: 'URL', value: 'url' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    nextQuery: 'inUrl',
    queryType: 'urlLabel'
  },
  title: {
    key: [{ label: 'Title', value: 'title' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    nextQuery: 'inTitle',
    queryType: 'titleLabel'
  },
  file_size: {
    key: [{ label: 'File Size', value: 'file_size' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    nextQuery: 'inFileSize',
    queryType: 'fileSizeLabel'
  },
  _content_type_uid: {
    key: [{ label: 'Type', value: '_content_type_uid' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    nextQuery: 'inType',
    queryType: 'typeLabel'
  },
  tags: {
    key: [{ label: 'Tags', value: 'tags' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    queryType: 'assetTagLabel',
    nextQuery: 'inAssetTag'
  },
  ...commonQueryFormat
}

export const entriesQueryFormat = {
  locale: {
    key: [{ label: 'Language', value: 'locale' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    queryType: 'languageLabel',
    nextQuery: 'inLanguage'
  },
  _content_type_uid: {
    key: [{ label: 'Content type', value: '_content_type_uid' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    queryType: 'contentTypeLabel',
    nextQuery: 'inContentType'
  },
  taxonomies: {
    key: [{ label: 'Terms', value: 'taxonomies' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    queryType: 'termLabel',
    nextQuery: 'inTerms'
  },
  tags: {
    key: [{ label: 'Tags', value: 'tags' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    queryType: 'entriesTagLabel',
    nextQuery: 'inEntriesTag'
  },
  $localization: {
    key: [{ label: 'Localized In', value: '$localization' }],
    operator: [stringOperators[0]],
    value: [{ label: 'Any Locales', value: [{ label: 'Any Locales', value: '$any' }] }],
    queryType: 'localizedInLabel',
    nextQuery: 'inLocalized',
    isQueryCompleted: true
  },
  _workflow: {
    key: [{ label: 'Workflow Stage', value: '_workflow' }],
    operator: [],
    value: [],
    isQueryCompleted: true,
    queryType: 'workflowStageLabel',
    nextQuery: 'inWorkflowStage',
  },
  ...commonQueryFormat
}

export const defaultTextContent = {
  placeholders: {
    search_bar: 'Search content',
    advance_search_query: 'Type something or click to see suggestions',
    saved_views: 'Find a saved search'
  },
  advance_search_ui: {
    suggested_syntax: {
      title: 'SUGGESTED FIELDS'
    },
    saved_searches: {
      title: 'SAVED SEARCHES'
    },
    recent_searches: {
      title: 'RECENT SEARCHES'
    },
    button: {
      see_more: 'More',
      see_less: 'See Less'
    }
  },
  saved_views: {
    title: 'Saved Searches',
    save_current_search_btn_txt: 'Save this search',
    no_search_result: 'No matching saved searches found',
    no_result: 'No saved searches, yet',
    no_result_description: 'When you save searches, you will find them here.'
  },
  notification: {
    delete_saved_search: 'Saved search deleted successfully',
    success_saved_search: 'Search saved successfully',
    update_saved_search: 'Search updated successfully'
  },
  modals: {
    delete_saved_search: {
      title: 'Delete Saved Search'
    }
  },
  empty_states: {
    recent_searches: {
      title: 'You have no recent searches.'
    },
    save_searches: {
      title: 'You have no saved searches'
    }
  },
  validations: {
    advance_query: {
      no_conditions: 'Please add a valid condition to search',
      no_cursor_found: "Content type field cannot be used in parent condition if already used in child condition",
      userdef_field_error: 'User-defined fields cannot be used if multiple content types are selected in the parent condition'
    },
    basic_query: {
      no_valid_text: 'Apply valid text to Search',
      only_single_text: "Cannot use multiple 'Text' fields"
    },
    filters: {
      no_filters: 'Apply some filters',
      no_localization: 'Localization cannot be empty'
    },
    saved_searches: {
      search_name_empty: 'Search name cannot be empty',
      search_name_length: 'Title cannot be greater than 50 character'
    }
  },
  links: {
    learn_more_basic_search: 'https://www.contentstack.com/docs/content-managers/search-content',
    learn_more_saved_searches: 'https://www.contentstack.com/docs/content-managers/search-content/use-saved-searches'
  },
  tooltips: {
    operators: {
      LessThanEquals: 'Less than or equal to',
      GreaterThanEquals: 'Greater than or equal to',
      GreaterThan: 'Greater than',
      LessThan: 'Less than',
      Contains: 'Contains',
      Equals: 'Matches',
      NotEquals: 'Does not match',
      NotEqualsNumeric: 'Not Equal to',
      EqualsNumeric: 'Equal to'
    },
    clear_search: 'Clear',
    close_view: 'Close',
    advance_query_bottom_remove_hover: 'Add Nested Condition Set',
    advance_query_right_remove_hover: 'Add Condition Set',
    saved_views: 'Saved Searches'
  }
}


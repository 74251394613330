import { useEffect } from 'react'
import { Editor } from 'slate'

export const handleEntrySave = (editor: Editor, isPlugin: boolean = false) => (event) => {
  if (!editor) return
  if (!isPlugin && event?.['detail']?.['eventName'] !== "entrySave") return
  editor['isEntrySaving'] = true
  delete editor['mockOperations']
}

function useEntrySave(editor: Editor, isPlugin: boolean = false) {
  const finalHandleEntrySave = handleEntrySave(editor, isPlugin)
  useEffect(() => {
    // This event is fired when entry is saved
    !isPlugin && window.addEventListener('$autoUpdateExtensionField', finalHandleEntrySave)
    return () => {
      !isPlugin && window.removeEventListener('$autoUpdateExtensionField', finalHandleEntrySave)
    }
  }, [])

  useEffect(() => {
    if (isPlugin && window.postRobot) {
      window.postRobot.on('entrySaved', finalHandleEntrySave)
    }
  }, [!!window.postRobot])
}

export default useEntrySave
import { Editor, Transforms } from 'slate'
import isUrl from 'is-url'

import { wrapLink } from '../../elements/Element/hyperlink/utils'
import { getPointBefore } from './utils/getPointBefore'
import { toggleList } from '../../elements/Element/list/utils'
import { insertImage, isImageUrl } from '../../elements/Element/image/utils'

export const rules: any = [
  {
    type: 'h1',
    markup: '#'
  },
  {
    type: 'h2',
    markup: '##'
  },
  {
    type: 'h3',
    markup: '###'
  },
  {
    type: 'h4',
    markup: '####'
  },
  {
    type: 'h5',
    markup: '#####'
  },
  {
    type: 'h6',
    markup: '######'
  },
  {
    type: 'ul',
    markup: ['*', '-'],
    format: (editor, match, type, at) => {
      toggleList({ preventDefault: () => { } }, editor, 'ul')
    }
  },
  {
    type: 'ol',
    markup: ['1.', '1)'],
    format: (editor, match, type, at) => {
      toggleList({ preventDefault: () => { } }, editor, 'ol')
    }
  },
  {
    type: 'blockquote',
    markup: ['>']
  },
  {
    type: 'bold',
    between: ['**', '**'],
    mode: 'inline',
    insertTrigger: true
  },
  {
    type: 'bold',
    between: ['__', '__'],
    mode: 'inline',
    insertTrigger: true
  },
  {
    type: 'italic',
    between: ['*', '*'],
    mode: 'inline',
    insertTrigger: true
  },
  {
    type: 'italic',
    between: ['_', '_'],
    mode: 'inline',
    insertTrigger: true
  },
  {
    type: 'img',
    markup: /!\[(.*)\]\((.*)\)/,
    regexp: true,
    mode: 'block',
    format: (editor, match, type, at) => {
      let altText = match[1]
      let url = match[2]
      if (!isImageUrl(url)) return false
      Transforms.delete(editor, { at })
      insertImage(editor, url, { alt: altText })
    }
  },
  {
    type: 'strikethrough',
    between: ['~~', '~~'],
    mode: 'inline',
    insertTrigger: true
  },
  {
    trigger: '`',
    type: 'code',
    markup: '``',
    mode: 'block'
  },
  {
    type: 'inlineCode',
    between: ['`', '`'],
    mode: 'inline',
    insertTrigger: true
  },
  {
    type: 'superscript',
    between: ['^', '^'],
    mode: 'inline',
    insertTrigger: true
  },
  {
    type: 'subscript',
    between: ['~', '~'],
    mode: 'inline',
    insertTrigger: true
  },
  {
    type: 'a',
    between: ['[', ')'],
    mode: 'inline',
    format: (editor) => {
      const linkStartPoint = getPointBefore(editor, editor.selection, {
        matchString: '[',
        skipInvalid: true
      })

      if (!linkStartPoint) return false

      const markupRange = {
        anchor: linkStartPoint,
        focus: editor.selection?.anchor
      }

      const markupString = Editor.string(editor, markupRange)

      const match = markupString.match(/\[(.*)\]\((.*)\)/)

      if (!match) return false

      const linkText = match[1]
      const linkUrl = match[2]

      if (!isUrl(linkUrl)) return false

      Transforms.delete(editor, { at: markupRange })
      wrapLink(editor, linkUrl, linkText, false)
    }
  }
]

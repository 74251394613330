//@ts-nocheck
import React, { memo, useContext } from 'react'
import { cx } from '@emotion/css';

import './styles.module.css'
import styles from './styles.module.css';

import {
  BoldIcon,
  ItalicIcon,
  ImageIcon,
  QuoteIcon,
  CodeIcon,
  UnorderedListIcon,
  HeadingIcon,
  DividerIcon,
  UndoIcon,
  RedoIcon,
  OrderedListIcon,
  LinkIcon,
} from './assets/svg'
import { Icon, Tooltip } from '../../../index'
import { handleMouseDown } from './utils/handleMouseDown'
import { linkHandleMouseDown, imageHandleMouseDown, uploadImageHandleMouseDown, orderedHandleMouseDown } from './elements'
import Dropdown from '../../DropDown/Dropdown'
import { EditorProvider } from './EditorProvider';

const handleRedo = (e, editor) => {
  e.preventDefault()
  editor.undoManager.redo()

}

const handleUndo = (e, editor) => {
  e.preventDefault()
  editor.undoManager.undo()
}

export const Button = ({ btn, editor }) => {
  return (
    <Tooltip key={btn.icon} content={btn.title} position="bottom">
      <span
        className="cs-toolbar-btn"
        id={`cs-toolbar-btn-${btn.title.toLowerCase()}`}
        onMouseDown={(e) =>
          btn.onMouseDown
            ? btn.onMouseDown(e, editor)
            : handleMouseDown(e, editor, btn.mark, btn.side ? btn.side : 'both', btn.placeholder, btn?.id, btn?.meta)
        }
      >
        <div
          className={styles['center']}
        >
          {typeof btn.icon === 'string' ? <Icon icon={btn.icon} /> : <btn.icon />}
        </div>
      </span>
    </Tooltip>
  )
}

export const Toolbar = memo(({ className, extra, isPreview, isAssetPublic, postfix, editorRef }: any) => {
  const emptyEvent = {
    preventDefault: () => { }
  }

  const { editorContext: editor } = useContext(EditorProvider)

  const buttonsInfo = [
    { mark: '**', placeholder: 'strong text', icon: BoldIcon, title: 'Bold', id: 'doBold' },
    { mark: '_', placeholder: 'italic text', icon: ItalicIcon, title: 'Italic', id: 'doItalic' },
    { onMouseDown: linkHandleMouseDown, icon: LinkIcon, title: 'Link', id: 'doLinkOrImage' },
    {
      mark: '>',
      side: 'left',
      placeholder: 'Quote text',
      icon: QuoteIcon,
      title: 'Block quote',
      id: 'doBlockquote'
    },
    {
      mark: '    ',
      side: 'left',
      placeholder: 'Code here',
      icon: CodeIcon,
      title: 'Code snippet',
      id: 'doCode'
    },
    {
      // onMouseDown: (e, editor) => orderedHandleMouseDown(e, editor, 'ol'),
      icon: OrderedListIcon,
      title: 'Ordered List',
      id: 'doList',
      meta: true // use this to pass additional info - here numbered
    },
    {
      // onMouseDown: (e, editor) => orderedHandleMouseDown(e, editor, 'ul'),
      icon: UnorderedListIcon,
      title: 'Unordered List',
      id: 'doList'
    },
    {
      mark: '# ',
      side: 'left',
      placeholder: 'heading text',
      title: 'Heading',
      icon: HeadingIcon,
      id: 'doHeading'
    },
    { mark: ' ---- \n', side: 'left', title: 'Divider', icon: DividerIcon, id: 'doHorizontalRule' },
    { onMouseDown: handleUndo, title: 'Undo', icon: UndoIcon },
    { onMouseDown: handleRedo, title: 'Redo', icon: RedoIcon },
  ]

  if (isAssetPublic) {
    buttonsInfo.splice(5, 0, {
      icon: ImageIcon, title: 'Image',
      onMouseDown: () => {
        if (editorRef) {
          editorRef.current.focus()
        }
      },
      children: [
        { label: 'Choose From Uploads', default: true, action: () => imageHandleMouseDown(emptyEvent, editor) },
        { label: 'Upload New File(s)', action: () => uploadImageHandleMouseDown(emptyEvent, editor) },
      ]
    });
  }
  const handlePreventDefault = (e) => e.preventDefault();
  return (
    <div id={`cs-markdown-toolbar${postfix}`} className={cx(className, 'cs-markdown-toolbar')} onMouseDown={handlePreventDefault} data-testid="markdown-toolbar">
      <div
        className={cx(isPreview && 'cs-markdown-toolbar-disabled')}
        onMouseDown={handlePreventDefault}
      >
        {buttonsInfo.map((btn) => {
          if (Array.isArray(btn.children)) {
            return (
              <Dropdown key={btn.title} list={btn.children} type='click'>
                <Button btn={btn} key={String(btn.icon)} editor={editor} />
              </Dropdown>
            )
          } else {
            return <Button btn={btn} key={String(btn.icon)} editor={editor} />
          }
        }
        )}
      </div>
      {extra}
    </div>
  )
}, (prev, next) => {
  return prev.isPreview === next.isPreview;
})

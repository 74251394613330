//@ts-nocheck
import { jsx } from 'slate-hyperscript';
import { LIST_TYPES } from '../../../Element/list/utils';
import { cloneDeep, isEmpty } from 'lodash';
import { v4 } from 'uuid'
import { isInlineElement } from "../../../../utils/queries"

const spanningAttrs = (el: HTMLElement) => {
    const attrs = {}
    const rowSpan = parseInt(el.getAttribute('rowspan') ?? '1')
    const colSpan = parseInt(el.getAttribute('colspan') ?? '1')
    if (rowSpan > 1) attrs['rowSpan'] = rowSpan
    if (colSpan > 1) attrs['colSpan'] = colSpan

    return attrs
}

const emptyCell = (cellType, attrs = {}) => {
    return jsx('element', { type: cellType, attrs: { void: true, ...attrs } }, [{ text: '' }])
}

const addVoidCellsAndApplyAttributes = (rows) => {
    rows.forEach((row, currIndex) => {
        const cells = row.children

        cells.forEach((cell, cellIndex) => {
            if (!cell || !cell.attrs) return

            const { rowSpan, inducedRowSpan } = cell.attrs

            let span = rowSpan ?? inducedRowSpan ?? 0

            if (!span || span < 2) return
            const nextRow = rows[currIndex + 1]
            if (!nextRow) {
                delete cell?.attrs?.inducedRowSpan
                return
            }

            // set inducedRowSpan on cell in row at cellIndex
            span--
            nextRow?.children?.splice(cellIndex, 0,
                emptyCell('td',
                    (span > 1) ? { inducedRowSpan: span } : {}
                ))

            // Include next row in trgrp
            nextRow['attrs']['included'] = true

            // Make a new trgrp
            if (rowSpan) {
                row['attrs']['origin'] = true
            }

            delete cell?.['attrs']?.['inducedRowSpan']

        })
    })
}

const getTbodyChildren = (rows) => {
    const newTbodyChildren = rows.reduce((tBodyChildren, row, rowIndex) => {

        const { included, origin } = row.attrs
        const l = tBodyChildren.length

        if (included || origin) {

            if (origin && !included) {
                tBodyChildren.push(jsx('element', { type: 'trgrp' }, row))
            }
            if (included) {
                tBodyChildren[l - 1].children.push(row)

            }
            delete row['attrs']['included']
            delete row['attrs']['origin']
            return tBodyChildren
        }

        tBodyChildren.push(row)
        return tBodyChildren

    }, [])
    return newTbodyChildren
}

const ELEMENT_TAGS = {
    A: el => ({ type: 'a', attrs: { url: el.getAttribute('href') || "#" } }),
    BLOCKQUOTE: () => ({ type: 'blockquote' }),
    H1: () => ({ type: 'h1', attrs: {} }),
    H2: () => ({ type: 'h2', attrs: {} }),
    H3: () => ({ type: 'h3', attrs: {} }),
    H4: () => ({ type: 'h4', attrs: {} }),
    H5: () => ({ type: 'h5', attrs: {} }),
    H6: () => ({ type: 'h6', attrs: {} }),
    IMG: el => {
        return ({ type: 'img', attrs: { url: el.getAttribute('src') ? el.getAttribute('src') : el.getAttribute('srcset'), width: 100 } })
    },
    SPAN: (el) => { return { type: 'span', attrs: {} } },
    LI: () => ({ type: 'li', attrs: {} }),
    OL: () => ({ type: 'ol', attrs: {} }),
    P: () => ({ type: 'p', attrs: {} }),
    PRE: () => ({ type: 'code', attrs: {} }),
    UL: () => ({ type: 'ul', attrs: {} }),
    IFRAME: (el) => ({ type: 'embed', attrs: { url: el.getAttribute('src') } }),
    EMBEDS: (el) => ({ type: 'social-embeds', attrs: { src: el.getAttribute('src') } }),
    TABLE: (el) => ({ type: 'table', attrs: {} }),
    THEAD: (el) => ({ type: 'thead', attrs: {} }),
    TBODY: (el) => ({ type: 'tbody', attrs: {} }),
    TR: (el) => ({ type: 'tr', attrs: {} }),
    TD: (el) => ({ type: 'td', attrs: {} }),
    TH: (el) => ({ type: 'th', attrs: {} })
}

const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    //DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true }),
    MARK: () => ({ mark: true }),
    B: () => ({ bold: true })
}

const isInline = ["span", "a"]
const isVoid = ["img", "embed", "social-embeds"]

const generateId = () => v4().split('-').join('')

const generateFragment = (child: any) => {
    return { type: 'fragment', attrs: {}, uid: generateId(), children: [child] }
}
const traverseChildAndWarpChild = (children: Array<Object>, inlineElementAttrs) => {
    let inlineElementIndex: Array<number> = []
    let hasBlockElement = false
    let childrenCopy = cloneDeep(children)
    Array.from(children).forEach((child: any, index) => {
        if (child.hasOwnProperty('text')) {
            inlineElementIndex.push(index)
            return
        }
        if (child.hasOwnProperty('type')) {
            if (isInline.includes(child.type)) {
                if (child.type === 'reference') {
                    if (child.attrs && (child.attrs['display-type'] === 'inline' || child.attrs['display-type'] === 'link')) {
                        inlineElementIndex.push(index)
                    } else {
                        hasBlockElement = true
                    }
                } else {
                    inlineElementIndex.push(index)
                }
            } else {
                hasBlockElement = true
            }
        } else {
            childrenCopy[index] = jsx('text', {}, child)
            inlineElementIndex.push(index)
        }
    })
    return Array.from(children).map((child, index) => {
        if (isVoid.includes(child.type) && child.type === 'img') {
            child.attrs = {
                ...child.attrs,
                anchorLink: inlineElementAttrs.attrs.url
            }
            return jsx('element', { type: 'div', attrs: {} }, child)
        }
        if (inlineElementIndex.includes(index)) {
            return generateFragment(jsx("element", { ...inlineElementAttrs }, child))
        }
        return { ...child, children: traverseChildAndWarpChild(child?.children || [], inlineElementAttrs) }
    })
}

const traverseChildWithBlockAndInline = (children: Array<Object>) => {
    let inlineElementIndex: Array<number> = []
    let hasBlockElement = false
    let childrenCopy = cloneDeep(children)
    Array.from(children).forEach((child: any, index) => {
        if (child.hasOwnProperty('text')) {
            inlineElementIndex.push(index)
            return
        }
        if (child.hasOwnProperty('type')) {
            if (isInline.includes(child.type)) {
                if (child.type === "reference") {
                    if (child.attrs && (child.attrs['display-type'] === "inline" || child.attrs['display-type'] === "link")) {
                        inlineElementIndex.push(index)
                    } else {
                        hasBlockElement = true
                    }
                } else {
                    inlineElementIndex.push(index)
                }
            } else {
                hasBlockElement = true
            }
        } else {
            childrenCopy[index] = jsx("text", {}, child)
            inlineElementIndex.push(index)
        }
    })
    if (hasBlockElement && !isEmpty(inlineElementIndex)) {
        Array.from(inlineElementIndex).forEach((child) => {
            children[child] = generateFragment(childrenCopy[child])
        })
    }
    return children
}


export const deserialize = (el) => {

    if (el.nodeType === 3) {
        return el.textContent
    } else if (el.nodeType !== 1) {
        return null
    } else if (el.nodeName === 'BR') {
        return { text: '\n', break: true }
    } else if (el.nodeName === 'META') {
        return null
    }
    let { nodeName } = el
    let parent = el
    if (nodeName === 'IFRAME' && el.getAttribute('data-tweet-id') || el.getAttribute('src')?.includes('https://www.facebook.com') || el.getAttribute('data-instgrm-payload-id') || nodeName === 'IFRAME' && el.getAttribute('src')?.includes('https://www.tiktok.com')) {
        nodeName = 'EMBEDS'
    }
    if (
        nodeName === 'PRE' &&
        el.childNodes[0] &&
        el.childNodes[0].nodeName === 'CODE'
    ) {
        parent = el.childNodes[0]
    }
    let children = Array.from(parent.childNodes).map(deserialize).flat()
    children = children.filter((child: any) => child !== null)
    children = traverseChildWithBlockAndInline(children)

    if (el.nodeName === 'BODY') {
        if (LIST_TYPES.includes(children?.[0]?.type)) {
            children.unshift({ type: 'p', children: [{ text: '' }] })
        }
        let isAllInline = children.every((child) => isInlineElement(child))
        if (isAllInline) {
            children = jsx('fragment', {}, children)
            children = [{ type: 'fragment', attrs: {}, children: children }]
        }
        return jsx('fragment', {}, children)
    }

    if(el.nodeName === 'STYLE' && el.parentNode.nodeName === "GOOGLE-SHEETS-HTML-ORIGIN"){
        return null
    }

    if (ELEMENT_TAGS[nodeName]) {
        let attrs = ELEMENT_TAGS[nodeName](el)
        if (attrs['type'] === 'li' && el.childNodes[0]?.nodeName === 'P') {
            const text = deserialize(el.childNodes[0].childNodes[0])
            return jsx('element', attrs, text)
        }
        if (nodeName === 'IMG') {
            return jsx('element', attrs, [{ text: '' }])
        }

        if (nodeName === 'EMBEDS' && el.parentNode.getAttribute('cite')) {
            attrs.attrs.src = el.parentNode.getAttribute('cite')
        }

        if (nodeName === "TABLE") {
            let row = 0, col
            // get number of rows by looking at table_child children length
            row = el.querySelectorAll('TR').length
            const colElementLength = el.getElementsByTagName('COLGROUP')[0]?.children?.length ?? 0
            col = Math.max(...Array.from(el.getElementsByTagName('TR')).map(row => row.children.length), colElementLength)

            let colWidths: Array<any> = Array.from({ length: col }).fill(250)
            Array.from(el.childNodes).forEach((child: any) => {
                if (child?.nodeName === 'COLGROUP') {
                    let colGroupWidth = Array<number>(col).fill(250)
                    let totalWidth = parseFloat(child.getAttribute('data-width')) || col * 250
                    Array.from(child.children).forEach((child: any, index) => {
                        if (child?.nodeName === 'COL') {
                            let width = child?.style?.width ?? '250px'
                            if (width.substr(-1) === '%') {
                                colGroupWidth[index] = (parseFloat(width) * totalWidth) / 100
                            } else if (width.substr(-2) === 'px') {
                                colGroupWidth[index] = parseFloat(width)
                            }
                        }
                    })
                    colWidths = colGroupWidth
                }
            })

            let tableHead
            let tableBody

            children.forEach((tableChild) => {
                if (tableChild?.type === 'thead') {
                    tableHead = tableChild
                    return
                }
                if (tableChild?.type === 'tbody') {
                    tableBody = tableChild
                    return
                }
            });

            let disabledCols = [...tableHead?.attrs?.disabledCols ?? [], ...tableBody?.attrs?.disabledCols ?? []]
            delete tableHead?.attrs?.disabledCols
            delete tableBody?.attrs?.disabledCols

            attrs = {
                ...attrs,
                attrs: {
                    ...attrs['attrs'],
                    rows: row,
                    cols: col,
                    colWidths: colWidths,
                    ...(disabledCols.length && { disabledCols: Array.from(new Set(disabledCols)) })
                }
            }
        }

        if (["THEAD", "TBODY"].includes(nodeName)) {
            const rows = children
            const disabledCols = rows.flatMap(row => {
                const { disabledCols } = row.attrs
                delete row['attrs']['disabledCols']
                return disabledCols ?? []
            })
            attrs.attrs['disabledCols'] = disabledCols
        }

        if (nodeName === "TBODY") {

            addVoidCellsAndApplyAttributes(children)

            children = getTbodyChildren(children)
        }

        if (nodeName === "TR") {
            const cells = children.filter(child => ['th', 'td'].includes(child.type))

            const disabledCols = cells.flatMap((cell, cellIndex) => {
                let { colSpan } = cell.attrs
                if (!colSpan) return []
                colSpan = parseInt(colSpan)
                return Array(colSpan).fill(0).map((_, i) => cellIndex + i)
            })

            if (disabledCols.length)
                attrs.attrs['disabledCols'] = disabledCols
        }

        if (nodeName === "TD" || nodeName === "TH") {
            let attr
            let textFormat = {}
            if (el.style?.['font-weight'] === 'bold') {
                textFormat = { ...textFormat, bold: true }
            }
            if (el.style?.['font-style'] === 'italic') {
                textFormat = { ...textFormat, italic: true }
            }

            if (el.style?.['text-decoration'] === 'underline') {
                textFormat = { ...textFormat, underline: true }
            }

            if (el.style?.['text-decoration'] === 'line-through') {
                textFormat = { ...textFormat, strikethrough: true }
            }

            const spannedAttrs = spanningAttrs(el)

            if (!isEmpty(spannedAttrs)) {
                attrs = {
                    ...attrs,
                    attrs: {
                        ...attrs['attrs'],
                        ...spannedAttrs,
                        "redactor-attributes": spannedAttrs
                    },
                }
            }

            children = children.map((child) => {
                if (typeof child === 'string' || child.nodeType === 3) return jsx('text', { ...textFormat }, child)
                else if (child.text !== undefined) {
                    const newChild = {
                        ...child,
                        attrs: { ...child.attrs },
                        ...textFormat
                    }

                    return newChild
                } else {
                    return child
                }
            })
            const { colSpan = 1, rowSpan } = attrs?.['attrs']

            const tableCell = [
                jsx('element', attrs, children),
                ...Array(colSpan - 1)
                    .fill(0)
                    .map((_) => emptyCell(nodeName.toLowerCase(), rowSpan ? { inducedRowSpan: rowSpan } : {}))
            ]
            return tableCell
        }

        if (nodeName === "SPAN") {
            Array.from(children).forEach((child: any) => {
                if (child?.type) {
                    if (!isInline.includes(child.type) && !isVoid.includes(child.type)) {
                        attrs = {
                            type: 'div',
                            attrs: {
                                orgType: 'span'
                            }
                        }
                    }
                }
            })
        }

        if (nodeName === 'A') {
            let childrenText = Array.from(children).find((child) => {
                return typeof child === 'string' || child.hasOwnProperty('text')
            })
            if (children.length > 0 && childrenText) {
                return jsx('element', attrs, children)
            }
            return jsx('element', { type: 'div', attrs: {} }, traverseChildAndWarpChild(children, attrs))
        }
        if (children.length === 0) {
            children = [{ text: '' }]
        }
        return jsx('element', attrs, children)
    }

    if (nodeName === 'INPUT') {
        if (el.getAttribute('type') === 'checkbox') {
            let checklistText: any = ''
            if (el.nextSibling?.nodeName === 'LABEL') {
                checklistText = Array.from(el.nextSibling.childNodes).map(child => {
                    return deserialize(child)
                })

            } else if (el.nextSibling?.nextSibling?.nodeName === 'LABEL') {
                checklistText = Array.from(el.nextSibling?.nextSibling?.childNodes).map(child => {
                    return deserialize(child)
                })
            }
            return jsx('element', { type: 'check-list' }, checklistText)
        }
    }

    if (nodeName === 'LABEL') {
        if (
            el.previousSibling?.nodeName === 'INPUT' &&
            el.previousSibling?.getAttribute('type') ===
            'checkbox'
        ) {
            return null
        } else if (
            el.previousSibling?.previousSibling?.nodeName === 'INPUT' &&
            el.previousSibling?.previousSibling?.getAttribute('type') ===
            'checkbox'
        ) {
            return null
        }
    }

    if (el.getAttribute('data-slate-fragment')) {
        const JsonString = decodeURIComponent(atob(el.getAttribute('data-slate-fragment')));
        let Json = JSON.parse(JsonString)[0];
        while (Json?.children[0]['children']) {
            Json = Json['children'][0]
            if (Json.type === "td") {
                Json = {
                    type: 'p',
                    attrs: Json.attrs,
                    children: Json.children
                }
            }
        }
        delete Json['id'];
        return Json;
    }

    if (TEXT_TAGS[nodeName]) {
        const attrs = TEXT_TAGS[nodeName](el)

        return children.map(child => {
            if (typeof child === 'string')
                return jsx('text', attrs, child)
            return child
        })

    }

    return children
}

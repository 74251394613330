//@ts-nocheck
import { Editor, Transforms, Path } from 'slate'
import isHotkey from 'is-hotkey'

export const customCodeBlockKeydown = (event, editor) => {
  if (event.key === 'Enter') {
    event.preventDefault()
    const { selection } = editor;
    if (!selection) return
    const [code] = Editor.nodes(editor, {
      match: n => n.type === 'code'
    })
    if (!code) {
      editor.insertBreak()
      return
    }
    editor.insertText('\n')
  }
}

export const handleExitCodeBlock = (event, editor) => {
  const { selection } = editor
  if (!selection) return
  if (!isHotkey('shift+enter')) return
  event.preventDefault()
  const [code] = Editor.nodes(editor, {
    match: (n: any) => n.type === 'code'
  })
  if (!code) {
    editor.insertBreak()
    return
  }
  const nextPath = Path.next(code[1])
  const newNode = { type: 'p', children: [{ text: '' }] }
  Transforms.insertNodes(editor, newNode, { at: nextPath, select: true })
}


//@ts-nocheck
import React, { memo } from 'react'

import { Button } from '../Button'
import { TriggerButton } from '../Button/TriggerButton'

export const MemoToolbarButton = memo((props) => {
  const { keyData, element, toolbarType, DefaultComponent, closeHoveringToolbar } = props;
  const { handleMouseDown, IngressComponent, iconName, title, toolbar, shortcut, hoveringToolbarOptions: { autoWidth } = {} } = element;

  if (toolbar?.RenderComponent) {
    const RenderComponent = toolbar.RenderComponent

    return (
      <div

      >
        <RenderComponent key={keyData} />
      </div>
    )
  }

  if (handleMouseDown) {
    return (
      <Button
        disabled={false}
        onMouseDown={(e) => {
          e.preventDefault()
          handleMouseDown() // put editor here
        }}
        format={keyData}
        title={title}
        autoWidth={autoWidth}
      >
        {iconName || 'icon-here'}
      </Button>
    )
  }
  if (IngressComponent) {
    return (
      <TriggerButton
        shortcut={shortcut}
        key={keyData}
        value={element}
        format={keyData}
        toolbarType={toolbarType}
        title={title}
        closeHoveringToolbar={closeHoveringToolbar}
      />
    )
  } else if (iconName) {
    return (
      <DefaultComponent
        key={keyData}
        element={element}
        keyData={keyData}
        toolbarType={toolbarType}
      />
    )
  } else return null
})

export const ToolbarButton = (props) => {

  return <MemoToolbarButton  {...props} />
}

import React from 'react'
import { useSelected, useSlate } from 'slate-react'
import { Registry } from '@react-registry'
//@ts-ignore
import styles from '../style.module.css'
import { cx } from '@emotion/css'
export * from './deserializer'

const TH = (props) => {
  const { attributes, children } = props
  const editor = useSlate()
  const selected = useSelected()
  const { colSpan } = props.element.attrs
  const cellStyles = {}
  if (colSpan) {
    cellStyles['gridColumn'] = `span ${colSpan}`
  }
  if (editor.isVoid(props.element)) {
    return (
      <div tabIndex={-1} contentEditable="false" style={{ userSelect: 'none', position: 'absolute' }}>
        {props.children}
      </div>
    )
  }
  return (
    <div
      data-testid="th"
      className={cx(styles['div-table-cell-heading'], { [styles['div-table-cell-focus']]: selected })}
      data-type="table-cell-heading"
      style={cellStyles}>
      <div {...attributes} style={{ overflow: 'hidden', flexWrap: 'wrap', width: '100%' }}>
        {children}
      </div>
    </div>
  )
}

export default TH

Registry.register(
  {
    Component: TH,
    isContentStackElement: true,
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true,
    },
    toolbar: {
      inHoveringToolbar: false
    },
  },
  { id: 'th', registry: 'v2.element' }
)

import React, { useContext, memo } from 'react'
import Tooltip from '../../Tooltip'
import { useSlate } from 'slate-react'
import { css, cx } from '@emotion/css'

import { isMarkActive, isBlockActive, toggleBlock, toggleMark } from './buttonUtils'
import { EditorProvider } from '../../ContextProvider/EditorProvider'
import { formatShortcut } from '../../../utils/shortcuts/formatShortcut'
//@ts-ignore
import styles from './style.module.css'
import { IDropdownShortcutElementProps } from '../../../utils/types'

const config = {
  placement: 'bottom',
  arrow: false,
  offset: [0, 5],
  delay: [300, 0],
  duration: [0, 0],
  hideOnClick: true,
  theme: 'small'
}

export function DropdownShortcutElement(props: IDropdownShortcutElementProps) {
  const shortcut = formatShortcut(props.shortcut)
  if (shortcut)
    return <span data-test-id={props.shortcut} className={styles['dropdown-shortcut']}>{formatShortcut(props.shortcut)}</span>
  else
    return null

}

export declare interface ButtonProps extends React.AllHTMLAttributes<HTMLSpanElement> {
  active: any
  format: any
  shortcut: any
  ref: any,
  dropdown?: boolean,
  isActiveIcon?: boolean,
  activeTitle?: string,
  autoWidth?: boolean
}

export const Button = memo(React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      disabled,
      format,
      shortcut,
      title,
      children,
      dropdown = false,
      isActiveIcon = false,
      activeTitle = '',
      autoWidth,
      ...props
    }: Partial<ButtonProps>,
    ref: React.Ref<HTMLSpanElement>
  ) => {
    return (
      <Tooltip
        config={config}
        content={
          <span className='font-small'>
            {(active && isActiveIcon) ? activeTitle : (title || format)}{' '}
            {shortcut?.key !== undefined && (
              <span
                className={styles['toolbar-shortcut']}>
                {formatShortcut(shortcut.key)}
              </span>
            )}
          </span>
        }>
        <span
          {...props}
          ref={ref}
          data-icon={format}
          className={cx(
            className,
            styles['Button'],
            { [styles['Button--width-auto']]: autoWidth },
            { [styles['Button--active']]: active && !disabled && !isActiveIcon },
            { [styles['Button--hoverable']]: !((active && !isActiveIcon) || disabled) },
            { [styles['Button--disable']]: disabled },
            { [styles['Button-dropdown']]: dropdown }
          )}>
          <div className={styles['flex-center']}>
            {children}
          </div>
        </span>
      </Tooltip>
    )
  }
), (prev, next) => {
  return prev.disabled === next.disabled && prev.active === next.active
})



export const BlockButton = ({ format, children, title, shortcut, autoWidth }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event && event.preventDefault()
        toggleBlock(editor, format)
      }}
      format={format}
      title={title}
      shortcut={shortcut}
      autoWidth={autoWidth}>
      {children}
    </Button>
  )
}

export const DropdownMenu = ({ format, children }) => {
  const editor = useSlate()
  return (
    <span
      onMouseDown={(event) => {
        event && event.preventDefault()
        toggleBlock(editor, format)
      }}>
      {children}
    </span>
  )
}

export const MarkButton = ({ format, children, shortcut, title }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event && event.preventDefault();
        toggleMark(editor, format)
      }}
      format={format}
      title={title}
      shortcut={shortcut}>
      {children}
    </Button>
  )
}

export const DropdownMenuItem = (props) => {
  const { icon, title, shortcut, format } = props

  return (
    <React.Fragment>
      <span
        data-icon={format}
        className={cx(
          css`
            display: flex;
            align-items: center;          
          `
        )}
      >
        {icon && (
          <span
            className={cx(css`
              width: 2rem;
              height: 2rem;
              display: flex;
              background: #F7F9FC;
              justify-content: center;
              align-items: center;
              margin-right: 1rem;
              border-radius: 5px
            `
            )}>
            {icon}
          </span>
        )}
        <span>{title}</span>
      </span>
      {shortcut?.key !== undefined && <DropdownShortcutElement shortcut={shortcut.key} />}
    </React.Fragment>
  )
}

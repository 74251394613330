import React, { useContext, useEffect, useRef } from 'react'
import Icon from '../Icon2/Icon'
import Tooltip from '../Tooltip/Tooltip'

import { localizedInLabel, languageLabel } from './util/entries'
import { iconName } from './util/constants'
import { getOperatorTextContent, numericTypes } from './util/common'
import { getOS } from './util/helpers'
import { SearchContext } from './searchContext'
import Connector from './Connector';
import SearchTooltip from './SearchTooltip'
import { truncate } from './util/helpers'

const RenderQueries = ({ queryArray, depth = 1 }: any) => {

  const { textContent } = useContext(SearchContext)
  queryArray = queryArray.filter((element) => !element.isBlankReq)
  return queryArray.map((query, queryIndex) => {
    const { key, operator, value, queryType = '' } = query
    const isLastField = queryIndex === queryArray.length - 1;

    const isCurrLang = queryType === languageLabel.queryType;
    const isPrevLanguage = queryIndex > 0 && queryArray[queryIndex - 1].queryType === languageLabel.queryType;
    const isCurrLocalization = queryType === localizedInLabel.queryType && isPrevLanguage;
    const frontConnectorType = isCurrLocalization ? 'and' : null;


    return key.map((queryKey, index) => {
      const operatorForKey = operator[index]
      const valueForKey = (value[index] && value[index]) || {}

      let tooltipContent = getOperatorTextContent({
        operatorKey: operatorForKey,
        queryType,
        textContent
      })

      let addClass = '';

      if (isCurrLang) {
        addClass = "AdvancedSearch__matched-criteria-count-mid"
      }
      if (depth === 4 && isLastField) {
        addClass = 'AdvancedSearch__matched-criteria-count-mid';
      }
      else if (depth > 4) {
        addClass = 'AdvancedSearch__matched-criteria-count';
      }

      const { truncatedText, isOverflow } = truncate(valueForKey.label || '');

      return (
        <div className={`AdvancedSearch__matched-criteria ${addClass}`} key={queryIndex}>
          <Connector depth={depth} type={frontConnectorType} />
          {/* {queryArray.length > 1 ? <Connector depth={depth} type={frontConnectorType} /> : null} */}
          <span className="AdvancedSearch__matched-type">{queryKey.label}</span>
          <SearchTooltip content={tooltipContent} position="top">
            <span className="AdvancedSearch__matched-closeEditDropdownoperator">
              <Icon icon={operatorForKey.icon}></Icon>
            </span>
          </SearchTooltip>

          <span className="AdvancedSearch__matched-string">
            {isOverflow
              ? <SearchTooltip content={valueForKey.label ? query.queryType !== 'termLabel' ? valueForKey.label : valueForKey.label + '(' + valueForKey.taxonomy + ')' : ''} position="top">
                {truncatedText}
              </SearchTooltip>
              : valueForKey.label ? query.queryType !== 'termLabel' ? valueForKey.label : valueForKey.label + '(' + valueForKey.taxonomy + ')' : ''
            }
          </span>

          {/* <div className="AdvancedSearch__dropdown">
            <Icon icon={iconName.DropdownDark} className="Dropdown__icon" />
          </div> */}

          {query.queryType !== 'termLabel' ? (
            <div className="AdvancedSearch__dropdown">
              <Icon icon={iconName.DropdownDark} className="Dropdown__icon" />
            </div>
          ) : (<div className='w-6'></div>)}

          {/* <Connector depth={depth} /> */}
          {/* {queryArray.length > 1 ? <Connector depth={depth} /> : null} */}
        </div>
      )
    })
  })
}

const RenderAdvanceQueryObject = ({ advanceQueryObject, depth, isFilters }: any) => {
  const queryType = advanceQueryObject.queryType === 'matchAny' ? 'Match : Any' : 'Match : All'

  const currentDepth = isFilters ? 1 : depth;

  let addClass = '';

  if (depth > 4 && !isFilters) {
    addClass = 'AdvancedSearch__matched-unit-count';
  }

  return (
    <>
      <div className={`AdvancedSearch__matched-unit ${addClass}`}>
        <Connector depth={currentDepth} />
        {queryType}
        {/* <div className="AdvancedSearch__dropdown">
          <Icon icon={iconName.DropdownDark} className="Dropdown__icon"></Icon>
        </div> */}


        {advanceQueryObject.queryType !== 'termLabel' ? (
          <div className="AdvancedSearch__dropdown">
            <Icon icon={iconName.DropdownDark} className="Dropdown__icon" />
          </div>
        ) : (<div className='w-6'></div>)}
      </div>
      <RenderQueries queryArray={advanceQueryObject.queryArray} depth={currentDepth} />
    </>
  )
}

const getLinearArrayOfQueires = (advanceQueryByFilters, arrayOfComponent, isFilters, depth = 1,) => {
  advanceQueryByFilters.map((object) => {
    arrayOfComponent.push(<RenderAdvanceQueryObject key={object.cursorY} advanceQueryObject={object} depth={depth} isFilters={isFilters} />)
    if (object.children.length) {
      getLinearArrayOfQueires(object.children, arrayOfComponent, isFilters, depth + 1)
    }
  })

  return arrayOfComponent
}

const SearchBar = (props) => {

  const scrollRef = useRef(null);

  const advanceQueries = props.advanceQueryArray.length ? props.advanceQueryArray : props.advanceQueryByFilters
  // const arrayOfComponents = getLinearArrayOfQueires(advanceQueries, [])
  // console.log("SearchBar -> arrayOfComponents", arrayOfComponents)

  const isFilters = props.advanceQueryArray.length === 0;

  const arrayOfComponents = getLinearArrayOfQueires(advanceQueries, [], isFilters)
  const filteredQueryArray = props.queryArray.filter((element) => !element.isBlankReq)
  const isQueriesExist = filteredQueryArray.length || props.advanceQueryByFilters.length || props.advanceQueryArray.length

  useEffect(() => {
    scrollRef.current.scrollLeft = scrollRef.current.scrollWidth;
  }, [props.queryArray.length, advanceQueries.length, arrayOfComponents])

  const ModuleDropdown = () =>
    <div className="AdvancedSearch__category">
      <button className="AdvancedSearch__category-expander flex-v-center relative">
        <span>{props.selectedOption.label}</span>
        <Icon icon={iconName.DropdownDark} className="AdvancedSearch__category-icon"></Icon>
        <Connector depth={1} />
      </button>
    </div>


  return (
    <div className="AdvancedSearch__area AdvancedSearch__area--opener"
      id='AdvanceSearchBar'
      tabIndex={0}
      onKeyDown={(e: any) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault()
          props.setSearchVisibility(true);
        }
      }}
    >
      <div className="AdvancedSearch__area__wrapper" onClick={() => props.setSearchVisibility(true)}>
        <div className="AdvancedSearch__main">
          <Icon icon="SearchSmall" className="AdvancedSearch__search-icon"></Icon>
          {(filteredQueryArray.length > 0 || advanceQueries.length > 0) && (
            <ModuleDropdown />
          )}

          <div className="AdvancedSearch__query-wrapper AdvancedSearch__query-wrapper--no-wrap" ref={scrollRef}>
            {props.searchCase === 'basicQuery' && filteredQueryArray.length > 0 && (
              <RenderQueries queryArray={props.queryArray} />
            )}

            {advanceQueries.length > 0 && <div className="flex">{arrayOfComponents}</div>}


            <div className="AdvancedSearch__current-query">
              <div className="AdvancedSearch__input-wrapper">
                {/* <input type="text" className="AdvancedSearch__query-input" /> */}

                {!isQueriesExist && (
                  <>
                    <input type="text" tabIndex={-1} className="AdvancedSearch__query-input" aria-label="input-search-query" />
                    <span className="AdvancedSearch__query-autocomplete">
                      {/* {props.textContent.placeholders.search_bar} */}
                      Search {props.selectedOption.label}
                      <span className='font-regular font-size-small ml-3'>{getOS() === 'macOS' ? `(⌘K)` : `(Ctrl+K)`}</span>
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* <Icon icon={iconName.DropdownDark} className="AdvancedSearch__search-indicator" /> */}
        </div>
      </div>
      <div className=" flex-v-center AdvancedSearch__right-actions">
        {props.savedViews}
        {isQueriesExist ? (
          <div>
            <SearchTooltip content={props.textContent.tooltips.clear_search} position="top">
              <button className="AdvancedSearch__remove-query" onClick={props.clearAndCloseSearch}>
                <Icon icon={iconName.DeleteQueryBuilder}></Icon>
              </button>
            </SearchTooltip>
          </div>
        ) : (
          <div className="AdvancedSearch__chevron" onClick={() => props.setSearchVisibility(true)}>
            <Icon icon={iconName.DropdownDark} />
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchBar
import React, { useState } from 'react'

import Field from '../../Field/Field'
import FieldLabel from '../../FieldLabel/FieldLabel'
import Help from '../../Help/Help'
import InstructionText from '../../InstructionText/InstructionText'
import TextInput from '../../TextInput/TextInput'
import ToggleSwitch from '../../ToggleSwitch/ToggleSwitch'
import { fieldUidRegex, getContentTypeFieldDataTestId, CONTENT_TYPE_FIELD_ACTION_IDS } from '../helper'

const ExperienceContainer = (props: any) => {
  const { onChange, uniqueKey, schema } = props

  const [initialSchema, setSchema] = useState(schema)
  const [error, setError] = useState(fieldUidRegex(schema.uid, schema))
  const { display_name, uid, field_metadata } = schema

  const onchange = () => {
    onChange && onChange(schema)
    setSchema({ ...schema })
  }

  const onDisplayNameChange = (event: any) => {
    schema.display_name = event.target.value

    if (schema.display_name && schema.newfield) {
      schema.uid = schema.display_name
        .trim()
        .replace(/[^A-Z0-9]+/gi, '_')
        .toLowerCase()
      schema.error_details = {
        ...schema.error_details,
        uid: ''
      }
    }

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.display_name.length) {
      schema.error = true
      schema.error_details = {
        ...schema.error_details,
        display_name: 'Display Name cannot be empty'
      }
    } else {
      schema.error = false
      schema.error_details = {
        ...schema.error_details,
        display_name: ''
      }
    }
    onchange()
  }
  const onUidChange = (event: any) => {
    setError(fieldUidRegex(event.target?.value?.trim(), schema))

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.uid.length) {
      schema.error = true
      schema.error_details = {
        ...schema.error_details,
        uid: 'uid cannot be empty'
      }
    } else {
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
      schema.error_details = {
        ...schema.error_details,
        uid: ''
      }
    }
    onchange()
  }
  const onInstructionChange = (event: any) => {
    field_metadata.instruction = event.target.value
    onchange()
  }
  const onHelpTextChange = (event: any) => {
    field_metadata.description = event.target.value
    onchange()
  }

  const onEnableDefaultVariationToggle = () => {
    field_metadata.enableDefaultVariation = !field_metadata.enableDefaultVariation
    onchange()
  }

  return (
    <>
      <div className="FormFields">
        <div className="FormFields__column">
          <Field>
            <FieldLabel required htmlFor="displayName">
              {props.t('content_type.save_content_type.body.fields.experience_container.info_modal.display_name.label')}
            </FieldLabel>
            <TextInput
              required
              autoFocus
              name={`${uniqueKey}.displayName`}
              value={display_name}
              onChange={onDisplayNameChange}
              error={schema.error && schema.error_details.display_name ? true : false}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              testId={getContentTypeFieldDataTestId(schema, CONTENT_TYPE_FIELD_ACTION_IDS.DISPLAY_NAME_INPUT)}
            />
          </Field>
          <Field>
            <FieldLabel htmlFor="helpText">
              {props.t('content_type.save_content_type.body.fields.experience_container.info_modal.help_text.label')}
            </FieldLabel>
            <Help
              text={props.t(
                'content_type.save_content_type.body.fields.experience_container.info_modal.help_text.help_text'
              )}
            />
            <TextInput
              name={`${uniqueKey}.helpText`}
              value={field_metadata.description || ''}
              onChange={onHelpTextChange}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              testId={getContentTypeFieldDataTestId(schema, CONTENT_TYPE_FIELD_ACTION_IDS.HELP_TEXT_INPUT)}
            />
          </Field>
        </div>
        <div className="FormFields__column">
          <Field>
            <FieldLabel required htmlFor="uid">
              {props.t('content_type.save_content_type.body.fields.experience_container.info_modal.uid.label')}
            </FieldLabel>
            <Help
              text={props.t('content_type.save_content_type.body.fields.experience_container.info_modal.uid.help_text')}
            />
            <TextInput
              required
              name={`${uniqueKey}.uid`}
              value={uid}
              onChange={onUidChange}
              error={(schema.error && schema.error_details.uid) || error ? true : false}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              testId={getContentTypeFieldDataTestId(schema, CONTENT_TYPE_FIELD_ACTION_IDS.UID_INPUT)}
            />
          </Field>
          <Field>
            <FieldLabel htmlFor="instruction">
              {props.t(
                'content_type.save_content_type.body.fields.experience_container.info_modal.instruction_value.label'
              )}
            </FieldLabel>
            <Help
              text={props.t(
                'content_type.save_content_type.body.fields.experience_container.info_modal.instruction_value.help_text'
              )}
            />
            <TextInput
              name={`${uniqueKey}.instruction`}
              value={field_metadata.placeholder || ''}
              onChange={onInstructionChange}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              testId={getContentTypeFieldDataTestId(schema, CONTENT_TYPE_FIELD_ACTION_IDS.INSTRUCTION_INPUT)}

            />
          </Field>
        </div>
      </div>
      <div className="flex-column">
        <Field className="ml-10 flex-v-center col-gap-10">
          <ToggleSwitch
            name="enable-default-variation-toggle"
            checked={!!field_metadata.enableDefaultVariation}
            onChange={onEnableDefaultVariationToggle}
            testId={getContentTypeFieldDataTestId(schema, CONTENT_TYPE_FIELD_ACTION_IDS.DEFAULT_VARIATION_TOGGLE)}
          />
          <span className="font-color-base font-size-large">
            {props.t(
              'content_type.save_content_type.body.fields.experience_container.info_modal.default_variation.include_default_variation'
            )}
          </span>
        </Field>
        <InstructionText>
          {props.t(
            'content_type.save_content_type.body.fields.experience_container.info_modal.default_variation.default_variation_instruction_text'
          )}
        </InstructionText>
      </div>
    </>
  )
}

export default React.memo(ExperienceContainer)

import React, { useState, useMemo, useCallback, version } from 'react'
import cn from 'classnames'

import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc'

import Icon from '../Icon2/Icon'
import Line from '../Line/Line'
import Button from '../Button/Button'
import { Truncate } from '../Truncate/Truncate'

import './Accordion.css'

export type accordionProps = {
  accordionLock?: boolean
  accordionData?: string
  addLeftComponent?: React.ReactNode
  addRightComponent?: React.ReactNode
  children?: React.ReactNode
  className?: string
  noChevron?: boolean
  preRender?: boolean
  renderExpanded?: boolean
  setTitleOnHide?: any | React.ReactNode
  title: string | React.ReactNode
  accordionDataCount?: number
  hasBackgroundColor?: boolean
  /**
   * Actions List of {component: ReactNode, onClick: () => void}
   */
  actions?: AccordionActionsList
  isAccordionOpen?: boolean | undefined
  onTitleClick?: () => void
  errorMessage?: string
  dashedLineVisibility?: 'hover' | 'always'
  accordionTitlePropagation?: boolean
  testId?: string
  version?: string
  isAccordionParent?: boolean
  stickyIcon?: React.ElementType
  titleIcon?: React.ReactNode
  accordionDescription?: React.ReactNode
  isContainerization?: Boolean
}

type accordionTitleProps = {
  accordionLock?: boolean
  addLeftComponent?: React.ReactNode
  addRightComponent?: React.ReactNode
  accordionDataCount: number
  noChevron?: boolean
  setTitleOnHide: any | React.ReactNode | undefined
  title: string | React.ReactNode
  toggle: Function
  toggleStat?: boolean
  actions?: AccordionActionsList
  onClick?: () => void
  error?: string
  hasBackgroundColor?: boolean
  dashedLineVisibility?: 'hover' | 'always'
  accordionTitlePropagation?: boolean
  version?: string
  isAccordionParent?: boolean
  titleIcon?: React.ReactNode
  stickyIcon?: React.ReactNode
  accordionDescription?: React.ReactNode
  isContainerization?: Boolean
}

type accordionDataProps = {
  accordionData: string | React.ReactNode
  version?: string
  isAccordionParent?: boolean
  isContainerization?: Boolean
  hasBackgroundColor?: boolean

}
type AccordionAction = {
  component: React.ReactNode
  onClick?: () => void
  actionClassName?: string
  version?: string
}

export type AccordionActionsList = AccordionAction[]

const getAccordionTitle = (toggleStat, setTitleOnHide, title) => {
  if (!toggleStat) {
    return setTitleOnHide ? setTitleOnHide : title
  }
  return title
}

export const NewVersionChevron = ({ handleToggle, toggleStat, accordionLock, version, isContainerization }) => {

  const handleOnKeyDown = (e: any) => {
    if (e.key === 'Enter' || e.key === ' ' || (toggleStat === false && e.key === 'Tab')) {
      e.preventDefault()
      e.stopPropagation()
      handleToggle(e)
    }
  }
  if (version === 'v2' && isContainerization) {
    return (
      <Button onlyIcon={true} onlyIconHoverColor="primary" disabled={accordionLock} onClick={handleToggle} onKeyDown={handleOnKeyDown} version="v2" icon="v2-CaretDown" iconProps={{ className: `Accordion-v2__heading__toggle ${toggleStat === true ? 'rotate-180' : ''}` }} />
    )
  }

  return (
    <div
      onClick={handleToggle}
      onKeyDown={handleOnKeyDown}
      tabIndex={0}
      className={`${version !== 'v2' ? 'ml-8' : ''} ${accordionLock ? 'Accordion__heading__toggle-disabled' : 'Accordion__heading__toggle'}`}>
      {version === 'v2' ?
        <Icon
          size={'small'}
          version={'v2'}
          className={`${toggleStat === true ? 'rotate-180' : ''}`}
          icon={'CaretDown'}
        /> :
        <Icon className={toggleStat === true ? 'rotate-180' : ''} icon={'ChevronDownMedium'} />
      }
    </div>
  )
}

const AccordionTitle = (props: accordionTitleProps) => {
  // const noDefaultIcon = props.noDefaultIcon !== undefined ? props.noDefaultIcon : false;

  const dashedLineClassname = cn('Accordion__heading__line', {
    visible: props.dashedLineVisibility === 'always'
  })

  const { accordionTitlePropagation } = props || { accordionTitlePropagation: true }

  const accordionHeadingClassname = useCallback(() => {
    let className = 'Accordion__heading'
    if (props.version === 'v2') {
      if (props.isAccordionParent && props.isContainerization) {
        className = className + ' Accordion-v2__heading-parent'
      }
      if (!props.toggleStat) {
        className = className + ' Accordion-v2__heading Accordion__heading__collapsed'
      } else {
        className = className + ' Accordion-v2__heading Accordion__heading__open'
      }
    }
    if (props.accordionLock) {
      className = className + ' Accordion__heading-disabled'
    }
    return className
  }, [props.version, props.isAccordionParent, props.toggleStat, props.accordionLock, props.isContainerization])

  const decriptionClassName = useCallback(() => {
    let className = ''
    // if (props.version === 'v2') {
    //   className = 'Accordion__description'
    //   if (!props.isAccordionParent) {
    //     className = className + ' Accordion__description-child'
    //   } else {
    //     if (props.toggleStat) {
    //       className = className + ' Accordion__description-parent-open'
    //     } else {
    //       className = className + ' Accordion__description-parent-close'
    //     }
    //   }
    // }
    if (props.version === 'v2') {
      className = 'Accordion__description'
      // if (!props.isAccordionParent) {
      //   className = className + ' Accordion__description-child'
      // } else {
      if (props.toggleStat) {
        // className = className + ' Accordion__description-parent-open'
        className = className + ' Accordion__description-open'
      } else {
        // className = className + ' Accordion__description-parent-close'
        className = className + ' Accordion__description-close'
      }
      // }
    }
    return className
  }, [props.version, props.isAccordionParent, props.toggleStat, props.accordionLock])

  return useMemo(() => {
    const handleToggle = (e: any) => {
      e.preventDefault()
      e.stopPropagation()
      if (!props.accordionLock) {
        if (props.onClick) props.onClick()
        props.toggle()
      }
    }

    const RightSideIcons = () => {
      return (
        <>
          <div></div>
          {props.addRightComponent ? (
            props.addRightComponent
          ) : (
            <div className={props.version === 'v2' ? 'flex-v-center' : 'flex-justify flex-v-center w-100'}>
              {/* {!props.noChevron && !(props.hasBackgroundColor === true && props.version === 'v2' && props.isContainerization === false) && ( */}
              {!props.noChevron && props.version !== 'v2' && (
                <NewVersionChevron
                  handleToggle={handleToggle}
                  accordionLock={props.accordionLock}
                  toggleStat={props.toggleStat}
                  version={props.version}
                  isContainerization={props.isContainerization}
                />
              )}
              <div className={`flex ${props.version === 'v1' ? 'flex-grow-1' : ''}`}>
                {props.error && <div className="Accordion__heading__errorMsg">{props.error}</div>}
                {props.version !== 'v2' && (
                  <span className={dashedLineClassname}>
                    <Line type="dashed" />
                  </span>
                )}
              </div>
              {/* {props.version === 'v2' && props.hasBackgroundColor === true && props.isContainerization === false ? */}
              <div className={`flex ${props.version !== 'v2' ? 'mr-15' : 'flex-v-center'}`}>
                {props.actions && <AccordionActions version={props.version} actions={props.actions} />}
                {props.version === 'v2' && props.stickyIcon && props.stickyIcon}
              </div>
              {props.version === 'v2' && (props.actions || props.stickyIcon) ? <Icon version='v2' icon='SeperatorLine' className='Accordion__heading__seperator' /> : null}
              {!props.noChevron && props.version === 'v2' ?
                <NewVersionChevron
                  handleToggle={handleToggle}
                  accordionLock={props.accordionLock}
                  toggleStat={props.toggleStat}
                  version={props.version}
                  isContainerization={props.isContainerization}
                />
                : null
              }
            </div>
          )}
        </>
      )
    }

    return (
      <>
        <div className={accordionHeadingClassname()} onClick={handleToggle}>
          <div className={`flex-v-center ${props.version === 'v2' ? props.isContainerization ? 'w-70' : 'w-80' : ''}`}>
            {props.addLeftComponent ? props.addLeftComponent : null}
            {props.version === 'v2' && props.titleIcon && (
              <div className="Accordion__heading__title-icon">{props.titleIcon}</div>
            )}
            <div className='flex-v-center w-100'>
              <div
                title={
                  typeof props.title == 'string' && props.version === 'v1'
                    ? getAccordionTitle(props.toggleStat, props.setTitleOnHide, props.title)
                    : null
                }
                className={`Accordion__heading__title ${props.version === 'v2'
                  ? props.isAccordionParent
                    ? 'Accordion__heading__v2__title-parent'
                    : 'Accordion__heading__v2__title'
                  : ''
                  } ${props.addLeftComponent ? 'ml-8' : ''} ${typeof props.title == 'string' ? 'Accordion__heading__title__ellipsis' : ''
                  }`}
                onClick={(e) => {
                  if (!accordionTitlePropagation) {
                    e.stopPropagation()
                  }
                }}>
                {
                  typeof props.title == 'string' ?
                    <Truncate isResponsive={true} truncateFrom={"end"}>
                      {getAccordionTitle(props.toggleStat, props.setTitleOnHide, props.title)}
                    </Truncate> :
                    getAccordionTitle(props.toggleStat, props.setTitleOnHide, props.title)
                }
              </div>
              {props.toggleStat === false && props.accordionDataCount && props.version !== 'v2' ? (
                <div className="Accordion__heading__field-count">{props.accordionDataCount}</div>
              ) : (
                ''
              )}
              {props.accordionDataCount !== undefined && props.accordionDataCount !== null && props.version === 'v2' ? (
                <div className="Accordion__heading__field-count">{props.accordionDataCount}</div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          {props.version === 'v2' ?
            <div className='flex-justify flex-v-center w-100'>
              <RightSideIcons />
            </div> :
            <RightSideIcons />
          }
        </div>
        {props.version === 'v2' && props.accordionDescription && props.isContainerization && (
          <div className={decriptionClassName()}>{props.accordionDescription}</div>
        )}
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.toggleStat,
    props.accordionDataCount,
    props.title,
    props.error,
    props.addLeftComponent,
    props.addRightComponent,
    props.version,
    props.isAccordionParent,
    props.isContainerization,
    props.hasBackgroundColor,
    props.accordionLock,
    props.actions
  ])
}

export const AccordionData = (props: accordionDataProps) => {
  const classNames = cn("Accordion__data",
    {
      'Accordion-v2__data': props.version === 'v2' && props.isAccordionParent,
      'Accordion-v2__data--background-padding': props.version === 'v2' && props.hasBackgroundColor && !props.isContainerization,
      'pb-20': props.isContainerization && props.version === 'v2'
    })
  return (
    <div className={classNames}>
      {props.accordionData}
    </div>
  )
}

const AccordionActions = ({ actions, version }: { actions: AccordionAction[]; version?: string }) => {
  // return useMemo(() => {
  return (
    <div className={`Accordion__actions ${version === 'v2' ? 'Accordion-v2__actions' : ''}`}>
      {actions?.map((action: AccordionAction, index) => {
        const actionClassname = cn('actions_list_item', action.actionClassName)
        return (
          <div
            className={actionClassname}
            onClick={(event) => {
              event.stopPropagation()
              action.onClick()
            }}
            key={index}>
            {action.component}
          </div>
        )
      })}
    </div>
  )
  // }, [actions?.length, version])
}

const Accordion = (props: accordionProps) => {
  const [toggleShow, setToggleShow] = useState(props.renderExpanded)

  const {
    title,
    accordionData,
    children,
    noChevron,
    accordionDataCount,
    hasBackgroundColor,
    dashedLineVisibility,
    accordionTitlePropagation,
    testId,
    version,
    isAccordionParent,
    stickyIcon,
    titleIcon,
    accordionDescription,
    isContainerization
  }: accordionProps = props

  const [preRender, setPreRender] = useState(props.preRender)

  const accordionDataValue = accordionData !== undefined ? accordionData : children

  const accordionDataCountValue = accordionDataCount

  const toggleView = () => {
    setToggleShow(!toggleShow)
    if (preRender === false) {
      setPreRender(true)
    }
  }

  let toggleState = toggleShow

  const classNames = cn('Accordion', props.className, {
    'Accordion--backgroundColor': hasBackgroundColor && !props.accordionLock,
    'Accordion-v2': props.version === 'v2',
    'Accordion-v2-parent': isAccordionParent && props.version === 'v2' && isContainerization === true,
    'Accordion-v2__container': isContainerization === true && props.version === 'v2'
  })

  if (props.isAccordionOpen !== undefined) {
    toggleState = props.isAccordionOpen
  }

  return (
    <div
      className={`${classNames} ${props.version === 'v2' ? `Accordion-${toggleShow ? 'open' : 'close'}` : ''}`}
      data-test-id={testId}>
      <AccordionTitle
        title={title}
        setTitleOnHide={props.setTitleOnHide}
        toggleStat={toggleState}
        toggle={toggleView}
        version={version}
        isAccordionParent={isAccordionParent}
        hasBackgroundColor={hasBackgroundColor}
        noChevron={noChevron}
        addLeftComponent={props.addLeftComponent}
        addRightComponent={props.addRightComponent}
        accordionDataCount={accordionDataCountValue}
        accordionLock={props.accordionLock}
        actions={props.actions}
        onClick={props.onTitleClick}
        error={props.errorMessage}
        dashedLineVisibility={dashedLineVisibility}
        accordionTitlePropagation={accordionTitlePropagation}
        stickyIcon={stickyIcon}
        titleIcon={titleIcon}
        accordionDescription={accordionDescription}
        isContainerization={isContainerization}
      />
      {preRender === false ? (
        toggleState === true ? (
          <div className="Accordion__open">
            <AccordionData version={version} isContainerization={isContainerization} hasBackgroundColor={hasBackgroundColor} isAccordionParent={isAccordionParent} accordionData={accordionDataValue} />
          </div>
        ) : null
      ) : (
        <div className={`${toggleState ? 'Accordion__open' : 'Accordion__collapsed'}`}>
          <AccordionData version={version} isAccordionParent={isAccordionParent} accordionData={accordionDataValue} />
        </div>
      )}
    </div>
  )
}

Accordion.defaultProps = {
  renderExpanded: false,
  preRender: false,
  noChevron: false,
  accordionLock: false,
  hasBackgroundColor: false,
  dashedLineVisibility: 'hover',
  accordionTitlePropagation: true,
  testId: 'cs-accordion',
  version: 'v1',
  isAccordionParent: false,
  isContainerization: false
} as Partial<accordionProps>

export default withDeprecatedProp(Accordion, {
  accordiondata: 'accordionData',
  accordiondataCount: 'accordionDataCount',
  accordionDataLock: 'accordionLock',
  accordionState: 'isAccordionOpen',
  backGroundColor: 'hasBackgroundColor',
  errorMsg: 'errorMessage',
  onClick: 'onTitleClick',
  noDefaultIcon: 'noChevron',
  actions: [
    {
      className: 'actionClassName'
    }
  ]
})



// {props.toggleStat === false && props.accordionDataCount && props.version !== 'v2' ? (
//   <div className="Accordion__heading__field-count">{props.accordionDataCount}</div>
// ) : (
//   ''
// )}
// {props.accordionDataCount !== undefined && props.accordionDataCount !== null && props.version === 'v2' ? (
//   <div className="Accordion__heading__field-count">{props.accordionDataCount}</div>
// ) : (
//   ''
// )}
// {props.addRightComponent ? (
//   props.addRightComponent
// ) : (
//   <>
//     {/* {!props.noChevron && !(props.hasBackgroundColor === true && props.version === 'v2' && props.isContainerization === false) && ( */}
//     {!props.noChevron && props.version !== 'v2' && (
//       <NewVersionChevron
//         handleToggle={handleToggle}
//         accordionLock={props.accordionLock}
//         toggleStat={props.toggleStat}
//         version={props.version}
//         isContainerization={props.isContainerization}
//       />
//     )}
//     <div className="flex-v-center flex-grow-1">
//       {props.error && <div className="Accordion__heading__errorMsg">{props.error}</div>}
//       {!props.isAccordionParent && props.version !== 'v2' && (
//         <span className={dashedLineClassname}>
//           <Line type="dashed" />
//         </span>
//       )}
//     </div>
//     {/* {props.version === 'v2' && props.hasBackgroundColor === true && props.isContainerization === false ? */}
//     <div className={`flex ${props.version !== 'v2' ? 'mr-15' : ''}`}>
//       {props.actions && <AccordionActions version={props.version} actions={props.actions} />}
//       {props.version === 'v2' && props.stickyIcon && props.stickyIcon}
//     </div>
//     {props.version === 'v2' && (props.actions || props.stickyIcon) ? <Icon version='v2' icon='SeperatorLine' className='Accordion__heading__seperator' /> : null}
//     {!props.noChevron && props.version === 'v2' ?
//       <NewVersionChevron
//         handleToggle={handleToggle}
//         accordionLock={props.accordionLock}
//         toggleStat={props.toggleStat}
//         version={props.version}
//         isContainerization={props.isContainerization}
//       />
//       : null
//     }
//   </>
// )}
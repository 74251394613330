/**
 * IMPORTANT
 * 1. infoModal Component renders as a different mini-app withine
 *  sibling node of body invoke using callback
 * 2. Block mulitple calling of infomodal callback in your component  using a state variable
 *  so that it wont open multi instance of info modal 
 **/

import React, { ReactNode, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import cn from 'classnames'
import './InfoModal.css'

export type portalProps = {
  sourceElem: React.ReactNode,
  AdjustLeftOffset?: Number,
  AdjustTopOffset?: Number,
  destinationElem?: React.ReactNode,
}

export type ModalProps = {
  onClose?: (data?: any) => void
  wrapperStyle?: Object
  targetNodeOrId: ReactNode | string
  closeOnOverlayClick?: boolean
  // alignment?: string
  portal?: portalProps
  className?: string
  ignoreContainers?: Array<string>
  closeOnEscape?: boolean,
}

export type InfoModalProps = {
  component: React.ReactNode
  modalProps?: ModalProps
  alignment?: string
}

export type ModalMain = {
  closeModal: (data?: any) => void
  component: any
  wrapperStyle: Object
  updatedProps: any
  className?: string
  alignment?: string
  portal?: portalProps
  destElem?: any
  sourceStyle?: any
  closeOnOverlayClick?: boolean,
  ignoreContainers?: Array<string>,
  closeOnEscape?: boolean
}

const infoModal = ({ component, modalProps, alignment }: InfoModalProps) => {
  let { onClose, targetNodeOrId, wrapperStyle, className, portal, closeOnOverlayClick, ignoreContainers, ...rest } = modalProps || {}

  const node = !portal ? getTargetNode() : undefined
  let div = document.createElement('div')
  if (node) {
    node.style.position = 'relative'
    node.setAttribute('id', 'InfoModalWrapper')
    node.setAttribute('class', 'InfoModalWrapper');
    node.appendChild(div)
  } else {
    if (!portal) {
      document.body.appendChild(div)
    }
  }
  if (portal) {
    let portalDestElem: any = portal.destinationElem;
    if (!portalDestElem) {
      document.body.appendChild(div)
    } else {
      portalDestElem.appendChild(div)
    }
  }

  function getTargetNode() {
    if (targetNodeOrId instanceof HTMLElement) return targetNodeOrId
    if (typeof targetNodeOrId === 'string') {
      return document.getElementById(targetNodeOrId)
    }
    if (!targetNodeOrId) {
      console.warn(`You are trying to pass targetNodeOrId but it is null. This might
        happen because the element may not have been added to DOM yet. Fallback modal attaches to window
      `)
    }
    return null
  }

  function destory() {
    const unmountResult = ReactDOM.unmountComponentAtNode(div)
    if (node && unmountResult) {
      node.removeChild(div)
    } else if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div)
    }
  }

  function closeModal(data) {
    destory()
    if (onClose) {
      onClose(data)
    }
  }


  function update(updatedProps) {
    render({ ...rest, ...updatedProps })
  }

  function render(updatedProps) {
    setTimeout(() => {
      ReactDOM.render(
        <Modal
          closeModal={closeModal}
          component={component}
          wrapperStyle={wrapperStyle}
          updatedProps={updatedProps}
          alignment={alignment}
          closeOnOverlayClick={closeOnOverlayClick}
          ignoreContainers={ignoreContainers}
          portal={portal}
          destElem={div}
          className={className}
        />,
        div
      )
    })
  }


  render(rest)

  return {
    div,
    closeModal,
    update,
  }
}

//check if the center modal is open throught infomodal
const findAncestor = (el, cls) => {
  if (el.classList.contains(cls)) {
    return true;
  }
  if (el.parentElement) {
    if (el.classList.contains(cls)) {
      return true;
    } else {
      el = el.parentElement;
      return findAncestor(el, cls);
    }
  } else {
    return false;
  }
}

const checkForParents = (e, parentsArr) => {
  let found = false;
  let temp;
  if (parentsArr && Array.isArray(parentsArr)) {
    parentsArr.map((id) => {
      temp = findAncestor(e, id);
      if (temp) {
        found = true;
        return false;
      };
    });
  }

  if (found) {
    return false;
  } else {
    return true
  }

}

export const Modal = ({ component: Component, ...rest }: ModalMain) => {
  const alignmentClass = `Infomodal--${rest.alignment === undefined ? 'top-left' : rest.alignment}`
  let classNames = cn(rest.className, alignmentClass, rest.portal ? 'Infomodal--portal' : '')
  const refElem = useRef(rest.destElem)

  const destElem = rest.portal ? refElem : null;
  let sourceEl: any = rest.portal ? rest.portal.sourceElem : null;
  let sourceElOffsetTop = sourceEl ? sourceEl.getBoundingClientRect().top + sourceEl.offsetHeight : null;
  let sourceElOffsetLeft = sourceEl ? sourceEl.getBoundingClientRect().left : null;
  sourceElOffsetTop += rest.portal ? rest.portal.AdjustTopOffset || 0 : 0;
  sourceElOffsetLeft += rest.portal ? rest.portal.AdjustLeftOffset || 0 : 0;
  let sourceStyle: any = sourceEl ? { top: sourceElOffsetTop + 'px', left: sourceElOffsetLeft + 'px' } : {};

  let isCursorInside = false

  const closeModal = () => {
    setTimeout(() => {
      rest.closeModal()
    })
  }

  const handleClickOutside = (event) => {
    let ignoreParent = checkForParents(event.target, rest.ignoreContainers);
    if (!isCursorInside && (rest.ignoreContainers && rest.ignoreContainers.length ? ignoreParent : true)) {
      closeModal()
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      closeModal();
    }
  }
  useEffect(() => {
    if (rest.closeOnOverlayClick) {
      document.addEventListener('click', handleClickOutside, false)

      return () => {
        document.removeEventListener('click', handleClickOutside, false)
      }
    }
  }, [])


  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleBlur = (e) => {
    const isCurrentlyNotInFocus = (e.relatedTarget !== e.currentTarget && !e.currentTarget.contains(e.relatedTarget));
    if (isCurrentlyNotInFocus && !isCursorInside) {
      closeModal();
    }
  }

  return <>
    {rest.portal ?

      ReactDOM.createPortal(
        <div
          onBlur={handleBlur}
          className={classNames}
          style={sourceStyle}
          onMouseOver={() => { isCursorInside = true }}
          onMouseOut={() => { isCursorInside = false }}
        >

          <Component closeModal={rest.closeModal} {...rest.updatedProps} />

        </div>
        ,
        destElem.current
      )
      :
      <div
        onBlur={handleBlur}
        className={classNames}
        style={{
          position: 'absolute',
          zIndex: 95,
          background: 'white',
          ...rest.wrapperStyle,
        }}
        onMouseOver={() => { isCursorInside = true }}
        onMouseOut={() => { isCursorInside = false }}
      >

        <Component closeModal={rest.closeModal} {...rest.updatedProps} />

      </div>
    }
  </>
}

export default infoModal

//@ts-nocheck
import React, { useState, useRef, useEffect } from 'react'
import { Transforms } from 'slate'
import { useSlateStatic, ReactEditor, useSelected, useFocused } from 'slate-react'
import { cbModal, ActionTooltip, Icon } from '../../../../../../index'
import { cx } from '@emotion/css'
import { Registry } from '@react-registry'
import { Resizable } from 're-resizable'

import { FullscreenIcon } from '../../utils/Icon'
import { handleDelete } from '../../utils/actions'
//@ts-ignore
import styles from './style.module.css'
import { ImageEditModal } from './ImageEditModal'
import { ImageFullScreen } from './ImageFullScreen'
import { handleImageError } from '.'
import { Tooltip } from '../../../../../../../src/index'

export const ImageElement = ({ attributes, children, element, attrs, type, entry, ...props }) => {
  const selected = useSelected()
  const focused = useFocused()
  const editor = useSlateStatic()
  const alignment = attrs?.style?.["textAlign"] || attrs?.style?.["text-align"]
  const imgRef = useRef(null)
  const parentRef = useRef(null)
  const [maxWidth, setMaxWidth] = useState(null)
  const [className, setClassName] = useState(null)


  let isInline = (element?.attrs?.inline || element?.attrs?.["redactor-attributes"]?.inline) ? true : false

  useEffect(() => {
    setClassName(element?.attrs?.['class-name'])
  }, [element?.attrs?.['class-name']])

  useEffect(() => {
    setMaxWidth(attributes.ref.current.parentElement.clientWidth - 48)
    //removing this as we no longer want to convert % value to pixels
  }, [maxWidth])


  const onResizeStop = (event, dir, el, size) => {
    let path = ReactEditor.findPath(editor, element)
    let { attrs } = element
    let width = el.style.width
    let height = el.style.height
    let isWidthInPixels = false
    let isHeightInPixels = false
    if (width.slice(-2) === 'px') {
      width = parseInt(width)
      isWidthInPixels = true
    }
    if (height.slice(-2) === 'px') {
      height = parseInt(heig)
      isHeightInPixels = true
    }

    if (element?.attrs?.width?.slice(-1) === '%' && isWidthInPixels) {
      var widthInPixels = width
      var screenWidth = attributes.ref.current.parentElement.clientWidth - 48;
      var widthInPercentage = ((screenWidth - widthInPixels) / screenWidth) * 100
      width = `${widthInPercentage}%`
    }

    const newAttrs = { ...attrs };
    newAttrs['width'] = width;
    newAttrs['height'] = height;
    const newStyles = { ...newAttrs.style }
    const newRedactorAttrs = { ...newAttrs['redactor-attributes'] }
    newAttrs.style = { ...newStyles }
    newAttrs['redactor-attributes'] = { ...newRedactorAttrs }
    newAttrs.style['max-width'] = isWidthInPixels ? `${width}px` : width
    newAttrs.style['width'] = isWidthInPixels ? `${width}px` : width
    newAttrs.style['height'] = isHeightInPixels ? `${height}px` : height
    newAttrs['max-width'] = `${width}`
    newAttrs['width'] = `${width}`
    newAttrs['height'] = `${height}`
    Transforms.setNodes(
      editor,
      { attrs: { ...newAttrs } },
      {
        at: path
      }
    )
  }
  const handleClick = () => {
    const selection = window.getSelection()
    selection.removeAllRanges()
    const range = document.createRange()
    range.selectNodeContents(imgRef.current)
    selection.addRange(range)
  }

  if (attrs?.style && attrs?.style?.width && !isNaN(attrs?.style?.width)) {
    attrs.style.width = `${attrs.style.width}px`
  }

  let alignmentStyle
  if (alignment) {
    delete attrs.style['float']
    const marginAlignment = {
      center: { margin: 'auto' },
      left: { marginRight: 'auto' },
      right: { marginLeft: 'auto' }
    }
    alignmentStyle = marginAlignment[alignment]
  }

  if (isInline) {
    alignmentStyle = { display: "inline-block", margin: "0 0.15rem -1px" }
    if (alignment) {
      alignmentStyle.float = alignment
    }
  } else {
    alignmentStyle = { ...alignmentStyle, display: 'block' }
  }

  alignmentStyle = isInline
    ?
    { ...alignmentStyle }
    : alignmentStyle

  const toolList = [
    {
      label: <FullscreenIcon id='fullscreen' />,
      title: 'Fullscreen',
      action: () => {
        cbModal({
          component: (props) => <ImageFullScreen url={type === 'asset' ? entry?.['asset-link'] : element?.attrs?.url} {...props} />
        })
      }
    },
    {
      label: <Icon id='edit' icon="Edit" size="small" />,
      title: 'Edit',
      action: () => {
        cbModal({
          component: (props) => <ImageEditModal type={type} element={element} editor={editor} {...props} />
        })
      }
    },
    {
      label: <Icon id='delete' icon="Trash" size="small" />,
      title: 'Delete',
      action: () => {
        handleDelete(editor, element)
      }
    }
  ]

  if (type === 'asset') {
    toolList.unshift(
      {
        label: <Icon icon='View' size='small' />,
        title: 'View',
        action: () => {
          const { history, apiKey } = editor?.['requestProps'] || {};
          const assetUid = attrs?.['asset-uid'];
          if (!history || !apiKey) return;
          history.push(`/stack/${apiKey}/assets/${assetUid}`);
        }
      }
    )
  }
  // clone value of attrs to tempAttrs and remove captionAttrs key from it
  let tempAttrs = { ...attrs }
  delete tempAttrs['captionAttrs']
  let resizeBound = document.getElementById('scrte-doc')
  const extraMarginStyle = alignmentStyle?.['margin'] ? { margin: alignmentStyle?.['margin'] } : {}
  return (
    <span {...attributes} {...tempAttrs} onClick={handleClick} data-type="img" style={{ ...alignmentStyle, ...attrs.style, ...extraMarginStyle }} data-testid={'image-wrapper'}>
      <Tooltip
        zIndex={909}
        className="p-0"
        position="bottom"
        variantType="light"
        appendTo={parentRef.current}
        offset={[0, -15]}
        content={
          <div contentEditable={false} className={styles['scrte--action-tooltip']}>
            {
              toolList.map((item) => {
                return (
                  <button data-testid={`cs-${item.title}`} id={item.title} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    item.action(e);
                  }}>
                    {item.label}
                  </button>
                )
              })
            }
          </div>
        }
      >
        <span
          data-testid={'image-parent-ref'}
          ref={parentRef}
          contentEditable={false}
          style={{
            paddingTop: '3px',
            paddingBottom: '3px',
            display: `${isInline ? 'block' : 'flow-root'}`,
          }}>
          <Resizable
            // bounds={resizeBound}
            onResizeStop={onResizeStop}
            lockAspectRatio={false}
            size={{
              width: `${isInline ? 'auto' : element.attrs.width ? element.attrs.width : 'auto'}`,
              height: `${element.attrs.height ? element.attrs.height : 'auto'}`
            }}
            className={cx(styles['scrte--img-container'], {
              [styles['scrte--img-inline']]: Boolean(isInline)
            })}
            handleStyles={{
              right: { right: 0, width: '15px' },
              left: { left: 0, width: '15px' },
              bottom: { bottom: '5px' },
              bottomLeft: { left: '-10px', bottom: '-5px' },
              bottomRight: { bottom: '-5px', right: '-10px' }
            }}
            handleWrapperClass="test2"
            minWidth={15}
          // enable={{ top: !isInline, right: !isInline, bottom: !isInline, left: !isInline, topRight: !isInline, bottomRight: !isInline, bottomLeft: !isInline, topLeft: !isInline }}
          >
            <div ref={imgRef}>
              <img
                id="image"
                src={type === 'asset' ? entry['url'] : element.attrs.url}
                onError={handleImageError}
                className={cx(styles['scrte-img'], {
                  [styles['scrte--img-selected']]: Boolean(selected && focused)
                }, className)}
                alt={element.attrs.alt}
                data-testid={'scrte-image'}
              />
              <span contentEditable={false} className={styles['scrte-image-resizer-feedback-left']}>
                <span
                  contentEditable={false}
                  className={cx(styles['scrte-image-resizer-feedback'], {
                    [styles['scrte-image-resizer-feedback-for-smallImg']]: Boolean(element?.attrs?.width < 40)
                  })}></span>
              </span>
              <span contentEditable={false} className={styles['scrte-image-resizer-feedback-right']}>
                <span
                  contentEditable={false}
                  className={cx(styles['scrte-image-resizer-feedback'], {
                    [styles['scrte-image-resizer-feedback-for-smallImg']]: Boolean(element?.attrs?.width < 40)
                  })}></span>
              </span>
              {element?.attrs?.caption && (
                <p className={styles['scrte--caption']}>{element.attrs.caption}</p>
              )}
            </div>
          </Resizable>
        </span>
      </Tooltip>
      {children}
    </span>
  )
}

export const ImageReadOnly = ({ attributes, children, element, attrs }) => {
  return (
    <span {...attributes} {...attrs}>
      <div
        contentEditable={false}
        style={{
          marginBottom: '1rem',
          width: `${element.attrs.width ? `${element.attrs.width}px` : 'auto'}`,
          minWidth: '165px',
          height: 'auto',
          maxWidth: `${element.attrs.width ? `${element.attrs.width}px` : 'auto'}`
        }}>
        {element.attrs.anchorLink ? (
          <a href={element.attrs.anchorLink} target={element.attrs.target ? '_blank' : '_self'}>
            <img src={element.attrs.url} className={styles['modal']} onError={handleImageError} />
          </a>
        ) : (
          <img src={element.attrs.url} className={styles['modal']} onError={handleImageError} />
        )}

        <p className={styles['scrte--caption']}>{element.attrs.caption}</p>
      </div>
      {children}
    </span>
  )
}
const beforeElementRender = (props) => {
  if (props?.element?.attrs?.inline || props?.element?.attrs?.style?.float === 'right' || props?.element?.attrs?.style?.float === 'left') {
    props.DisableDND = true
    props.DisableSelectionHalo = true
  }
}

Registry.register(
  {
    Component: ImageElement,
    ReadOnlyComponent: ImageReadOnly,
    //iconName: 'ri-image-line',
    title: 'Image',
    subtitle: 'Add beautiful images',
    toolbar: {
      inMainToolbar: false,
      inHoveringToolbar: false
    },
    beforeElementRender: beforeElementRender
  },
  { id: 'img', registry: 'element' }
)



import React, { Component, ChangeEventHandler } from 'react'
import cn from 'classnames'
import './Textarea.css'
import Icon from '../Icon2'
import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc'

export type TextareaProps = {
  /**
   *Specifies the name attribute of input element
   */
  name?: string
  /**
   *Specifies the id attribute of input element
   */
  id?: string
  /**
   *Used to give a hint before entering value
   */
  placeholder?: string
  /**
   *Pass the class names to be appended to this prop
   */
  className?: string
  /**
   *Sets the width of the text area .It can be `small`| `medium` |`large` |`x-large`| `full`
   */
  width?: 'small' | 'medium' | 'large' | 'x-large' | 'full'
  /**
   *Used to limit the length of the input value characters
   */
  maxLength?: number
  /**
   *Sets that the corresponding input is required and cannot be blank/empty
   */
  required?: boolean
  /**
   *Used to disable the input field
   */
  disabled?: boolean
  /**
   *Lets you add value in the text area
   */
  value?: string
  /**
   *Specifies the row space taken by the text area
   */
  rows?: number
  /**
   *Used to visually indicate an error
   */
  error?: boolean
  /**
   *Shows the count of characters entered in the text area
   */
  showCharacterCount?: boolean
  /**
   *The event occurs when the value of an element has been changed
   */
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  /**
   *This will add a reference to the text area
   */
  inputRef?: React.Ref<HTMLTextAreaElement>
  /**
   *Used for testing purposes
   */
  testId?: string
  /**
   * Provides the directionality of the text
   */
  textDirection?: 'ltr' | 'rtl' | 'auto'
  /**
   * Lets you mention the version of TextArea to use
   */
  version?: 'v2'
  /**
   * Allows read-only access
   */
  isReadOnly?: boolean
  /**
   * Lets you add suffix icon for input element
   */
  suffix?: React.ReactNode
  /**
   * Lets you specify the maximum resizable height
   */
  maxHeight?: any
  /**
   * Lets you specify the mainimum resizable height
   */
  minHeight?: any
  /**
   * Provides an option to hide the character count error message.
   */
  hideCharCountError?: boolean
} & typeof defaultProps

export interface TextareaState {
  value?: string
}

const defaultProps = {
  disabled: false,
  required: false,
  width: 'full',
  placeholder: 'Type something...',
  testId: 'cs-text-area',
  dir: 'auto',
  isReadOnly: false,
  hideCharCountError: true
}

const CharacterCount = ({ version, value, maxLength, showCharacterCount, hideCharCountError, computedCount }) => {
  if (!showCharacterCount || !maxLength) {
    return null
  }
  const length = value?.length || 0

  if (version === 'v2') {
    if (length <= maxLength) {
      return <span className="Textarea__char-length">{`${computedCount}`}</span>
    }

    if (!hideCharCountError) {
      return (
        <span className="Textarea__char-length Textarea__char-length--reached">Maximum character limit exceeded</span>
      )
    }
  } else {
    return (
      <span
        className={`Textarea__char-length${
          length === maxLength ? ' Textarea__char-length--reached' : ''
        }`}>{`${computedCount}`}</span>
    )
  }
  return null
}

export class Textarea extends Component<TextareaProps, TextareaState> {
  static defaultProps = defaultProps
  state = {
    value: this.props.value
  }
  y = 0
  h = 0
  defaultId = 'resizeableTextarea' + Date.now()
  id = this.props.id ? this.props.id : this.defaultId
  defaultMinHeight = 30
  defaultMaxHeight = 500
  revisedMinHeight = this.props.minHeight < this.defaultMinHeight ? this.props.minHeight : this.defaultMinHeight
  minHeight = this.props.minHeight ? this.revisedMinHeight : this.defaultMinHeight
  reviseddMaxHeight = this.props.maxHeight < this.minHeight ? this.defaultMaxHeight : this.props.maxHeight
  maxHeight = this.props.maxHeight ? this.reviseddMaxHeight : this.defaultMaxHeight

  UNSAFE_componentWillReceiveProps(nextProps: TextareaProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: nextProps.value
      })
    }
  }
  renderIsReadOnly = () => {
    if (this.props?.version === 'v2' && this.props?.isReadOnly) {
      return (
        <span className="Textarea__read-only__wrapper">
          {this.props.version === 'v2' ? <Icon withTooltip={true} tooltipContent={"Field locked"} tooltipPosition={"top"} icon="Lock" version="v2" size="medium" /> : <Icon icon="LockThin" />}
        </span>
      )
    }
  }

  renderSuffix = () => {
    if (this.props?.version === 'v2' && this.props?.suffix && !this.props?.isReadOnly) {
      return <span className="Textarea__suffix__wrapper">{this.props?.suffix}</span>
    }
    return null
  }

  mouseDownHandler = (e: { clientY: number }) => {
    const ele = document.getElementById(this.id)
    this.y = e.clientY

    // calculate the dimension of element
    const styles = getComputedStyle(ele)
    this.h = parseInt(styles?.height || '0')
    // attach the listeners to `document`
    document.addEventListener('mousemove', this.mouseMoveHandler)
    document.addEventListener('mouseup', this.mouseUpHandler)
  }

  mouseUpHandler = () => {
    // Remove the handlers of `mousemove` and `mouseup`
    document.removeEventListener('mousemove', this.mouseMoveHandler)
    document.removeEventListener('mouseup', this.mouseUpHandler)
  }

  mouseMoveHandler = (e: { clientY: number }) => {
    const ele = document.getElementById(this.id)
    ele.focus()
    const dy = e.clientY - this.y
    ele.style.height = `${this.h + dy}px`
  }

  componentDidMount() {
    let textarea = document.querySelector(`#${this.id}`)

    if (textarea) {
      textarea.addEventListener('input', autoResize, false)
    }
    function autoResize() {
      //autoresize only if scroll height is lessthan 100
      if (this.scrollHeight !== 40 && this.scrollHeight <= 100) {
        if (this.scrollHeight <= this.maxHeight) {
          this.style.height = 'inherit'
          this.style.height = this.scrollHeight + 'px'
        }
      }
    }
    return () => {
      textarea.removeEventListener('input', autoResize, false)
    }
  }

  render() {
    const {
      className,
      placeholder,
      maxLength,
      onChange,
      disabled,
      required,
      error,
      width,
      value,
      name,
      rows,
      id,
      inputRef,
      showCharacterCount,
      testId,
      textDirection,
      isReadOnly,
      suffix,
      hideCharCountError,
      ...otherProps
    } = this.props

    const widthClass = `Textarea--${width}`
    const versionClass = `${otherProps?.version === 'v2' ? 'Textarea__v2' : ''}`
    const classNames = cn('Textarea', className, [widthClass], {
      'Textarea--disabled': disabled,
      'Textarea--negative': error || (this.state.value?.length > maxLength && maxLength > 0),
      'Textarea--with-count': showCharacterCount,
      'Textarea__read-only': isReadOnly,
      'Textarea__read-only-disabled': isReadOnly && disabled,
      Textarea__suffix: suffix
    })
    const computedCount = `${
      this.state?.value && this.state.value?.length ? this.state.value.length : 0
    } / ${maxLength}`
    const isReadOnlyNode = this.renderIsReadOnly()
    const suffixNode = this.renderSuffix()

    return (
      <div className={classNames} data-test-id={testId}>
        {isReadOnlyNode}
        {suffixNode}
        <textarea
          aria-label={name}
          dir={textDirection}
          className={`Textarea__textarea${versionClass ? ` ${versionClass}` : ''}`}
          id={id}
          rows={rows}
          disabled={disabled}
          onChange={(e) => {
            if (disabled || isReadOnly) return
            if (onChange) {
              onChange(e)
            }
            this.setState({ value: e.target.value })
          }}
          placeholder={disabled && !this.state.value ? '' : placeholder}
          name={name}
          value={disabled ? value : this.state && this.state.value}
          ref={inputRef}
          {...otherProps}
        />
        {/* ----Resize---- */}
        {otherProps?.version === 'v2' && (
          <div className="Textarea__resize-icon" onMouseDown={this.mouseDownHandler}>
            <Icon icon="FieldResize" />
          </div>
        )}

        {/* ----Character count---- */}
        <CharacterCount
          version={otherProps.version}
          value={this.state.value}
          maxLength={maxLength}
          showCharacterCount={showCharacterCount}
          hideCharCountError={hideCharCountError}
          computedCount={computedCount}
        />
      </div>
    )
  }
}

export default withDeprecatedProp(Textarea, { showCharCount: 'showCharacterCount' })

import React, { useState, useEffect, createContext, useRef } from 'react'
import ReactDOM from 'react-dom'
import cn from 'classnames'
import infoModal from '../InfoModal/InfoModal'
import Tooltip from '../Tooltip/Tooltip'
import './FieldTypeSelector.css'
import Icon from '../Icon2/Icon'
import { isEnterOrSpacePressed, isTabPressed } from 'utils/keyboardUtils'

const FieldTypeSelectorContext = createContext([])

export const useFieldTypeSelectorContext = () => {
  return React.useContext(FieldTypeSelectorContext)
}

export const FieldTypeSelectorProvider = (props: any) => {
  const state = useState()
  return (
    <FieldTypeSelectorContext.Provider value={state}>
      {props.children}
    </FieldTypeSelectorContext.Provider>
  )
}

interface IFieldList {
  fields: Object
  onFieldClick: (arg: any) => void
  closeModal: (arg: any) => void
  type?: 'tiles' | 'dropdown'
}
interface IActionBar {
  fields: Object
  action?: (arg: any) => void,
  forceShow?: boolean
  fieldListType?: 'tiles' | 'dropdown'
  disableAddAction?: boolean
  tooltipText?: string
}
interface IFieldTypeSelector {
  children?: any
  fields: Object
  action?: (arg: any) => void
  forceShow?: boolean
  fieldListType?: 'tiles' | 'dropdown'
  disableAddAction?: boolean
  tooltipText?: string,
  testId?: string
}

const FieldList = (props: IFieldList) => {
  //props :- fields, onFieldClick
  const { fields, onFieldClick, type } = props;
  const fieldListRef = useRef(null);

  const handleKeyPressed = (e, id) => {
    if (isEnterOrSpacePressed(e.key)) {
      e.preventDefault();
      e.stopPropagation();
      props.closeModal && props.closeModal(null)
      onFieldClick(id);
    }
    if (document.activeElement === fieldListRef?.current?.childNodes[Object.keys(fields).length - 1]) {
      if (isTabPressed(e.key)) {
        e.preventDefault();
        e.stopPropagation();
        props.closeModal && props.closeModal(null);
      }
    }
  }

  useEffect(() => {
    if (fieldListRef?.current?.childNodes.length) {
      fieldListRef?.current?.childNodes[0].focus()
    }
  }, [])

  const handleFieldMouseOver = (e): void => {
    e.target?.focus()
  }

  const handleFieldClick = (id): void => {
    if (props.closeModal) {
      props.closeModal(null);
    }
    onFieldClick(id)
  }


  if (type === FieldListTypes.DROPDOWN) {
    return (
      <div ref={fieldListRef} className="FieldTypeSelector__field-dropdown">
        {Object.keys(fields).map((field, fieldIndex) => {
          const { display_name, id, iconName } = fields[field];
          const classname = cn("FieldTypeSelector__field-dropdown__item", {
            "flex-v-center": iconName
          });
          return (
            <div
              tabIndex={0}
              className={classname}
              key={fieldIndex}
              onClick={() => handleFieldClick(id)}
              onKeyDown={(e) => handleKeyPressed(e, id)}
              onMouseOver={handleFieldMouseOver}
            >
              {iconName && <Icon icon={iconName} />}
              {display_name}
            </div>
          )
        })}
      </div>
    );
  }

  return (
    <div ref={fieldListRef} className="FieldTypeSelector__field-tiles">
      {Object.keys(fields).map((field, fieldIndex) => {
        const { id, iconName, display_name, css_class } = fields[field]
        return (
          <div
            tabIndex={0}
            className={`FieldTypeSelector__field-tile ${css_class ? css_class : ''}`}
            key={fieldIndex}
            onClick={() => handleFieldClick(id)}
            onKeyDown={(e) => handleKeyPressed(e, id)}
            onMouseOver={handleFieldMouseOver}
          >
            {iconName && <Icon icon={iconName} />}
            <div className="FieldTypeSelector__field-tile__title" data-test-id={`cs-ct-select-field-${id}`}>{display_name}</div>
          </div>
        )
      })}
    </div>
  );
}

const symbol = {
  open: '+',
  close: 'x',
}
const ActionBar = (props: IActionBar) => {
  //props:- fields,action
  const { fields, action, forceShow, fieldListType, disableAddAction, tooltipText = "Insert a field" } = props
  const [signOfAction, setSignOfAction] = useState(symbol.open)
  const targetRef = React.useRef(null)
  // Accessing FieldTypeSelectorContext context from custom hook
  const [contextState, setContextState = () => { }]: any = useFieldTypeSelectorContext()
  const fieldListRef = React.useRef(null)
  const [isHide, setIsHide] = useState(typeof forceShow == 'undefined' ? signOfAction === symbol.open || forceShow : false)

  useEffect(() => {
    if (targetRef.current) {
      if (targetRef.current.childNodes.length === 0) {
        //if fieldList modal is removed by any other newly opening modal, then we need to set the signofAction to 'open' symbol
        setSignOfAction(symbol.open)
        setIsHide(true)
      }
    }
  }, [contextState])

  function destroy(node) {
    const unmountResult = ReactDOM.unmountComponentAtNode(node)
    if (unmountResult) {
      node.remove()
      setIsHide(true)
    }
  }

  const onActionBarClick = event => {
    if (disableAddAction) {
      return;
    }
    if (signOfAction === symbol.open) {
      if (contextState) {
        //removing any already existing fieldtype node from DOM
        destroy(contextState)
      }
      infoModal({
        component: props => <FieldList fields={fields} onFieldClick={onFieldClick} type={fieldListType} {...props} />,
        modalProps: {
          targetNodeOrId: targetRef.current,
          wrapperStyle: {
            top: '10px',
            left: 0,
            right: 0,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            background: 'transparent',
          },
          closeOnOverlayClick: true,
          onClose: () => { setSignOfAction(symbol.open); setIsHide(true) },
          ignoreContainers: ["ReactModalPortal", "ReactModal__Content", "Table__body__row"]
        },
      })
      setSignOfAction(symbol.close)
      setIsHide(true)
      setContextState(targetRef.current.childNodes[0])

      setTimeout(() => {
        let PageLayout__body: any = document.querySelector('.PageLayout__body');
        let FieldsList: any = document.querySelector('.FieldTypeSelector__field-tiles');
        let CurrElem = fieldListRef.current;
        let CurrElemOffset = CurrElem.getBoundingClientRect().top - 56;

        let oldTimeStamp = null;
        let scrollY = PageLayout__body.scrollTop;
        let scrollDist;
        scrollDist = PageLayout__body.scrollTop + ((CurrElemOffset + FieldsList?.offsetHeight) - PageLayout__body.offsetHeight) + 20;

        function step(newTimestamp) {
          if (oldTimeStamp !== null) {
            // if duration is 0 scrollY will be -Infinity
            scrollY += scrollDist * (newTimestamp - oldTimeStamp) / 500;
            PageLayout__body.scrollTop = scrollY;
            if (scrollY > scrollDist) {
              scrollY = scrollDist;
              return PageLayout__body.scrollTop = scrollDist;
            };
          }
          oldTimeStamp = newTimestamp;
          window.requestAnimationFrame(step);
        }
        if (CurrElemOffset + FieldsList?.offsetHeight > PageLayout__body.offsetHeight) {
          window.requestAnimationFrame(step);
        }
      });

    }
    if (signOfAction === symbol.close) {
      setSignOfAction(symbol.open)
      //close opened fieldList modal
      targetRef &&
        targetRef.current &&
        targetRef.current.childNodes[0] &&
        destroy(targetRef.current.childNodes[0])
    }
  }

  const onFieldClick = field => {
    typeof action === 'function' && action(field)
  }

  const handleKeyPress = (e) => {
    if (isEnterOrSpacePressed(e.key)) {
      e.preventDefault();
      e.stopPropagation();
      onActionBarClick(e)
    }
  }

  const handleOnFocus = () => setIsHide(false)


  const handleOnBlur = () => setIsHide(true)

  return (
    <div
      tabIndex={0}
      className={[
        'FieldTypeSelector',
        disableAddAction ? 'FieldTypeSelector_disabled' : '',
        signOfAction == symbol.close ? 'FieldTypeSelector--active' : ''
      ].join(' ')}
      ref={fieldListRef}
      onClick={onActionBarClick}
      onKeyDown={handleKeyPress}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
    >
      <div
        className={cn('FieldTypeSelector__action-bar',
          disableAddAction ? 'FieldTypeSelector__action-bar_disabled' : '',
          {
            hide: typeof forceShow == 'undefined' ? (signOfAction === symbol.open || forceShow) && isHide : false
          })}>
        <div className="FieldTypeSelector__action-sign">
          {signOfAction === symbol.open ? (
            <Tooltip
              content={tooltipText}
              position="top"
              variantType={disableAddAction ? 'light' : undefined}
              type="secondary">
              <Icon icon={disableAddAction ? 'GreyAdd' : 'PurpleAdd'} />
            </Tooltip>
          ) : (
              <Icon icon="PurpleRemove" />
            )}
        </div>
      </div>
      <div ref={targetRef}></div>
    </div >
  )
}
//Before using FieldTypeSelector component always wrap that component inside FieldTypeSelectorProvider component
export default function FieldTypeSelector(props: IFieldTypeSelector) {
  //props :- children, fields,action
  const { children, fields, action, forceShow, fieldListType = 'tiles', disableAddAction = false, tooltipText, testId = 'cs-field-type-selector' } = props
  return (
    <div data-test-id={testId}>
      {React.Children.count(children) ? children : null}
      <ActionBar
        fields={fields}
        action={action}
        forceShow={forceShow}
        fieldListType={fieldListType}
        disableAddAction={disableAddAction}
        tooltipText={tooltipText}
      />
    </div>
  )
}

enum FieldListTypes {
  TILES = 'tiles',
  DROPDOWN = 'dropdown',
}

import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import debounce from 'lodash/debounce'

import Icon from '../Icon2/Icon'
import SkeletonTile from '../SkeletonTile/SkeletonTile'
import Datepicker from '../Datepicker/Datepicker'
import Tooltip from '../Tooltip/Tooltip'
import Checkbox from '../Checkbox/Checkbox'

import { textLabel } from './util/entries'
import { fileSizeLabel } from './util/assets'
import { limitToFetch, stringOperators, options, tooltipWrapperId } from './util/constants'
import {
  autoSuggest,
  handleDataFetch,
  updateCurrentSearch,
  getSuggestionsLength,
  getSuggestionsDataByIndex,
  numericTypes,
  validateSelectedOption,
  notify,
  getOperatorTextContent,
} from './util/common'
import { SearchContext } from './searchContext'
import { truncate, groupEntriesByCt } from './util/helpers'
import SearchTooltip from './SearchTooltip'
import Breadcrumb from '../Breadcrumb/Breadcrumb'


const SkeletonLoader = ({ size = 2 }) => {
  return (
    <div>
      {Array(size)
        .fill(1)
        .map((data, index) => {
          return (
            <div className="flex-v-center" key={index}>
              <SkeletonTile
                numberOfTiles={1}
                tileHeight={8}
                tileWidth={110}
                tileRadius={6}
                tileBottomSpace={12}
                tileTopSpace={12}
                tileleftSpace={18}
              />
            </div>
          )
        })}
    </div>
  )
}

//todo improve cal logic
const HighlightMatch = ({ string, option }) => {
  const fullString = option.label
  const matchStart = fullString.toLowerCase().indexOf('' + string.toLowerCase() + '')
  const matchEnd = matchStart + string.length - 1

  const { truncatedText, isOverflow } = truncate(fullString)

  const beforeMatch = truncatedText.slice(0, matchStart)
  const matchText = truncatedText.slice(matchStart, matchEnd + 1)
  const afterMatch = truncatedText.slice(matchEnd + 1)

  const MatchTextComponent = () => {

    if (isOverflow && option.type === 'text') {
      return (
        <>
          "
          {beforeMatch}
          <span><strong id="react-select">{matchText}</strong></span>
          {afterMatch}
          "
        </>
      )
    }

    return (
      <>
        {beforeMatch}
        <span>
          <strong id="react-select">{option.type === 'text' ? `"${matchText}"` : matchText}</strong>
        </span>
        {afterMatch}
      </>
    )
  }

  if (isOverflow || option.info) {
    return (
      <SearchTooltip content={option.info ? option.info : fullString} position="right">
        <MatchTextComponent />
      </SearchTooltip>
    )
  }

  return <MatchTextComponent />
}

class AutoComplete extends Component<any> {
  state = {
    // -> handle when implemented for all modules
    queryType: this.props.queryType || 'init',
    nextQuery: this.props.nextQuery,
    suggestions: [],
    currentSearch: this.props.currentSearch || {
      key: [],
      operator: [],
      value: [],
      isQueryCompleted: false,
    },
    needOperator: typeof this.props.needOperator === 'boolean' ? this.props.needOperator : false,
    isQueryCompleted: false,
    action: this.props.action || 'create',
    queryCursor: 0,
    opeartorCursor: 0,
    suggestionsLength: 0,
    showSuggestions: true,
    hasMore: true,
    skip: 0,
    count: 0,
    loading: false,
    operators: stringOperators,
  }

  suggestionsRef: any = React.createRef()
  isCursorInside: any = false
  timer: any = null
  preventMultiEnter = false

  async componentDidMount() {
    try {
      this.setState({ loading: true })
      let ct_status = ''
      let selectedOption = {}
      const { action, editableQueryIndex, selectedOperator } = this.props

      //handling advc query case for entries contenttype
      if (this.props.selectedOption === options[0].value && this.props.case === 'advanceQuery') {
        ct_status = this.props.queryObject.ct_status
        //to pass valueOption ie there in selected label for adv case
        if (action === 'edit' && editableQueryIndex >= 0) {
          const query = this.props.queryArray[editableQueryIndex]
          selectedOption = query.key[0]
        }
      }

      const { withOperator, queryData, isQueryCompleted, count, operators } = await autoSuggest({
        queryType: this.state.queryType,
        selectedModule: this.props.selectedOption,
        fetchData: this.props.fetchData,
        queryCase: this.props.case,
        inputValue: this.props.inputValue,
        skip: 0,
        limit: limitToFetch,
        action: this.state.action,
        ct_status,
        selectedOption,
        currentSearch: this.state.currentSearch,
        taxonomyPlanEnabled: this.props.taxonomyPlanEnabled
      })

      console.log("componentDidMount ~ queryData:", queryData)

      //This handles condition loading is true and click outside detected case
      if (!this.context.isSearchVisible) {
        this.setState({ loading: false }, () => {
          this.props.closeAutoComplete()
          this.props.resetInputValue()
        })
        return
      }

      //setting operator cursor if any
      let opeartorCursor = 0
      if (selectedOperator) {
        const foundIndex = operators.findIndex((operator) => operator.value === selectedOperator)
        opeartorCursor = foundIndex !== -1 ? foundIndex : 0
      }
      //setting first placeholder
      const length = getSuggestionsLength(queryData)
      if (length && this.props.action !== 'edit') {
        const { selectedOpt } = getSuggestionsDataByIndex(queryData, 0)
        this.props.setPlaceholder(selectedOpt.label)
      }

      this.setState(
        {
          suggestions: queryData,
          needOperator: withOperator,
          operators: operators,
          isQueryCompleted,
          suggestionsLength: length,
          queryCursor: 0,
          opeartorCursor,
          count,
          hasMore: length >= count ? false : true,
          skip: 0,
          loading: false,
        },
        () => {
          //handling click of suggested syntax case
          if (this.props.suggestedSyntaxCase && this.state.suggestionsLength) {
            const { queryType, nextQuery, selectedOpt, type } = getSuggestionsDataByIndex(this.state.suggestions, 1)
            console.log("🚀 ~ file: AutoComplete.tsx:203 ~ AutoComplete ~ componentDidMount ~ { queryType, nextQuery, selectedOpt, type }:", { queryType, nextQuery, selectedOpt, type })
            this.handleSelectQuery(queryType, nextQuery, selectedOpt, type)
          }
        }
      )
      document.addEventListener('keyup', this.regiterKeyBindings, false)
      document.addEventListener('click', this.handleClickOutside, false)
    } catch (error) {
      console.log('AutoComplete componentDidMount error', error)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { queryCursor, suggestionsLength, queryType, currentSearch } = this.state
    const { inputValue, isAutoCompleteOpen } = this.props

    //self close conditon
    if (!inputValue && !currentSearch.key.length && isAutoCompleteOpen && this.props.case !== 'advanceQuery') {
      console.log('==> Running self close')
      this.props.closeAutoComplete()
    }

    //ScrollIntoView logic for arrow key binding
    if (prevState.queryCursor !== queryCursor && suggestionsLength > 7) {
      let scrollDropdownPosition = (queryCursor - 4) * 33
      this.suggestionsRef.current.scrollTop = scrollDropdownPosition
    }

    //handleSelectQuery resetinput that invoke debouceSearch to block have used isResetInput
    if (prevProps.inputValue !== inputValue && !this.props.isResetInput) {
      this.setState({ loading: true })
      this.debounceInputChange()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.regiterKeyBindings, false)
    document.removeEventListener('click', this.handleClickOutside, false)
    this.props.setInputType && this.props.inputType !== 'text' && this.props.setInputType('text')
    this.debounceInputChange.cancel()
    clearTimeout(this.timer)
  }

  updatePlaceholder = (label = '') => {
    try {
      const inputValue = this.props.inputValue || ''

      if (!inputValue.trim()) {
        this.props.setPlaceholder(label)
        return
      }

      if (inputValue && label && label.toLowerCase().startsWith(inputValue.toLowerCase())) {
        const unMatchLabelArray = label.toLowerCase().split(inputValue.toLowerCase())
        const updatedLabel = `${inputValue}${unMatchLabelArray[1]}`

        this.props.setPlaceholder(updatedLabel)
        return
      }

      this.props.setPlaceholder('')
    } catch (error) {
      console.log("updatePlaceholder -> error", error)
      this.props.setPlaceholder(label)
    }
  }

  debounceInputChange = debounce(async () => {
    try {
      let ct_status = ''
      let selectedOption = {}
      if (this.props.selectedOption === options[0].value && this.props.case === 'advanceQuery') {
        ct_status = this.props.queryObject.ct_status
        const { key } = this.state.currentSearch
        selectedOption = key[0]
      }

      const { withOperator, queryData, isQueryCompleted, count } = await autoSuggest({
        queryType: this.state.queryType,
        selectedModule: this.props.selectedOption,
        fetchData: this.props.fetchData,
        queryCase: this.props.case,
        inputValue: this.props.inputValue,
        skip: 0,
        limit: limitToFetch,
        action: this.state.action,
        ct_status,
        currentSearch: this.state.currentSearch,
        selectedOption,
        taxonomyPlanEnabled: this.props.taxonomyPlanEnabled
      })
      const length = getSuggestionsLength(queryData)
      this.setState({
        suggestions: queryData,
        needOperator: withOperator,
        isQueryCompleted,
        suggestionsLength: length,
        queryCursor: 0,
        // opeartorCursor: 0,
        count,
        hasMore: length >= count ? false : true,
        skip: 0,
        loading: false,
      })
      if (length && this.props.action !== 'edit') {
        const { selectedOpt } = getSuggestionsDataByIndex(queryData, 0)
        this.updatePlaceholder(selectedOpt.label)
      }
    } catch (error) {
      this.setState({ loading: false })
      console.log('debounceInputChange error', error)
    }
  }, 300)

  handleClickOutside = (e) => {
    const target = e.target
    if (
      !this.isCursorInside &&
      this.props.action === 'edit' &&
      !(target.className === 'AdvancedSearch__matched-string')
    ) {
      console.log('click outside detected from autoComplete edit')
      this.props.closeAutoComplete()
    } else if (
      !this.isCursorInside &&
      this.props.case === 'advanceQuery' &&
      target.name !== this.props.inputName &&
      this.props.action !== 'edit'
    ) {
      this.context.updateAdvanceQueryArray({
        action: 'deleteIncompleteQuery',
        queryArray: this.props.queryArray,
      })
      this.props.closeAutoComplete()
    }
  }

  regiterKeyBindings = (event) => {
    const { key } = event
    let {
      needOperator,
      queryCursor,
      opeartorCursor,
      suggestionsLength,
      suggestions,
      operators,
      currentSearch,
    } = this.state

    if (needOperator && this.context.isSearchVisible) {
      const isDisableArrowKey = this.props.action === 'edit' && this.props.disableArrowKey
      if (key === 'ArrowRight' && opeartorCursor < operators.length - 1 && !isDisableArrowKey) {
        opeartorCursor = opeartorCursor + 1
        // console.log('ArrowRight opeartorCursor', opeartorCursor)
        this.handleOperatorChange(operators[opeartorCursor])
        this.setState({ opeartorCursor })
      } else if (key === 'ArrowLeft' && opeartorCursor > 0 && !isDisableArrowKey) {
        opeartorCursor = opeartorCursor - 1
        // console.log('ArrowLeft opeartorCursor', opeartorCursor)
        this.handleOperatorChange(operators[opeartorCursor])
        this.setState({ opeartorCursor })
      }
    }

    if (key === 'ArrowDown' && queryCursor < suggestionsLength - 1 && this.context.isSearchVisible) {
      queryCursor = queryCursor + 1
      // console.log('ArrowDown queryCursor', queryCursor)
      this.setState({ queryCursor })
    } else if (key === 'ArrowUp' && queryCursor > 0 && this.context.isSearchVisible) {
      queryCursor = queryCursor - 1
      // console.log('ArrowUp queryCursor', queryCursor)
      this.setState({ queryCursor })
    }

    // set auto complete placeholder
    if (queryCursor >= 0 && suggestionsLength && this.props.action !== 'edit') {
      // const updatedCursor = needOperator ? queryCursor - operators.length : queryCursor
      const { selectedOpt } = getSuggestionsDataByIndex(suggestions, queryCursor)
      this.updatePlaceholder(selectedOpt.label)
    }

    if (key === 'Enter' && queryCursor >= 0 && suggestionsLength && !this.preventMultiEnter && this.context.isSearchVisible) {
      this.preventMultiEnter = true
      this.timer = setTimeout(() => {
        const { queryType, nextQuery, selectedOpt, type } = getSuggestionsDataByIndex(suggestions, queryCursor)
        if (this.state.nextQuery === 'inLocalized') {
          let currentSearchValue = currentSearch.value
          let value = (currentSearchValue[0] && currentSearchValue[0].value) || []
          const foundOpt = value.find((v) => v.value === selectedOpt.value)
          const checkStatus = foundOpt ? false : true
          this.handleMultiSelectQuery(queryType, nextQuery, selectedOpt, type, checkStatus)
        } else {
          this.handleSelectQuery(queryType, nextQuery, selectedOpt, type)
        }
      }, 300)
    }
  }

  handleSearchTextOnClickSearchIcon = () => {
    try {
      const { suggestions, queryCursor } = this.state
      const { queryType, nextQuery, selectedOpt, type } = getSuggestionsDataByIndex(suggestions, queryCursor)
      this.handleSelectQuery(queryType, nextQuery, selectedOpt, type)
    } catch (error) {
      console.log("handleSearchTextOnClickSearchIcon", error)
    }
  }

  handleSelectQuery = async (queryType, nextQuery, selectedOpt, type) => {
    try {
      if (numericTypes.includes(queryType)) {
        this.props.setInputType && this.props.setInputType('number')
      }

      if (queryType === 'entriesTagLabel' || queryType === 'assetTagLabel') {
        this.props.setInputType && this.props.setInputType('tagLabelCase')
      }

      const errorContent = this.context.textContent.validations.basic_query

      const { isValid, errorMessage } = validateSelectedOption({
        queryType,
        nextQuery,
        selectedOpt,
        type,
        queryArray: this.context.queryArray,
        errorContent,
        action: this.state.action
      })

      if (!isValid) {
        notify(errorMessage, 'warning')
        this.preventMultiEnter = false
        return
      }

      this.setState({ loading: true })
      const { withOperator, queryData, isQueryCompleted, count, operators, defaultOperator } = await autoSuggest({
        queryType,
        selectedModule: this.props.selectedOption,
        fetchData: this.props.fetchData,
        queryCase: this.props.case,
        // inputValue: this.props.inputValue,
        skip: 0,
        limit: limitToFetch,
        action: this.state.action,
        selectedOption: selectedOpt,
        currentSearch: this.state.currentSearch,
      })

      //This handles condition loading is true and click outside detected case
      if (!this.context.isSearchVisible) {
        this.setState({ loading: false }, () => {
          this.props.closeAutoComplete()
          this.props.resetInputValue()
        })
        return
      }

      const length = getSuggestionsLength(queryData)
      if (length && this.props.action !== 'edit') {
        const { selectedOpt } = getSuggestionsDataByIndex(queryData, 0)
        this.updatePlaceholder(selectedOpt.label)
      } else if (!length && queryType === fileSizeLabel.queryType) {
        this.props.setPlaceholder('Type a value')
      } else {
        this.props.setPlaceholder('')
      }
      const updatedSearchQuery = updateCurrentSearch({
        queryType,
        nextQuery,
        selectedOpt,
        type,
        isQueryCompleted,
        currentSearch: this.state.currentSearch,
        withOperator,
        action: this.props.action,
        operators: operators,
        defaultOperator,
      })
      if (this.props.case === 'basicQuery') {
        this.context.updateQueryArray(updatedSearchQuery, this.state.action, this.props.editableQueryIndex)
      }
      if (this.props.case === 'advanceQuery') {
        this.context.updateAdvanceQueryArray({
          currentSearch: updatedSearchQuery,
          action: this.state.action,
          queryArray: this.props.queryArray,
          editableQueryIndex: this.props.editableQueryIndex,
          queryObject: this.props.queryObject,
        })
      }
      //this invoke componentdidmount
      this.props.resetInputValue()

      if (isQueryCompleted) {
        this.props.closeAutoComplete()
        return
      }

      this.setState({
        queryType,
        nextQuery,
        suggestions: queryData,
        needOperator: withOperator,
        isQueryCompleted,
        suggestionsLength: length,
        queryCursor: 0,
        opeartorCursor: 0,
        currentSearch: updatedSearchQuery,
        action: this.props.action || 'update',
        count,
        hasMore: length >= count ? false : true,
        skip: 0,
        loading: false,
        operators,
      })
      this.preventMultiEnter = false
    } catch (error) {
      this.setState({ loading: false })
      this.preventMultiEnter = false
      console.log('handleSelectQuery error', error)
    }
  }

  //This method is just for localized case need to make ot generic
  handleMultiSelectQuery = (queryType, nextQuery, selectedOpt, type, checked) => {
    const { currentSearch } = this.state

    let currentSearchValue = currentSearch.value
    let value = (currentSearchValue[0] && currentSearchValue[0].value) || []

    if (value.find((v) => v.value === '$any') && selectedOpt.value !== '$any') {
      return
    }
    let updatedValue = []

    if (!currentSearchValue.length && checked) {
      updatedValue = [{ label: selectedOpt.label, value: [selectedOpt] }]
    }
    if (selectedOpt.value === '$any' && checked) {
      updatedValue = [{ label: 'Any Locales', value: [selectedOpt] }]
    } else if (checked) {
      let finalValue = [...value, selectedOpt]
      const label = finalValue.length === 1 ? finalValue[0].label : `${finalValue.length} Selected`
      updatedValue = [{ label, value: finalValue }]
    } else if (!checked) {
      let filteredValue = value.filter((v) => v.value !== selectedOpt.value)
      if (!filteredValue.length) {
        updatedValue = []
      } else {
        const label = filteredValue.length === 1 ? filteredValue[0].label : `${filteredValue.length} Selected`
        updatedValue = [{ label, value: filteredValue }]
      }
    }

    // console.log('AutoComplete -> updatedValue', updatedValue)
    const updatedSearchQuery = { ...currentSearch, value: updatedValue }

    this.setState({ currentSearch: updatedSearchQuery })

    if (this.props.case === 'basicQuery') {
      const isMulti = true
      this.context.updateQueryArray(updatedSearchQuery, this.state.action, this.props.editableQueryIndex, isMulti)
    }
    if (this.props.case === 'advanceQuery') {
      this.context.updateAdvanceQueryArray({
        currentSearch: updatedSearchQuery,
        action: this.state.action,
        queryArray: this.props.queryArray,
        editableQueryIndex: this.props.editableQueryIndex,
        queryObject: this.props.queryObject,
      })
    }
    this.preventMultiEnter = false
  }

  handleOperatorChange = (selectedOperator) => {
    const { needOperator, currentSearch } = this.state
    if (needOperator) {
      const copySearchQuery = { ...currentSearch }
      const { operator } = copySearchQuery
      operator.splice(operator.length - 1, 1, selectedOperator)
      this.setState({ currentSearch: copySearchQuery })
      let currentAction = this.props.action || 'update'

      if (this.props.case === 'basicQuery') {
        this.context.updateQueryArray(copySearchQuery, currentAction, this.props.editableQueryIndex)
      }
      if (this.props.case === 'advanceQuery') {
        this.context.updateAdvanceQueryArray({
          currentSearch: copySearchQuery,
          action: currentAction,
          queryArray: this.props.queryArray,
          queryObject: this.props.queryObject,
          editableQueryIndex: this.props.editableQueryIndex,
        })
      }
    }
  }

  fetchOnScroll = async () => {
    try {
      let { skip, suggestions, suggestionsLength, count, nextQuery, currentSearch, queryType } = this.state
      if (suggestionsLength >= count) {
        this.setState({ hasMore: false })
        return
      }

      let payload: any = {
        skip: skip + limitToFetch,
        limit: limitToFetch,
        currentQuery: nextQuery,
        selectedOption: this.props.selectedOption,
        inputValue: this.props.inputValue,
        fetchData: this.props.fetchData,
        suggestionsLength
      }

      if (nextQuery === 'inReference') {
        const { key } = currentSearch
        const valueOptions = (key[0] && key[0].valueOptions) || []
        let query = { _content_type_uid: { $in: valueOptions } }
        payload.query = query
      }

      const response: any = await handleDataFetch(payload)

      if (nextQuery === 'inReference') {
        let entriesOptions = []
        suggestions.forEach(query => {
          entriesOptions = [...entriesOptions, ...query.options]
        })

        entriesOptions = [...entriesOptions, ...response.data]
        const groupedEntries = groupEntriesByCt({ data: entriesOptions, count: response.count })
        suggestions = [...groupedEntries]
      } else {
        const foundSuggestion = suggestions.find((obj) => obj.queryType === nextQuery)
        foundSuggestion.options = [...foundSuggestion.options, ...response.data]
      }

      const length = getSuggestionsLength(suggestions)

      this.setState({
        skip: skip + limitToFetch,
        count: response.count,
        suggestions: [...suggestions],
        suggestionsLength: length,
      })

      //Added this condition just for tag
      if ((queryType === 'entriesTagLabel' || queryType === 'assetTagLabel') && !response.data.length) {
        this.setState({ hasMore: false })
      }
    } catch (error) {
      this.setState({ loading: false })
      console.log('fetchOnScroll  error', error)
    }
  }


  onChangeDate = (selectedDate) => {
    const { currentSearch } = this.state
    const value = [{ label: selectedDate, value: selectedDate }]
    let currentAction = this.props.action || 'update'

    if (this.props.case === 'basicQuery') {
      this.context.updateQueryArray(
        { ...currentSearch, value, isQueryCompleted: true },
        currentAction,
        this.props.editableQueryIndex
      )
    }
    if (this.props.case === 'advanceQuery') {
      this.context.updateAdvanceQueryArray({
        currentSearch: { ...currentSearch, value, isQueryCompleted: true },
        action: currentAction,
        queryArray: this.props.queryArray,
        editableQueryIndex: this.props.editableQueryIndex,
        queryObject: this.props.queryObject,
      })
    }
    this.props.closeAutoComplete()
  }

  render() {
    let optionCounter = 0
    const {
      needOperator,
      queryCursor,
      opeartorCursor,
      suggestionsLength,
      suggestions,
      loading,
      operators,
      nextQuery,
      currentSearch,
    } = this.state

    const { textContent } = this.context

    const shouldOperatorRender =
      (needOperator && suggestionsLength > 0) || nextQuery === 'inDate' || nextQuery === 'inFileSize' || nextQuery === 'inTerms'
    const isNumeric = numericTypes.includes(nextQuery)
    const isMulti = nextQuery === 'inLocalized'
    const customStyles: any = {}
    const topOffset = 15
    const leftOffset = 15

    if (this.props.editInputRef) {
      const { top: targetTop, left: targetLeft, height } = this.props.editInputRef.getBoundingClientRect()
      const { top: gsTop, left: gsLeft } = document.querySelector('#AdvanceSearchComponent').getBoundingClientRect()
      customStyles.top = targetTop - gsTop + height + topOffset
      customStyles.left = targetLeft - gsLeft + leftOffset
    }
    return (
      <div
        style={customStyles}
        className={`AdvancedSearch__suggestion-dropdown ${this.state.showSuggestions ? ' AdvancedSearch__suggestion-dropdown--show' : ''
          } ${nextQuery === 'inTerms' && suggestions.length ? 'AdvancedSearch__suggestion-dropdown-interms' : ''}`}
        onMouseOver={() => {
          this.isCursorInside = true
        }}
        onMouseOut={() => {
          this.isCursorInside = false
        }}
      >
        {shouldOperatorRender && (
          <div className="AdvancedSearch__operators">
            {operators.map((operator, index) => {
              let tooltipContent = getOperatorTextContent({
                operatorKey: operator,
                queryType: nextQuery,
                textContent,
              })

              return (
                <SearchTooltip content={tooltipContent} position="top" key={index}>
                  <div
                    className={`AdvancedSearch__operator ${opeartorCursor === index ? ' AdvancedSearch__operator--active' : ''
                      }`}
                  >
                    <button
                      className="AdvancedSearch__operator-control"
                      onClick={() => {
                        this.setState({ opeartorCursor: index })
                        this.handleOperatorChange(operator)
                      }}
                    >
                      <Icon icon={operator.icon} className="AdvancedSearch__operator-icon" />
                    </button>
                  </div>
                </SearchTooltip>
              )
            })}
          </div>
        )}

        {nextQuery === 'inDate' ? (
          <Datepicker onChange={this.onChangeDate} initialDate={new Date()} />
        ) : (nextQuery === 'inTerms') ? (<ul
          className={`AdvancedSearch__suggestion-items AdvancedSearch__suggestion-items--terms ${suggestionsLength > 9 ? 'AdvancedSearch__filterDropdown-items--limit' : ''
            }`}
          ref={this.suggestionsRef}
          id="autocompleteScrollableDiv"
        >
          <InfiniteScroll
            style={{ overflowY: 'hidden' }}
            dataLength={suggestionsLength}
            next={this.fetchOnScroll}
            hasMore={this.state.hasMore}
            loader={<SkeletonLoader />}
            scrollableTarget="autocompleteScrollableDiv"
            hasChildren={true}
          >
            {loading && this.props.inputValue.length ? (
              <>
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
              </>
            ) : !this.props.inputValue.length ? (
              <li className="ml-20 AdvancedSearch__no-result">Start typing a keyword.
              </li>
            ) : !suggestionsLength ? <li className="ml-20 AdvancedSearch__no-result">No results found. Don't worry though, we're here to help. Try adjusting your search terms or check if you misspelled anything.</li> : (
              suggestions.map((query, index) => {
                if (query.queryType === textLabel.queryType) {
                  return null
                }
                return (
                  <div key={index}>
                    {query.group && (
                      <li
                        className="AdvancedSearch__suggestion-item--heading--terms"
                        style={{ marginLeft: query.nestingLevel ? `${query.nestingLevel * 15}px` : '' }}
                      >
                        {query.group}
                      </li>
                    )}
                    {query.options.map((opt, i) => {
                      optionCounter = optionCounter + 1

                      return (
                        <RenderTermsOption
                          handleSelectQuery={this.handleSelectQuery}
                          query={query}
                          opt={opt}
                          key={opt.value + index}
                          inputValue={this.props.inputValue}
                          optionCounter={optionCounter}
                          queryCursor={queryCursor}
                          breadcrumb={opt.breadcrumb?.length ? opt.breadcrumb : [{ name: '/..' }]}
                        />
                      )
                    })}
                  </div>
                )
              })
            )}
          </InfiniteScroll>
        </ul>) : (
          <ul
            className={`AdvancedSearch__suggestion-items ${suggestionsLength > 9 ? 'AdvancedSearch__filterDropdown-items--limit' : ''
              }`}
            ref={this.suggestionsRef}
            id="autocompleteScrollableDiv"
          >
            <InfiniteScroll
              style={{ overflowY: 'hidden' }}
              dataLength={suggestionsLength}
              next={this.fetchOnScroll}
              hasMore={this.state.hasMore}
              loader={<SkeletonLoader />}
              scrollableTarget="autocompleteScrollableDiv"
              hasChildren={true}
            >
              {loading ? (
                <SkeletonLoader />
              ) : !suggestionsLength && nextQuery !== 'inFileSize' ? (
                <li className="ml-20 AdvancedSearch__no-result">No Suggestions</li>
              ) : (
                suggestions.map((query, index) => {
                  //not rendering text case here applied only when user click from suggestedSyntax
                  if (query.queryType === textLabel.queryType) {
                    return null
                  }
                  return (
                    <div key={index}>
                      {query.group && (
                        <li
                          className="AdvancedSearch__suggestion-item--heading"
                          style={{ marginLeft: query.nestingLevel ? `${query.nestingLevel * 15}px` : '' }}
                        >
                          {query.group}
                        </li>
                      )}
                      {query.options.map((opt, i) => {
                        optionCounter = optionCounter + 1

                        if (isMulti) {
                          return (
                            <RenderMultiSelectOption
                              key={opt.value + index}
                              handleMultiSelectQuery={this.handleMultiSelectQuery}
                              query={query}
                              opt={opt}
                              inputValue={this.props.inputValue}
                              optionCounter={optionCounter}
                              queryCursor={queryCursor}
                              currentSearch={currentSearch}
                            />
                          )
                        }

                        return (
                          <RenderOption
                            handleSelectQuery={this.handleSelectQuery}
                            query={query}
                            opt={opt}
                            key={opt.value + index}
                            inputValue={this.props.inputValue}
                            optionCounter={optionCounter}
                            queryCursor={queryCursor}
                          />
                        )
                      })}
                    </div>
                  )
                })
              )}
            </InfiniteScroll>
          </ul>
        )}
        {nextQuery === 'inTerms' && suggestions.length && !loading ? <div className='AdvancedSearch__terms-count'>{this.state.count} results  found</div> : null}
      </div>
    )
  }
}

AutoComplete.contextType = SearchContext

//This compoenet is just for localized case need to make ot generic
const RenderMultiSelectOption = (props) => {
  const { opt, query, optionCounter, queryCursor, currentSearch } = props

  const isMatchFound = props.inputValue && opt.label.toLowerCase().indexOf(props.inputValue.toLowerCase()) !== -1

  let isOptionCheck = false
  const currentSearchValue = currentSearch.value[0]

  let disabled = false
  if (currentSearchValue) {
    if (currentSearchValue.value[0] && currentSearchValue.value[0].value === '$any') {
      isOptionCheck = true
      disabled = opt.value === '$any' ? false : true
    } else {
      isOptionCheck = currentSearchValue.value.find((v) => v.value === opt.value)
    }
  }

  const CheckBoxWrapper = ({ children }) => {
    const cssClass = `AdvancedSearch__suggestion-item AdvancedSearch__suggestion-item--with-checkbox AdvancedSearch__suggestion-item--selectable ${optionCounter - 1 === queryCursor ? ' AdvancedSearch__suggestion-item--active' : ''
      }`

    return (
      <li className={cssClass}>
        <Checkbox
          checked={isOptionCheck || false}
          disabled={disabled}
          onChange={(e) =>
            props.handleMultiSelectQuery(query.queryType, query.nextQuery, opt, query.type, e.target.checked)
          }
          text={children}
        />
      </li>
    )
  }

  const { isOverflow, truncatedText } = truncate(opt.label)

  if (isMatchFound) {
    return (
      <CheckBoxWrapper>
        <HighlightMatch string={props.inputValue} option={opt} />
      </CheckBoxWrapper>
    )
  }

  return isOverflow ? (
    <SearchTooltip content={opt.label} position="right">
      <CheckBoxWrapper>{truncatedText}</CheckBoxWrapper>
    </SearchTooltip>
  ) : (
    <CheckBoxWrapper>{opt.label}</CheckBoxWrapper>
  )
}

const RenderOption = (props) => {
  const { opt, query, optionCounter, queryCursor } = props

  const OptionWrapper = ({ children, left = 0 }) => {
    const cssClass = `AdvancedSearch__suggestion-item AdvancedSearch__suggestion-item--selectable${optionCounter - 1 === queryCursor ? ' AdvancedSearch__suggestion-item--active' : ''
      }`

    return (
      <li
        onClick={() => {
          props.handleSelectQuery(query.queryType, query.nextQuery, opt, query.type)
        }}
        className={cssClass}
      >
        <div style={{ marginLeft: left ? `${left * 15}px` : '' }}>
          {children}
        </div>
      </li>
    )
  }

  if (props.inputValue && opt.label.toLowerCase().indexOf(props.inputValue.toLowerCase()) !== -1) {
    return (
      <OptionWrapper>
        <HighlightMatch string={props.inputValue} option={opt} />
      </OptionWrapper>
    )
  }

  const { truncatedText, isOverflow } = truncate(opt.label)

  return (
    <OptionWrapper left={opt.nestingLevel}>
      {isOverflow ? (
        <SearchTooltip content={opt.label} position="right">
          {truncatedText}
        </SearchTooltip>
      ) : (
        opt.label
      )}
    </OptionWrapper>
  )
}

const RenderTermsOption = (props) => {
  const { opt, query, optionCounter, queryCursor } = props
  const OptionWrapper = ({ children, left = 0 }) => {
    const cssClass = `AdvancedSearch__suggestion-item-term AdvancedSearch__suggestion-item--selectable${optionCounter - 1 === queryCursor ? ' AdvancedSearch__suggestion-item--active' : ''
      }`

    return (
      <li
        onClick={() => {
          props.handleSelectQuery(query.queryType, query.nextQuery, opt, query.type)
        }}
        className={cssClass}
      >
        <div style={{ marginLeft: left ? `${left * 15}px` : '' }} className='term-name-wrapper'>
          {children}
        </div>
        <Breadcrumb breadcrumbItems={props.breadcrumb} tooltipPosition={'top'} maxWidth={props.breadcrumb.length === 1 ? 400 : 150} truncateLimit={5} />
      </li>
    )
  }

  if (props.inputValue && opt.label.toLowerCase().indexOf(props.inputValue.toLowerCase()) !== -1) {
    return (
      <OptionWrapper>
        <HighlightMatch string={props.inputValue} option={opt} />
      </OptionWrapper>
    )
  }

  const { truncatedText, isOverflow } = truncate(opt.label)

  return (
    <OptionWrapper left={opt.nestingLevel}>
      {isOverflow ? (
        <SearchTooltip content={opt.label} position="right">
          {truncatedText}
        </SearchTooltip>
      ) : (
        opt.label
      )}
    </OptionWrapper>
  )
}

export default AutoComplete

// opt.info ? (
//   <>
//     {opt.label}
//     <span style={{ fontSize: '8px' }}>{opt.info}</span>
//   </>
// ) : opt.label
// return (
//   <li
//     onClick={() => {
//       props.handleSelectQuery(query.queryType, query.nextQuery, opt, query.type)
//     }}
//     // key={opt.value + index}
//     className={`AdvancedSearch__suggestion-item AdvancedSearch__suggestion-item--selectable${optionCounter - 1 === queryCursor ? ' AdvancedSearch__suggestion-item--active' : ''
//       }`}
//   >
//     {props.inputValue &&
//       opt.label.toLowerCase().indexOf(props.inputValue.toLowerCase()) !== -1 ? (
//         <HighlightMatch string={props.inputValue} option={opt} />
//       ) : (
//         opt.info ?
//           <Tooltip content={opt.info} position="top">
//             {opt.label}
//           </Tooltip> : opt.label
//       )}
//   </li>
// )
// if (opt.info) {
//   return (
//     <OptionWrapper>
//       <SearchTooltip content={opt.info} position="right">
//         {opt.label}
//       </SearchTooltip>
//     </OptionWrapper>
//   )
// }

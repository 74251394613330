import React, { useState, useRef, useEffect } from 'react'

import Field from '../../Field/Field'
import TextInput from '../../TextInput/TextInput'
import FieldLabel from '../../FieldLabel/FieldLabel'
import Checkbox from '../../Checkbox/Checkbox'
import Textarea from '../../Textarea/Textarea'
import Help from '../../Help/Help'
import ValidationMessage from '../../ValidationMessage/ValidationMessage'
import TagAsSelect from '../../TagAsSelect/TagAsSelect'
import AceEditor from 'react-ace'
import { fieldUidRegex, getContentTypeFieldDataTestId, CONTENT_TYPE_FIELD_ACTION_IDS } from '../helper'

export const Custom = (props: any) => {
  const { onChange, parentInfo, uniqueKey, extensionFieldOption, schema, type, hasNonLocalizablePlan } = props
  console.log(parentInfo,"pasojxhsdjhvcsdck")
  const {
    loadMoreOptions,
    totalCounts,
    isMulti,
    value,
    updateTag,
    openModalCb,
    loadMoreCT,
    isMultiCT,
    openCTModal,
    reference_to,
    plan,
    ...restReference
  } = extensionFieldOption

  const { isMarketplaceEnabled = false } = plan || {}

  const displayNameRef: any = useRef(null)
  const [error, setError] = useState(fieldUidRegex(schema.uid, schema))

  useEffect(() => {
    displayNameRef?.current?.focus()
  }, [displayNameRef])

  const [initialSchema, setSchema] = useState(schema)
  const { display_name, uid, field_metadata } = schema
  // const [extension, setExtension] = useState(schema.reference_val)
  const [extension, setExtension] = useState(
    schema.extension_uid ? [{ label: schema.extension_name, value: schema.extension_uid }] : []
  )

  const preSelectedVal = isMultiCT
    ? reference_to.length
      ? reference_to.map((val) => {
        return {
          id: val,
          label: val,
          title: val
        }
      })
      : []
    : reference_to

  const [referenceCT, setReferenceCT] = useState(field_metadata.ref_multiple_content_types ? preSelectedVal : [])

  const configVal = typeof schema.config == 'object' ? JSON.stringify(schema.config) : schema.config

  const onchange = () => {
    onChange && onChange(schema)
    setSchema({ ...schema })
  }

  const onDisplayNameChange = (event: any) => {
    schema.display_name = event.target.value

    if (schema.hasOwnProperty('display_name') && schema.newfield) {
      schema.uid = schema.display_name
        .trim()
        .replace(/[^A-Z0-9]+/gi, '_')
        .toLowerCase()
      if (schema.display_name) {
        schema.error_details = {
          ...schema.error_details,
          uid: ''
        }
      }
    }

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.display_name.length) {
      schema.error_details = {
        ...schema.error_details,
        display_name: 'Display Name cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        display_name: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onUidChange = (event: any) => {
    setError(fieldUidRegex(event.target?.value?.trim(), schema))

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.uid.length) {
      schema.error_details = {
        ...schema.error_details,
        uid: 'uid cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        uid: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onInstructionChange = (event: any) => {
    field_metadata.instruction = event.target.value
    onchange()
  }
  const onHelpTextChange = (event: any) => {
    field_metadata.description = event.target.value
    onchange()
  }
  const onConfigParamChange = (value: any) => {
    schema.config = value
    onchange()
  }
  const onOptionsChange = (event: any) => {
    if (event.target.name === `${uniqueKey}.mandatory`) {
      schema.mandatory = event.target.checked
    }
    if (event.target.name === `${uniqueKey}.nonLocalization`) {
      schema.non_localizable = event.target.checked
    }
    onchange()
  }

  const handleRefUpdate = (selectedRefs: any) => {
    if (selectedRefs && selectedRefs.contentModal) {
      setExtension([
        {
          label: selectedRefs.contentModal.title,
          value: selectedRefs.uid
        }
      ])
    } else {
      setExtension([])
    }

    schema.extension_uid = selectedRefs.uid
    schema.extension_name = selectedRefs.contentModal && selectedRefs.contentModal.title
    if (selectedRefs.contentModal) {
      if (selectedRefs.contentModal.data_type === 'reference') {
        schema.field_metadata.ref_multiple_content_types = true
        if (!schema.reference_to) {
          schema.reference_to = []
          schema.error_details = {
            ...schema.error_details,
            reference_to: 'Please select a Content type'
          }
          schema.error = true
        }
      } else {
        delete schema.field_metadata.ref_multiple_content_types
        delete schema.reference_to
        setReferenceCT([])
        if (schema.error_details) {
          delete schema.error_details.reference_to
        }
      }
    } else {
      delete schema.field_metadata.ref_multiple_content_types
      delete schema.reference_to
      setReferenceCT([])
      if (schema.error_details) {
        delete schema.error_details.reference_to
      }
    }

    if (schema && !schema.extension_uid) {
      schema.error_details = {
        ...schema.error_details,
        extension_uid: 'Please select an Extension'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        extension_uid: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }

  const handleCTRefUpdate = (selectedRefs: any) => {
    let newSelectedRef = selectedRefs.length
      ? selectedRefs.map((val) => {
        return {
          id: val,
          label: val,
          title: val
        }
      })
      : []

    setReferenceCT(newSelectedRef)
    schema.reference_to = selectedRefs

    if (schema.reference_to && !schema.reference_to.length) {
      schema.error_details = {
        ...schema.error_details,
        reference_to: 'Please select a Content type'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        reference_to: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }

  if (type === 'basic') {
    if (isMarketplaceEnabled)
      return (
        <div>
          <div className="FormFields">
            <div className="FormFields__column">
              <Field>
                <FieldLabel required htmlFor="displayName">
                  {props.t('content_type.save_content_type.body.fields.custom.fields.display_name.label')}
                </FieldLabel>
                <TextInput
                  required
                  name={`${uniqueKey}.displayName`}
                  value={display_name}
                  onChange={onDisplayNameChange}
                  inputRef={displayNameRef}
                  error={schema.error && schema.error_details.display_name ? true : false}
                  placeholder={props.t(
                    'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                  )}
                  testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.DISPLAY_NAME_INPUT)}
                />
              </Field>
            </div>
            <div className="FormFields__column">
              <Field>
                <FieldLabel required htmlFor="uid">
                  {props.t('content_type.save_content_type.body.fields.custom.fields.uid.label')}
                </FieldLabel>
                <Help text={props.t('content_type.save_content_type.body.fields.custom.fields.uid.help_text')} />
                <TextInput
                  required
                  name={`${uniqueKey}.uid`}
                  value={uid}
                  onChange={onUidChange}
                  error={(schema.error && schema.error_details.uid) || error ? true : false}
                  placeholder={props.t(
                    'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                  )}
                  testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.UID_INPUT)}
                />
              </Field>
            </div>
          </div>

          <div className="FormFields FormFields--full">
            <div className="FormFields__column FormFields__column--full">
              <Field>
                <FieldLabel htmlFor="extensionField" required>
                  {props.t('content_type.save_content_type.body.fields.custom.fields.select_extension.label')}
                </FieldLabel>
                <TagAsSelect
                  placeholder={props.t(
                    'content_type.save_content_type.body.fields.custom.fields.select_extension.placeholder'
                  )}
                  isMulti={isMulti}
                  showEmptyState={true}
                  showCount={true}
                  showSingleItem={true}
                  tags={extension ? extension : []}
                  updateTag={handleRefUpdate}
                  openModalCb={(callback) => openModalCb(callback)}
                  {...restReference}
                />
                {schema.error && schema.error_details.extension_uid ? (
                  <ValidationMessage className={'error'}>{schema.error_details.extension_uid}</ValidationMessage>
                ) : null}
              </Field>
            </div>

            {field_metadata.ref_multiple_content_types ? (
              <div className="FormFields__column FormFields__column--full">
                <Field>
                  <FieldLabel htmlFor="extensionField">
                    {props.t('content_type.save_content_type.body.fields.custom.fields.referenced_ct.label')}
                  </FieldLabel>
                  <TagAsSelect
                    placeholder={'Select'}
                    showEmptyState={true}
                    isMulti={true}
                    showCount={true}
                    tags={referenceCT.length ? referenceCT : []}
                    updateTag={handleCTRefUpdate}
                    openModalCb={(callback) => openCTModal(callback)}
                    {...restReference}
                  />
                  {schema.error && schema.error_details.reference_to ? (
                    <ValidationMessage className={'error'}>{schema.error_details.reference_to}</ValidationMessage>
                  ) : null}
                </Field>
              </div>
            ) : (
                <></>
              )}
            <div className="FormFields__column FormFields__column--full">
              <Field>
                <FieldLabel htmlFor="helpText">
                  {props.t('content_type.save_content_type.body.fields.custom.fields.help_text.label')}
                </FieldLabel>
                <Help text={props.t('content_type.save_content_type.body.fields.custom.fields.help_text.help_text')} />
                <Textarea
                  name={`${uniqueKey}.helpText`}
                  value={field_metadata.description || ''}
                  onChange={onHelpTextChange}
                  placeholder={props.t(
                    'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                  )}
                  testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.HELP_TEXT_INPUT)}
                />
              </Field>
            </div>
            <div className="FormFields__column FormFields__column--full">
              <Field>
                <FieldLabel htmlFor="instruction">
                  {props.t('content_type.save_content_type.body.fields.custom.fields.instruction.label')}
                </FieldLabel>
                <Help
                  text={props.t('content_type.save_content_type.body.fields.custom.fields.instruction.help_text')}
                />
                <Textarea
                  name={`${uniqueKey}.instruction`}
                  value={field_metadata.instruction || ''}
                  onChange={onInstructionChange}
                  placeholder={props.t(
                    'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                  )}
                  testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.HELP_TEXT_INPUT)}
                />
              </Field>
            </div>
          </div>
        </div>
      )
    else {
      return (
        <div className="FormFields">
          <div className="FormFields__column">
            <Field>
              <FieldLabel required htmlFor="displayName">
                Display Name
              </FieldLabel>
              <TextInput
                required
                name={`${uniqueKey}.displayName`}
                value={display_name}
                onChange={onDisplayNameChange}
                inputRef={displayNameRef}
                error={schema.error && schema.error_details.display_name ? true : false}
                placeholder={props.t(
                  'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                )}
                testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.DISPLAY_NAME_INPUT)}
              />
            </Field>
            <Field>
              <FieldLabel htmlFor="helpText">Help Text</FieldLabel>
              <Help text="Provide additional info about the field. The help text will appear when the user hovers on the ‘?’ sign beside the field name." />
              <Textarea
                name={`${uniqueKey}.helpText`}
                value={field_metadata.description || ''}
                onChange={onHelpTextChange}
                placeholder={props.t(
                  'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                )}
                testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.DESCRIPTION_INPUT)}
              />
            </Field>
            <Field>
              <FieldLabel htmlFor="extensionField">Select Extension</FieldLabel>
              <TagAsSelect
                placeholder={'Select'}
                isMulti={isMulti}
                showEmptyState={true}
                showCount={true}
                showSingleItem={true}
                tags={extension ? extension : []}
                updateTag={handleRefUpdate}
                openModalCb={(callback) => openModalCb(callback)}
                {...restReference}
                testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.REFERENCE_TAG_AS_SELECT)}
              />
              {schema.error && schema.error_details.extension_uid ? (
                <ValidationMessage className={'error'}>{schema.error_details.extension_uid}</ValidationMessage>
              ) : null}
            </Field>
          </div>
          <div className="FormFields__column">
            <Field>
              <FieldLabel required htmlFor="uid">
                Unique ID
              </FieldLabel>
              <Help text="A unique ID for this field. This should not be same as the ID of any other field in this content type." />
              <TextInput
                required
                name={`${uniqueKey}.uid`}
                value={uid}
                onChange={onUidChange}
                error={schema.error && schema.error_details.uid ? true : false}
                placeholder={props.t(
                  'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                )}
                testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.UID_INPUT)}
              />
            </Field>
            <Field>
              <FieldLabel htmlFor="instruction">Instruction Value</FieldLabel>
              <Help text="Helps you add instructions for this field. The instructional text will appear below the field." />
              <Textarea
                name={`${uniqueKey}.instruction`}
                value={field_metadata.instruction || ''}
                onChange={onInstructionChange}
                placeholder={props.t(
                  'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                )}
                testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.INSTRUCTION_INPUT)}
              />
            </Field>
            {field_metadata.ref_multiple_content_types ? (
              <Field>
                <FieldLabel htmlFor="extensionField">Referenced Content Type</FieldLabel>
                <TagAsSelect
                  placeholder={'Select'}
                  showEmptyState={true}
                  isMulti={true}
                  showCount={true}
                  tags={referenceCT.length ? referenceCT : []}
                  updateTag={handleCTRefUpdate}
                  openModalCb={(callback) => openCTModal(callback)}
                  {...restReference}
                  testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.EXTENTION_TAG_AS_SELECT)}
                />
                {schema.error && schema.error_details.reference_to ? (
                  <ValidationMessage className={'error'}>{schema.error_details.reference_to}</ValidationMessage>
                ) : null}
              </Field>
            ) : (
                <></>
              )}
          </div>
        </div>
      )
    }
  } else if (type === 'advanced') {
    return (
      <>
        <div className="FormFields">
          <Field>
            <FieldLabel htmlFor="options">Options</FieldLabel>
            <div className="FormFields__selection-wrapper">
              <Checkbox
                name={`${uniqueKey}.mandatory`}
                text="Mandatory"
                checked={schema.mandatory}
                onChange={onOptionsChange}
              />
              {hasNonLocalizablePlan ? (parentInfo.hasParent ? !parentInfo.isMulti : true) ? (
                <>
                  {' '}
                  <Checkbox
                    name={`${uniqueKey}.nonLocalization`}
                    text="Non-localizable"
                    checked={schema.non_localizable}
                    onChange={onOptionsChange}
                  />{' '}
                  <div className="nl-note">
                    If enabled, editing this field is restricted in localized entries. The field will use the value
                    of the master-language entry in all localized entries.
                  </div>{' '}
                </>
              ) : null : null}
            </div>
          </Field>
        </div>
        <div className="FormFields">
          <Field className="AceEditorField">
            <FieldLabel htmlFor="configParam">Config Parameter</FieldLabel>
            <Help text="The config pamameter that you specify here is applicable only for this instance of the custom field." />
            <AceEditor
              mode="json"
              theme="github"
              value={configVal}
              onChange={onConfigParamChange}
              name={`${uniqueKey}.config`}
              minLines={8}
              maxLines={20}
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true
              }}
            />
          </Field>
        </div>
      </>
    )
  }
}

export default React.memo(Custom)
//@ts-nocheck
import { Editor, Transforms, Node } from 'slate'
import { generateId } from '../../../utils/hooks/withId'
import { isSelectionCollapsed } from '../../../utils/queries'
import styles from './style.module.css'

export const LIST_TYPES = ['ol', 'ul']

export const listWrapperElement = (format) => {
  return { type: format, children: [], id: generateId() }
}

export const toggleList = (event, editor, format) => {
  event?.preventDefault()
  const selection = editor.selection || editor.savedSeleciton
  if (selection) {
    // incase selection is from savedSelection
    Transforms.select(editor, selection)

    if (isSelectionCollapsed(selection)) {
      const [currentElement, currentElementPath] = Editor.parent(editor, selection)
      // if it already a list
      if (currentElement.type === 'li') {
        const listType = Editor.parent(editor, currentElementPath)

        // different list then convert
        if (listType?.[0]?.type !== format) {
          Transforms.setNodes(editor, { type: format }, { at: listType[1] })
        }
        return
      }

      // if prevSibling is also a same list
      if (currentElementPath[currentElementPath.length - 1] > 0) {
        const prevSiblingPath = currentElementPath.slice()
        prevSiblingPath[prevSiblingPath.length - 1] -= 1

        const [prevSibling] = Editor.node(editor, prevSiblingPath)
        if (prevSibling.type === format) {
          Transforms.setNodes(editor, {
            type: 'li'
          })

          //@ts-ignore
          const movePosition = [...prevSiblingPath, prevSibling.children.length]
          Transforms.moveNodes(editor, { to: movePosition })
          return
        }
      }
    } else {
      let fixed = false

      // already a list
      for (const [, listItemPath] of Editor.nodes(editor, {
        match: (n) => n.type === 'li'
      })) {
        fixed = true
        const [listType, listTypePath] = Editor.parent(editor, listItemPath)
        if (listType.type !== format) {
          Transforms.setNodes(editor, { type: format }, { at: listTypePath })
        }
      }

      if (fixed) return
    }

    // create a new list
    Editor.withoutNormalizing(editor, () => {
      Transforms.setNodes(editor, {
        type: 'li'
      })
      const block = listWrapperElement(format)
      Transforms.wrapNodes(editor, block)
    })
  }
}

export const setListTypeAttrs = (props) => {
  const attrs = {};
  if (props?.element?.attrs?.["redactor-attributes"]) {
    attrs['type'] = props?.element?.attrs?.["redactor-attributes"]?.type;
    attrs['className'] = getTypeClassName(props?.element?.attrs?.["redactor-attributes"].type);
  }
  return attrs;
}

export const getTypeClassName = (type) => {
  let styleType = 'type-auto';
  switch (type) {
    case 'a':
      styleType = 'type-lower-alpha';
      break;
    case 'A':
      styleType = 'type-upper-alpha';
      break;
    case 'i':
      styleType = 'type-lower-roman';
      break;
    case 'I':
      styleType = 'type-upper-roman';
      break;
  }
  return styles[styleType];
}

import React, { useEffect, useState, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import './Breadcrumb.css'
import Tooltip from '../Tooltip/Tooltip'
import { truncate } from 'lodash'
interface IBreadcrumbItem {
  name: React.ReactNode
  action?: (breadcrumbItem) => void
  path: string
}

interface IProps {
  maxWidth?: number
  tooltipPosition?: string
  breadcrumbItems: Array<IBreadcrumbItem>
  testId?: string
  truncateLimit?: number,
  isResponsive?: boolean
}

const Breadcrumb = (props: IProps) => {
  // const { breadcrumbItems = [] } = props;
  const { breadcrumbItems = [], isResponsive, tooltipPosition, maxWidth, testId, truncateLimit } = props;

  const totalItemsLength = breadcrumbItems.length;
  const [tooltipFlags, setTooltipFlags] = useState([]);
  const breadcrumbLinkRef: any = useRef([]);
  const breadcrumbWrapperRef: any = useRef([]);

  useEffect(() => {
    const offsetWidthLimitFlags = [];

    if (!isResponsive) {
      breadcrumbLinkRef?.current?.forEach(function (element: any) {
        offsetWidthLimitFlags.push(element?.offsetWidth >= props.maxWidth)
      })
      setTooltipFlags([...offsetWidthLimitFlags])
    }
  }, [breadcrumbItems])

  useEffect(() => {
    if (isResponsive) {
      const breadcrumbWrapper = breadcrumbWrapperRef?.current?.offsetWidth;
      const finalMaxWidth = `${(Math.floor((breadcrumbWrapper / breadcrumbItems.length)) - 20)}px`
      breadcrumbLinkRef?.current?.forEach(function (element: any) {
        element?.style?.setProperty('max-width', finalMaxWidth);
      })
    }
  }, [breadcrumbItems])

  function getBreadcrumbPath(breadcrumbArray) {
    const names = breadcrumbArray.map((item) => item.name);
    return names.join('/');
  }

  const breadcrumbPath = getBreadcrumbPath(breadcrumbItems);

  if (truncateLimit && breadcrumbItems.length >= truncateLimit) {
    const truncatedItems = breadcrumbItems.slice(0, 2).concat(breadcrumbItems.slice(-2));
    const ellipsis = <Tooltip content={breadcrumbPath} position={tooltipPosition}><span className='Breadcrumb__link--ellipses'>...&nbsp;/</span></Tooltip>;

    return (
      <div className="Breadcrumb" data-test-id={testId}>
        <span className='Breadcrumb__link flex-v-center'>
          {truncatedItems.map((item, index) => (
            <>
              {tooltipFlags?.length && tooltipFlags[index] ? (
                <Tooltip content={item.name} position={tooltipPosition}>
                  <span
                    onClick={item.action ? item.action : undefined}
                    ref={(el) => (breadcrumbLinkRef.current[index] = el)}
                    style={{ maxWidth: maxWidth }}
                    className="Breadcrumb__link--text"
                  >
                    {item.name}
                  </span>
                </Tooltip>
              ) : (
                <span
                  ref={(el) => (breadcrumbLinkRef.current[index] = el)}
                  className="Breadcrumb__link--text"
                  style={{ maxWidth: maxWidth }}
                >
                  {item.name}
                </span>
              )}
              {index < truncatedItems.length - 1 && ' / '}
              {index === 1 && ellipsis}
            </>
          ))}
        </span>
      </div>
    );
  }

  return (
    <div className="Breadcrumb" ref={breadcrumbWrapperRef} data-test-id={props.testId}>
      {breadcrumbItems.map((breadcrumbItem: IBreadcrumbItem, index: number) => {
        let isCurrentPage = index === totalItemsLength - 1

        if (isCurrentPage) {
          const name: any = breadcrumbItem.name
          return (
            <>
              {tooltipFlags?.length && tooltipFlags[index] ? (
                <Tooltip content={name} position={props.tooltipPosition}>
                  <span ref={el => breadcrumbLinkRef.current[index] = el} key={index} style={{ maxWidth: props.maxWidth }} className="Breadcrumb__link--text">{name}</span>
                </Tooltip>
              ) : (
                <>
                  {isResponsive && typeof name === 'string' ?
                    <span title={name} ref={el => breadcrumbLinkRef.current[index] = el} className="Breadcrumb__link--text" key={index} style={{ maxWidth: props.maxWidth }}>
                      {name}
                    </span> :
                    <span ref={el => breadcrumbLinkRef.current[index] = el} className="Breadcrumb__link--text" key={index} style={{ maxWidth: props.maxWidth }}>
                      {name}
                    </span>
                  }
                </>
              )}
            </>
          )
        }
        return (
          <>
            <span className="Breadcrumb__link flex-v-center" key={index}
              onClick={() => breadcrumbItem.action ? breadcrumbItem.action(breadcrumbItem) : undefined}
            >
              {tooltipFlags?.length && tooltipFlags[index] ? (
                <Tooltip content={breadcrumbItem.name} position={props.tooltipPosition}>
                  <span ref={el => breadcrumbLinkRef.current[index] = el} className="Breadcrumb__link--text " style={{ maxWidth: props.maxWidth }}>{`${breadcrumbItem.name}`}</span>
                </Tooltip>
              ) : (
                <>
                  {isResponsive && typeof breadcrumbItem.name === 'string' ?
                    <span title={breadcrumbItem.name} ref={el => breadcrumbLinkRef.current[index] = el} className="Breadcrumb__link--text" style={{ maxWidth: props.maxWidth }}>
                      {`${breadcrumbItem.name}`}
                    </span> :
                    <span ref={el => breadcrumbLinkRef.current[index] = el} className="Breadcrumb__link--text" style={{ maxWidth: props.maxWidth }}>
                      {`${breadcrumbItem.name}`}
                    </span>
                  }
                </>
              )
              }
              <span className="Breadcrumb__link--slash">{`/`}</span>
            </span>
          </>
        )
      })}
    </div>
  )
}

let withRouterBreadcrumb = withRouter(Breadcrumb)
Breadcrumb.defaultProps = {
  maxWidth: 500,
  tooltipPosition: "bottom",
  testId: 'cs-breadcrumb',
  isResponsive: false
}
export default Breadcrumb
export { withRouterBreadcrumb }

{/*
 <>
                  {isResponsive && typeof breadcrumbItem.name === 'string' ?
                    <span title={breadcrumbItem.name} ref={el => breadcrumbLinkRef.current[index] = el} className="Breadcrumb__link--text" style={{ maxWidth: props.maxWidth }}>
                      {`${breadcrumbItem.name}`}
                    </span> :
                    <span ref={el => breadcrumbLinkRef.current[index] = el} className="Breadcrumb__link--text" style={{ maxWidth: props.maxWidth }}>
                      {`${breadcrumbItem.name}`}
                    </span>
                  }
                </> 
              */}
import { jsx } from 'slate-hyperscript'

import { isInlineElement } from '../../../../utils/queries'
import { LIST_TYPES } from '../../../Element/list/utils'
import { isEmpty } from 'lodash'

interface skipStatus {
  "font-size": boolean,
  color: boolean,
  "font-weight": boolean,
  "font-style": boolean,
  "text-decoration": boolean,
  "background-color": boolean
}

const getElementStyle = (el) => {
  let style = {}
  if (el && el.style && el.style['text-align']) {
    style['text-align'] = el.style['text-align']
  }
  if (el && (el.dir || (el.style && el.style['direction']))) {
    style['direction'] = el.dir ? el.dir : el.style['direction']
  }
  return style
}

const HeadingElements = ["H1", "H2", "H3", "H4", "H5", "H6"]

const updateSkipStatusForTextTransformation = (skipStatus: skipStatus, el) => {
  const isHyperlink = el && el.parentNode && el.parentNode.nodeName && el.parentNode.nodeName === "A"
  const isHeading = HeadingElements.includes(el && el.parentNode && el.parentNode.nodeName && el.parentNode.nodeName)
  if (isHyperlink) {
    skipStatus['background-color'] = true
    skipStatus.color = true
    skipStatus['font-style'] = true
    skipStatus['text-decoration'] = true
  } else if (isHeading) {
    skipStatus['font-size'] = true
  }
}

const modifyLineBreak = (children: Array<any>) => {
  if (!children.length) {
    return
  }
  let newChildren = []
  const containsInlineElement = children.some((value) => isInlineElement(value) && !(value && value.break))
  const containsBreakElement = children.some((value) => value.break)
  if (containsBreakElement && children.length === 1) {
    return children
  }
  if (!containsInlineElement && containsBreakElement) {
    for (const element of children) {
      if (element && element.break) {
        newChildren.push(jsx('element', { type: 'p', attrs: {} }, [{ text: "" }]))
      } else {
        newChildren.push(element)
      }
    }
    return newChildren
  } else if (containsInlineElement && containsBreakElement) {
    for (const element of children) {
      if (element && element.break) {
        newChildren.push({ text: "\n" })
      } else {
        newChildren.push(element)
      }
    }
    return newChildren
  }
  return children
}

const spanningAttrs = (el: HTMLElement) => {
  const attrs = {}
  const rowSpan = parseInt(el.getAttribute('rowspan') ?? '1')
  const colSpan = parseInt(el.getAttribute('colspan') ?? '1')
  if (rowSpan > 1) attrs['rowSpan'] = rowSpan
  if (colSpan > 1) attrs['colSpan'] = colSpan

  return attrs
}

const emptyCell = (cellType, attrs = {}) => {
  return jsx('element', { type: cellType, attrs: { void: true, ...attrs } }, [{ text: '' }])
}

const addVoidCellsAndApplyAttributes = (rows) => {
  rows.forEach((row, currIndex) => {
    const cells = row.children

    cells.forEach((cell, cellIndex) => {
      if (!cell || !cell.attrs) return

      const { rowSpan, inducedRowSpan } = cell.attrs

      let span = rowSpan ?? inducedRowSpan ?? 0

      if (!span || span < 2) return
      const nextRow = rows[currIndex + 1]
      if (!nextRow) {
        delete cell?.attrs?.inducedRowSpan
        return
      }

      // set inducedRowSpan on cell in row at cellIndex
      span--
      nextRow?.children?.splice(cellIndex, 0,
        emptyCell('td',
          (span > 1) ? { inducedRowSpan: span } : {}
        ))

      // Include next row in trgrp
      nextRow['attrs']['included'] = true

      // Make a new trgrp
      if (rowSpan) {
        row['attrs']['origin'] = true
      }

      delete cell?.['attrs']?.['inducedRowSpan']

    })
  })
}

const getTbodyChildren = (rows) => {
  const newTbodyChildren = rows.reduce((tBodyChildren, row, rowIndex) => {

    const { included, origin } = row.attrs
    const l = tBodyChildren.length

    if (included || origin) {

      if (origin && !included) {
        tBodyChildren.push(jsx('element', { type: 'trgrp' }, row))
      }
      if (included) {
        tBodyChildren[l - 1].children.push(row)

      }
      delete row['attrs']['included']
      delete row['attrs']['origin']
      return tBodyChildren
    }

    tBodyChildren.push(row)
    return tBodyChildren

  }, [])
  return newTbodyChildren
}

const ELEMENT_TAGS = {
  A: (el) => ({
    type: 'a',
    attrs: {
      url: el.getAttribute('href') || '#'
    }
  }),
  BLOCKQUOTE: (el) => ({ type: 'blockquote', attrs: {} }),
  H1: (el) => ({ type: 'h1', attrs: { style: getElementStyle(el) } }),
  H2: (el) => ({ type: 'h2', attrs: { style: getElementStyle(el) } }),
  H3: (el) => ({ type: 'h3', attrs: { style: getElementStyle(el) } }),
  H4: (el) => ({ type: 'h4', attrs: { style: getElementStyle(el) } }),
  H5: (el) => ({ type: 'h5', attrs: { style: getElementStyle(el) } }),
  H6: (el) => ({ type: 'h6', attrs: { style: getElementStyle(el) } }),
  HR: (el) => ({ type: 'hr', attrs: { style: getElementStyle(el) } }),

  IMG: el => {
    return ({ type: 'img', attrs: { url: el.getAttribute('src'), width: 100 } })
  },
  LI: (el) => ({ type: 'li', attrs: { style: getElementStyle(el) } }),
  OL: (el) => ({ type: 'ol', attrs: { style: getElementStyle(el) } }),
  P: (el) => ({ type: 'p', attrs: { style: getElementStyle(el) } }),
  PRE: (el) => ({ type: 'code', attrs: { style: getElementStyle(el) } }),
  UL: (el) => ({ type: 'ul', attrs: { style: getElementStyle(el) } }),
  TABLE: (el) => ({ type: 'table', attrs: {} }),
  THEAD: (el) => ({ type: 'thead', attrs: {} }),
  TBODY: (el) => ({ type: 'tbody', attrs: {} }),
  TR: (el) => ({ type: 'tr', attrs: {} }),
  TD: (el) => ({ type: 'td', attrs: {} }),
  TH: (el) => ({ type: 'th', attrs: {} }),
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.

const whiteCharPattern = /^[\s ]+$/
export const deserialize = (el: HTMLElement, options: any = {}) => {
  if (el.nodeType === 3) {
    if (whiteCharPattern.test(el.textContent)) return null
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return { "text": "\n", break: true }
  } else if (el.nodeName === 'META') {
    return null
  }

  const { nodeName } = el
  let parent = el
  if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
    // @ts-ignore
    parent = el.childNodes[0]
  }
  let children = Array.from(parent.childNodes).map((child: any) => deserialize(child, options)).flat()

  children = children.filter((child) => child !== null && child !== undefined)
  children = modifyLineBreak(children)

  if (el.nodeName === 'BODY') {
    if (LIST_TYPES.includes(children?.[0]?.type)) {
      children.unshift({ type: 'p', children: [{ text: '' }] })
    }
    return jsx('fragment', {}, children)
  }

  if (ELEMENT_TAGS[nodeName]) {
    let attrs = ELEMENT_TAGS[nodeName](el)
    if (attrs['type'] === 'li' && el.childNodes[0]?.nodeName === 'P') {
      Array.from(el.childNodes[0].childNodes).forEach((child) => {
        // @ts-ignore
        const text = deserialize(child, options)
        return jsx('element', attrs, text)
      })
    }
    if (nodeName === 'IMG') {
      return jsx('element', attrs, [{ text: '' }])
    }
    if (nodeName === 'HR') {
      return jsx('element', attrs, [{ text: '' }])
    }
    if (nodeName === 'TABLE') {
      let row = 0
      // get number of rows by looking at table_child children length
      row = el.querySelectorAll('TR').length
      let colWidths = []
      let col = 0
      let totalWidth = 0
      if (el.childNodes[0] && el.childNodes[0].nodeName === 'COLGROUP') {
        if (el.childNodes[1] && el.childNodes[1].childNodes[0]) {
          col = el.childNodes[1].childNodes[0].childNodes.length
        }
        Array.from(el.childNodes[0].childNodes).forEach((child: any) => {
          if (child.width) {
            totalWidth += parseInt(child.width)
          }
        })
        if (totalWidth !== 0) {
          Array.from(el.childNodes[0].childNodes).forEach((child: any) =>
            colWidths.push((child.width * 250 * col) / totalWidth)
          )
        }
      } else if (el.childNodes[0] && el.childNodes[0].childNodes[0]) {
        col = el.childNodes[0].childNodes[0].childNodes.length
      }
      if (totalWidth === 0) {
        colWidths = Array.from({ length: col }).fill(250)
      }

      let tableHead
      let tableBody

      children.forEach((tableChild) => {
        if (tableChild?.type === 'thead') {
          tableHead = tableChild
          return
        }
        if (tableChild?.type === 'tbody') {
          tableBody = tableChild
          return
        }
      });

      let disabledCols = [...tableHead?.attrs?.disabledCols ?? [], ...tableBody?.attrs?.disabledCols ?? []]
      delete tableHead?.attrs?.disabledCols
      delete tableBody?.attrs?.disabledCols

      attrs = {
        ...attrs,
        attrs: {
          ...attrs.attrs,
          rows: row,
          cols: col,
          colWidths: colWidths,
          ...(disabledCols?.length && { disabledCols: Array.from(new Set(disabledCols)) })
        }
      }
    }

    if (["THEAD", "TBODY"].includes(nodeName)) {
      const rows = children
      const disabledCols = rows.flatMap(row => {
        if (!row.attrs) return []
        const { disabledCols } = row.attrs
        delete row['attrs']['disabledCols']
        return disabledCols ?? []
      })
      attrs.attrs['disabledCols'] = disabledCols
    }

    if (nodeName === "TBODY") {

      addVoidCellsAndApplyAttributes(children)

      children = getTbodyChildren(children)
    }

    if (nodeName === "TR") {
      const cells = children.filter(child => ['th', 'td'].includes(child.type))

      const disabledCols = cells.flatMap((cell, cellIndex) => {
        let { colSpan } = cell.attrs
        if (!colSpan) return []
        colSpan = parseInt(colSpan)
        return Array(colSpan).fill(0).map((_, i) => cellIndex + i)
      })

      if (disabledCols?.length)
        attrs.attrs['disabledCols'] = disabledCols
    }

    if (nodeName === "TD" || nodeName === "TH") {
      let textFormat = {}
      if (el.style?.['font-weight'] === 'bold') {
        textFormat = { ...textFormat, bold: true }
      }

      if (el.style?.['font-style'] === 'italic') {
        textFormat = { ...textFormat, italic: true }
      }

      if (el.style?.['text-decoration'] === 'underline') {
        textFormat = { ...textFormat, underline: true }
      }

      if (el.style?.['text-decoration'] === 'line-through') {
        textFormat = { ...textFormat, strikethrough: true }
      }
      const spannedAttrs = spanningAttrs(el)

      if (!isEmpty(spannedAttrs)) {
        attrs = {
          ...attrs,
          attrs: {
            ...attrs['attrs'],
            ...spannedAttrs,
            "redactor-attributes": spannedAttrs
          },
        }
      }

      if (!children || children.length === 0) {
        return [{ text: '' }]
      }

      children = children.map((child) => {
        if (typeof child === 'string' || child.nodeType === 3) return jsx('text', { ...textFormat }, child)
        else if (child.text !== undefined) {
          const newChild = {
            ...child,
            attrs: { ...child.attrs },
            ...textFormat
          }
          return newChild
        } else {
          return child
        }
      })

      const { colSpan = 1, rowSpan } = attrs?.['attrs']

      const tableCell = [
        jsx('element', attrs, children),
        ...Array(colSpan - 1)
          .fill(0)
          .map((_) => emptyCell(nodeName.toLowerCase(), rowSpan ? { inducedRowSpan: rowSpan } : {}))
      ]
      return tableCell
    }

    if (!children || children.length === 0) {
      return [{ text: '' }]
    }
    return jsx('element', attrs, children)
  }

  // if (TEXT_TAGS[nodeName]) {
  //   const attrs = TEXT_TAGS[nodeName](el)

  //   return children.map((child) => jsx('text', attrs, child))
  // }
  // Google docs handles all text-transformation through span.
  if (nodeName === 'SPAN') {
    let attrs = { style: {} }
    let textFormat = {}
    const skipStyles = {
      "font-size": false,
      color: false,
      "font-weight": false,
      "font-style": false,
      "text-decoration": false,
      "background-color": false,
      "vertical-align": false
    }
    if (options && options.disableFontSize) {
      skipStyles["font-size"] = true
    }
    if (options && options.disableColor) {
      skipStyles.color = true
    }
    if (options && options.disableBackgroundColor) {
      skipStyles["background-color"] = true
    }
    updateSkipStatusForTextTransformation(skipStyles, el)
    if (el.style['vertical-align'] === 'sub' && !skipStyles['vertical-align']) {
      textFormat = { ...textFormat, subscript: true }
    }
    if (el.style['vertical-align'] === 'super' && !skipStyles['vertical-align']) {
      textFormat = { ...textFormat, superscript: true }
    }
    if (el.style['font-size'] && !skipStyles['font-size']) {
      attrs = {
        ...attrs,
        style: {
          ...attrs.style,
          'font-size': el.style['font-size']
        }
      }
    }
    if (el.style['color'] && el.style['color'] !== "rgb(0, 0, 0)" && !skipStyles.color && el.closest('a') === null) {
      attrs = {
        ...attrs, style: {
          ...attrs.style, color: el.style['color']
        }
      }
    }
    if (el.style['font-weight'] === '700' && !skipStyles['font-weight']) {
      textFormat = { ...textFormat, bold: true }
    }
    if (el.style['font-style'] === 'italic' && !skipStyles['font-style']) {
      textFormat = { ...textFormat, italic: true }
    }
    if (el.style['text-decoration'] === 'underline' && !skipStyles['text-decoration']) {
      textFormat = { ...textFormat, underline: true }
    }
    if (el.style['text-decoration'] === 'line-through' && !skipStyles['text-decoration']) {
      textFormat = { ...textFormat, strikethrough: true }
    }



    if (el.style['background-color'] && el.style['background-color'] !== 'transparent' && !skipStyles['background-color']) {
      textFormat = { ...textFormat, mark: true }
    }

    if (attrs !== undefined && el.childNodes.length === 1 && el.childNodes[0].nodeName === '#text') {
      return jsx('text', { attrs: attrs, ...textFormat }, children)
    }
  }
  return children
}

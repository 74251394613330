import React from 'react'

class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    const { children, selection, history } = this.props.editor ?? {}
    if(this.props.onError){
      this.props.onError(error, { children, history, selection })
    }
    else{
      console.error(error, { children, history, selection })
    }
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}

export default ErrorBoundary

import React, { useRef } from 'react'
import './SkipLink.css'

interface SkipLinkProperties {
  className?: string
  selectorAttribute?: string
  attributeValue: string
  text?: string
  tagName?: string
  onFocusCb?: Function
  onBlurCb?: Function
}

const SkipLink: React.FC<SkipLinkProperties> = (props) => {
  const { selectorAttribute, attributeValue, className, text, tagName, onFocusCb, onBlurCb } = props
  const focussedContainer = useRef(null)
  const clickHandler = (event: React.SyntheticEvent) => {
    event.preventDefault()
    focusToMainContent()
  }
  const getQueryToSelect = (): string => {
    let query = attributeValue
    if (selectorAttribute) {
      query = `[${selectorAttribute}=${attributeValue}]`
    }
    if (tagName) {
      query = `${tagName}${query}`
    }
    return query
  }
  const focusToMainContent = () => {
    const query = getQueryToSelect()
    focussedContainer.current = document.querySelector(query)
    if (focussedContainer.current) {
      focussedContainer.current.focus()
    }
  }
  const skipLinkOnFocus = (e: any) => {
    onFocusCb && onFocusCb(e)
  }
  const skipLinkOnBlur = (e: any) => {
    onBlurCb && onBlurCb(e)
  }


  return (
    <button className={className} onClick={clickHandler} onFocus={skipLinkOnFocus} onBlur={skipLinkOnBlur}>
      {text}
    </button>
  )
}

SkipLink.defaultProps = {
  className: 'skip-link',
  text: 'Skip to main content',
  tagName: ''
}

export default SkipLink

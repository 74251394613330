import React, {
    Component,
    CSSProperties,
    MouseEvent as ReactMouseEvent,
    MouseEventHandler,
    Fragment,
  } from 'react'
  import cn from 'classnames'
  import './CircularLoader.css';
  
  
  const defaultProps = {
    size: 'small',
  }
  
  type DefaultProps = Readonly<typeof defaultProps>
  
  export type CirleLoaderProps = {
  
    loadingColor?: string
    size?: string,
    style?: CSSProperties
  } & Partial<DefaultProps>
  
  class CircularLoader extends Component<CirleLoaderProps> {
    public static propTypes: any = {}
    static defaultProps = defaultProps
    
    render() {
      const {
        size,
        style = {}
      }: any = this.props;

      const classNames = cn('CircularLoader', {
        [`CircularLoader--size-${size}`]: size
      })
      
      return (
        <div style={style} className={classNames}></div>
      )
    }
  }
  export default CircularLoader
  
//@ts-nocheck
import React from 'react'
import { Editor, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

import { ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup } from '../../../../../../../../index'
import { Editor as ModifiedEditor } from '../../../../../utils/types'
import Dropable from './Upload/Dropable'
import { Upload } from './Upload'
import { uploadImage } from '../request'
import './style.css'
import { insertImage } from '../../../image'
import { checkProps } from '../../utils/checkProps'
import { InvalidPropsMsg } from '../../utils/InvalidPropsMessage/InvalidPropsMsg'

export const getImageMarkdown = (href, text = 'Enter your image caption here') => {
  text = text === '' ? href : text
  return `![${text}](${href})`
}

declare interface ModifiedEditorSecond extends ModifiedEditor, ReactEditor { }

export declare type ModalComponentProps = {
  editor: ModifiedEditorSecond
  closeModal: Function
}

declare type image = {}

export default class extends React.Component<ModalComponentProps> {
  state = {
    folderUid: 'cs_root',
    loading: false,
  }

  setFolderUid = (uid) => {
    this.setState({
      folderUid: uid,
    })
  }

  handleSubmit = (res) => {
    res.forEach((instance) => {
      const asset = instance.asset
      const url = asset?.url
      const filename = asset?.filename
      const editor = this.props.editor
      const selection = editor.selection || editor.savedSelection

      const isEditorBlur = !selection;
      if (isEditorBlur) {
        ReactEditor.focus(editor);
        Transforms.select(editor, Editor.end(editor, []));
      } else {
        Transforms.select(editor, selection);
      }
      if (!url) {
      } else {
        insertImage(editor, url, {
          alt: filename
        })
      }
    })
    this.handleReset()
  }

  handleReset = () => {
    if (!ReactEditor.isFocused(this.props.editor)) {
      let editor = this.props.editor;
      if (editor.savedSelection)
        Transforms.select(editor, editor.savedSelection)
    }
    this.props.closeModal()
  }

  setLoading = isLoading => this.setState({ loading: isLoading });

  handleUpload = (files, uid = '') => {
    const requestProps: Object = this.props.editor.requestProps || {};
    let folderUid = uid.length !== 0 ? uid : this.state.folderUid;
    folderUid = folderUid === 'cs_root' ? '' : folderUid;
    this.setState({ loading: true });
    uploadImage({ ...requestProps, files: files, folderUid })
      .then(res => { this.handleSubmit(res); this.setLoading(false) })
      .catch(err => this.setLoading(false));
  };

  render() {
    const editor = this.props.editor
    const isPropsValid = checkProps({ editor })
    return (
      <div id="scrte-image-modal">
        <ModalHeader title="Select a folder to upload asset(s)" closeModal={this.handleReset} />

        <ModalBody className="modalBodyCustomClass">
          {isPropsValid ? (
            <Upload loading={this.state.loading} editor={this.props.editor} handleUpload={this.handleUpload} setFolderUid={this.setFolderUid} />
          ) : (
            <InvalidPropsMsg asset="Images" />
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={this.handleReset} buttonType="light" testId={"asset-modal-reset"}>
              Cancel
            </Button>
            <Dropable config={{ noDrag: true, multiple: true, accept: 'image/*', onDrop: this.handleUpload }}>
              <Button icon='Upload' loading={this.state.loading}>Upload Here</Button>
            </Dropable>
          </ButtonGroup>
        </ModalFooter>
      </div>
    )
  }
}

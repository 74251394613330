import React, { Component, useEffect, useState } from 'react'

import Icon from '../Icon2/Icon'
import Search from '../Search/Search'
import cbModal, { ModalFooter, ModalHeader, ModalBody } from '../Modal/Modal'
import Button from '../Button/Button'
import ButtonGroup from '../Button/ButtonGroup/ButtonGroup'
import TextInput from '../TextInput/TextInput'
import EmptyState from '../EmptyState/EmptyState'
import ActionTooltip from '../ActionTooltip/ActionTooltip'
import SkeletonTile from '../SkeletonTile/SkeletonTile'

import { notify } from './util/common'
import { validateAdvanceQuery } from './util/advanceQueryEntries'
import ValidationMessage from '../ValidationMessage/ValidationMessage'
import { truncate } from './util/helpers'
import SearchTooltip from './SearchTooltip'
import { viewAllCtaId } from './util/constants'

const SkeletonLoader = ({ size = 4 } = {}) => {
  return (
    <div>
      {Array(size)
        .fill(1)
        .map((data, index) => {
          return (
            <div className="flex-v-center" key={index}>
              <SkeletonTile
                numberOfTiles={1}
                tileHeight={10}
                tileWidth={140}
                tileRadius={6}
                tileBottomSpace={20}
                tileTopSpace={20}
                tileleftSpace={18}
              />
            </div>
          )
        })}
    </div>
  )
}

const SaveViewCountLoader = () => {
  return (
    <SkeletonTile
      numberOfTiles={1}
      tileHeight={18}
      tileWidth={18}
      tileRadius={6}
      tileBottomSpace={0}
      tileTopSpace={0}
      tileleftSpace={10}
    />
  )
}

const DeleteSavedView = (props) => {
  const [loading, setLoading] = useState(false)

  const onDeletSavedView = async () => {
    try {
      const newData = { uid: props.search.uid }
      setLoading(true)
      await props.deleteSavedView(newData, props.search)
      /**
       * Mixpanel event, metadata capture
       **/
      const { query, search_text, type, name } = props.search

      let eventName = `Delete a saved search`
      let eventMetadata = {
        search_text,
        search_name: name,
        query: query,
        module: type,
      }
      props.userAnalytics.trackEvent(eventName, eventMetadata)
      setLoading(false)
      props.closeModal()
    } catch (error) {
      console.log('DeleteSavedView', error)
      setLoading(false)
    }
  }

  const onCancel = () => {
    /**
     * Mixpanel event, metadata capture
     **/
    const { query, search_text, type, name } = props.search

    let eventName = `Cancel ‘Delete a saved search'`
    let eventMetadata = {
      search_text,
      search_name: name,
      query: query,
      module: type,
    }
    props.userAnalytics.trackEvent(eventName, eventMetadata)
    props.closeModal()
  }

  return (
    <div className="ReactModal__delete">
      <ModalHeader title={props.textContent.modals.delete_saved_search.title} closeModal={props.closeModal} />
      <ModalBody>
        <p className="delete-alert">
          Are you sure you want to permanently delete <strong>'{props.search.name}'</strong> ?
        </p>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button aria-label="Cancel" buttonType="light" onClick={onCancel}>
            Cancel
          </Button>
          <Button aria-label="Delete Saved Search" buttonType="delete" icon="Delete" onClick={onDeletSavedView} loading={loading}>
            <span>Delete</span>
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </div>
  )
}

const InlineForm = (props) => {
  let [name, updateName] = useState(props.search.name || '')
  let [error, setError] = useState('')

  useEffect(() => {
    const hanldekeyBindings = (event) => {
      const { key } = event

      if (key === 'Enter') {
        OnSubmitSavedView()
      }
    }

    document.addEventListener('keyup', hanldekeyBindings, false)

    return () => {
      document.removeEventListener('keyup', hanldekeyBindings, false)
    }
  }, [name])

  const validate = (value) => {
    if (!value) {
      setError(props.textContent.validations.saved_searches.search_name_empty)
      return false
    }

    if (value.length > 50) {
      setError(props.textContent.validations.saved_searches.search_name_length)
      return false
    }

    setError('')
    return true
  }

  const OnSubmitSavedView = async () => {
    try {
      const isValid = validate(name)

      if (!isValid || props.loading) return

      let newData = { name, uid: props.search.uid }
      await props.onSumbit(newData, props.search)
      props.onCancel()
    } catch (error) {
      console.log('InlineForm error', error)
    }
  }

  const handleNameChange = (e) => {
    const value = e.target.value.trim()
    updateName(value)
    validate(value)
  }

  return (
    <div className="w-100">
      <div className="AdvancedSearch__savedViews-item" key={props.search.uid || 'create'}>
        <TextInput
          type="text"
          value={name}
          onChange={handleNameChange}
          autoFocus
          placeholder="Provide a name*"
          error={!!error}
          className={!!error ? 'TextInput--error' : ''}
        />
        <Icon disabled={props.disabled} onClick={() => { props.onCancel(props.search) }} icon="CancelCircle" className="Icon__cancel-circle" />
        <Icon
          disabled={props.disabled}
          onClick={OnSubmitSavedView}
          icon="CheckedCircle"
          className="Icon__checked-cirle"
        />
      </div>
      <div className="error-message">{!!error && <ValidationMessage>{error}</ValidationMessage>}</div>
    </div>
  )
}

const initialState = {
  isSavedViewOpen: false,
  mode: 'default',
  disabled: false,
  editingIndex: -1,
  searchText: '',
  loading: false,
}

class SavedViews extends Component<any, any> {
  state = {
    ...initialState,
  }

  isCursorInside = false
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, false)
  }

  componentDidUpdate(prevProps) {
    if (this.state.isSavedViewOpen && !prevProps.isSearchVisible && this.props.isSearchVisible) {
      this.setState({ isSavedViewOpen: false, searchText: '' })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false)
  }

  handleClickOutside = (event) => {
    if (!this.isCursorInside && this.state.isSavedViewOpen && event.target.id !== viewAllCtaId) {
      console.log('clikc out side detected')
      this.setState(initialState)
    }
  }

  openSavedView = async () => {
    try {
      if (!this.state.isSavedViewOpen) {
        if (!this.props.canFetchSavedAndRecentSearch) {
          this.setState({ loading: true, isSavedViewOpen: true })
          await this.props.setSavedAndRecentSearch()
          this.setState({ loading: false })
        } else {
          this.setState({ isSavedViewOpen: true })
        }
      }
    } catch (error) {
      console.log("SavedViews -> error", error)
    }
  }

  closeSavedView = () => {
    this.setState(initialState)
    let eventName =
      this.props.queryCase === 'basicQuery' ? `Cancel 'Save a quick search'` : `Cancel ‘Save an advanced search’`
    let eventMetadata = {}
    this.props.userAnalytics.trackEvent(eventName, eventMetadata)
  }

  setMode = () => {
    this.setState({ mode: 'edit' })
  }

  setDefaultMode = () => {
    this.setState({ mode: 'default', viewName: '' })
    /**
     * Mixpanel event, metadata capture
     **/
    let eventName =
      this.props.queryCase === 'basicQuery' ? `Cancel 'Save a quick search'` : `Cancel ‘Save an advanced search’`
    let eventMetadata = {}
    this.props.userAnalytics.trackEvent(eventName, eventMetadata)
  }

  //handle edit of saved view
  updateSavedView = async (data, search) => {
    try {
      console.log('SavedViews -> data', data, search)
      const { query, search_text, type } = search

      let updatedData = { saved_search: { ...data } }
      this.setState({ loading: true, disabled: true })

      await this.props.updateSavedView({ data: updatedData })
      await this.props.fetchAllviews()

      /**
       * Mixpanel event, metadata capture
       **/
      let eventName = 'Edit a saved search'
      let eventMetadata = {
        search_text,
        search_name: data.name,
        query: query,
        module: type,
      }
      this.props.userAnalytics.trackEvent(eventName, eventMetadata)

      notify(this.props.textContent.notification.update_saved_search, 'success')
      this.setState({ disabled: false, loading: false })
    } catch (error) {
      console.log('updateSavedView', error)
      this.setState({ disabled: false, loading: false })
    }
  }

  deleteSavedView = async (data) => {
    try {
      this.setState({ disabled: true, loading: true })
      await this.props.deleteSavedView({ data })
      await this.props.fetchAllviews()

      notify(this.props.textContent.notification.delete_saved_search, 'success')
      this.setState({ disabled: false, loading: false })
    } catch (error) {
      console.log('deleteSavedView', error)
      this.setState({ disabled: false, loading: false })
    }
  }

  cancelEditView = (search) => {
    this.setState({ editingIndex: -1 })
    const { query, search_text, type, name } = search || {}
    /**
     * Mixpanel event, metadata capture
     **/
    let eventName =
      this.props.queryCase === 'basicQuery' ? `Cancel 'Save a quick search'` : `Cancel ‘Save an advanced search’`
    let eventMetadata = {
      query,
      search_text,
      search_name: name,
      module: type
    }
    this.props.userAnalytics.trackEvent(eventName, eventMetadata)
  }

  onDeleteView = (data) => {
    cbModal({
      component: (props) => (
        <DeleteSavedView
          userAnalytics={this.props.userAnalytics}
          {...props}
          search={data}
          deleteSavedView={this.deleteSavedView}
          textContent={this.props.textContent}
        />
      ),
      modalProps: {
        shouldCloseOnOverlayClick: true,
        // onClose,
      },
    })
  }

  handleSubmitOfView = async (data) => {
    try {
      // console.log("handleSubmitOfView create -> data", data)
      this.setState({ disabled: true, loading: true })
      const viewName = data.name
      await this.props.createSavedView(viewName)
      notify(this.props.textContent.notification.success_saved_search, 'success')

      this.setState({
        disabled: false,
        isSavedViewOpen: false,
        mode: 'default',
        viewName: '',
        loading: false
      })
    } catch (error) {
      console.log("handleSubmitOfView -> error", error)
      const { data } = error || {}
      if (data.error_code === 357) {
        notify(data.error_message, 'error')
      }
      this.setState({ disabled: false, loading: false })
    }
  }

  handleSearchChange = (value) => {
    this.setState({ searchText: value.trim() || '' })
  }

  handleSavedSearchClick = (search) => {
    this.props.handleSavedSearchClick(search)
    this.closeSavedView()
  }

  render() {
    let savedViewsLength = this.props.savedSearches.length
    const mode = this.state.mode
    const actionList = [
      {
        label: <Icon icon="Edit" />,
        title: 'Edit',
        action: (event, data) => {
          event.stopPropagation()
          // console.log('SavedViews ->edit data', data)
          this.setState({ editingIndex: data.index })
        },
      },
      {
        label: <Icon icon="Trash" />,
        title: 'Delete',
        action: (event, data) => {
          event.stopPropagation()
          this.onDeleteView(data)
        },
      },
    ]

    let queryArray = this.props.queryArray
    let isValidQuery = queryArray.length > 0 && queryArray[queryArray.length - 1].isQueryCompleted

    if (this.props.queryCase === 'advanceQuery') {
      let errorObject = {
        isQueryEmpty: true,
        isErrorCursorFound: false,
      }

      validateAdvanceQuery(this.props.advanceQueryArray, errorObject)

      if (!errorObject.isQueryEmpty && !errorObject.isErrorCursorFound) {
        queryArray = this.props.advanceQueryArray
        isValidQuery = true
      }
    }

    const filteredSaveViewLength = this.props.savedSearches.filter((sv) => sv.name.includes(this.state.searchText))
      .length

    const SaveViewsList = () => {
      if (this.state.loading) {
        return (
          <div className={`AdvancedSearch__savedViews-items__list`}>
            <SkeletonLoader />
          </div>
        )
      }

      if (filteredSaveViewLength) {
        return (
          <div
            className={`AdvancedSearch__savedViews-items__list ${filteredSaveViewLength === 1 ? 'AdvancedSearch__savedViews-items__list--visible' : ''
              }`}
          >
            {this.props.savedSearches.map((search, index) => {
              if (!search.name.includes(this.state.searchText)) return null

              const { isOverflow, truncatedText } = truncate(search.name, 47)

              return this.state.editingIndex === index && !this.state.loading ? (
                <InlineForm
                  loading={this.state.loading}
                  textContent={this.props.textContent}
                  search={search}
                  onSumbit={this.updateSavedView}
                  onCancel={this.cancelEditView}
                  disabled={this.state.disabled}
                  type="edit"
                />
              ) : (
                <ActionTooltip list={actionList} data={{ ...search, index }} key={index}>
                  <li className="AdvancedSearch__savedViews-item" onClick={() => this.handleSavedSearchClick(search)}>
                    {isOverflow ? (
                      <SearchTooltip content={search.name}>
                        <div className="AdvancedSearch__savedViews-item__name">{truncatedText}</div>
                      </SearchTooltip>
                    ) : (
                      <div className="AdvancedSearch__savedViews-item__name">{search.name}</div>
                    )}
                  </li>
                </ActionTooltip>
              )
            })}
          </div>
        )
      } else {
        return (
          <EmptyState
            heading={
              this.state.searchText.length
                ? this.props.textContent.saved_views.no_search_result
                : this.props.textContent.saved_views.no_result
            }
            description={this.state.searchText.length ? '' : this.props.textContent.saved_views.no_result_description}
            actions={
              <>
                <div
                  className="mt-20 EmptyState__link"
                  onClick={() => window.open(this.props.textContent.links.learn_more_saved_searches)}
                >
                  Learn more
                </div>
              </>
            }
            displayImage={false}
            // moduleIcon='EntryModuleLarge'
            type="secondary"
          />
        )
      }
    }

    return (
      <div
        className="relative"
        onMouseOver={() => {
          this.isCursorInside = true
        }}
        onMouseOut={() => {
          this.isCursorInside = false
        }}
      >
        <div tabIndex={0}
          onKeyDown={(e: any) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault()
              e.stopPropagation()
              this.state.isSavedViewOpen ? this.closeSavedView() : this.openSavedView();
            }
            if (e.shiftKey && e.key === "Tab" && this.state.isSavedViewOpen) {
              this.closeSavedView()
            }
          }} id='AdvanceSearchSaveViewIcon' className={`AdvancedSearch__savedViews-icon ${!this.props.savedSearches.length && this.props.canFetchSavedAndRecentSearch ? 'AdvancedSearch__savedViews-disabledIcon' : ''}`} onClick={this.openSavedView}>
          <SearchTooltip content={!this.props.savedSearches.length && this.props.canFetchSavedAndRecentSearch ? 'No Saved Searches' : this.props.textContent.tooltips.saved_views} position="top">
            <Icon icon="Disk" />
          </SearchTooltip>
        </div>
        {this.state.isSavedViewOpen && (
          <div className="AdvancedSearch__savedViews-dropdown">
            {/* saved views header part */}
            <div>
              <div className="AdvancedSearch__savedViews__heading flex-v-center flex-justify">
                <div className="info-heading">
                  {this.props.textContent.saved_views.title}{' '}
                  {this.state.loading ? <SaveViewCountLoader /> : <div>{filteredSaveViewLength}</div>}
                </div>
                <SearchTooltip content={this.props.textContent.tooltips.close_view} position="top">
                  <div tabIndex={0} onKeyDown={(e: any) => {
                    if (e.key === "Enter" || e.key === " ") {
                      e.preventDefault()
                      e.stopPropagation()
                      this.closeSavedView()
                      document.getElementById('AdvanceSearchSaveViewIcon')?.focus()
                    }
                  }} onClick={this.closeSavedView} className=" flex-v-center flex-h-center AdvancedSearch__savedViews__heading__cancel">
                    <Icon icon="Cancel" />
                  </div>
                </SearchTooltip>
              </div>

              {savedViewsLength ? (
                <Search
                  onChange={this.handleSearchChange}
                  width="full"
                  placeholder={this.props.textContent.placeholders.saved_views}
                  debounceSearch={true}
                  type="secondary"
                />
              ) : null}
            </div>
            {/* Saved views body */}

            <ul
              className={`AdvancedSearch__savedViews-items ${savedViewsLength === 0 ? 'AdvancedSearch__savedViews-items--emptyState' : ''
                }`}
            >
              {mode === 'default' && isValidQuery && (
                <div
                  className="AdvancedSearch__savedViews-item AdvancedSearch__savedViews-item--action"
                  onClick={this.setMode}
                >
                  <Icon icon="AddCircle" />
                  <div className="AdvancedSearch__savedViews-item--action__save">
                    {this.props.textContent.saved_views.save_current_search_btn_txt}
                  </div>
                </div>
              )}
              {mode === 'edit' && !this.state.loading && (
                <div className="AdvancedSearch__savedViews-item--action">
                  <InlineForm
                    loading={this.state.loading}
                    textContent={this.props.textContent}
                    search={{}}
                    onSumbit={this.handleSubmitOfView}
                    onCancel={this.setDefaultMode}
                    disabled={this.state.disabled}
                    type="create"
                  />
                </div>
              )}
              <SaveViewsList />
            </ul>
          </div>
        )}
      </div>
    )
  }
}

export default SavedViews


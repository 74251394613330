//@ts-nocheck
import React, { useState } from 'react'
import { Transforms, Node, Editor } from 'slate'
import { ReactEditor } from 'slate-react'
import { ModalBody, ModalHeader, ModalFooter, Button, ButtonGroup, Icon } from '../../../../../../index'

import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';
import { ImageEditForm } from './ImageEditForm'
//@ts-ignore
import styles from './style.module.css'
import { handleImageError } from './withImage'

export const ImageEditModal = ({ element, editor, type, ...props }) => {
  const [updatedFormData, setUpdatedFormData] = useState()
  const [hasError, setHasError] = useState(false)

  const updateFormData = (formData) => {
    setUpdatedFormData(formData)
  }

  const handleFormSubmit = (values) => {
    let fields = { ...values }

    if (fields['inline']) {
      if (fields['position'] === 'center' || !fields['position']) {
        fields['position'] = 'left'
      }
    }
    const path = ReactEditor.findPath(editor, element)

    const newAttributes = {
      ...element.attrs,
      "redactor-attributes": {
        ...element.attrs?.["redactor-attributes"]
      },
      style: {
        ...element.attrs?.style
      }
    }
    Object.entries(fields).forEach(([f, v]) => {
      const field = f
      const value: any = v
      if (value && value !== '') {
        newAttributes["redactor-attributes"][field] = value
        if (field === 'inline') {
          newAttributes['inline'] = value
          newAttributes.style['width'] = Number(newAttributes['width']) ? `${newAttributes['width']}px` : newAttributes['width']
        }

        if (field === 'alt') {
          if (type === 'asset')
            newAttributes['asset-alt'] = value.trim();
          else
            newAttributes['alt'] = value
        }

        if (field === 'url') {
          newAttributes['url'] = value
        }
        if (field === 'caption' || field === 'anchorLink') {
          if (type === 'asset') {
            if (field === 'caption')
              newAttributes['asset-caption'] = value.trim();
            else newAttributes['link'] = value.trim();
          }
          else
            newAttributes[field] = value.trim()
        }

        if (field === 'position') {
          if (type === 'asset') {
            newAttributes['position'] = value;
          }
          if (value === 'none') {
            newAttributes["redactor-attributes"]['position'] = 'none'
            delete newAttributes.style["text-align"]
            delete newAttributes.style["text-align"]
            delete newAttributes.style["float"]
            delete newAttributes.style["width"]
            delete newAttributes.style["max-width"]
            delete newAttributes['max-width']
          } else {
            newAttributes.style["text-align"] = value
            newAttributes["redactor-attributes"]['position'] = value

            if (value !== 'center') {
              if (newAttributes['max-width']) {
                newAttributes.style["max-width"] = Number(newAttributes['max-width']) ? `${newAttributes['max-width']}px` : newAttributes['max-width']
                newAttributes['max-width'] = newAttributes['max-width']
                newAttributes.style["width"] = Number(newAttributes['width']) ? `${newAttributes['width']}px` : newAttributes['width']
                newAttributes['width'] = newAttributes.width
              }
              else {
                newAttributes.style["max-width"] = Number(newAttributes['max-width']) ? `${newAttributes['max-width']}px` : newAttributes['max-width']
                newAttributes['max-width'] = newAttributes['width']
              }
              newAttributes.style["float"] = value
            }
            else {
              if (newAttributes['max-width']) {
                newAttributes['width'] = newAttributes['max-width']
                delete newAttributes.style["float"]
                delete newAttributes.style["width"]
                delete newAttributes.style["max-width"]
                delete newAttributes['max-width']
              }
            }
          }
        }
        if (field === 'target') {
          newAttributes.target = value ? '_blank' : '_self';
        }
      } else {
        if (field === 'position') {
          delete newAttributes.style["text-align"]
        }
        delete newAttributes[field]
        delete newAttributes["redactor-attributes"][field]
      }
    })

    let node = Node.get(editor, path)
    let newNode = cloneDeep(node);
    newNode.attrs = newAttributes;
    if (newAttributes.inline && !Editor.isInline(editor, node)) {
      Transforms.removeNodes(editor, { at: path })
      const inlineImage = {
        type: 'p',
        children: [
          newNode,
        ]
      }
      Transforms.insertNodes(editor, inlineImage, { at: path })
    } else if (!newAttributes.inline && Editor.isInline(editor, node)) {
      // remove the image element from p tag in case inline is disabled after enabling it;
      // removing the node from p tag
      Transforms.removeNodes(editor, { at: path });
      // removing path last item
      path.pop();
      Transforms.insertNodes(editor, newNode, { at: path })
    } else {
      Transforms.setNodes(editor, { attrs: newAttributes }, { at: path })
    }

    props.closeModal()
  }

  return (
    <div>
      <ModalHeader title="Edit Image" closeModal={props.closeModal} />

      <ModalBody>
        <div className={styles['scrte-form-container']}>
          <div>
            <img src={type === 'asset' ? element.attrs?.['asset-link'] : element.attrs.url} className={styles['modal']} onError={handleImageError} data-testid={'scrte-image-element'} />
          </div>
          <div>
            <ImageEditForm
              type={type}
              className={styles['scrte-form']}
              attributes={{
                ...element?.attrs,
                ...element?.attrs?.["redactor-attributes"]
              }}
              updateFormData={updateFormData}
              hasError={{ value: hasError, callback: setHasError }}
            />
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button key="cancel" buttonType="light" onClick={() => props.closeModal()} data-testid={'scrte-cancel'}>
            Cancel
          </Button>
          <Button
            icon={'SaveWhite'}
            key="add"
            disabled={hasError}
            onClick={() => {
              handleFormSubmit(updatedFormData)
            }}
            data-testid={'scrte-submit'}
          >
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </div>
  )
}

import { cloneDeep, isEmpty } from 'lodash'
import { jsx } from 'slate-hyperscript'
import { LIST_TYPES } from '../../../Element/list/utils'
import { v4 } from 'uuid'

const spanningAttrs = (el: HTMLElement) => {
  const attrs = {}
  const rowSpan = parseInt(el.getAttribute('rowspan') ?? '1')
  const colSpan = parseInt(el.getAttribute('colspan') ?? '1')
  if (rowSpan > 1) attrs['rowSpan'] = rowSpan
  if (colSpan > 1) attrs['colSpan'] = colSpan

  return attrs
}

const emptyCell = (cellType, attrs = {}) => {
  return jsx('element', { type: cellType, attrs: { void: true, ...attrs } }, [{ text: '' }])
}


const addVoidCellsAndApplyAttributes = (rows) => {
  rows.forEach((row, currIndex) => {
    const cells = row.children

    cells.forEach((cell, cellIndex) => {
      if (!cell || !cell.attrs) return

      const { rowSpan, inducedRowSpan } = cell.attrs

      let span = rowSpan ?? inducedRowSpan ?? 0

      if (!span || span < 2) return
      const nextRow = rows[currIndex + 1]
      if (!nextRow) {
        delete cell?.attrs?.inducedRowSpan
        return
      }

      // set inducedRowSpan on cell in row at cellIndex
      span--
      nextRow?.children?.splice(cellIndex, 0,
        emptyCell('td',
          (span > 1) ? { inducedRowSpan: span } : {}
        ))

      // Include next row in trgrp
      nextRow['attrs']['included'] = true

      // Make a new trgrp
      if (rowSpan) {
        row['attrs']['origin'] = true
      }

      delete cell?.['attrs']?.['inducedRowSpan']

    })
  })
}

const getTbodyChildren = (rows) => {
  const newTbodyChildren = rows.reduce((tBodyChildren, row, rowIndex) => {

    const { included, origin } = row.attrs
    const l = tBodyChildren.length

    if (included || origin) {

      if (origin && !included) {
        tBodyChildren.push(jsx('element', { type: 'trgrp' }, row))
      }
      if (included) {
        tBodyChildren[l - 1].children.push(row)

      }
      delete row['attrs']['included']
      delete row['attrs']['origin']
      return tBodyChildren
    }

    tBodyChildren.push(row)
    return tBodyChildren

  }, [])
  return newTbodyChildren
}

const ELEMENT_TAGS = {
  A: el => ({ type: 'a', attrs: { url: el.getAttribute('href') || "#" } }),
  H1: () => ({ type: 'h1', attrs: {} }),
  H2: () => ({ type: 'h2', attrs: {} }),
  H3: () => ({ type: 'h3', attrs: {} }),
  H4: () => ({ type: 'h4', attrs: {} }),
  H5: () => ({ type: 'h5', attrs: {} }),
  H6: () => ({ type: 'h6', attrs: {} }),
  IMG: (el) => {
    return { type: 'img', attrs: { url: el.getAttribute('src') } }
  },
  LI: () => ({ type: 'li', attrs: {} }),
  OL: () => ({ type: 'ol', attrs: {} }),
  P: () => ({ type: 'p', attrs: {} }),
  PRE: () => ({ type: 'code', attrs: {} }),
  UL: () => ({ type: 'ul', attrs: {} }),
  IFRAME: (el) => ({ type: 'embed', attrs: { url: el.getAttribute('href') } }),
  TABLE: (el) => ({ type: 'table', attrs: {} }),
  THEAD: (el) => ({ type: 'thead', attrs: {} }),
  TBODY: (el) => ({ type: 'tbody', attrs: {} }),
  TR: (el) => ({ type: 'tr', attrs: {} }),
  TD: (el) => ({ type: 'td', attrs: {} }),
  TH: (el) => ({ type: 'th', attrs: {} }),
}

const getNextSibling = (elem) => {
  let allOLElements = []
  var sibling = elem?.nextSibling
  while (sibling?.getAttribute?.('class')?.includes('ListContainerWrapper')) {
    if (sibling && sibling.children[0].nodeName === 'OL') {
      allOLElements.push(sibling.children[0])
    }
    sibling = sibling?.nextSibling
  }
  return allOLElements
};

const generateId = () => v4().split('-').join('')

export const deserialize = (el) => {
  if (el.nodeType === 3) {
    if (
      el.parentElement.nodeName === 'BODY'
    ) {
      return null
    }
    return el.textContent
  } else if (el.nodeType !== 1 || el.nodeName === 'META') {
    return null
  } else if (el.nodeName === 'BR') {
    return { text: '\n', break: false, separaterId: generateId() }
  } else if (el.nodeName === 'O:P') {
    if (el.childNodes?.length) {
      return { text: '' }
    }
    return null
  }

  const { nodeName } = el
  let parent = el

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0]?.nodeName === 'CODE'
  ) {
    parent = el.childNodes[0]
  }
  let children = Array.from(parent.childNodes).map(deserialize).flat()
  children = children.filter((child) => child !== null)
  children = children.map((child) => {
    if (typeof child === 'string') {
      return child.replace(/\n/g, ' ')
    } else return child
  })
  if (children.length === 0) {
    if (nodeName === "A") {
      children = [{ text: '\n', break: false }]
    }
    else {
      children = [{ text: '' }]
    }
  }

  if (el.nodeName === 'BODY') {
    // if (LIST_TYPES.includes(children?.[0]?.type)) {
    //   children.unshift({ type: 'p', children: [{ text: '' }] })
    // }
    return jsx('fragment', {}, [...children])
  }

  if (el.nodeName === 'DIV' && el.getAttribute('class').includes('ListContainerWrapper')) {
    if (el.children[0]?.nodeName === "OL" && el.children[0]?.getAttribute('start') > "1" && el.children[0].getAttribute('class').includes('NumberListStyle1')) {
      return
    }
  }

  if (el.nodeName === "SPAN" && el.getAttribute('data-ccp-parastyle') === 'heading 1') {
    return jsx('element', { type: 'h1', attrs: {} }, children)
  }


  if (el.nodeName === "SPAN" && el.getAttribute('data-ccp-parastyle') === 'heading 2') {
    return jsx('element', { type: 'h2', attrs: {} }, children)
  }

  if (el.nodeName === "SPAN" && el.getAttribute('data-ccp-parastyle') === 'heading 3') {
    return jsx('element', { type: 'h3', attrs: {} }, children)
  }

  if (el.nodeName === "SPAN" && (el.getAttribute('data-ccp-parastyle') === 'Quote' || el.getAttribute('data-ccp-parastyle') === 'Intense Quote')) {
    return jsx('element', { type: 'blockquote', attrs: {} }, children)
  }

  if (ELEMENT_TAGS[nodeName]) {
    let attrs = ELEMENT_TAGS[nodeName](el)

    if (nodeName === 'OL' && el.getAttribute('start') === '1' && el?.parentElement?.nextSibling?.nodeName === "DIV" && el.parentElement.nextSibling.children[0]?.getAttribute('class').includes('NumberListStyle1')) {
      let olChildren = []
      olChildren.push(children[0])
      if (el.parentElement?.nodeName === 'DIV') {
        let allSiblingOLElements = getNextSibling(el.parentElement)
        Array.from(allSiblingOLElements).forEach((child) => {
          if (child.children[0].nodeName === 'LI') {
            olChildren.push(deserialize(child.children[0]))
          }
        })
        let attrs = ELEMENT_TAGS[nodeName](el)
        return jsx('element', attrs, olChildren)
      }
    }
    else if (nodeName === 'OL' && el.getAttribute('start') === '1' && el?.parentElement?.nextSibling?.nodeName === "DIV" && el.parentElement.nextSibling.children[0]?.getAttribute('class').includes('NumberListStyle2')) {
      return
    }

    if (nodeName === 'TABLE') {
      let row = 0,
        col
      row = el.querySelectorAll('TR').length
      const colElementLength = el.getElementsByTagName('COLGROUP')[0]?.children?.length ?? 0
      col = Math.max(...Array.from(el.getElementsByTagName('TR') as HTMLElement[]).map(row => row.children.length), colElementLength)

      let colWidths: Array<any> = Array.from({ length: col }).fill(250)

      Array.from(el.childNodes).forEach((child: any) => {
        if (child?.nodeName === 'COLGROUP') {
          let colGroupWidth = Array<number>(col).fill(250)
          let totalWidth = parseFloat(child.getAttribute('data-width')) || col * 250
          Array.from(child.children).forEach((child: any, index) => {
            if (child?.nodeName === 'COL') {
              let width = child?.style?.width ?? '250px'
              if (width.substr(-1) === '%') {
                colGroupWidth[index] = (parseFloat(width) * totalWidth) / 100
              } else if (width.substr(-2) === 'px') {
                colGroupWidth[index] = parseFloat(width)
              }
            }
          })
          colWidths = colGroupWidth
        }
      })

      let tableHead
      let tableBody

      children.forEach((tableChild) => {
        if (tableChild?.type === 'thead') {
          tableHead = tableChild
          return
        }
        if (tableChild?.type === 'tbody') {
          tableBody = tableChild
          return
        }
      });

      let disabledCols = [...tableHead?.attrs?.disabledCols ?? [], ...tableBody?.attrs?.disabledCols ?? []]
      delete tableHead?.attrs?.disabledCols
      delete tableBody?.attrs?.disabledCols

      attrs = {
        ...attrs,
        attrs: {
          ...attrs['attrs'],
          rows: row,
          cols: col,
          colWidths: colWidths,
          ...(disabledCols.length && { disabledCols: Array.from(new Set(disabledCols)) })
        }
      }
    }

    if (["THEAD", "TBODY"].includes(nodeName)) {
      const rows = children
      const disabledCols = rows.flatMap(row => {
        const { disabledCols } = row.attrs
        delete row['attrs']['disabledCols']
        return disabledCols ?? []
      })
      attrs.attrs['disabledCols'] = disabledCols
    }

    if (nodeName === "TBODY") {

      addVoidCellsAndApplyAttributes(children)

      children = getTbodyChildren(children)
    }

    if (nodeName === "TR") {
      const cells = children.filter(child => ['th', 'td'].includes(child.type))

      const disabledCols = cells.flatMap((cell, cellIndex) => {
        let { colSpan } = cell.attrs
        if (!colSpan) return []
        colSpan = parseInt(colSpan)
        return Array(colSpan).fill(0).map((_, i) => cellIndex + i)
      })

      if (disabledCols.length)
        attrs.attrs['disabledCols'] = disabledCols
    }

    if (nodeName === "TD" || nodeName === "TH") {
      let textFormat = {}
      if (el.style?.['font-weight'] === 'bold') {
        textFormat = { ...textFormat, bold: true }
      }

      if (el.style?.['font-style'] === 'italic') {
        textFormat = { ...textFormat, italic: true }
      }

      if (el.style?.['text-decoration'] === 'underline') {
        textFormat = { ...textFormat, underline: true }
      }

      if (el.style?.['text-decoration'] === 'line-through') {
        textFormat = { ...textFormat, strikethrough: true }
      }
      const spannedAttrs = spanningAttrs(el)

      if (!isEmpty(spannedAttrs)) {
        attrs = {
          ...attrs,
          attrs: {
            ...attrs['attrs'],
            ...spannedAttrs,
            "redactor-attributes": spannedAttrs
          },
        }
      }

      let childrenCopy = cloneDeep(children)
      let finalTdChildren = Array.from(childrenCopy).map((child) => {
        if (child['type']) {
          return child
        }
      })
      finalTdChildren = finalTdChildren.filter((child) => child !== undefined)

      children = finalTdChildren.map((child: any) => {
        if (typeof child === 'string' || child.nodeType === 3) return jsx('text', { ...textFormat }, child)
        else if (child.text !== undefined && child.text !== '') {
          const newChild = {
            ...child,
            attrs: { ...child.attrs },
            ...textFormat
          }
          return newChild
        } else {
          return child
        }
      })

      const { colSpan = 1, rowSpan } = attrs?.['attrs']

      const tableCell = [
        jsx('element', attrs, children),
        ...Array(colSpan - 1)
          .fill(0)
          .map((_) => emptyCell(nodeName.toLowerCase(), rowSpan ? { inducedRowSpan: rowSpan } : {}))
      ]
      return tableCell
    }
    return jsx('element', attrs, children)
  }

  if (el?.nodeName === 'SPAN') {
    let attrs = { style: {} }
    let textFormat = {}

    children = children.map((child) => {
      if (typeof child === 'string') {
        return child.replace(/\n/g, ' ')
      } else return child
    })
    //to skip all styles from children of <a>
    if (el.style['color'] && el.closest('a') === null) {
      attrs = {
        ...attrs,
        style: {
          ...attrs.style,
          color: el.style['color']
        }
      }
    }
    if (el.getAttribute('class').includes('Bold') || (el?.style?.['font-weight'] && el.style['font-weight'] === 'bold')) {
      textFormat = { ...textFormat, bold: true }
    }

    if (el.style['font-style'] === 'italic') {
      textFormat = { ...textFormat, italic: true }
    }

    if (el.getAttribute('class').includes('Underlined')) {
      textFormat = { ...textFormat, underline: true }
    }

    if (el.getAttribute('class').includes('Strikethrough')) {
      textFormat = { ...textFormat, strikethrough: true }
    }

    if (el.getAttribute('class').includes('Subscript')) {
      textFormat = { ...textFormat, subscript: true }
    }

    if (el.getAttribute('class').includes('Superscript')) {
      textFormat = { ...textFormat, superscript: true }
    }

    children = children.map((child) => {
      if (typeof child === 'string' || child.nodeType === 3)
        return jsx('text', { attrs, ...textFormat }, child)
      else if (child.text !== undefined) {
        const newChild = {
          ...child,
          attrs: { ...child.attrs, ...attrs },
          ...textFormat
        }

        return newChild
      } else {
        return child
      }
    })
  }

  return children
}
import React from 'react'
import cn from 'classnames'

export type TimelineMiddleProps = {
  className?: string
  style?: React.CSSProperties

}

const TimelineMiddle: React.FC<TimelineMiddleProps> = (props) => {
  const { children, className, style, } = props;
  const classNames = cn('TimelineMiddle', className)
  return (
    <div style={{ ...style }} className={classNames}>{children}</div>
  )
}
export default TimelineMiddle
import { IDiscussionDTO } from 'components/JsonRTE/SuperChargedRte/utils/types'
import { isPlainObject } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

interface IDiscussionListenerProps {
  path: string,
  setActiveDiscussion: (discussionUid: string) => void,
  discussions: { [key: string]: IDiscussionDTO },
  isFetchingDiscussions: boolean,
  isPlugin: boolean
}

interface setActiveDiscussionEvent extends Event {
  detail: {
    discussion: string,
  }
}

function useDiscussionListeners({ path, setActiveDiscussion, discussions, isFetchingDiscussions, isPlugin }: IDiscussionListenerProps) {
  function handleSetActiveDiscussion(event: setActiveDiscussionEvent) {

    // @ts-ignore
    const discussionUid = isPlugin ? event?.data?.detail?.discussion : event?.detail?.discussion
    if (!discussionUid || !isPlainObject(discussions)) return
    const discussion = Object.values(discussions).find(discussion => discussion?.uid === discussionUid)
    if (!discussion) return

    const targetDOM = document.querySelector(`[data-discussion-uid~="${discussionUid}"]`) as HTMLElement
    if (!targetDOM) return

    targetDOM.scrollIntoView({ block: 'center' })

    const isRangeComment = !discussion.field.block_uid
    isRangeComment ? setActiveDiscussion(`range-${discussionUid}`) : targetDOM.click()

    if (isPlugin) {
      window?.postRobot?.sendToParent?.(`activeDiscussionRteSet-${path}`)
    }
    else {
      // @ts-ignore
      event?.detail?.controller?.abort()
    }
  }

  const postRobotListener = useRef()

  useEffect(() => {
    if (isFetchingDiscussions || !path) return
    if (isPlugin) return
    window.addEventListener(`activeDiscussionRte-${path}`, handleSetActiveDiscussion)
    window.dispatchEvent(new CustomEvent(`${path}-load`))
    return () => {
      window.removeEventListener(`activeDiscussionRte-${path}`, handleSetActiveDiscussion)
    }
  }, [isFetchingDiscussions, path, JSON.stringify(discussions)])

  useEffect(() => {
    if (isFetchingDiscussions || !path) return

    if (isPlugin && window.postRobot) {
      // @ts-ignore
      postRobotListener?.current?.cancel?.()
      postRobotListener.current = window.postRobot.on(`activeDiscussionRte-${path}`, handleSetActiveDiscussion)
      window.postRobot.sendToParent(`${path}-load`);
    }
  }, [!!window.postRobot, isFetchingDiscussions, path, JSON.stringify(discussions)])
}

export default useDiscussionListeners
import React, { ReactNode } from 'react'
import cn from 'classnames'
import Icon from '../Icon2/Icon'
import Tooltip from '../Tooltip/Tooltip'
import './ListRow.css'
import Button from '../Button/Button'
import { Truncate } from '../Truncate/Truncate'

export type ListRowProps = {
  content: string | ReactNode
  active: boolean
  onClick?: Function
  onKeyDown?: any
  leftIcon?: React.ReactNode
  rightArrow?: boolean
  lockIcon?: boolean
  className?: string
  id?: string
  linkToRow?: string
  newEntryIcon?: boolean
  newEntryOnClick?: Function
  testId?: string,
  version?: "v1" | "v2"
}
const GetLeftIconContent = ({ content, lockIcon }) => {
  if (typeof content === 'string' && content.length > 15) {
    return (
      <Tooltip content={content} position="top" interactive={false}>
        <div className={`ListRow__content ${lockIcon ? 'ListRow__lock-width' : 'ListRow__content-width'}`}>
          {content}
        </div>
      </Tooltip>
    )
  }

  return (
    <div className={`ListRow__content ${lockIcon ? 'ListRow__lock-width' : 'ListRow__content-width'}`}>{content}</div>
  )
}
const GetContent = ({ content, lockIcon, leftIcon }) => {
  let listContent = null
  if (!leftIcon) {
    listContent =
      typeof content === 'string' && content.length > 20 ? (
        <Tooltip content={content} position="top" interactive={false}>
          <div className={`ListRow__content ${lockIcon ? 'ListRow__content-width' : 'ListRow__maxcontent-width'}`}>
            {content}
          </div>
        </Tooltip>
      ) : (
        <div className={`ListRow__content ${lockIcon ? 'ListRow__content-width' : 'ListRow__maxcontent-width'}`}>
          {content}
        </div>
      )
  }
  return listContent
}

const IconWrapper = ({ version, children }) => {
  if (version === "v2")
    return <div className='ListRowV2__icon-wrapper' >{children}</div>
  return <>{children}</>
}

const ListRowNode = ({ testId, onClickFunction, classNames, onKeyDown, id, linkToRow, leftIcon, content, lockIcon, rightArrow, newEntryIcon, newEntryClickFunction, version }) => {

  const ParentCN = version === "v2" ? "ListRowV2" : "ListRow";

  return (
    <div
      data-test-id={testId}
      onClick={onClickFunction}
      onKeyDown={onKeyDown}
      className={classNames}
      id={id}
      tabIndex={linkToRow ? null : 0}>
      {leftIcon && <span className={`${ParentCN}__left-icon`}>{leftIcon}</span>}
      {
        version === "v1" ?
          <>
            {leftIcon ? (
              <GetLeftIconContent content={content} lockIcon={lockIcon} />
            ) : (
              <GetContent content={content} lockIcon={lockIcon} leftIcon={leftIcon} />
            )}
          </> :
          <>
            {
              React.isValidElement(content) ? content :
                <Truncate isResponsive truncateFrom='end' >{content}</Truncate>
            }
          </>
      }
      {lockIcon || rightArrow || newEntryIcon ?
        <IconWrapper version={version} >
          {lockIcon && <Icon icon="Lock" className={`${ParentCN}__lock-icon`} version='v2' size="small" />}
          {rightArrow && <Icon icon="Right" className={`${ParentCN}__right-icon`} version='v2' size="medium" />}
          {newEntryIcon && (
            <>
              {version === "v2" ?
                <Button
                  tabIndex="-1"
                  className={`${ParentCN}__new-entry-wrapper`}
                  id="ListRow__new-entry-create-icon"
                  data-test-id="cs-listrow-create-new-entry"
                  onClick={newEntryClickFunction}
                  size="small"
                  buttonType="tertiary"
                  onlyIcon={true}
                  icon={'v2-NewEntries'}
                  version="v2"
                  onlyIconHoverColor='secondary'
                  iconProps={{
                    withTooltip: true,
                    tooltipContent: "Create Entry",
                    tooltipPosition: "top",
                    id: "ListRow__new-entry-create-icon",
                    className: `${ParentCN}__new-entry-create-icon`,
                    size: 'tiny'
                  }}
                />

                : <div
                  className={`${ParentCN}__new-entry-wrapper`}
                  id="ListRow__new-entry-create-icon"
                  data-test-id="cs-listrow-create-new-entry"
                  onClick={newEntryClickFunction}>
                  <Tooltip content="Create Entry" position="top">
                    <div className={`${ParentCN}__new-entry`} id="ListRow__new-entry-create-icon">
                      <Icon icon="NewEntry" id="ListRow__new-entry-create-icon" />
                    </div>
                  </Tooltip>
                </div>}
            </>

          )}
        </IconWrapper>
        : null}
    </div>
  )
}

const ListRow = (props: ListRowProps) => {
  const {
    active,
    leftIcon,
    rightArrow,
    lockIcon,
    className,
    onClick,
    id,
    onKeyDown,
    content,
    linkToRow,
    newEntryIcon,
    newEntryOnClick,
    testId = 'cs-list-row',
    version = "v1"
  } = props

  const onClickFunction = (event) => {
    if (onClick && !event.ctrlKey) {
      onClick(event)
    }
  }

  const newEntryClickFunction = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (newEntryOnClick) {
      newEntryOnClick(event, id)
    }
  }
  const isVersion2CN = version === "v2" ? "V2" : "";
  const classNames = cn(
    `ListRow${isVersion2CN}`,
    className,
    {
      [`ListRow${isVersion2CN}--active`]: active,
      [`ListRow${isVersion2CN}--newEntry--active`]: newEntryIcon
    })

  return (
    <>
      {linkToRow ? (
        <a href={linkToRow || undefined}
          className={`ListRow${isVersion2CN}-wrapper`}>
          <ListRowNode
            version={version}
            testId={testId} onClickFunction={onClickFunction}
            classNames={classNames}
            onKeyDown={onKeyDown}
            id={id}
            linkToRow={linkToRow}
            leftIcon={leftIcon}
            content={content}
            lockIcon={lockIcon}
            rightArrow={rightArrow}
            newEntryIcon={newEntryIcon}
            newEntryClickFunction={newEntryClickFunction}
          />
        </a>
      ) : (
        <ListRowNode
          version={version}
          testId={testId}
          onClickFunction={onClickFunction}
          classNames={classNames}
          onKeyDown={onKeyDown}
          id={id}
          linkToRow={linkToRow}
          leftIcon={leftIcon}
          content={content}
          lockIcon={lockIcon}
          rightArrow={rightArrow}
          newEntryIcon={newEntryIcon}
          newEntryClickFunction={newEntryClickFunction}
        />
      )}
    </>
  )
}

export default ListRow

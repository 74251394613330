const KEYS = {
  ENTER: 'Enter',
  SPACE: ' ',
  TAB: 'Tab',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ESCAPE: 'Escape',
}

export const isTabPressed = (key: string): boolean => (key === KEYS.TAB)

export const isEnterPressed = (key: string): boolean => (key === KEYS.ENTER)

export const isSpacePressed = (key: string): boolean => (key === KEYS.SPACE)

export const isEnterOrSpacePressed = (key: string): boolean => (isEnterPressed(key) || isSpacePressed(key))

export const isArrowUpPressed = (key: string): boolean => (key === KEYS.ARROW_UP)

export const isArrowDownPressed = (key: string): boolean => (key === KEYS.ARROW_DOWN)

export const isArrowLeftPressed = (key: string): boolean => (key === KEYS.ARROW_LEFT)

export const isArrowRightPressed = (key: string): boolean => (key === KEYS.ARROW_RIGHT)

export const isEscapePressed = (key: string): boolean => (key === KEYS.ESCAPE)
import './StackCard.css'
import React, { useMemo, useState } from 'react'
import Avatar, { AvatarData } from '../Avatar/Avatar'
import { iconName } from '../Icon2/constants'
import Icon from '../Icon2/Icon'
import Tooltip from '../Tooltip/Tooltip'
import cn from 'classnames'

const defaultProps = {
  title: 'Title',
  active: false,
  stats: [],
  avatars: [],
  type: 'image',
  modified: '',
  onActiveToggle: (state, event) => { },
  starActiveTooltip: 'Starred',
  starInActiveTooltip: 'Not starred',
  testId: 'cs-stackcard'
}

type DefaultProps = Readonly<typeof defaultProps>

type CardStats = {
  number: string
  category: string
}

export type StackCardProps = {
  title?: string
  active?: boolean
  stats: CardStats[]
  data?: any
  avatars?: AvatarData[]
  modified: string
  type?: 'text' | 'image'
  version?: 'v2'
  testId?: string
  starActiveTooltip?: string
  starInActiveTooltip?: string
  onActiveToggle: (state, event) => void
} & Partial<DefaultProps>

const StackCardFooter = ({ avatars, type, modified, version }) => {
  return useMemo(() => {
    return (
      <div className="StackCard__footer">
        {version === 'v2' ? (
          <Avatar avatars={avatars} staticCustomIcon={true} type={type} />
        ) : (
          <Avatar avatars={avatars} type={type} showPlusSignAfter={2} showExtraType="link" showExtraColor="dark" />
        )}
        {modified && (
          <Tooltip
            variantType={version === 'v2' ? 'basic' : 'dark'}
            showArrow={version === 'v2'}
            content="Last modified"
            position="top">
            <div className="StackCard__modified flex-v-center">
              {version === 'v2' ? (
                <Icon version="v2" icon={'ClockMedium'} />
              ) : (
                <Icon className="StackCard__modified-icon" icon={'ModifiedBy'} />
              )}
              <span className="StackCard__modified-date">{modified}</span>
            </div>
          </Tooltip>
        )}
      </div>
    )
  }, [modified, avatars, type])
}

const StackCard = (props: StackCardProps) => {
  let { title, active, stats, avatars, modified, onActiveToggle, type, data, version, testId } = props

  let [isActive, setIsActive] = useState(active)

  const handleStarClick = (event: any) => {
    setIsActive(!isActive)
    onActiveToggle({ isActive: !isActive, data }, event)
  }

  const returnDigitSize = (count: any) => {
    if (!count) {
      return 0
    }
    let formatNumber =
      Math.abs(Number(count)) >= 1.0e12
        ? (Math.abs(Number(count)) / 1.0e12).toFixed(2) + 'T'
        : Math.abs(Number(count)) >= 1.0e9
          ? (Math.abs(Number(count)) / 1.0e9).toFixed(2) + 'B'
          : Math.abs(Number(count)) >= 1.0e6
            ? (Math.abs(Number(count)) / 1.0e6).toFixed(2) + 'M'
            : Math.abs(Number(count)) >= 1.0e3
              ? (Math.abs(Number(count)) / 1.0e3).toFixed(2) + 'K'
              : Math.abs(Number(count))
    return formatNumber
  }
  const versionClass = `${version === 'v2' ? 'StackCard__v2' : ''}`

  const classNames = cn(['StackCard'], [versionClass])

  return (
    <div className={classNames} data-test-id={testId}>
      <div className="StackCard__heading">
        <h4 className="StackCard__title flex-v-center">
          {version === 'v2' && title?.length > 70 ? (
            <Tooltip variantType="basic" showArrow content={title} position="top">
              <span className="StackCard__title--truncate">{title}</span>
            </Tooltip>
          ) : (!version && title?.length > 18) || (title?.length > 15) ? (
            <Tooltip content={title} position="top">
              <span className="StackCard__title--truncate">{title}</span>
            </Tooltip>
          ) : (
            <span>{title}</span>
          )}
          <button
            className="StackCard__star-button"
            onClick={handleStarClick}
            aria-label={`${isActive ? `Click to unstar ${title} stack` : `Click to star ${title} stack`}`}>
            <Tooltip
              showArrow={version === 'v2'}
              variantType={version === 'v2' ? 'basic' : 'dark'}
              content={isActive ? props.starActiveTooltip : props.starInActiveTooltip}
              position="left">
              <Icon
                icon={iconName.Star}
                className={`StackCard__star-icon ${isActive ? ' StackCard__star-icon--active' : ''}`}></Icon>
            </Tooltip>
          </button>
        </h4>
      </div>
      <div className="StackCard__content">
        <div className="StackCard__stats">
          {stats.map((statsUnit, index) => {
            return (
              <div className="StackCard__stats-unit" key={index}>
                <span className="StackCard__stats-number">{returnDigitSize(statsUnit.number)}</span>
                <h5 className="StackCard__stats-category">{statsUnit.category}</h5>
              </div>
            )
          })}
        </div>
      </div>
      <StackCardFooter avatars={avatars} type={type} modified={modified} version={version} />
    </div>
  )
}

StackCard.defaultProps = defaultProps

export default StackCard

import React from 'react'
import { Registry } from '@react-registry'
import { useSlate, ReactEditor, useSelected } from 'slate-react'
//@ts-ignore
import styles from '../style.module.css'
import { cx } from '@emotion/css'
import useIsCellSelected from '../hooks/useIsCellSelected'
import { Editor, Range } from 'slate'

export * from './deserializer'

const TableCell = (props) => {
  const editor = useSlate()
  const path = ReactEditor.findPath(editor as ReactEditor, props.element)
  const [row, col] = path.slice(-2)
  const table = Editor.above(editor, { at: path, match: (node) => node["type"] === 'table' })
  let isSelectionOnlyInTable = false

  const tableRange = Editor.range(editor, table?.[1])

  if (editor.selection && tableRange.anchor && tableRange.focus) {
    isSelectionOnlyInTable =
      Range.includes(tableRange, Range.start(editor.selection)) &&
      Range.includes(tableRange, Range.end(editor.selection))
  }

  const isInRectangle = useIsCellSelected(path, table)
  const defaultSelection = useSelected()
  const selected = isSelectionOnlyInTable ? isInRectangle : defaultSelection
  let { colSpan, rowSpan } = props?.element?.attrs ?? {}

  let spans = {}
  if (colSpan) {
    spans = { ...spans, colSpan }
  }
  if (rowSpan) {
    spans = { ...spans, rowSpan }
  }

  const rowColSpanStyles = (rowspan = 1, colspan = 1) => {
    const styles = {}
    styles['gridRow'] = `${row + 1} / span ${rowspan}`
    styles['gridColumn'] = `${col + 1} / span ${colspan}`
    return styles
  }

  let className = cx(styles['div-table-cell'], { [styles['div-table-cell-focus']]: selected })

  if (editor.isVoid(props.element)) {
    return (
      <div
        {...props.attributes}
        tabIndex={-1}
        contentEditable="false"
        style={{ userSelect: 'none', position: 'absolute' }}>
        {props.children}
      </div>
    )
  }

  return (
    <div
      style={rowColSpanStyles(rowSpan, colSpan)}
      data-type="table-cell"
      data-testid="td"
      className={className}
      {...props.attributes}>
      <div style={{ overflow: 'hidden', flexWrap: 'wrap', width: '100%' }}>{props.children}</div>
    </div>
  )
}


export default TableCell

Registry.register(
  {
    Component: TableCell,
    title: 'Table Cell',
    iconName: 'ri-layout-column-line',
    toolbar: {
      inMainToolbar: false,
      inHoveringToolbar: false,
      inSlashToolbar: false,
    },
    isContentStackElement: true,
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true,
    },
  },
  { id: 'td', registry: 'v2.element' }
)

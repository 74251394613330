import React, { useState } from 'react'
import './Avatar.css'
import '../DropDown/Dropdown'
import Tooltip from '../Tooltip/Tooltip'
import cbModal, { ModalFooter, ModalHeader, ModalBody } from '../Modal/Modal'
import Button from '../Button/Button'
import Search from '../Search/Search'
import Icon from '../Icon2/Icon'

const defaultProps = {
  avatars: [],
  showPlusSignAfter: 5,
  type: 'text',
  showExtraType: 'button',
  showExtraColor: 'light',
  size: 'small',
  showStatus: false,
  staticCustomIcon: false,
  testId: 'cs-avatar'
}

type DefaultProps = Readonly<typeof defaultProps>

export type AvatarProps = {
  avatars?: Array<AvatarData>
  showPlusSignAfter?: number
  type?: 'text' | 'image'
  showExtraType?: string
  showExtraColor?: string
  size?: 'x-small' | 'small' | 'large' | 'x-large'
  showStatus?: boolean
  staticCustomIcon?: boolean
  testId?: string
} & Partial<DefaultProps>

export type AvatarData = {
  id: number
  name: string
  image: string
  email: string
  avatarColor: string
}

function displayAvatarContent(type, avatar) {
  let nameInitials = avatar && avatar.name && avatar.name.split(' ')
  let firstInitial = (nameInitials && nameInitials[0]) || ''
  let lastInitial = (nameInitials && nameInitials[nameInitials?.length - 1]) || ''
  let nameInitialsShort =
    nameInitials && nameInitials.length === 1
      ? nameInitials[0]
      : firstInitial?.substring(0, 1) + lastInitial?.substring(0, 1)
  if (type === 'image') {
    return <img src={avatar.image} alt={avatar.name} className="Avatar__image" />
  }
  return nameInitialsShort
}

const searchUser = (users, searchText = '') => {
  if (searchText) {
    return users.filter((user) => {
      return user.name.toLowerCase().includes(searchText.toLowerCase())
    })
  }
  return users
}
const PopUpComponent = (props) => {
  const { avatars, closeModal } = props
  const [searchText, setSearchText] = useState('')
  const updateSearchText = (value) => {
    setSearchText(value)
  }
  const filterUsers = searchUser(avatars, searchText)

  return (
    <>
      <ModalHeader
        title={
          <div className="flex">
            <div className="popUp-body__header">Members</div>
            <div className="popUp-body__header-count flex-v-center flex-h-center">{avatars.length}</div>
          </div>
        }
        closeModal={closeModal}
      />
      <ModalBody className="popUp-body__container">
        <div>
          <div className="popUp-body__search">
            <Search
              debounceSearch={true}
              value={searchText}
              placeholder={'Search members'}
              onChange={updateSearchText}
              onClear={true}
            />
          </div>
          <ul>
            {filterUsers.length ? (
              filterUsers.map((user, i) => {
                return <li key={i}>{user.name}</li>
              })
            ) : (
              <li>No matching members found</li>
            )}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button buttonType="light" onClick={closeModal}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  )
}

const popUpMembersList = (event: any, avatars: any) => {
  event.stopPropagation()
  event.preventDefault()
  cbModal({
    component: (props) => <PopUpComponent {...props} avatars={avatars} />,
    modalProps: {
      size: 'tiny',
      style: {
        overlay: {
          zIndex: '1000'
        },
        content: {}
      }
    }
  })
}

function renderExtraAvatarsButton(
  showExtraAvatars,
  showExtraType,
  extraCount,
  showExtraColor,
  avatars,
  size,
  staticCustomIcon
) {
  if (showExtraAvatars) {
    if (showExtraType === 'button') {
      return (
        <button
          className={`AvatarList__expand-button${
            extraCount > 9 ? ' AvatarList__expand-button--tiny-text' : ''
          } AvatarList__expand-button-${size}`}
          onClick={(e) => {
            popUpMembersList(e, avatars)
          }}>
          +{extraCount}
        </button>
      )
    } else if (showExtraType === 'link') {
      return (
        <a
          role="link"
          className={`AvatarList__expand-link${showExtraColor === 'dark' ? ' AvatarList__expand-link--dark' : ''}`}
          onClick={(e) => {
            popUpMembersList(e, avatars)
          }}>
          +{extraCount} others
        </a>
      )
    }
  }
}

const getAvatarBackgroundColor = (showStatus: any, avatar: any) => {
  if (showStatus) {
    if (avatar.status === 'active') {
      return avatar.avatarColor
    }
    return '#EDF1F7'
  }
  return avatar.avatarColor
}

function Avatar({
  avatars,
  showPlusSignAfter,
  type,
  showExtraType,
  showExtraColor,
  staticCustomIcon,
  size,
  showStatus,
  testId
}: AvatarProps) {
  if (staticCustomIcon) {
    return (
      <div className="AvatarList__users-count flex-v-center">
        <Icon icon="Avatar" version="v2" />
        <div
          className="ml-5"
          onClick={
            avatars.length === 1
              ? null
              : (e) => {
                  popUpMembersList(e, avatars)
                }
          }>
          {avatars.length === 1 ? '1 User' : `${avatars.length} Users`}
        </div>
      </div>
    )
  }
  if (avatars.length === 1) {
    let avatar = avatars[0]
    return (
      <Tooltip content={avatar.name || avatar.email} position="bottom" showArrow={false}>
        <div
          style={{ backgroundColor: getAvatarBackgroundColor(showStatus, avatar) }}
          className="Avatar Avatar--single">
          <span className="Avatar__link">{displayAvatarContent(type, avatar)}</span>
          {showStatus && avatar.status === 'active' && <span className="Avatar__active"></span>}
        </div>
      </Tooltip>
    )
  } else {
    let showExtraAvatars = avatars.length > showPlusSignAfter ? true : false
    let visibleAvatars = []
    let hiddenAvatars = []
    if (showExtraAvatars) {
      visibleAvatars = avatars.slice(0, showPlusSignAfter)
      hiddenAvatars = avatars.slice(showPlusSignAfter)
    } else {
      visibleAvatars = avatars
    }
    return (
      <div className="AvatarList" data-test-id={testId}>
        {visibleAvatars.map((avatar) => {
          return (
            <React.Fragment key={avatar.id}>
              <Tooltip content={avatar.name || avatar.email} position="bottom" showArrow={false}>
                <div
                  style={{ backgroundColor: getAvatarBackgroundColor(showStatus, avatar) }}
                  className={`Avatar Avatar--${size}`}>
                  <span className="Avatar__link">{displayAvatarContent(type, avatar)}</span>
                  {showStatus && avatar.status === 'active' && <span className="Avatar__active"></span>}
                </div>
              </Tooltip>
            </React.Fragment>
          )
        })}
        {renderExtraAvatarsButton(
          showExtraAvatars,
          showExtraType,
          hiddenAvatars.length,
          showExtraColor,
          avatars,
          size,
          staticCustomIcon
        )}
      </div>
    )
  }
}

const AvatarInfo = ({ name, email }) => {
  return (
    <div className="Avatar__info">
      <h6 className="Avatar__name">{name}</h6>
      <span className="Avatar__email">{email}</span>
    </div>
  )
}

Avatar.defaultProps = defaultProps

export default Avatar
export { AvatarInfo }

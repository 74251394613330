import React from 'react'
import { Registry } from '@react-registry'
import { cx } from '@emotion/css'
//@ts-ignore
import styles from '../style.module.css'

import RowDNDIcon from './DndRowIcon'
//@ts-ignoreDndstyles
import Dndstyles from './style.module.css'
//@ts-ignoreDndstyles
import PreviewStyleClass from './preview.module.css'
import { ReactEditor, useSlateStatic } from 'slate-react'
import { Editor } from 'slate'
import { isRowCountExceeded } from '../utils'

export * from './deserializer'
let classNames = {
  divTableRow: styles['div-table-row'],
  tableRowExtraCell: styles['table-add-column-extra-cell']
}
const TableRow = (props) => {
  const editor = useSlateStatic() as ReactEditor
  const path = ReactEditor.findPath(editor, props.element)

  if (isParentTrGrp(editor, path)) {
    return (
      <div
        data-testid="tr"
        data-type="table-row"
        style={{ display: 'contents' }}>
        {props.children}
      </div>
    )
  }
  props = { ...props, styles: { ...classNames } }
  return (
    <div {...props.attributes} className={'scrte-table-row'} >
      <div
        data-testid="tr"
        data-type="table-row"
        className={props.styles.divTableRow}>
        {props.children}
      </div>
    </div>
  )
}

const isParentTrGrp = (editor, path) => {
  const [parent] = Editor.parent(editor, path)
  // @ts-ignore
  return parent?.type === 'trgrp'
}

const getDroppableContainer = (element, path) => {
  return path.slice(0, path.length - 1).join('')
}
const beforeElementRender = (props) => {
  let { editor, path } = props
  if (isParentTrGrp(editor, path) || isRowCountExceeded(editor, path)) {
    props.DisableDND = true
  }
}
export default TableRow

Registry.register(
  {
    Component: TableRow,
    title: 'Table Row',
    iconName: 'ri-layout-row-line',
    beforeElementRender,
    toolbar: {
      inMainToolbar: false,
      inHoveringToolbar: false,
      inSlashToolbar: false
    },
    isContentStackElement: true,
    dndOptions: {
      DisableSelectionHalo: true,
      CustomDndIcon: RowDNDIcon,
      ContainerClassName: 'scrte-table-row-dnd-container',
      DisableAdd: true,
      dndStyles: Dndstyles,
      getDroppableContainer: getDroppableContainer,
      PreviewStyleClass: PreviewStyleClass,
      DisableGridDnd: true
    }
  },
  { id: 'tr', registry: 'element' }
)

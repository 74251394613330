import { Transforms, Editor } from 'slate'
import { ElementWithType } from '../../../../../../utils/types'

import { LIST_TYPES } from '../../../elements/Element/list/utils'


export const toggleBlock = (editor, format) => {
  let isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n: any) => {
      return LIST_TYPES.includes(n.type)
    },
    split: true,
    mode: 'lowest',
  })
  if (isActive && isList) {
    isActive = false
  }

  let type: string;
  if (isActive) {
    type = 'p'
  } else if (isList) {
    type = 'li'
  } else {
    type = format
  }
  Transforms.setNodes(editor, {
    type: type,
  } as Partial<ElementWithType>)

  if (!isActive) {
    if (format === 'code') {
      let attrs;
      Transforms.setNodes(editor, {
        type: type,
        attrs: {
          ...attrs,
          language: 'html'
        }
      } as Partial<ElementWithType>)
    }
    else {
      Transforms.setNodes(editor, {
        type: type,
        attrs: {}
      } as Partial<ElementWithType>)
    }

  }
  if (!isActive && format === 'check-list') {
    Transforms.setNodes(editor, { checked: false } as Partial<ElementWithType>)
  }
  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n: ElementWithType) => n.type === format,
  })
  return !!match
}

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)
  isActive ? Editor.removeMark(editor, format) : Editor.addMark(editor, format, true)
}

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

//@ts-nocheck
import React, { useEffect, useRef, useContext } from 'react'
import { useSlate, ReactEditor } from 'slate-react'
import { Editor } from 'slate'
import { Registry } from '@react-registry'


//@ts-ignore
import { EditorProvider } from '../../../../components/ContextProvider/EditorProvider'
import styles from '../style.module.css'
import { cx } from '@emotion/css'

export * from './deserializer'


const TableHead = (props) => {
  const { attributes, children } = props

  const editor = useSlate()
  const elementPath = ReactEditor.findPath(editor, props.element)
  const { editorRef } = useContext(EditorProvider)

  let tablePath;
  const theadRef = useRef(null)


  useEffect(() => {
    tablePath = elementPath.slice(0, elementPath.length - 1)

    const scrollEventListener = () => {
      let tableDom, editorScrollDom
      const theadDom = theadRef.current
      const editorDom = editorRef.current
      if (editorDom) {
        editorScrollDom = editorDom.querySelector(".scrte-editable")
      }
      const [tableElement] = Editor.node(editor, tablePath)
      if (tableElement) {
        tableDom = ReactEditor.toDOMNode(editor, tableElement)
      }
      if (theadDom && tableDom && editorScrollDom) {
        let theadWrapperDom = theadDom.parentElement
        const theadDimension = theadDom.getBoundingClientRect()
        const tableDimension = tableDom.getBoundingClientRect()
        const editorScrollDimension = editorScrollDom.getBoundingClientRect()


        //above table
        if (tableDimension.top > editorScrollDimension.top) {
          tableDom.style.paddingTop = 0;
          theadDom.className = "scrte-table-header-container"
          theadDom.style.width = `auto`;
          theadWrapperDom.style.minHeight = `${theadDimension.height}px`;
          theadDom.style.clipPath = `unset`

        }

        // inside table
        if (tableDimension.top < editorScrollDimension.top && tableDimension.bottom >= editorScrollDimension.top) {
          theadWrapperDom.style.minHeight = `${theadDimension.height - 14}px`;

          theadDom.className = "scrte-table-header-container scrte-table-header-sticky"
          theadDom.style.top = `${editorScrollDimension.top}px`;
          theadDom.style.width = `${tableDimension.width}px`;
          theadDom.scrollLeft = `${tableDom.scrollLeft}`
          if (tableDom.classList.contains('scrte-table-selected')) {
            theadDom.style.left = `${tableDimension.left - 1}px`;

          } else {
            theadDom.style.left = `${tableDimension.left}px`;

          }
          theadDom.style.clipPath = `unset`

          if (editorScrollDimension.top - (tableDimension.bottom - theadDimension.height) >= 0) {
            const top = tableDimension.bottom - theadDimension.height
            const overflowElement = (editorScrollDimension.top - top) * 100 / theadDimension.height
            theadDom.style.top = `${top}px`
            theadDom.style.clipPath = `polygon(0% ${overflowElement}%,100% ${overflowElement}%,100.00% 100.00%,0% 100%)`
          }
        }


        // below table

        if (tableDimension.bottom < editorScrollDimension.top) {
          theadDom.style.top = '-999px'
        }
      }
    }

    window.addEventListener("scroll", scrollEventListener, true)

    return () => {
      window.removeEventListener("scroll", scrollEventListener, true)
    }
  }, [elementPath])
  return (
    <div
      style={{ display: 'table-header-group' }}
      className={cx('scrte-table-header-container', styles['scrte-table-header-container'])}>
      <div>
        <div ref={theadRef}>
          <div data-type="table-head" data-testid="thead" className={styles['div-table-head']} {...attributes}>
            {children}
          </div>
        </div>
      </div>
      <div
        data-drag="no"
        data-testid="row-extra-cell"
        contentEditable={false}
        className={cx(styles['table-add-column-extra-cell'], 'scrte-table-row-extra-cell')}></div>
    </div>
  )
}

export default TableHead

Registry.register(
  {
    Component: TableHead,
    isContentStackElement: true,
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true,
    },
    toolbar: {
      inHoveringToolbar: false
    },
  },
  { id: 'thead', registry: 'v2.element' }
)

//@ts-nocheck

import React, { Fragment } from 'react'

import { ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup, Notification } from '../../../index'
import Dropable from './Upload/Dropable'
import { Upload } from './Upload'
import '../style.css'

function isJson(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const getImageMarkdown = (href, text = 'Enter your image caption here') => {
  text = text === '' ? href : text
  return `![${text}](${href})`
}


export default class extends React.Component<{ closeModal: any, onSubmit?: () => void }, {}> {
  state = {
    folderUid: 'cs_root',
    loading: false,
    selected: false,
    files: []
  }

  setFolderUid = (uid) => {
    this.setState({
      folderUid: uid,
    })
  }

  handleReset = () => {
    this.props.closeModal()
  }

  setLoading = isLoading => this.setState({ loading: isLoading });

  handleError = (errorType) => {
    let message = ''
    if (errorType === 'size') {
      message = `The file size should be within ${this.props?.size?.min || 0}  MB and ${
        this.props?.size?.max || 700
      } MB.`
    } else if (errorType === 'maxSelect') {
      message = `Maximum 10 files can be uploaded.`
    }
    Notification({
      displayContent: {
        text: message
      },
      notifyProps: { hideProgressBar: true, position: 'bottom-center' },
      type: 'error'
    })
    this.setLoading(false)
  }

  handleUpload = (files, rejectedFilesDueToSize: File[] = []) => {
    let showError = {
      maxSelect: false,
      size: false
    }
    let rejectedFilesDueToMaxLimit = []
    if (files.length > this.props?.multiple?.max) {
      showError.maxSelect = true
      rejectedFilesDueToMaxLimit = files.slice(this.props?.multiple?.max)
      files = files.slice(0, this.props?.multiple?.max)
    }
    if (rejectedFilesDueToSize?.length) {
      showError.size = true
    }
    const extension = window.contentstackSDK;
    if (!extension) return;
    const upload = extension.stack.Asset.uploadAsset;
    let folderUid = this.state.folderUid;
    folderUid = folderUid === 'cs_root' ? '' : folderUid;
    this.setState({ loading: true });
    if (!files?.length) {
      this.handleError('size')
      this.props.onSubmit(
        [],
        ...rejectedFilesDueToSize.map((file) => ({
          message: `File size should be within ${this.props?.size?.min || 0} MB and ${
            this.props?.size?.max || 700
          } MB.`,
          code: 'sizeLimit',
          file: file.file
        }))
      )
      this.handleReset()
      return;
    }
    upload(files, { parentFolderUid: folderUid })
      .then(res => {
        if (this.props.onSubmit) {
          if (isJson(res?.data)) {
            const error = JSON.parse(res?.data)?.data
            console.log('Upload error', error)
            this.setState({ loading: false })
            this.handleReset()
            Notification({
              displayContent: { text: error.error_message },
              notifyProps: { hideProgressBar: true, position: 'bottom-center' },
              type: 'error'
            })
            this.props.onSubmit(
              [],
              files.map((file) => ({ file, message: error.error_message }))
            )
            return
          }
          const responseAssets = Array.from(res?.data)?.map((val) => ({
            url: val?.asset?.url,
            fileName: val?.asset?.filename,
            uid: val?.asset?.uid,
            title: val?.asset?.title,
            fileSize: val?.asset?.file_size,
            contentType: val?.asset?.content_type
          }))
          this.props.onSubmit(responseAssets, [
            ...rejectedFilesDueToMaxLimit.map((file) => ({
              message: 'Max upload limit reached',
              code: 'maxSelectionLimit',
              file
            })),
            ...rejectedFilesDueToSize.map((file) => ({
              message: `File size should be within ${this.props?.size?.min || 0} MB and ${
                this.props?.size?.max || 700
              } MB.`,
              code: 'sizeLimit',
              file: file.file
            }))
          ])
        }
        if (showError.maxSelect)
          Notification({
            displayContent: { text: 'Too many files selected. One or more files are ignored while uploading.' },
            notifyProps: { hideProgressBar: true, position: 'bottom-center' },
            type: 'error'
          })
        if (showError.size) {
          Notification({
            displayContent: {
              text: `${
                this.props?.multiple ? 'One or more files are ignored while uploading because t' : 'T'
              }he file size should be within ${this.props?.size?.min || 0}  MB and ${this.props?.size?.max || 700} MB.`
            },
            notifyProps: { hideProgressBar: true, position: 'bottom-center' },
            type: 'error'
          })
        }
        this.setState({ loading: false });
        this.handleReset();
      })
      .catch(err => {
        this.setLoading(false)
        this.handleReset()
      })
  };

  render() {
    const fileAccept = this.props?.fileTypes?.split(',')?.map(ele => `.${ele}`) || []
    if (this.props?.only) {
      fileAccept.push(this.props?.only + '/*');
    }
    return (
      <div className="asset-picker">
        <div id="scrte-image-modal">
          <ModalHeader title="Select a folder to upload asset(s)" closeModal={this.handleReset} />

          <ModalBody className="modalBodyCustomClass">
            <Fragment>
              <Upload loading={this.state.loading} multiple={this.props.multiple} size={this.props.size} handleUpload={this.handleUpload} setFolderUid={this.setFolderUid} />
            </Fragment>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button onClick={this.handleReset} buttonType="light">
                Cancel
              </Button>
              <Dropable
                config={{
                  noDrag: true,
                  multiple: this.props?.multiple,
                  onDrop: this.handleUpload,
                  ...(this.props?.size?.max && { maxSize: this.props?.size?.max * (1024 * 1024) }),
                  ...(this.props?.size?.min && { minSize: this.props?.size?.min * (1024 * 1024) })
                }}
                nativeConfig={{ accept: fileAccept.join() }}>
                <Button icon='Upload' loading={this.state.loading}>Upload Here</Button>
              </Dropable>

            </ButtonGroup>
          </ModalFooter>
        </div>
      </div>
    )
  }
}
import React, { Component } from 'react'
import cn from 'classnames'

import './FieldLabel.css'

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps = {
  requiredText: '*',
  required: false,
  testId: 'cs-field-label'
}

export type FieldLabelProps = {
  /**
   * Sets or returns the value of the for attribute of a label. It specifies to which form element the label is bound to
   */
  htmlFor: string
  /**
   * Pass the class names to be appended to this prop
   */
  className?: string
  /**
   * Pass an ID that you can use for testing purposes. It is applied as a data attribute (data-test-id)
   */
  testId?: string
  /**
   * Sets whether it is mandatory to input FieldLabel
   */
  required?: boolean
  /**
   * Pass a custom message that can be added instead of the default text
   */
  requiredText?: string
  /**
   * Indicates an error
   */
  error?: boolean
  /**
   * Sets it in the disabled state
   */
  disabled?: boolean
  /**
   * Add child elements defined within a component
   */
  children?: React.ReactNode
  /**
   * Pass a help text that can be displayed on hover of icon
   */
  helpText?: React.ReactNode
  version?: 'v2'
  labelTooltip?: string
} & Partial<DefaultProps>

export class FieldLabel extends Component<FieldLabelProps> {
  static defaultProps = defaultProps

  renderHelpText = () => {
    if (this.props.helpText) {
      return <span className="helpText__wrapper">{this.props.helpText}</span>
    }
    return null
  }
  render() {
    const {
      className,
      children,
      htmlFor,
      required,
      requiredText,
      error,
      disabled,
      testId,
      helpText,
      version,
      labelTooltip,
      ...otherProps
    } = this.props

    const classNames = cn('FieldLabel', className, { 'FieldLabel--disabled': disabled })

    const helpTextNode = this.renderHelpText()

    return (
      <label className={`${classNames}`} htmlFor={htmlFor} {...otherProps} data-test-id={testId}>
        <span title={labelTooltip}>{children} </span>
        {helpTextNode}
        {required && requiredText?.length? (
          <span
            className={` ${
              error
                ? requiredText !== '*'
                  ? 'ml-8 FieldLabel__required-text--error'
                  : 'FieldLabel__required-text'
                : 'FieldLabel__required-text'
            } ${version === 'v2' && 'ml-8'}`}>
            {version === 'v2' ? '(required)' : requiredText}
          </span>
        ) : (
            requiredText?.length && version === 'v2'&& requiredText !==  "(required)" && requiredText !==  "*" ? (
              <span className="FieldLabel__required-text ml-8">{requiredText}</span>
            ): (null)
        ) }
      </label>
    )
  }
}

export default FieldLabel

import { Transforms, Editor, Range, NodeEntry, Node, BaseEditor, Path, Element } from 'slate'
import React from 'react'
import { jsx } from 'slate-hyperscript'
import { ElementWithType } from '../../../../../../../utils/types'
import { range } from 'lodash'
import { getSelectedCells } from './getSelectedCells'


export const isPathInRectangle = (editor: BaseEditor, path: Path, tablePath: Path) => {
  const edgePaths = Range.edges(editor.selection as Range).map((point) => point.path)

  const [startCellPath, endCellPath] = edgePaths.map(
    // @ts-ignore
    (path) => Editor.above(editor, { at: path, match: (node) => node.type === 'td' })?.[1]
  )

  if (!(startCellPath && endCellPath && path)) return false

  const tablePathLength = tablePath.length

  let [startRowGroupIndex, startRowIndex, startColIndex] = startCellPath.slice(-3)
  let [endRowGroupIndex, endRowIndex, endColIndex] = endCellPath.slice(-3)
  let [currRowGroupIndex, currRowIndex, currColIndex] = path.slice(-3)

  if (startCellPath.length - tablePathLength > 3) {
    startRowIndex = parseFloat(`${startRowGroupIndex}.${startRowIndex}`)
  }
  if (endCellPath.length - tablePathLength > 3) {
    endRowIndex = parseFloat(`${endRowGroupIndex}.${endRowIndex}`)
  }
  if (path.length - tablePathLength > 3) {
    currRowIndex = parseFloat(`${currRowGroupIndex}.${currRowIndex}`)
  }

  [startColIndex, endColIndex] = [Math.min(startColIndex, endColIndex), Math.max(startColIndex, endColIndex)]

  const isBetweenCols = currColIndex >= startColIndex && currColIndex <= endColIndex
  const isBetweenRows = currRowIndex >= startRowIndex && currRowIndex <= endRowIndex
  // rows inside of trgrp will have decimal numbers
  // TODO: combine logic
  return isBetweenCols && isBetweenRows
}

export const cellType = ['td', 'th']

export const mergeCells = (event, { editor }: { editor: Editor }) => {
  const { selection } = editor as BaseEditor
  const trgrp = jsx('element', { type: 'trgrp' })


  if (!selection || Range.isCollapsed(selection)) return

  // @ts-ignore
  const [table] = Editor.nodes(editor, { match: (node) => node.type === 'table' })

  const edgePaths = Range.edges(editor.selection as Range).map((point) => point.path)

  const [startCellPath, endCellPath] = edgePaths.map(
    // @ts-ignore
    (path) => Editor.above(editor, { at: path, match: (node) => cellType.includes(node.type) })?.[1]
  )

  const startColIndex = startCellPath[startCellPath.length - 1]
  const endColIndex = endCellPath[endCellPath.length - 1]


  const setCellVoid = ([cell, path]: NodeEntry) => {
    // @ts-ignore
    const emptyCell = jsx('element', { type: cell?.type ?? 'td', attrs: { void: true } }, [{ text: '' }])
    Transforms.removeNodes(editor, { at: path })
    Transforms.insertNodes(editor, emptyCell, { at: path })
  }

  const setSpans = () => {

    const colSpan = 1 + Math.abs(startColIndex - endColIndex)
    const rowSpan = selectedCells.length / colSpan

    const spans = { colSpan, rowSpan }

    if (rowSpan < 2) delete spans.rowSpan
    if (colSpan < 2) delete spans.colSpan

    const [cellToset, cellTosetPath] = selectedCells[0]

    const newRedactorAttributes = {
      ...cellToset['attrs']?.['redactor-attributes'],
    }
    if (spans['rowSpan'])
      newRedactorAttributes['rowspan'] = rowSpan
    if (spans['colSpan'])
      newRedactorAttributes['colspan'] = colSpan

    const props = {
      attrs: {
        ...cellToset['attrs'] ?? {}, ...spans,
        'redactor-attributes': newRedactorAttributes
      }
    } as Partial<Element>

    Transforms.setNodes(editor, props, { at: cellTosetPath })
  }

  const setDisabledCols = () => {

    // @ts-ignore
    const { disabledCols = [] } = table[0]?.attrs

    Transforms.setNodes(editor, {
      attrs: {
        // @ts-ignore
        ...table[0].attrs,
        disabledCols: Array.from(new Set(disabledCols.concat(...range(startColIndex, endColIndex + 1))))
      }
    } as ElementWithType, { at: table[1] })
  }

  const transformNodes = () => {
    // Get TBODY children in selection
    const tBodyChildren = Array.from(Editor.nodes(editor, {
      match: (node, path) => {

        // @ts-ignore
        if (node.type === 'trgrp') return true

        // @ts-ignore
        if (node.type !== 'tr') return false

        const rowParent = Editor.parent(editor, path)?.[0]
        // @ts-ignore
        return rowParent.type === 'tbody'

      }
    }))

    if (tBodyChildren.length < 2) return

    // Get Pathref 
    const tBodyChildrenRefs = tBodyChildren.map(([node, path]) => Editor.pathRef(editor, path))

    const startPath = tBodyChildrenRefs[0].current
    const endPath = tBodyChildrenRefs[tBodyChildren.length - 1].current
    // @ts-ignore
    Transforms.wrapNodes(editor, trgrp, { at: [startPath, endPath] })

    // 
    tBodyChildrenRefs.forEach(ref => {
      const path = ref.current
      const [node] = Editor.node(editor, path) as NodeEntry<ElementWithType>

      if (node.type === 'trgrp')
        Transforms.unwrapNodes(editor, { at: path })
    })

  }

  const selectedCells = Array.from(
   getSelectedCells(editor, table[1])
  )
  if (!isValidForMerging(editor, selectedCells)) return

  setSpans()

  const cellToPreserve = selectedCells.shift()
  selectedCells.forEach(setCellVoid)

  setDisabledCols()

  transformNodes()

  Transforms.collapse(editor, { edge: 'start' })

}

export function isValidForMerging(editor, cells: NodeEntry[]) {
  if (cells.length < 2) return false

  const hasSpanningCells = cells.some(([cell]) => {
    const { hasColSpan, hasRowSpan } = isCellSpanning(cell)
    return hasRowSpan || hasColSpan
  })

  if (hasSpanningCells) return false

  const hasVoidCells = cells.some(([cell]) => editor.isVoid(cell))
  if (hasVoidCells) return false

  return true
}

export function isCellSpanning(cell) {
  const { rowSpan = 1, colSpan = 1 } = cell?.attrs ?? {}
  return { hasRowSpan: rowSpan > 1, hasColSpan: colSpan > 1 }
}

import isUrl from 'is-url'
import { Registry } from '@react-registry'

import { insertImage, isImageUrl } from '../image'
import { Element, Transforms } from 'slate'
import { stringToStyleObject } from '../../utils/attributesProvider'
import { cloneDeep } from 'lodash'

export const withEmbeds = editor => {
    const { isVoid, normalizeNode } = editor

    editor.isVoid = element => {
        return (element.type === 'embed' ? true : isVoid(element))
    }

    editor.insertEmbed = src => {
        if (!src || !isUrl(src)) return false;
        if (isImageUrl(src)) {
            insertImage(editor, src)
            return
        }
        let embed = {
            type: 'embed',
            attrs: {
                src
            },
            children: [{ text: '' }]
        }
        editor.insertNode(embed);
    }

    editor.normalizeNode = (nodeEntry) => {
        const [node, path] = nodeEntry
        if (Element.isElement(node) && node['type'] === 'embed') {
            let style = node?.['attrs']?.['style'] || {}
            const styleString = Object.entries(style).filter(([key, value]) => {
                //@ts-ignore
                return !isNaN(key)
            }).map(([key, value]) => value).join("")

            if (styleString) {
                let newAttributes = cloneDeep(node['attrs'])
                newAttributes.style = { ...stringToStyleObject(styleString) }
                Transforms.setNodes(
                    editor,
                    //@ts-ignore
                    { attrs: newAttributes },
                    {
                        at: path
                    }
                )
            }
        }
        normalizeNode(nodeEntry)
    }

    return editor
}

Registry.register(withEmbeds, { id: 'withEmbed', registry: 'v2.plugin' })
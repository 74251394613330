//@ts-nocheck
import { Transforms, Editor, Node, Path } from 'slate'
import { Registry } from '@react-registry'

export const withPara = (editor) => {
  const { insertBreak } = editor

  editor.insertBreak = () => {
    const { selection } = editor
    if (selection) {

      const [isParaOrFrag] = Editor.nodes(editor, {
        match: (n) => {
          return n.type === 'p' || n.type === 'fragment'
        }
      })

      if (isParaOrFrag && editor.csOnlyBreakline) {
        const [currNode] = Node.last(editor, selection.anchor.path)
        const nextNode = Editor.next(editor, { at: selection.anchor.path })
        const textLength = currNode.text.length
        if (currNode.text[textLength - 1] === '\n' && !currNode.hasOwnProperty('break') && !nextNode) {
          Editor.deleteBackward(editor)
          Transforms.insertNodes(editor, { type: 'p', children: [{ text: '' }] }, { at: editor.selection, select: true })
        }
        else {
          let nextPath = Editor.next(editor, { at: editor.selection })?.[1]
          if (nextPath && nextPath[nextPath.length - 2] === selection.anchor.path[selection.anchor.path.length - 2]) {
            if (currNode.text === "\n") {
              nextPath[nextPath.length - 1] = nextPath[nextPath.length - 1] + 1
            }
            editor.insertText('\n')
            if (currNode.text[textLength - 1] !== '\n' && currNode.text.indexOf("\n") > -1) {
              return
            }
            if (currNode.text[textLength - 1] === '\n') {
              Transforms.select(editor, {
                "anchor": {
                  "path": nextPath,
                  "offset": currNode.text.lastIndexOf("\n") + 1
                },
                "focus": {
                  "path": nextPath,
                  "offset": currNode.text.lastIndexOf("\n") + 1
                }
              })
            }

            else {
              let newSelection = {
                "anchor": {
                  "path": nextPath,
                  "offset": 0
                },
                "focus": {
                  "path": nextPath,
                  "offset": 0
                }
              }
              Transforms.select(editor, newSelection)
            }
          }
          else {
            editor.insertText('\n')
          }
        }
      }
      else {
        insertBreak()
      }
    }
  }
  return editor
}
Registry.register(withPara, { id: 'withPara', registry: 'plugin' })
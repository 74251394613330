import React, { useEffect, useRef } from 'react'
import Tippy from '@tippyjs/react'
import { ReactEditor, useSlateStatic } from 'slate-react'
import { Editor, Transforms } from 'slate'

import { formatShortcut } from '../../../../utils/shortcuts/formatShortcut'

import { TableDeleteRowIcon, TableAddRowBeforeIcon, TableAddRowAfterIcon } from '../../../utils/Icon'
import { deleteRow, insertRowBefore, insertRowAfter, ROW_EXCEED_COUNT } from '../utils/index'
import ToolTip from '../../../../../../Tooltip/Tooltip'

import styles from '../style.module.css'

function SvgComponent(props) {
    const { path } = props
    const editor = useSlateStatic()
    const [iconOfNode] = Editor.node(editor, path) as any
    const rows = iconOfNode.type === 'trgrp' ? iconOfNode.children : [iconOfNode]
    const ref = useRef<HTMLDivElement>()
    useEffect(() => {
        if (rows.length > 1) {
            const iconAttachedTo = ReactEditor.toDOMNode(editor as ReactEditor, iconOfNode).firstElementChild

            if (!iconAttachedTo) return

            const templateRows = window.getComputedStyle(iconAttachedTo).getPropertyValue('grid-template-rows')
            if (ref.current)
                ref.current.style.gridTemplateRows = templateRows
        }
    })
    const tooltipRef = useRef(null)
    const closeTooltip = (dom) => {
        if (dom) {
            if (dom.getAttribute('elementtype') === 'tr') {
                return
            }
            if (dom?._tippy) {
                dom._tippy.hide()
                return
            }
            closeTooltip(dom.parentElement)
        } else {
            return
        }
    }
    return (
        <div ref={ref} style={{ height: '100%', display: 'grid' }}>
            {rows.map((row) => (
                <Tippy
                    placement="right"
                    content={
                        <div
                            data-testid="dnd-row-icon-toolbar"
                            style={{ display: 'flex', alignItems: 'center', padding: '2px 8px' }}
                            ref={tooltipRef}>
                            <span
                                data-testid="delete-row-icon"
                                className={styles['table-column-toolbarIcon']}
                                onMouseDown={(e) => {
                                    e.preventDefault()
                                    const firstCell = [...path, 0]
                                    Transforms.select(editor, firstCell)
                                    deleteRow(e, { editor })
                                    closeTooltip(tooltipRef.current)

                                }}><Tippy placement="top" className="tippy-box-dark" offset={() => [0, 20]} content={<span data-testid="delete-row-text" className={styles['scrte-table-tooltip-text']} >Delete Row</span>} ><TableDeleteRowIcon /></Tippy></span>
                            <span data-testid="insert-row-before-icon" className={styles['table-column-toolbarIcon']} style={{ margin: '0px 10px' }} onMouseDown={(e) => {
                                e.preventDefault()
                                const firstCell = [...path, 0]
                                closeTooltip(tooltipRef.current)
                                Transforms.select(editor, firstCell)
                                insertRowBefore(e, { editor })
                                Transforms.select(editor, firstCell)

                            }}><Tippy placement="top" className="tippy-box-dark" offset={() => [0, 20]} content={<span data-testid="add-row-up-text" className={styles['scrte-table-tooltip-text']} >Add Row to the top  <span className={styles['scrte-table-tooltip-shortcut']}>{formatShortcut('ctrl+shift+up')}</span></span>} ><TableAddRowBeforeIcon /></Tippy></span>
                            <span data-testid="insert-row-after-icon" className={styles['table-column-toolbarIcon']} onMouseDown={(e) => {
                                e.preventDefault()
                                const firstCell = [...path, 0]

                                Transforms.select(editor, firstCell)
                                insertRowAfter(e, { editor })
                                closeTooltip(tooltipRef.current)
                                const nextCell = [...path.slice(0, path.length - 1), path[path.length - 1] + 1, 0]
                                Transforms.select(editor, nextCell)

                            }}><Tippy placement="top" className="tippy-box-dark" offset={() => [0, 20]} content={<span data-testid="add-row-down-text" className={styles['scrte-table-tooltip-text']} >Add Row to the bottom  <span className={styles['scrte-table-tooltip-shortcut']}>{formatShortcut('ctrl+shift+down')}</span></span>} ><TableAddRowAfterIcon /></Tippy></span>

                        </div>
                    }
                    onTrigger={e => {
                        e.setProps({ appendTo: ref.current.parentElement })
                    }}
                    trigger="click"
                    interactive={true}
                    className="tippy-box-light tippy-box-light--primary"
                >
                    <span style={{ height: "100%" }} data-testid="row-dnd-icon">
                        <svg
                            width={12}
                            height="100%"
                            viewBox="0 0 12 12"
                            fill="none"
                            style={{ opacity: 0.5 }}
                            xmlns="http://www.w3.org/2000/svg"
                            {...props}
                        >
                            <rect x={2.052} width={3.002} height={3.002} rx={1.501} fill="#647696" />
                            <rect
                                x={2.052}
                                y={4.499}
                                width={3.002}
                                height={3.002}
                                rx={1.501}
                                fill="#647696"
                            />
                            <rect
                                x={2.052}
                                y={8.998}
                                width={3.002}
                                height={3.002}
                                rx={1.501}
                                fill="#647696"
                            />
                            <rect x={6.946} width={3.002} height={3.002} rx={1.501} fill="#647696" />
                            <rect
                                x={6.946}
                                y={4.499}
                                width={3.002}
                                height={3.002}
                                rx={1.501}
                                fill="#647696"
                            />
                            <rect
                                x={6.946}
                                y={8.998}
                                width={3.002}
                                height={3.002}
                                rx={1.501}
                                fill="#647696"
                            />
                        </svg>
                    </span>
                </Tippy>

            ))}
        </div>
    )
}

export default SvgComponent

import React, { CSSProperties, ReactNode } from 'react'
import './InfoContainer.css';
import cn from 'classnames'

export type InfoRow = {
  title?: string | ReactNode,
  content: string | ReactNode
}

export type InfoContainerProps = {
  rows: InfoRow[],
  className?: string
  contentFontColor?: "base" | "secondary" | "tertiary" | "black"
  contentFontSize?: "tiny" | "small" | "medium" | "large"
  seeMoreCb?: Function
  testId?: string
  seeMoreTestId?: string,
  count?: string | number | React.ReactNode,
}

const InfoContainter = (props: InfoContainerProps) => {

  const InfoRowContentClassNames = cn('InfoRowContent', {
    [`InfoRowContent--tiny`]: props.contentFontSize === 'tiny',
    [`InfoRowContent--small`]: props.contentFontSize === 'small',
    [`InfoRowContent--medium`]: props.contentFontSize === 'medium',
    [`InfoRowContent--secondary`]: props.contentFontColor === 'secondary',
    [`InfoRowContent--tertiary`]: props.contentFontColor === 'tertiary',
    [`InfoRowContent--base`]: props.contentFontColor === 'base',
  })

  const seeMoreClick = () => {
    props.seeMoreCb();
  }

  return (
    <div className={"InfoContainer"}>
      {props.rows?.map((infoRow, index) => (
        <div className={"InfoRow"} key={index}>
          {infoRow.title && <div className="InfoRowTitle">{infoRow.title}</div>}
          <div className={InfoRowContentClassNames}>{infoRow.content ? infoRow.content : '-'}</div>
        </div>
      ))}
      {
        props.seeMoreCb &&
        <div onClick={seeMoreClick} className="InfoSeeMore" data-test-id={props.seeMoreTestId}>{`See All (${props?.count ? props?.count : props.rows?.length})`}</div>
      }
    </div>
  )
}

InfoContainter.defaultProps = {
  contentFontColor: "black",
  contentFontSize: "large",
  testId: "cs-infocontainer",
  seeMoreTestId: "cs-infocontainer-see-more"
} as Partial<InfoContainerProps>;

export default InfoContainter;
import React, { useState, useRef, useEffect } from 'react'

import Field from '../../Field/Field'
import TextInput from '../../TextInput/TextInput'
import Textarea from '../../Textarea/Textarea'
import Tooltip from '../../Tooltip/Tooltip'
import FieldLabel from '../../FieldLabel/FieldLabel'
import Checkbox from '../../Checkbox/Checkbox'
import Help from '../../Help/Help'
import ValidationMessage from '../../ValidationMessage/ValidationMessage'
import TagAsSelect from '../../TagAsSelect/TagAsSelect'
import { fieldUidRegex, getContentTypeFieldDataTestId, CONTENT_TYPE_FIELD_ACTION_IDS } from '../helper'

export const Global = (props: any) => {
  const { onChange, uniqueKey, parentInfo, globalFieldOptions, schema, type, isNonLocalizable, hasNonLocalizablePlan } =
    props
  const { loadMoreOptions, totalCounts, isMulti, value, updateTag, openModalCb, ...restReference } = globalFieldOptions
  let preSelectedVal: any
  if (isMulti) {
    preSelectedVal = value.map((val) => {
      return {
        id: val,
        label: val,
        title: val
      }
    })
  } else {
    preSelectedVal = value
  }
  const [error, setError] = useState(fieldUidRegex(schema.uid, schema))
  const [referenceValue, setReference] = useState(
    schema.reference_to && schema?.reference_val[0] && schema?.reference_val[0].label
      ? [{ label: schema.reference_val[0].label, value: schema.uid }]
      : []
  )

  const displayNameRef: any = useRef(null)

  useEffect(() => {
    displayNameRef.current.focus()
  }, [displayNameRef])

  const [initialSchema, setSchema] = useState(schema)

  const { display_name, uid, field_metadata } = schema
  const onchange = () => {
    onChange && onChange(schema)
    setSchema({ ...schema })
  }

  const handleRefUpdate = (selectedRefs: any) => {
    if (selectedRefs && selectedRefs.contentModal) {
      setReference([
        {
          label: selectedRefs.contentModal.title,
          value: selectedRefs.uid
        }
      ])
    } else {
      setReference([])
    }
    schema.reference_to = selectedRefs.uid
    if (selectedRefs?.uid && selectedRefs?.contentModal?.title) {
      schema.reference_val = [
        {
          label: selectedRefs.contentModal.title,
          value: selectedRefs.uid
        }
      ]
    }
    if (!schema.reference_to) {
      schema.error_details = {
        ...schema.error_details,
        reference_to: 'Please select a Global Field'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        reference_to: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onDisplayNameChange = (event: any) => {
    schema.display_name = event.target.value

    if (schema.hasOwnProperty('display_name') && schema.newfield) {
      schema.uid = schema.display_name
        .trim()
        .replace(/[^A-Z0-9]+/gi, '_')
        .toLowerCase()
      if (schema.display_name) {
        schema.error_details = {
          ...schema.error_details,
          uid: ''
        }
      }
    }

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.display_name.length) {
      schema.error_details = {
        ...schema.error_details,
        display_name: 'Display Name cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        display_name: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onUidChange = (event: any) => {
    setError(fieldUidRegex(event.target?.value?.trim(), schema))

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.uid.length) {
      schema.error_details = {
        ...schema.error_details,
        uid: 'uid cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        uid: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onInstructionChange = (event: any) => {
    field_metadata.instruction = event.target.value
    onchange()
  }
  const onHelpTextChange = (event: any) => {
    field_metadata.description = event.target.value
    onchange()
  }
  const onOptionsChange = (event: any) => {
    if (event.target.name === `${uniqueKey}.multiple`) {
      schema.multiple = event.target.checked
      schema.error_details = {
        ...schema.error_details,
        max_instance: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
      if (!schema.multiple) {
        delete schema.max_instance
      }
    }
    if (event.target.name === `${uniqueKey}.nonLocalization`) {
      schema.non_localizable = event.target.checked
    }
    onchange()
  }

  const onMaxInstance = (event: any) => {
    schema.max_instance = event.target.value ? parseInt(event.target.value) : null

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (event.target.value.length && schema.max_instance < 0) {
      schema.error_details = {
        ...schema.error_details,
        max_instance: 'max instance cannot be less then zero'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        max_instance: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }

    onchange()
  }

  if (type === 'basic') {
    return (
      <div className="FormFields">
        <div className="FormFields__column">
          <Field>
            <FieldLabel required htmlFor="displayName">
              Display Name
            </FieldLabel>
            <TextInput
              required
              name={`${uniqueKey}.displayName`}
              value={display_name}
              onChange={onDisplayNameChange}
              inputRef={displayNameRef}
              error={schema.error && schema.error_details.display_name ? true : false}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.DISPLAY_NAME_INPUT)}
            />
          </Field>
          <Field>
            <FieldLabel htmlFor="helpText">Help Text</FieldLabel>
            <Help text="Provide additional info about the field. The help text will appear when the user hovers on the ‘?’ sign beside the field name." />
            <Textarea
              name={`${uniqueKey}.helpText`}
              value={field_metadata.description || ''}
              onChange={onHelpTextChange}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              // multiline={true}
              testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.HELP_TEXT_INPUT)}
            />
          </Field>
        </div>
        <div className="FormFields__column">
          <Field>
            <FieldLabel required htmlFor="uid">
              Unique ID
            </FieldLabel>
            <Help text="A unique ID for this field. This should not be same as the ID of any other field in this content type." />
            <TextInput
              required
              name={`${uniqueKey}.uid`}
              value={uid}
              onChange={onUidChange}
              error={(schema.error && schema.error_details.uid) || error ? true : false}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.UID_INPUT)}
            />
          </Field>
          <Field>
            <FieldLabel htmlFor="instruction">Instruction Value</FieldLabel>
            <Help text="Helps you add instructions for this field. The instructional text will appear below the field." />
            <Textarea
              name={`${uniqueKey}.instruction`}
              value={field_metadata.instruction || ''}
              onChange={onInstructionChange}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.INSTRUCTION_INPUT)}
            />
          </Field>
          <Field>
            <FieldLabel htmlFor="globalField">Select Global Field</FieldLabel>

            <TagAsSelect
              placeholder={'Select Global field'}
              showEmptyState={true}
              isMulti={isMulti}
              showCount={true}
              showSingleItem={true}
              tags={referenceValue ? referenceValue : []}
              updateTag={handleRefUpdate}
              openModalCb={(callback) => openModalCb(callback)}
              {...restReference}
              testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.SELECT_GLOBAL_FIELD_TAG_AS_SELECT)}
            />
            {schema.error && schema.error_details.reference_to ? (
              <ValidationMessage className={'error'}>{schema.error_details.reference_to}</ValidationMessage>
            ) : null}
          </Field>
        </div>
      </div>
    )
  } else if (type === 'advanced') {
    return (
      <div className="FormFields">
        <Field>
          <FieldLabel htmlFor="options">Options</FieldLabel>
          <div className="FormFields__selection-wrapper">
            <Checkbox
              testId="cs-ct-field-global-multiple"
              name={`${uniqueKey}.multiple`}
              text="Multiple"
              checked={schema.multiple}
              onChange={onOptionsChange}
            />
            {schema.multiple ? (
              <div>
                <Field>
                  <FieldLabel className="ml-0" htmlFor="validation">
                    Set Maximum Limit
                  </FieldLabel>
                  <TextInput
                    type="number"
                    name={`${uniqueKey}.maxinstance`}
                    value={schema.max_instance || null}
                    onChange={onMaxInstance}
                    // placeholder="Maximum"
                    placeholder={props.t(
                      'content_type.save_content_type.body.fields.common_properties.placeholders.maximum'
                    )}
                    error={schema.error && schema.max_instance ? true : false}
                    testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.SET_MAX_LIMIT_INPUT)}
                  />
                </Field>
              </div>
            ) : null}
            {(parentInfo.hasParent ? !parentInfo.isMulti : true) ? (
              hasNonLocalizablePlan ? (
                <>
                  {isNonLocalizable ? (
                    <Tooltip position="top" content="Available only if there are multiple languages in your stack.">
                      <Checkbox
                        testId="cs-ct-field-global-non-localizable"
                        name={`${uniqueKey}.nonLocalization`}
                        text="Non-localizable"
                        checked={schema.non_localizable}
                        disabled={isNonLocalizable}
                        onChange={onOptionsChange}
                      />
                    </Tooltip>
                  ) : (
                      <Checkbox
                        testId="cs-ct-field-global-non-localizable"
                        name={`${uniqueKey}.nonLocalization`}
                        text="Non-localizable"
                        checked={schema.non_localizable}
                        disabled={isNonLocalizable}
                        onChange={onOptionsChange}
                      />
                    )}
                  <div className="nl-note">
                    If enabled, editing this field is restricted in localized entries. The field will use the value of
                    the master-language entry in all localized entries.
                  </div>
                </>
              ) : null
            ) : null}
          </div>
        </Field>
      </div>
    )
  }
}

export default React.memo(Global)

//@ts-nocheck

import React, { useState } from 'react'
import { Range, Transforms, Editor } from 'slate'
import { useSlateStatic } from 'slate-react'
import { cbModal, ModalBody, ModalHeader, Button, TextInput, Icon, Field } from '../../../../../../index'
import { Registry } from '@react-registry'

import { setClass, setId, getClass, getId } from './utils'
import { setOldSelection } from '../../../utils/setOldSelection'
import { elementCategories } from '../elementCategories'
function openPropertyModal(event, editor) {
  event?.preventDefault()
  if (editor.selection) {
    const initialClassName = getClass(editor, editor.selection)
    const initialId = getId(editor, editor.selection)

    cbModal({
      modalProps: {
        shouldReturnFocusAfterClose: false,
        onClose: () => setOldSelection(editor)
      },
      component: (props) => (
        <PropertyOverlayModal
          {...props}
          editor={editor}
          selection={editor.selection}
          initialClassName={initialClassName}
          initialId={initialId}
        />
      )
    })
  }
}

export function PropertyOverlayWrapper(props) {
  const editor = useSlateStatic()
  const handleMouseDown = (event) => {
    openPropertyModal(event, editor)
  }

  return <React.Fragment>{props.children({ handleMouseDown })}</React.Fragment>
}

export function PropertyOverlayModal(props) {
  const { selection, initialClassName, initialId, editor } = props

  const [classVal, setClassVal] = useState(initialClassName)
  const [idVal, setIdVal] = useState(initialId)

  const [disableClassButton, setDisableClassButton] = useState(false)
  const [disableIdButton, setDisableIdButton] = useState(false)


  function isIdPropertyDisabled() {
    if (Range.isExpanded(editor.selection)) {
      const [table] = Editor.nodes<JsonNode>(editor, { at: selection, match: (n: JsonNode) => n.type === 'table' })

      if (table) {
        const [, tablePath] = table
        const tableRange = Editor.range(editor, tablePath)

        const isSelectionOnlyInTable =
          Range.includes(tableRange, Range.start(editor.selection)) &&
          Range.includes(tableRange, Range.end(editor.selection))

        if (isSelectionOnlyInTable) {
          return true
        }
      }
    }

    return false
  }


  const handleClassOnChange = (e) => {
    setClassVal(e.target.value)
    setDisableClassButton(false)
  }
  const handleClassPlace = () => {
    Transforms.select(editor, selection)
    setClass(editor, classVal, selection)
    setDisableClassButton(true)
  }
  const handleClassClear = () => {
    Transforms.select(editor, selection)
    setClassVal('')
    setClass(editor, '', selection)
    setDisableClassButton(false)
  }

  // Id functions
  const handleIdOnChange = (e) => {
    setIdVal(e.target.value)
    setDisableIdButton(false)
  }
  const handleIdPlace = () => {
    Transforms.select(editor, selection)
    setId(editor, idVal, selection)
    setDisableIdButton(true)
  }
  const handleIdClear = () => {
    Transforms.select(editor, selection)
    setIdVal('')
    setId(editor, '', selection)
    setDisableIdButton(false)
  }

  return (
    <div>
      <ModalHeader title="Place Class / Id" closeModal={props.closeModal} />

      <ModalBody className="modalBodyCustomClass">
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Field labelText="Class" style={{ width: '65%' }}>
            <TextInput
              autoFocus
              type="text"
              name="classProperty"
              value={classVal}
              onChange={handleClassOnChange}
              required
              placeholder="Enter Class property"
              data-testid="property_class_input"
            />
          </Field>
          <div>
            <Button buttonType="light" disabled={!classVal} onClick={handleClassClear}>
              Clear
            </Button>
          </div>
          <div>
            <Button
              id="applyClassBtn"
              disabled={disableClassButton}
              icon={disableClassButton ? 'CheckedWhite' : ''}
              onClick={handleClassPlace}>
              {disableClassButton ? 'Applied' : 'Apply'}
            </Button>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Field labelText="ID" style={{ width: '65%' }}>
            <TextInput
              type="text"
              name="IdProperty"
              value={idVal}
              onChange={handleIdOnChange}
              required
              placeholder="Enter Id property"
              data-testid="property_id_input"
              disabled={isIdPropertyDisabled()}
            />
          </Field>
          <div>
            <Button buttonType="light" disabled={!idVal} onClick={handleIdClear}>
              Clear
            </Button>
          </div>
          <div>
            <Button
              id="applyIdBtn"
              disabled={isIdPropertyDisabled() || disableIdButton}
              icon={disableIdButton ? 'CheckedWhite' : ''}
              onClick={handleIdPlace}>
              {disableIdButton ? 'Applied' : 'Apply'}
            </Button>
          </div>
        </div>
      </ModalBody>
    </div>
  )
}

export function shortcutOpenPropertyModal(event, editor, format, registryObj) {
  openPropertyModal(event, editor)
}

Registry.register(
  {
    iconName: <Icon icon="Properties" />,
    title: 'Properties',
    shortcut: { key: 'mod+shift+p', callback: shortcutOpenPropertyModal },
    IngressComponent: PropertyOverlayWrapper,
    category: elementCategories.MULTIMEDIA_SEE_MORE,
    isContentStackElement: true,
    inBasicToolbar: false,
    canCustomize: false,
    category: elementCategories.MULTIMEDIA_SEE_MORE
  },
  { id: 'property', registry: 'element' }
)

import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';

const components = {
  DropdownIndicator: null,
};

const createOption = (label: string) => ({
  label,
  value: label,
});

export default class CreatableInputOnly extends Component<any, any> {
  state = {
    inputValue: '',
    value: this.props.value && this.props.value.length ? this.props.value : [],
  };

  handleChange = (value: any, actionMeta: any) => {
    // console.group('Value Changed', this.props);
    // console.log(value);
    // console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();
    this.setState({
      value: value ? value : []
    }, () => {
      this.props.onChange(this.state.value);
    });
  };

  handleInputChange = (inputValue: string) => {
    this.setState({ inputValue });
  };

  handleBlur = (evt: any) => {
    const { value } = this.state;
    if (evt.target.value) {
      this.setState({
        value: [...value, createOption(evt.target.value)],
      })
    }
    this.props.onChange([...value, createOption(evt.target.value)])
  };

  handleKeyDown = (event: any) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (this.state.value.filter(fi => fi.value === inputValue).length === 0) {
          this.setState({
            inputValue: '',
            value: [...value, createOption(inputValue)],
          }, () => {
            this.props.onChange(this.state.value);
          });
        }
        event.preventDefault();
    }
  };
  render() {
    const { inputValue, value } = this.state;
    return (
      <div
        data-test-id={this.props.testID || "creatable-select-root"}
        className={`Select ${this.props.version === "v2" ? "Select__v2" : ""}`} style={{
          width: this.props.width || '200px',
          maxWidth: this.props.maxWidth,
          // minWidth: '100px',
        }}>
        <CreatableSelect
          name={this.props.name}
          components={components}
          inputValue={inputValue}
          isClearable
          isMulti
          menuIsOpen={false}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          placeholder={this.props.placeholder}
          value={value}
          isDisabled={this.props.disabled}
          classNamePrefix="Select"
          className={this.props.className}
          aria-label={'cs-creatable-aria'}
        />
      </div>
    );
  }
}
import React from 'react'
import { Registry } from '@react-registry'
import { Element, Editor, Range } from 'slate'
import { useSlate, useSlateStatic, ReactEditor, useSelected } from 'slate-react'
import Paragraph from './paragraph'
import { DndBlock, DndElement } from '../../components/Dnd'
import { attrsProvider, lineHeightProvider } from '../utils/attributesProvider'
//@ts-ignore
import styles from './style.module.css'
export default (props) => {
  const { element } = props
  let extraAttrs = {}
  const registryComponent = Registry.get({
    id: element.type,
    registry: 'element',
    conditions: props.templateRegistryCondition
  });
  const editor = useSlateStatic();
  const { selection } = editor;
  const path = ReactEditor.findPath(editor as ReactEditor, element);

  if (!registryComponent) {
    return (
      <DndBlock singleLineRef={props.singleLineRef} path={path} editor={editor} disablegriddnd={true} setTriggerRerenderingAfterDnd={props.setTriggerRerenderingAfterDnd} >
        <DndElement path={path} elementtype='p' setTriggerRerenderingAfterDnd={props.setTriggerRerenderingAfterDnd}>
          <Paragraph
            {...props}
            attrs={attrsProvider(element)}
            extraAttrs
            slatePath={path}
            elementtype={element.type}
          />
        </DndElement>
      </DndBlock>
    )
  }
  const { Component, dndOptions, beforeElementRender }: any = registryComponent;
  let { DisableDND, DisableSelectionHalo, getDroppableContainer, PreviewStyleClass, DisableGridDnd } = dndOptions || {}


  let selectableHalo = false;

  if (selection) {
    const range = Editor.range(editor, path)
    const intersection = Range.intersection(range, selection)
    if (range && intersection) {
      if (Range.equals(range, intersection) && Range.isExpanded(selection)) {
        selectableHalo = true
      }
    }
  }
  //Find parent type and import modify renderer
  const parentElement: any = Editor.parent(editor, path)
  if (parentElement?.[0]?.type) {
    const parentDoc: any = Registry.get({
      id: String(parentElement[0].type),
      registry: 'element'
    })
    if (parentDoc) {
      const { beforeChildrenRender }: any = parentDoc
      if (beforeChildrenRender) {
        let basicProps = {
          DisableDND,
          DisableSelectionHalo,
          element,
          path,
          editor,
          extraAttrs
        }
        beforeChildrenRender(basicProps)
        DisableDND = basicProps.DisableDND
        DisableSelectionHalo = basicProps.DisableSelectionHalo
        extraAttrs = basicProps.extraAttrs
      }
    }
  }

  if (beforeElementRender) {
    const isSelected = useSelected()
    let basicProps = {
      DisableDND,
      DisableSelectionHalo,
      element,
      path,
      editor,
      isSelected,
      extraAttrs,
    }
    beforeElementRender(basicProps)
    DisableDND = basicProps.DisableDND
    DisableSelectionHalo = basicProps.DisableSelectionHalo
    extraAttrs = basicProps.extraAttrs
  }
  const isChildrenEmpty = !!(
    Element.isElement(element) &&
    element.children.length === 1 &&
    //@ts-ignore
    element.children[0].text === ''
  )
  if (Component) {
    if (DisableDND) {
      if (DisableSelectionHalo) {
        return (
          <Component
            {...props}
            editor={editor}
            attrs={attrsProvider(element)}
            extraAttrs
            isChildrenEmpty={isChildrenEmpty}
            slatePath={path}
          />
        )
      }
      return (
        <div className={selectableHalo ? styles['selection-halo'] : ''}>
          <Component
            {...props}
            attrs={attrsProvider(element)}
            extraAttrs
            isChildrenEmpty={isChildrenEmpty}
            slatePath={path}
          />
        </div>
      )
    }
    let container = getDroppableContainer?.(element.type, path) || 'docs'

    if (DisableSelectionHalo) {
      return (
        <React.Fragment>
          <DndBlock singleLineRef={props.singleLineRef} path={path} editor={editor} collection={container} disablegriddnd={DisableGridDnd} setTriggerRerenderingAfterDnd={props.setTriggerRerenderingAfterDnd}>
            <DndElement path={path} {...lineHeightProvider(element)} elementtype={element.type} collection={container} previewstyleclass={PreviewStyleClass} setTriggerRerenderingAfterDnd={props.setTriggerRerenderingAfterDnd}>
              <Component
                {...props}
                attrs={attrsProvider(element)}
                extraAttrs
                isChildrenEmpty={isChildrenEmpty}
                slatePath={path}
              />
            </DndElement>
          </DndBlock>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <DndBlock singleLineRef={props.singleLineRef} path={path} element={element} editor={editor} collection={container} disablegriddnd={DisableGridDnd} setTriggerRerenderingAfterDnd={props.setTriggerRerenderingAfterDnd}>
          <DndElement
            path={path}
            element={element}
            {...lineHeightProvider(element)}
            elementtype={element.type}
            collection={container}
            previewstyleclass={PreviewStyleClass}
            setTriggerRerenderingAfterDnd={props.setTriggerRerenderingAfterDnd}>
            <div className={selectableHalo ? styles['selection-halo'] : ''}>
              <Component
                {...props}
                attrs={attrsProvider(element)}
                extraAttrs
                isChildrenEmpty={isChildrenEmpty}
                slatePath={path}
              />
            </div>
          </DndElement>
        </DndBlock>
      </React.Fragment>
    )
  }
}

export const PlainElement = (props) => {
  const { element } = props
  const editor = useSlate()
  const path = ReactEditor.findPath(editor as ReactEditor, element)
  const registeredComponent = Registry.get({ id: element.type, registry: 'element' })
  if (registeredComponent) {
    const { Component, ReadOnlyComponent }: any = registeredComponent
    if (ReadOnlyComponent) {
      return (<ReadOnlyComponent {...props} attrs={attrsProvider(element)} slatePath={path} />)
    }
    return (<Component {...props} attrs={attrsProvider(element)} slatePath={path} />)
  }
  return <Paragraph {...props} attrs={attrsProvider(element)} />
}

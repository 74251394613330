import './Timepicker.css'

import React, {
	useEffect,
	useState
} from 'react'

import className from 'classnames'
import TimeZoneStandard from './TimeZoneStandard'
import { defaultTimezone } from '../DateTime/helper'
import DSTTimeZoneList from '../DateTime/TimeZoneList'
import { ITimeSlider, ITimePicker } from '../DateTime/interfaces'
import TextInput from '../TextInput/TextInput'
import Button from '../Button/Button'
import Checkbox from '../Checkbox/Checkbox'
import Icon from '../Icon2/Icon'
import './Timepicker.css'
import ButtonGroup from '../Button/ButtonGroup/ButtonGroup'

const TimeSlider: React.FunctionComponent<ITimeSlider> = (props) => {
	const { name, value, onClick, min, max }: any = props
	return (
		<div className={`TimePicker__time TimePicker__time--${name}`}>
			<div className="TimePicker__time__title">{name}</div>
			<div className="TimePicker__time__box">
				<TextInput
					type="number"
					placeholder={'00'}
					value={value}
					className="TimePicker__time__value"
					min={min}
					max={max}
					onFocus={(event) => {
						event.preventDefault()
						event.stopPropagation()
					}}
					onChange={(event) => {
						event.preventDefault()
						onClick(event.target.value)
					}}
				/>
			</div>
		</div>
	)
}

const TimePicker2: React.FunctionComponent<ITimePicker> = (props) => {
	const { initialDate, onChange, viewType, onDone, onCancel, closeModal, useVisitorTimezone,
		useVisitorTimezoneOnChange,
		hasVisitorTimezoneCheckBox, doneCtaTestId, cancelCtaTestId } = props

	const initialDate2 = initialDate ? initialDate : '' //'00:00:00+0000';
	const getTimeZone = initialDate2.includes('+') ? initialDate2.split('+') : initialDate2.split('-')
	const getTimeArray = getTimeZone[0].split(':')
	const [time, setTime] = useState(initialDate2)
	const [hours, setHours] = useState(getTimeArray[0] ? parseInt(getTimeArray[0]) : '')
	const [minutes, setMinutes] = useState(getTimeArray[1] ? parseInt(getTimeArray[1]) : '')
	const [seconds, setSeconds] = useState(getTimeArray[2] ? parseInt(getTimeArray[2]) : '')

	const [zone, setZone] = useState(
		initialDate2 && initialDate2[0] ? (initialDate2.includes('+') ? `+${getTimeZone[1]}` : `-${getTimeZone[1]}`) : ''
	)

	const [dstZone, setDstZone] = useState(zone ? defaultTimezone(zone) : '')
	const [useUserTimezone, setUseUserTimezone] = useState(Boolean(useVisitorTimezone));

	useEffect(() => {
		const selectedTime = `${hours > 0 && hours < 10 ? '0' + hours : hours || '00'}:${minutes > 0 && minutes < 10 ? '0' + minutes : minutes || '00'
			}:${seconds > 0 && seconds < 10 ? '0' + seconds : seconds || '00'}${zone ? zone : ''}`

		if (onChange) {
			onChange(selectedTime)
		}
	}, [hours, minutes, seconds, zone])
	const currZoneObj = zone
		? DSTTimeZoneList().find((singleZoneObj) => zone === singleZoneObj.offsetString)
		: { label: '(GMT +5:30) India Standard Time - Kolkata', value: '+0530' }
	const [timeObj, setTimeObj] = useState(currZoneObj)

	const hourChangeHandler = (value: any) => {
		const hour = parseInt(value)

		if (hour >= 0 && hour < 24) {
			setHours(parseInt(value))
		}
	}
	const minuteChangeHandler = (value: any) => {
		const minute = parseInt(value)

		if (minute >= 0 && minute < 60) {
			setMinutes(parseInt(value))
		}
	}
	const secondChangeHandler = (value: any) => {
		const second = parseInt(value)

		if (second >= 0 && second < 60) {
			setSeconds(parseInt(value))
		}
	}
	const zoneHandler = (zone: any, obj: any) => {
		setZone(obj.offsetString)
		setDstZone(zone)
		setTimeObj(obj)
	}

	const doneHandler = () => {
		const selectedTime = `${hours > 0 && hours < 10 ? '0' + hours : hours || '00'}:${minutes > 0 && minutes < 10 ? '0' + minutes : minutes || '00'
			}:${seconds > 0 && seconds < 10 ? '0' + seconds : seconds || '00'}${zone ? zone : ''}`
		setTime(selectedTime)

		onDone(selectedTime, timeObj)
		if (closeModal) {
			closeModal()
		}
	}

	const cancelHandler = () => {
		if (onCancel) {
			onCancel(time)
		}
		if (closeModal) {
			closeModal()
		}
	}

	const handlePickerClick = (event: any) => {
		event.stopPropagation()
		if (
			!event.target.parentElement?.classList.contains('TimePicker__time__value') &&
			!event.target?.classList.contains('TimePicker__action') &&
			!event.target.parentElement?.classList.contains('TimePicker__action') &&
			!event.target.parentElement.parentElement?.classList.contains('TimePicker__action')
		) {
			let elems = document.querySelectorAll('.TimePicker__time--focus')
				;[].forEach.call(elems, function (el) {
					el.classList.remove('TimePicker__time--focus')
				})
		}
	}

	const useVisitorTimezoneChangedHandler = () => {
		useVisitorTimezoneOnChange(!useUserTimezone);
		setUseUserTimezone(!useUserTimezone);
	}

	return (
		<>
			<div
				className={className('TimePicker', {
					'TimePicker__date-time': viewType === 'datetime'
				})}
				onClick={handlePickerClick}>
				<div className="TimePicker--time">
					<div className="TimePicker__title">Choose Time</div>
					<div className="flex TimePicker__container ">
						<TimeSlider name="Hour" value={hours} onClick={hourChangeHandler} min={0} max={23} />
						<TimeSlider name="Minute" value={minutes} onClick={minuteChangeHandler} min={0} max={59} />
						<TimeSlider name="Second" value={seconds} onClick={secondChangeHandler} min={0} max={59} />
					</div>
				</div>

				<div className="TimePicker--timezone">
					<div className="TimePicker__title">Choose Time Zone</div>
					{<TimeZoneStandard dstZone={dstZone} zone={zone} onChange={zoneHandler} disableTzDropdown={useUserTimezone} />}
				</div>

				{hasVisitorTimezoneCheckBox &&
					<div className='TimePicker--useUserTimezone'>
						<div className="Checkbox-wrapper">
							<Checkbox
								onChange={useVisitorTimezoneChangedHandler}
								label={'User\'s timezone'}
								checked={useUserTimezone}
							/>
							<Icon className='pl-10 TimePicker__icon' icon='InformationOutlineSmall' />
						</div>
					</div>
				}

				{viewType !== 'datetime' && (
					<div className="TimePicker__actions flex-right">
						<ButtonGroup>
							<Button testId={`${cancelCtaTestId ? cancelCtaTestId : 'cs-time-picker-cancel'}`} buttonType="tertiary" onClick={cancelHandler}>
								Cancel
							</Button>
							<Button testId={`${doneCtaTestId ? doneCtaTestId : 'cs-time-picker-done'}`} size="small" buttonType="primary" version='v2' onClick={doneHandler} >
								Done
							</Button>
						</ButtonGroup>
					</div>
				)}
			</div>
		</>
	)
}
export default TimePicker2

import React from "react"


type AssetWrapperProps = {
  version?: 'v1' | 'v2',
  children: React.ReactNode,
  className?: string
}

export const AssetWrapper = ({
  version,
  children,
  className
}: AssetWrapperProps) => {
  const isV2 = version === 'v2';

  if (isV2) {
    return <div className={className} >{children}</div>
  }
  return (
    <>{children}</>
  )
}
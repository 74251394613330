import CheckCircle from './svgV2/CheckCircle.svg';
import CloseBorder from './svgV2/CloseBorder.svg';
import InformationCircle from './svgV2/InformationCircle.svg';
import WarningBold from './svgV2/WarningBold.svg';
import CloseNoborder from './svgV2/CloseNoborder.svg';
import Stacks from './svgV2/Stacks.svg';
import Pin from './svgV2/Pin.svg';
import ArrowDown from './svgV2/ArrowDown.svg';
import ArrowUp from './svgV2/ArrowUp.svg';
import Chat from './svgV2/Chat.svg';
import Setting from './svgV2/Setting.svg';
import Image from './svgV2/Image.svg';
import Document from './svgV2/Document.svg';
import Code from './svgV2/Code.svg';
import CodeSecondary from './svgV2/CodeSecondary.svg';
import DropDown from './svgV2/DropDown.svg';
import Filter from './svgV2/Filter.svg';
import Search from './svgV2/Search.svg';
import Copy from './svgV2/Copy.svg';
import Delete from './svgV2/Delete.svg';
import ChangeView from './svgV2/ChangeView.svg';
import Save from './svgV2/Save.svg';
import Folder from './svgV2/Folder.svg';
import FolderSimplePlus from './svgV2/FolderSimplePlus.svg';
import Expand from './svgV2/Expand.svg';
import Refresh from './svgV2/Refresh.svg';
import List from './svgV2/List.svg';
import Users from './svgV2/Users.svg';
import Dashboard from './svgV2/Dashboard.svg';
import Entries from './svgV2/Entries.svg';
import Edit from './svgV2/Edit.svg';
import ComfortView from './svgV2/ComfortView.svg';
import CompactView from './svgV2/CompactView.svg';
import Boolean from './svgV2/Boolean.svg';
import Custom from './svgV2/Custom.svg';
import Date from './svgV2/Date.svg';
import File from './svgV2/File.svg';
import FileArrowUp from './svgV2/FileArrowUp.svg'
import Global from './svgV2/Global.svg';
import FieldResize from './svgV2/FieldResize.svg';
import Group from './svgV2/Group.svg';
import Link from './svgV2/Link.svg';
import Markdown from './svgV2/Markdown.svg';
import ModularBlocks from './svgV2/ModularBlocks.svg';
import MultiLineText from './svgV2/MultiLineText.svg';
import Number from './svgV2/Number.svg';
import Reference from './svgV2/Reference.svg';
import RichTextEditor from './svgV2/RichTextEditor.svg';
import Select from './svgV2/Select.svg';
import SingleLineText from './svgV2/SingleLineText.svg';
import Clock from './svgV2/Clock.svg';
import ClockMedium from './svgV2/ClockMedium.svg';
import Avatar from './svgV2/Avatar.svg';
import Plus from './svgV2/Plus.svg';
import Warning from './svgV2/Warning.svg';
import LeftArrow from './svgV2/LeftArrow.svg';
import Lock from './svgV2/Lock.svg';
import H1 from './svgV2/H1.svg';
import H2 from './svgV2/H2.svg';
import H3 from './svgV2/H3.svg';
import H4 from './svgV2/H4.svg';
import H5 from './svgV2/H5.svg';
import H6 from './svgV2/H6.svg';
import Bold from './svgV2/Bold.svg';
import Italic from './svgV2/Italic.svg';
import Underline from './svgV2/Underline.svg';
import StrikeThrough from './svgV2/StrikeThrough.svg';
import Check from './svgV2/Check.svg';
import Help from './svgV2/Help.svg';
import Email from './svgV2/Email.svg';
import Key from './svgV2/Key.svg';
import Upload from './svgV2/Upload.svg';
import Login from './svgV2/Login.svg';
import EnvironmentColor from './svgV2/EnvironmentColor.svg';
import Eye from './svgV2/Eye.svg';
import EyeClose from './svgV2/EyeClose.svg';
import OpenLock from './svgV2/OpenLock.svg';
import HTML2 from './svgV2/HTML2.svg';
import PDF2 from './svgV2/PDF2.svg';
import DOC2 from './svgV2/DOC2.svg';
import MP4 from './svgV2/MP4.svg';
import MP3 from './svgV2/MP3.svg';
import JS from './svgV2/JS.svg';
import Zip from './svgV2/Zip.svg';
import CSV from './svgV2/CSV.svg';
import JPG from './svgV2/JPG.svg';
import PNG from './svgV2/PNG.svg';
import CSS from './svgV2/CSS.svg';
import GitBranch from './svgV2/GitBranch.svg';
import Pie from './svgV2/Pie.svg';
import Webhooks from './svgV2/Webhooks.svg';
import Feedback from './svgV2/Feedback.svg';
import CaretDown from './svgV2/CaretDown.svg';
import CaretRight from './svgV2/CaretRight.svg';
import CaretLeft from './svgV2/CaretLeft.svg';
import Compress from './svgV2/Compress.svg';
import ArrowsInLineVertical from './svgV2/ArrowsInLineVertical.svg';
import ArrowsOutLineVertical from './svgV2/ArrowsOutLineVertical.svg';
import SeeDetails from './svgV2/SeeDetails.svg';
import Paragraph from './svgV2/Paragraph.svg';
import ApiDocs from './svgV2/ApiDocs.svg';
import ApiConsole from './svgV2/ApiConsole.svg';
import ApiTokens from './svgV2/ApiTokens.svg';
import Information from './svgV2/Information.svg';
import Audit from './svgV2/Audit.svg';
import Notification from './svgV2/Notification.svg';
import NotificationAlert from './svgV2/NotificationAlert.svg';
import Rename from './svgV2/Rename.svg';
import InfoSecondary from './svgV2/InfoSecondary.svg';
import Extension from './svgV2/Extension.svg';
import Language from './svgV2/Language.svg';
import NewTab from './svgV2/NewTab.svg';
import MinusSign from './svgV2/MinusSign.svg';
import GlobalField from './svgV2/GlobalField.svg';
import ChatSupport from './svgV2/ChatSupport.svg';
import Chevron from './svgV2/Chevron.svg';
import HelpCentre from './svgV2/HelpCentre.svg';
import Send from './svgV2/Send.svg';
import Restore from './svgV2/Restore.svg';
import RestAlias from './svgV2/RestAlias.svg';
import EntryChatAlert from './svgV2/EntryChatAlert.svg';
import Update from './svgV2/Update.svg';
import WhatsNew from './svgV2/WhatsNew.svg';
import StatusPage from './svgV2/StatusPage.svg';
import ProductTour from './svgV2/ProductTour.svg';
import KeyboardShortcut from './svgV2/KeyboardShortcut.svg';
import Create from './svgV2/Create.svg';
import ParentGroupIcon from './svgV2/ParentGroupIcon.svg';
import ParentModularBlockIcon from './svgV2/ParentModularBlockIcon.svg';
import ParentGlobalFieldIcon from './svgV2/ParentGlobalFieldIcon.svg';
import CheckBox from './svgV2/CheckBox.svg';
import SuccessTransferOwnership from './svgV2/SuccessTransferOwnership.svg';
import FailTransferOwnership from './svgV2/FailTransferOwnership.svg';
import Cancel from './svgV2/Cancel.svg';
import Disk from './svgV2/Disk.svg';
import ChangeVersion from './svgV2/ChangeVersion.svg';
import PublishQueue from './svgV2/PublishQueue.svg';
import Delivery from './svgV2/Delivery.svg';
import Executable from './svgV2/Executable.svg';
import Archive from './svgV2/Archive.svg';
import Audio from './svgV2/Audio.svg';
import Video from './svgV2/Video.svg';
import Assets from './svgV2/Assets.svg';
import LearnMore from './svgV2/LearnMore.svg';
import StackSettings from './svgV2/StackSettings.svg';
import PublishRule from './svgV2/PublishRule.svg';
import Workflow from './svgV2/Workflow.svg';
import UsersRoles from './svgV2/UsersRoles.svg';
import Compare from './svgV2/Compare.svg';
import Move from './svgV2/Move.svg';
import InviteUser from './svgV2/InviteUser.svg';
import Tasks from './svgV2/Tasks.svg';
import TestSSO from './svgV2/TestSSO.svg';
import Preferences from './svgV2/Preferences.svg';
import Proceed from './svgV2/Proceed.svg';
import Management from './svgV2/Management.svg';
import Enable from './svgV2/Enable.svg';
import Deploy from './svgV2/Deploy.svg';
import LivePreview from './svgV2/LivePreview.svg';
import NewEntry from './svgV2/NewEntry.svg';
import Docs from './svgV2/Docs.svg';
import Taxonomy from './svgV2/Taxonomy.svg';
import TrashWhite from './svgV2/TrashWhite.svg'
import Left from './svgV2/Left.svg';
import Leftmost from './svgV2/Leftmost.svg';
import LeftConnector from './svgV2/LeftConnector.svg';
import Right from './svgV2/Right.svg';
import Rightmost from './svgV2/Rightmost.svg';
import RightConnector from './svgV2/RightConnector.svg';
import GridView from './svgV2/GridView.svg';
import Intercom from './svgV2/Intercom.svg';
import OrgSettings from './svgV2/OrgSettings.svg';
import Star from './svgV2/Star.svg';
import PublishIcon from './svgV2/PublishIcon.svg';
import UnpublishIcon from './svgV2/UnpublishIcon.svg';
import Widgets from './svgV2/Widgets.svg';
import Podium from './svgV2/Podium.svg';
import Layout from './svgV2/Layout.svg';
import Success from './svgV2/Success.svg';
import Security from './svgV2/Security.svg';
import Hamburger from './svgV2/Hamburger.svg';
import NewFolder from './svgV2/NewFolder.svg';
import Version from './svgV2/Version.svg';
import Bulb from './svgV2/Bulb.svg';
import Roles from './svgV2/Roles.svg';
import Invitation from './svgV2/Invitation.svg';
import Replace from './svgV2/Replace.svg';
import Equals from './svgV2/Equals.svg';
import NotEquals from './svgV2/NotEquals.svg';
import SeeMore from './svgV2/SeeMore.svg';
import ActionBar from './svgV2/ActionBar.svg';
import Import from './svgV2/Import.svg';
import Export from './svgV2/Export.svg';
import LightBulb from './svgV2/LightBulb.svg';
import Close from './svgV2/Close.svg';
import Status from './svgV2/Status.svg';
import Pause from './svgV2/Pause.svg';
import Resume from './svgV2/Resume.svg';
import Undo from './svgV2/Undo.svg';
import Redo from './svgV2/Redo.svg';
import Quote from './svgV2/Quote.svg';
import Alignment from './svgV2/Alignment.svg';
import Properties from './svgV2/Properties.svg';
import FolderOpen from './svgV2/FolderOpen.svg';
import Reload from './svgV2/Reload.svg';
import Profile from './svgV2/Profile.svg';
import DashboardWidgetVersion1 from './svgV2/DashboardWidgetVersion1.svg';
import CustomWidget from './svgV2/CustomWidget.svg';
import Analytics from './svgV2/Analytics.svg';
import ReadComment from './svgV2/ReadComment.svg';
import NewComment from './svgV2/NewComment.svg';
import AddComment from './svgV2/AddComment.svg';
import StageIcon from './svgV2/StageIcon.svg';
import Community from './svgV2/Community.svg';
import Time from './svgV2/Time.svg';
import Apicalls from './svgV2/Apicalls.svg';
import Scope from './svgV2/Scope.svg';
import InternalLink from './svgV2/InternalLink.svg';
import SortingDesc from './svgV2/SortingDesc.svg';
import SortingAsc from './svgV2/SortingAsc.svg';
import Env from './svgV2/Env.svg';
import Orientation from './svgV2/Orientation.svg';
import Viewport from './svgV2/Viewport.svg';
import DotsThreeLargeVertical from './svgV2/DotsThreeLargeVertical.svg';
import Freezed from './svgV2/Freezed.svg';
import Description from './svgV2/Description.svg';
import SuperUser from './svgV2/SuperUser.svg';
import RefreshEntries from './svgV2/RefreshEntries.svg';
import PersonalizationEvents from './svgV2/PersonalizationEvents.svg';
import ScholarHat from './svgV2/ScholarHat.svg';
import AudienceExperience from './svgV2/AudienceExperience.svg';
import ABTestingAnalytics from './svgV2/ABTestingAnalytics.svg';
import ABTesting from './svgV2/ABTesting.svg';
import ABTestingMetrics from './svgV2/ABTestingMetrics.svg';
import ABTestingTargeting from './svgV2/ABTestingTargeting.svg';
import GuidedTour from './svgV2/GuidedTour.svg';
import CellNetwork from './svgV2/CellNetwork.svg';
import Reminder from './svgV2/Reminder.svg';
import FormSubmit from './svgV2/FormSubmit.svg';
import Bucket from './svgV2/Bucket.svg';
import OngoingTask from './svgV2/OngoingTask.svg';
import CompletedTask from './svgV2/CompletedTask.svg';
import GlobeWithCursor from './svgV2/GlobeWithCursor.svg';
import Redeploy from './svgV2/Redeploy.svg';
import CreatedAt from './svgV2/CreatedAt.svg';
import Embed from './svgV2/Embed.svg';
import Teams from './svgV2/Teams.svg';
import ServerSelection from './svgV2/ServerSelection.svg';
import DontSave from './svgV2/DontSave.svg';
import MoveHere from './svgV2/MoveHere.svg';
import CrashedPage from './svgV2/CrashedPage.svg';
import ResetReverse from './svgV2/ResetReverse.svg';
import TaxonomySmall from './svgV2/TaxonomySmall.svg';
import Clone from './svgV2/Clone.svg';
import AllCollections from './svgV2/AllCollections.svg';
import AppConfiguration from './svgV2/AppConfiguration.svg';
import App from './svgV2/App.svg';
import AssetManagerSidebar from './svgV2/AssetManagerSidebar.svg';
import Automations from './svgV2/Automations.svg';
import ConnectedApps from './svgV2/ConnectedApps.svg';
import CustomField from './svgV2/CustomField.svg';
import CustomModal from './svgV2/CustomModal.svg';
import DeveloperHub from './svgV2/DeveloperHub.svg';
import EntryEditorSidebar from './svgV2/EntryEditorSidebar.svg';
import ExecutionLog from './svgV2/ExecutionLog.svg';
import FetchTemplate from './svgV2/FetchTemplate.svg';
import FieldModifiers from './svgV2/FieldModifiers.svg';
import FullPageApp from './svgV2/FullPageApp.svg';
import Guides from './svgV2/Guides.svg';
import MarketPlace from './svgV2/MarketPlace.svg';
import Starter from './svgV2/Starter.svg';
import TextEditorApp from './svgV2/TextEditorApp.svg';
import ExperienceContainer from './svgV2/ExperienceContainer.svg';
import DashboardWidgetVersion2 from './svgV2/DashboardWidgetVersion2.svg';
import Url from './svgV2/Url.svg';
import JsonRichTextEditor from './svgV2/JsonRichTextEditor.svg';
import Tag from './svgV2/Tag.svg';
import SeperatorLine from './svgV2/SeperatorLine.svg'
import CloudArrowUp from './svgV2/CloudArrowUp.svg'
import InviteDeclined from './svgV2/InviteDeclined.svg'
import InviteReceived from './svgV2/InviteReceived.svg'
import AcceptInvite from './svgV2/AcceptInvite.svg'
import ArrowLineLeft from './svgV2/ArrowLineLeft.svg'
import Home from './svgV2/Home.svg'
import CheckCircleDark from './svgV2/CheckCircleDark.svg'
import Prohibit from './svgV2/Prohibit.svg'
import Question from './svgV2/Question.svg'
import ArrowLineRight from './svgV2/ArrowLineRight.svg'
import FlowArrow from './svgV2/FlowArrow.svg'
import ManageColumn from './svgV2/ManageColumn.svg'
import ThumbsDown from './svgV2/ThumbsDown.svg'
import ThumbsUp from './svgV2/ThumbsUp.svg'
import Unfreezed from './svgV2/Unfreezed.svg'
import RowDensity from './svgV2/RowDensity.svg'
import Download from './svgV2/Download.svg'
import DotsSixVertical from './svgV2/DotsSixVertical.svg'
import ListDashes from './svgV2/ListDashes.svg'
import CaretCircleRight from './svgV2/CaretCircleRight.svg'
import CaretCircleDoubleRight from './svgV2/CaretCircleDoubleRight.svg'
import CaretCircleLeft from './svgV2/CaretCircleLeft.svg'
import CaretCircleDoubleLeft from './svgV2/CaretCircleDoubleLeft.svg'
import NoSort from './svgV2/NoSort.svg'
import Discussions from './svgV2/Discussions.svg'
import OpenURL from './svgV2/OpenURL.svg'
import PushPin from './svgV2/PushPin.svg'
import DiscussionsNotifiction from './svgV2/DiscussionsNotifiction.svg'
import CalendarBlank from './svgV2/CalendarBlank.svg'
import InformationCircleNotification from './svgV2/InformationCircleNotification.svg'
import Releases from './svgV2/Releases.svg'
import PublishNew from './svgV2/PublishNew.svg'
import UnPublishNew from './svgV2/UnPublishNew.svg'
import SelectionFilled from './svgV2/SelectionFilled.svg'
import SelectionFilledPill from './svgV2/SelectionFilledPill.svg'
import SecurityConfiguration from './svgV2/SecurityConfiguration.svg'
import XLS from './svgV2/XLS.svg'
import XML from './svgV2/XML.svg'
import AVI from './svgV2/AVI.svg'
import PSD from './svgV2/PSD.svg'
import PPT from './svgV2/PPT.svg'
import RTF from './svgV2/RTF.svg'
import TXT from './svgV2/TXT.svg'
import AI from './svgV2/AI.svg'
import EXE from './svgV2/EXE.svg'
import JSON from './svgV2/JSON.svg'
import CaretDownNew from './svgV2/CaretDownNew.svg'
import Thumbnail from './svgV2/Thumbnail.svg'
import NewEntries from './svgV2/NewEntries.svg'

export const newVersionIconComponents = {
  CheckCircle,
  CloseBorder,
  InformationCircle,
  Releases,
  WarningBold,
  CloseNoborder,
  Stacks,
  Pin,
  ArrowDown,
  ArrowUp,
  Chat,
  Setting,
  Image,
  Document,
  Code,
  CodeSecondary,
  DropDown,
  Filter,
  Search,
  Copy,
  Delete,
  ChangeView,
  Save,
  Folder,
  FolderSimplePlus,
  Expand,
  Refresh,
  List,
  Users,
  Dashboard,
  Entries,
  Edit,
  ComfortView,
  CompactView,
  Boolean,
  Custom,
  Date,
  File,
  FileArrowUp,
  Global,
  FieldResize,
  Group,
  Link,
  Markdown,
  ModularBlocks,
  MultiLineText,
  Number,
  Reference,
  RichTextEditor,
  Select,
  SingleLineText,
  Clock,
  Plus,
  Warning,
  LeftArrow,
  Lock,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Bold,
  Italic,
  Underline,
  StrikeThrough,
  Check,
  Help,
  Email,
  Key,
  Upload,
  Login,
  EnvironmentColor,
  Eye,
  EyeClose,
  OpenLock,
  HTML2,
  PDF2,
  DOC2,
  MP4,
  MP3,
  JS,
  Zip,
  CSV,
  JPG,
  PNG,
  CSS,
  GitBranch,
  Pie,
  Webhooks,
  ClockMedium,
  Avatar,
  Feedback,
  CaretDown,
  CaretRight,
  CaretLeft,
  Compress,
  ArrowsInLineVertical,
  ArrowsOutLineVertical,
  SeeDetails,
  Paragraph,
  ApiDocs,
  ApiConsole,
  ApiTokens,
  Information,
  Audit,
  Notification,
  NotificationAlert,
  InfoSecondary,
  Rename,
  Extension,
  Language,
  NewTab,
  MinusSign,
  ChatSupport,
  GlobalField,
  Chevron,
  HelpCentre,
  Send,
  Restore,
  EntryChatAlert,
  RestAlias,
  Update,
  WhatsNew,
  StatusPage,
  ProductTour,
  KeyboardShortcut,
  Create,
  ParentGroupIcon,
  ParentModularBlockIcon,
  ParentGlobalFieldIcon,
  CheckBox,
  SuccessTransferOwnership,
  FailTransferOwnership,
  Cancel,
  Disk,
  ChangeVersion,
  PublishQueue,
  Delivery,
  Executable,
  Archive,
  Audio,
  Video,
  Assets,
  LearnMore,
  StackSettings,
  PublishRule,
  Workflow,
  UsersRoles,
  Compare,
  Move,
  InviteUser,
  Tasks,
  TestSSO,
  Preferences,
  Proceed,
  Management,
  Enable,
  Deploy,
  LivePreview,
  NewEntry,
  Docs,
  Taxonomy,
  Export,
  TrashWhite,
  Left,
  Leftmost,
  LeftConnector,
  Right,
  Rightmost,
  RightConnector,
  GridView,
  Intercom,
  OrgSettings,
  Star,
  PublishIcon,
  UnpublishIcon,
  Widgets,
  Podium,
  Layout,
  Success,
  Security,
  Hamburger,
  NewFolder,
  Version,
  Bulb,
  Roles,
  Invitation,
  Replace,
  Equals,
  NotEquals,
  SeeMore,
  ActionBar,
  Import,
  LightBulb,
  Close,
  Status,
  Pause,
  Resume,
  Undo,
  Redo,
  Quote,
  Alignment,
  Properties,
  FolderOpen,
  Reload,
  Profile,
  DashboardWidgetVersion1,
  CustomWidget,
  Analytics,
  ReadComment,
  NewComment,
  AddComment,
  StageIcon,
  Community,
  Time,
  Apicalls,
  Scope,
  InternalLink,
  SortingDesc,
  SortingAsc,
  Env,
  Orientation,
  Viewport,
  DotsThreeLargeVertical,
  Freezed,
  Description,
  SuperUser,
  RefreshEntries,
  PersonalizationEvents,
  ScholarHat,
  AudienceExperience,
  ABTestingAnalytics,
  ABTesting,
  ABTestingMetrics,
  ABTestingTargeting,
  GuidedTour,
  CellNetwork,
  Reminder,
  FormSubmit,
  Bucket,
  OngoingTask,
  CompletedTask,
  GlobeWithCursor,
  Redeploy,
  CreatedAt,
  Embed,
  Teams,
  ServerSelection,
  DontSave,
  MoveHere,
  CrashedPage,
  ResetReverse,
  TaxonomySmall,
  Clone,
  AllCollections,
  AppConfiguration,
  App,
  AssetManagerSidebar,
  Automations,
  ConnectedApps,
  CustomField,
  CustomModal,
  DeveloperHub,
  EntryEditorSidebar,
  ExecutionLog,
  FetchTemplate,
  FieldModifiers,
  FullPageApp,
  Guides,
  MarketPlace,
  Starter,
  TextEditorApp,
  ExperienceContainer,
  DashboardWidgetVersion2,
  Url,
  JsonRichTextEditor,
  Tag,
  SeperatorLine,
  CloudArrowUp,
  InviteDeclined,
  InviteReceived,
  AcceptInvite,
  ArrowLineLeft,
  Home,
  CheckCircleDark,
  Prohibit,
  Question,
  ArrowLineRight,
  FlowArrow,
  ManageColumn,
  ThumbsDown,
  ThumbsUp,
  Unfreezed,
  RowDensity,
  Download,
  DotsSixVertical,
  ListDashes,
  CaretCircleRight,
  CaretCircleDoubleRight,
  CaretCircleLeft,
  CaretCircleDoubleLeft,
  NoSort,
  Discussions,
  OpenURL,
  PushPin,
  DiscussionsNotifiction,
  CalendarBlank,
  InformationCircleNotification,
  PublishNew,
  UnPublishNew,
  SelectionFilled,
  SelectionFilledPill,
  SecurityConfiguration,
  XLS,
  XML,
  AVI,
  PSD,
  PPT,
  RTF,
  TXT,
  AI,
  EXE,
  JSON,
  CaretDownNew,
  Thumbnail,
  NewEntries
}

export const newVersionIconNames = {
  CheckCircle: 'CheckCircle',
  CloseBorder: 'CloseBorder',
  InformationCircle: 'InformationCircle',
  WarningBold: 'WarningBold',
  CloseNoborder: 'CloseNoborder',
  Stacks: 'Stacks',
  Pin: 'Pin',
  ArrowDown: 'ArrowDown',
  ArrowUp: 'ArrowUp',
  Chat: 'Chat',
  Setting: 'Setting',
  Image: 'Image',
  Document: 'Document',
  Code: 'Code',
  CodeSecondary: 'CodeSecondary',
  DropDown: 'DropDown',
  Filter: 'Filter',
  Search: 'Search',
  Copy: 'Copy',
  Delete: 'Delete',
  ChangeView: 'ChangeView',
  Save: 'Save',
  Folder: 'Folder',
  FolderSimplePlus: 'FolderSimplePlus',
  Expand: 'Expand',
  Refresh: 'Refresh',
  List: 'List',
  Users: 'Users',
  Dashboard: 'Dashboard',
  Entries: 'Entries',
  Edit: 'Edit',
  ComfortView: 'ComfortView',
  CompactView: 'CompactView',
  Boolean: 'Boolean',
  Custom: 'Custom',
  Date: 'Date',
  File: 'File',
  FileArrowUp: 'FileArrowUp',
  Global: ' Global',
  FieldResize: 'FieldResize',
  Group: 'Group',
  Link: 'Link',
  Markdown: 'Markdown',
  ModularBlocks: 'ModularBlocks',
  MultiLineText: 'MultiLineText',
  Number: 'Number',
  Reference: 'Reference',
  RichTextEditor: 'RichTextEditor',
  Select: 'Select',
  SingleLineText: 'SingleLineText',
  Clock: 'Clock',
  ClockMedium: 'ClockMedium',
  Avatar: 'Avatar',
  Plus: 'Plus',
  Warning: 'Warning',
  LeftArrow: 'LeftArrow',
  Lock: 'Lock',
  H1: 'H1',
  H2: 'H2',
  H3: 'H3',
  H4: 'H4',
  H5: 'H5',
  H6: 'H6',
  Bold: 'Bold',
  Italic: 'Italic',
  Underline: 'Underline',
  StrikeThrough: 'StrikeThrough',
  Check: 'Check',
  Help: 'Help',
  Email: 'Email',
  Key: 'Key',
  Upload: 'Upload',
  Login: 'Login',
  EnvironmentColor: 'EnvironmentColor',
  Eye: 'Eye',
  EyeClose: 'EyeClose',
  OpenLock: 'OpenLock',
  HTML2: 'HTML2',
  PDF2: 'PDF2',
  DOC2: 'DOC2',
  MP4: 'MP4',
  MP3: 'MP3',
  JS: 'JS',
  Zip: 'Zip',
  CSV: 'CSV',
  JPG: 'JPG',
  PNG: 'PNG',
  CSS: 'CSS',
  GitBranch: 'GitBranch',
  Pie: 'Pie',
  Webhooks: 'Webhooks',
  Feedback: 'Feedback',
  CaretDown: 'CaretDown',
  CaretRight: 'CaretRight',
  CaretLeft: 'CaretLeft',
  Compress: 'Compress',
  ArrowsInLineVertical: 'ArrowsInLineVertical',
  ArrowsOutLineVertical: 'ArrowsOutLineVertical',
  SeeDetails: 'SeeDetails',
  Paragraph: 'Paragraph',
  ApiDocs: 'ApiDocs',
  ApiConsole: 'ApiConsole',
  ApiTokens: 'ApiTokens',
  Information: 'Information',
  Audit: 'Audit',
  Notification: 'Notification',
  NotificationAlert: 'NotificationAlert',
  InfoSecondary: 'InfoSecondary',
  Rename: 'Rename',
  Extension: 'Extension',
  Language: 'Language',
  NewTab: 'NewTab',
  MinusSign: 'MinusSign',
  ChatSupport: 'ChatSupport',
  GlobalField: 'GlobalField',
  Chevron: 'Chevron',
  HelpCentre: 'HelpCentre',
  Send: 'Send',
  Restore: 'Restore',
  EntryChatAlert: 'EntryChatAlert',
  RestAlias: 'RestAlias',
  Update: 'Update',
  WhatsNew: 'WhatsNew',
  StatusPage: 'StatusPage',
  ProductTour: 'ProductTour',
  KeyboardShortcut: 'KeyboardShortcut',
  Create: 'Create',
  ParentGroupIcon: 'ParentGroupIcon',
  ParentModularBlockIcon: 'ParentModularBlockIcon',
  ParentGlobalFieldIcon: 'ParentGlobalFieldIcon',
  CheckBox: 'CheckBox',
  SuccessTransferOwnership: 'SuccessTransferOwnership',
  FailTransferOwnership: 'FailTransferOwnership',
  Cancel: 'Cancel',
  Disk: 'Disk',
  ChangeVersion: 'ChangeVersion',
  PublishQueue: 'PublishQueue',
  Delivery: 'Delivery',
  Executable: 'Executable',
  Archive: 'Archive',
  Audio: 'Audio',
  Video: 'Video',
  Assets: 'Assets',
  LearnMore: 'LearnMore',
  StackSettings: 'StackSettings',
  PublishRule: 'PublishRule',
  Workflow: 'Workflow',
  UsersRoles: 'UsersRoles',
  Compare: 'Compare',
  Move: 'Move',
  InviteUser: 'InviteUser',
  Tasks: 'Tasks ',
  TestSSO: 'TestSSO',
  Preferences: 'Preferences',
  Proceed: 'Proceed',
  Management: 'Management',
  Enable: 'Enable',
  Deploy: 'Deploy',
  LivePreview: 'LivePreview',
  NewEntry: 'NewEntry',
  Docs: 'Docs',
  Taxonomy: 'Taxonomy',
  Export: 'Export',
  TrashWhite: 'TrashWhite',
  Left: 'Left',
  Leftmost: 'Leftmost',
  LeftConnector: 'LeftConnector',
  Right: 'Right',
  Rightmost: 'Rightmost',
  RightConnector: 'RightConnector',
  GridView: 'GridView',
  Intercom: 'Intercom',
  OrgSettings: 'OrgSettings',
  Star: 'Star',
  PublishIcon: 'PublishIcon',
  UnpublishIcon: 'UnpublishIcon',
  Widgets: 'Widgets',
  Podium: 'Podium',
  Layout: 'Layout',
  Success: 'Success',
  Security: 'Security',
  Hamburger: 'Hamburger',
  NewFolder: 'NewFolder',
  Version: 'Version',
  Bulb: 'Bulb',
  Roles: 'Roles',
  Invitation: 'Invitation',
  Replace: 'Replace',
  Equals: 'Equals',
  NotEquals: 'NotEquals',
  SeeMore: 'SeeMore',
  ActionBar: 'ActionBar',
  Import: 'Import',
  LightBulb: 'LightBulb',
  Close: 'Close',
  Status: 'Status',
  Pause: 'Pause',
  Resume: 'Resume',
  Undo: 'Undo',
  Redo: 'Redo',
  Quote: 'Quote',
  Alignment: 'Alignment',
  Properties: 'Properties',
  FolderOpen: 'FolderOpen',
  Reload: 'Reload',
  Profile: 'Profile',
  DashboardWidgetVersion1: 'DashboardWidgetVersion1',
  CustomWidget: 'CustomWidget',
  Analytics: 'Analytics',
  ReadComment: 'ReadComment',
  NewComment: 'NewComment',
  AddComment: 'AddComment',
  StageIcon: 'StageIcon',
  Community: 'Community',
  Time: 'Time',
  Apicalls: 'Apicalls',
  Scope: 'Scope',
  InternalLink: 'InternalLink',
  SortingDesc: 'SortingDesc',
  SortingAsc: 'SortingAsc',
  Env: 'Env',
  Orientation: 'Orientation',
  Viewport: 'Viewport',
  DotsThreeLargeVertical: 'DotsThreeLargeVertical',
  Freezed: 'Freezed',
  Description: 'Description',
  SuperUser: 'SuperUser',
  RefreshEntries: 'RefreshEntries',
  PersonalizationEvents: 'PersonalizationEvents',
  ScholarHat: 'ScholarHat',
  AudienceExperience: 'AudienceExperience',
  ABTestingAnalytics: 'ABTestingAnalytics',
  ABTesting: 'ABTesting',
  ABTestingMetrics: 'ABTestingMetrics',
  ABTestingTargeting: 'ABTestingTargeting',
  GuidedTour: 'GuidedTour',
  CellNetwork: 'CellNetwork',
  Reminder: 'Reminder',
  FormSubmit: 'FormSubmit',
  Bucket: 'Bucket',
  OngoingTask: 'OngoingTask',
  CompletedTask: 'CompletedTask',
  GlobeWithCursor: 'GlobeWithCursor',
  Redeploy: 'Redeploy',
  CreatedAt: 'CreatedAt',
  Embed: 'Embed',
  Teams: 'Teams',
  ServerSelection: 'ServerSelection',
  DontSave: 'DontSave',
  MoveHere: 'MoveHere',
  CrashedPage: 'CrashedPage',
  ResetReverse: 'ResetReverse',
  TaxonomySmall: 'TaxonomySmall',
  Clone: 'Clone',
  AllCollections: 'AllCollections',
  AppConfiguration: 'AppConfiguration',
  App: 'App',
  AssetManagerSidebar: 'AssetManagerSidebar',
  Automations: 'Automations',
  ConnectedApps: 'ConnectedApps',
  CustomField: 'CustomField',
  CustomModal: 'CustomModal',
  DeveloperHub: 'DeveloperHub',
  EntryEditorSidebar: 'EntryEditorSidebar',
  ExecutionLog: 'ExecutionLog',
  FetchTemplate: 'FetchTemplate',
  FieldModifiers: 'FieldModifiers',
  FullPageApp: 'FullPageApp',
  Guides: 'Guides',
  MarketPlace: 'MarketPlace',
  Starter: 'Starter',
  TextEditorApp: 'TextEditorApp',
  ExperienceContainer: 'ExperienceContainer',
  DashboardWidgetVersion2: 'DashboardWidgetVersion2',
  Url: 'Url',
  JsonRichTextEditor: 'JsonRichTextEditor',
  Tag: 'Tag',
  SeperatorLine: 'SeperatorLine',
  CloudArrowUp: 'CloudArrowUp',
  InviteDeclined: 'InviteDeclined',
  InviteReceived: 'InviteReceived',
  AcceptInvite: 'AcceptInvite',
  ArrowLineLeft: 'ArrowLineLeft',
  Home: 'Home',
  CheckCircleDark: 'CheckCircleDark',
  Prohibit: 'Prohibit',
  Question: 'Question',
  ArrowLineRight: 'ArrowLineRight',
  FlowArrow: 'FlowArrow',
  ManageColumn: 'ManageColumn',
  ThumbsDown: 'ThumbsDown',
  ThumbsUp: 'ThumbsUp',
  Unfreezed: 'Unfreezed',
  RowDensity: 'RowDensity',
  Download: 'Download',
  DotsSixVertical: 'DotsSixVertical',
  ListDashes: 'ListDashes',
  CaretCircleRight: 'CaretCircleRight',
  CaretCircleDoubleRight: 'CaretCircleDoubleRight',
  CaretCircleLeft: 'CaretCircleLeft',
  CaretCircleDoubleLeft: 'CaretCircleDoubleLeft',
  NoSort: 'NoSort',
  Discussions: 'Discussions',
  OpenURL: 'OpenURL',
  PushPin: 'PushPin',
  DiscussionsNotifiction: 'DiscussionsNotifiction',
  CalendarBlank: 'CalendarBlank',
  InformationCircleNotification: 'InformationCircleNotification',
  Releases: 'Releases',
  PublishNew: 'PublishNew',
  UnPublishNew: 'UnPublishNew',
  SelectionFilled: 'SelectionFilled',
  SelectionFilledPill: 'SelectionFilledPill',
  SecurityConfiguration: 'SecurityConfiguration',
  XLS: 'XLS',
  XML: 'XML',
  AVI: 'AVI',
  PSD: 'PSD',
  PPT: 'PPT',
  RTF: 'RTF',
  TXT: 'TXT',
  AI: 'AI',
  EXE: 'EXE',
  JSON: 'JSON',
  CaretDownNew: 'CaretDownNew',
  Thumbnail: 'Thumbnail',
  NewEntries: 'NewEntries'
}
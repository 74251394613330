//@ts-nocheck
import { Transforms, Editor } from 'slate'
import { Registry } from '@react-registry'
import React from 'react'

import { defaultTable } from './defaultTable'
import { elementCategories } from '../../elementCategories'

import { InsertTable } from './CustomTableIcon/insertTable'
import { useSlate } from 'slate-react'

import { disableOnCodeChild } from '../../code/utils'
import { TableCreateIcon } from '../../../utils/Icon'
import { TableIcon } from '../../../utils/Icon'

export const addTable = (event, { editor }) => {
  const { selection } = editor

  const tableAttributes = defaultTable(2, 2)
  if (selection && selection.focus.path) {
    const { path } = selection.focus

    let paragraphPath = path.slice(0, path.length - 1)
    paragraphPath[paragraphPath.length - 1] = paragraphPath[paragraphPath.length - 1] + 2
    let tablePath = paragraphPath.slice()
    tablePath[tablePath.length - 1] = tablePath[tablePath.length - 1] - 1
    tablePath = [...tablePath, 0, 0, 0, 0]

    Editor.withoutNormalizing(editor, () => {
      Transforms.insertNodes(editor, tableAttributes)
      Transforms.select(editor, tablePath)
    })

    Transforms.insertNodes(
      editor,
      {
        type: 'p',
        attrs: {},
        children: [{ text: '' }]
      },
      { at: [...paragraphPath] }
    )
  }
  return
}

const CreateTableButton = (props) => {
  const editor = useSlate()
  return (
    <React.Fragment>
      {props.children({
        handleMouseDown: addTable,
        isDisable: { func: disableOnCodeChild, params: { editor } },
        CustomIcon: InsertTable,
        additionalParams: { editor }
      })}
    </React.Fragment>
  )
}

const shortcutAddTable = (event, editor, format, registryObj) => {
  addTable(event, { editor })
}

Registry.register(
  {
    IngressComponent: CreateTableButton,
    category: elementCategories.TABLE_ACTION,
    iconName: <TableCreateIcon />,
    title: 'Insert table',
    slashTitle: 'Table',
    slashIconName: <TableIcon />,
    keywords: ['table'],
    shortcut: { key: 'mod+alt+shift+t', callback: shortcutAddTable },
    toolbar: {
      inSlashToolbar: true
    },
    canCustomize: false,
    inBasicToolbar: false,
    isContentStackElement: true
  },
  {
    id: 'table-create-table',
    registry: 'v2.element'
  }
)

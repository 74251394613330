import React from 'react'
import { Registry } from '@react-registry'
import { cx } from '@emotion/css'
import { ReactEditor, useSelected, useSlateStatic } from 'slate-react'
import { Editor, Path, Range } from 'slate'

//@ts-ignore
import styles from '../style.module.css'
import TableColumnDnd from '../table-column-dnd/index'
import TableRowAdd from '../table-footer/index'
import { getSelectedCells } from '../utils/getSelectedCells'
import { jsx } from 'slate-hyperscript'
import { toRedactor } from '../../../../'
import { checkIfIsSelectionOnlyInTable } from '../utils/isSelectionOnlyInTable'
export * from './deserializer'
const Table = (props) => {
  const { attributes, children, element, slatePath } = props
  let attrs
  if (element.attrs) {
    attrs = element.attrs
  }
  const templateColumns = attrs.colWidths.map(w => `${w}px`).join(' ')
  let isSelected = useSelected()
  const editor = useSlateStatic()

  const isSingleCellSelected = Boolean(Editor.above(editor, {
    // @ts-ignore
    match: (node) => ['td', 'th'].includes(node.type)
  }))
  
  const tablePath = ReactEditor.findPath(editor as ReactEditor, element)
  const isSelectionOnlyInTable = checkIfIsSelectionOnlyInTable(editor, tablePath)
  const disableSelection = editor.selection && !isSingleCellSelected && isSelectionOnlyInTable
  return (
    <div
      data-type="table"
      data-testid="table"
      className={cx(styles['div-table'], 'scrte-table', { 'scrte-table-selected': isSelected }, { 'scrte-table-selected--hide-selection': disableSelection })}
      {...attributes}
      data-row={attrs.rows}
      data-col={attrs.cols}
      data-colwidth={attrs.colWidths}
      style={{ '--template-column': templateColumns }}
      onCopy={e => handleTableCopy(e, isSingleCellSelected, isSelectionOnlyInTable, editor, tablePath)}>
      <TableColumnDnd
        element={props.element}
        tablePath={slatePath}
        tableRef={attributes.ref}
        isSelected={isSelected}
      ></TableColumnDnd>
      {children}
      {isSelected && <TableRowAdd tablePath={slatePath}></TableRowAdd>}
    </div>
  )
}


function handleTableCopy(
  event: React.ClipboardEvent,
  isSingleCellSelected: boolean,
  isSelectionOnlyInTable: boolean,
  editor: Editor,
  tablePath: Path
) {
  if (isSingleCellSelected || !isSelectionOnlyInTable) return
  const selectedCells = getSelectedCells(editor, tablePath)

  let rows = {}

  for (const [cell, cellPath] of selectedCells) {
    const [row, col] = cellPath.slice(-2)
    rows[row] = rows[row] ? rows[row].concat(cell) : [cell]
  }

  rows = Object.values(rows).map((cells) => {
    return jsx('element', { type: 'tr' }, cells)
  })

  const tbody = jsx('element', { type: 'tbody' }, rows)
  const table = jsx('element', { type: 'table', attrs: { colWidths: [] } }, tbody)

  const html = `<p></p>${toRedactor(table)}<p></p>`

  event.clipboardData.setData('text/html', html)
  event.preventDefault()
  event.stopPropagation()
}

const beforeElementRender = (props) => {
  let { isSelected } = props
  // Check if current element if first child
  if (isSelected) {
    props.DisableDND = true
  }
}

export default Table

Registry.register(
  {
    Component: Table,
    isContentStackElement: true,
    toolbar: {
      inHoveringToolbar: false
    },
    beforeElementRender
  },
  { id: 'table', registry: 'element' }
)

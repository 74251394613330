import React, { useMemo, useCallback, useState, useRef, useEffect, Fragment } from 'react'
import { createEditor, Transforms, Editor, Node } from 'slate'
import { Slate, Editable, withReact, ReactEditor } from 'slate-react'
import { withHistory } from 'slate-history'
import { cx, css } from '@emotion/css'
import { cloneDeep, isEqual } from 'lodash'
import { v4 } from 'uuid'
import siteViewStyles from '../../styles.module.css'
import { savedSelection } from '../../utils/savedSelection'
//@ts-ignore
import styles from './style.module.css'
import { Element, Leaf } from '../../elements'
import { MainToolbar, HoveringToolbar } from '../Toolbar'
import { applyPlugins } from '../../utils'
import { HtmlEditComponent } from '../../elements/utils/editHtml'
import { EditorProvider, ScrollRefProvider, ReduxProvider } from '../ContextProvider'
import { handleShortcut } from '../../utils/shortcuts/handleShortcut'
import { Icon } from '../../../../..'
import { useEditorResize, handleRteOverlayClick } from '../../../../../utils/hooks'
import dndStyles from '../Dnd/style.module.css'
import { htmlToJson, jsonToHtml } from '../../utils/serialize/jsonHtmlTransformations'
import isHotkey from 'is-hotkey'
import ErrorBoundary from './ErrorBoundary'
import { useDidUpdate } from '../../../../../utils/hooks/componentLifecycle'

export declare interface EditorElementProps {
  customToolbarComponents?: any
  value
  hideToolbarOnBlur?: boolean
  onChange?
  onError?
  toolbar: boolean
  hoveringToolbar: boolean
  contentStack: boolean
  toolbarMode: any
  templateRegistryCondition: any
  requestProps: any
  onFocus: any
  onBlur: any
  onMouseEnter: any
  onMouseLeave: any
  className: any
  required: boolean
  allowExtraTags: object
  customPasteOptions: object
  contentTypeNameMap: object
  disabled: boolean
  fieldName: string
  csOnlyBreakline: boolean
  copyPasteConfig: object
  updateValueFromAppSdk: number
}

export const initialHtmlValue = 'scrte-_-initial-value'
let toolbarHeight = 37

const EditorElement = ({
  customToolbarComponents,
  value,
  hideToolbarOnBlur = false,
  onChange,
  toolbar = true,
  hoveringToolbar = true,
  contentStack,
  toolbarMode,
  templateRegistryCondition,
  allowExtraTags,
  customPasteOptions,
  csOnlyBreakline,
  contentTypeNameMap,
  disabled = false,
  fieldName = 'RichTextEditor',
  copyPasteConfig,
  onError,
  ...props
}: EditorElementProps) => {
  const scrollRef = useRef(null)
  const editorRef = useRef(null)
  const editorResizeRef = useRef(null)
  const singleLineRef = useRef(null)
  const [slateValue, setSlateValue] = useState(value)
  const [showEditHtml, setEditHtml] = useState(false)
  const [triggerRerenderingAfterDnd, setTriggerRerenderingAfterDnd] = useState(Date.now())
  let customToolbarComponentsCopy = cloneDeep(customToolbarComponents)

  useDidUpdate(() => {
    errorWrapper(editor, () => {
      Transforms.removeNodes(editor, { at: [0] })
      Transforms.insertNodes(editor, value, { at: [0] })
    }, onError)
  }, [props.updateValueFromAppSdk])

  useEffect(() => {
    errorWrapper(editor, () => {
      editor.selection = null
      setSlateValue(value)
      editor.children = value
    }, onError)
  }, [value])
  
  
  const [valueHtml, setValueHtml] = useState(initialHtmlValue)
  useEffect(() => {
    editor.requestProps = props.requestProps
  }, [props.requestProps])

  let customToolbarElement = cloneDeep(customToolbarComponents['element'] || [])
  let customToolbarLeaf = cloneDeep(customToolbarComponents['leaf'] || [])
  let customToolbarEditorButtons = cloneDeep(customToolbarComponents['editorButtons'] || [])

  if (customToolbarComponents?.element && Array.isArray(customToolbarComponents?.element)) {
    if (customToolbarElement.includes('table')) {
      customToolbarElement.push(
        'table-create-table',
        'table-delete-table',
        'table-insert-row-top',
        'table-insert-row-bottom',
        'table-delete-row',
        'table-insert-col-before',
        'table-insert-col-after',
        'table-create-header',
        'thead',
        'table-merge-cells',
        'tableHeaderBtn'
      )
    }

    if (customToolbarElement.includes('alignment')) {
      customToolbarElement.push('left-align', 'center-align', 'right-align', 'justify-align')
    }

    if (customToolbarElement.includes('lists')) {
      customToolbarElement.push('ol', 'ul')
    }

    if (customToolbarElement.includes('img')) {
      customToolbarElement.push('uploadImage', 'chooseImage')
    }
  }
  customToolbarComponentsCopy = {
    element: customToolbarElement,
    leaf: customToolbarLeaf,
    editorButtons: customToolbarEditorButtons
  }
  useEffect(() => {
    if (showEditHtml) {
      let htmlValue = jsonToHtml(slateValue[0])
      setValueHtml(htmlValue)
    } else if (valueHtml !== initialHtmlValue) {
      let slateJson = htmlToJson(valueHtml, editor.allowExtraTags)
      Transforms.removeNodes(editor, { at: [0] })
      Transforms.insertNodes(editor, slateJson, { at: [0] })
    }
  }, [showEditHtml])
  const editor = useMemo(() => applyPlugins(withHistory(withReact(createEditor() as ReactEditor))), [])

  const handleKeyDown = useCallback((event) => {
    handleShortcut(event, editor, {
      customToolbarComponents,
      contentStack,
      toolbarMode
    })
  }, [])

  const elementRenderer = useCallback(
    (props) => (
      <Element setTriggerRerenderingAfterDnd={setTriggerRerenderingAfterDnd} singleLineRef={singleLineRef} {...props} templateRegistryCondition={templateRegistryCondition} />
    ),
    [triggerRerenderingAfterDnd]
  )
  const leafRenderer = useCallback((props) => <Leaf {...props} />, [])

  let newProps = {
    ...props,
    value: slateValue,
    setSlateValue: setSlateValue,
    showEditHtml: showEditHtml,
    setEditHtml: setEditHtml,
    editorRef: editorRef
  }

  const handleFocus = (e) => {
    props.onFocus(e)
  }

  const handleEmptyClick = (event) => {
    if (event.clientX > event.target.clientWidth || event.clientY > event.target.clientHeight) return
    const endPoint = Editor.end(editor, [])
    const { path } = endPoint
    const node = Node.get(editor, path.slice(0, 2))
    ReactEditor.focus(editor)
    if (Editor.isVoid(editor, node)) {
      Transforms.select(editor, path)
    } else {
      Transforms.select(editor, endPoint)
    }
  }

  const handleFocusForAccessibility = (e) => {
    const toolbarParent = e?.target?.getElementsByClassName('toolbar-parent')
    if (toolbarParent?.length && toolbarParent[0]?.childNodes?.length) {
      Array.from(toolbarParent[0]?.childNodes).forEach((child: any, index: any) => {
        if (index === 0) return
        let i = 0
        while (i < child.childNodes?.length) {
          if (!child.childNodes[i].className.includes('divider')) {
            child.childNodes[i]?.setAttribute('tabIndex', '-1')
          }
          i++
        }
      })
    }
    const editableElement = e?.target?.querySelector('#scrte-editable')
    if (editableElement) editableElement.setAttribute('tabIndex', '-1')
  }

  const handleKeyDownForAccessibility = (e) => {
    if (e.key === 'Enter') {
      e?.target?.querySelector('span[data-icon="Html"]')?.focus()
      const toolbarParent = e?.target?.getElementsByClassName('toolbar-parent')
      if (toolbarParent?.length && toolbarParent[0]?.childNodes?.length) {
        Array.from(toolbarParent[0]?.childNodes).forEach((child: any, index: any) => {
          if (index === 0) return
          let i = 0
          while (i < child.childNodes?.length) {
            if (!child.childNodes[i].className.includes('divider')) {
              child.childNodes[i]?.setAttribute('tabIndex', '0')
            }
            i++
          }
        })
      }
      const editableElement = e?.target?.querySelector('#scrte-editable')
      if (editableElement) editableElement.setAttribute('tabIndex', '0')
    }
    if (isHotkey('shift+meta+enter', e)) {
      e.preventDefault()
      handleEmptyClick(e)
    }
    if (e.key === 'Escape') {
      e.target?.closest('#scrte-editor')?.focus()
    }
  }

  useEffect(() => {
    if (!editor.savedSelection) {
      savedSelection(editor)
    }
  }, [])

  const handleBlur = (e) => {
    props.onBlur(e)
    savedSelection(editor)
  }

  useEffect(() => {
    editor.uniqueId = v4().split('-').join('')
    editor.allowExtraTags = allowExtraTags
    editor.customPasteOptions = customPasteOptions || {}
    editor.contentTypeNameMap = contentTypeNameMap
    editor.csOnlyBreakline = csOnlyBreakline
    editor.copyPasteConfig = copyPasteConfig
  }, [copyPasteConfig])

  useEditorResize(editorResizeRef, showEditHtml)
  const shouldNotRenderHtmlComponent = valueHtml === initialHtmlValue || !showEditHtml
  return (
    <ErrorBoundary onError={onError} editor={editor}>
      <ReduxProvider>
        <EditorProvider.Provider value={newProps}>
          <ScrollRefProvider.Provider value={{ scrollRef }}>
            <Slate
              editor={editor}
              value={slateValue}
              onChange={(newValue) => {
                errorWrapper(editor, () => {
                  setSlateValue(newValue)
                  onChange(newValue)
                }, onError)
              }}>
              <div ref={singleLineRef} contentEditable={false} className={cx(dndStyles['scrte-dragline'])} />
              <div
                tabIndex={0}
                onFocus={handleFocusForAccessibility}
                onKeyDown={handleKeyDownForAccessibility}
                ref={editorRef}
                id="scrte-editor"
                className={cx(
                  styles['super-charged-rte'],
                  'super-charged-rte active',
                  disabled && styles['disabled'],
                  css`
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    background: ${showEditHtml ? '#4C5566' : '#fff'};
                  `
                )}>
                {toolbar && (
                  <MainToolbar
                    customToolbarComponents={customToolbarComponentsCopy}
                    className={cx('scrte-toolbar', styles['scrte-main-toolbar'])}
                    contentStack={contentStack}
                    toolbarMode={toolbarMode}
                    templateregistrycondition={templateRegistryCondition}
                    fieldName={fieldName}
                    editorRef={editorRef}
                  />
                )}
                {hoveringToolbar && !showEditHtml && (
                  <HoveringToolbar
                    customToolbarComponents={customToolbarComponentsCopy}
                    scrollRef={scrollRef}
                    contentStack={contentStack}
                    toolbarMode={toolbarMode}
                  />
                )}
                <div
                  id="scroller"
                  className={cx('scrte-scroller', `scrte-editor-${editor.uniqueId}`, {
                    [styles['scrte-scroller-edit-html']]: showEditHtml
                  })}
                  ref={scrollRef}>
                  {shouldNotRenderHtmlComponent ? (
                    <Fragment>
                      <Editable
                        onDrop={() => {}}
                        onDragExit={() => {}}
                        onDragLeave={() => {}}
                        onDragOver={() => {}}
                        onDrag={() => {}}
                        onDragStart={() => {}}
                        onDragEnter={() => {}}
                        onDragEnd={() => {}}
                        onMouseDown={handleEmptyClick}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        renderElement={elementRenderer}
                        renderLeaf={leafRenderer}
                        onKeyDown={handleKeyDown}
                        autoCorrect="true"
                        spellCheck="true"
                        id="scrte-editable"
                        readOnly={disabled}
                        tabIndex={-1}
                        //@ts-ignore
                        className={cx(
                          siteViewStyles['editable'],
                          props.className,
                          styles['scrte-editable'],
                          'scrte-editable'
                        )}
                        required={props.required}></Editable>
                      <div className="editor-resize" ref={editorResizeRef}>
                        <Icon icon="Resize" className={styles['resize-icon']} />
                      </div>
                    </Fragment>
                  ) : (
                    <HtmlEditComponent
                      valueHtml={valueHtml}
                      setValueHtml={(html) => {
                        setValueHtml(html)
                        onChange(html)
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="rte-fullscreen-overlay" onClick={() => handleRteOverlayClick(editorRef)}></div>
            </Slate>
          </ScrollRefProvider.Provider>
        </EditorProvider.Provider>
      </ReduxProvider>
    </ErrorBoundary>
  )
}

EditorElement.defaultProps = {
  onChange: () => {
    // intentionally blank
  },
  value: [
    {
      type: 'docs',
      children: [
        {
          type: 'h1',
          attrs: {},
          children: [{ text: 'Hello, world!' }]
        }
      ]
    }
  ],
  requestProps: {},
  onFocus: () => {
    // intentionally blank
  },
  onBlur: () => {
    // intentionally blank
  },
  onMouseEnter: () => {
    // intentionally blank
  },
  onMouseLeave: () => {
    // intentionally blank
  },
  placeholder: `Type '/' for options`,
  uid: null,
  required: false,
  allowExtraTags: {},
  customPasteOptions: {},
  contentTypeNameMap: {},
  disabled: false
}

export default EditorElement

const errorWrapper = (editor, func, onError) => {
  try {
    func()
  } catch (e) {
    const { children, selection, history } = editor
    const metadata = { children, history, selection }
    onError ? onError(e, metadata) : console.error(e, metadata)
  }
}
import React, { useRef } from 'react'
import { Editor, Transforms } from 'slate'
import { useSlate } from 'slate-react'

import { formatShortcut } from '../../../../utils/shortcuts/formatShortcut'
import { deleteCol, insertColAfter, insertColBefore } from '../utils/index'
import { TableDeleteColumnIcon, TableAddColumnAboveIcon, TableAddColumnBelowIcon } from '../../../utils/Icon'
import ToolTip from '../../../../../../Tooltip/Tooltip'
//@ts-ignore
import styles from '../style.module.css'
import Tippy from '@tippyjs/react'
import { cx } from '@emotion/css'
import useColDrop from '../hooks/useColDrop'
export default (props) => {
  const { index, children, width, table, tablePath, type, disabled, tableRef, isDraggingInProgress } = props
  const editor = useSlate()
  const tooltipRef = useRef(null)
  const getFirstCellPath = () => {
    const [[, firstRowPath]] = Editor.nodes(editor, {
      at: tablePath,
      match: (node) => {
        //@ts-ignore
        return node.type === 'tr'
      }
    })
    return [...firstRowPath, index]
  }

  const {over, drop} = useColDrop({type, disabled, table, tablePath, editor, colIndex: index})
  const closeTooltip = (dom) => {
    if (dom) {
      if (dom.className === 'scrte-table-column-dnd') {
        return
      }
      if (dom?._tippy) {
        dom._tippy.hide()
        return
      }
      closeTooltip(dom.parentElement)
    } else {
      return
    }
  }
  return (
    <>
    { isDraggingInProgress &&  <div style={{ height: tableRef?.current?.clientHeight - 33 }} ref={drop} className={cx(styles['table-column-drop-container'], { [styles['over']]: over })}></div>}

    <ToolTip position="top" content={
      <div data-testid="col-toolbar" style={{ display: "flex", alignItems: "center", padding: "2px 8px" }} ref={tooltipRef}>
        <span data-testid="delete-col-icon" className={styles['table-column-toolbarIcon']} onMouseDown={(e) => {
          e.preventDefault()
          const firstCellPath = getFirstCellPath()
          Transforms.select(editor, firstCellPath)
          deleteCol(e, { editor })
          closeTooltip(tooltipRef.current)

        }}
        ><Tippy placement="top" className="tippy-box-dark" offset={() => [0, 20]} content={<span data-testid="delete-col-icon-text" className={styles['scrte-table-tooltip-text']} >Delete Column</span>} ><TableDeleteColumnIcon /></Tippy></span>
        <span data-testid="insert-col-before-icon" className={styles['table-column-toolbarIcon']} style={{ margin: '0px 10px' }} onMouseDown={(e) => {
          e.preventDefault()
          closeTooltip(tooltipRef.current)
          const firstCellPath = getFirstCellPath()
          Transforms.select(editor, firstCellPath)
          insertColBefore(e, { editor })
          Transforms.select(editor, firstCellPath)

        }}><Tippy placement="top" className="tippy-box-dark" offset={() => [0, 20]} content={<span data-testid="insert-col-before-icon-text" className={styles['scrte-table-tooltip-text']} >Add Column to the left  <span className={styles['scrte-table-tooltip-shortcut']}>{formatShortcut('ctrl+shift+left')}</span></span>} ><TableAddColumnAboveIcon /></Tippy></span>
        <span data-testid="insert-col-after-icon" className={styles['table-column-toolbarIcon']} onMouseDown={(e) => {
          e.preventDefault()

          const firstCellPath = getFirstCellPath()
          Transforms.select(editor, firstCellPath)
          insertColAfter(e, { editor })
          closeTooltip(tooltipRef.current)
          Transforms.select(editor, [...firstCellPath.slice(0, -1), index + 1])

        }}><Tippy placement="top" className="tippy-box-dark" offset={() => [0, 20]} content={<span data-testid="insert-col-after-icon-text" className={styles['scrte-table-tooltip-text']} >Add Column to the right  <span className={styles['scrte-table-tooltip-shortcut']}>{formatShortcut('ctrl+shift+right')}</span></span>} ><TableAddColumnBelowIcon /></Tippy></span>

      </div>
    } variantType="light"
      trigger="click"
      disabled={disabled}
    >
      <div
        contentEditable={false}
        style={{
          width: `${width}px`,
        }}
        className={cx(styles['table-column-draggable-container'], { [styles['disabled']]: disabled }, { [styles['over']]: over })}
        data-testid="table-column-draggable-container">
        {children}
      </div>
    </ToolTip>
  </>
  )
}

import { jsx } from 'slate-hyperscript'
import { LIST_TYPES } from '../../../Element/list/utils'
import { v4 } from 'uuid'

const ELEMENT_TAGS = {
  A: el => ({ type: 'a', attrs: { url: el.getAttribute('href') || "#" } }),
  H1: () => ({ type: 'h1', attrs: {} }),
  H2: () => ({ type: 'h2', attrs: {} }),
  H3: () => ({ type: 'h3', attrs: {} }),
  H4: () => ({ type: 'h4', attrs: {} }),
  H5: () => ({ type: 'h5', attrs: {} }),
  H6: () => ({ type: 'h6', attrs: {} }),
  IMG: (el) => {
    return { type: 'img', attrs: { url: el.getAttribute('src'), width: 100 } }
  },
  LI: () => ({ type: 'li', attrs: {} }),
  OL: () => ({ type: 'ol', attrs: {} }),
  P: () => ({ type: 'p', attrs: {} }),
  PRE: () => ({ type: 'code', attrs: {} }),
  UL: () => ({ type: 'ul', attrs: {} }),
  IFRAME: (el) => ({ type: 'embed', attrs: { url: el.getAttribute('href') } }),
  TABLE: (el) => ({ type: 'table', attrs: {} }),
  THEAD: (el) => ({ type: 'thead', attrs: {} }),
  TBODY: (el) => ({ type: 'tbody', attrs: {} }),
  TR: (el) => ({ type: 'tr', attrs: {} }),
  TD: (el) => ({ type: 'td', attrs: {} }),
  TH: (el) => ({ type: 'th', attrs: {} })
}

const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  //DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
  MARK: () => ({ mark: true }),
  B: () => ({ bold: true }),
  SUP: () => ({ superscript: true }),
  SUB: () => ({ subscript: true })
}

const generateId = () => v4().split('-').join('')
const whiteCharPattern = /^[\s ]+$/
export const deserialize = (el, options) => {
  if (el.nodeType === 3) {
    if (
      el.parentElement.nodeName === 'BODY'
    ) {
      return null
    }
    return el.textContent
  } else if (el.nodeType !== 1 || el.nodeName === 'META') {
    return null
  } else if (el.nodeName === 'BR') {
    return { text: "\n", break: false, seperatorId: generateId() }
  } else if (el.nodeName === 'O:P') {
    if (el.childNodes?.length) {
      return { text: '' }
    }
    return null
  }
  const { nodeName } = el
  let parent = el

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0]?.nodeName === 'CODE'
  ) {
    parent = el.childNodes[0]
  }

  if (nodeName === 'DIV' && el.getAttribute('style')?.includes('mso-element:comment-list')) {
    return null
  }

  let children = Array.from(parent.childNodes).map(deserialize).flat()
  children = children.filter((child) => child !== null)

  children = children.map((child) => {
    if (typeof child === 'string') {
      return child.replace(/\n/g, ' ')
    } else return child
  })

  if (children.length === 0) {
    if (nodeName === "A" && el.getAttribute('href')) {
      children = [{ text: '\n', break: false }]
    }
    else if (nodeName === "SPAN" && el.getAttribute('class') === 'Apple-converted-space') {
      children = [{ text: ' ' }]
    }
    else {
      children = [{ text: '' }]
    }
  }

  if (el.nodeName === 'BODY') {
    // if (LIST_TYPES.includes(children?.[0]?.type)) {
    //   children.unshift({ type: 'p', children: [{ text: '' }] })
    // }
    return jsx('fragment', {}, [...children])
  }

  const heading_pattern = /^MsoHeading/
  const list_pattern = /^MsoListParagraph/


  if (ELEMENT_TAGS[nodeName]) {
    let attrs = ELEMENT_TAGS[nodeName](el)
    if (el.getAttribute('class') === 'MsoTitle') {
      attrs = {
        type: 'h1',
        ...attrs,
        attrs: {
          ...attrs.attrs,
          style: {
            ...attrs.attrs.style,
            "text-align": 'center'
          }
        }
      }
    }

    if (el.getAttribute('align')) {
      attrs = {
        ...attrs,
        attrs: {
          ...attrs.attrs,
          style: {
            ...attrs.attrs.style,
            "text-align": el.style['text-align']
          }
        }
      }
    }

    if (el.getAttribute('dir')) {
      attrs = {
        ...attrs,
        attrs: {
          ...attrs.attrs,
          style: {
            ...attrs.attrs.style,
            "direction": (el.dir as string).toLowerCase()
          }
        }
      }
    }

    if (el.nodeName === 'P') {
      const nodeClass = el.getAttribute('class')

      switch (nodeClass) {
        case 'MsoTitle':
          attrs.type = 'h1'
          attrs = {
            ...attrs,
            attrs: {
              ...attrs.attrs,
              style: {
                ...attrs.attrs.style,
                "text-align": 'center'
              }
            }
          }
          break
        case 'MsoSubtitle':
          attrs.type = 'h3'
          break
        case 'MsoQuote':
        case 'MsoIntenseQuote':
          attrs.type = 'blockquote'
          break
      }
    }

    if (el.nodeName === 'A' && !el.getAttribute('href')) {
      //ignoring "a" and returning it's children only
      let anchorChild = Array.from(el.childNodes).map((child) => {
        return deserialize(child, options)
      })
      return anchorChild
    }

    // if (nodeName === 'P' && el.childNodes[0]?.nodeName === 'SPAN') {
    //   if (el.childNodes[0]?.childNodes[0]?.nodeName === 'W:SDT') {
    //     const checked = el.childNodes[0]?.childNodes[0]?.getAttribute(
    //       'checkboxischecked'
    //     )
    //     if (checked) {
    //       return jsx(
    //         'element',
    //         {
    //           type: 'check-list',
    //           attrs: {},
    //           checked: checked === 't' ? true : false
    //         },
    //         [{ text: el.childNodes[0]?.childNodes[1]?.textContent || '' }]
    //       )
    //     }
    //   }
    // }

    // if (nodeName === 'P' && heading_pattern.test(el.getAttribute('class'))) {
    //   const paragraphChild = Array.from(el.childNodes).map((child: any, index) => {
    //     if (child.nodeType === 3) {
    //       return {
    //         text: child.textContent,
    //         italic:
    //           el.getAttribute('class')[el.getAttribute('class').length - 1] %
    //           2 ===
    //           0
    //       }
    //     }
    //     return children[index]
    //   })
    //   return jsx('element', { type: 'h6', attrs: {} }, paragraphChild)
    // }

    if (nodeName === "P" && (el.getAttribute?.('style')?.includes('mso-list'))) {
      let listType = 'ul'
      //@ts-ignore
      let levelMatch = String(el.getAttribute?.('style')).match(
        /lfo(\d+)/
      )
      let levelMatchPrevious = String(el.previousSibling?.getAttribute?.('style')).match(
        /lfo(\d+)/
      )

      let matchCurrent = String(el.getAttribute?.('style')).match(
        /level(\d+)/
      )

      let matchNext = String(el.nextSibling?.getAttribute?.('style')).match(
        /level(\d+)/
      )

      const findListParent = (el) => {
        if (el?.childNodes?.length === 1) {
          return findListParent(el.childNodes[0])
        }

        else if (el?.childNodes?.length > 1) {
          const firstListChild = findFirstListElement(el.childNodes[0])
          if (firstListChild && firstListChild.firstChild?.nodeName === "SPAN" && firstListChild.firstChild.getAttribute?.('style').includes('mso-list')) {
            return el.childNodes
          }
        }
      }

      const findFirstListElement = (listChild) => {
        if (listChild.nodeName !== "SPAN") {
          if (listChild.nodeType !== 3 && listChild.nodeName !== "O:P")
            return findFirstListElement(listChild.childNodes[0])
        }
        else if (listChild.firstChild?.nodeName === "SPAN" && listChild.firstChild.getAttribute?.('style')?.includes('mso-list')) {
          return listChild
        }
      }

      const createParagraphChild = (el: Node) => {
        let listChildren = []
        let children = []
        let listElWrapper = findListParent(el)
        //@ts-ignore
        if (listElWrapper) {
          Array.from(listElWrapper).forEach((child: any, index: number) => {
            let firstListElement = findFirstListElement(child)
            if (firstListElement) {
              if (firstListElement.childNodes[0]?.childNodes[0]?.nodeName === '#text') {
                if (
                  !Number.isNaN(
                    parseInt(firstListElement?.childNodes[0]?.childNodes[0]?.textContent[0])
                  )
                )
                  listType = 'ol'
              }
            }
            if (index > 0) {
              let listChild = deserialize(child, options)
              if (listChild) {
                if (typeof listChild === 'string') listChildren.push({ text: listChild })
                else listChildren.push(...deserialize(child, options))
              }
            }
          }
          )
        }
        children.push({ type: 'li', attrs: {}, children: listChildren })
        return children.filter((child) => child !== null)
      }
      const createMultilevelChildren = (el: Node) => {
        let children = Array.from(el.childNodes).map((child) => {
          if (
            child.nodeType === 3 ||
            (child.nodeName === 'SPAN' && child.childNodes[0].nodeType === 3)
          ) {
            return {
              text: child.textContent
            }
          }
          if (child.nodeName === 'SPAN' && child.childNodes[0].nodeName === 'A') {
            return deserialize(child, options)[0]
          }
          if (Object.keys(TEXT_TAGS).includes(child.nodeName)) {
            if (child.childNodes[0].nodeName === 'SPAN' && child.childNodes[0].childNodes[0].nodeType !== 3) {
              return null
            }
            let text = deserialize(child, options)
            return text[0]
          }
          else {
            return null
          }
        })
        return children.filter((child) => child !== null)
      }

      const createMultilevelList = (el, lvl, listType) => {
        let currentListItem = el
        let elLevel
        let isLastReached = false
        let children = []
        while (!isLastReached) {
          if (currentListItem?.nodeType === 3) {
            currentListItem = currentListItem.nextSibling
            continue
          }
          let levelMatch = String(currentListItem?.getAttribute('style')).match(
            /level(\d+)/
          )
          if (levelMatch) {
            elLevel = Number(levelMatch[1])
            if (elLevel === lvl) {
              children.push({
                type: 'li',
                attrs: {},
                children: createMultilevelChildren(currentListItem)
              })
              if (currentListItem?.getAttribute?.('style')?.includes('level2')) {
                isLastReached = true
                break
              }
              currentListItem = currentListItem.nextSibling
              if (!currentListItem) {
                return
              }
            } else if (elLevel > lvl) {
              let res = createMultilevelList(currentListItem, lvl + 1, listType)
              children.push(res[0])
              currentListItem = res[1]
              isLastReached = res[2]
            } else if (elLevel < lvl) {
              break
            }
          } else {
            if (currentListItem) {
              children.push({
                type: 'li',
                attrs: {},
                children: createMultilevelChildren(currentListItem)
              })

              if (currentListItem.getAttribute?.('style')?.includes('mso-list')) {
                isLastReached = true
                break
              } else {
                currentListItem = currentListItem?.nextSibling
              }
            } else {
              isLastReached = true;
              break
            }
          }
        }
        return [
          jsx('element', { type: listType, attrs: {} }, children),
          currentListItem,
          isLastReached
        ]
      }
      const findIfNestedList = (elem) => {
        var sibling: any = elem.nextSibling
        let siblingLfo = String(el.getAttribute?.('style')).match(
          /lfo(\d+)/
        )
        let siblingLevel = String(el.getAttribute?.('style')).match(
          /lfo(\d+)/
        )
        while (sibling?.getAttribute?.('style')?.includes('mso-list')) {
          if (Number(siblingLfo)?.[1] === Number(levelMatch?.[1]) && Number(siblingLevel?.[1]) > 1) {
            return true
          }
          sibling = sibling?.nextSibling
        }
      }
      const getNextSibling = (elem) => {
        let allLevel1Elements = []
        var sibling = elem?.nextSibling
        let levelMatchSibling = String(sibling?.getAttribute?.('style')).match(
          /lfo(\d+)/
        )
        while (sibling?.getAttribute?.('style')?.includes('mso-list') || sibling?.getAttribute?.('class')?.includes('MsoListParagraphCxSpMiddle')) {
          levelMatchSibling = String(sibling?.getAttribute?.('style')).match(
            /lfo(\d+)/
          )
          if (sibling?.getAttribute?.('style')?.includes('level1') && Number(levelMatch?.[1]) === Number(levelMatchSibling?.[1])) {
            allLevel1Elements?.push(sibling)
          }
          sibling = sibling?.nextSibling
        }
        return allLevel1Elements
      };
      if (nodeName === "P" && (el?.getAttribute('class')?.includes('MsoListParagraphCxSpMiddle') || el?.getAttribute('class')?.includes('MsoListParagraphCxSpLast'))) {
        return null
      }
      if (nodeName === "P" && Number(matchCurrent?.[1]) === 1 && Number(levelMatch?.[1]) === Number(levelMatchPrevious?.[1])) {
        return null
      }
      if (nodeName === "P" && Number(matchCurrent?.[1]) === 1 && Number(matchCurrent?.[1]) < Number(matchNext?.[1]) || findIfNestedList(el)) {
        let listType = 'ul'
        Array.from(el.childNodes).forEach((child: any) => {
          if (
            child.nodeName === 'SPAN' &&
            child.childNodes[0]?.nodeName === 'SPAN' &&
            child.childNodes[0]?.getAttribute('style') &&
            /^mso-list/.test(child.childNodes[0]?.getAttribute?.('style'))
          ) {
            if (child.childNodes[0]?.childNodes[0]?.nodeName === '#text') {
              if (
                !Number.isNaN(
                  parseInt(child.childNodes[0]?.childNodes[0]?.textContent[0])
                )
              )
                listType = 'ol'
            }
          }
        })
        children = [...createMultilevelList(el, 1, listType)[0]['children']]
        if (getNextSibling(el)?.length) {
          Array.from(getNextSibling(el)).forEach((element) => {
            Array.from(createMultilevelList(element, 1, listType)[0]['children']).forEach((child) => {
              children.push(child)
            })
          })
        }
        return jsx('element', { type: listType, attrs: {} }, children)
      }

      if (nodeName === "P" && Number(matchCurrent?.[1]) > 1) {
        return null
      }

      if (nodeName === "P" && el.getAttribute?.('style')?.includes('level1') && el.previousSibling?.getAttribute?.('style')?.includes('level1') && Number(levelMatch?.[1]) === Number(levelMatchPrevious?.[1])) {
        return null
      }
      //first list
      if (nodeName === "P" && el.getAttribute('style')?.includes('level1') && !el.previousSibling?.getAttribute?.('style')?.includes('mso-list')) {
        let multipleListChildren = createParagraphChild(el)
        let getSibling = getNextSibling(el)
        if (getSibling) {
          Array.from(getSibling).forEach((element) => {
            multipleListChildren?.push(...createParagraphChild(element))
          })
        }
        return jsx(
          'element',
          {
            type: listType,
            attrs: {}
          },
          multipleListChildren
        )
      }
      //other list
      else if (nodeName === "P" && el.getAttribute?.('style')?.includes('level1') && el.previousSibling?.getAttribute?.('style')?.includes('mso-list') && Number(levelMatch?.[1]) !== Number(levelMatchPrevious?.[1])) {
        let multipleListChildren = createParagraphChild(el)
        if (getNextSibling(el)) {
          Array.from(getNextSibling(el)).forEach((element) => {
            multipleListChildren?.push(...createParagraphChild(element))
          })
        }
        return jsx(
          'element',
          {
            type: listType,
            attrs: {}
          },
          multipleListChildren
        )
      }
    }
    // if (nodeName === 'P' && list_pattern.test(el.getAttribute('class'))) {
    //   let listType = 'ul'
    //   const nodeClass = el.getAttribute('class')

    //   const createParagraphChild = (el: Node) => {
    //     let paragraphChild = Array.from(el.childNodes).map((child: any) => {
    //       if (
    //         child.nodeName === 'SPAN' &&
    //         child.childNodes[0]?.nodeName === 'SPAN' &&
    //         child.childNodes[0]?.getAttribute('style') &&
    //         /^mso-list/.test(child.childNodes[0]?.getAttribute('style'))
    //       ) {

    //         if (child?.childNodes[0]?.childNodes[0]?.nodeName === '#text') {
    //           if (
    //             !Number.isNaN(
    //               parseInt(child?.childNodes[0]?.childNodes[0]?.textContent[0])
    //             )
    //           )
    //             listType = 'ol'
    //         }
    //       }

    //       if (child.nodeType === 3) {
    //         return {
    //           text: child.textContent
    //         }
    //       } else {
    //         return null
    //       }
    //     })
    //     return paragraphChild.filter((child) => child !== null)
    //   }

    //   if (nodeClass === 'MsoListParagraph') {
    //     const listChildren = createParagraphChild(el)
    //     return jsx(
    //       'element',
    //       {
    //         type: listType,
    //         attrs: {}
    //       },
    //       { type: 'li', attrs: {}, children: listChildren }
    //     )
    //   }

    //   const createMultilevelChildren = (el: Node) => {
    //     let children = Array.from(el.childNodes).map((child) => {
    //       if (
    //         child.nodeType === 3 ||
    //         (child.nodeName === 'SPAN' && child.childNodes[0].nodeType === 3)
    //       ) {
    //         return {
    //           text: child.textContent
    //         }
    //       } else {
    //         return null
    //       }
    //     })
    //     return children.filter((child) => child !== null)
    //   }

    //   const createMultilevelList = (el, lvl, listType) => {
    //     let currentListItem = el
    //     let elLevel
    //     let isLastReached = false
    //     let children = []

    //     while (!isLastReached) {
    //       if (currentListItem.nodeType === 3) {
    //         currentListItem = currentListItem.nextSibling
    //         continue
    //       }
    //       let levelMatch = String(currentListItem.getAttribute('style')).match(
    //         /level(\d+)/
    //       )
    //       if (levelMatch) {
    //         elLevel = Number(levelMatch[1])

    //         if (elLevel === lvl) {
    //           children.push({
    //             type: 'li',
    //             attrs: {},
    //             children: createMultilevelChildren(currentListItem)
    //           })
    //           if (currentListItem.getAttribute('class')?.endsWith('Last')) {
    //             isLastReached = true
    //             break
    //           }
    //           currentListItem = currentListItem.nextSibling
    //         } else if (elLevel > lvl) {
    //           let res = createMultilevelList(currentListItem, lvl + 1, listType)

    //           children.push(res[0])
    //           currentListItem = res[1]
    //           isLastReached = res[2]
    //         } else if (elLevel < lvl) {
    //           break
    //         }
    //       } else {
    //         children.push({
    //           type: 'li',
    //           attrs: {},
    //           children: createMultilevelChildren(currentListItem)
    //         })

    //         if (currentListItem.getAttribute('class')?.endsWith('Last')) {
    //           isLastReached = true
    //           break
    //         } else {
    //           currentListItem = currentListItem.nextSibling
    //         }
    //       }
    //     }

    //     return [
    //       jsx('element', { type: listType, attrs: {} }, children),
    //       currentListItem,
    //       isLastReached
    //     ]
    //   }

    //   if (nodeClass.endsWith('First')) {
    //     let listType = 'ul'
    //     Array.from(el.childNodes).forEach((child: any) => {
    //       if (
    //         child.nodeName === 'SPAN' &&
    //         child.childNodes[0]?.nodeName === 'SPAN' &&
    //         child.childNodes[0]?.getAttribute('style') &&
    //         /^mso-list/.test(child.childNodes[0]?.getAttribute('style'))
    //       ) {
    //         if (child.childNodes[0]?.childNodes[0]?.nodeName === '#text') {
    //           if (
    //             !Number.isNaN(
    //               parseInt(child.childNodes[0]?.childNodes[0]?.textContent[0])
    //             )
    //           )
    //             listType = 'ol'
    //         }
    //       }
    //     })

    //     return createMultilevelList(el, 1, listType)[0]
    //   }

    //   if (nodeClass.endsWith('Middle') || nodeClass.endsWith('Last')) {
    //     return null
    //   }
    // }

    if (el.nodeName === 'P') {
      const nodeClass = el.getAttribute('class')

      switch (nodeClass) {
        case 'MsoTitle':
          attrs.type = 'h1'
          break
        case 'MsoSubtitle':
          attrs.type = 'h3'
          break
        case 'MsoQuote':
        case 'MsoIntenseQuote':
          attrs.type = 'blockquote'
          break
      }
    }

    if (
      el.nodeName === 'P' &&
      (el.getElementsByTagName('IMG').length ||
        el.getElementsByTagName('V:IMAGEDATA').length)
    ) {
      let image = null

      if (el.getElementsByTagName('IMG').length) {
        image = el.getElementsByTagName('IMG')[0]
      } else if (el.getElementsByTagName('V:IMAGEDATA').length) {
        image = el.getElementsByTagName('V:IMAGEDATA')[0]
      }

      let attrs = ELEMENT_TAGS['IMG'](image)
      if (attrs?.attrs?.['url'].startsWith('file:///') || !attrs?.attrs?.['url']) {
        attrs.attrs['src'] = null
      }

      return jsx('element', attrs, [{ text: '' }])
    }

    // if (attrs['type'] === 'li' && el.childNodes[0]?.nodeName === 'P') {
    //   const text = deserialize(el.childNodes[0]?.childNodes[0])
    //   return jsx('element', attrs, text)
    // }

    if (nodeName === 'IMG') {
      return jsx('element', attrs, [{ text: '' }])
    }

    if (nodeName === 'TABLE') {
      let isWidthTaken = false
      let colWidths
      let tableChildren = Array.from(el.childNodes).map((child: any) => {
        if (child.nodeType === 3) {
          return null
        } else if (child.nodeName === 'TBODY') {
          const deserialized_child = deserialize(child, options)
          if (!isWidthTaken) {
            colWidths = deserialized_child[1]
            isWidthTaken = true
          }
          return deserialized_child[0]
        } else {
          return null
        }
      })

      tableChildren = tableChildren.filter((child) => child !== null)

      let row = 0
      let col = 0
      colWidths = colWidths.map(Number)
      tableChildren.forEach((child) => {
        if (child.type === 'tbody' || child.type === 'thead') {
          row += child.children.length
          if (child.children[0]) {
            col = child.children[0].children.length
          }
        }
      })

      attrs = {
        ...attrs,
        attrs: {
          ...attrs['attrs'],
          rows: row,
          cols: col,
          colWidths
        }
      }

      return jsx('element', attrs, tableChildren)
    }

    if (nodeName === 'TBODY') {
      let isWidthTaken = false
      let colWidths
      let tableBodyChildren = Array.from(el.childNodes).map((child: any) => {
        if (child.nodeType === 3) {
          return null
        } else if (child.nodeName === 'TR') {
          const deserialized_child = deserialize(child, options)
          if (!isWidthTaken) {
            colWidths = deserialized_child[1]
            isWidthTaken = true
          }
          return deserialized_child[0]
        } else {
          return null
        }
      })
      tableBodyChildren = tableBodyChildren.filter((child) => child !== null)
      return [jsx('element', attrs, tableBodyChildren), colWidths]
    }

    if (nodeName === 'TR') {
      let colWidths = []
      let tableRowChildren = Array.from(el.childNodes).map((child: any) => {
        if (child.nodeType === 3) {
          return null
        } else if (child.nodeName === 'TD') {
          const deserialized_child = deserialize(child, options)
          colWidths.push(deserialized_child[1])
          return deserialized_child[0]
        } else {
          return null
        }
      })
      tableRowChildren = tableRowChildren.filter((child) => child !== null)
      return [jsx('element', attrs, tableRowChildren), colWidths]
    }

    if (nodeName === 'TD') {
      let tableDataChildren = Array.from(el.childNodes).map((child: any) => {
        if (child.nodeType === 3) {
          return null
        } else {
          return deserialize(child, options)
        }
      })
      tableDataChildren = tableDataChildren.filter((child) => child !== null)
      return [
        jsx('element', attrs, tableDataChildren),
        el.getAttribute('width')
      ]
    }

    if (el?.childNodes[0]?.nodeName === 'W:SDT') {
      const checked = el.childNodes[0].getAttribute('checkboxischecked')
      if (checked) {
        return jsx(
          'element',
          {
            type: 'check-list',
            attrs: {},
            checked: checked === 't' ? true : false
          },
          [{ text: children[1] }]
        )
      }
    }

    return jsx('element', attrs, children)
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el)
    return children.map((child) => jsx('text', attrs, child))
  }

  if (el?.nodeName === 'SPAN') {
    let attrs = { style: {} }
    let textFormat = {}
    const skipStyles = {
      color: false,
    }
    if (options && options.disableColor) {
      skipStyles.color = true
    }

    children = children.map((child) => {
      if (typeof child === 'string') {
        return child.replace(/\n/g, ' ')
      } else return child
    })
    if (el.style['color'] && el.closest('a') === null && !skipStyles) {
      attrs = {
        ...attrs,
        style: {
          ...attrs.style,
          color: el.style['color']
        }
      }
    }

    if (
      el.parentNode?.nodeName === 'P' &&
      el.parentNode?.getAttribute('class') === 'MsoNormal'
    ) {
      let nodeClass = el.getAttribute('class')

      if (
        nodeClass === 'MsoSubtleEmphasis' ||
        nodeClass === 'MsoIntenseEmphasis'
      ) {
        textFormat = { ...textFormat, italic: true }
      }
    }

    children = children.map((child) => {
      if (typeof child === 'string' || child.nodeType === 3)
        return jsx('text', { attrs, ...textFormat }, child)
      else if (child.text !== undefined) {
        const newChild = {
          ...child,
          attrs: { ...child.attrs, ...attrs },
          ...textFormat
        }

        return newChild
      } else {
        return child
      }
    })
  }

  return children
}
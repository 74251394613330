import React, { useRef, useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import helper from './helper'
import Icon from './TableColumnDndIcon'
import { ColumnDndPreview } from './CustomColumnPreview'
//@ts-ignore
import styles from '../style.module.css'
import { cx } from '@emotion/css'
import { ROW_EXCEED_COUNT } from '../utils'

export default (props) => {
  const { index, type, width, tableRef, disabled, rows, setIsDraggingInProgress } = props
  const CustomPreview = useRef(null)
  let height = 250
  if (tableRef.current) {
    height = tableRef.current.clientHeight - 22
  }
  const [{ isdrag }, drag, preview] = useDrag({
    item: {
      type: type,
      index: index,
    },
    canDrag: () => !disabled,
    collect: (monitor) => {
      return {
        isdrag: monitor.isDragging(),
      }
    },
    begin: (monitor) => {
      helper.addEventListenerForPreview(CustomPreview)

      // NOTE: This is done deliberately to let the dragging start before IsDraggingInProgress is set to true
      setTimeout(() => {
        setIsDraggingInProgress(true)
      }, 0)
    },
    end: (monitor) => {
      helper.removeEventListenerForPreview()
      setIsDraggingInProgress(false)
    },
  })
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])
  return (
    <React.Fragment>
      {isdrag && (
        <ColumnDndPreview
          width={width}
          height={height}
          index={index}
          previewRef={CustomPreview}
        ></ColumnDndPreview>
      )}
      <div contentEditable={false}>
        <div
          contentEditable={false}
          ref={rows && rows >= ROW_EXCEED_COUNT ? null : drag}
          className={cx(
            { [styles['table-column-draggable-active']]: isdrag },
            styles['table-column-draggable']
          )}
        >
          <Icon
            className={styles['table-column-draggable-icon']}
            style={{ opacity: '0.5' }}
          ></Icon>
        </div>
      </div>
    </React.Fragment>
  )
}

import React, { CSSProperties, MouseEventHandler, KeyboardEventHandler } from 'react'
import cn from 'classnames'

import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc'
import { useComponentVisible } from '../DropDown/Dropdown';
import { dropdownKeyboardNavigation } from '../../utils/helpers'

import Icon, { IconProps } from '../Icon2'
import { iconName } from '../Icon2/constants'
import AsyncLoader from '../AsyncLoader/AsyncLoader'

import './Button.css'

export type ButtonProps = {
  /**
   * Class names to be appended to the className prop of the Button Component
   */
  className?: string

  children?: React.ReactNode
  /**
   * Pass the icon that want to add in button
   */
  icon?: any
  /**
   * If you want to show only icon, set the param to ‘true’
   */
  onlyIcon?: boolean
  /**
   * Determines the alignment of the icon
   */
  iconAlignment?: 'left' | 'right' | 'both'
  /**
   * Pass additional props for icon
   */
  iconProps?: Partial<IconProps>
  /**
   * Pass an id to the button
   */
  id?: string
  /**
   * Determine the type of button you want to use
   */
  buttonType?:
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'tertiary-outline'
  | 'outline'
  | 'success'
  | 'danger'
  | 'warning'
  | 'light'
  | 'control'
  | 'delete'
  | 'bulk-delete'
  | 'outline-delete'
  | 'outline-success'
  | 'white'
  | 'link'
  | 'destructive'
  /**
   * Determine the size of the button: small or large
   */
  size?: 'small' | 'regular'
  /**
   * If button covers full width
   */
  isFullWidth?: boolean
  /**
   * If button is rounded
   */
  isRounded?: boolean
  /**
   * An ID used for testing purposes applied as a data attribute (data-test-id)
   */
  testId?: string
  /**
   * Used to set the button in the loading state
   */
  isLoading?: boolean
  /**
   * Determines the color of the loading animation you want to display
   */
  loadingColor?: string
  /**
   * Action that you want to perform when a button is clicked
   */
  onClick?: MouseEventHandler
  /**
   * Determines whether button is disabled or not
   */
  disabled?: boolean
  /**
   * If you want a button as a link, provide the details in href.
   */
  href?: string
  /**
   * Type of button action (button, submit,reset)
   */
  type?: 'button' | 'submit' | 'reset'
  /**
   * Pass any CSS properties for the button
   */
  style?: CSSProperties
  /**
   * Action that you want to perform when a key is pressed down
   */
  onKeyDown?: KeyboardEventHandler
  /**
   * Action that you want to perform when a key is pressed up
   */
  onKeyUp?: KeyboardEventHandler
  version?: 'v1' | 'v2'
  isContextualDropdown?: boolean
  contextualDropdownList?: []
  isOpen?: boolean
  canCloseOnClickOutside?: boolean
  defaultSelected?: object
  onlyIconHoverColor?: 'primary' | 'secondary',
  onlyIconActive: boolean,
  disableContextualDropdown: boolean
}

export const DropDownList = ({ contextualDropdownList, menuRef, setIsComponentVisible, isComponentVisible, handleChange, defaultSelected }) => {

  const handleListElementClick = (evt, index, item) => {

    if (!item.disable) {
      if (handleChange) {
        handleChange(evt, index, item)
      }
      if (item.action) {
        item.action(evt, index, item)
      }
      setIsComponentVisible(!isComponentVisible)
    }
  }

  return (
    <div className='button-contextual-dropdown-wrapper' ref={menuRef}>
      <ul className='Dropdown__menu__list'>
        {contextualDropdownList.map((item: any, index: any) => {
          return (
            <li
              tabIndex={item.disable ? -1 : 0}
              data-test-id={`${item.testId || 'cs-dropdown-elements'}`}
              key={index}
              title={item.label}
              onKeyDown={(e: any) =>
                dropdownKeyboardNavigation('contextual-button', e, {
                  handleListElementClick,
                  isComponentVisible,
                  setIsComponentVisible,
                  index,
                  item,
                  contextualDropdownList
                })
              }
              className={`Dropdown__menu__list__item flex-v-center ${item.id === defaultSelected.id ? 'Dropdown__menu__list__item--active' : ''} ${item.disable ? 'Dropdown__menu__list__item--disabled' : ''}`}
              onClick={(evt) => handleListElementClick(evt, index, item)}
            >
              {item.icon ? item.icon : null}
              <span className='Dropdown__menu__list__item--value'>
                {item.label}
              </span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export const ContextualDropdown = ({ isComponentVisible, validSize, buttonType, setIsComponentVisible, disableContextualDropdown }) => {

  const classNames = cn('Button contextual-dropdown-wrapper', [`Button--${buttonType}`], {
    [`Button--size-${validSize}`]: validSize,
    ['Button--disabled']: disableContextualDropdown
  })

  const handleOnClick = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  return (
    <button type='button' disabled={disableContextualDropdown} onClick={handleOnClick} className={classNames}>
      <Icon
        className={`chevron-toggle-icon ${isComponentVisible ? 'rotate-180' : ''}`}
        version='v2'
        size={validSize === 'small' ? 'small' : 'medium'}
        icon="CaretDown"
      />
    </button>
  )
}

export const RenderButtonDom = ({
  testId,
  Element,
  classNames,
  disabled,
  href,
  id,
  type,
  style,
  onClick,
  isContextualDropdown,
  onKeyDown,
  onKeyUp,
  isLoading,
  loadingColor,
  buttonType,
  addButtonIconClassNames,
  nestedChildren,
  version,
  setIsComponentVisible,
  isComponentVisible,
  validSize,
  defaultSelected,
  contextualDropdownList,
  menuRef,
  handleChange,
  disableContextualDropdown,
  otherProps,
}) => {

  const handleClick = (e: any) => {
    if (e && onClick) {
      onClick(e)
    }
  }

  return (
    <>
      <Element
        data-test-id={testId}
        className={classNames}
        disabled={disabled}
        href={!disabled ? href : null}
        id={id}
        aria-label={id || 'aria-button'}
        type={type}
        style={style}
        onClick={handleClick}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        {...otherProps}>
        <div className="flex-center">
          {isLoading && (
            <div className="Button__loader">
              <AsyncLoader color={loadingColor} />
            </div>
          )}
          <div className={`flex-v-center ${version === 'v2' && validSize !== 'small' ? 'Button__mt-regular' : ''} ${!isLoading ? 'Button__visible' : 'Button__hidden'}`}>
            {buttonType === 'control' && (
              <span className={addButtonIconClassNames}>
                <Icon icon={iconName.PlusSign} />
              </span>
            )}
            {nestedChildren}
          </div>
        </div>
      </Element>
      {isContextualDropdown && version === 'v2' ?
        <ContextualDropdown disableContextualDropdown={disableContextualDropdown} setIsComponentVisible={setIsComponentVisible} isComponentVisible={isComponentVisible} validSize={validSize} buttonType={buttonType} /> :
        null
      }
      {isContextualDropdown && isComponentVisible && version === 'v2' ?
        <DropDownList
          defaultSelected={defaultSelected}
          contextualDropdownList={contextualDropdownList}
          menuRef={menuRef}
          setIsComponentVisible={setIsComponentVisible}
          isComponentVisible={isComponentVisible}
          handleChange={handleChange}
        /> : null
      }
    </>
  )
}

const Button = (props: ButtonProps) => {
  const {
    className,
    children,
    buttonType,
    size,
    isFullWidth,
    isRounded,
    onClick,
    disabled,
    href,
    type,
    icon,
    iconAlignment,
    style,
    isLoading,
    loadingColor,
    onlyIcon,
    testId,
    id,
    iconProps = {},
    version,
    onKeyDown,
    onKeyUp,
    isContextualDropdown,
    contextualDropdownList,
    isOpen,
    canCloseOnClickOutside,
    handleChange,
    defaultSelected,
    onlyIconHoverColor,
    onlyIconActive,
    disableContextualDropdown,
    ...otherProps
  }: any = props;
  const { ref, menuRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(
    false,
    isOpen,
    canCloseOnClickOutside
  );

  let validSize = size;
  if (size === 'regular') {
    validSize = version === 'v1' ? 'small' : 'large'
  }
  const classNames = cn('Button', className, {
    [`Button--${buttonType}`]: !onlyIconHoverColor,
    [`Button--state-disabled`]: disabled,
    [`Button--size-${validSize}`]: validSize,
    ['Button--style-fullwidth']: isFullWidth,
    ['Button--style-circle']: isRounded,
    [`Button--icon-alignment-${iconAlignment}`]: icon && iconAlignment,
    [`Button--state-loading`]: isLoading,
    [`Button--icon`]: onlyIcon,
    [`Button--v2`]: version === 'v2',
    [`Button-contextual-dropdown`]: isContextualDropdown && version === 'v2',
    [`Button--icon-hover-${onlyIconHoverColor}`]: onlyIconHoverColor && !onlyIconActive && onlyIcon && version === 'v2',
    [`Button--icon-active-${onlyIconHoverColor}`]: onlyIconHoverColor && onlyIconActive && onlyIcon && version === 'v2',
  })
  const addButtonIconClassNames = cn('Button__icon')
  const Element = href ? 'a' : 'button'
  let nestedChildren = children && <>{children}</>
  if (icon) {
    let iconChild = <Icon icon={icon} {...iconProps} />
    if (icon.includes('v2-') && version !== 'v1') {
      const newVersionIconName = icon.split('v2-')[1];
      iconChild = <Icon icon={newVersionIconName} size={validSize === 'small' ? 'small' : 'medium'} version="v2" {...iconProps} />
    }
    switch (iconAlignment) {
      case 'left':
        nestedChildren = nestedChildren = onlyIcon ? (
          iconChild
        ) : (
          <>
            {iconChild}
            {nestedChildren}
          </>
        )
        break
      case 'right':
        nestedChildren = nestedChildren = onlyIcon ? (
          iconChild
        ) : (
          <>
            {nestedChildren}
            {iconChild}
          </>
        )
        break
      case 'both':
        nestedChildren = onlyIcon ? (
          iconChild
        ) : (
          <>
            {iconChild}
            {nestedChildren}
            {iconChild}
          </>
        )
        break
      default:
        break
    }
  }

  if (isContextualDropdown && version === 'v2') {
    return (
      <div ref={ref}>
        <RenderButtonDom
          testId={testId}
          Element={Element}
          classNames={classNames}
          disabled={disabled}
          href={href}
          id={id}
          type={type}
          style={style}
          onClick={onClick}
          isContextualDropdown={isContextualDropdown}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          isLoading={isLoading}
          loadingColor={loadingColor}
          buttonType={buttonType}
          addButtonIconClassNames={addButtonIconClassNames}
          nestedChildren={nestedChildren}
          version={version}
          setIsComponentVisible={setIsComponentVisible}
          isComponentVisible={isComponentVisible}
          validSize={validSize}
          defaultSelected={defaultSelected}
          contextualDropdownList={contextualDropdownList}
          menuRef={menuRef}
          handleChange={handleChange}
          disableContextualDropdown={disableContextualDropdown}
          otherProps={otherProps}
        />
      </div>
    )
  }
  return <RenderButtonDom
    testId={testId}
    Element={Element}
    classNames={classNames}
    disabled={disabled}
    href={href}
    id={id}
    type={type}
    style={style}
    onClick={onClick}
    isContextualDropdown={isContextualDropdown}
    onKeyDown={onKeyDown}
    onKeyUp={onKeyUp}
    isLoading={isLoading}
    loadingColor={loadingColor}
    buttonType={buttonType}
    addButtonIconClassNames={addButtonIconClassNames}
    nestedChildren={nestedChildren}
    version={version}
    setIsComponentVisible={setIsComponentVisible}
    isComponentVisible={isComponentVisible}
    validSize={validSize}
    defaultSelected={defaultSelected}
    contextualDropdownList={contextualDropdownList}
    menuRef={menuRef}
    handleChange={handleChange}
    disableContextualDropdown={disableContextualDropdown}
    otherProps={otherProps}
  />
}

Button.defaultProps = {
  isFullWidth: false,
  isRounded: false,
  disabled: false,
  buttonType: 'primary',
  type: 'button',
  isLoading: false,
  testId: 'cs-button',
  size: 'regular',
  iconAlignment: 'left',
  version: 'v1',
  isContextualDropdown: false,
  isOpen: false,
  canCloseOnClickOutside: false
} as Partial<ButtonProps>

export default withDeprecatedProp(Button, { loading: 'isLoading' })





// style={{ visibility: !isLoading ? 'visible' : 'hidden' }}

// import React, { Component, CSSProperties, MouseEventHandler } from 'react'
// import cn from 'classnames'

// import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc'

// import Icon, { IconProps } from '../Icon2'
// import { iconName } from '../Icon2/constants'
// import AsyncLoader from '../AsyncLoader/AsyncLoader'

// import './Button.css'

// const defaultProps = {
//   isFullWidth: false,
//   isRounded: false,
//   disabled: false,
//   buttonType: 'primary',
//   type: 'button',
//   isLoading: false,
//   testId: 'cs-button',
//   size: 'regular',
//   iconAlignment: 'left',
//   version: 'v1'
// }

// type DefaultProps = Readonly<typeof defaultProps>

// export type ButtonProps = {
//   /**
//    * Class names to be appended to the className prop of the Button Component
//    */
//   className?: string

//   children?: React.ReactNode
//   /**
//    * Pass the icon that want to add in button
//    */
//   icon?: any
//   /**
//    * If you want to show only icon, set the param to ‘true’
//    */
//   onlyIcon?: boolean
//   /**
//    * Determines the alignment of the icon
//    */
//   iconAlignment?: 'left' | 'right' | 'both'
//   /**
//    * Pass additional props for icon
//    */
//   iconProps?: Partial<IconProps>
//   /**
//    * Pass an id to the button
//    */
//   id?: string
//   /**
//    * Determine the type of button you want to use
//    */
//   buttonType?:
//   | 'primary'
//   | 'secondary'
//   | 'tertiary'
//   | 'tertiary-outline'
//   | 'outline'
//   | 'success'
//   | 'danger'
//   | 'warning'
//   | 'light'
//   | 'control'
//   | 'delete'
//   | 'bulk-delete'
//   | 'outline-delete'
//   | 'outline-success'
//   | 'white'
//   | 'link'
//   | 'destructive'
//   /**
//    * Determine the size of the button: small or large
//    */
//   size?: 'small' | 'regular'
//   /**
//    * If button covers full width
//    */
//   isFullWidth?: boolean
//   /**
//    * If button is rounded
//    */
//   isRounded?: boolean
//   /**
//    * An ID used for testing purposes applied as a data attribute (data-test-id)
//    */
//   testId?: string
//   /**
//    * Used to set the button in the loading state
//    */
//   isLoading?: boolean
//   /**
//    * Determines the color of the loading animation you want to display
//    */
//   loadingColor?: string
//   /**
//    * Action that you want to perform when a button is clicked
//    */
//   onClick?: MouseEventHandler
//   /**
//    * Determines whether button is disabled or not
//    */
//   disabled?: boolean
//   /**
//    * If you want a button as a link, provide the details in href.
//    */
//   href?: string
//   /**
//    * Type of button action (button, submit,reset)
//    */
//   type?: 'button' | 'submit' | 'reset'
//   /**
//    * Pass any CSS properties for the button
//    */
//   style?: CSSProperties
//   version?: 'v1' | 'v2'
// } & Partial<DefaultProps>
// export class Button extends Component<ButtonProps> {
//   public static propTypes: any = {}
//   static defaultProps = defaultProps
//   render() {
//     const {
//       className,
//       children,
//       buttonType,
//       size,
//       isFullWidth,
//       isRounded,
//       onClick,
//       disabled,
//       href,
//       type,
//       icon,
//       iconAlignment,
//       style,
//       isLoading,
//       loadingColor,
//       onlyIcon,
//       testId,
//       id,
//       iconProps = {},
//       version,
//       ...otherProps
//     }: any = this.props

//     let validSize = size;
//     if (size === 'regular') {
//       validSize = version === 'v1' ? 'small' : 'large'
//     }
//     const classNames = cn('Button', className, [`Button--${buttonType}`], {
//       [`Button--state-disabled`]: disabled,
//       [`Button--size-${validSize}`]: validSize,
//       ['Button--style-fullwidth']: isFullWidth,
//       ['Button--style-circle']: isRounded,
//       [`Button--icon-alignment-${iconAlignment}`]: icon && iconAlignment,
//       [`Button--state-loading`]: isLoading,
//       [`Button--icon`]: onlyIcon
//     })
//     const addButtonIconClassNames = cn('Button__icon')
//     const Element = href ? 'a' : 'button'
//     let nestedChildren = children && <>{children}</>
//     if (icon) {
//       const iconChild = icon && <Icon icon={icon} {...iconProps} />
//       switch (iconAlignment) {
//         case 'left':
//           nestedChildren = (
//             <>
//               {iconChild}
//               {nestedChildren}
//             </>
//           )
//           break
//         case 'right':
//           nestedChildren = (
//             <>
//               {nestedChildren}
//               {iconChild}
//             </>
//           )
//           break
//         case 'both':
//           nestedChildren = onlyIcon ? (
//             iconChild
//           ) : (
//             <>
//               {iconChild}
//               {nestedChildren}
//               {iconChild}
//             </>
//           )
//           break
//         default:
//           break
//       }
//     }
//     return (
//       <Element
//         data-test-id={testId}
//         className={classNames}
//         disabled={disabled}
//         href={!disabled ? href : null}
//         id={id}
//         aria-label={id || 'aria-button'}
//         type={type}
//         style={style}
//         onClick={(e: any) => {
//           if (onClick) {
//             onClick(e)
//           }
//         }}
//         {...otherProps}>
//         <div className="flex-center">
//           {isLoading && (
//             <div className="Button__loader">
//               <AsyncLoader color={loadingColor} />
//             </div>
//           )}
//           <div style={{ visibility: !isLoading ? 'visible' : 'hidden' }} className="flex-v-center">
//             {buttonType === 'control' && (
//               <span className={addButtonIconClassNames}>
//                 <Icon icon={iconName.PlusSign} />
//               </span>
//             )}
//             {nestedChildren}
//           </div>
//         </div>
//       </Element>
//     )
//   }
// }

// export default withDeprecatedProp(Button, { loading: 'isLoading' })

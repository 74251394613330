import React, { useState, useEffect } from 'react';
import { Editor } from './SuperChargedRte/index'
import cloneDeep from 'lodash/cloneDeep';
export const isLeaf = (node) => typeof node === 'object' && node['text'];

export const handleLocalizeEntry = (requestProps, slateJson) => {
  const { uidToEntry } = requestProps || {};
  const inlineVoid = ['inline'];
  if (!uidToEntry) return;
  const value = cloneDeep(slateJson);
  function traverseJson(json) {
    if (Array.isArray(json) && json.length) {
      //fix children
      const newJson = [];
      json.forEach((block, idx) => {
        if (block['type'] === 'reference' && inlineVoid.includes(block.attrs?.['display-type'])) {
          let lastEl = newJson.length && newJson[newJson.length - 1];
          if (idx < 1 || (lastEl && !isLeaf(lastEl))) {
            newJson.push({ text: '' });
          }
          newJson.push(block);
          if (idx === json.length - 1) {
            newJson.push({ text: '' });
          }
        } else {
          newJson.push(block);
        }
      })
      // iterate child blocks;
      json.length = 0;
      json.push.apply(json, newJson);
      json.forEach(traverseJson);
    }
    else if (typeof json === 'object') {
      if (json.text) return;
      if (json.type === 'reference' && json.attrs) {
        const entryUid = json.attrs['entry-uid'];
        if (uidToEntry[entryUid] && uidToEntry[entryUid]['locale']) {
          json.attrs['locale'] = uidToEntry[entryUid]['locale'];
        }
      }
      if (json.children)
        traverseJson(json.children);
    }
  }
  traverseJson(value);
  return value;
}

const RichTextEditor = (props: any) => {
  const {
    contentStack = false,
    toolbarMode = 'basic',
    onChange = null,
    hideToolbarOnBlur = false,
    customToolbarComponents = {},
    csOnlyBreakline,
    csBreakLineOnEnter,
    requestProps,
    customPasteOptions = {},
    ...otherProps
  } = props

  let { value } = otherProps;
  value = handleLocalizeEntry(requestProps, value);

  return (
    <Editor
      requestProps={requestProps}
      contentStack={true}
      toolbarMode={toolbarMode}
      value={value}
      hideToolbarOnBlur={hideToolbarOnBlur}
      customToolbarComponents={customToolbarComponents}
      customPasteOptions={customPasteOptions}
      csOnlyBreakline={csOnlyBreakline}
      csBreakLineOnEnter={csBreakLineOnEnter}
      onChange={(receivedJson: any) => {
        if (onChange) {
          onChange(receivedJson)
        }
      }}
      {...otherProps}
    />
  )
}

export default RichTextEditor

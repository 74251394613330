import React, { FC, useEffect, useRef, useState } from 'react'
import PillIcon from './PillIcon'
import Icon from '../Icon2'
import { PillVariant, IPill } from './type'
import debounce from 'lodash/debounce'
import { setAttributeCollectionOfElement, setCaretPos, elementMatchesSelector } from '../../utils/index'
import Tooltip from '../Tooltip/Tooltip'

const calculateTotalWidth = (mirror: HTMLSpanElement) => {
  const widthWithoutSpace = +getComputedStyle(mirror).width.split('px')[0]
  return widthWithoutSpace
}

const autoSize = (refNode: HTMLInputElement, className) => {
  let mirror: HTMLSpanElement = refNode.parentElement.getElementsByClassName(className)[0] as HTMLSpanElement
  if (!mirror) {
    mirror = document.createElement('span')
    mirror.setAttribute('class', className)
    refNode.parentElement.appendChild(mirror)
  }
  if (mirror.style.display === 'none') {
    mirror.style.display = 'inline-block'
  }

  let stringValue = refNode.value || ''
  mirror.textContent = stringValue

  refNode.style.width = `${calculateTotalWidth(mirror)}px`
  mirror.style.display = 'none'
}

const memorizedHandleChange = debounce((value, onChange) => {
  onChange(value)
}, 100)

const makeAllInputsFocusable = (parentRef) => {
  const inputNodes = parentRef.getElementsByTagName('INPUT') as HTMLCollectionOf<HTMLInputElement>
  if (inputNodes[0]?.tabIndex === -1) {
    parentRef.setAttribute('tabindex', '-1')
    setAttributeCollectionOfElement(inputNodes, 'tabindex', '0')
  }
}

const makePillWrapFocusable = (parentRef) => {
  if (!elementMatchesSelector(parentRef, ':focus-within')) {
    parentRef.setAttribute('tabindex', '0')
    const inputNodes = parentRef.getElementsByTagName('INPUT') as HTMLCollectionOf<HTMLInputElement>
    setAttributeCollectionOfElement(inputNodes, 'tabindex', '-1')
  }
}

const mirrorClassName = 'PillItem__input--mirror'

interface PillInputProps {
  className?: string
  defaultValue: string
  onChange: (string) => void
  onFocus?: () => void
  onBlur?: () => void
  onEnterPressed?: () => void
  variant?: PillVariant
  isDisabled?: boolean
  onRemoveItem?: () => void
  isDragging?: boolean
  pillIndex?: number
  customKeyDown?: Function
  isLastTag?: Boolean
  pillsCount?: Number
  canTruncate?: boolean
}

const PillInput: FC<PillInputProps> = (props) => {
  const {
    className,
    defaultValue,
    onChange,
    onFocus,
    onBlur,
    onEnterPressed,
    variant,
    isDisabled,
    onRemoveItem,
    isDragging,
    pillIndex,
    canTruncate
  } = props

  const inputRef = useRef<HTMLInputElement>(null)
  const [isEllipsisActive, setEllipsesActive] = useState(false);

  useEffect(() => {
    const node = inputRef.current
    autoSize(node, mirrorClassName)
    if (canTruncate) setEllipsesActive(checkEllipsisActive(node))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFocus = () => {
    const listener = (e: any) => {
      var code = e.keyCode ? e.keyCode : e.which
      if (code === 9) setCaretPos(inputRef.current, inputRef.current?.value?.length)
      inputRef.current?.removeEventListener('keyup', listener)
    }
    inputRef.current?.addEventListener('keyup', listener)

    makeAllInputsFocusable(inputRef.current?.parentElement.parentElement)
    onFocus && onFocus()
  }

  const handleBlur = (e) => {
    if (canTruncate) {
      setEllipsesActive(checkEllipsisActive(e.target))
      e.target.setAttribute('readonly', true)
    }
    const node = inputRef.current
    onChange(node.value)
    makePillWrapFocusable(inputRef.current?.parentElement.parentElement)
    if (onBlur) onBlur()
  }

  const handleChange = (e) => {
    const node = inputRef.current
    autoSize(node, mirrorClassName)
    if (node.value) memorizedHandleChange(node.value, onChange)
  }

  const handleKeyDown = (event) => {
    if (event.key === "Backspace" && props.pillsCount !== 1) {
      const node: any = inputRef.current
      if (node?.value?.length === 0) {
        onRemoveItem()
        event.preventDefault()
        const pillWrapper: any = node?.parentElement?.parentElement
        const prevPill: any = pillWrapper?.getElementsByTagName('input')[pillIndex !== 0 ? (pillIndex - 1) : 1]
        if (prevPill) {
          setCaretPos(prevPill, prevPill.value.length)
        }
      }
    }
    if (event.key === 'Enter' && inputRef.current.value) {
      event.preventDefault()
      inputRef.current?.parentElement.classList.remove('PillItem--active')
      if (onEnterPressed) onEnterPressed()
    }
    if (event.key === 'Escape') {
      inputRef.current?.blur()
      const parentFocusableEle = inputRef.current?.parentElement.parentElement
        ; (parentFocusableEle as HTMLButtonElement).focus()
    }
    if (event.key === 'Tab' && event.shiftKey && pillIndex === 0) {
      event.preventDefault()
      inputRef.current?.blur()
      const parentFocusableEle = inputRef.current?.parentElement?.parentElement;
      parentFocusableEle && (parentFocusableEle as HTMLButtonElement).focus()
    }
    props.isLastTag && props.customKeyDown && props.customKeyDown(event)
  }
  const handelMouseDown = (e) => {
    e.stopPropagation()
  }
  const handleClick = (e) => {
    e.target.removeAttribute('readonly')
    e.target.focus()
  }
  const checkEllipsisActive = (ele) => {
    return ele?.offsetWidth < ele?.scrollWidth
  }

  return (
    <>
      <Tooltip content={defaultValue} position={"bottom"} disabled={!isEllipsisActive}>
        <input
          className={`${className} ${canTruncate ? 'PillItem__inputElement--truncate' : ''}`}
          defaultValue={defaultValue}
          onChange={handleChange}
          type="text"
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          disabled={isDisabled}
          onMouseDown={handelMouseDown}
          aria-label="editable pill"
          tabIndex={-1}
          onClick={canTruncate ? handleClick : undefined}
        />
      </Tooltip>
      <PillIcon
        icon={
          <Icon
            icon={'CancelTransparent'}
            className={`PillIcon__remove ${isDragging ? 'PillIcon__remove--dragging' : ''}`}
            width={10}
            height={10}
            onClick={isDisabled ? undefined : onRemoveItem}
            disabled={isDisabled}
          />
        }
        variant={variant}
        pillIconPosition="end"
      />
    </>
  )
}

export default PillInput

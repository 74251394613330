import { Editor, Operation, Range, Text, Transforms } from 'slate'

export function getDiscussionsOnTextNode(textNode) {
  return new Set(Object.keys(textNode).filter(isDiscussionIDMark).map(getDiscussionIDFromMark))
}
export function getDiscussionMarksOnTextNode(textNode: Text) {
  return [...new Set(Object.keys(textNode).filter(isDiscussionIDMark))]
}
export function isDiscussionThread(leafType: string) {
  return leafType.startsWith(DISCUSSION_THREAD_PREFIX)
}

export function isDiscussionThreadActive(leafType: string, activeDiscussionMap: Object){
  return activeDiscussionMap.hasOwnProperty(`range-${getDiscussionIDFromMark(leafType)}`)
}
export const DISCUSSION_THREAD_PREFIX = 'discussionThread_'

export function getDiscussionIDFromMark(mark) {
  if (!isDiscussionIDMark(mark)) {
    throw new Error('Expected mark to be of a comment thread')
  }
  return mark.replace(DISCUSSION_THREAD_PREFIX, '')
}

function isDiscussionIDMark(mayBeDiscussion) {
  return mayBeDiscussion.indexOf(DISCUSSION_THREAD_PREFIX) === 0
}

export function getMarkForDiscussionID(threadID) {
  return `${DISCUSSION_THREAD_PREFIX}${threadID}`
}

function updateCommentThreadLengthMap(editor, discussions, nodeIterator, map) {
  let nextNodeEntry = nodeIterator(editor)

  while (nextNodeEntry != null) {
    const nextNode = nextNodeEntry[0]
    const commentThreadsOnNextNode = getDiscussionsOnTextNode(nextNode)

    const intersection = [...commentThreadsOnNextNode].filter((x) => discussions.has(x))

    // All comment threads we're looking for have already ended meaning
    // reached an uncommented text node OR a commented text node which
    // has none of the comment threads we care about.
    if (intersection.length === 0) {
      break
    }

    // update thread lengths for comment threads we did find on this
    // text node.
    for (let i = 0; i < intersection.length; i++) {
      map.set(intersection[i], map.get(intersection[i]) + nextNode.text.length)
    }

    // call the iterator to get the next text node to consider
    nextNodeEntry = nodeIterator(editor, nextNodeEntry[1])
  }

  return map
}

const reverseTextNodeIterator = (slateEditor, nodePath) =>
  Editor.previous(slateEditor, {
    at: nodePath,
    mode: 'lowest',
    match: Text.isText
  })

const forwardTextNodeIterator = (slateEditor, nodePath) =>
  Editor.next(slateEditor, {
    at: nodePath,
    mode: 'lowest',
    match: Text.isText
  })

export function getSmallestDiscussionAtTextNode(editor, textNode) {
  const discussions = getDiscussionsOnTextNode(textNode)
  const discussionsAsArray = Array.from(discussions)
  let shortestDiscussion = discussionsAsArray[0]

  if (discussions.size < 2) return shortestDiscussion

  // The map here tracks the lengths of the comment threads.
  // We initialize the lengths with length of current text node
  // since all the comment threads span over the current text node
  // at the least.
  const discussionLengthById = new Map(discussionsAsArray.map((id) => [id, textNode.text.length]))

  // traverse in the reverse direction and update the map
  updateCommentThreadLengthMap(editor, discussions, reverseTextNodeIterator, discussionLengthById)

  // traverse in the forward direction and update the map
  updateCommentThreadLengthMap(editor, discussions, forwardTextNodeIterator, discussionLengthById)

  let minLength = Number.POSITIVE_INFINITY

  for (let [threadID, length] of discussionLengthById) {
    if (length < minLength) {
      shortestDiscussion = threadID
      minLength = length
    }
  }

  return shortestDiscussion
}

export const shouldAllowNewCommentThreadAtSelection = (editor, selection) => {
  if (selection == null || Range.isCollapsed(selection)) {
    return false
  }

  const textNodeIterator = Editor.nodes(editor, {
    at: selection,
    mode: 'lowest'
  })

  let nextTextNodeEntry = textNodeIterator.next().value
  const textNodeEntriesInSelection = []
  while (nextTextNodeEntry != null) {
    textNodeEntriesInSelection.push(nextTextNodeEntry)
    nextTextNodeEntry = textNodeIterator.next().value
  }

  if (textNodeEntriesInSelection.length === 0) {
    return false
  }

  return textNodeEntriesInSelection.some(([textNode]) => getDiscussionsOnTextNode(textNode).size === 0)
}


export const removeStaleDiscussions = (editor: Editor) => {
  if (editor['staleDiscussionThreads']?.size) {
    try {
      editor['staleDiscussionThreads'].forEach((thread) => {
        Transforms.unsetNodes(editor, thread, {
          mode: 'lowest', match: Text.isText, at: [0]
        })
      })
    }
    catch (e) {
      console.error(e)
    }
    editor['staleDiscussionThreads'] = null
  }
}

export const editorHasOnlyCommentChanges = (operations: Operation[]) => {
  for (const operation of operations) {

    switch (operation.type) {
      case 'insert_node':
      case 'move_node':
      case 'remove_node':
      case 'insert_text':
      case 'remove_text': {
        return false
      }

      case "merge_node":
      case "split_node":
      case "set_node": {
        const changedProperties = { ...operation.properties, ...operation?.['newProperties'] ?? {} }
        const isCommentChange = Object.keys(changedProperties).every(isDiscussionThread)
        if (!isCommentChange) return false
      }
    }
  }
  return true
}
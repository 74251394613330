import React from 'react'
import './Timeline.css'
import cn from 'classnames'

export type TimelineSeparatorProps = {
  className?: string
  color?: string
  style?: React.CSSProperties
}

const TimelineSeparator: React.FC<TimelineSeparatorProps> = ({ className, color, style }) => {
  const classNames = cn('TimelineSeparator', className)
  return <span className={classNames} style={{ backgroundColor: color, ...style }}></span>
}
export default TimelineSeparator

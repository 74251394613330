//@ts-nocheck

import React, { useState } from 'react'
import { Transforms, Range, Editor } from 'slate'
import { useSlateStatic } from 'slate-react'
import {
  cbModal,
  ModalBody,
  ModalHeader,
  Button,
  TextInput,
  Icon,
  Field
} from '../../../../../../index'
import { Registry } from '@react-registry'

import { setElementClass, setElementId, getElementClass, getElementId, getInlineClass, getInlineId, setInlineClass, setInlineId } from './utils'
import { setOldSelection } from '../../../utils/setOldSelection';
import { elementCategories } from '../elementCategories'

export function openPropertyModal(event, editor) {
  event?.preventDefault()
  if (editor.selection) {
    const [start, end] = Range.edges(editor.selection);
    const range = Editor.range(editor, start, end);
    const selectedText = Editor.string(editor, range);

    const isInline = selectedText.length > 0;

    let initialClassName;
    let initialId;

    if (isInline) {
      initialClassName = getInlineClass(editor, editor.selection)
      initialId = getInlineId(editor, editor.selection)
    } else {
      initialClassName = getElementClass(editor, editor.selection)
      initialId = getElementId(editor, editor.selection)
    }

    editor.setFullScreen();

    cbModal({
      modalProps: {
        shouldReturnFocusAfterClose: false,
        onClose: () => setOldSelection(editor),
      },
      component: (props) => (
        <PropertyOverlayModal
          {...props}
          editor={editor}
          selection={editor.selection}
          initialClassName={initialClassName}
          initialId={initialId}
          isInline={isInline}
        />
      )
    })
  }
}

export function PropertyOverlayWrapper(props) {
  const editor = useSlateStatic()
  const handleMouseDown = (event) => {
    openPropertyModal(event, editor)
  }

  return <React.Fragment>{props.children({ handleMouseDown })}</React.Fragment>
}

function PropertyOverlayModal(props) {
  const { selection, initialClassName, initialId, editor } = props

  const [classVal, setClassVal] = useState(initialClassName)
  const [idVal, setIdVal] = useState(initialId)

  const [disableClassButton, setDisableClassButton] = useState(false)
  const [disableIdButton, setDisableIdButton] = useState(false)

  const handleClassOnChange = (e) => {
    setClassVal(e.target.value)
    setDisableClassButton(false)

  }
  const handleClassPlace = () => {
    Transforms.select(editor, selection)
    if (props.isInline) {
      setInlineClass(editor, classVal, selection)
    } else {
      setElementClass(editor, classVal, selection)
    }
    setDisableClassButton(true)
  }

  const handleClassClear = () => {
    Transforms.select(editor, selection)
    setClassVal('')
    if (props.isInline) {
      setInlineClass(editor, '', selection)
    } else {
      setElementClass(editor, '', selection)
    }
    setDisableClassButton(false)
  }

  // Id functions
  const handleIdOnChange = (e) => {
    setIdVal(e.target.value)
    setDisableIdButton(false)
  }

  const handleIdPlace = () => {
    Transforms.select(editor, selection)
    if (props.isInline) {
      setInlineId(editor, idVal, selection)
    } else {
      setElementId(editor, idVal, selection)
    }
    setDisableIdButton(true)
  }

  const handleIdClear = () => {
    Transforms.select(editor, selection)
    setIdVal('')
    if (props.isInline) {
      setInlineId(editor, '', selection)
    } else {
      setElementId(editor, '', selection)
    }
    setDisableIdButton(false)
  }

  return (
    <div>
      <ModalHeader title="Add Property" closeModal={props.closeModal} />

      <ModalBody className="modalBodyCustomClass">
        {/* <p>Class</p> */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Field labelText="Class" style={{ width: "65%" }}>
            <TextInput
              autoFocus
              type="text"
              name="classProperty"
              value={classVal}
              onChange={handleClassOnChange}
              required
              placeholder="Enter class property"
              data-testid="property_class_input"
            />
          </Field>
          {/* <ButtonGroup> */}
          <div>
            <Button buttonType="light" disabled={!classVal} onClick={handleClassClear}>
              Clear
            </Button>
          </div>
          <div>
            <Button id="applyClassBtn" disabled={disableClassButton} icon={disableClassButton ? "CheckedWhite" : ""} onClick={handleClassPlace}>{disableClassButton ? "Applied" : "Apply"}</Button>
          </div>

          {/* </ButtonGroup> */}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Field labelText="ID" style={{ width: "65%" }}>
            <TextInput
              type="text"
              name="IdProperty"
              value={idVal}
              onChange={handleIdOnChange}
              required
              placeholder="Enter ID property"
              data-testid="property_id_input"
            />
          </Field>
          <div>
            <Button buttonType="light" disabled={!idVal} onClick={handleIdClear}>
              Clear
            </Button>
          </div>
          <div>
            <Button id="applyIdBtn" disabled={disableIdButton} icon={disableIdButton ? "CheckedWhite" : ""} onClick={handleIdPlace}>{disableIdButton ? "Applied" : "Apply"}</Button>
          </div>
        </div>
      </ModalBody>
    </div>
  )
}

export function shortcutOpenPropertyModal(event, editor, format, registryObj) {
  openPropertyModal(event, editor)
}

Registry.register(
  {
    iconName: <Icon icon="Properties" />,
    title: 'Properties',
    shortcut: { key: 'mod+shift+p', callback: shortcutOpenPropertyModal },
    IngressComponent: PropertyOverlayWrapper,
    category: elementCategories.MULTIMEDIA_SEE_MORE,
    isContentStackElement: true,
    inBasicToolbar: false,
    canCustomize: false,
    category: elementCategories.MULTIMEDIA_SEE_MORE
  },
  { id: 'property', registry: 'v2.element' }
)
import React from 'react'
import { useDrop } from 'react-dnd'
import { Editor, Element, Path, Transforms } from 'slate'
import { ElementWithType } from 'utils/types'

interface IColDropProps {
  type: string,
  disabled: boolean
  table: Element,
  tablePath: Path,
  editor: Editor,
  colIndex: number

}

function useColDrop({ type, disabled, table, tablePath, editor, colIndex }: IColDropProps) {
  const [{ over }, drop] = useDrop({
    accept: type,
    canDrop: () => !disabled,
    collect: (monitor) => {
      return {
        over: monitor.isOver(),
      }
    },
    drop: (item: any) => {
      Array.from(table.children).map((tableChild: any, tableChildIndex) => {
        Array.from(tableChild.children).map((row: any, rowIndex) => {
          if (row.type === 'trgrp') {
            const rowGrp = row
            const rowGrpIndex = rowIndex
            rowGrp.children.forEach((row, rowIndex) => {
              const sourcePath = [...tablePath, tableChildIndex, rowGrpIndex, rowIndex, item.index]
              const destinationPath = [...tablePath, tableChildIndex, rowGrpIndex, rowIndex, colIndex]
              Transforms.moveNodes(editor, { at: sourcePath, to: destinationPath })
            })
          } else {
            const sourcePath = [...tablePath, tableChildIndex, rowIndex, item.index]
            const destinationPath = [...tablePath, tableChildIndex, rowIndex, colIndex]
            Transforms.moveNodes(editor, { at: sourcePath, to: destinationPath })
          }
        })
      })
      let colsWidth = table['attrs'].colWidths.slice()
      const sourceValue = colsWidth.splice(item.index, 1)
      colsWidth.splice(colIndex, 0, sourceValue[0])
      Transforms.setNodes(
        editor,
        {
          attrs: {
            ...table['attrs'],
            colWidths: colsWidth,
          },
        } as ElementWithType,
        { at: tablePath }
      )
    },
  })

  return (
    {
      over: !disabled && over,
      drop
    }
  )
}

export default useColDrop
/**
 * This table component provides infinite scrolling
 * with virtual rendering
 **/
import React, { useEffect, useRef, useState, forwardRef, Ref, useCallback, useImperativeHandle } from 'react'
import { useTable, useSortBy, useRowSelect, useResizeColumns, useColumnOrder, useBlockLayout } from 'react-table'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import classnames from 'classnames'
import ResizeObserver from 'resize-observer-polyfill'
import flatten from 'lodash/flatten'
import debounce from 'lodash/debounce'

import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc'
import Icon from '../Icon2/Icon'
import TablePanel from './TablePanel'
import {
  pushCheckboxInRow,
  RowSelectAction,
  TableHead,
  RenderRow,
  pushActionColumn,
  ComposeEmptyState,
} from './BitsAndPiecesTable'

import {
  constants,
  viewByName,
  setWidth,
  defaultValue as defaultValueUtil,
  defaultProps as defaultPropsUtil,
  MAX_FREEZE_COUNT,
  appendRule,
  getFreezeColumnCount,
  getTableHeadWidth,
  handleDataOnRowSelect,
  compareColumn,
  getControlledFreezedColumns,
  getControlledListProps,
  getMinMaxStyle,
  generateRowStartStop,
  calculateUpdatedPage,
  EMPTY_ROW_ID
} from './util'

import { Pagination, ControlledInfiniteLoader, TableLoaderV2 } from './Paginate'
import { BulkActionPanel } from '../BulkActionPanel/BulkActionPanel'
import { TableProps, defaultValue, RowDisableProp, FetchDataArgProp } from './types'
import './Table.css'

export const InfiniteScrollTable = (props: TableProps) => {
  //memoize data, column if needed
  const rowSelectCheckboxProp = props.rowSelectCheckboxProp

  const controlledSelectedRowIds =
    typeof props.initialSelectedRowIds === 'object'
      ? props.initialSelectedRowIds
      : defaultValue.controlledSelectedRowIds

  const name = props.name || defaultValue.name

  const columnSelector = typeof props.columnSelector === 'boolean' ? props.columnSelector : defaultValue.columnSelector
  const minBatchSizeToFetch = props.minBatchSizeToFetch || defaultValue.minBatchSizeToFetch
  const viewSelector = typeof props.viewSelector === 'boolean' ? props.viewSelector : defaultValue.viewSelector
  const loading = typeof props.loading === 'boolean' ? props.loading : defaultValue.loading
  const canSearch = typeof props.canSearch === 'boolean' ? props.canSearch : defaultValue.canSearch
  const canRefresh = typeof props.canRefresh === 'boolean' ? props.canRefresh : defaultValue.canRefresh
  const tableHeight = typeof props.tableHeight === 'number' ? props.tableHeight : 0
  const staticRowCount = typeof props.staticRowCount === 'number' ? props.staticRowCount : 0

  const totalCounts = typeof props.totalCounts === 'number' ? props.totalCounts : 30
  const maxSelect = typeof props.maxSelect === 'number' ? props.maxSelect : null
  const linkToRow = props.linkToRow || null
  const withExportCta = props.withExportCta || defaultValue.withExportCta
  const testId = typeof props.testId === 'string' ? props.testId : defaultValue.tableTestId

  const initialRowSelectedData: any = Array.isArray(props.initialRowSelectedData) ? props.initialRowSelectedData : []
  const hiddenColumnsProp: any = Array.isArray(props.hiddenColumns) ? props.hiddenColumns : null

  const rowDisableProp: RowDisableProp = props.rowDisableProp
  const onRowHoverText: any = props.onRowHoverText

  const canOrderColumn = typeof props.canOrderColumn === 'boolean' ? props.canOrderColumn : defaultValue.canOrderColumn
  const columnsOrder = props.columnsOrder || []
  const onChangeColumnOrder = props.onChangeColumnOrder

  const columnWidthsObjectProp = typeof props.resizedColumnWidths === 'object' ? props.resizedColumnWidths : {}

  const v2Features = props.v2Features || defaultValue.v2Features

  const canFreezeCheckbox = (v2Features.canFreezeCheckbox && props.isRowSelect) || false
  const isPaginated = v2Features.pagination || false
  const rowPerPageOptions = props.rowPerPageOptions || defaultValue.rowPerPageOptions

  const [activePage, updatePage] = useState(props.initialPage || 1)
  const [pageSize, updatePageSize] = useState(props.initialPageSize || defaultValue.rowPerPageOptions[0])

  let [selectedData, setSelectedData] = useState(initialRowSelectedData)
  const [viewBy, setViewBy] = useState(constants.comfort_view)
  const [searchValue, setSearchValue] = useState(props.searchValue || '')
  const [toolTipPosFromRight, updateTooltipPos] = useState(0)

  const [isOpenAddColumn, setIsOpenAddColumn] = useState(false)
  const [rowActionData, updateRowActionData] = useState({ rowIndex: -1 })
  const itemSize = props.itemSize || (viewBy === constants.compact_view ? 48 : 60)
  let [listData, setListData] = useState([])
  const tableHeadRef = useRef(null)
  const tableRef = useRef(null)

  const tableHeadWdth = tableHeadRef.current && tableHeadRef.current.offsetWidth
  const tableWidth = tableRef.current && tableRef.current.offsetWidth
  const [updatedTableWidth, setUpdatedTableWidth] = useState(tableWidth)
  const isTableWidthGreater = tableHeadWdth > tableWidth

  const defaultRef = useRef(null)
  const fixedlistRef = props.fixedlistRef || defaultRef

  const tableDefaultReducer = (newState) => newState
  const defaultColumn = () => {
    if (props.isResizable) {
      return getMinMaxStyle(tableWidth)
    }
  }

  const useTableInstance = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        hiddenColumns: props.hiddenColumns || [],
        sortBy: props.initialSortBy || [],
        selectedRowIds: controlledSelectedRowIds
      },
      disableMultiSort: true,
      manualSortBy: true,
      disableSortRemove: true,
      manualPagination: true,
      autoResetSelectedRows: true,
      autoResetHiddenColumns: false,
      autoResetPage: false,
      getRowId: (row: any) => row[props.uniqueKey],
      stateReducer: props.tableStateReducer || tableDefaultReducer
    },
    useSortBy,
    // usePagination,
    useRowSelect,
    useResizeColumns,
    useBlockLayout,
    useColumnOrder,
    (hooks: any) => {
      if (props.isRowSelect) {
        pushCheckboxInRow(hooks, {
          rowSelectCheckboxProp,
          selectedData,
          maxSelect,
          totalCount: totalCounts,
          uniqueKey: props.uniqueKey,
          rowDisableProp
        })
      }
      if (v2Features.tableRowAction) {
        pushActionColumn(hooks, {
          updateRowActionData,
          rowActionData,
          tableRowActionList: props.tableRowActionList || []
        })
      }
      if (props.isResizable) {
        hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
          const headerGroupColumns = headerGroups[0]
          const columnWidthsArrayProp = Object.keys(columnWidthsObjectProp)
          headerGroupColumns.headers.map((headerGroup) => {
            columnWidthsArrayProp.forEach((key, index) => {
              if (headerGroup.id == key) {
                headerGroup.width = setWidth(columnWidthsObjectProp[key], tableWidth)
              }
            })
            headerGroup.canResize = !headerGroup.disableResizing
          })
        })
      }
    }
  )

  const {
    getTableProps,
    getTableBodyProps,
    allColumns,
    headerGroups,
    prepareRow,
    rows,
    state: { sortBy, selectedRowIds, columnResizing },
    setHiddenColumns,
    setColumnOrder
  } = useTableInstance

  const allVisibleColumn = allColumns.filter(
    (column) => column.isVisible && column.id !== 'selection' && column.id !== 'tableActions'
  )
  const hasFreezedColumn = props.canFreezeColumn && getFreezeColumnCount(listData, props.isRowSelect)

  const columnWidthsObjectState = typeof columnResizing.columnWidths === 'object' ? columnResizing.columnWidths : {}
  const totalHeadWidth = getTableHeadWidth(headerGroups)

  const freezedColumnPropsLength = props.freezedColumns?.length || 0

  useEffect(() => {
    try {
      if (props.canFreezeColumn) {
        let filteredAllColumn = allColumns.filter((column) => column.addToColumnSelector)

        let controlledFreezedColumn = []
        if (props.freezedColumns?.length > 0) {
          let columnsToFreeze = getControlledFreezedColumns(props.freezedColumns, filteredAllColumn)

          let freezedColumnObj = columnsToFreeze.map((columnId) => ({ id: columnId }))
          //picking object value from columnId of freezedColumns
          controlledFreezedColumn = compareColumn(allColumns, freezedColumnObj)

          //filtering columnId from unfreezed column
          controlledFreezedColumn.forEach((column) => {
            filteredAllColumn = filteredAllColumn.filter((filterColumn) => {
              return filterColumn.id !== column.id
            })
          })
          setDragListData([controlledFreezedColumn, filteredAllColumn])
          handleScrollPosition(controlledFreezedColumn)
          return
        }

        setListData([[], filteredAllColumn])
      } else {
        resetScrollPosition();
      }
    } catch (error) {
      console.log('useEffect', error)
    }
  }, [allColumns.length, freezedColumnPropsLength, props.canFreezeColumn, columnsOrder.length])

  useEffect(() => {
    setColumnOrder(columnsOrder)
  }, [columnsOrder.join(',')])

  useEffect(() => {
    if (hiddenColumnsProp) {
      const controlledHiddenColumn =
        allVisibleColumn.length === 1 && props.columns.length >= 2 && hiddenColumnsProp?.length
          ? hiddenColumnsProp.filter((column, index) => index !== 0)
          : hiddenColumnsProp
      setHiddenColumns(controlledHiddenColumn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenColumnsProp])

  useEffect(() => {
    const argsData = {
      selectedRowIds,
      rowSelectCheckboxProp,
      data: props.data,
      rowDisableProp,
      uniqueKey: props.uniqueKey,
      selectedData
    }
    const { updatedSelected, selectedRowIdsArr } = handleDataOnRowSelect(argsData)

    setSelectedData(updatedSelected)
    if (props.getSelectedRow) {
      // Passing allRow Selected Data as second Argument
      props.getSelectedRow(selectedRowIdsArr, updatedSelected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(selectedRowIds).length, props.data.length])

  useEffect(() => {
    if (isPaginated) {
      if (!props.resetSearch) {
        // firstPaginatedApiCalled.current = false
        customGotoPage(1)
      }
      return
    }
    let fetchDataArgument: FetchDataArgProp = defaultValue.fetchTableDataArg
    if (sortBy.length) {
      const { desc, id } = sortBy[0]
      const sortingDirection = desc ? constants.desc : constants.asc
      // fetchDataArgument.sortBy = { sortingDirection, id };
      fetchDataArgument = { ...fetchDataArgument, sortBy: { sortingDirection, id } }
    }
    // fetchDataArgument.searchText = searchValue;
    fetchDataArgument = { ...fetchDataArgument, searchText: searchValue }
    if (fixedlistRef && fixedlistRef.current) {
      fixedlistRef.current.scrollToItem(0)
    }
    if (!props.resetSearch) {
      props.fetchTableData(fetchDataArgument)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, searchValue, isPaginated])

  useEffect(() => {
    if (props.resetSearch) {
      setSearchValue('')
    }
  }, [props.resetSearch])

  const columnResizeIndex = allColumns.findIndex((column) => column.isResizing)

  useEffect(() => {
    const columnWidthsArrayState = Object.keys(columnWidthsObjectState)
    if (columnWidthsArrayState.length) {
      let storedWidths = columnWidthsObjectProp
      columnWidthsArrayState.forEach((key) => {
        if (columnWidthsObjectProp && columnWidthsObjectProp.hasOwnProperty(key)) {
          storedWidths[key] = setWidth(columnWidthsObjectState[key], tableWidth)
        } else {
          storedWidths = { ...storedWidths, [key]: setWidth(columnWidthsObjectState[key], tableWidth) }
        }
        columnWidthsObjectState[key] = setWidth(columnWidthsObjectState[key], tableWidth)
      })

      if (props.onResizeColumn) {
        props.onResizeColumn(storedWidths, columnResizeIndex)
        if (hasFreezedColumn > 0) {
          let [freezed = []] = listData
          handleScrollPosition(freezed)
        }
      }
    }
  }, [columnWidthsObjectState, columnWidthsObjectProp, columnResizeIndex])

  useEffect(() => {
    const element = tableRef?.current
    if (!element) return

    const observer = new ResizeObserver(() => {
      setUpdatedTableWidth(element.offsetWidth)
    })

    observer.observe(element)
    return () => {
      observer.disconnect()
    }
  }, [])

  const setTableColumnOrder = (updatedColumnOrder) => {
    setColumnOrder(updatedColumnOrder)
    if (onChangeColumnOrder) {
      onChangeColumnOrder(updatedColumnOrder)
    }
  }

  const setDragListData = (data, action = '') => {
    setListData(data)
    const updatedOrderedColumnIds = flatten(data).map((list: any) => list.id)

    setTableColumnOrder(updatedOrderedColumnIds)

    let [freezed = []] = data
    if (action === 'setColumnFreeze') {
      onColumnsFreeze(freezed)
    }
  }

  const handleRowClick = (e, isRowDisabled, row) => {
    if (props.onRowClick && e.target.id !== 'rowSelect' && !isRowDisabled) {
      props.onRowClick(row.original)
    }

    if (props.isRowSelect && props.fullRowSelect) {
      const disableSelect =
        maxSelect &&
        selectedData.length >= maxSelect &&
        row.original &&
        !selectedData.find((data) => data[props.uniqueKey] === row.original[props.uniqueKey])
      if (
        rowSelectCheckboxProp &&
        row.original[rowSelectCheckboxProp.key] === rowSelectCheckboxProp.value &&
        !disableSelect
      ) {
        const isSelected = row.isSelected ? false : true
        row.toggleRowSelected(isSelected)
      } else if (!rowSelectCheckboxProp && !disableSelect) {
        const isSelected = row.isSelected ? false : true
        row.toggleRowSelected(isSelected)
      }
    }
  }

  const updateViewBy = (selectedViewBy) => {
    if (viewBy !== selectedViewBy) {
      setViewBy(selectedViewBy)

      if (props.getViewByValue) {
        props.getViewByValue(selectedViewBy)
      }
    }
  }

  const onColumnsFreeze = (freezed) => {
    let freezedIds = freezed.map((item) => item.id)

    props.onColumnFreeze(freezedIds)
  }

  const unFreezeColumn = (freezed, unfreezed, column) => {
    let itemIndex = freezed.findIndex((item) => item.id === column.id)
    if (itemIndex > -1) {
      unfreezed.unshift(freezed[itemIndex])
      freezed.splice(itemIndex, 1)
    }
    setDragListData([freezed, unfreezed])
    handleScrollPosition(freezed)
    onColumnsFreeze(freezed)
  }

  const freezeColumn = (freezed, unfreezed, column) => {
    let itemIndex = unfreezed.findIndex((item) => item.id === column.id)
    if (itemIndex > -1) {
      freezed.push(unfreezed[itemIndex])
      unfreezed.splice(itemIndex, 1)
    }

    setDragListData([freezed, unfreezed])
    handleScrollPosition(freezed)
    onColumnsFreeze(freezed)
  }
  const replaceColumn = (freezed, unfreezed, column) => {
    let itemIndex = unfreezed.findIndex((item) => item.id === column.id)
    if (itemIndex > -1) {
      let poppedItem = freezed.pop()
      freezed.push(unfreezed[itemIndex])
      unfreezed.splice(itemIndex, 1)
      unfreezed.unshift(poppedItem)
    }
    setDragListData([freezed, unfreezed])
    handleScrollPosition(freezed)
    onColumnsFreeze(freezed)
  }

  const freezedUnfreezeColumn = (column) => {
    let [freezed = [], unfreezed = []] = listData
    let isFreezed = freezed.findIndex((item) => item.id === column.id) > -1
    if (isFreezed) {
      //remove column /unfreeze
      unFreezeColumn(freezed, unfreezed, column)
      return
    }
    if (freezed.length < MAX_FREEZE_COUNT) {
      // add or remove column
      if (!isFreezed) {
        // add or freeze column
        freezeColumn(freezed, unfreezed, column)
      }
    } else {
      // if freezed count exceeds MAX_FREEZE_COUNT
      if (!isFreezed) {
        replaceColumn(freezed, unfreezed, column) // replace column
      }
    }
  }

  const dropDownList = [
    {
      label: (
        <>
          <Icon version='v2' size='medium' icon="ComfortView" className={`${viewBy === constants.comfort_view ? 'selected' : ''}`} />
          <div className={`${viewBy === constants.comfort_view ? 'selected' : ''}`}>{constants.comfort_view}</div>
        </>
      ),
      action: () => updateViewBy(constants.comfort_view),
      default: true,
      id: 'cs-dropdown-list-comfortView',
      testId: 'cs-table-dropdown-list-comfortView'
    },
    {
      label: (
        <>
          <Icon version='v2' size='medium' icon="CompactView" className={`${viewBy === constants.compact_view ? 'selected' : ''}`} />
          <div className={`${viewBy === constants.compact_view ? 'selected' : ''}`}>{constants.compact_view}</div>
        </>
      ),
      action: () => updateViewBy(constants.compact_view),
      id: 'cs-dropdown-list-compactView',
      testId: 'cs-table-dropdown-list-compactView'
    }
  ]

  const loadMoreItems = (startIndex, stopIndex) => {
    /**
     * Don't make call initially for loading case (totalCount not a number)
     * let parent component make call through fetchTableData on mount or
     * if sortBy changes table will call fetch table data
     **/
    if (typeof props.totalCounts !== 'number') {
      return
    }
    const skip = startIndex ? startIndex - 1 : startIndex
    const limit = stopIndex - skip

    let fetchDataArgument: any = { skip, limit, startIndex, stopIndex }

    if (sortBy.length) {
      const { desc, id } = sortBy[0]
      const sortingDirection = desc ? constants.desc : constants.asc
      fetchDataArgument = { ...fetchDataArgument, sortBy: { sortingDirection, id } }
    }

    if (searchValue) {
      fetchDataArgument = { ...fetchDataArgument, searchText: searchValue }
    }
    return props.loadMoreItems(fetchDataArgument)
  }
  const fetchMoreItems = loading ? () => { } : loadMoreItems

  const onChangeSearch = (value) => {
    setSearchValue(value)
  }

  const onRefresh = () => {
    if (isPaginated) {
      if (props.canRefresh && props.onRefresh) return props.onRefresh()
      const { startIndex, stopIndex } = generateRowStartStop(activePage, pageSize)
      return fetchPaginateItems(startIndex, stopIndex, true)
    }
    let fetchDataArgument: FetchDataArgProp = defaultValue.fetchTableDataArg
    if (sortBy.length) {
      const { desc, id } = sortBy[0]
      const sortingDirection = desc ? constants.desc : constants.asc
      fetchDataArgument = { ...fetchDataArgument, sortBy: { sortingDirection, id } }
    }
    fetchDataArgument = { ...fetchDataArgument, searchText: searchValue }
    if (fixedlistRef && fixedlistRef.current) {
      fixedlistRef.current.scrollToItem(0)
    }

    if (props.canRefresh && props.onRefresh) {
      props.onRefresh()
    } else {
      props.fetchTableData(fetchDataArgument)
    }
  }

  // func to reset scroll position 
  const resetScrollPosition = () => {
    const ruleForScroll = appendRule(document.styleSheets[0])
    ruleForScroll.selectorText = '.Table__body::-webkit-scrollbar-track:horizontal';
    ruleForScroll.style['marginLeft'] = 0;
  }

  // func to shift  scroll position in case of freezed column
  const handleScrollPosition = (freezed) => {
    let totalFreezedColumn = freezed.filter((column) => column.isVisible).length;
    let freezeColumnCount = totalFreezedColumn > 0 && props.isRowSelect ? totalFreezedColumn + 1 : totalFreezedColumn;
    const ruleForScroll = appendRule(document.styleSheets[0])
    ruleForScroll.selectorText = '.Table__body::-webkit-scrollbar-track:horizontal'
    let totalWidth = 0
    headerGroups.map((headerGroup) => {
      headerGroup.headers.map((header, index) => {
        if (freezeColumnCount > 0 && index < freezeColumnCount) {
          totalWidth += headerGroup.headers[index].width
        }
      })
    })
    ruleForScroll.style['marginLeft'] = totalWidth + 'px'
    setActionTooltipOnScroll({ nativeElement: { target: { scrollLeft: tableWidth } } })
  }

  const getTooltipPosition = (scrollLeft) => {
    return totalHeadWidth < tableWidth
      ? defaultValue.toolTipOffset
      : totalHeadWidth + defaultValue.toolTipOffset - (tableWidth + scrollLeft)
  }

  const setActionTooltipOnScroll = (event: { nativeElement?: { target: { scrollLeft: any } }; nativeEvent?: any }) => {
    // need only for action tool tip old table row action
    if (Array.isArray(props.tableRowActionList) && !v2Features.tableRowAction) {
      if (hasFreezedColumn || canFreezeCheckbox) {
        let tableScrollLeft = event.nativeEvent ? event.nativeEvent?.target.scrollLeft : tableWidth
        const scrollLeft =
          tableScrollLeft === tableWidth || tableScrollLeft === tableWidth - defaultValue.freezeColumnBorderWidth
            ? 1
            : tableScrollLeft
        const toolTipPosFromRight = getTooltipPosition(scrollLeft)
        updateTooltipPos(toolTipPosFromRight)
        return
      }
      const scrollLeft = (tableRef.current && tableRef.current.scrollLeft) || 0
      let toolTipPosFromRight = defaultValue?.toolTipOffset
      if (tableHeadWdth && tableWidth) {
        toolTipPosFromRight = tableHeadWdth + defaultValue?.toolTipOffset - (tableWidth + scrollLeft)
      }
      updateTooltipPos(toolTipPosFromRight)
    }
  }

  const isItemLoaded = (index) => {
    if (props.itemStatusMap[index] === 'loaded') {
      return true
    }
    return false
  }

  const fetchPaginateItems = (startIndex, stopIndex, isRefreshing?: boolean) => {
    const skip = startIndex
    const limit = stopIndex - startIndex

    let fetchDataArgument: any = { skip, limit, startIndex, stopIndex }

    if (sortBy.length) {
      const { desc, id } = sortBy[0]
      const sortingDirection = desc ? constants.desc : constants.asc
      // fetchDataArgument.sortBy = { sortingDirection, id };
      fetchDataArgument = { ...fetchDataArgument, sortBy: { sortingDirection, id } }
    }

    fetchDataArgument = { ...fetchDataArgument, searchText: searchValue }
    if (isRefreshing) fetchDataArgument = { ...fetchDataArgument, refresh: true }
    props.fetchTableData(fetchDataArgument)
  }

  const customGotoPage = (page) => {
    if (activePage !== page) {
      updatePage(page)
    }
    const { startIndex, stopIndex } = generateRowStartStop(page, pageSize)
    fetchPaginateItems(startIndex, stopIndex)
  }

  const handleSetPageSize = (newRowPerPage: number, oldRowPerPage: number, oldPage: number) => {
    const updatePageNum = calculateUpdatedPage(newRowPerPage, oldRowPerPage, oldPage)
    updatePageSize(newRowPerPage)
    if (oldPage !== updatePageNum) updatePage(updatePageNum)

    const { startIndex, stopIndex } = generateRowStartStop(updatePageNum, newRowPerPage)
    fetchPaginateItems(startIndex, stopIndex)

    if (props.onChangePagination) {
      props.onChangePagination({
        pageSize: newRowPerPage,
        isPageSizeChanged: true,
        page: updatePageNum,
        isPageChange: oldPage !== updatePageNum
      })
    }
  }
  const handleSetPage = (page: number, rowCount: number) => {
    updatePage(page)
    const { startIndex, stopIndex } = generateRowStartStop(page, rowCount)
    fetchPaginateItems(startIndex, stopIndex)
    if (props.onChangePagination) {
      props.onChangePagination({
        pageSize: pageSize,
        isPageSizeChanged: false,
        page: page,
        isPageChange: true
      })
    }
  }

  useImperativeHandle(
    props.tableInstanceRef,
    () => ({
      setTablePage: (page) => {
        updatePage(page)
      },
      pageSize,
      activePage,
      setTablePageSize: (size) => {
        updatePageSize(size)
      }
    }),
    [pageSize, activePage]
  )

  const canShowEmptyBorder = (finalCount) => {
    let rowCount = finalCount;
    if (isPaginated) {
      rowCount = (activePage * pageSize) <= props.totalCounts ? pageSize : props.totalCounts - ((activePage - 1) * pageSize)
    }

    const tableBody = document.querySelector('.Table__body');
    let showEmptyBorder = false;
    let emptyBorderRowHeight = 0
    if (tableBody) {
      let tableBodyHeight = tableBody.getBoundingClientRect().height;
      const tableHead = document.querySelector('.Table__head');
      if (tableHead && tableBody.contains(tableHead)) {
        const tableHeadHeight = tableHead.getBoundingClientRect().height;
        tableBodyHeight = tableBodyHeight - tableHeadHeight;
      }
      const listRowHeight = rowCount * itemSize
      showEmptyBorder = tableBodyHeight > listRowHeight;
      if (showEmptyBorder) {
        emptyBorderRowHeight = tableBodyHeight - listRowHeight
        // if emptyBorderRowHeight less than rowHeight we set emptyBorderRowHeight same as rowHeight
        if (itemSize > emptyBorderRowHeight) emptyBorderRowHeight = itemSize
      }
    }
    return { showEmptyBorder, emptyBorderRowHeight }
  }

  const TableHeadNodes = (
    <TableHead
      isTableWidthGreater={isTableWidthGreater}
      viewBy={viewBy}
      listData={listData}
      tableHeadRef={tableHeadRef}
      isRowSelect={props.isRowSelect}
      isResizable={props.isResizable}
      headerGroups={headerGroups}
      equalWidthColumns={props.equalWidthColumns}
      canFreezeColumn={props.canFreezeColumn}
      columnWidthsObjectState={columnWidthsObjectState}
      columnWidthsObjectProp={columnWidthsObjectProp}
      totalCounts={totalCounts}
      v2Features={v2Features}
      tableWidth={tableWidth}
      loading={loading}
      canFreezeCheckbox={canFreezeCheckbox}
      tableWidthSmaller={totalHeadWidth < tableWidth}
      columns={props.columns}
    />
  )

  //This render Table head with All table row Data
  const innerElementType = forwardRef<HTMLDivElement>(({ style, ...rest }: { children: []; style: any }, ref) => {
    return (
      <div className="Table__inner__wrapper">
        {TableHeadNodes}
        {isPaginationLoader ? null : (
          <>
            {props.customRowAdd && props.customRowComponent}
            <div
              ref={ref}
              style={{
                ...style,
                height: `${parseFloat(style?.height)}px`
              }}
              {...rest}
            />
          </>
        )}
      </div>
    )
  })

  const isPaginatedVisible = isPaginated && totalCounts > 0 && allVisibleColumn.length && !props.showSelected
  const isPaginatedVisibleOnTablePanel = isPaginated && allVisibleColumn.length && !props.showSelected

  const tablePanelProps = {
    staticRowCount: staticRowCount,
    columnSelector: columnSelector,
    allColumns: allColumns,
    name: name,
    totalCounts: totalCounts,
    viewSelector: viewSelector,
    dropDownList: dropDownList,
    freezedUnfreezeColumn: freezedUnfreezeColumn,
    isResizable: props.isResizable,
    viewBy: viewBy,
    loading: loading,
    onChangeSearch: onChangeSearch,
    onRefresh: onRefresh,
    searchPlaceholder: props.searchPlaceholder,
    searchValue: props.searchValue,
    canSearch: canSearch,
    canRefresh: canRefresh,
    onToggleColumnSelector: props.onToggleColumnSelector,
    withExportCta: withExportCta,
    setTableColumnOrder: setTableColumnOrder,
    canOrderColumn: canOrderColumn,
    canFreezeColumn: props.canFreezeColumn,
    listData: listData,
    setListData,
    setDragListData,
    dropdownCustomOptions: props.dropdownCustomOptions,
    isOpenAddColumn,
    setIsOpenAddColumn,
    hasFreezedColumn: hasFreezedColumn,
    testId,
    showTotalCount: !isPaginatedVisibleOnTablePanel,
    countExceedPompt: props.countExceedPompt
  }

  const emptyHead = props.emptyHeading || 'No Records Found'
  const emptyDesc = props.emptyDescription || ''

  const isTableEmpty = totalCounts === 0 && !loading
  const noVisibleColumn = columnSelector && allVisibleColumn.length === 0


  const isPaginationLoader = isPaginated && loading
  const isStickyPosition = props.canFreezeColumn || v2Features.tableRowAction || canFreezeCheckbox

  let finalCount
  if (isPaginated) {
    finalCount = props.data.length
  } else {
    const incrementalCount = props.data.length < totalCounts ? props.data.length + minBatchSizeToFetch : props.data.length
    finalCount = incrementalCount >= totalCounts ? totalCounts : incrementalCount
  }

  const { showEmptyBorder, emptyBorderRowHeight } = canShowEmptyBorder(finalCount)
  //Adding empty row Object to fill table border in case of empty
  finalCount = showEmptyBorder ? finalCount + 1 : finalCount;
  let controlledRows = [];
  if (rows.length && showEmptyBorder) {
    controlledRows = [...controlledRows, ...rows, { ...rows[0], id: EMPTY_ROW_ID, index: rows.length }];
  }

  const RenderRowProps = {
    rows: controlledRows.length && showEmptyBorder ? controlledRows : rows,
    totalCounts: totalCounts,
    viewBy: viewBy,
    isResizable: props.isResizable,
    itemStatusMap: props.itemStatusMap,
    prepareRow: prepareRow,
    isRowSelect: props.isRowSelect,
    singleSelectedRowId: props.singleSelectedRowId,
    onHoverListCondition: props.onHoverListCondition,
    conditionalSingleSelect: props.conditionalSingleSelect,
    rowDisableProp: rowDisableProp,
    tableRowActionList: props.tableRowActionList,
    rowSelectCheckboxProp: rowSelectCheckboxProp,
    equalWidthColumns: props.equalWidthColumns,
    handleRowClick: handleRowClick,
    toolTipPosFromRight: toolTipPosFromRight,
    fullRowSelect: props.fullRowSelect,
    linkToRow: linkToRow,
    onRowHoverText: onRowHoverText,
    headerGroups: headerGroups,
    columnWidthsObjectState: columnWidthsObjectState,
    columnWidthsObjectProp: columnWidthsObjectProp,
    columnResizing: columnResizing,
    listData: listData,
    canFreezeColumn: props.canFreezeColumn,
    isPaginated,
    v2Features: v2Features,
    tableWidth: tableWidth,
    rowActionData: rowActionData,
    updateRowActionData: updateRowActionData,
    canFreezeCheckbox,
    customRowAdd: props.customRowAdd,
    tableWidthSmaller: totalHeadWidth < tableWidth,
    columns: props.columns,
    showEmptyBorder,
    emptyBorderRowHeight,
  }

  let isLastPage = totalCounts > 0 && Math.ceil(totalCounts / pageSize) == activePage;
  let showCountExceedPrompt = !loading && isLastPage && props.countExceedPompt && props.countExceedPompt == "gte"

  return (
    <div data-test-id={testId}>
      {selectedData.length && props.bulkActionList && props.bulkActionList.length ? (
        <>
          {v2Features.tableBulkAction ? (
            <>
              <BulkActionPanel
                bulkActionList={props.bulkActionList}
                selectedData={selectedData}
                updatedParentWidth={updatedTableWidth}
              />
              <TablePanel {...tablePanelProps} />
            </>
          ) : (
            <RowSelectAction
              selectedData={selectedData}
              name={name}
              bulkActionList={props.bulkActionList}
              tableWidth={tableWidth}
            />
          )}
        </>
      ) : (
        !props.hideTablePanel && <TablePanel {...tablePanelProps} />
      )}

      {showCountExceedPrompt ? <div className="countExceedPompt">
        <p>We display a maximum of 10,000 records for a search query. Please try using filters or adjusting your query to get more specific results.</p>
      </div> : null}

      <div
        {...getTableProps()}
        className={classnames(
          'Table',
          (hasFreezedColumn || v2Features.tableRowAction || canFreezeCheckbox) && 'TableColumnSticky',
          isPaginatedVisible && 'TableWithPaginated',
          (v2Features.tableRowAction) && totalHeadWidth < tableWidth && 'TableWithRowAction',
          (canFreezeCheckbox) && totalHeadWidth < tableWidth && 'TableFreezeCheckbox'
        )}
        ref={tableRef}
        onScroll={debounce(setActionTooltipOnScroll, 300)}>
        <div {...getTableBodyProps()} className="Table__rowgroup">
          {isTableEmpty || noVisibleColumn ? (
            <>
              {v2Features.isNewEmptyState && isTableEmpty ? TableHeadNodes : null}
              <ComposeEmptyState
                noVisibleColumn={noVisibleColumn}
                onOpenAddColumn={() => setIsOpenAddColumn(true)}
                emptyObj={props.emptyObj}
                emptyHead={emptyHead}
                emptyDesc={emptyDesc}
                isNewEmptyState={v2Features.isNewEmptyState}
              />
            </>
          ) : (
            <div
              style={{
                height: tableHeight || '100vh',
                width: 'inherit',
                userSelect: columnResizeIndex == -1 ? 'auto' : 'none'
              }}>
              <ControlledInfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={finalCount + 1}
                loadMoreItems={fetchMoreItems}
                minimumBatchSize={minBatchSizeToFetch}
                threshold={Math.floor(minBatchSizeToFetch / 2)}
                isPaginated={isPaginated}>
                {({ onItemsRendered, ref }: any) => (
                  <>
                    <AutoSizer disableWidth={true}>
                      {({ height }) => {
                        const controlledListProps = getControlledListProps({
                          isStickyPosition,
                          height,
                          innerElementType,
                          isTableWidthGreater,
                          isItemLoaded,
                          isPaginationLoader
                        })
                        return (
                          <>
                            {/* for non sticky case render table head and pagination loader  */}
                            {!isStickyPosition && (
                              <>
                                {TableHeadNodes}
                                {isPaginationLoader && (
                                  <TableLoaderV2 tableWidth={tableWidth} tableHeight={tableHeight} />
                                )}
                              </>
                            )}
                            {/* Do not render anything for non sticky case and pagination loader */}
                            {!isStickyPosition && isPaginationLoader ? null : (
                              <>
                                {props.customRowAdd && !isStickyPosition && props.customRowComponent}
                                <FixedSizeList
                                  width="100%"
                                  itemCount={finalCount}
                                  itemSize={itemSize}
                                  onItemsRendered={onItemsRendered}
                                  ref={(list) => {
                                    if (ref) ref(list)
                                    fixedlistRef.current = list
                                  }}
                                  {...controlledListProps}>
                                  {(fixedListProps) => {
                                    return <RenderRow {...RenderRowProps} {...fixedListProps} />
                                  }}
                                </FixedSizeList>
                              </>
                            )}
                          </>
                        )
                      }}
                    </AutoSizer>
                    {/* For sticky case render pagination loader and table head will come from innerElementType */}
                    {isPaginationLoader && isStickyPosition && (
                      <TableLoaderV2 tableWidth={tableWidth} tableHeight={tableHeight} />
                    )}
                  </>
                )}
              </ControlledInfiniteLoader>
            </div>
          )}
        </div>
      </div>
      {isPaginatedVisible && (
        <Pagination
          totalCount={props.totalCounts || 0}
          rowsOptions={rowPerPageOptions}
          rowPerPage={pageSize}
          onRowOptionChange={handleSetPageSize}
          onPageChange={handleSetPage}
          activePage={activePage}
          loading={loading}
          countExceedPompt={props.countExceedPompt}
        />
      )}
    </div>
  )
}

const InfiniteScrollTableWithDeprecatedProp = withDeprecatedProp(InfiniteScrollTable, {
  serachValue: 'searchValue',
  onHoverActionList: 'tableRowActionList',
  onRowSelectProp: 'bulkActionList'
})

const TableComponent = React.forwardRef<HTMLDivElement, any>((props, ref) => (
  <InfiniteScrollTableWithDeprecatedProp tableInstanceRef={ref} {...props} />
))

export default TableComponent

import React from 'react'
import { useSlateStatic } from 'slate-react'
import { Registry } from '@react-registry'
import { textDirection, isNodeTextDirected } from '../utils'
import { elementCategories } from '../../elementCategories'
import { LTRTextDirectionIcon } from '../../../utils/Icon'

export const ShortcutTextDirectionLTR = (editor) => {
  textDirection(editor, 'ltr')
}

export const LeftToRight = (props) => {
  const editor = useSlateStatic()

  const handleMouseDown = (event) => {
    event.preventDefault()
    textDirection(editor, 'ltr')
  }

  return (
    <React.Fragment>
      {props.children({
        handleMouseDown,
        active: isNodeTextDirected(editor, 'ltr')
      })}
    </React.Fragment>
  )
}

Registry.register(
  {
    iconName: <LTRTextDirectionIcon />,
    slashIconName: <LTRTextDirectionIcon />,
    style: 'ltr',
    shortcut: { key: { win: 'mod+alt+r', mac: 'cmd+ctrl+r' }, callback: ShortcutTextDirectionLTR },
    title: 'Left-to-right(LTR)',
    slashTitle: 'Left-to-right(LTR)',
    template: '',
    IngressComponent: LeftToRight,
    category: elementCategories.MULTIMEDIA_SEE_MORE,
    isContentStackElement: true,
    inBasicToolbar: true,
    canCustomize: false,
    keywords: ['ltr']
  },
  { id: 'text-direction-ltr', registry: 'v2.element' }
)

import { isPlainObject } from "lodash"

interface IAttrs {
  [key: string]: string
}

export function getHTMLAttributesJson(el: HTMLElement) {
  if (!(el instanceof HTMLElement)) return {}
  return Object.fromEntries(
    Array.from(el.attributes)
      .map((attribute) => {
        return [attribute.name, el.getAttribute(attribute.name)]
      })
  )
}

export function getHTMLAttributeJsonToString(attrs: IAttrs) {
  if (!isPlainObject(attrs)) return ''
  return Object.entries(attrs).map(([key, value]) => `${key}="${value}"`).join(" ")
}



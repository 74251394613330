import React, { FC, useEffect, useMemo, useRef } from 'react'
import InfiniteLoader from 'react-window-infinite-loader'
import Dropdown from '../DropDown/Dropdown'
import Icon from '../Icon2/Icon'
import Button from '../Button/Button'

import cn from 'classnames'

export const ControlledInfiniteLoader = (props) => {
  const { children, isPaginated, ...rest } = props
  if (isPaginated) return <>{children({})}</>
  return <InfiniteLoader {...rest}>{(data) => children(data)}</InfiniteLoader>
}

interface PaginationProps {
  totalCount: number
  rowsOptions: number[]
  rowPerPage: number
  onRowOptionChange: (newRowPerPage: number, oldRowPerPage: number, oldPage: number) => void
  onPageChange: (page: number, rowPerPage: number) => void
  activePage: number
  loading?: boolean
  countExceedPompt?: string
}

export const Pagination: FC<PaginationProps> = React.memo((props) => {
  const { totalCount, rowsOptions, rowPerPage, onRowOptionChange, onPageChange, activePage, loading, countExceedPompt } = props

  const optionList = useMemo(() => {
    return rowsOptions?.map((eachOption) => {
      return {
        label: eachOption,
        default: rowPerPage === eachOption
      }
    })
  }, [rowsOptions, rowPerPage])

  const pageList = useMemo(() => {
    let pages = []
    let totalPage = Math.ceil(totalCount / rowPerPage)
    totalPage = totalCount === 0 ? activePage : totalPage

    for (let i = totalCount === 0 ? activePage : 1; i <= totalPage; i++) {
      pages.push({
        label: i,
        default: i === activePage
      })
    }
    return pages
  }, [rowPerPage, totalCount, activePage])

  const leftIconDisabled = activePage === 1 || loading
  const rightIconDisabled = activePage === pageList.length || loading
  const tableInfoCN = cn('TablePagination__info', { [`TablePagination__info--disable`]: loading })
  const tableNavCN = cn('TablePagination__navigation', { [`TablePagination__navigation--disable`]: loading })
  // const leftIconCN = cn('TablePagination__icon', { ['TablePagination__icon--disabled']: activePage === 1 })
  // const rightIconCN = cn('TablePagination__icon', {
  //   ['TablePagination__icon--disabled']: activePage === pageList.length
  // })

  const visitedRow = totalCount === 0 ? 0 : (activePage - 1) * rowPerPage + 1
  const visitedRowWithCurrentPage = visitedRow - 1 + rowPerPage > totalCount ? totalCount : visitedRow - 1 + rowPerPage
  const finalVisitedRow = visitedRowWithCurrentPage > totalCount ? totalCount : visitedRowWithCurrentPage

  const handleOnKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.target.click()
    }
  }

  let countExceededSign = countExceedPompt === 'gte' ? '+ ' : ''

  return (
    <div className="TablePagination" data-test-id="cs-table-Paginate">
      <div className={tableInfoCN}>
        Showing
        <Dropdown
          testId="paginate-page-size-option"
          list={optionList}
          type="select"
          dropDownPosition="top"
          withSearch={false}
          closeAfterSelect
          highlightActive
          version="v2"
          onChange={loading ? undefined : (data) => onRowOptionChange(data.label, rowPerPage, activePage)}
          className={loading ? 'TablePagination__dropdown--disabled' : ''}
          selectedAriaLabel={`Showing ${rowPerPage} records`}
        />
        <span data-test-id="cs-table-Paginate-count">{visitedRow} to {finalVisitedRow} of {totalCount + countExceededSign} {totalCount > 1 ? 'records' : 'record'}</span>
      </div>
      <div className={tableNavCN}>
        <Button
          aria-label="Go to the First Page"
          disabled={leftIconDisabled}
          onKeyDown={handleOnKeyDown}
          onClick={() => onPageChange(1, rowPerPage)}
          testId="paginate-move-first"
          size="regular"
          buttonType="tertiary"
          onlyIcon={true}
          icon="v2-CaretCircleDoubleLeft"
          version="v2"
          onlyIconHoverColor='secondary'
          iconProps={{
            withTooltip: true,
            tooltipContent: "First Page",
            tooltipPosition: "top"
          }}
        />

        <Button
          aria-label="Previous Page"
          disabled={leftIconDisabled}
          onKeyDown={handleOnKeyDown}
          onClick={leftIconDisabled ? undefined : () => onPageChange(activePage - 1, rowPerPage)}
          testId="paginate-move-previous"
          size="regular"
          buttonType="tertiary"
          onlyIcon={true}
          icon="v2-CaretCircleLeft"
          version="v2"
          onlyIconHoverColor='secondary'
          iconProps={{
            withTooltip: true,
            tooltipContent: "Previous",
            tooltipPosition: "top"
          }}
        />

        <Dropdown
          list={pageList}
          type="select"
          dropDownPosition="top"
          withSearch={false}
          closeAfterSelect
          highlightActive
          version="v2"
          onChange={pageList.length === 1 || loading ? undefined : (data) => onPageChange(data.label, rowPerPage)}
          className={pageList.length === 1 || loading ? 'TablePagination__dropdown--disabled' : ''}
          testId="paginate-page-options"
          selectedAriaLabel={`Page ${activePage}`}
        />
        <Button
          aria-label="Next Page"
          disabled={rightIconDisabled}
          onKeyDown={handleOnKeyDown}
          onClick={rightIconDisabled ? undefined : () => onPageChange(activePage + 1, rowPerPage)}
          testId="paginate-move-next"
          size="regular"
          buttonType="tertiary"
          onlyIcon={true}
          icon="v2-CaretCircleRight"
          version="v2"
          onlyIconHoverColor='secondary'
          iconProps={{
            withTooltip: true,
            tooltipContent: "Next",
            tooltipPosition: "top"
          }}
        />

        <Button
          aria-label="Go to the Last Page"
          disabled={rightIconDisabled}
          onKeyDown={handleOnKeyDown}
          onClick={() => onPageChange(pageList.length, rowPerPage)}
          testId="paginate-move-last"
          size="regular"
          buttonType="tertiary"
          onlyIcon={true}
          icon="v2-CaretCircleDoubleRight"
          version="v2"
          onlyIconHoverColor='secondary'
          iconProps={{
            withTooltip: true,
            tooltipContent: "Last Page",
            tooltipPosition: "top",
          }}
        />
      </div>
    </div>
  )
})

export const TableLoaderV2 = ({ tableWidth, tableHeight }) => {
  let height = tableHeight ? tableHeight + 'px' : '100%'
  return (
    <div
      className="Table__loader"
      data-test-id="cs-table-loader"
      style={{
        width: tableWidth + 'px',
        height: `calc(${height} - 11.175rem)`
      }}>
      <div className="Table__loader_content">
        <Icon icon="PurpleLoader" className="Table__loader-svg" />
        <div className="Table__loader-desc">Fetching details...</div>
      </div>
    </div>
  )
}
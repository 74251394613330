import { Editor, Element, Node, NodeEntry, Path, Transforms } from 'slate'
import { ElementWithType } from '../../../../../../utils/types'
import { generateId } from '../../../utils/hooks/withId'
import { isInlineElement, isSelectionCollapsed } from '../../../utils/queries'
import { isEqual } from 'lodash'

import { jsx } from 'slate-hyperscript'
import { wrapChildren } from '../../utils/wrapChildren'

export const LIST_TYPES = ['ol', 'ul']

export const listWrapperElement = (format) => {
  return { type: format, children: [], id: generateId() }
}

export const toggleList = (event, editor, format) => {
  event?.preventDefault()
  const selection = editor.selection || editor.savedSeleciton
  if (selection) {
    // incase selection is from savedSelection
    Transforms.select(editor, selection)

    if (isSelectionCollapsed(selection)) {
      const [currentElement, currentElementPath] = Editor.parent(editor, selection) as any

      // if it already a list
      if (currentElement.type === 'li') {
        const listType = Editor.parent(editor, currentElementPath) as any

        // different list then convert
        if (listType?.[0]?.type !== format) {
          Transforms.setNodes(editor, { type: format } as ElementWithType, { at: listType[1] })
        }
        return
      }

      // if prevSibling is also a same list
      if (currentElementPath[currentElementPath.length - 1] > 0) {
        const prevSiblingPath = currentElementPath.slice()
        prevSiblingPath[prevSiblingPath.length - 1] -= 1

        const [prevSibling] = Editor.node(editor, prevSiblingPath) as any
        if (prevSibling.type === format) {
          Transforms.setNodes(editor, {
            type: 'li'
          } as ElementWithType)

          //@ts-ignore
          const movePosition = [...prevSiblingPath, prevSibling.children.length]
          Transforms.moveNodes(editor, { to: movePosition })
          return
        }
      }
    } else {
      let fixed = false

      // already a list
      for (const [, listItemPath] of Editor.nodes(editor, {
        match: (n: ElementWithType) => n.type === 'li'
      })) {
        fixed = true
        const [listType, listTypePath] = Editor.parent(editor, listItemPath) as any
        if (listType.type !== format) {
          Transforms.setNodes(editor, { type: format } as ElementWithType, { at: listTypePath })
        }
      }

      if (fixed) return
    }

    // create a new list
    editor.stopNormalizeNode = true
    Transforms.setNodes(editor, {
      type: 'li'
    } as ElementWithType)
    const block = listWrapperElement(format)
    editor.stopNormalizeNode = false
    Transforms.wrapNodes(editor, block)
  }
}

export const getPreviousListItemEntry = (editor: Editor, liPath: Path) => {
  const previousPath = Path.previous(liPath)

  const previousItem = Node.get(editor, previousPath)
  if (previousItem['type'] === 'li') return [previousItem, previousPath] as NodeEntry<Element>

  const [previousLiEntry] = Editor.nodes<Element>(editor, {
    at: previousPath,
    match: (node, path) => {
      if (node['type'] !== 'li' || !Element.isElement(node)) return

      if (Array.isArray(node.children) && node.children.find((child) => LIST_TYPES.includes(child?.['type']))) return
      const [parent, parentPath] = Editor.parent(editor, path)
      const lastPath = [...parentPath, parent.children.length - 1]
      const isLast = isEqual(lastPath, path)
      return isLast && Path.isBefore(path, liPath)
    }
  })

  return previousLiEntry
}

export const handleTableInList = (editor: Editor, liPath: Path) => {
  const previousLiEntry = getPreviousListItemEntry(editor, liPath)
  if (!previousLiEntry) return false

  const [previousLi, previousLiPath] = previousLiEntry
  const liRef = Editor.pathRef(editor, liPath)

  const previousLiRef = Editor.pathRef(editor, previousLiPath)

  // we check for last element here because if one element is inline all elements should be 
  // and thus wrapped in p tag
  if (isInlineElement(previousLi.children.at(-1))) {
    wrapChildren(editor, previousLiEntry, jsx('element', { type: 'p' }))
  }

  const updatedPreviousLi = Node.get(editor, previousLiRef.current)

  if (!Element.isElement(updatedPreviousLi)) return

  const pathToMove = Path.next(previousLiRef.current)
  Transforms.moveNodes(editor, {
        at: liPath,
        to: pathToMove
  })
  Transforms.select(editor, pathToMove)
  Transforms.collapse(editor, {edge: 'start'})
  Transforms.mergeNodes(editor, {at: pathToMove, match: (node) => node?.['type'] === 'li'})

  liRef.unref()
  previousLiRef.unref()
  return true
}

export const removeListMarker = (editor: Editor, liEntry: NodeEntry<Element>) => {
  const [li] = liEntry
  Editor.withoutNormalizing(editor, () => {
    Transforms.unwrapNodes(editor, {
      match: (n: any) => LIST_TYPES.includes(n.type),
      split: true
    })
    if (isInlineElement(li.children[0])) {
      Transforms.setNodes(editor, { type: 'p' } as Partial<Element>)
    } else {
      Transforms.unwrapNodes(editor, {
        match: (n: any) => n.type === 'li'
      })
    }
  })
}

import collapse from 'collapse-whitespace'
import prettify from 'pretty'

import { htmlSanitizer } from '..'
import { fromRedactor } from './fromRedactor'
import { toRedactor } from './toRedactor'

const transformInvalidNestedLists = (html: HTMLElement) => {
  const invalidLists = Array.from(html.querySelectorAll(`ol > ol, ol > ul, ul > ol, ul > ul`))

  for (let list of invalidLists) {
    const previousListItem = list.previousElementSibling
    if (!previousListItem) return
    previousListItem.appendChild(list)
  }
}

export function htmlToJson(htmlValue, allowExtraTags) {
  let parsedHtml = htmlSanitizer(htmlValue)
  collapse(parsedHtml)
  parsedHtml.normalize() // remove empty text nodes

  transformInvalidNestedLists(parsedHtml)

  const slateJson = [
    {
      type: 'docs',
      children: fromRedactor(parsedHtml, allowExtraTags)
    }
  ]
  return slateJson
}

export function jsonToHtml(slateDoc) {
  return prettify(toRedactor(slateDoc))
}
import React from 'react'
import "./Timeline.css"
import cn from 'classnames'



export type TimelinePointProps = {
  shape?: 'circle' | 'square',
  type?: 'outlined' | 'filled'
  className?: string,
  style?: React.CSSProperties,
  countColor?: string,
  color?: string,
  onClick?: any,
  cursor?: 'pointer'
}

const TimelinePoint: React.FC<TimelinePointProps> = (props) => {
  const { shape, children, className, type, style, onClick, cursor } = props;
  const PointTypes = {
    'TimelinePoint--Circle': shape === 'circle',
    'TimelinePoint--Square': shape === 'square',
    'TimelinePoint--Outlined': type === 'outlined',
    'TimelinePoint--Filled': type === 'filled',
    'TimelinePoint--NoPoint': ["string", "number"].includes(typeof children),
    'TimelinePoint--Pointer': cursor === 'pointer',
  }
  let click = onClick ? onClick : () => { };

  const classNames = cn('TimelinePoint', className, PointTypes,)
  return (
    <div
      onClick={click}
      style={{ ...style }}
      className={classNames}>{children}</div>
  )
}

export default TimelinePoint
import React from 'react'
import Tooltip from '../Tooltip/Tooltip'
import Icon from '../Icon2/Icon'
import './Help.css'

export interface HelpProps {
    className?: string
    text: string | React.ReactNode
    type: 'primary' | 'secondary'
    alignment?: string
    tippyProps?: any
    children?: string | React.ReactNode
    testId?: string
}

export const Help = (props: HelpProps) => {
    const { className, text, alignment, type, tippyProps, testId, ...otherProps } = props
    const variantType = type === 'secondary' ? 'light' : 'dark'
    return (
        <span data-test-id={testId}>
            <Tooltip {...tippyProps} content={text} variantType={variantType} type={type} position={alignment}>
                <Icon icon="Question" version="v2" size="small" className="Help" {...otherProps}></Icon>
            </Tooltip>
        </span>
    )
}

Help.defaultProps = {
    type: 'primary',
    alignment: 'right',
    tippyProps: {},
    testId: 'cs-help'
}

export default Help

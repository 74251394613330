import './ProjectCard.css'

import React, {
  useMemo, useState,
} from 'react'
import Avatar, { AvatarData } from '../Avatar/Avatar'

import Dropdown from '../DropDown/Dropdown'
import Icon from '../Icon2/Icon'
import Tooltip from '../Tooltip/Tooltip';

const defaultProps = {
  title: 'Title',
  active: false,
  stats: [],
  actionList: [],
  avatars: [],
  type: 'text',
  modified: ''
}

type DefaultProps = Readonly<typeof defaultProps>

type CardStats = {
  number: string
  category: string
}

export type ProjectCardProps = {
  title?: string
  active?: boolean
  stats: CardStats[]
  data?: any
  actionList: any,
  avatars: AvatarData[]
  modified: string
  type?: 'text' | 'image',
  isActive?: boolean,
  onActiveToggle: (state, event) => void,
  starActiveTooltip?: string,
  starInActiveTooltip?: string,
  showPlusSignAfter: number
} & Partial<DefaultProps>

const ProjectCardFooter = ({ type, modified, avatars, showPlusSignAfter }: { type: string, modified: string, avatars: AvatarData[], showPlusSignAfter }) => {
  return useMemo(() => {
    return (
      <div className="ProjectCard__footer">

        {avatars && (
          <Avatar
            avatars={avatars}
            type={"text"}
            showPlusSignAfter={showPlusSignAfter}
            showExtraType="link"
            showExtraColor="dark"
          ></Avatar>
        )}

        {
          modified &&
          <Tooltip content="Last modified" position="top">
            <div className="ProjectCard__modified flex-v-center">
              <Icon className="ProjectCard__modified-icon" icon={'ModifiedBy'} />
              <span className="ProjectCard__modified-date">{modified}</span>
            </div>
          </Tooltip>
        }
      </div>
    )
  }, [type, modified])
}

const ProjectCard = (props: ProjectCardProps) => {
  let { title, stats, modified, type, actionList, avatars, showPlusSignAfter, active, onActiveToggle } = props;

  const shortName = (name: any) => {
    if (name) {
      if (name.length < 18) {
        return name
      } else {
        return `${name.slice(0, 18)}`
      }
    }
  }

  let [isActive, setIsActive] = useState(active)
  const handleStarClick = (event: any) => {
    setIsActive(!isActive)
    onActiveToggle({ isActive: !isActive }, event)
  }
  return (
    <div className="ProjectCard">
      <div className="ProjectCard__heading">
        <h4 className="ProjectCard__title flex-v-center">
          <div>
            {title.length > 18 ? (<>
              <span>{shortName(title)}</span>
              <Tooltip content={title} position="top">
                <span>...</span>
              </Tooltip></>
            ) : (
              <span>{title}</span>
            )}
          </div>
        </h4>
        <div className="ProjectCard__context-action-menu">
          {actionList.length ?
            <Dropdown
              list={actionList}
              type="click"
              className="rotate-90"
              isEllipse={true}
              closeAfterSelect={true}
            /> :
            <button
              className="ProjectCard__star-button"
              onClick={handleStarClick}
              aria-label={`${isActive ? `Click to unstar ${title} stack` : `Click to star ${title} stack`}`}
            >
              <Tooltip content={isActive ? props.starActiveTooltip : props.starInActiveTooltip} position="top">
                <Icon
                  icon={'Star'}
                  className={`ProjectCard__star-icon ${isActive ? ' ProjectCard__star-icon--active' : ''}`}
                ></Icon>
              </Tooltip>
            </button>
          }
        </div>
      </div>
      <div className="ProjectCard__content">
        <div className="ProjectCard__stats">
          {stats.map((statsUnit, index) => {
            return (
              <div className="ProjectCard__stats-unit" key={index}>
                <span className="ProjectCard__stats-number">{statsUnit.number}</span>
                <h5 className="ProjectCard__stats-category">{statsUnit.category}</h5>
              </div>
            )
          })}
        </div>
      </div>
      <ProjectCardFooter type={type} modified={modified} avatars={avatars} showPlusSignAfter={showPlusSignAfter} />
    </div>
  )
}

ProjectCard.defaultProps = defaultProps

export default ProjectCard

// import React, { useState, useEffect } from 'react';

// import PageLayout from '../PageLayout/PageLayout';
// import PageHeader from '../PageHeader/PageHeader';
import Icon from '../Icon2/Icon';
import SectionHeader from '../SectionHeader/SectionHeader'

// import './LeftNavigation.css';

// type actionProps = {
//   label: string | React.ReactNode
//   onClick?: React.MouseEventHandler
//   type?:
//   | 'primary'
//   | 'secondary'
//   | 'success'
//   | 'danger'
//   | 'warning'
//   | 'muted'
//   | 'light'
//   | 'dark'
//   | 'link'
// };

// type headerDataProps = {
//   title?: string
//   actions?: actionProps[]
//   component?: React.ReactNode
// };

// type navArrayProp = {
//   component: React.ReactNode
//   onclick: Function
//   id: string
// };

// type leftNavigationProps = {
//   navigationComponents: Array<navArrayProp>
//   navigationTitle: string
//   header?: headerDataProps
//   component: React.ReactNode
// };

// export type navigationProps = {
//   navigationProps: leftNavigationProps
//   headerContent?: boolean
// };

// type headerProps = {
//   headerProps: headerDataProps
// };

// type leftNavProps = {
//   setActiveNav: Function
//   navigationProps: leftNavigationProps
//   activeNav?: string | undefined
// };

// type titleProps = {
//   label: string
//   info?: string
// };

// type leftHeaderProps = {
//   title: titleProps
//   actions?: actionProps[]
// };

// export const LeftNavHeader = (props: leftHeaderProps) => {
//   const { title, actions } = props;

//   const handleClick = () => {
// document.querySelector('.LeftSidebar').classList.toggle('LeftSidebar__hide');
// document.querySelector('.Content').classList.toggle('collapse-out');
//   };

//   return (
// <>
//   <div className="PageLayout-PageTitle flex-v-center">
//     <div className="Hamburger" onClick={handleClick}></div>
//     <div className="PageHeaderWrapper w-100">
//       <PageHeader title={title} actions={actions} />
//     </div>
//   </div>
// </>
//   );
// };

// const LeftNav = (props: leftNavProps) => {
//   const [NavIndex, setNavIndex] = useState(NavIndexSetter(props.navigationProps.navigationComponents, props.activeNav))
//   const [prevActiveNav, setPrevActiveNav] = useState(props.activeNav)

//   useEffect(() => {
//     if (props.activeNav !== undefined) {
//       if (prevActiveNav !== props.activeNav) {
//         setNavIndex(NavIndexSetter(props.navigationProps.navigationComponents, props.activeNav));
//         setPrevActiveNav(props.activeNav);
//       };
//     };
//   }, [props.activeNav]);

//   const handleClick = (event: any) => {
//     let id = event.currentTarget.id;
//     setNavIndex(parseInt(event.currentTarget.id));
//     if (props.navigationProps.navigationComponents[event.currentTarget.id].onclick) {
//       props.navigationProps.navigationComponents[event.currentTarget.id].onclick(id);
//     };
//   };

//   return (
//     <>
//       <div className="Navigation__title">{`${props.navigationProps.navigationTitle}`}</div>
//       <div>
//         {props.navigationProps.navigationComponents.map((navElement: any, index: any) => {
//           return (
//             <div
//               id={index}
//               className={`Navigation__item flex-justify ${index === NavIndex ? 'Navigation__item--active' : ''}`}
//               key={index}
//               onClick={handleClick}
//             >
//               <div>{navElement.component}</div>
//               <Icon className="Navigation__item--active__icon" icon="Right" />
//             </div>
//           )
//         })}
//       </div>
//     </>
//   );
// };

// const Header = (props: headerProps) => {
//   return (
//     <>
//       {props.headerProps.component ? (
//         <>{props.headerProps.component}</>
//       ) : (
//           <PageHeader
//             title={{ label: `${props.headerProps.title}` }}
//             actions={props.headerProps.actions}
//           />
//         )}
//     </>
//   );
// };

// const NavIndexSetter = (Navs: Array<navArrayProp>, activeNav: string | undefined) => {
//   let index: any;
//   if (activeNav === undefined) {
//     index = 0;
//   }
//   else {
//     Navs.map((indiNavs: any) => {
//       if (indiNavs.id === activeNav) {
//         index = Navs.indexOf(indiNavs);
//       };
//     });
//   };
//   return index;
// };

// const LeftNavigation = (props: navigationProps) => {
//   const { navigationProps, headerContent } = props;

//   const newActiveNav = (id: any) => {
//     let index: any;
//     navigationProps.navigationComponents.map((value: any) => {
//       if (value.id === id) {
//         index = navigationProps.navigationComponents.indexOf(value)
//       }
//     });
//     if (navigationProps.navigationComponents[index].onclick !== undefined) {
//       navigationProps.navigationComponents[index].onclick()
//     };
//   };

//   const contentLeftNav = {
//     component: <LeftNav setActiveNav={newActiveNav} navigationProps={navigationProps} />,
//   };

//   const contentHeader = {
//     component: <Header headerProps={navigationProps.header} />,
//   };

//   const mainContent = {
//     component: navigationProps.component,
//   };

//   return (
//     <>
//       {headerContent === undefined ? (
// <PageLayout header={contentHeader} content={mainContent} leftSidebar={contentLeftNav} type={'list'} />
//       ) : (
// <PageLayout leftSidebar={contentLeftNav} content={mainContent} type={'list'} />
//         )}
//     </>
//   );
// };

// export default LeftNavigation;


import React from 'react';

// import UiLayout from '../UiLayout';
// import PageHeader from '../PageHeader';
import PageLayout from '../PageLayout/PageLayout';
import PageHeader from '../PageHeader/PageHeader';
// import PageTitle from '../PageTitle';

import './AutomationLeftNavigation.css'
import ListRow from '../ListRow';

interface ActionProps {
  label: string,
  onClick: Function,
  type: string
}

type navArrayProp = {
  component: React.ReactNode
  onclick: Function
  id: string
};

interface HeaderDataProps {
  title: string,
  actions: Array<ActionProps>,
  component : any
}
interface NavArrayProp {
  title: React.ReactNode,
  onclick: Function,
  id: string,
  headerData?: HeaderDataProps,
  component: React.ReactNode
  externalLink?: string
  default?: boolean
  link?: string
}

interface LeftNavigation {
  navigationData: Array<NavArrayProp>,
  navigationTitle: string,
  customDefault?: NavArrayProp
}

interface NavigationProps {
  navigationProps: LeftNavigation
  headerContent?: boolean
  backNavigation?: Function
}

interface headerProps {
  headerProps: NavArrayProp
}

interface LeftNavProps {
  setActiveNav: Function
  navigationProps: LeftNavigation
  icon?: React.ReactNode
  activeNav: NavArrayProp
  backNavigation?: Function
}

interface TitleProps {
  label: string
  info?: string
  component?: React.ReactNode
}

interface LeftHeaderProps {
  title: TitleProps,
  actions?: Array<ActionProps>
  component?: React.ReactNode
}

export const LeftNavHeader: React.FunctionComponent<LeftHeaderProps> = props => {

  const { title, actions, component }: any = props;

  const handleClick = () => {
    // document.querySelector('.PageLayout__content').classList.toggle('LeftSidebar__hide');
    // document.querySelector('.Content').classList.toggle('collapse-out');
    // document.querySelector('.UiLeftPanel-show').classList.toggle('UiLeftPanel-hide');
    // document.querySelector('.UiLayout-Content').classList.toggle('collapse-out')
    const ele: any = document.getElementsByClassName('PageLayout__content');
    document.querySelector('.PageLayout__leftSidebar').classList.toggle('PageLayout__leftSidebar--hide');
    ele[0].classList.toggle('PageLayout__content--full');
  }

  return (
    <>
      {/* <div className="UiLayout-PageTitle flex-v-center">
        <span onClick={handleClick}><Icon icon={'Hamburger'} /></span>
        <div className="Hamburger" onClick={handleClick}></div>
        <div className="PageHeaderWrapper w-100">
          <PageHeader
            title={title}
            actions={actions}
          />
        </div>
      </div> */}
      <>
        {/* <div className="PageLayout-PageTitle flex-v-center">
          <div className="Hamburger" onClick={handleClick}></div>
          <span onClick={handleClick}><Icon icon={'Hamburger'} /></span>
          <div className="PageHeaderWrapper w-100">
            <PageHeader title={title} actions={actions} />
          </div>
        </div> */}

        <div className="PageLayout__head">
          <div className="PageLayout__head__hamburger" onClick={handleClick}><Icon icon="Hamburger" size="original" /></div>
          <PageHeader title={title} actions={actions} component={component} />
        </div>
      </>
    </>
  )
}

const NavIndexSetter = (Navs: Array<navArrayProp>, activeNav: string | undefined) => {
  let index: any;
  if (activeNav === undefined) {
    index = 0;
  }
  else {
    Navs.map((indiNavs: any) => {
      if (indiNavs.id === activeNav) {
        index = Navs.indexOf(indiNavs);
      };
    });
  };
  return index;
};

const LeftNav: React.FunctionComponent<LeftNavProps> = props => {
  const handleClick = (event: any) => {
    props.setActiveNav(event.currentTarget.id)
  }

  return (
    <>
      <div className="Navigation__header">
        <div className="Navigation__icon">{props.backNavigation && <Icon onClick={(event: any) => props.backNavigation(event)} icon='BackArrow' size='original' />}</div>
        <SectionHeader content={<>{`${props.navigationProps.navigationTitle}`}</>} />
      </div>
      <div className="Navigation__list">
        {props.navigationProps.navigationData.map((navElement: any, index: any) => {
          return (
            <ListRow id={navElement.id} active={props.activeNav.id === navElement.id} onClick={handleClick} linkToRow={navElement.link} key={index} rightArrow={true} content={navElement.title} leftIcon={navElement.icon} />
          )
        })}
      </div>
      {/* <div className="nav-heading">{`${props.navigationProps.navigationTitle}`}</div>
      <div className="menu-wrapper">
        {props.navigationProps.navigationData.map((navElement: any) => {
          return (
            <div onClick={handleClick} id={navElement.id}
              key={navElement.id}
              className={`menu-item ${navElement.id} ${props.activeNav.id === navElement.id ? 'active-menu' : 'inactive-menu'
                }`}
            >
              <div className="menu-name">{navElement.title}</div>
              {navElement.externalLink ? <img src={navElement.externalLink} className="api-docs-icon"></img> : null}
            </div>
          )
        })}
      </div> */}
    </>
  )
}

const Header: React.FunctionComponent<headerProps> = props => {

  const headerActions: any = props.headerProps.headerData.actions;

  return (
    <>
      <PageHeader title={{ label: `${props.headerProps.headerData.title}` }} component={props.headerProps.headerData.component} actions={headerActions} />
    </>
  )
}

const AutomationLeftNavigation: React.FunctionComponent<NavigationProps> = props => {

  const {
    navigationProps,
    backNavigation
  } = props;

  const checkDefault = () => {
    const defaultData = navigationProps.customDefault
      ? navigationProps.customDefault : navigationProps.navigationData[0];
    let active = navigationProps.navigationData.filter(data => data.default !== undefined && data.default);
    return active.length > 0
      ? active[0] : defaultData
  }

  const [activeNav, setActiveNav] = React.useState(checkDefault());

  const newActiveNav = (id: any) => {

    let index: any;
    navigationProps.navigationData.map((value: any) => {
      if (value.id === id) {
        index = navigationProps.navigationData.indexOf(value);
      }
    });
    if (navigationProps.navigationData[index].onclick !== undefined) {
      navigationProps.navigationData[index].onclick()
    };
    setActiveNav(navigationProps.navigationData[index])
  }

  const contentLeftNav = {
    component: <LeftNav setActiveNav={newActiveNav} activeNav={activeNav} navigationProps={navigationProps} backNavigation={backNavigation} />
  }

  const contentHeader = {
    component: <Header headerProps={activeNav} />
  }

  const mainContent = {
    component: activeNav.component
  }

  return (
    <>
      {props.headerContent === undefined ?
        // <PageLayout header={contentHeader} leftNav={contentLeftNav} mainContent={mainContent} type={'list'} />
        <PageLayout header={contentHeader} content={mainContent} leftSidebar={contentLeftNav} type={'list'} />
        :
        // <PageLayout leftNav={contentLeftNav} headerContent={false} mainContent={mainContent} type={'list'} />
        <PageLayout leftSidebar={contentLeftNav} content={mainContent} type={'list'} />
      }
    </>
  );
};

export default AutomationLeftNavigation;

import { cx } from '@emotion/css'
import { Button, Notification } from '../../../../../index'
import React, { useState } from 'react'
import { IEditorRequestProps, IEntryMetadata } from '../../utils/types'
import DiscussionHeaderLoader from './loader/DiscussionHeader'

import styles from './style.module.css'
import { checkIsRangeComment, getDiscussionTitle, parseDiscussionUID } from './utils'
import { failureNotification, resolveDiscussions, updateJsonRteDoc } from './utils/request'
import { Editor, Transforms } from 'slate'
import { useSlateStatic } from 'slate-react'
import { editorHasOnlyCommentChanges } from '../../elements/Leaf/rangeComment/helpers'
import { DiscussionProvider } from '../ContextProvider'

interface IDiscussionHeader {
  editor: any,
  commentCount: number,
  discussionUID: string,
  setActiveDiscussion: Function,
  requestProps: IEditorRequestProps,
  entryMetadata: IEntryMetadata,
  isLoading: boolean,
  displayResolve: boolean,
  blockUID: string,
  removeDiscussion: (blockUID: string) => void
}
const DiscussionHeader: React.FC<IDiscussionHeader> = ({ editor, commentCount, discussionUID, displayResolve, entryMetadata, isLoading, requestProps, setActiveDiscussion, removeDiscussion, blockUID }) => {
  const [isResolving, setResolving] = useState(false)
  const { discussions } = React.useContext(DiscussionProvider)
  const handleResolveDiscussion = () => {
    setResolving(true)
    resolveDiscussions(requestProps, discussionUID, entryMetadata.contentTypeUid, entryMetadata.entryUid, entryMetadata.locale)
      .then((data) => {
        
        if (checkIsRangeComment(discussionUID)) {
          Transforms.select(editor, [0])
          Editor.removeMark(editor, `discussionThread_${parseDiscussionUID(discussionUID)}`)
          Transforms.deselect(editor)
          if (editorHasOnlyCommentChanges(editor?.['mockOperations'])) {
            let rteDoc = editor.children[0]
            updateJsonRteDoc(requestProps, entryMetadata, { field: discussions[discussionUID].field }, rteDoc)
          }
        }
        Notification({
          displayContent: { text: data.notice },
          notifyProps: { hideProgressBar: true },
          type: 'success',
        })
        setActiveDiscussion(null)
        removeDiscussion(blockUID)
      })
      .catch((error) => {
        failureNotification(error?.data?.error_message || "Error while resolving discussions.", error?.data?.errors)
      })
      .finally(() => {
        setResolving(false)
      })
  }
  return (
    <div className={cx(styles['scrte-discussion-header--wrapper'], 'flex-v-center')}>
      {
        isLoading ?
          <DiscussionHeaderLoader />
          :
          <div className={cx(styles['scrte-discussion-header--container'], 'flex-v-center')}>
            <div className={styles['scrte-discussion-header--title']}>
              {getDiscussionTitle(commentCount)}
            </div>
            {
              displayResolve
              &&
              <Button
                buttonType="secondary"
                className={styles['scrte-discussion-header--resolve']}
                icon="RightMarkActive"
                size='small'
                iconProps={{ className: styles['scrte-discussion-header--resolve--icon'] }}
                isLoading={isResolving}
                loadingColor={'#6c5ce7'}
                onClick={handleResolveDiscussion}
                testId={'discussion-resolve-btn'}
              >
                <span className={styles['scrte-discussion-header--resolve--text']}>Resolve</span>
              </Button>
            }

          </div>
      }
    </div>
  )
}

export default DiscussionHeader
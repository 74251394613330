import { camelCase, cloneDeep, isEmpty } from "lodash"

export const stringToStyleObject = (styleString: string) => {
    let styles = {}
    if (styleString && typeof styleString === 'string') {
        Array.from(styleString.split(';')).forEach((attr: any) => {
            let [property, value] = attr?.split?.(':')?.map?.((val) => val.trim())
            if (property && value) styles[property] = value
        })
    }
    return styles
}

export const attrsProvider = (element) => {
    let elementAttrs = {}
    elementAttrs["data-editor-type"] = "scrte"

    if (element.attrs) {

        if (element.attrs['id']) {
            elementAttrs = {
                ...elementAttrs,
                id: element.attrs['id']
            }
        }
        elementAttrs['className'] = elementAttrs['class-name']
        delete elementAttrs['class-name']
        elementAttrs['className'] = `scrte_${element.type} ${elementAttrs['className'] ? element.attrs['className'].join('') : ''}`
        if (element.attrs['style']) {
            let style = element.attrs['style']
            const styleString = Object.entries(style).filter(([key, value]) => {
                //@ts-ignore
                return !isNaN(key)
            }).map(([key, value]) => value).join("")

            let attrsMap = {}
            if (typeof element.attrs['style'] === 'string' || styleString) {
                const styles = styleString ? styleString : element.attrs['style']
                attrsMap = { ...stringToStyleObject(styles) }
            }
            let camelCaseStyle = {}
            let styleCopy = !isEmpty(attrsMap) ? cloneDeep(attrsMap) : cloneDeep(element.attrs['style'])
            Object.entries(styleCopy).forEach(([key, val]) => {
                camelCaseStyle[camelCase(key)] = val
            })
            elementAttrs = {
                ...elementAttrs,
                style: { ...camelCaseStyle }
            }
        }

    }
    let result = { ...element.attrs, ...elementAttrs }
    delete result.dirty
    delete result["redactor-attributes"]
    return result
}

import React from 'react'
import { TimelineContext } from './Timeline'
import cn from 'classnames'

export type TimelineRightProp = {
  style?: React.CSSProperties
  className?: string
  onClick?: any
  title?: string
  description?: string
}

const TimelineRight: React.FC<TimelineRightProp> = (props) => {
  const { children, className, style, onClick, title, description } = props
  const { showOnHover } = React.useContext(TimelineContext)
  const classNames = cn(showOnHover && 'TimelineRight--NotVisible', 'TimelineRight', className)

  let click = onClick ? onClick : () => {}

  return (
    <div style={{ ...style }} className={classNames} onClick={click}>
      {children ? (
        <div>{children}</div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 10px' }}>
          <span style={{ fontSize: '15px' }}>{title}</span>
          <span style={{ fontSize: '10px' }}>{description}</span>
        </div>
      )}
    </div>
  )
}
export default TimelineRight

import React, { useState, useEffect } from 'react'
import { defaultTimezone } from '../DateTime/helper'
import DSTTimeZoneList from '../DateTime/TimeZoneList'
import { ITimeZoneStandard } from '../DateTime/interfaces'
import Dropdown from '../DropDown/Dropdown'

const TimeZoneStandard: React.FunctionComponent<ITimeZoneStandard> = (props) => {
  const { zone, onChange, dstZone, disableTzDropdown } = props
  const propsZone = dstZone

  let DSTTZList = DSTTimeZoneList()
  let defaultTZ = zone ? defaultTimezone(zone) : defaultTimezone('')
  let zoneObj: any

  if (dstZone) {
    let idx = DSTTZList.findIndex((singleTZObj) => singleTZObj.value === dstZone)
    if (typeof idx !== 'undefined' && DSTTZList[idx]) {
      DSTTZList[idx].default = true
    }
    zoneObj = DSTTZList[idx]
  } else {
    let idx = DSTTZList.findIndex((singleTZObj) => singleTZObj.value === defaultTZ)
    if (typeof idx !== 'undefined' && DSTTZList[idx]) {
      DSTTZList[idx].default = true
    }
    zoneObj = DSTTZList[idx]
  }
  const [currZoneObj, setCurrZoneObj] = useState(zoneObj)

  const zoneHandler = (value: any) => {
    let valObj = { ...value }
    if (!valObj) {
      valObj = {}
    }
    let val: any = valObj.value ? valObj.value : ''
    onChange(val, valObj)
  }

  useEffect(() => {
    if (dstZone) {
      let idx = DSTTZList.findIndex((singleTZObj) => singleTZObj.value === dstZone)
      if (typeof idx !== 'undefined' && DSTTZList[idx]) {
        DSTTZList[idx].default = true
      }
      setCurrZoneObj(DSTTZList[idx])
      zoneHandler({ ...DSTTZList[idx] })
    } else {
      let idx = DSTTZList.findIndex((singleTZObj) => singleTZObj.value === defaultTZ)
      if (typeof idx !== 'undefined' && DSTTZList[idx]) {
        DSTTZList[idx].default = true
      }
      setCurrZoneObj(DSTTZList[idx])
      zoneHandler({ ...DSTTZList[idx] })
    }
  }, [propsZone])

  return (
    <div>
      <Dropdown
        list={DSTTZList}
        type="select"
        viewAs="label"
        title="Choose Time Zone"
        searchPlaceholder="search time zone"
        dropDownType="tertiary"
        withSearch={true}
        onChange={zoneHandler}
        closeAfterSelect={true}
        arrowSecondary={true}
        isDisabled={disableTzDropdown}
      />
    </div>
  )
}

export default TimeZoneStandard

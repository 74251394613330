import { ReactEditor } from "slate-react";

// This function is use to auto scroll to the particular cell through the selection inside the editor
export const scrollToCell = ({editor, scrollToPoint}) => {
  try {
    const domPoint = ReactEditor.toDOMPoint(
      editor,
      editor.selection.focus
    )
    const node = domPoint[0]
    if (node == null) return
    const element = node.parentElement;
    if (element == null) return
    const parentCellElement = element.closest('div[data-type="td"]');
    parentCellElement.scrollIntoView({ behavior: "smooth", block: scrollToPoint, inline: scrollToPoint })
  } catch (error) {
    console.error("Failed to auto scroll to the table cell");
  }
}
import * as React from 'react'
import 'systemjs/dist/system.js'
import 'systemjs/dist/extras/named-register.js'
import { setupSystemRegister, loadDropdownPlugin } from './helper'
import withRTE, { RTE } from '../../../lib/rteSDK'
import { clone } from 'lodash'
import { getConfigForApp } from 'components/JsonRTE/lib/requestModule'

const { useEffect } = React
interface IScrollToDiscussionDTO {
  data: {
    blockUID: string
    discussionUID: string
  }
}
export const useRtePlugins = ({ plugins, setPlugins, editor, editorRef }) => {
  useEffect(() => {
    if (!plugins) return

    // LOAD SYSTEM JS ENV
    const system = setupSystemRegister()
    console.debug('system', system, plugins)
    setTimeout(() => {
      const promises = []
      const finalPlugins = []
      const _rte = RTE.init({ editor, editorRef })
      plugins.forEach((plugin, idx) => {
        const extension = editor['requestProps']['extensions']?.[idx] || {}
        const pluginURL = new URL(plugin)
        if (extension['$$appToken']) {
          pluginURL.searchParams.append('app-token', extension['$$appToken'])
        }
        promises.push(
          system
            .import(pluginURL.toString())
            .then(async (output) => {
              // PLUGINS CODE HERE
              const _plugins = await output.default
              console.log('output', _plugins, output)
              const rte = clone(_rte)
              // load config
              rte.getConfig = () => {
                if (extension['app_installation_uid']) {
                  return getConfigForApp(extension)
                    .then((res) => res.data)
                    .catch((err) => err)
                }
                return extension?.['config'] || {}
              }
              
              // load field config
              rte.getFieldConfig = () => {
                return extension?.['config'] || {}
              }

              // load signed token
              rte.signedAppToken = extension['$$appToken']

              const defaultEventProps = {
                app_uid: extension.app_uid,
                installation_uid: extension.app_installation_uid,
                extension_uid: extension.uid,
                location_type: extension.type,
                manifest_version: '',
                visibility: ''
              }

              // ? Trigger Plugin load heap event
              if (window.postRobot) {
                window.postRobot.sendToParent('analytics', { eventName: 'Viewed - App', metadata: defaultEventProps })
              }

              await Promise.all(
                Object.entries(_plugins).map(async ([key, value]: any) => {
                  const isDropdown = value.isContainer
                  if (isDropdown && value.containerMetaData) {
                    await loadDropdownPlugin({
                      key,
                      value,
                      rte
                    })
                  } else if (typeof value.get === 'function') {
                    withRTE({
                      plugin: await value.get(rte),
                      rte,
                      key
                    })
                  }
                })
              )

              finalPlugins.push(plugin)
            })
            .catch((err) => console.error('Failed to load plugin', err))
        )
      })

      // ONCE ALL PLUGINS ARE LOADED
      Promise.all(promises).then(() => {
        setPlugins(finalPlugins)
        // handle listeners
        if (window.postRobot) {
          window.postRobot.on('scrollToDiscussion', function (data: IScrollToDiscussionDTO) {
            const scrollField: HTMLDivElement = document.querySelector(
              `[data-discussion-uid~='${data?.data?.discussionUID || ''}']`
            )
            if (!scrollField) return
            scrollField.scrollIntoView({
              block: 'center'
            })
            setTimeout(() => {
              scrollField.click()
            }, 100)
          })
        }
      })
    }, 100)
  }, [plugins])
}

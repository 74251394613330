import React from 'react'

import { Registry } from '@react-registry'
import { getRegistry } from '../../utils/getRegistry'
import { isDiscussionThread, isDiscussionThreadActive } from './rangeComment/helpers'
import { useSlateStatic } from 'slate-react'

const leafElements = Object.entries(getRegistry('v2.leaf')).map(([key, value]) => {
  return key
})

export default (props) => {
  let { attributes, children, leaf, discussionUtils } = props
 const {enabled: commentsEnabled} = discussionUtils

  const { text, classname, id, ...rest } = leaf
  const editor = useSlateStatic()
  const extraProps = {
    className: `token ${Object.keys(rest).filter(mark => leaf[mark]).join(' ')}`
  }

  leafElements.find((leaf) => {
    if (rest[leaf]) {
      delete rest[leaf]
    }
  })

  if (classname) extraProps['className'] = `${extraProps['className']} ${classname}`
  if (id) extraProps['id'] = id
  if (leaf.createCommentHighlight) extraProps['style'] = { background: '#BADEFE' }

  if (children.props.text.attrs && children.props.text.attrs.style) {
    children = (
      <span {...attributes} data-editor-type="scrte" {...extraProps}>
        {children}
      </span>
    )
  }

  Object.entries(leaf).forEach((el) => {
    let leafType = el[0]
    if (isDiscussionThread(leafType) && commentsEnabled && isDiscussionThreadActive(leafType, discussionUtils.discussions)) {
      leafType = 'rangeComment'
    }
    if (el[1] === false) return
    const val = Registry.get({
      id: leafType,
      registry: 'v2.leaf',
      conditions: {
        overide: true
      }
    })
    if (val) {
      const { Component }: any = val
      if (!Component) return
      children = <Component {...props}>{children}</Component>
    }
  })

  return (
    <span {...attributes} data-editor-type="scrte" {...extraProps}>
      {children}
    </span>
  )
}

export * from './bold'

export * from './italic'

export * from './underline'

export * from './mark'

export * from './strikethrough'

export * from './inlineCode'

export * from './superscript'

export * from './subscript'

export * from './removeFormatting'

export * from './rangeComment'

import React, { useCallback } from 'react'
import { BaseEditor, Path } from 'slate'
import { IDiscussionProps, IElement } from '../../utils/types'
import DiscussionIcon from './DiscussionIcon'
import { isCommentsAllowedForElement } from './utils'

export const DiscussionHOC = (RegistryElement, discussion: IDiscussionProps, editor: BaseEditor, element: IElement, path: Path) => useCallback((props) => {
  const isCommentsAllowedForBlock = isCommentsAllowedForElement(editor, element, path)
  const isCommentsEnabled = discussion?.comments?.enabled && isCommentsAllowedForBlock
  if (!isCommentsEnabled) {
    return (<><RegistryElement {...props} /></>)
  }
  return (<div data-testid='scrte-discussion--wrapper' className='scrte-discussion--wrapper'><RegistryElement {...props} /><DiscussionIcon element={element} discussion={discussion} elementPath={path} editor={editor} /></div>)
}, [RegistryElement, discussion?.discussions, discussion?.comments?.enabled])

import React, { CSSProperties, ReactNode } from 'react'
import cn from 'classnames'
import './Link.css'
import { isEnterPressed } from 'utils/keyboardUtils'
import Icon, { IconProps } from '../../Icon2'

export type LinkProps = {
  children: ReactNode
  /**
   * Specify the URL where the text is to be linked.
   */
  href: string
  /**
   *  Pass the function to be triggered when the link is clicked on.
   */
  cbOnClick?: Function
  /**
   * Set the type of link to “external” when you want the icon and text to be made clickable
   */
  type?: 'external'
  /**
   * Specify where to display the end response. It can be set to “_blank,” “_self,” “_parent,” or “_top”.
   */
  target?: '_blank' | '_self' | '_parent' | '_top'
  /**
   * Make the link text bolder. You can set it to “bold,” “regular,” or “semi-bold”.
   */
  fontWeight?: 'bold' | 'regular' | 'semi-bold'
  /**
   * Set the font-size of link text
   */
  fontSize?: 'tiny' | 'small' | 'medium' | 'large' | 'regular' | 'max'
  /**
   * Add styles to the component.
   */
  style?: CSSProperties
  /**
   * Add a custom icon, set this prop to the name of the icon.
   */
  iconName?: string
  /**
   *  Pass the class names to be appended to this prop.
   */
  className?: string
  /**
   * Display an underline below the hyperlink, set this prop to “true”, else set it to “false”.
   */
  underline?: boolean
  /**
   * Change the underline style of the link to either “dashed” or “solid”.
   */
  underLineType?: 'dashed' | 'solid'
  /**
   * Change the color of the link text. You can set it to ”link”, “white” or "basic".
   */
  fontColor: 'white' | 'link' | 'basic'
  /**
   * Pass an ID that you can use for testing purposes.
   */
  testId?: string
  /**
   * Pass additional props for icon
   */
  iconProps?: Partial<IconProps>
}

const Link = (props: LinkProps) => {
  const {
    children,
    className,
    href,
    type,
    fontSize,
    underline,
    iconName,
    underLineType,
    fontWeight,
    fontColor,
    cbOnClick,
    testId,
    iconProps = {},
    ...rest
  } = props
  const isExternal = type === 'external'
  const target = props.target ? props.target : '_blank'
  const fontSizeType = fontSize === 'regular' ? 'xl' : fontSize
  const classNames = cn('Link', className, {
    ExternalLink: isExternal,
    [`Link--${fontWeight}`]: fontWeight,
    [`Link--${fontSizeType}`]: fontSizeType,
    [`Link--${fontColor}`]: fontColor
  })

  const handleOnClick = () => {
    if (cbOnClick) {
      cbOnClick()
    }
  }

  const handleKeyUp = (e) => {
    if (isEnterPressed(e.key)) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (cbOnClick) {
      cbOnClick()
    }
  }

  return (
    <div className={classNames} onClick={handleOnClick} data-test-id={testId} {...rest}>
      <a tabIndex={0} onKeyUp={handleKeyUp} href={href} target={target} className="flex-v-center">
        <span className={`${underline ? `Link__${underLineType}` : ''}`}>{children}</span>
        {isExternal && <Icon className="ml-8" icon={iconName}  {...iconProps} />}
      </a>
    </div>
  )
}

Link.defaultProps = {
  iconName: 'NewTab',
  underLineType: 'solid',
  target: '_blank',
  underline: false,
  fontWeight: 'regular',
  fontColor: 'link',
  fontSize: 'large',
  testId: 'cs-link'
} as Partial<LinkProps>

export default Link
import { isInlineElement } from "../../../utils/queries"
import { getNormalTextElement } from "../../Element/paragraph/utils"
import { jsx } from 'slate-hyperscript'


const doesFragmentHaveOnlyTr = (fragment) => {
  if(!Array.isArray(fragment) || !fragment.length) return false
  return fragment.every(child => child.type === 'tr')
}
export const preInsert = (editor, fragment) => {
  if (doesFragmentHaveOnlyTr(fragment)) {
    const rows = fragment?.length
    const cols = fragment?.[0]?.children?.length ?? 1
    const colWidths = Array(cols).fill(250)
    
    const tbody = jsx('element', { type: 'tbody' }, fragment)
    const table = jsx('element', { type: 'table', attrs: { rows, cols, colWidths } }, tbody)

    fragment = [table]
  }
  const firstNodeType = fragment[0]?.type || undefined
  if (firstNodeType && !isInlineElement(fragment[0]) && firstNodeType !== 'fragment' && firstNodeType !== 'p') {
    fragment.unshift(getNormalTextElement())
    fragment.push(getNormalTextElement());
  }
  return fragment
}
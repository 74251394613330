import React from 'react'
import { Registry } from '@react-registry'
import { ReactEditor, useSlateStatic } from 'slate-react'
import { Editor } from 'slate'
import styles from './style.module.css'

const FRAGMENT = ({ attributes, children, ...props }) => {
  const editor = useSlateStatic() as ReactEditor
  const path = ReactEditor.findPath(editor, props.element)
  const [parent] = Editor.parent(editor, path)
  const isParentLi = parent?.['type'] === 'li'
  const className = isParentLi ? styles['display-block'] : styles['display-inline']

  return <span className={className}>{children}</span>
}

export default FRAGMENT
Registry.register(
  {
    Component: FRAGMENT,
    isContentStackElement: true,
    dndOptions: {
      DisableDND: true,
      DisableSelectionHalo: true
    }
  },
  { id: 'fragment', registry: 'element' }
)

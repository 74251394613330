import React, { CSSProperties, useRef } from 'react'
import './Info.css'
import cn from 'classnames'
import Icon from '../Icon2/Icon'
import Tooltip from '../Tooltip/Tooltip'
import Button from '../Button/Button'
import { isEnterOrSpacePressed } from 'utils/keyboardUtils'

type InfoType = 'light' | 'dark' | 'success' | 'warning' | 'attention'

interface InfoProps {
  className?: string
  icon?: React.ReactNode
  content: string | React.ReactNode
  type?: InfoType
  borderColor?: string
  backgroundColor?: string
  dismissable?: boolean,
  width?: number | string | undefined,
  leftSpacing?,
  rightSpacing?,
  bottomSpacing?
  topSpacing?
  enableHover?: boolean | undefined,
  style?: CSSProperties,
  contentStyles?: CSSProperties,
  iconStyles?: CSSProperties,
  dismissStyles?: CSSProperties,
  contentClassName?: string,
  iconClassName?: string,
  dismissClassName?: string,
  testId?: string,
  tabIndex?: number
  version?: "v2"
}

const InfoDismissiable = ({ contentClass, contentStyles, dismissable, content, version, dismissClass, dismissStyles, onDismissClick, tabIndex, handleDismissKeyUp }) => {
  return (
    <>
      <div className={contentClass} style={contentStyles}>{content}</div>

      {dismissable && (
        version !== 'v2' ?
          <div className={dismissClass} style={dismissStyles} onClick={onDismissClick} role='button' onKeyUp={tabIndex > -1 && handleDismissKeyUp} tabIndex={tabIndex}>
            <Tooltip content="Dismiss" position="top" showArrow={false} interactive={false}>
              <Icon icon="Cancel" />
            </Tooltip>
          </div> :
          <Button className="ml-4" onlyIcon={true} version="v2" iconProps={{
            withTooltip: true,
            tooltipContent: "Dismiss",
            tooltipPosition: "top"
          }} onClick={onDismissClick} onKeyUp={tabIndex > -1 && handleDismissKeyUp} icon="v2-CloseNoborder" onlyIconHoverColor="primary" />
      )}
    </>
  )
}

const Info = (props: InfoProps) => {
  const {
    icon,
    content,
    className,
    type,
    backgroundColor,
    borderColor,
    dismissable,
    width,
    leftSpacing,
    rightSpacing,
    bottomSpacing,
    topSpacing,
    enableHover,
    style = {},
    contentStyles = {},
    iconStyles = {},
    dismissStyles = {},
    contentClassName,
    iconClassName,
    dismissClassName,
    testId,
    version,
    tabIndex
  } = props

  let customStyle: CSSProperties = {}
  const infoEl = useRef(null);

  if (backgroundColor) customStyle.backgroundColor = backgroundColor;
  if (borderColor) customStyle.borderColor = borderColor;
  if (width) customStyle.width = width;
  if (leftSpacing) customStyle.marginLeft = leftSpacing;
  if (rightSpacing) customStyle.marginRight = rightSpacing;
  if (bottomSpacing) customStyle.marginBottom = bottomSpacing;
  if (topSpacing) customStyle.marginTop = topSpacing;

  customStyle = Object.assign(customStyle, style);

  const onDismissClick = () => {
    infoEl.current.remove();
  };

  const handleDismissKeyUp = (e: any): any => {
    if (isEnterOrSpacePressed(e.key)) {
      onDismissClick();
    }
  }

  const infoClass = cn('Info', [className], { [`Info--${type}`]: type, [`Info--v2`]: version === "v2" })
  const dismissClass = cn('Info__dismiss', [type], { 'hoverable__dismiss': enableHover, }, [dismissClassName])
  const contentClass = cn('Info__content', [type], [contentClassName])
  const iconClass = cn('Info__icon', [iconClassName], { [`Info__icon--${type}`]: type, }, 'flex-h-center', 'flex-v-center')

  return (
    <div
      className={infoClass}
      style={customStyle}
      ref={infoEl}
      data-test-id={testId}
    >
      {icon && <div className={iconClass} style={iconStyles}>{icon}</div>}
      {version === "v2" && <div className="Info__border"></div>}
      {version === "v2" ?
        <div className='flex-v-center flex-h-center'>
          <InfoDismissiable
            contentClass={contentClass}
            contentStyles={contentStyles}
            dismissable={dismissable}
            content={content}
            version={version}
            dismissClass={dismissClass}
            dismissStyles={dismissStyles}
            onDismissClick={onDismissClick}
            tabIndex={tabIndex}
            handleDismissKeyUp={handleDismissKeyUp}
          />
        </div> :
        <InfoDismissiable
          contentClass={contentClass}
          contentStyles={contentStyles}
          dismissable={dismissable}
          content={content}
          version={version}
          dismissClass={dismissClass}
          dismissStyles={dismissStyles}
          onDismissClick={onDismissClick}
          tabIndex={tabIndex}
          handleDismissKeyUp={handleDismissKeyUp}
        />
      }
    </div>
  )
}

Info.defaultProps = {
  testId: 'cs-Info',
  tabIndex: -1
} as Partial<InfoProps>

export default Info

import { Transforms, Editor, Node, Text, Range } from 'slate'
import { ElementWithType } from '../../../../../../utils/types'

import { LIST_TYPES } from '../../../elements/Element/list/utils'
import { isPathInRectangle } from '../../../elements'

export const toggleBlock = (editor, format) => {
  let isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)
  removeStyle(editor);
  Transforms.unwrapNodes(editor, {
    match: (n: any) => {
      return LIST_TYPES.includes(n.type)
    },
    split: true,
    mode: 'lowest',
  })
  if (isActive && isList) {
    isActive = false
  }

  let type;
  if (isActive) {
    type = 'p'
  } else if (isList) {
    type = 'li'
  } else {
    type = format
  }
  Transforms.setNodes(editor, {
    type: type,
  } as Partial<ElementWithType>)
  if (!isActive && format === 'check-list') {
    Transforms.setNodes(editor, { checked: false } as Partial<ElementWithType>)
  }
  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n: ElementWithType) => n.type === format,
  })
  return !!match
}

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  const cellEntries = getCellsInRectangle(editor)

  if (cellEntries) {
    for (let [, path] of cellEntries) {
      for (let [, textPath] of Node.texts(editor, { from: path, to: path })) {
        isActive
          ? Transforms.unsetNodes(editor, format, { at: textPath })
          : Transforms.setNodes(editor, { [format]: true }, { at: textPath })
      }
    }
    return
  }

  isActive ? Editor.removeMark(editor, format) : Editor.addMark(editor, format, true)
}

export const isMarkActive = (editor, format) => {
  try {
    const cellEntries = getCellsInRectangle(editor)
    if (cellEntries) {
      return Array.from(cellEntries).every(([, path]) => {
        return Array.from(Node.texts(editor, { from: path, to: path })).every(([textNode]) => {
          const { text, ...marks } = textNode
          return marks ? marks[format] === true : false
        })
      })
    }

    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  } catch (err) {
    if (err instanceof Error) {
      err.message = 'While getting marks: ' + err.message
      console.error(err, JSON.stringify({ selection: editor.selection, children: editor.children }))
    }
  }
}

function removeStyle(editor) {
  const matches = Array.from(Editor.nodes(editor, {
    match: (node, path) => {
      const hasStyle = !!(!Editor.isEditor(node) && !Text.isText(node) && node?.['attrs']?.['style']);
      return hasStyle;
    }
  }));
  matches.reverse().forEach(([node, path]) => {
    const attrs = Object.assign({}, node['attrs']);
    delete attrs['style'];
    Transforms.setNodes(editor, { attrs } as any, {
      at: path
    })
  })
}

function getCellsInRectangle(editor) {
  if(!editor.selection) return 
  const [table] = Editor.nodes(editor, { at: editor.selection, match: (n) => n['type'] === 'table' })
  if (table) {
    const tablePath = table[1]
    const tableRange = Editor.range(editor, tablePath)

    const isSelectionOnlyInTable =
      Range.includes(tableRange, Range.start(editor.selection)) &&
      Range.includes(tableRange, Range.end(editor.selection))

    if (isSelectionOnlyInTable) {
      const nodeEntries = Editor.nodes(editor, {
        match: (node, path) =>
          node['type'] === 'th' || (node['type'] === 'td' && isPathInRectangle(editor, path, tablePath))
      })

      return nodeEntries
    }
  }
}

import Checkbox from '../Checkbox/Checkbox'
import SkeletonTile from '../SkeletonTile/SkeletonTile'
import Search from '../Search/Search'
import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import Dropdown from '../DropDown/Dropdown'
import Icon from '../Icon2/Icon'
import Button from '../Button/Button'

import {
  reorderList,
  filterColumnData,
  compareColumn,
  move,
  MAX_FREEZE_COUNT,
  getTableCount,
  getHeaderValue
} from './util'

const InfoComponent = () => {
  return (
    <div
      onClick={(event) => {
        event.stopPropagation()
      }}
      className="TablePanel__info_container">
      <div className="mr-10">
        <Icon icon="Warning" size="small" />
      </div>
      <div className="TablePanel__info_text">At least two columns must be visible</div>
    </div>
  )
}

const Selector = ({ column, isLock = false, allVisibleColumn, canFreezeColumn, freezedUnfreezeColumn }) => {
  const toggleHiddenProps = { ...column.getToggleHiddenProps() }
  return (
    <div className="selector">
      <Checkbox
        text={typeof column.Header === 'function' ? <column.Header /> : column.Header}
        {...toggleHiddenProps}
        disabled={column.default || isLock || (allVisibleColumn.length <= 2 && allVisibleColumn.includes(column.id))}
        testId={'tabel-column-selector-checkbox'}
        aria-label={`${getHeaderValue(column.Header)} column ${toggleHiddenProps.checked ? 'visible' : 'hidden'}`}
      />
      {canFreezeColumn && (
        <span
          aria-label={`${isLock ? 'unfreeze' : 'freeze'} ${getHeaderValue(column.Header)} column`}
          data-test-id={'tabel-column-freeze-unfreeze-icon'}
          className={`selector-icon ${column.isVisible ? '' : 'disabled'} ${!isLock ? 'unfreeze' : 'freeze'}`}
          onClick={() => freezedUnfreezeColumn(column)}
          tabIndex={toggleHiddenProps.checked ? 0 : -1}>
          <Icon
            withTooltip={true}
            tooltipContent={isLock ? 'Unfreeze' : 'Freeze'}
            tooltipPosition="top"
            version='v2'
            size='medium'
            icon={!isLock ? 'Freezed' : 'Unfreezed'}
          />
        </span>
      )}
    </div>
  )
}

const TableSetting = ({
  isDisabled,
  isOpenAddColumn,
  setIsOpenAddColumn,
  primaryDropdownList
}) => {

  const [actionSelected, setActionSelected] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)
  const [isCursorInside, setIsCursorInside] = useState(false)

  let tablePanelValueRef: any = useRef({})
  tablePanelValueRef.current.isCursorInside = isCursorInside
  tablePanelValueRef.current.showDropdown = showDropdown

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('click', handleClickOutside)
      document.addEventListener('closeTableSettingDropdown', closeDropdown)
      document.addEventListener('keyup', registerKeybindings, false)

      return () => {
        document.removeEventListener('click', handleClickOutside)
        document.removeEventListener('closeTableSettingDropdown', closeDropdown)
        document.removeEventListener('keyup', registerKeybindings, false)
      }
    }
  }, [showDropdown])

  const closeDropdown = () => {
    setShowDropdown(false)
    setActionSelected('')
  }
  useEffect(() => {
    if (isOpenAddColumn) {
      setIsOpenAddColumn(false)
      setShowDropdown(true)
      setActionSelected('manage_columns')
    }
  }, [isOpenAddColumn])

  const handleClickOutside = () => {
    if (tablePanelValueRef.current.showDropdown && !tablePanelValueRef.current.isCursorInside) {
      setShowDropdown(false)
      setActionSelected('')
    }
  }

  const registerKeybindings = (e) => {
    if (e.key === 'Escape') {
      closeDropdown()
    }
  }

  return (
    <div
      className={`${isDisabled ? 'settings-wrapper-container-disabled' : ''}`}
      onMouseEnter={() => {
        setIsCursorInside(true)
      }}
      onMouseLeave={() => {
        setIsCursorInside(false)
      }}>
      {primaryDropdownList.length > 0 && (
        <Button
          aria-expanded={showDropdown ? 'true' : 'false'}
          aria-label="Table Settings"
          role="menu"
          className={`settings-wrapper`}
          disabled={isDisabled}
          testId="settings-wrapper"
          onClick={(e) => {
            if (e.type === 'click' && e.pageX !== 0 && e.pageY !== 0) {
              e.preventDefault()
              e.stopPropagation()
              setShowDropdown(!showDropdown)
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setShowDropdown(!showDropdown)
              return
            }
            if (e.key === 'Escape' && showDropdown) {
              closeDropdown()
            }
          }}
          size="regular"
          buttonType="tertiary"
          onlyIcon={true}
          icon={'v2-Setting'}
          version="v2"
          onlyIconHoverColor='secondary'
          onlyIconActive={showDropdown}
          iconProps={{
            withTooltip: true,
            tooltipContent: "Table Settings",
            tooltipPosition: "left"
          }}
        />
      )}
      {showDropdown && (
        <div className="TablePanel__actions_list">
          <ul role="menu">
            {primaryDropdownList.map((option, index) => {
              return (
                <li
                  id="tableSettingBtn"
                  className={classnames(
                    'TablePanel__actions_list_item',
                    option.value === actionSelected && 'selected',
                  )}
                  data-test-id={`table-panel-action-list-item_${option.value}`}
                  key={option.value}
                  onMouseEnter={(e) => setActionSelected(option.value)}
                  tabIndex={0}
                  aria-label={option.label}
                  role="menuitem"
                  onKeyDown={(e) => {
                    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                      setActionSelected(option.value)
                      setTimeout(() => {
                        document.getElementById('cs-dropdown-list-comfortView')?.focus()
                      })
                      return
                    }
                  }}
                >
                  <div className="dropdown-primary">
                    <span className="dropdown-primary-item">
                      <Icon
                        version='v2'
                        icon={option.icon}
                        size='medium'
                      // active={getActiveStateIcon(actionSelected, option.value)}
                      // activeIcon={option.activeIcon}
                      // className="dropdown-list"
                      />{' '}
                      {option.label}
                    </span>
                    <span className='Right-icon-wrapper'>
                      <Icon
                        version='v2'
                        // active={getActiveStateIcon(actionSelected, option.value)}
                        icon="Right"
                        size='medium'
                      />
                    </span>
                  </div>
                  {option.value === actionSelected && (
                    <div className={`TablePanel__actions__subitems flex-v-center `}>
                      <Dropdown
                        version='v2'
                        type="click"
                        closeAfterSelect={false}
                        dropDownPosition="bottom"
                        className={`flex-v-center ${option.className}`}
                        isOpen={true}
                        list={option.list}
                        lists={option.lists}
                        dragDropProps={option.dragDropProps}
                        isMultiCheck={option.isMultiCheck}
                        infoComponent={option.infoComponent}
                        canCloseOnClickOutside={false}
                        testId={`table-panel-action-list-subitem`}
                      />
                    </div>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

const TablePanel = (props) => {
  const {
    columnSelector,
    allColumns,
    totalCounts,
    name,
    viewSelector,
    dropDownList,
    viewBy,
    loading,
    searchPlaceholder,
    onChangeSearch,
    onRefresh,
    searchValue,
    canSearch,
    canRefresh,
    staticRowCount,
    onToggleColumnSelector,
    withExportCta,
    setTableColumnOrder,
    canOrderColumn,
    freezedUnfreezeColumn,
    canFreezeColumn,
    listData,
    setListData,
    setDragListData,
    setIsOpenAddColumn,
    isOpenAddColumn,
    hasFreezedColumn,
    showTotalCount,
    countExceedPompt
  } = props

  const allVisibleColumn = allColumns
    .filter((column) => column.isVisible && column.addToColumnSelector)
    .map((column) => column.id)

  let totalString = getTableCount({ totalCounts, name, staticRowCount, countExceedPompt })

  const onChangeColumnSelector = (allColumnsData) => {
    let [freezed = [], unfreezed = []] = listData
    freezed = compareColumn(allColumnsData, freezed)
    unfreezed = compareColumn(allColumnsData, unfreezed)
    setListData([freezed, unfreezed])
    if (onToggleColumnSelector) {
      const columnSelectorData = filterColumnData(allColumnsData)
      onToggleColumnSelector(columnSelectorData)
    }
  }
  const getSettingsDropdownList = (viewSelector, columnSelector, canFreezeColumn) => {
    let lists = []
    if (props.dropdownCustomOptions && props.dropdownCustomOptions.length > 0) {
      lists = [...lists, ...props.dropdownCustomOptions]
    }
    if (columnSelector) {
      lists.push({
        label: 'Manage columns',
        value: 'manage_columns',
        icon: 'ManageColumn',
        activeIcon: 'ManageColumn',
        list: canFreezeColumn ? [] : list,
        lists: canFreezeColumn ? getDropdownList() : [],
        dragDropProps: {
          canDragAndDrop: canOrderColumn,
          onDragEnd: onDragEnd,
          labels: canFreezeColumn ? ['Frozen Columns', 'Unfrozen Columns'] : ''
        },
        isMultiCheck: true,
        infoComponent: allVisibleColumn.length <= MAX_FREEZE_COUNT && InfoComponent
      })
    }

    if (viewSelector) {
      lists.push({
        label: 'Row density',
        value: 'table_view',
        icon: 'RowDensity',
        activeIcon: 'RowDensity',
        className: 'view-selector',
        list: dropDownList,
      })
    }

    return lists
  }

  const getListItem = (columns, isLock = false) => {
    let list = []
    columns.forEach((column) => {
      if (column.addToColumnSelector) {
        const action = () => {
          setTimeout(() => {
            onChangeColumnSelector(allColumns)
          })
        }
        list.push({
          label: <Selector column={column}
            isLock={isLock}
            allVisibleColumn={allVisibleColumn}
            canFreezeColumn={canFreezeColumn}
            freezedUnfreezeColumn={freezedUnfreezeColumn} />,
          action,
          id: column.id,
          canDragDrop: column.canDragDrop,
          isDropDisabled: hasFreezedColumn && !column.isVisible
        })
      }
    })
    return list
  }

  let list = getListItem(allColumns) || []

  const getDropdownList = () => {
    let [freezed = [], unfreezed = []] = listData
    let unfreezedColumn = []
    let freezedColumn = []
    if (freezed?.length > 0) {
      freezedColumn = getListItem(compareColumn(allColumns, freezed), true)
    }
    if (unfreezed.length > 0) {
      unfreezedColumn = getListItem(compareColumn(allColumns, unfreezed), false)
    }
    return [freezedColumn, unfreezedColumn]
  }

  const onDragEnd = (result, draggableId) => {
    const { source, destination } = result
    if (!destination) {
      return
    }
    if (canFreezeColumn) {
      const sInd = source.droppableId
      const dInd = destination.droppableId
      if (sInd === dInd) {
        const items = reorderList(listData[sInd], source.index, destination.index)
        const newState = [...listData]
        newState[sInd] = items
        setDragListData(newState, 'setColumnFreeze')
      } else {
        const items = move(listData[sInd], listData[dInd], source, destination)
        const newState = [...listData]
        newState[sInd] = items[sInd]
        newState[dInd] = items[dInd]
        freezedUnfreezeColumn({ id: result.draggableId })
      }
    } else {
      const updatedList: any = reorderList(list, result.source.index, result.destination.index)
      const updatedOrderedColumnIds = updatedList.map((list) => list.id)
      setTableColumnOrder(updatedOrderedColumnIds)
    }
  }

  const primaryDropdownList = getSettingsDropdownList(viewSelector, columnSelector, canFreezeColumn)
  return (
    <div className="TablePanel flex-v-center">
      {canSearch && (
        <Search
          width="full"
          corners="regular"
          placeholder={searchPlaceholder}
          onChange={onChangeSearch}
          debounceSearch={true}
          value={searchValue}
        />
      )}
      <div className={`flex-v-center ${canSearch ? '' : 'w-100 flex-justify'}`}>
        <label
          className="TablePanel__list-count flex-v-center"
          data-test-id={`${props.testId || 'cs-table'}-list-count`}>
          {showTotalCount && <div>
            {loading ? (
              <SkeletonTile
                numberOfTiles={1}
                tileHeight={10}
                tileWidth={80}
                tileBottomSpace={7}
                tileTopSpace={5}
                tileleftSpace={5}
              />
            ) : (
              totalString
            )}
          </div>}
          {canRefresh && (
            <Button
              className="ml-10 refresh-wrapper"
              aria-label="Refresh List"
              onClick={onRefresh}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onRefresh()
                }
              }}
              testId="table-refresh-icon"
              size="regular"
              buttonType="tertiary"
              onlyIcon={true}
              icon={'v2-Refresh'}
              version="v2"
              onlyIconHoverColor='secondary'
              iconProps={{
                withTooltip: true,
                tooltipContent: "Refresh",
                tooltipPosition: "left"
              }}
            />
          )}
        </label>
        {columnSelector || viewSelector || (withExportCta && withExportCta.showExportCta) ? (
          <div className="TablePanel__actions ml-10">
            <div
              data-test-id={'table-panel-action-items'}
              className={
                'TablePanel__actions__items ' +
                ((viewSelector && !columnSelector) || (!viewSelector && columnSelector)
                  ? 'TablePanel__actions__items--single'
                  : '')
              }>
              {withExportCta && withExportCta.component && withExportCta.showExportCta ? (withExportCta?.asButtonIcon ? <>{withExportCta.component}</> : <div className={`import-cta mr-20 ml-10`}>{withExportCta.component}</div>) : null}

              <TableSetting
                isDisabled={totalCounts === 0}
                setIsOpenAddColumn={setIsOpenAddColumn}
                isOpenAddColumn={isOpenAddColumn}
                primaryDropdownList={primaryDropdownList} />

            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default TablePanel

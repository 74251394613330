import { Registry } from '@react-registry'
import { deserialize as defaultDeserialize } from './deserializer/defaultDeserializer'
import { deserialize as googleDeserialize } from './deserializer/googleDocsSerializer'
import { deserialize as csDeserialize } from './deserializer/superchargedRTEDeserializer'
import { deserialize as wordDeserialize } from './deserializer/wordDeserializer'
import collapse from 'collapse-whitespace'
import { preInsert } from './utils'
import { detect } from 'detect-browser'
import { uploadImage } from '../../Element/contentstack/image/request'

import { Transforms } from 'slate'
const pattern = /^docs-internal-guid/

const getUserAgentDetails = () => {
  const systemDetails = detect()
  return {
    platform: systemDetails.os,
    browser: systemDetails.name,
    browserVersion: systemDetails.version
  }
}

const getFragmentFromCopyPasteScript = (
  copyPasteConfig,
  docTypeFromScript,
  parsedHtml,
  wordPasteOptions,
  googlePasteOptions
) => {
  try {
    const userAgentDetails = getUserAgentDetails()
    const docCopyPasteConfig = copyPasteConfig?.docCopyPasteConfig
    let configMatch = Array.from(docCopyPasteConfig.config).find((config: any) => {
      return (
        config.type === docTypeFromScript.docType &&
        config?.browser === userAgentDetails.browser &&
        config?.browser === userAgentDetails.browser &&
        config?.browser === userAgentDetails.browser &&
        userAgentDetails.platform.includes(config?.platform) &&
        userAgentDetails.platform.includes(config?.platform) &&
        userAgentDetails.platform.includes(config?.platform) &&
        config?.mode === docTypeFromScript.mode &&
        config.shouldVisit(parsedHtml)
      )
    })
    if (configMatch) {
      //@ts-ignore
      return configMatch.parser(parsedHtml, wordPasteOptions, googlePasteOptions)
    }
    return null
  } catch (err) {
    console.log('Error while copy pasting data using external script', err)
  }
}

const uploadImageToAsset = async (editor, url, extraAttrs = {}) => {
  if (url.startsWith('data')) { // upload to assets
    let fileArray = url.split(','),
      mime = fileArray[0].match(/:(.*?);/)[1],
      bstr = atob(fileArray[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const fileToUpload = new File([u8arr], 'image.png', { type: mime });
    const requestProps: Object = editor?.requestProps || {};
    const folderUid = ''
    const imgUploadRes: any = await uploadImage({ ...requestProps, files: [fileToUpload], folderUid })

    if (imgUploadRes) {
      return imgUploadRes?.[0]?.['asset']?.['url']
    }
  }
}

const convertBase64UrlToAssetUrl = async (editor, parsedHtml) => {
  const imgElements = parsedHtml.querySelectorAll('img')
  if (imgElements) {
    await Promise.all(Array.from(imgElements).map(async (img: any) => {
      if (img.src?.startsWith('data')) {
        const response = await uploadImageToAsset(editor, img.src);
        if (response) {
          img.setAttribute('src', response);
        }
      }
    }));
  }
  return parsedHtml
}

const withHtml = (editor) => {
  const { insertData } = editor

  editor.insertData = async (data) => {
    const customPasteOptions = editor.customPasteOptions || {}
    const copyPasteConfig = editor.copyPasteConfig || {}
    const googlePasteOptions = (customPasteOptions && customPasteOptions.googleDoc) || {}
    const wordPasteOptions = (customPasteOptions && customPasteOptions.wordDoc) || {}
    const html = data.getData('text/html')
    if (html) {
      let parsedHtml: any = new DOMParser().parseFromString(html, 'text/html').body
      collapse(parsedHtml)
      let docTypeFromScript = copyPasteConfig?.findDocType?.(parsedHtml)

      let fragment
      let googlePlainHtml = ''

      let isGoogleDoc = Array.from(parsedHtml.childNodes).some((node: any) => {
        if (node.nodeName === 'B' && pattern.test(node['id'])) {
          googlePlainHtml = node.innerHTML
          return true
        }
        return false
      })
      const isCSContent = parsedHtml.querySelector('[data-editor-type*="scrte"]')

      const isWord =
        parsedHtml.getElementsByTagName('O:P').length ||
        parsedHtml.querySelector('[class*="Mso"]' || parsedHtml.querySelector('[style*="mso"]'))

      const fragmentFromCopyPasteScript = getFragmentFromCopyPasteScript(
        copyPasteConfig,
        docTypeFromScript,
        parsedHtml,
        wordPasteOptions,
        googlePasteOptions
      )

      if (fragmentFromCopyPasteScript) {
        fragment = fragmentFromCopyPasteScript
      } else if (isGoogleDoc) {
        parsedHtml = new DOMParser().parseFromString(googlePlainHtml, 'text/html').body
        fragment = googleDeserialize(parsedHtml, googlePasteOptions)
      } else if (isCSContent) {
        fragment = csDeserialize(parsedHtml)
      } else if (isWord) {
        console.error('Error while loading word copy paste script. Running deserilizer from venus-components.')
        fragment = wordDeserialize(parsedHtml, wordPasteOptions)
      } else {
        let updatedHtml = await convertBase64UrlToAssetUrl(editor, parsedHtml)
        if (updatedHtml) fragment = defaultDeserialize(updatedHtml)
      }

      fragment = preInsert(editor, fragment)
      Transforms.insertFragment(editor, fragment)
      return
    }
    insertData(data)
  }
  return editor
}

Registry.register(withHtml, { id: 'withHtml', registry: 'plugin' })

import React from 'react'
import { Registry } from '@react-registry'
import { elementCategories } from '../../elementCategories'
import { UnorderedListIcon } from '../../../utils/Icon'
//@ts-ignore
import styles from '../style.module.css'
import { useSlate } from 'slate-react'
import { toggleList } from '../utils'
import { isBlockActive } from '../../../../components/Toolbar/Button/buttonUtils'
import { cx } from '@emotion/css'

export * from './deserializer'

const format = 'ul'

export const UnOrderedList = (props) => {
  const isNodeRTLDirected = props?.attrs?.style?.direction === "rtl";
  return (
    <div
      {...props.attributes}
      {...props.attrs}
      className={
        isNodeRTLDirected
          ? cx(styles["list"], styles["list-rtl"])
          : styles["list"]
      }
    >
      <ul>{props.children}</ul>
    </div>
  )
}

export const UnOrderedListToggleButton = (props) => {
  const editor = useSlate()

  const handleMouseDown = (event) => {
    toggleList(event, editor, format)
  }

  return <React.Fragment>{props.children({ handleMouseDown, active: isBlockActive(editor, format) })}</React.Fragment>
}

export const shortcutUnOrderedListToggle = (event, editor, format, registryObj) => {
  toggleList(event, editor, format)
}

Registry.register(
  {
    Component: UnOrderedList,
    IngressComponent: UnOrderedListToggleButton,
    iconName: <UnorderedListIcon />,
    slashIconName: <UnorderedListIcon />,
    shortcut: { key: 'mod+shift+8', callback: shortcutUnOrderedListToggle },
    title: 'Unordered list',
    slashTitle: 'Unordered list',
    subtitle: 'Unordered list of Items',
    category: elementCategories.LIST_ITEMS,
    isContentStackElement: true,
    inBasicToolbar: false,
    keywords: ['unordered list'],
    dndOptions: {
      DisableDND: true
    }
  },
  { id: format, registry: 'v2.element' }
)

import React, { useEffect, useRef, useState, useCallback } from 'react'
import { unstable_batchedUpdates as batch } from 'react-dom'
import ReactDOM from 'react-dom'
import debounce from 'lodash.debounce'
import classnames from 'classnames'

import Tooltip from '../Tooltip/Tooltip'
import Dropdown from '../DropDown/Dropdown'
import Icon from '../Icon2/Icon'
import Button from '../Button/Button'

export type BulkActionPanelProps = {
  bulkActionList: any
  selectedData: any
  updatedParentWidth: number
  testId?: string
}
const throttle = (f) => {
  let token = null,
    lastArgs = null
  const invoke = () => {
    f(...lastArgs)
    token = null
  }
  const result = (...args) => {
    lastArgs = args
    if (!token) {
      token = requestAnimationFrame(invoke)
    }
  }
  result.cancel = () => token && cancelAnimationFrame(token)
  return result
}

const useRefEffect = (handler) => {
  const storedValue = useRef<HTMLElement>()
  const unsubscribe = useRef<HTMLElement>()
  const result = useCallback(
    (value) => {
      storedValue.current = value
      if (unsubscribe.current) {
        //unsubscribe.current()
        unsubscribe.current = undefined
      }
      if (value) {
        unsubscribe.current = handler(value)
      }
    },
    [handler]
  )
  useEffect(() => {
    result(storedValue.current)
  }, [result])
  return result
}

// combine several `ref`s into one
// list of refs is supposed to be immutable after first render
const useCombinedRef = (...refs) => {
  const initialRefs = useRef(refs)
  return useCallback((value) => {
    initialRefs.current.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else {
        ref.current = value
      }
    })
  }, [])
}

// create a ref to subscribe to given element's event
const useDomEvent = (name, handler) => {
  return useCallback(
    (elem) => {
      elem.addEventListener(name, handler)
      return () => {
        elem.removeEventListener(name, handler)
      }
    },
    [name, handler]
  )
}

// callback with persistent reference,
// but updated on every render
const usePersistentCallback = (f) => {
  const realF = useRef(f)
  useEffect(() => {
    realF.current = f
  }, [f])
  return useCallback((...args) => {
    return realF.current(...args)
  }, [])
}

// persistent reference to identity function
const useDraggable = () => {
  const [pressed, setPressed] = useState(false)
  const [position, setPosition] = useState({ x: 447, y: 140 })
  const size = useRef({ x: Infinity, y: Infinity })
  const handleDrag = useCallback(
    ({ x, y }) => ({
      x: Math.max(0, Math.min(x, size.current.x)),
      y: Math.max(0, Math.min(y, size.current.y))
    }),
    []
  )
  const ref = useRef<HTMLElement>()
  const handleMouseDown = useCallback((e) => {
    if (e.button !== 0) {
      return
    }
    setPressed(true)
  }, [])

  useEffect(() => {
    if (!ref.current) return
    const elem = ref.current
    if (elem) {
      elem.style.userSelect = 'none'
      return () => {
        elem.style.userSelect = 'auto'
      }
    }
  }, [])
  const subscribeMouseDown = useDomEvent('pointerdown', handleMouseDown)
  const ref2 = useRefEffect(subscribeMouseDown)
  const combinedRef = useCombinedRef(ref, ref2)
  const persistentOnDrag = usePersistentCallback(handleDrag)
  useEffect(() => {
    if (!pressed) {
      return
    }
    let delta = position,
      lastPosition = position
    const applyTransform = () => {
      if (!ref.current) {
        return
      }

      const { x, y } = lastPosition
      ref.current.style.transform = `translate(${x}px, ${y}px)`
    }
    const handleMouseMove = throttle(({ movementX, movementY }) => {
      let { x, y } = delta
      if (x >= window.innerWidth - 60) {
        x = window.innerWidth - 60
      }
      if (y >= window.innerHeight - 30) {
        y = window.innerHeight - 30
      }
      delta = { x: x + movementX, y: y + movementY }
      lastPosition = persistentOnDrag(delta)
      applyTransform()
    })
    const handleMouseUp = (e) => {
      handleMouseMove(e)
      batch(() => {
        setPressed(false)
        setPosition(lastPosition)
      })
    }
    const terminate = () => {
      lastPosition = position
      applyTransform()
      setPressed(false)
    }
    const handleKeyDown = (e) => {
      if (e.code === 'Escape') {
        e.preventDefault()
        terminate()
      }
    }
    document.addEventListener('pointermove', handleMouseMove)
    document.addEventListener('pointerup', handleMouseUp)
    document.addEventListener('keydown', handleKeyDown)
    window.addEventListener('blur', terminate)
    return () => {
      handleMouseMove.cancel()
      document.removeEventListener('pointermove', handleMouseMove)
      document.removeEventListener('pointerup', handleMouseUp)
      document.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('blur', terminate)
    }
  }, [position, pressed, persistentOnDrag])
  return { combinedRef, pressed, position }
}

// this function is to show no. of items in bulk action panel and rest in show more dropdwn
const isDropdownVisible = (bulkActionList) => {
  let sliceCount
  if (window.innerWidth < 1480 && bulkActionList.length >= 4) {
    sliceCount = 4
  }

  if (window.innerWidth < 1366 && bulkActionList.length >= 3) {
    sliceCount = 3
  }
  if (window.innerWidth <= 1200 && bulkActionList.length >= 2) {
    sliceCount = 2
  }
  return sliceCount
}

// This component is for V2 Bulk Action Panel
export const BulkActionPanel: React.FC<BulkActionPanelProps> = (props) => {
  const { bulkActionList, selectedData, updatedParentWidth, testId } = props
  let sliceCount = isDropdownVisible(bulkActionList)
  const [showDropdown, setShowDropdown] = useState(sliceCount > 0)
  const handleResize = debounce(() => {
    if (sliceCount > 0) {
      setShowDropdown(true)
    } else {
      setShowDropdown(false)
    }
  }, 500)

  useEffect(() => {
    handleResize()
  }, [updatedParentWidth])

  const hanldeClick = (action) => {
    const type = typeof action.label === 'string' ? action.label.toLowerCase() : ''
    action.cb({ data: selectedData, type })
  }
  const { combinedRef } = useDraggable()
  let foundDiv = document.getElementById('tableBulkActionNode')
  let div: any

  if (foundDiv) {
    div = foundDiv
  } else {
    div = document.createElement('div')
    div.setAttribute('id', 'tableBulkActionNode')
    document.body.appendChild(div)
  }

  function closeTableBulkAction() {
    if (div.parentNode) {
      div.parentNode.removeChild(div)
    }
  }

  return ReactDOM.createPortal(
    <div ref={combinedRef} className={'bulk-action-wrapper'} style={{ width: updatedParentWidth * 0.82 + 'px' }} data-test-id={testId}>
      <BulkActionPanelItem
        bulkActionList={bulkActionList}
        hanldeClick={hanldeClick}
        showDropdown={showDropdown}
        sliceCount={sliceCount}
      />
    </div>,
    div
  )
}

export const BulkActionPanelItem = ({ bulkActionList, hanldeClick, showDropdown, sliceCount }) => {
  let bulkActionListItems = showDropdown ? bulkActionList.slice(0, sliceCount) : bulkActionList
  return (
    <div className={`TableActionPanel flex-justify flex-v-center Table-bulk-action`} tabIndex={0}>
      <div className="selected-items">
        <div className="drag-icon">
          <Tooltip variantType="basic" content="Drag" position="top" showArrow={true}>
            <Icon icon="ActionBar" version='v2' size='small' />
          </Tooltip>
        </div>
      </div>
      <div className={'bulk-action-items'}>
        {bulkActionListItems.map((action: any, i: number) => {
          return (
            <Button
              version="v2"
              id={`bulk-panel-action-item-${i}`}
              testId={action.testId}
              aria-label={action.ariaLabel}
              key={i}
              size="large"
              buttonType={action.type != 'primary' ? action.type : 'light'}
              icon={action.icon ? action.icon : undefined}
              onClick={() => hanldeClick(action)}
              className={classnames(action.showSelected && 'show-select-btn')}
            // onKeyDown={(e) => {
            //   //reiterating focus through list
            //   if (i === bulkActionListItems.length - 1 && e.key === 'Tab' && !showDropdown) {
            //     setTimeout(() => {
            //       document.getElementById('bulk-panel-action-item-0')?.focus()
            //     })
            //   }
            // }}
            >
              {action.label}
            </Button>
          )
        })}
        {showDropdown > 0 && bulkActionList?.length > bulkActionListItems?.length && (
          <Dropdown
            version="v1"
            list={bulkActionList.slice(sliceCount, bulkActionList.length).map((list) => {
              return {
                label: (
                  <>
                    <Icon icon={list.icon} className={list.icon === 'Unpublish' ? 'Icon--unpublish' : ''} />
                    <div data-test-id={list.testId}>{list.label}</div>
                  </>
                ),
                action: () => hanldeClick(list),
                default: true
              }
            })}
            type="click">
            <div className="see-more-icon" data-test-id={'table-see-more-icon'}>
              <Icon icon="SeeMore" fill="white" size="small" height={24} width={24} />
            </div>
          </Dropdown>
        )}
      </div>
    </div>
  )
}

BulkActionPanel.defaultProps = {
  testId: 'cs-bulk-action-panel'
}
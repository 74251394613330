import React, { useState, useRef, useEffect } from 'react'

import Field from '../../Field/Field'
import TextInput from '../../TextInput/TextInput'
import Textarea from '../../Textarea/Textarea'
import Tooltip from '../../Tooltip/Tooltip'
import FieldLabel from '../../FieldLabel/FieldLabel'
import Checkbox from '../../Checkbox/Checkbox'
import Help from '../../Help/Help'
import { fieldUidRegex, getContentTypeFieldDataTestId, CONTENT_TYPE_FIELD_ACTION_IDS } from '../helper'

export const Link = (props: any) => {
  const { onChange, options, parentInfo, uniqueKey, schema, type, isNonLocalizable, hasNonLocalizablePlan } = props
  const [initialSchema, setSchema] = useState(schema)
  const { display_name, uid, field_metadata } = schema
  const [error, setError] = useState(fieldUidRegex(schema.uid, schema))

  const displayNameRef: any = useRef(null)

  useEffect(() => {
    displayNameRef.current.focus()
  }, [displayNameRef])

  const onchange = () => {
    onChange && onChange(schema)
    setSchema({ ...schema })
  }
  const onDisplayNameChange = (event: any) => {
    schema.display_name = event.target.value

    if (schema.hasOwnProperty('display_name') && schema.newfield) {
      schema.uid = schema.display_name
        .trim()
        .replace(/[^A-Z0-9]+/gi, '_')
        .toLowerCase()
      if (schema.display_name) {
        schema.error_details = {
          ...schema.error_details,
          uid: ''
        }
      }
    }

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.display_name.length) {
      schema.error_details = {
        ...schema.error_details,
        display_name: 'Display Name cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        display_name: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onUidChange = (event: any) => {
    setError(fieldUidRegex(event.target?.value?.trim(), schema))

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (!schema.uid.length) {
      schema.error_details = {
        ...schema.error_details,
        uid: 'uid cannot be empty'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        uid: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }
    onchange()
  }
  const onInstructionChange = (event: any) => {
    field_metadata.instruction = event.target.value
    onchange()
  }
  const onHelpTextChange = (event: any) => {
    field_metadata.description = event.target.value
    onchange()
  }
  const onOptionsChange = (event: any) => {
    if (event.target.name === `${uniqueKey}.mandatory`) {
      schema.mandatory = event.target.checked
    }
    if (event.target.name === `${uniqueKey}.multiple`) {
      schema.multiple = event.target.checked
      schema.error_details = {
        ...schema.error_details,
        max_instance: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
      if (!schema.multiple) {
        delete schema.max_instance
      }
    }
    if (event.target.name === `${uniqueKey}.nonLocalization`) {
      schema.non_localizable = event.target.checked
    }
    onchange()
  }
  const onDefaultValueTitleChange = (event: any) => {
    field_metadata.default_value.title = event.target.value
    onchange()
  }
  const onDefaultValueUrlChange = (event: any) => {
    field_metadata.default_value.url = event.target.value
    onchange()
  }
  const onMaxInstance = (event: any) => {
    schema.max_instance = event.target.value ? parseInt(event.target.value) : null

    if (!schema.error_details) {
      schema.error_details = {}
    }

    if (event.target.value.length && schema.max_instance < 0) {
      schema.error_details = {
        ...schema.error_details,
        max_instance: 'max instance cannot be less then zero'
      }
      schema.error = true
    } else {
      schema.error_details = {
        ...schema.error_details,
        max_instance: ''
      }
      schema.error = Object.keys(schema.error_details).filter((fi) => schema.error_details[fi].length).length
        ? true
        : false
    }

    onchange()
  }

  if (type === 'basic') {
    return (
      <div className="FormFields">
        <div className="FormFields__column">
          <Field>
            <FieldLabel required htmlFor="displayName">
              Display Name
            </FieldLabel>
            <TextInput
              required
              name={`${uniqueKey}.displayName`}
              value={display_name}
              onChange={onDisplayNameChange}
              inputRef={displayNameRef}
              error={schema.error && schema.error_details.display_name ? true : false}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.DISPLAY_NAME_INPUT)}
            />
          </Field>
          <Field>
            <FieldLabel htmlFor="helpText">Help Text</FieldLabel>
            <Help text="Provide additional info about the field. The help text will appear when the user hovers on the ‘?’ sign beside the field name." />
            <Textarea
              name={`${uniqueKey}.helpText`}
              value={field_metadata.description || ''}
              onChange={onHelpTextChange}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              // multiline={true}
              testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.HELP_TEXT_INPUT)}
            />
          </Field>
        </div>
        <div className="FormFields__column">
          <Field>
            <FieldLabel required htmlFor="uid">
              Unique ID
            </FieldLabel>
            <Help text="A unique ID for this field. This should not be same as the ID of any other field in this content type." />
            <TextInput
              required
              name={`${uniqueKey}.uid`}
              value={uid}
              onChange={onUidChange}
              error={(schema.error && schema.error_details.uid) || error ? true : false}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.UID_INPUT)}
            />
          </Field>
          <Field>
            <FieldLabel htmlFor="instruction">Instruction Value</FieldLabel>
            <Help text="Helps you add instructions for this field. The instructional text will appear below the field." />
            <Textarea
              name={`${uniqueKey}.instruction`}
              value={field_metadata.instruction || ''}
              onChange={onInstructionChange}
              placeholder={props.t(
                'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
              )}
              // multiline={true}
              testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.INSTRUCTION_INPUT)}
            />
          </Field>
        </div>
      </div>
    )
  } else if (type === 'advanced') {
    return (
      <div className="FormFields">
        <div className="FormFields__column">
          <Field>
            <FieldLabel htmlFor="defaultValue">Default Value</FieldLabel>
            <Help text="Set a default field value for this field. The value will appear by default while creating an entry for this content type." />
            <div className="FormFields__selection-wrapper">
              <Field>
                <FieldLabel htmlFor="validation">Title:</FieldLabel>
                <TextInput
                  name={`${uniqueKey}.defaultValue.title`}
                  value={field_metadata.default_value.title || ''}
                  onChange={onDefaultValueTitleChange}
                  className="mt-5"
                  placeholder={props.t(
                    'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                  )}
                  testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.TITLE_INPUT)}
                />
              </Field>
              <Field>
                <FieldLabel htmlFor="validation">URL:</FieldLabel>
                <TextInput
                  name={`${uniqueKey}.defaultValue.url`}
                  value={field_metadata.default_value.url || ''}
                  onChange={onDefaultValueUrlChange}
                  className="mt-5"
                  placeholder={props.t(
                    'content_type.save_content_type.body.fields.common_properties.placeholders.enter_value'
                  )}
                  testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.URL_INPUT)}
                />
              </Field>
            </div>
          </Field>
        </div>
        <div className="FormFields__column">
          <Field>
            <FieldLabel htmlFor="options">Options</FieldLabel>
            <div className="FormFields__selection-wrapper">
              <Checkbox
                testId="cs-ct-field-link-mandatory"
                name={`${uniqueKey}.mandatory`}
                text="Mandatory"
                checked={schema.mandatory}
                onChange={onOptionsChange}
              />
              <Checkbox
                testId="cs-ct-field-link-multiple"
                name={`${uniqueKey}.multiple`}
                text="Multiple"
                checked={schema.multiple}
                onChange={onOptionsChange}
              />
              {schema.multiple ? (
                <div>
                  <Field>
                    <FieldLabel className="ml-0" htmlFor="validation">
                      Set Maximum Limit
                    </FieldLabel>
                    <TextInput
                      type="number"
                      name={`${uniqueKey}.maxinstance`}
                      value={schema.max_instance || null}
                      onChange={onMaxInstance}
                      // placeholder="Maximum"
                      placeholder={props.t(
                        'content_type.save_content_type.body.fields.common_properties.placeholders.maximum'
                      )}
                      error={schema.error && schema.max_instance ? true : false}
                      testId={getContentTypeFieldDataTestId(schema, type, CONTENT_TYPE_FIELD_ACTION_IDS.SET_MAX_LIMIT_INPUT)}
                    />
                  </Field>
                </div>
              ) : null}
              {(parentInfo.hasParent ? !parentInfo.isMulti : true) ? (
                hasNonLocalizablePlan ? (
                  <>
                    {isNonLocalizable ? (
                      <Tooltip position="top" content="Available only if there are multiple languages in your stack.">
                        <Checkbox
                          testId="cs-ct-field-link-non-localizable"
                          name={`${uniqueKey}.nonLocalization`}
                          text="Non-localizable"
                          checked={schema.non_localizable}
                          disabled={isNonLocalizable}
                          onChange={onOptionsChange}
                        />
                      </Tooltip>
                    ) : (
                        <Checkbox
                          testId="cs-ct-field-link-non-localizable"
                          name={`${uniqueKey}.nonLocalization`}
                          text="Non-localizable"
                          checked={schema.non_localizable}
                          disabled={isNonLocalizable}
                          onChange={onOptionsChange}
                        />
                      )}
                    <div className="nl-note">
                      If enabled, editing this field is restricted in localized entries. The field will use the value of
                      the master-language entry in all localized entries.
                    </div>{' '}
                  </>
                ) : null
              ) : null}
            </div>
          </Field>
          {(
            parentInfo.hasParent
              ? parentInfo.isMulti && (parentInfo.data_type == 'group' || parentInfo.data_type == 'global_field')
              : false
          ) ? (
              <>
                <div className="mark-group-title">
                  <Checkbox
                    testId="cs-ct-field-link-group-title"
                    name={`${uniqueKey}.isTitle`}
                    text="Mark as Group title"
                    checked={field_metadata.isTitle || false}
                    onChange={(event) => {
                      let eventData = event.target.checked
                      field_metadata.isTitle = eventData
                      onchange()
                      // groupAsTitle(parentInfo.id, schema, () => {
                      //   console.log('callback triggered groupAsTitle');
                      // });
                    }}
                  />
                </div>
              </>
            ) : null}
        </div>
      </div>
    )
  }
}

export default React.memo(Link)

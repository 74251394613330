//@ts-nocheck
import { Transforms, Node, Editor, Element } from 'slate'

/** element level  */
export const setElementClass = (editor, classText, selection) => {
  for (const [node, path] of Editor.nodes(editor, { at: selection })) {
    if (Element.isElement(node) && node.type !== 'doc') {
      const oldAttributes = node.attrs
      const elementAttribute = {
        attrs: {
          ...oldAttributes,
          "class-name": classText
        }
      }
      Transforms.setNodes(editor, elementAttribute, { at: path })
    }
  }
}

export const setElementId = (editor, idText, selection) => {

  for (const [node, path] of Editor.nodes(editor, { at: selection })) {
    if (Element.isElement(node) && node.type !== 'doc') {
      const oldAttributes = node.attrs
      const elementAttribute = {
        attrs: {
          ...oldAttributes,
          id: idText
        }
      }
      Transforms.setNodes(editor, elementAttribute, { at: path })
    }
  }

}

export const getElementClass = (editor, selection) => {
  if (selection.focus.path) {
    const attributes = Node.parent(editor, selection.focus.path).attrs
    if (attributes) {
      return attributes["class-name"]
    }
  }
}

export const getElementId = (editor, selection) => {
  if (selection.focus.path) {
    const attributes = Node.parent(editor, selection.focus.path).attrs
    if (attributes) {
      return attributes.id
    }
  }
}

/** inline level  */
export const setInlineClass = (editor, classText, selection) => {
  if (selection) {
    Editor.addMark(editor, "classname", classText)
  }
}

export const setInlineId = (editor, idText, selection) => {
  if (selection) {
    Editor.addMark(editor, "id", idText)
  }
}

export const getInlineClass = (editor, selection) => {
  if (selection.focus.path) {
    const className = Node.leaf(editor, selection.focus.path)["classname"]
    if (className) {
      return className
    }
  }
}

export const getInlineId = (editor, selection) => {
  if (selection.focus.path) {
    const id = Node.leaf(editor, selection.focus.path)["id"]
    if (id) {
      return id
    }
  }
}
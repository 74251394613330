/* istanbul ignore file */
import React, { useRef, useEffect, useState } from 'react'
import { Range, Editor } from 'slate'
import { ReactEditor, useSlate } from 'slate-react'
import { Portal, Menu } from '../ToolbarWrapper'
//@ts-ignore
import styles from '../style.module.css'
import { cx } from '@emotion/css'
import { isEqual } from 'lodash'


function disapperModal(el, open) {
  if (!open) return;
  el.style.opacity = 0;
  el.removeAttribute('style');
}

function handlePosition(el, body, open, document) {
  const domSelection = document.getSelection();
  // if selection is not there than return;
  if (!domSelection || domSelection.rangeCount < 1) return;

  const topOffset = 14;
  const leftOffset = 10

  const domRange = domSelection.getRangeAt(0);
  const { bottom, height, left, top, width } = domRange.getBoundingClientRect();
  const { bottom: bodyBottom, left: bodyLeft, right: bodyRight, top: bodyTop, width: bodyWidth, } = body.getBoundingClientRect();

  // handle modal top position
  if (bottom + height + el.offsetHeight + topOffset > bodyBottom) {
    el.style.top = `${top - topOffset - el.offsetHeight}px`
  } else el.style.top = `${bottom + topOffset}px`;

  // handle modal left right
  let modalLeft = (left + (width - el.offsetWidth) / 2);
  let modalRight = (left + (width + el.offsetWidth) / 2);
  // if modal left is out of left markdown line set left = 0
  if (modalLeft < bodyLeft) el.style.left = bodyLeft + leftOffset + 'px';
  // if modal right is out of right markdown line set right = 0
  // here trying to set the el to right side of the json rte 
  else if (modalRight > bodyRight) el.style.left = bodyLeft + ( bodyWidth - el.offsetWidth - leftOffset) + 'px';
  // else set at the center of selection
  else
    el.style.left = modalLeft + 'px';

  // if modal gets out of editor
  if (top < bodyTop || bottom > bodyBottom) {
    el.style.opacity = 0;
    el.style.pointerEvents = 'none';
    el.style.display = 'none';
  } else {
    el.style.opacity = 1;
    el.style.pointerEvents = 'auto';
    el.style.display = 'inline-block';
  }
}


export default ({ children, scrollRef }) => {
  const ref = useRef(null);
  const editor = useSlate();
  const [open] = useState(false);
  const _open = useRef(open);

  const closeHoveringToolbar = () => {
    const el = ref.current;
    disapperModal(el, _open.current);
    if (_open.current) {
      _open.current = false;
    }
  }

  const openHoveringToolbar = () => {
    const el = ref.current
    if (!el) return

    handlePosition(el, scrollRef.current.querySelector('#scrte-editable'), _open.current, document);
    if (!_open.current) {
      _open.current = true;
    }
  }

  const handleClose = (e) => {
    if (!ref.current.contains(e.target)) {
      closeHoveringToolbar();
    }
  }

  const handleHoveringToolbar = (event?) => {
    const el = ref.current;
    if (!el) {
      return;
    }
    const { selection } = editor;
    const isRangeExpanded = selection && Range.isExpanded(selection);
    const isCollapsed = !selection || Range.isCollapsed(selection) || Editor.string(editor, selection) === '';
    const start = selection?.focus.path || [], end = selection?.anchor.path || [];
    const sameElement = isEqual(start.slice(0, start.length - 1), end.slice(0, end.length - 1));

    // close
    if (isCollapsed || !isRangeExpanded || !ReactEditor.isFocused(editor as ReactEditor)) {
      closeHoveringToolbar();
      return;
    }
    // open
    openHoveringToolbar();
  }

  useEffect(() => {
    const scrte = scrollRef.current;
    const editor = scrte.querySelector('#scrte-editable');
    editor.addEventListener('mouseup', handleHoveringToolbar);
    document.addEventListener('mousedown', handleClose);
    document.addEventListener('scroll', handleHoveringToolbar, true);

    return () => {
      editor.removeEventListener('mouseup', handleHoveringToolbar);
      document.removeEventListener('mousedown', handleClose);
      document.removeEventListener('scroll', handleHoveringToolbar, true);
    }
  }, []);


  useEffect(() => {
    handleHoveringToolbar()
  }, [JSON.stringify(editor.selection)])


  const newChildren = React.cloneElement(children, { closeHoveringToolbar })

  return (
    <Portal>
      <Menu
        ref={ref}
        className={cx(
          styles['hovering-toolbar'],
          [styles['hovering-toolbar--fullscreen']],
          'scrte-toolbar',
          'scrte-hovering-toolbar'
        )}
      >
        {newChildren}
      </Menu>
    </Portal>
  )
}

import React, { Children } from 'react'
import './Timeline.css'
import cn from 'classnames'
import Icon from '../Icon2/Icon'

export type TimelineProps = {
  className?: string
  showOnHover?: boolean
  showSearchBar?: boolean
  style?: React.CSSProperties
  align?: 'left' | 'right' | 'default'
  searchText?: string,
  placeholderText?: string
}

export const TimelineContext = React.createContext<TimelineProps>({
  showOnHover: false,
  showSearchBar: false,
  searchText: ''
})

const Timeline: React.FC<TimelineProps> = (props) => {
  const { children, showOnHover, align, className, showSearchBar, style, placeholderText } = props
  const [searchText, setSearchText] = React.useState('')
  const alignItems = {
    'Timeline--Left': align === 'left',
    'Timeline--Right': align === 'right',
    Timeline: align === 'default'
  }

  const classNames = cn(alignItems, showOnHover && 'TimelineHover', 'Timeline', className)
  const searchClasses = cn(showOnHover && 'hideSearchBar', 'searchBar')
  return (
    <TimelineContext.Provider value={{ showOnHover, showSearchBar, searchText }}>
      <ul id="Timeline" style={{ ...style }} className={classNames}>
        {showSearchBar && (
          <div className="searchBarWrapper">
            <Icon width="20px" height="20" icon="Search" size="tiny" />
            <div>
              <input
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                className={searchClasses}
                type="text"
                placeholder={placeholderText ?? 'Search for items'}
              />
            </div>
          </div>
        )}
        {children}
      </ul>
    </TimelineContext.Provider>
  )
}
export default Timeline

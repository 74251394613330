import moment from 'moment'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'

import { isEmpty } from '../util/helpers'



import { stringOperators, options, allOperators, basicOperators, notPublishedEnvObj } from '../util/constants'
import { getFileSizes, getLocalizedOptions } from '../util/helpers'
import { languageLabel, localizedInLabel } from '../util/entries'

const commonFilters = [
  {
    key: { label: 'Created At', value: 'created_at' },
    value: {},
    operator: allOperators[4],
    currentQuery: 'listDate',
    queryType: 'date',
    defaultOperator: allOperators[4]
  },
  {
    key: { label: 'Created By', value: 'created_by' },
    value: {},
    operator: stringOperators[0],
    // isMulti: true,
    currentQuery: 'fetchUsers',
    defaultOperator: stringOperators[0]
  },
  {
    key: { label: 'Published At', value: '_published_at' },
    value: {},
    operator: allOperators[4],
    currentQuery: 'listDate',
    queryType: 'date',
    defaultOperator: allOperators[4]
  },
  {
    key: { label: 'Published By', value: '_published_by' },
    value: [],
    operator: stringOperators[0],
    isMulti: true,
    currentQuery: 'fetchUsers',
    defaultOperator: stringOperators[0]
  },
  {
    key: { label: 'Published Environment', value: '_publish_environment' },
    value: [],
    operator: stringOperators[0],
    isMulti: true,
    currentQuery: 'fetchEnvironments',
    type: 'publishedEnvironment',
    defaultOperator: stringOperators[0]
  },
  // {
  //   key: { label: 'Tags', value: 'tags' },
  //   value: [],
  //   operator: stringOperators[0],
  //   isMulti: true,
  //   currentQuery: 'fetchTags',
  // },
  {
    key: { label: 'Updated By', value: 'updated_by' },
    value: [],
    operator: stringOperators[0],
    isMulti: true,
    currentQuery: 'fetchUsers',
    defaultOperator: stringOperators[0]
  },
  {
    key: { label: 'Updated At', value: 'updated_at' },
    value: {},
    operator: allOperators[4],
    currentQuery: 'listDate',
    queryType: 'date',
    defaultOperator: allOperators[4]
  }
]

export let entriesfilters = [
  {
    key: { label: 'Text', value: '_text_entries' },
    value: '',
    operator: stringOperators[1],
    isText: true,
    isMulti: false,
    type: 'textLabel',
    defaultOperator: stringOperators[1]
  },
  {
    key: { label: 'Content Type', value: '_content_type_uid' },
    value: [],
    operator: stringOperators[0],
    isMulti: true,
    currentQuery: 'fetchContentTypes',
    type: 'contentType',
    defaultOperator: stringOperators[0]
  },
  {
    key: { label: 'Terms', value: 'taxonomies' },
    value: [],
    operator: stringOperators[0],
    isMulti: true,
    currentQuery: 'fetchTerms',
    type: 'terms',
    defaultOperator: stringOperators[0]
  },
  {
    key: { label: 'Language', value: 'locale' },
    value: {},
    operator: stringOperators[0],
    isMulti: false,
    currentQuery: 'fetchLanguages',
    defaultOperator: stringOperators[0]
  },
  {
    key: { label: 'Localized In', value: 'localization' },
    value: [],
    operator: stringOperators[0],
    isMulti: true,
    currentQuery: 'fetchLocales',
    defaultOperator: stringOperators[0]
  },
  {
    key: { label: 'Tags', value: 'tags' },
    value: [],
    operator: stringOperators[0],
    isMulti: true,
    currentQuery: 'fetchEntriesTags',
    defaultOperator: stringOperators[0]
  },
  {
    key: { label: 'UID', value: 'uid' },
    operator: basicOperators[0],
    value: '',
    isMulti: false,
    isText: true,
    type: 'uid',
    defaultOperator: basicOperators[0]
  },
  {
    key: { label: 'Workflow Stage', value: '_workflow' },
    value: {},
    operator: stringOperators[0],
    isMulti: false,
    currentQuery: 'fetchWorkflowStage',
    defaultOperator: stringOperators[0]
  },
  ...commonFilters
].map((ob, idx) => ({ uid: idx + 1, ...ob }))

entriesfilters = sortBy(entriesfilters, (obj) => obj.key.label)

let assetsFilters = [
  {
    key: { label: 'Text', value: '_text_assets' },
    value: '',
    operator: stringOperators[1],
    isText: true,
    isMulti: false,
    type: 'textLabel',
    defaultOperator: stringOperators[1]
  },
  {
    key: { label: 'File name', value: 'filename' },
    operator: stringOperators[1],
    value: '',
    isMulti: false,
    isText: true,
    defaultOperator: stringOperators[1]
  },
  {
    key: { label: 'URL', value: 'url' },
    operator: stringOperators[1],
    value: '',
    isMulti: false,
    isText: true,
    defaultOperator: stringOperators[1]
  },
  {
    key: { label: 'Title', value: 'title' },
    operator: stringOperators[1],
    value: '',
    isMulti: false,
    isText: true,
    defaultOperator: stringOperators[1]
  },
  {
    key: { label: 'UID', value: 'uid' },
    operator: basicOperators[0],
    value: '',
    isMulti: false,
    isText: true,
    type: 'uid',
    defaultOperator: basicOperators[0]
  },
  {
    key: { label: 'File Size', value: 'file_size' },
    operator: allOperators[0],
    value: '',
    isMulti: false,
    isNumeric: true,
    currentQuery: 'fetchFileSize',
    queryType: 'fileSize',
    defaultOperator: allOperators[0]
  },
  {
    key: { label: 'Type', value: '_content_type_uid' },
    value: [],
    operator: stringOperators[0],
    isMulti: true,
    currentQuery: 'fetchAssetTypes',
    defaultOperator: stringOperators[0]
  },
  {
    key: { label: 'Tags', value: 'tags' },
    value: [],
    operator: stringOperators[0],
    isMulti: true,
    currentQuery: 'fetchAssetTags',
    defaultOperator: stringOperators[0]
  },
  ...commonFilters
].map((ob, idx) => ({ uid: idx + 1, ...ob }))

assetsFilters = sortBy(assetsFilters, (obj) => obj.key.label)

export const fetchFilterData = async ({
  skip,
  limit,
  inputValue,
  fetchData,
  currentQuery,
  isText,
  filters,
  languages
}: any) => {
  try {
    if (currentQuery === 'fetchFileSize') {
      const fileSizeOptions = getFileSizes({ inputValue: inputValue })
      return { data: fileSizeOptions, count: fileSizeOptions.length }
    }

    if (currentQuery === 'fetchLocales') {
      const languageFilter = filters.find((f) => f.key.value === 'locale') || {}
      const localizedOptions = getLocalizedOptions({
        languages,
        selectedLanguageValue: languageFilter.value.value
      })
      return { data: localizedOptions, count: localizedOptions.length }
    }

    if (isText && inputValue) {
      return { data: [{ value: inputValue || '', label: inputValue || '', type: 'text' }], count: 1 }
    }

    const fetchDataHandler = fetchData[currentQuery]

    if (fetchDataHandler) {

      let payload: any = {
        skip,
        limit,
        inputValue
      }

      if (currentQuery === 'fetchWorkflowStage') {
        payload = { inputValue }
      }

      let response: any = await fetchDataHandler(payload)

      // if (currentQuery === 'fetchEntriesTags' || currentQuery === 'fetchAssetTags') {
      //   let filteredTags = response.data
      //   if (inputValue) {
      //     filteredTags = response.data.filter((tag) => {
      //       return tag.label.toLowerCase().includes(inputValue.toLowerCase())
      //     })
      //   }
      //   filteredTags = sortBy(filteredTags, (tag) => tag.label)
      //   response.data = filteredTags
      // }

      if (currentQuery === 'fetchWorkflowStage') {
        let filteredStages = response.data
        if (inputValue) {
          filteredStages = filteredStages.filter((stage) => {
            return stage.label.toLowerCase().includes(inputValue.toLowerCase())
          })
        }

        let groupedObj = groupBy(filteredStages, (data) => {
          return [data.workflowName]
        })
        let data = []
        Object.keys(groupedObj).forEach((k) => {
          const valueArray = groupedObj[k]
          valueArray.unshift({ group: k, value: k })
          data = [...data, ...valueArray]
        })

        return { data, count: data.length }
      }

      return response
    }
    return { data: [], count: 0 }
  } catch (error) {
    console.log('fetchFilterData -> error', error)
  }
}

const entriesFilterQueryBuilder = ({ filters }) => {
  let queryObj = { $and: [] }
  let searchText = ''

  for (let filterObj of filters) {
    const { key, operator, value } = filterObj

    if (filterObj.key.value === 'localization') continue

    if (filterObj.key.value === '_text_entries' && value) {
      searchText = value.value
      continue
    }

    if (filterObj.key.value === 'locale' && value && value.value) {
      let query = { [key.value]: { [operator.value]: value.value } }
      const localizedFilter = filters.find((f) => f.key.value === 'localization')
      const hasAny = localizedFilter.value.find((v: any) => v.value === '$any') ? true : false

      if (!hasAny) {
        const evaluatedOperator = localizedFilter.operator.value === '$eq' ? '$in' : '$nin'
        const localizationValues = localizedFilter.value.map((v) => v.value)
        query[key.value]['$localization'] = { [evaluatedOperator]: localizationValues }
      }
      queryObj.$and.push(query)
      continue
    }

    console.log("filterObj.isMulti", filterObj.isMulti, filterObj)
    if (filterObj.isMulti) {
      //content type case with value
      if (filterObj.type === 'contentType' && value.length) {
        //  handle contains case
        if (operator.value === '$eq') {
          let queryArray = value.map((v) => v.value)
          queryObj[key.value] = { $in: queryArray }
        } else if (operator.value === '$ne') {
          let queryArray = value.map((v) => v.value)
          queryObj[key.value] = { $nin: queryArray }
        } else if (operator.value === 'contains') {
          value.map((v) => {
            let query = { _content_type_uid: { $regex: `.*${v.value}.*` } }
            queryObj.$and.push(query)
          })
        }
        // without value
      } else if (filterObj.type === 'contentType') {
        queryObj[key.value] = '$all'
        //spl case publish env
      } else if ((filterObj.type === 'publishedEnvironment' || filterObj.currentQuery === 'fetchEntriesTags') && value.length) {

        let notPubEnvCaseCheck = false
        let publishedEnvValue = []
        value.forEach(pubValue => {
          if (pubValue.value === notPublishedEnvObj.value) {
            notPubEnvCaseCheck = true
          }
          else {
            publishedEnvValue.push(pubValue.value)
          }
        })

        let evaluatedOperator = operator.value === '$eq' ? '$in' : '$nin'

        if (notPubEnvCaseCheck && operator.value === '$eq') {
          const notPubQuery: any = { $or: [{ _publish_details: { $exists: false } }] }
          if (publishedEnvValue.length) {
            notPubQuery.$or.push({ _publish_environment: { $in: publishedEnvValue } })
          }
          queryObj.$and.push(notPubQuery)
        }
        else if (publishedEnvValue.length) {
          let query = { [key.value]: { [evaluatedOperator]: publishedEnvValue } }
          queryObj.$and.push(query)
        }

        //every other case
      } else {
        value.forEach((v) => {
          let query = { [key.value]: { [operator.value]: v.value } }
          queryObj.$and.push(query)
        })
      }
    } else if (filterObj.value.value && filterObj.currentQuery === 'listDate') {
      if (operator.value === '$eq') {
        let startTime = moment(value.value).startOf('day').toISOString()
        let endTime = moment(value.value).endOf('day').toISOString()
        let startTimeQuery = { [key.value]: { $gte: startTime } }
        let endTimeQuery = { [key.value]: { $lte: endTime } }
        queryObj.$and.push(startTimeQuery)
        queryObj.$and.push(endTimeQuery)
      } else if (operator.value === '$lte') {
        let startTime = moment(value.value).endOf('day').toISOString()
        let query = { [key.value]: { [operator.value]: startTime } }
        queryObj.$and.push(query)
      } else {
        let startTime = moment(value.value).startOf('day').toISOString()

        let query = { [key.value]: { [operator.value]: startTime } }
        queryObj.$and.push(query)
      }
    } else if (filterObj.value.value) {
      const { key, operator, value } = filterObj

      let query = { [key.value]: { [operator.value]: value.value } }
      queryObj.$and.push(query)
    }
  }

  let { $and, ...rest } = queryObj

  if ($and.length) {
    rest['$and'] = $and
  }

  return { queryObject: rest, search: searchText }
}

const assetFilterQueryBuilder = ({ filters }) => {
  // console.log("assetFilterQueryBuilder -> filters", filters)

  let queryObj = { $and: [] }
  let searchText = ''
  for (let filterObj of filters) {
    const { key, operator, value } = filterObj

    if (filterObj.key.value === '_text_assets' && value) {
      searchText = value.value
      continue
    }

    if (filterObj.isMulti) {
      if (filterObj.type === 'publishedEnvironment' && value.length) {
        let queryArray = value.map((v) => v.value)
        let evaluatedOperator = operator.value === '$eq' ? '$in' : '$nin'
        let query = { [key.value]: { [evaluatedOperator]: queryArray } }
        queryObj.$and.push(query)
      } else {
        value.forEach((v) => {
          let query = { [key.value]: { [operator.value]: v.value } }
          queryObj.$and.push(query)
        })
      }
    } else if (filterObj.value.value && filterObj.currentQuery === 'listDate') {
      if (operator.value === '$eq') {
        let startTime = moment(value.value).startOf('day').toISOString()
        let endTime = moment(value.value).endOf('day').toISOString()
        let startTimeQuery = { [key.value]: { $gte: startTime } }
        let endTimeQuery = { [key.value]: { $lte: endTime } }
        queryObj.$and.push(startTimeQuery)
        queryObj.$and.push(endTimeQuery)
      } else {
        let startTime = moment(value.value).startOf('day').toISOString()

        let query = { [key.value]: { [operator.value]: startTime } }
        queryObj.$and.push(query)
      }
    } else if (operator.value === 'contains' && filterObj.value.value) {
      let query = { [key.value]: { '$regex': value.value } }
      queryObj.$and.push(query)
    }
    else if (filterObj.value.value) {
      const { key, operator, value } = filterObj

      let query = { [key.value]: { [operator.value]: value.value } }
      queryObj.$and.push(query)
    }
  }
  let { $and, ...rest } = queryObj

  if ($and.length) {
    rest['$and'] = $and
  }

  console.log('assets-filter-output', rest)

  if (Object.keys(rest).length) {
    return { queryObject: rest, search: searchText }
  } else {
    return { search: searchText }
  }
}

export const filterQueryObjectBuilder = ({ filters, selectedModuleVal }) => {
  if (selectedModuleVal === options[0].value) {
    return entriesFilterQueryBuilder({ filters })
  }
  if (selectedModuleVal === options[1].value) {
    return assetFilterQueryBuilder({ filters })
  }
}

export const getFiltersBySelectedOption = (selectedOption) => {
  if (selectedOption.value === options[0].value) {
    return JSON.parse(JSON.stringify(entriesfilters))
  }
  if (selectedOption.value === options[1].value) {
    return JSON.parse(JSON.stringify(assetsFilters))
  }
  return []
}

export const getAdvanceQueryByFilters = (filters) => {
  let advanceQueryFilters = [
    {
      queryType: 'matchAll',
      root: true,
      queryArray: [],
      children: []
    }
  ]

  for (let filter of filters) {
    const { key, operator, value, isMulti } = filter

    if (isMulti && key.value === 'localization' && value.length) {
      const queryObject: any = { key: [key], operator: [operator] }
      if (value.length >= 2) {
        queryObject.value = [
          {
            label: `${value.length} Selected`,
            value: value
          }
        ]
      } else if (value.length === 1) {
        queryObject.value = [value[0]]
      }

      queryObject.queryType = localizedInLabel.queryType

      advanceQueryFilters[0].queryArray.push(queryObject)
      continue
    }

    if (isMulti && value.length) {
      let multiFilter = {
        queryType: 'matchAny',
        children: [],
        queryArray: []
      }
      value.map((v) => {
        const queryObject = { key: [key], operator: [operator], value: [v] }
        multiFilter.queryArray.push(queryObject)
      })
      advanceQueryFilters[0].children.push(multiFilter)
      continue
    }

    if (!isMulti && value.value) {
      const queryObject: any = { key: [key], operator: [operator], value: [value] }
      if (key.value === 'locale') {
        queryObject.queryType = languageLabel.queryType
      }
      advanceQueryFilters[0].queryArray.push(queryObject)
    }
  }

  return advanceQueryFilters
}

export const checkSearchFilterValue = (filters) => {
  let isValid = false;

  let hasLanguageValue = false;
  let hasLocalizedValue = false;
  let hasNonEmptyValue = false;

  for (const filter of filters) {
    const { value, key } = filter;

    const hasValue = !isEmpty(value);

    if (key.value === 'locale') {
      hasLanguageValue = hasValue;
    }

    if (key.value === 'localization') {
      hasLocalizedValue = hasValue;
    }

    if (hasValue) {
      hasNonEmptyValue = true;
    }
  }

  if (hasLanguageValue && !hasLocalizedValue) {
    isValid = false;
    return isValid
  }

  if (!hasNonEmptyValue) {
    isValid = false;
    return isValid
  }

  return true
}

// const queryArray = [
//   {
//     key: [
//       {
//         label: 'Content type',
//         value: 'contenttype',
//       },
//     ],
//     operator: [
//       {
//         name: 'Equals',
//         icon: 'Equals',
//         value: 'equals',
//       },
//     ],
//     value: [
//       {
//         label: 'content type1',
//         value: 'content type1',
//       },
//     ],
//     isQueryCompleted: true,
//     queryType: 'ContentTypeLabel',
//     nextQuery: 'inContentType',
//   },
// ]

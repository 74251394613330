import React, { useState, useMemo, useEffect } from 'react'
import cn from 'classnames'

import Icon from '../Icon2/Icon'
import Line from '../Line/Line'

import './AutomationAccordion.css'

export type accordionProps = {
  accordionDataLock?: boolean
  accordiondata?: string
  addLeftComponent?: React.ReactNode
  addRightComponent?: React.ReactNode
  children?: React.ReactNode
  className?: string
  noDefaultIcon?: boolean
  preRender?: boolean
  renderExpanded?: boolean
  setTitleOnHide?: any | React.ReactNode
  title: string | React.ReactNode
  accordiondataCount?: number
  backGroundColor?: boolean
  /**
   * Actions List of {component: ReactNode, onClick: () => void}
   */
  actions?: AccordionActionsList

  accordionState?: boolean | undefined
  onClick?: () => void
  errorMsg?: string
  dashedLineVisibility?: 'hover' | 'always'
}

type accordionTitleProps = {
  accordionDataLock?: boolean
  addLeftComponent?: React.ReactNode
  addRightComponent?: React.ReactNode
  accordionDataCount: number
  noDefaultIcon?: boolean
  setTitleOnHide: any | React.ReactNode | undefined
  title: string | React.ReactNode
  toggle: Function
  toggleStat?: boolean
  actions?: AccordionActionsList
  onClick?: () => void
  error?: string
  backGroundColor?: boolean
  dashedLineVisibility?: 'hover' | 'always'
}

type accordionDataProps = {
  accordionData: string | React.ReactNode
}
type AccordionAction = {
  component: React.ReactNode
  onClick?: () => void
  className?: string
}

export type AccordionActionsList = AccordionAction[]

const AccordionTitle = (props: accordionTitleProps) => {
  const noDefaultIcon = props.noDefaultIcon !== undefined ? props.noDefaultIcon : false

  const getAccordionTitle = () => {
    if (!props.toggleStat) {
      return props.setTitleOnHide ? props.setTitleOnHide : props.title
    } else return props.title
  }

  const dashedLineClassname = cn('Accordion__heading__line', {
    visible: props.dashedLineVisibility === 'always'
  })

  return useMemo(() => {
    const handleToggle = () => {
      if (!props.accordionDataLock) {
        if (props.onClick) props.onClick()
        props.toggle()
      }
    }

    return (
      <>
        <div className={`Accordion__heading`} onClick={handleToggle}>
          <div className="flex">
            {props.addLeftComponent ? props.addLeftComponent : null}
            <div className={`Accordion__heading__title ${props.addLeftComponent ? 'ml-8' : ''}`}>
              {getAccordionTitle()}
            </div>
          </div>
          {props.accordionDataCount ? (
            <div className="Accordion__heading__field-count">{props.accordionDataCount}</div>
          ) : (
            ''
          )}
          {noDefaultIcon === false ? (
            props.addRightComponent ? (
              props.addRightComponent
            ) : (
              <>
                <div className={`Accordion__heading__toggle`}>
                  <Icon className={props.toggleStat === true ? 'rotate-180' : ''} icon="DownArrowEnabled" />
                </div>
                <div className="flex-v-center flex-grow-1">
                  {props.error && <div className="Accordion__heading__errorMsg">{props.error}</div>}
                  <span className={dashedLineClassname}>
                    <Line type="dashed" />
                  </span>
                </div>
                {props.actions && <AccordionActions actions={props.actions} />}
              </>
            )
          ) : null}
        </div>
      </>
    )
  }, [
    props.toggleStat,
    props.accordionDataCount,
    props.title,
    props.error,
    props.addLeftComponent,
    props.addRightComponent
  ])
}

export const AccordionData = (props: accordionDataProps) => {
  return <div className="Accordion__data">{props.accordionData}</div>
}

const AccordionActions = ({ actions }: { actions: AccordionAction[] }) => {
  return (
    <div className="Accordion__actions">
      {actions?.map((action: AccordionAction, index) => {
        const actionClassname = cn('actions_list_item', action.className)
        return (
          <div
            className={actionClassname}
            onClick={(event) => {
              event.stopPropagation()
              action.onClick()
            }}
            key={index}>
            {action.component}
          </div>
        )
      })}
    </div>
  )
}

const AutomationAccordion = (props: accordionProps) => {
  const [toggleShow, setToggleShow] = useState(props.renderExpanded)

  const {
    title,
    accordiondata,
    children,
    noDefaultIcon,
    accordiondataCount,
    backGroundColor,
    dashedLineVisibility
  }: accordionProps = props

  const [preRender, setPreRender] = useState(props.preRender)

  const accordionData = accordiondata !== undefined ? accordiondata : children

  const accordionDataCount = accordiondataCount

  const toggleView = () => {
    setToggleShow(!toggleShow)
    if (preRender === false) {
      setPreRender(true)
    }
  }

  const classNames = cn('Accordion', props.className, {
    'Accordion--backgroundColor': backGroundColor
  })

  let toggleState = toggleShow

  if (props.accordionState !== undefined) {
    toggleState = props.accordionState
  }

  return (
    <div className={classNames}>
      <AccordionTitle
        title={title}
        setTitleOnHide={props.setTitleOnHide}
        toggleStat={toggleState}
        toggle={toggleView}
        backGroundColor={backGroundColor}
        noDefaultIcon={noDefaultIcon}
        addLeftComponent={props.addLeftComponent}
        addRightComponent={props.addRightComponent}
        accordionDataCount={accordionDataCount}
        accordionDataLock={props.accordionDataLock}
        actions={props.actions}
        onClick={props.onClick}
        error={props.errorMsg}
        dashedLineVisibility={dashedLineVisibility}
      />
      {preRender === false ? (
        toggleState === true ? (
          <div className="Accordion__open">
            <AccordionData accordionData={accordionData} />
          </div>
        ) : null
      ) : (
        <div className={`${toggleState ? 'Accordion__open' : 'Accordion__collapsed'}`}>
          <AccordionData accordionData={accordionData} />
        </div>
      )}
    </div>
  )
}

AutomationAccordion.defaultProps = {
  renderExpanded: false,
  preRender: false,
  noDefaultIcon: false,
  accordionDataLock: false,
  backGroundColor: false,
  dashedLineVisibility: 'hover'
} as Partial<accordionProps>

export default AutomationAccordion
//@ts-nocheck
import { jsx } from 'slate-hyperscript'
import { v4 } from 'uuid'
import { kebabCase, isEmpty, flatten, isObject, cloneDeep, isArray, isPlainObject, reduce } from 'lodash'
import { isInlineElement } from '../queries'
import collapse from 'collapse-whitespace'
import { getHTMLAttributesJson } from './utils'

const generateId = () => v4().split('-').join('')
const isInline = ['span', 'a', 'inlineCode', 'reference']
const isVoid = ['img', 'embed', 'social-embeds']

const ELEMENT_TAGS: { [key: string]: Function } = {
  A: (el: HTMLElement) => {
    return ({
      type: 'a',
      attrs: {
        url: el.getAttribute('href'),
        target: el.getAttribute('target')
      }
    })
  },
  BLOCKQUOTE: () => ({ type: 'blockquote', attrs: {} }),
  H1: () => ({ type: 'h1', attrs: {} }),
  H2: () => ({ type: 'h2', attrs: {} }),
  H3: () => ({ type: 'h3', attrs: {} }),
  H4: () => ({ type: 'h4', attrs: {} }),
  H5: () => ({ type: 'h5', attrs: {} }),
  H6: () => ({ type: 'h6', attrs: {} }),
  IMG: (el: HTMLElement) => {
    return { type: 'img', attrs: { url: el.getAttribute('src') } }
  },
  LI: () => ({ type: 'li', attrs: {} }),
  OL: () => ({ type: 'ol', attrs: {} }),
  P: () => ({ type: 'p', attrs: {} }),
  PRE: () => ({ type: 'code', attrs: {} }),
  UL: () => ({ type: 'ul', attrs: {} }),
  IFRAME: (el: HTMLElement) => ({ type: 'embed', attrs: { url: el.getAttribute('src') } }),
  EMBEDS: (el: HTMLElement) => ({ type: 'social-embeds', attrs: { src: el.getAttribute('src') } }),
  TABLE: (el: HTMLElement) => ({ type: 'table', attrs: {} }),
  THEAD: (el: HTMLElement) => ({ type: 'thead', attrs: {} }),
  TBODY: (el: HTMLElement) => ({ type: 'tbody', attrs: {} }),
  TR: (el: HTMLElement) => ({ type: 'tr', attrs: {} }),
  TD: (el: HTMLElement) => ({ type: 'td', attrs: { ...spanningAttrs(el) } }),
  TH: (el: HTMLElement) => ({ type: 'th', attrs: { ...spanningAttrs(el) } }),
  FIGURE: (el: HTMLElement) => {
    return { type: 'img', attrs: {} }
  },
  SPAN: (el: HTMLElement) => {
    return { type: 'span', attrs: {} }
  },
  DIV: (el: HTMLElement) => {
    return { type: 'div', attrs: {} }
  },
  STYLE: (el: HTMLElement) => {
    return { type: 'style', attrs: { 'style-text': el.textContent } }
  },
  SCRIPT: (el: HTMLElement) => {
    return { type: 'script', attrs: {} }
  },
  HEADER: (el: HTMLElement) => {
    return { type: 'header', attrs: {} }
  }
}

export const NODENAME_TO_MARKNAME_MAP = {
  CODE: "code",
  DEL: "strikethrough",
  EM: "italic",
  I: "italic",
  S: "strikethrough",
  STRONG: "bold",
  U: "underline",
  SUP: "superscript",
  SUB: "subscript",
} as const

const TEXT_TAGS: { [key: string]: Function } = Object.fromEntries(
  Object.entries(NODENAME_TO_MARKNAME_MAP).map(([nodeName, markName]) => (
    [nodeName, (el: HTMLElement) => ({ [markName]: true, attrs: { style: {}, "redactor-attributes": { [markName]: getHTMLAttributesJson(el) } } })]
  ))
)

const spanningAttrs = (el: HTMLElement) => {
  const attrs = {}
  const rowSpan = parseInt(el.getAttribute('rowspan') ?? '1')
  const colSpan = parseInt(el.getAttribute('colspan') ?? '1')
  if (rowSpan > 1) attrs['rowSpan'] = rowSpan
  if (colSpan > 1) attrs['colSpan'] = colSpan

  return attrs
}
const emptyCell = (cellType, attrs = {}) => {
  return jsx('element', { type: cellType, attrs: { void: true, ...attrs } }, [{ text: '' }])
}

const addVoidCellsAndApplyAttributes = (rows) => {
  rows.forEach((row, currIndex) => {
    const cells = row.children

    cells.forEach((cell, cellIndex) => {
      if (!cell || !cell.attrs) return

      const { rowSpan, inducedRowSpan } = cell.attrs

      let span = rowSpan ?? inducedRowSpan ?? 0

      if (!span || span < 2) return
      const nextRow = rows[currIndex + 1]
      if (!nextRow) {
        delete cell?.attrs?.inducedRowSpan
        return
      }

      // set inducedRowSpan on cell in row at cellIndex
      span--
      nextRow?.children?.splice(cellIndex, 0,
        emptyCell('td',
          (span > 1) ? { inducedRowSpan: span } : {}
        ))

      // Include next row in trgrp
      nextRow['attrs']['included'] = true

      // Make a new trgrp
      if (rowSpan) {
        row['attrs']['origin'] = true
      }

      delete cell?.['attrs']?.['inducedRowSpan']

    })
  })
}
const getTbodyChildren = (rows) => {
  const newTbodyChildren = rows.reduce((tBodyChildren, row, rowIndex) => {

    const { included, origin } = row.attrs
    const l = tBodyChildren.length

    if (included || origin) {

      if (origin && !included) {
        tBodyChildren.push(jsx('element', { type: 'trgrp' }, row))
      }
      if (included) {
        tBodyChildren[l - 1].children.push(row)

      }
      delete row['attrs']['included']
      delete row['attrs']['origin']
      return tBodyChildren
    }

    tBodyChildren.push(row)
    return tBodyChildren

  }, [])
  return newTbodyChildren
}

const generateFragment = (child: any) => {
  return { type: 'fragment', attrs: {}, uid: generateId(), children: [child] }
}
const traverseChildAndModifyChild = (element: any, attrsForChild: any) => {
  if (element.hasOwnProperty('text')) {
    let attrsForChildCopy = cloneDeep(attrsForChild)
    delete attrsForChildCopy.style
    let style = { ...attrsForChild?.attrs?.style, ...element?.attrs?.style }
    const redactorAttributes = { ...attrsForChild?.attrs?.['redactor-attributes'], ...element?.attrs?.['redactor-attributes'] }
    if (element?.attrs) {
      element.attrs.style = style
    } else {
      element.attrs = { style: style }
    }
    Object.entries(attrsForChildCopy).forEach(([key, value]) => {
      element[key] = value
    })
    element.attrs['redactor-attributes'] = redactorAttributes
    return
  }
  Array.from(element.children || [])
    .map((el) => traverseChildAndModifyChild(el, attrsForChild))
    .flat()
  return
}
const traverseChildAndWarpChild = (children: Array<Object>) => {
  let inlineElementIndex: Array<number> = []
  let hasBlockElement = false
  let childrenCopy = cloneDeep(children)
  Array.from(children).forEach((child: any, index) => {
    if (child.hasOwnProperty('text')) {
      inlineElementIndex.push(index)
      return
    }
    if (child.hasOwnProperty('type')) {
      // Ignore image from being considered as inline/block and to keep as it is
      if (!isInline.includes(child.type) && child.type === 'img' && child?.attrs?.inline) return
      
      if (isInline.includes(child.type)) {
        if (child.type === 'reference') {
          if (child.attrs && (child.attrs['display-type'] === 'inline' || child.attrs['display-type'] === 'link')) {
            inlineElementIndex.push(index)
          } else {
            hasBlockElement = true
          }
        } else {
          inlineElementIndex.push(index)
        }
      } else {
        hasBlockElement = true
      }
    } else {
      childrenCopy[index] = jsx('text', {}, child)
      inlineElementIndex.push(index)
    }
  })
  if (hasBlockElement && !isEmpty(inlineElementIndex)) {
    Array.from(inlineElementIndex).forEach((child) => {
      children[child] = generateFragment(childrenCopy[child])
      children[child]?.children?.map((child) => {
        if (!child.children?.text) {
          child.children?.unshift({ text: '' })
        }
      })
    })
  }
  return children
}
declare type customelement = Node & {
  parentNode: any
  attributes: any
}
declare type allowExtraTags = {
  script: boolean
  style: boolean
}
const whiteCharPattern = /^[\s ]{2,}$/

const tableElements = ['TABLE', 'COLGROUP', 'COL', 'TBODY', 'THEAD', 'TR'];

const filterTableChildren = (child, nodeName) => {
  if (tableElements.includes(nodeName)) {
    return child.filter((c) => isPlainObject(c) && c.hasOwnProperty('type'));
  }
  return child;
}

export const fromRedactor = (el?: HTMLElement, allowExtraTags?: allowExtraTags) => {
  if (el.nodeType === 3) {
    if (whiteCharPattern.test(el.textContent)) return null
    if (el.textContent === '\n') {
      return null
    }
    if (el.textContent === '' && el?.parentElement?.nodeName === 'A' && el?.parentElement?.firstChild?.nodeName === 'IMG') {
      return null
    }
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    let attributes = getHTMLAttributesJson(el)
    let brTextNode = { text: '\n', break: false, separaterId: generateId() }
    if (!isEmpty(attributes)) {
      brTextNode.attrs = {
        'redactor-attributes': attributes
      }
    }
    return brTextNode
  } else if (el.nodeName === 'WBR') {
    return { text: '\u200b' }
  } else if (el.nodeName === 'META') {
    return null
  } else if (el.nodeName === 'COLGROUP') {
    return null
  } else if (el.nodeName === 'LI' || el.nodeName === 'IFRAME') {
    //TODO: move collapse to src/components/RichTextEditor/RichTextEditor.tsx - getJsonValue
    collapse(el)
  }
  else if (el.nodeName === "TABLE") {
    const tbody = el.querySelector('tbody')
    const thead = el.querySelector('thead')

    if (!tbody && !thead) {
      el.append(document.createElement('tbody'))
    }
  }
  else if (['TBODY', 'THEAD'].includes(el.nodeName)) {
    const row = el.querySelector('tr')
    if (!row) {
      const tr = document.createElement('tr')
      el.append(tr)
    }
  }
  else if (el.nodeName === 'TR') {
    const cell = el.querySelector('th, td')
    if (!cell) {
      const cellType = el.parentElement.nodeName === 'THEAD' ? 'th' : 'td'
      const cell = document.createElement(cellType)
      el.append(cell)
    }
  }
  let { nodeName } = el
  if (el.getAttribute('data-type') === 'social-embeds') {
    nodeName = 'EMBEDS'
  }
  let parent = el

  let children: any = flatten(Array.from(parent.childNodes).map((child) => fromRedactor(child, allowExtraTags)))

  children = children.filter((child: any) => child !== null)
  children = filterTableChildren(children, nodeName)
  children = traverseChildAndWarpChild(children)
  if (children.length === 0) {
    if (nodeName === "A") {
      children = [{ text: '\n', break: false, separaterId: generateId() }]
    }
    else {
      children = [{ text: '' }]
    }
  }
  if (el.nodeName === 'BODY') {
    if (el.childNodes.length === 1 && el.childNodes[0].nodeType === 3) {
      return jsx('fragment', {}, [
        { type: 'p', attrs: {}, uid: generateId(), children: [{ text: el.childNodes[0].textContent }] }
      ])
    }
    if (el.childNodes.length === 0) {
      return jsx('fragment', {}, [{ type: 'p', attrs: {}, children: [{ text: '' }], uid: generateId() }])
    }
    // if all children inline add fragment wrapper
    let isAllInline = children.every((child) => isInlineElement(child))
    if (isAllInline) {
      children = jsx('fragment', {}, children)
      children = [{ type: 'fragment', attrs: {}, children: children }]
    }
    return jsx('fragment', {}, children)
  }


  if (nodeName === "STYLE" && (allowExtraTags?.style !== true)) {

    return children
  }
  if (nodeName === 'SCRIPT' && allowExtraTags?.script !== true) {
    return children
  }
  if (!Object.keys(ELEMENT_TAGS).includes(nodeName) && !Object.keys(TEXT_TAGS).includes(nodeName)) {
    const attributes = el.attributes
    const attribute = {}
    Array.from(attributes).forEach((child: any) => {
      attribute[child.nodeName] = child.nodeValue
    })

    return jsx('element', { type: nodeName.toLowerCase(), attrs: { 'redactor-attributes': attribute } }, children)
  }

  const isEmbedEntry = el.attributes['data-sys-entry-uid']?.value
  const type = el.attributes['type']?.value
  if (isEmbedEntry && type === 'entry') {
    const entryUid = el.attributes['data-sys-entry-uid']?.value
    const contentTypeUid = el.attributes['data-sys-content-type-uid']?.value
    const displayType = el.attributes['data-sys-style-type']?.value || el.attributes['sys-style-type']?.value
    const locale = el.attributes['data-sys-entry-locale']?.value
    if (entryUid && contentTypeUid && displayType && locale) {
      let elementAttrs: any = { attrs: { style: {} } }
      const attributes = el.attributes
      const attribute = Array.from(attributes).map((child: any) => {
        return {
          [child.nodeName]: child.nodeValue
        }
      })
      const redactor = Object.assign({}, ...attribute)
      if (redactor['id']) {
        elementAttrs = { ...elementAttrs, attrs: { ...elementAttrs['attrs'], id: redactor['id'] } }
        delete redactor['id']
      }
      if (redactor['class']) {
        elementAttrs = { ...elementAttrs, attrs: { ...elementAttrs['attrs'], 'class-name': redactor['class'] } }
        delete redactor['class']
      }
      if (redactor['data-sys-entry-uid']) {
        delete redactor['data-sys-entry-uid']
      }
      if (redactor['data-sys-content-type-uid']) {
        delete redactor['data-sys-content-type-uid']
      }
      if (redactor['data-sys-style-type']) {
        delete redactor['data-sys-style-type']
      }
      if (redactor['sys-style-type']) {
        delete redactor['sys-style-type']
      }
      if (redactor['data-sys-entry-locale']) {
        delete redactor['data-sys-entry-locale']
      }
      if (el.style) {
        delete redactor['style']
        let allStyleAttrs: { [key: string]: any } = {}
        Array.from({ length: el.style.length }).forEach((child, index) => {
          let property = el.style.item(index)
          allStyleAttrs[kebabCase(property)] = el.style.getPropertyValue(property)
        })
        elementAttrs = {
          ...elementAttrs,
          attrs: { ...elementAttrs['attrs'], style: { ...elementAttrs.attrs['style'], ...allStyleAttrs } }
        }
      }
      if (displayType === 'link') {
        elementAttrs.attrs.href = redactor['href']
        delete redactor['href']
      }
      elementAttrs.attrs['redactor-attributes'] = redactor
      return jsx(
        'element',
        {
          attrs: {
            ...elementAttrs?.attrs,
            'entry-uid': entryUid,
            'content-type-uid': contentTypeUid,
            'display-type': displayType,
            locale
          },
          type: 'reference',
          uid: generateId()
        },
        children
      )
    }
  }
  const isEmbedAsset = el.attributes['data-sys-asset-uid']?.value
  if (isEmbedAsset && type === 'asset') {
    const fileLink = el.attributes['data-sys-asset-filelink']?.value
    const uid = el.attributes['data-sys-asset-uid']?.value
    const fileName = el.attributes['data-sys-asset-filename']?.value
    const contentType = el.attributes['data-sys-asset-contenttype']?.value
    const displayType = el.attributes['type']?.value
    const caption = el.attributes['data-sys-asset-caption']?.value
    const alt = el.attributes['data-sys-asset-alt']?.value
    const link = el.attributes['data-sys-asset-link']?.value
    const position = el.attributes['data-sys-asset-position']?.value
    const target = el.attributes['data-sys-asset-isnewtab']?.value ? '_blank' : '_self'
    const contentTypeUid = el.attributes['content-type-uid']?.value || 'sys_assets'
    if (uid && fileName && contentType) {
      let elementAttrs: any = { attrs: { style: {} } }
      const attributes = el.attributes
      const attribute = Array.from(attributes).map((child: any) => {
        return {
          [child.nodeName]: child.nodeValue
        }
      })
      const redactor = Object.assign({}, ...attribute)
      if (redactor['id']) {
        elementAttrs = { ...elementAttrs, attrs: { ...elementAttrs['attrs'], id: redactor['id'] } }
        delete redactor['id']
      }
      if (redactor['class']) {
        elementAttrs = { ...elementAttrs, attrs: { ...elementAttrs['attrs'], 'class-name': redactor['class'] } }
        delete redactor['class']
      }
      if (redactor['data-sys-asset-filelink']) {
        delete redactor['data-sys-asset-filelink']
      }
      if (redactor['data-sys-asset-uid']) {
        delete redactor['data-sys-asset-uid']
      }
      if (redactor['data-sys-asset-filename']) {
        delete redactor['data-sys-asset-filename']
      }
      if (redactor['data-sys-asset-contenttype']) {
        delete redactor['data-sys-asset-contenttype']
      }
      if (redactor['width']) {
        let width = parseInt(redactor['width'])
        if (isNaN(width)) {
          width = 100
        }
        elementAttrs.attrs.width = width
        delete redactor['width']
      }
      if (redactor['content-type-uid']) {
        delete redactor['content-type-uid']
      }
      if (redactor['data-sys-asset-caption']) {
        delete redactor['data-sys-asset-caption']
      }
      if (redactor['data-sys-asset-alt']) {
        delete redactor['data-sys-asset-alt']
      }
      if (redactor['data-sys-asset-link']) {
        delete redactor['data-sys-asset-link']
      }
      if (redactor['data-sys-asset-position']) {
        delete redactor['data-sys-asset-position']
      }
      if (redactor['data-sys-asset-isnewtab']) {
        delete redactor['data-sys-asset-isnewtab']
      }
      if (el.style) {
        delete redactor['style']
        let allStyleAttrs: { [key: string]: any } = {}
        Array.from({ length: el.style.length }).forEach((child, index) => {
          let property = el.style.item(index)
          allStyleAttrs[kebabCase(property)] = el.style.getPropertyValue(property)
        })
        elementAttrs = {
          ...elementAttrs,
          attrs: { ...elementAttrs['attrs'], style: { ...elementAttrs.attrs['style'], ...allStyleAttrs } }
        }
      }
      elementAttrs.attrs['redactor-attributes'] = redactor
      return jsx(
        'element',
        {
          attrs: {
            ...elementAttrs?.attrs,
            'asset-caption': caption,
            link: link,
            'asset-alt': alt,
            target,
            position,
            'asset-link': fileLink,
            'asset-uid': uid,
            'display-type': displayType,
            'asset-name': fileName,
            'asset-type': contentType,
            'content-type-uid': contentTypeUid
          },
          type: 'reference',
          uid: generateId()
        },
        children
      )
    }
  }
  if (nodeName === 'FIGCAPTION') {
    return null
  }
  if (nodeName === 'DIV') {
    const dataType = el.attributes['data-type']?.value
    if (dataType === 'row') {
      const attrs = {
        type: 'row',
        uid: generateId()
      }
      return jsx('element', attrs, children)
    }
    if (dataType === 'column') {
      const { width } = el.attributes
      const attrs = {
        type: 'column',
        uid: generateId(),
        meta: {
          width: Number(width.value)
        }
      }
      return jsx('element', attrs, children)
    }
    if (dataType === 'grid-container') {
      const gutter = el.attributes?.['gutter']?.value
      const attrs = {
        type: 'grid-container',
        attrs: {
          gutter
        }
      }
      return jsx('element', attrs, children)
    }
    if (dataType === 'grid-child') {
      const gridRatio = el.attributes?.['grid-ratio']?.value
      const attrs = {
        type: 'grid-child',
        attrs: {
          gridRatio
        }
      }
      return jsx('element', attrs, children)
    }
    if (dataType === 'hr') {
      return jsx(
        'element',
        {
          type: 'hr',
          uid: generateId()
        },
        [
          {
            text: ''
          }
        ]
      )
    }
  }

  if (ELEMENT_TAGS[nodeName]) {
    if (el.nodeName === 'P') {
      children = children.map((child: any) => {
        if (typeof child === 'string') {
          return child.replace(/\n/g, ' ')
        }
        return child
      })
    }

    if (el.parentNode.nodeName === 'PRE') {
      return el.outerHTML
    }

    if (el.closest('pre') && el.nodeName !== 'PRE') {
      return null
    }

    let elementAttrs = ELEMENT_TAGS[nodeName](el)
    const attributes = el.attributes
    if (attributes.length !== 0) {
      const attribute = Array.from(attributes).map((child: any) => {
        return {
          [child.nodeName]: child.nodeValue
        }
      })
      const redactor = Object.assign({}, ...attribute)
      if (redactor['id']) {
        elementAttrs = { ...elementAttrs, attrs: { ...elementAttrs['attrs'], id: redactor['id'] } }
      }
      if (redactor['class']) {
        elementAttrs = { ...elementAttrs, attrs: { ...elementAttrs['attrs'], 'class-name': redactor['class'] } }
      }
      if (el.getAttribute('style')) {
        let allStyleAttrs: { [key: string]: any } = {}
        let styles = el.getAttribute('style')?.split?.(';')
        Array.from(styles).forEach((style) => {
          let [property, ...value] = style?.split?.(':')
          property = property.trim()
          value = value.join(':').trim()
          if (property && value) allStyleAttrs[property] = value
        })
        if (redactor?.width && allStyleAttrs?.width && redactor.width !== allStyleAttrs.width) {
          allStyleAttrs.width = redactor.width
        }
        if (redactor?.height && allStyleAttrs?.height && redactor.height !== allStyleAttrs.height) {
          allStyleAttrs.height = redactor.height
        }
        elementAttrs = {
          ...elementAttrs,
          attrs: { ...elementAttrs['attrs'], style: { ...elementAttrs.attrs['style'], ...allStyleAttrs } }
        }
      }
      if (el.style && (el.style.getPropertyValue('text-align') || el.style.getPropertyValue('float'))) {
        const alignStyle = el.style.getPropertyValue('text-align')
          ? el.style.getPropertyValue('text-align')
          : el.style.getPropertyValue('float')
        elementAttrs = {
          ...elementAttrs,
          attrs: { ...elementAttrs['attrs'], style: { ...elementAttrs.attrs['style'], 'text-align': alignStyle } }
        }
      }
      if (redactor['data-sys-asset-uid']) {
        elementAttrs = {
          ...elementAttrs,
          attrs: { ...elementAttrs['attrs'], 'data-sys-asset-uid': redactor['data-sys-asset-uid'] }
        }
      }

      elementAttrs = { ...elementAttrs, attrs: { ...elementAttrs['attrs'], 'redactor-attributes': redactor } }
    }
    if (!elementAttrs['uid']) {
      elementAttrs['uid'] = generateId()
    }

    if (nodeName === 'FIGURE') {
      let newChildren = children.filter((child: any) => {
        if (typeof child === 'string') {
          return child.trim() !== ''
        }
        return true
      })
      // this is required because redactor often has blank space between tags
      // which is interpreted as children in our code
      let { style } = elementAttrs.attrs
      let extraAttrs: { [key: string]: any } = {
        position: null
      }
      if (style && style['text-align']) {
        extraAttrs.position = style['text-align']
      }
      let sizeAttrs: { [key: string]: any } = {}
      if (el.style?.width) {
        sizeAttrs.width = el.style.width

      }
      if (el.style?.['max-width']) {
        sizeAttrs['max-width'] = el.style['max-width']
      }
      let captionElements = el.getElementsByTagName('FIGCAPTION')

      if (captionElements?.[0]) {
        let caption = captionElements[0]
        const captionElementsAttrs = caption.attributes
        const captionAttrs = {}
        if (captionElementsAttrs) {
          Array.from(captionElementsAttrs).forEach((child: any) => {
            captionAttrs[child.nodeName] = child.nodeValue
          })
        }
        extraAttrs['captionAttrs'] = captionAttrs
        extraAttrs['caption'] = captionElements?.[0]?.textContent
      }

      //three possibilities:
      // 1. figure tag has only img element (with or without figcaption, with or without anchor link)
      // 2. figure tag has element/elements other than the the img element
      // 3. figure tag has both img and any other inline/block element

      //1. figure tag has only img element (with or without figcaption, with or without anchor link)
      let figureWithOnlyImageElements = Array.from(newChildren).every((child) => {
        return child?.type === 'img' || child.type === 'a' || child.type === 'figcaption'
      })

      if (figureWithOnlyImageElements) {
        if (newChildren[0].type === 'a') {
          const { href, target } = newChildren[0].attrs?.['redactor-attributes']
          extraAttrs['anchorLink'] = href
          if (target && target !== '') {
            extraAttrs['target'] = true
          }
          const imageAttrs = newChildren[0].children[0]
          elementAttrs = getImageAttributes(elementAttrs, imageAttrs.attrs, { ...extraAttrs, ...sizeAttrs })
        }
        if (newChildren[0].type === 'img') {
          if (newChildren[0].attrs.width) {
            sizeAttrs.width = newChildren[0].attrs.width.toString()
          }
          elementAttrs = getImageAttributes(
            elementAttrs,
            { ...newChildren[0].attrs, ...sizeAttrs },
            { ...extraAttrs, ...sizeAttrs }
          )
        }
        return jsx('element', elementAttrs, [{ text: '' }])
      }
      else {
        //2. figure tag has element/elements other than the the img element 
        let imgElementInsideFigure = Array.from(newChildren).find((child) => {
          return child?.type === 'img' || child.type === 'a'
        })
        let imgElementIndex = newChildren.indexOf(imgElementInsideFigure)

        if (!imgElementInsideFigure) {
          let attrs = {}
          elementAttrs = {
            type: "figure",
            attrs,
          }
          return jsx('element', elementAttrs, newChildren)
        }
        //3. figure tag has both img and any other inline/block element
        let isWithOtherBlockElement = true

        let figCaptionElement = Array.from(newChildren).find((child) => {
          return child?.type === 'figcaption'
        })

        if (figCaptionElement) {
          let figCaptionElementIndex = newChildren.indexOf(figCaptionElement)

          //remove figcaption child
          newChildren.splice(figCaptionElementIndex, 1)

          //get image attributes for the img element present inside the figure tag
          if (newChildren[imgElementIndex].attrs.width) {
            sizeAttrs.width = newChildren[imgElementIndex].attrs.width.toString()
          }
          newChildren[imgElementIndex] = getImageAttributes(
            elementAttrs,
            { ...newChildren[imgElementIndex].attrs, ...sizeAttrs },
            { ...extraAttrs, ...sizeAttrs },
            isWithOtherBlockElement
          )

          //add children to the img element
          newChildren[imgElementIndex]['children'] = [{ text: '' }]
          // change the element to "figure" as the wrapper here
          let attrs = { ...newChildren[imgElementIndex]['attrs'] }
          elementAttrs = {
            type: "figure",
            attrs,
          }
          return jsx('element', elementAttrs, newChildren)
        }

        if (newChildren[imgElementIndex].attrs.width) {
          sizeAttrs.width = newChildren[imgElementIndex].attrs.width.toString()
        }
        newChildren[imgElementIndex] = getImageAttributes(
          elementAttrs,
          { ...newChildren[imgElementIndex].attrs, ...sizeAttrs },
          { ...extraAttrs, ...sizeAttrs },
          isWithOtherBlockElement
        )

        //add children to the img element
        newChildren[imgElementIndex]['children'] = [{ text: '' }]

        // change the element to "figure" as the wrapper here has to be figure
        let attrs = {}
        elementAttrs = {
          type: "figure",
          attrs,
        }
        return jsx('element', elementAttrs, newChildren)
      }
    }

    if (nodeName === 'A') {
      let hasBlockChildren = false
      let newChildren = children.filter((child: any) => {
        if (typeof child === 'string') {
          return child.trim() !== ''
        }
        return true
      })
      if (newChildren.length === 1 && newChildren[0]?.type === 'img') {
        let extraAttrs: { [key: string]: any } = {}
        const { href, target } = elementAttrs.attrs?.['redactor-attributes'] || {}
        extraAttrs['anchorLink'] = href ? href : '#'
        if (target && target !== '') {
          extraAttrs['target'] = true
        }
        const imageAttrs = newChildren[0]
        elementAttrs = getImageAttributes(imageAttrs, imageAttrs.attrs, extraAttrs)
        return jsx('element', elementAttrs, [{ text: '' }])
      }
      else {
        if (isArray(newChildren)) {
          hasBlockChildren = newChildren.some((child: any) => {
            return !isInlineElement(child)
          })
        }
        elementAttrs = {
          ...elementAttrs,
          attrs: {
            ...elementAttrs.attrs,
            isBlock: hasBlockChildren
          }
        }
      }
    }
    if (nodeName === 'IMG' || nodeName === 'IFRAME' || nodeName === 'EMBEDS') {
      if (nodeName !== 'IMG') {
        if (elementAttrs?.attrs?.['redactor-attributes']?.width) {
          let width = elementAttrs.attrs['redactor-attributes'].width
          if (nodeName === 'IFRAME')
            elementAttrs.attrs.height = elementAttrs.attrs['redactor-attributes'].height
          if (width.slice(-1) === '%') {
            // if it is iframe or embeds then send width as it is (user can provide value in any unit)
            if (nodeName === 'IFRAME' || nodeName === 'EMBEDS') {
              elementAttrs.attrs.width = width
            } else {
              elementAttrs.attrs.width = parseFloat(width.slice(0, width.length - 1))
            }
          } else if (width.slice(-2) === 'px') {
            elementAttrs.attrs.width = parseInt(width.slice(0, width.length - 2))
          } else {
            elementAttrs.attrs.width = parseInt(width)
          }
        }
      }
      else {
        if (elementAttrs?.attrs?.['redactor-attributes']?.width || elementAttrs?.attrs?.style?.width) {
          let width = elementAttrs?.attrs?.['redactor-attributes']?.width ?? elementAttrs?.attrs?.style?.width
          if (width.slice(-2) === 'px') {
            elementAttrs.attrs.width = parseInt(width.slice(0, width.length - 2))
          }
          else {
            elementAttrs.attrs.width = width
          }
        }
        if (elementAttrs?.attrs?.['redactor-attributes']?.height || elementAttrs?.attrs?.style?.height) {
          let height = elementAttrs?.attrs?.['redactor-attributes']?.height ?? elementAttrs?.attrs?.style?.height
          if (height.slice(-2) === 'px') {
            elementAttrs.attrs.height = parseInt(height.slice(0, height.length - 2))
          } else {
            elementAttrs.attrs.height = height
          }
        }
      }
      if (elementAttrs?.attrs?.['redactor-attributes']?.inline) {
        elementAttrs.attrs.inline = Boolean(elementAttrs?.attrs?.['redactor-attributes']?.inline)
      }
      if (nodeName === 'EMBEDS' && !el.getAttribute('src')) {
        if (!elementAttrs?.attrs?.src) {
          elementAttrs.attrs.src = ''
        }
      }
      // preserve iframe descendants
      return jsx('element', elementAttrs, nodeName === 'IFRAME' ? children : [{ text: '' }])
    }
    if (nodeName === 'BLOCKQUOTE') {
      children = Array.from(children).map((child: any) => {
        if (child['break']) {
          return { text: '\n', break: true }
        }
        return child
      })
    }
    if (nodeName === 'TABLE') {
      let table_child = ['THEAD', 'TBODY']
      let cell_type = ['TH', 'TD']
      let col = 0
      // get number of rows by looking at table_child children length
      const row = el.querySelectorAll('TR').length
      const colElementLength = el.getElementsByTagName('COLGROUP')[0]?.children?.length ?? 0
      col = Math.max(...Array.from(el.getElementsByTagName('TR')).map(row => row.children.length), colElementLength)

      let colWidths: Array<any> = Array.from({ length: col }).fill(250)

      Array.from(el.childNodes).forEach((child: any) => {
        if (child?.nodeName === 'COLGROUP') {
          let colGroupWidth = Array<number>(col).fill(250)
          let totalWidth = parseFloat(child.getAttribute('data-width')) || col * 250
          Array.from(child.children).forEach((child: any, index) => {
            if (child?.nodeName === 'COL') {
              let width = child?.style?.width ?? '250px'
              if (width.substr(-1) === '%') {
                colGroupWidth[index] = (parseFloat(width) * totalWidth) / 100
              } else if (width.substr(-2) === 'px') {
                colGroupWidth[index] = parseFloat(width)
              }
            }
          })
          colWidths = colGroupWidth
        }
      })
      let tableHead
      let tableBody

      children.forEach((tableChild) => {
        if (tableChild?.type === 'thead') {
          tableHead = tableChild
          return
        }
        if (tableChild?.type === 'tbody') {
          tableBody = tableChild
          return
        }
      });

      let disabledCols = [...tableHead?.attrs?.disabledCols ?? [], ...tableBody?.attrs?.disabledCols ?? []]
      delete tableHead?.attrs?.disabledCols
      delete tableBody?.attrs?.disabledCols

      elementAttrs = {
        ...elementAttrs,
        attrs: {
          ...elementAttrs.attrs,
          rows: row,
          cols: col,
          colWidths: colWidths,
          disabledCols: Array.from(new Set(disabledCols))
        }
      }
    }
    if (["THEAD", "TBODY"].includes(nodeName)) {
      const rows = children
      const disabledCols = rows.flatMap(row => {
        const { disabledCols } = row.attrs
        delete row['attrs']['disabledCols']
        return disabledCols ?? []
      })
      elementAttrs.attrs['disabledCols'] = disabledCols
    }
    if (nodeName === "TBODY") {

      const rows = children

      addVoidCellsAndApplyAttributes(rows)

      children = getTbodyChildren(rows)
    }

    if (nodeName === "TR") {

      const cells = children.filter(child => ['th', 'td'].includes(child.type))


      const disabledCols = cells.flatMap((cell, cellIndex) => {
        let { colSpan } = cell.attrs
        if (!colSpan) return []
        colSpan = parseInt(colSpan)
        return Array(colSpan).fill(0).map((_, i) => cellIndex + i)
      })

      if (disabledCols.length)
        elementAttrs.attrs['disabledCols'] = disabledCols


    }
    if (['TD', 'TH'].includes(nodeName)) {
      const { colSpan = 1, rowSpan } = elementAttrs?.['attrs']

      return [
        jsx('element', elementAttrs, children),
        ...Array(colSpan - 1)
          .fill(0)
          .map((_) => emptyCell(nodeName.toLowerCase(), rowSpan ? { inducedRowSpan: rowSpan } : {}))
      ]
    }
    if (nodeName === 'P') {
      if (
        elementAttrs?.attrs?.['redactor-attributes']?.['data-checked'] &&
        elementAttrs?.attrs?.['redactor-attributes']?.['data-type']
      ) {
        elementAttrs.type = 'check-list'
        elementAttrs.checked = elementAttrs.attrs['redactor-attributes']['data-checked'] === 'true'
        delete elementAttrs.attrs['redactor-attributes']['data-checked']
        delete elementAttrs.attrs['redactor-attributes']['data-type']
      }
    }
    if (nodeName === 'SPAN') {
      if (elementAttrs?.attrs?.['redactor-attributes']?.['data-inline-block'] === 'true') {
        const newChildren = children.map(child => typeof child === 'string' ? jsx('text', {}, [{ text: child }]) : child)
        // merge fragments
        const p = {
          type: 'p',
          children: newChildren
        }
        return p
      }
      if (elementAttrs?.attrs?.['redactor-attributes']?.['data-type'] === 'inlineCode') {
        delete elementAttrs?.attrs?.['redactor-attributes']?.['data-type']
        let attrsStyle = {
          attrs: {
            style: {}, 'redactor-attributes': {
              inlineCode: elementAttrs?.attrs?.['redactor-attributes']
            }
          }, inlineCode: true
        }
        if (isEmpty(children)) {
          children = [{ text: '' }]
        }
        return children.map((child: any) => {
          attrsStyle.attrs['redactor-attributes'] = { ...attrsStyle.attrs['redactor-attributes'], ...child?.attrs?.['redactor-attributes'] }
          return jsx('text', attrsStyle, child)
        })
      }
      if (elementAttrs?.attrs?.['redactor-attributes']?.style) {
        const hasImageChild = Array.from(children).find((child) => child?.type === 'img')
        if (!hasImageChild) {
          return jsx('element', elementAttrs, children)
        }
      }
      if (nodeName === 'SPAN') {
        Array.from(children).forEach((child: any) => {
          if (child.type) {
            if ((!isInline.includes(child.type) && !isVoid.includes(child.type)) || child.type === 'img') {
              const elAttributes = el.attributes
              const attributes = {}
              let allStyleAttrs: { [key: string]: any } = {}
              if (elAttributes) {
                Array.from(elAttributes).forEach((child: any) => {
                  attributes[child.nodeName] = child.nodeValue
                })
                if (elAttributes.style) {
                  Array.from({ length: el.style.length }).forEach((child, index) => {
                    let property = el.style.item(index)
                    allStyleAttrs[kebabCase(property)] = el.style.getPropertyValue(property)
                  })
                  attributes['style'] = allStyleAttrs
                }
              }
              elementAttrs = {
                type: 'div',
                attrs: {
                  orgType: 'span',
                  ...attributes
                },
                uid: generateId()
              }
            }
          }
        })
      }

      let noOfInlineElement = 0
      const attributes = {}
      const elAttributes = el.attributes
      if (elAttributes) {
        Array.from(elAttributes).forEach((child: any) => {
          attributes[child.nodeName] = child.nodeValue
        })
      }
      Array.from(el.parentNode.childNodes).forEach((child: any) => {
        if (child.nodeType === 3 || child.nodeName === 'SPAN' || child.nodeName === 'A' || TEXT_TAGS[child.nodeName]) {
          noOfInlineElement += 1
        }
      })
      if (noOfInlineElement !== el.parentNode.childNodes.length) {
        elementAttrs = {
          type: 'div',
          attrs: {
            orgType: 'span',
            ...attributes
          },
          uid: generateId()
        }
      }
    }
    if (nodeName === 'LI') {
      if (Array.isArray(children)) {
        children = children.flatMap(child => {
          if (child.type === "fragment") {
            return child.children
          }
          return [child]
        })

        const newChildren = []
        children.forEach((child) => {
          if (isInlineElement(child)) {
            if (newChildren?.at(-1)?.type === 'fragment') {
              newChildren.at(-1).children.push(typeof child === 'string' ? jsx('text', {}, child) : child)
              return
            } else {
              newChildren.push(jsx('element', { type: 'fragment' }, child))
              return
            }
          }
          newChildren.push(child)
        }, [])

        // check here if the children of li has should have first child as fragment with empty text node
        const LIST_TYPES = ['ol', 'ul']
        const shouldAddFragmentToStart = LIST_TYPES.includes(newChildren[0].type)
        if (shouldAddFragmentToStart) {
          newChildren.unshift(jsx('element', { type: 'fragment' }, jsx('text')))
        }

        children = newChildren
      }
    }
    if (children.length === 0) {
      children = [{ text: '' }]
    }
    return jsx('element', elementAttrs, children)
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el)
    let attrsStyle = { ...attrs }
    let newChildren = children.map((child: any) => {
      if (isObject(child)) {
        traverseChildAndModifyChild(child, attrsStyle)
        return child
      } else {
        return jsx('text', attrsStyle, child)
      }
    })
    return jsx('fragment', {}, newChildren)
  }
  if (children.length === 0) {
    children = [{ text: '' }]
  }
  return children
}

const getImageAttributes = (elementAttrs: any, childAttrs: any = {}, extraAttrs: any, isWithOtherBlockElement: any) => {
  elementAttrs = {
    ...elementAttrs,
    attrs: {
      ...elementAttrs.attrs,
      ...childAttrs,
      'redactor-attributes': {
        ...childAttrs?.['redactor-attributes'],
        ...extraAttrs
      },
      caption: extraAttrs.caption,
      captionAttrs: extraAttrs.captionAttrs,
      anchorLink: extraAttrs.anchorLink,
      style: { ...elementAttrs.attrs.style },
      isWithOtherBlockElement
    }
  }
  if (extraAttrs.anchorLink) {
    delete elementAttrs.attrs.anchorLink
  }
  return elementAttrs
}

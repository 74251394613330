import './RuleStackCard.css'

import React, {
  useMemo,
} from 'react'

import Avatar, { AvatarData } from '../AutomationAvatar/AutomationAvatar'
import Dropdown from '../DropDown/Dropdown'
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon2/Icon'

const defaultProps = {
  title: 'Title',
  description: '',
  active: false,
  avatars: [],
  type: 'image',
  actionList: [],
  modified: '',
  footerText: '',
  enableSwitch: true,
  onRuleStackClick: () => { },
  onSwitchClick: (e: any) => { }
}

const defaultAvatars: any = [
  {
    id: 1,
    name: ' ',
    image: 'https://images.contentstack.io/v3/assets/bltbbe427c9ae90c534/blt4699ecc85144a324/611359f5365a7d358c6c08d7/Avatar.png',
  },
  {
    id: 2,
    name: ' ',
    image: 'https://images.contentstack.io/v3/assets/bltbbe427c9ae90c534/bltafb0dbc24b9db576/611359f5ac7fb61a3678efe9/Avatar.png',
  }
]

type DefaultProps = Readonly<typeof defaultProps>

export type RuleStackCardProps = {
  title?: string
  active?: boolean
  data?: any
  avatars?: AvatarData[],
  actionList: any,
  modified: string
  type?: 'text' | 'image'
} & Partial<DefaultProps>

const RuleStackCardHeader = ({ avatars, type, actionList }) => {
  return useMemo(() => {
    return (
      <div className="RuleStackCard__header">
        {avatars && avatars.length ? (
          <Avatar
            avatars={avatars}
            type={type}
            isToHideTooltip={true}
            showPlusSignAfter={4}
            showExtraType="button"
            showExtraColor="dark"
          ></Avatar>
        ) : (<Avatar
          avatars={defaultAvatars}
          type={type}
          isToHideTooltip={true}
          showPlusSignAfter={4}
          showExtraType="button"
          showExtraColor="dark"
        ></Avatar>)}



        <div className="RuleStackCard__context-action-menu">
          <Dropdown
            list={actionList}
            type="click"
            className="rotate-90"
            isEllipse={true}
            closeAfterSelect={true}
          >
            {/* <span className="RuleStackCard__context-menu-dot">&#8942;</span> */}
          </Dropdown>
        </div>


      </div>
    )
  }, [avatars, type, actionList])
}

const RuleStackCardFooter = ({ footerText, enableSwitch, onSwitchClick, modified }) => {
  return useMemo(() => {
    return (
      <div className="RuleStackCard__footer">
        <div className="RuleStackCard__footer-primary-content">
          <div onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSwitchClick(e);
          }}><ToggleSwitch checked={enableSwitch} /></div>
          <div className="RuleStackCard__footer-text">{footerText}</div>
        </div>

        <div>
          {
            modified &&
            <Tooltip content="Last modified" position="top">
              <div className="RuleStackCard__modified flex-v-center">
                <Icon className="RuleStackCard__modified-icon" icon={'ModifiedBy'} />
                <span className="RuleStackCard__modified-date">{modified}</span>
              </div>
            </Tooltip>
          }
        </div>

      </div>
    )
  }, [footerText, enableSwitch, onSwitchClick])
}

const RuleStackCard = (props: RuleStackCardProps) => {
  let { title, avatars, type, actionList, footerText, enableSwitch, description, onSwitchClick, onRuleStackClick, modified } = props;

  const shortName = (name: any, expectedlength: number) => {
    if (name) {
      if (name.length < expectedlength) {
        return name
      } else {
        return `${name.slice(0, expectedlength)}`
      }
    }
  }

  return (
    <div className="RuleStackCard" onClick={onRuleStackClick}>
      <RuleStackCardHeader actionList={actionList} avatars={avatars} type={type} />
      <div className="RuleStackCard__heading">
        <h4 className="RuleStackCard__title">
          <div>
            {title.length > 60 ? (<>
              <Tooltip content={title} position={"top"}>
                <span>{shortName(title, 60) + "..."}</span>
              </Tooltip></>
            ) : (
              <span>{title}</span>
            )}
          </div>
        </h4>
      </div>
      <div className='RuleStackCard__description'>
        {description.length > 200 ? (<>
          <Tooltip position={"top"} content={description}>
            <span>{shortName(description, 200) + "..."}</span>
          </Tooltip>

        </>
        ) : (
          <span>
            {description}
          </span>
        )}
      </div>
      <RuleStackCardFooter footerText={footerText} onSwitchClick={onSwitchClick} enableSwitch={enableSwitch} modified={modified} />
    </div>
  )
}

RuleStackCard.defaultProps = defaultProps

export default RuleStackCard

import React, { useState, useEffect, useRef } from 'react'
import { FixedSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { useComponentVisible } from '../DropDown/Dropdown'
import Icon from '../Icon2/Icon'
import TextInput from '../TextInput/TextInput'
import SkeletonTile from '../SkeletonTile/SkeletonTile'
import './SaveVersionDropdown.css'
import Tooltip from '../Tooltip/Tooltip'

export type IList = {
  _version: number
  _version_name?: {
    locale?: string
    title: string
    updated_at?: string
    updated_by?: string
  }
}

type ISaveVersionDropdown = {
  latestVersion: {
    _version: number
    _version_name?: {
      locale?: string
      title: string
      updated_at?: string
      updated_by?: string
    }
  }
  defaultSelected: {
    _version: number
    _version_name?: {
      locale?: string
      title: string
      updated_at?: string
      updated_by?: string
    }
  }
  list: Array<IList>
  versionChange: Function
  loadMoreVersion: Function
  itemStatusMap: object
  saveVersion: Function
  itemsCount?: number
  compareVersion?: Function
  defaultVersionList: Array<IList>
  viewOnlyMode?: boolean
  isEntryCompare?: boolean
  reduceCount?: number
  cancelEdit?: Function
  dropdownVersion?: string
}

const Skeleton = () => {
  return (
    <SkeletonTile
      numberOfTiles={1}
      tileHeight={10}
      tileWidth={200}
      tileBottomSpace={0}
      tileTopSpace={0}
      tileleftSpace={0}
    />
  )
}

const SaveVersionDropdown: React.FunctionComponent<ISaveVersionDropdown> = (props: any) => {
  const {
    latestVersion,
    versionChange,
    saveVersion,
    compareVersion,
    defaultVersionList,
    defaultSelected,
    viewOnlyMode,
    isEntryCompare,
    reduceCount,
    cancelEdit,
    itemsCount,
    dropdownVersion,
  } = props
  const viewOnlyList = viewOnlyMode !== undefined ? viewOnlyMode : false
  const isEntryCompareFlag = isEntryCompare !== undefined ? isEntryCompare : false
  const reduceCountNumber = reduceCount !== undefined ? reduceCount : 0
  const getDefaultValue = () => {
    if (defaultSelected) {
      if (defaultSelected.hasOwnProperty('_version_name')) {
        return defaultSelected._version_name.title
      } else {
        return `Version ${defaultSelected._version}`
      }
    }
  }

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const [versionList, setVersionList] = useState(props.list || [])
  const [selectedData, setSelectedData] = useState(getDefaultValue())
  const [selectedVersion, setSelectedVersion] = useState(defaultSelected._version)
  const [editMode, setEditMode] = useState(false)
  const [editValue, setEditValue] = useState('')
  const [editIndex, setEditIndex] = useState()
  const textInput: any = useRef(null)

  useEffect(() => {
    setSelectedData(getDefaultValue())
    setSelectedVersion(defaultSelected._version)
  }, [props.latestVersion._version, props.defaultSelected._version])

  useEffect(() => {
    setEditValue('')
    setEditIndex(undefined)
  }, [!isComponentVisible])

  useEffect(() => {
    setVersionList(props.list)
  }, [props.list])

  const handleSelect = (index: any) => {
    console.log('selectedVersion', selectedVersion, index, selectedData, versionList[index])
    if (selectedVersion !== versionList[index]._version) {
      if (versionList[index].hasOwnProperty('_version_name')) {
        if (versionList[index]._version_name.title !== selectedData) {
          setSelectedData(versionList[index]._version_name.title)
        } else {
          setSelectedData(`Version ${versionList[index]._version}`)
        }
      } else {
        if (`Version ${versionList[index]._version}` !== selectedData) {
          setSelectedData(`Version ${versionList[index]._version}`)
        }
      }
      setIsComponentVisible(!isComponentVisible)
      if (versionList.length) {
        // let sortList: any = [...versionList]
        let sortList: any = [...defaultVersionList]
        defaultVersionList.map((version_el: any, i: any) => {
          if (version_el._version === versionList[index]._version) {
            let f = sortList.splice(i, 1)[0]
            sortList.splice(0, 0, f)
          }
        })
        console.log('check latest array ', sortList)
        setVersionList(sortList)
      }
      setSelectedVersion(versionList[index]._version)
      if (versionChange) {
        versionChange(versionList[index]._version)
      }
    } else {
      setIsComponentVisible(!isComponentVisible)
    }
  }

  const saveEditMode = (index: any) => {
    let newVersionList: any = [...versionList]
    let saveSelectedFlag: any = false
    if (newVersionList[index].hasOwnProperty('_version_name')) {
      // newVersionList[index]._version_name.title = editValue;
      if (newVersionList[index]._version_name.title === selectedData) {
        saveSelectedFlag = true
      }
      newVersionList[index]._version_name.title = textInput.current.value
    } else {
      if (`Version ${newVersionList[index]._version}` === selectedData) {
        saveSelectedFlag = true
      }
      newVersionList[index] = {
        _version_name: {
          // title: editValue
          title: textInput.current.value
        },
        ...newVersionList[index]
      }
    }
    setVersionList(newVersionList)
    setEditMode(false)
    saveVersion(newVersionList[index])
    // console.log('check')
    // setSelectedVersion(newVersionList[index]._version)
    if (saveSelectedFlag) {
      setSelectedData(textInput.current.value)
    }
  }

  const cancelEditMode = (evt: any) => {
    evt.stopPropagation()
    if (cancelEdit) {
      if (textInput.current && textInput.current.value) {
        cancelEdit(textInput.current.value)
      } else {
        cancelEdit(editValue)
      }
    }
    setEditValue('')
    setEditIndex(undefined)
    setEditMode(false)
  }

  const enableEditMode = (index: any) => {
    setEditMode(true)
    setEditIndex(index)
    if (versionList[index].hasOwnProperty('_version_name')) {
      setEditValue(versionList[index]._version_name.title)
    } else {
      setEditValue(`Version ${versionList[index]._version}`)
    }
  }

  const compareVersionCB = (versionItem: any) => {
    compareVersion({
      current: selectedVersion,
      compareTo: versionItem
    })
    setIsComponentVisible(false)
  }

  const loadMoreVersion = async (startIndex: any, stopIndex: any) => {
    const skip = startIndex ? startIndex - 1 : startIndex
    const limit = stopIndex - skip
    const fetchDataArgument: any = { skip, limit, startIndex, stopIndex, selectedVersion }
    props.loadMoreVersion(fetchDataArgument)
  }

  const versionRow = (i: any, item: any) => {
    let widthClassname = `${`Dropdown__version__info ${item._version === latestVersion._version ? 'Dropdown__version__info--latest' : ''
      }`}`
    // let widthClassname = item._version === latestVersion._version ? 'Dropdown__version__name--latest' : 'Dropdown__version__name'
    return (
      <div className={widthClassname}>
        {editMode && editIndex === i ? (
          <form
            onSubmit={(evt: any) => {
              evt.stopPropagation()
              saveEditMode(i)
            }}>
            <TextInput
              data-test-id="cs-version-dropdown-input"
              placeholder={'version name'}
              type="text"
              value={editValue}
              key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
              onChange={(e: any) => {
                e.stopPropagation()
                console.log('this is ref ', textInput.current)
                // setEditValue(e.target.value)
              }}
              onClick={(e: any) => e.stopPropagation()}
              inputRef={textInput}
              autoFocus={true}
              maxLength={32}
            />
          </form>
        ) : (
          <div className="flex-v-center">
            {/* <Tooltip
              content={(item._version_name && item._version_name.title) || `Version ${item._version}`}
              position="bottom"> */}

            <div className="Dropdown__version__name" title={(item._version_name && item._version_name.title) || `Version ${item._version}`}>
              {(item._version_name && item._version_name.title) || `Version ${item._version}`}
            </div>
            {/* </Tooltip> */}
            {(item.hasOwnProperty('_version_name') &&
              item._version_name.title === latestVersion.hasOwnProperty('_version_name') &&
              latestVersion._version_name.title) ||
              `Version ${item._version}` === `Version ${latestVersion._version}` ? (
              <span className="Dropdown__version__legend">{`Latest`}</span>
            ) : null}
          </div>
        )}
        {editMode && editIndex === i ? (
          <div className="Dropdown__version__action">
            <span onClick={cancelEditMode} data-test-id="cs-version-dropdown-cancel-edit">
              <Icon icon="SmallCancelCircle" className="mr-10" />
            </span>
            <span
              data-test-id="cs-version-dropdown-edit-save"
              onClick={(evt: any) => {
                evt.stopPropagation()
                saveEditMode(i)
              }}>
              <Icon icon="SmallCheckedCircle" />
            </span>
          </div>
        ) : (
          !viewOnlyList && (
            <div className="Dropdown__version__action">
              <Tooltip content={'Rename'} position="bottom" showArrow={false}>
                <div
                  data-test-id="cs-version-dropdown-edit"
                  className="Dropdown__version__action--edit"
                  onClick={(evt: any) => {
                    evt.stopPropagation()
                    enableEditMode(i)
                  }}>
                  <Icon icon="Rename"></Icon>
                </div>
              </Tooltip>
              {compareVersion &&
                ((item.hasOwnProperty('_version_name') && item._version_name.title === selectedData) ||
                  `Version ${item._version}` === selectedData ? null : (
                  <Tooltip content={'Compare'} position="bottom" showArrow={false}>
                    <div
                      data-test-id="cs-version-dropdown-compare"
                      className="Dropdown__version__action--compare"
                      onClick={(evt: any) => {
                        evt.stopPropagation()
                        compareVersionCB(item)
                      }}>
                      <Icon icon="Compare"></Icon>
                    </div>
                  </Tooltip>
                ))}
            </div>
          )
        )}
      </div>
    )
  }

  const Row = (prop: any) => {
    const { index, style } = prop
    let label
    let customStyle = { ...style }
    if (props.itemStatusMap[index] === 'loaded') {
      let content = versionList[index]
      label = content ? versionRow(index, content) : <Skeleton />
      // label = versionRow(index, content);
    } else {
      label = <Skeleton />
    }
    // if (index === 0) {
    //   console.log("le style ", style);
    //   style.marginTop = "10px"
    // }
    customStyle.marginTop = '10px'
    customStyle.marginBottom = '10px'
    return (
      // <>{label}</>
      <li
        data-test-id={`cs-save-version-item-${index}`}
        style={customStyle}
        className="Dropdown__version__menu__items"
        key={versionList[index]?.uuid || index}
        tabIndex={0}
        onKeyDown={(e: any) => {
          if (!editMode && (e.key === 'Enter' || e.key === ' ')) {
            handleSelect(index)
          }
          if (e.key === 'ArrowDown' && isComponentVisible) {
            const liDomElemet: any = document.getElementsByClassName('Dropdown__version__menu__items')[index + 1]
            liDomElemet?.focus()
          }
          if (e.key === 'ArrowUp' && isComponentVisible) {
            if (index === 0) {
              setIsComponentVisible(!isComponentVisible)
              let dropdownHeaderDom: any = document.getElementsByClassName('Dropdown__version')[0]
              dropdownHeaderDom?.focus()
            }
            const liDomElemet: any = document.getElementsByClassName('Dropdown__version__menu__items')[index - 1]
            liDomElemet?.focus()
          }
        }}
        onClick={(evt: any) => {
          handleSelect(index)
        }}>
        {label}
      </li>
    )
  }

  let finalHeight: any
  if (versionList.length < 10) {
    finalHeight = versionList.length * 39
    finalHeight = finalHeight + 20
    if (finalHeight > 300) {
      finalHeight = 300
    }
  } else {
    finalHeight = 300
  }

  const isItemLoaded = (index: any) => !!props.itemStatusMap[index]
  return (
    <>
      <div
        ref={ref}
        tabIndex={0}
        onKeyDown={(e: any) => {
          if (!editMode && (e.key === 'Enter' || e.key === ' ')) {
            setIsComponentVisible(!isComponentVisible)
          }
          if (e.key === 'ArrowDown' && !e.target.classList.contains('Dropdown__version__menu__items')) {
            const liDomElemet: any = document.getElementsByClassName('Dropdown__version__menu__items')[0]
            liDomElemet?.focus()
          }
        }}
        className={`Dropdown__version ${dropdownVersion === 'v2' ? 'Dropdown__version-v2' : 'Dropdown__version'}`}>
        <div
          onClick={() => {
            setIsComponentVisible(!isComponentVisible)
          }}
          className="Dropdown__version__header">
          <div className="flex-v-center">
            <div className="Dropdown__version__header__value" title={selectedData}>
              {selectedData}
            </div>

            {(latestVersion.hasOwnProperty('_version_name') && latestVersion._version_name.title === selectedData) ||
              `Version ${latestVersion._version}` === selectedData ? (
              <span className="Dropdown__version__legend">{` Latest`}</span>
            ) : null}
          </div>
          {/* {!isComponentVisible ? (
            <Icon className={`Dropdown__version__header__chevron`} icon="ChevronSmall" />
          ) : (
            <Icon className={`Dropdown__version__header__chevron-inverted`} icon="ChevronSmall" />
          )} */}
          {dropdownVersion === 'v2' ?
            <Icon version='v2' size="medium" className={`${!isComponentVisible ? 'Dropdown__version__header__chevron' : 'Dropdown__version__header__chevron-inverted'}`} icon="CaretDownNew" />
            :
            <Icon className={`${!isComponentVisible ? 'Dropdown__version__header__chevron' : 'Dropdown__version__header__chevron-inverted'}`} icon="ChevronSmall" />
          }
        </div>
        {/* )} */}
        {isComponentVisible && (
          <ul className="Dropdown__version__menu-wrapper">
            {versionList && versionList.length ? (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemsCount + 1 || latestVersion._version + 1}
                loadMoreItems={loadMoreVersion}
                minimumBatchSize={30}>
                {({ onItemsRendered, ref }) => (
                  <List
                    className={`Dropdown__version__menu ${versionList.length === 1 ? 'Dropdown__version__menu--visible' : ''
                      }`}
                    height={finalHeight}
                    itemCount={
                      isEntryCompareFlag
                        ? latestVersion._version - reduceCountNumber
                        : itemsCount || latestVersion._version
                    }
                    itemSize={39}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    width={'100%'}>
                    {Row}
                  </List>
                )}
              </InfiniteLoader>
            ) : versionList.length === 0 && defaultVersionList.length > 0 ? (
              <></>
            ) : (
              <div>Loading</div>
            )}
          </ul>
        )}
      </div>
    </>
  )
}

SaveVersionDropdown.defaultProps = {
  dropdownVersion: 'v1',
} as Partial<ISaveVersionDropdown>
export default SaveVersionDropdown

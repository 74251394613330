//@ts-nocheck
import { Registry } from '@react-registry'
import { Editor, Range, Point, Node, Transforms, Element, Text } from 'slate'
import { wrapChildren } from '../../../elements/'
import { jsx } from 'slate-hyperscript'
import { createHeadCell } from './utils/createHeadCell'
import { createCell } from './utils/createCell'
import { getSelectedCells } from './utils/getSelectedCells'
const cellType = ['td', 'th']
export const withTables = editor => {
  const { deleteBackward, deleteForward, insertBreak, deleteFragment, isVoid, normalizeNode } = editor

  editor.deleteBackward = unit => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n => cellType.includes(n.type),
        mode: 'lowest'
      })

      if (cell) {
        const [, cellPath] = cell
        const [start, end] = Editor.edges(editor, cellPath)
        const textNodesIncell = Array.from(Editor.nodes(editor, {at: cellPath, match: node => Text.isText(node)})).map(ne => ne[0])

        if (Point.equals(selection.anchor, start) && start.offset === 0 && end.offset === 0 && textNodesIncell.length === 1) {
          Transforms.setNodes(editor, { type: 'p' }, { at: [...cellPath, 0] })
          return
        }
        if (Point.equals(selection.anchor, start)) {
          const element = Node.parent(editor, selection.anchor.path)
          if (element.type !== 'li') {
            return
          }
        }

        try{
          const [anchorElement] = Editor.nodes(editor, { match : node => Element.isElement(node) && node.type === 'a'})
          const isAnchorFirstElement = anchorElement?.[1]?.slice(-1)?.[0] === 1
          const isAtStartOfAnchor = anchorElement ? Editor.isStart(editor, editor.selection.anchor, anchorElement[1]) : null
          const isFirstTextNodeEmpty = anchorElement ? Editor.previous(editor, {at: anchorElement[1]})?.[0]?.text?.length === 0 : null
          
          if(isAnchorFirstElement && isAtStartOfAnchor && isFirstTextNodeEmpty){
            return
          }
        }
        catch(err){
          console.log('err', err)
        }

      }
    }

    deleteBackward(unit)
  }

  editor.deleteForward = unit => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n => cellType.includes(n.type),
        mode: 'lowest'
      })

      if (cell) {
        const [, cellPath] = cell
        const end = Editor.end(editor, cellPath)
        if (Point.equals(selection.anchor, end)) {
          return
        }
      }
    }
    deleteForward(unit)
  }
  editor.deleteFragment = (unit) => {
    const { selection } = editor
    if (selection) {
      const [table] = Editor.nodes(editor, { match: n => n.type === 'table' })
      // Get cells in which selection ranges
      const cells = Array.from(Editor.nodes(editor, {
        match: (n) => {
          return cellType.includes(n.type) && !Editor.isVoid(editor, n)
        }
      }))

      if (cells.length === 1) { // only one cell in selection
        Transforms.delete(editor) // delete text only
        return
      }
      if (table) {
        const [, tablePath] = table
        const tableRange = Editor.range(editor, tablePath)
        const isWholeTableSelected = Range.equals(Range.intersection(selection, tableRange), tableRange)// check if the whole table is selected
        let isSelectionOnlyInTable = false

        if (editor.selection) {
          isSelectionOnlyInTable =
            Range.includes(tableRange, Range.start(editor.selection)) &&
            Range.includes(tableRange, Range.end(editor.selection))
        }

        if (isWholeTableSelected) {
          Transforms.delete(editor, { at: tablePath })
        }
        else if (isSelectionOnlyInTable) {
          const cells = Array.from(getSelectedCells(editor, tablePath))
          cells.forEach(cell => {
            const newCell = { type: cell[0].type, attrs: {}, children: [{ text: '' }] }
            Transforms.removeNodes(editor, { at: cell[1] })
            Transforms.insertNodes(editor, newCell, { at: cell[1] })
          });

          Transforms.select(editor, cells[0][1])

          return
        }
        else {
          const [cell] = Editor.nodes(editor, {
            match: (n, path) => {
              if (n.type === 'td' || n.type === 'th') {
                const start = Editor.start(editor, path)
                const end = Editor.end(editor, path)
                const isPartialTableSelected = Range.includes(selection, start) && Range.includes(selection, end) // delete only the content of the table
                if (isPartialTableSelected) {
                  const node = n.type === 'td' ? createCell() : createHeadCell()
                  Transforms.removeNodes(editor, { at: [...path] })
                  Transforms.insertNodes(editor, node, { at: [path] })
                  Transforms.select(editor, [...path])
                } else {
                  Transforms.delete(editor, { at: Range.intersection(selection, Editor.range(editor, path)) })
                }
              }
            }
          })
          return
        }
      }
    }
    deleteFragment(unit)
  }
  editor.insertBreak = () => {
    const { selection } = editor
    if (selection) {
      const [table] = Editor.nodes(editor, { match: n => n.type === 'table', mode: 'lowest' })
      const [selectionElement] = Editor.nodes(editor, { match: n => cellType.includes(n.type), mode: 'lowest' })

      if (table) {
        if (selectionElement && selectionElement[0] && selectionElement[0].children.length >= 1 && selectionElement[0].children[0].type) {
          if (selectionElement[0].children[0].type === 'a') {
            return
          }
        }
        else {
          return
        }
      }
    }

    insertBreak()
  }
  editor.isVoid = (element) => {
    return (['td', 'th'].includes(element.type) && element.attrs.void) ? true : isVoid(element)
  }

  editor.normalizeNode = (nodeEntry) => {
    const [node, path] = nodeEntry
    if (Element.isElement(node) && cellType.includes(node.type)) {
      // cell should not have direct text nodes and if they do wrap them in fragment
      if (Text.isTextList(node.children)) {
        wrapChildren(editor, nodeEntry, jsx('element', { type: 'fragment' }))
      }
    }

    normalizeNode(nodeEntry)
  }
  return editor
}

Registry.register(withTables, { id: 'withTables', registry: 'plugin' })
